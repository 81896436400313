import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { sha256 } from "js-sha256";

import { Card } from "./card.blueprint";

@Component({
  selector: "app-authen-completed",
  templateUrl: "./authen-completed.component.html",
  styleUrls: ["./authen-completed.component.scss"],
})
export class AuthenCompletedComponent implements OnInit {
  public authen: any;
  public code;
  public shop_id;
  public state;
  public userInSessionStorage;

  constructor(private _router: Router, private http: HttpClient) { }

  ngOnInit(): void {
    this.http.get<any>("api/Authen/session").subscribe(
      (data) => {
        console.log(data);
        this.userInSessionStorage = JSON.parse(JSON.stringify(data));
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.code = urlParams.get("code");
        this.shop_id = urlParams.get("shop_id");
        this.state = urlParams.get("state");
        if (this.code != undefined) {
          if (this.shop_id != undefined) {
            this.getAccesstokenShopeeV2();
          } else if (this.state == 'tiktok') {
            this.getAccesstokenTikTokShop();
          } else {
            this.getAccesstokenLazada();
          }
        }
      },
      (err) => {
        alert("Session หมดอายุ กรุณา Login และทำการเชื่อมต่อ API ใหม่อีกครั้ง");
        window.location.href = "https://acccloud.me/login";
      }
    );
  }
  public card = new Card({
    buttons: ["Back"],
    content: "authcompletedcontent",
    icons: ["privacy_tip"],
    subtitle: "authcompletedsubtitle",
    title: "authcompletedtitle",
  });
  backClicked() {
    if (this.code != undefined) {
      if (this.shop_id != undefined) {
        this._router.navigate(["open-api/shop", "shopee"]);
      } else if (this.state == 'tiktok') {
        this._router.navigate(["open-api/shop", "tiktokshop"]);
      } else {
        this._router.navigate(["open-api/shop", "lazada"]);
      }
    } else {
      this._router.navigate(["open-api/home"]);
    }
  }

  public getAccesstokenTikTokShop() {
    var api_path_node = "/api/TikTokShop/get_access_token";
    var app_key = "67v3n8ga8cc3g";
    var app_secret =
      "7191d6dd9350e087fe082887564d29ec7a05da97";
    var code = this.code;
    var url =
      api_path_node +
      "?app_key=" +
      app_key +
      "&auth_code=" +
      code +
      "&app_secret=" +
      app_secret +
      "&grant_type=authorized_code"
    this.http.get<any>(url).subscribe(async (data) => {
      var filter = {
        companyCode: this.userInSessionStorage.companyCode,
        access_token: data.data.access_token,
        auth_code: this.code,
        access_token_expire_in: data.data.access_token_expire_in,
        refresh_token: data.data.refresh_token,
        refresh_token_expire_in: data.data.refresh_token_expire_in,
      };
      var shopDetail = await this.tiktokGetAuthorizedShop(data.data.access_token)
      let filterUpdateProfile = {
        ...filter,
        ...(shopDetail.length && { shop_id: shopDetail[0].id, shop_name: shopDetail[0].name, auth_code: shopDetail[0].cipher }),
      }
      this.http
        .post<any>('api/center/TikTokShop/updateToken', [filterUpdateProfile])
        .subscribe((data) => {
          console.log(data)
        });
    });
  }
  public getAccesstokenShopeeV2() {
    var timestamp = new Date().getTime().toString().substring(0, 10);
    var api_path_node = "/api/shopee/overview/get_access_token";
    var api_path = "/api/v2/auth/token/get";
    var partner_id = 2001369;
    var partner_key =
      "954af541630d4a7e80272c7ac60e9f5a359adf73bca64466a3228100b7a02cab";
    var base_string = partner_id + api_path + timestamp;
    var code = this.code;
    var shop_id = this.shop_id;
    var sign = sha256.hmac(partner_key, base_string);
    var url =
      api_path_node +
      "?partner_id=" +
      partner_id +
      "&timestamp=" +
      timestamp +
      "&sign=" +
      sign;
    var body = {
      code: code,
      shop_id: Number(shop_id),
      partner_id: Number(partner_id),
    };
    this.http.post<any>(url, body).subscribe(async (data) => {
      var filter = {
        companyCode: this.userInSessionStorage.companyCode,
        shop_id: this.shop_id,
        access_token: data.access_token,
        refresh_token: data.refresh_token,
        shop_name: null,
      };

      filter.shop_name = await this.shopeeGetShopProfile(filter)
      this.http
        .post<any>('api/center/shopee/update', [filter])
        .subscribe((data) => {
          console.log(data)
        });
    });
  }

  public getAccesstokenLazada() {
    var timestamp = new Date().getTime().toString();
    var api_path_node = "/api/lazada/system/generate_access_token";
    var api_path = "/auth/token/create";
    var app_key = 102014;
    var app_secret = "LWTteWLA8vyCHP8FmheEZBKKTEPIrRBN";
    var code = this.code;
    var sign_method = "sha256";
    var urlUnSort = [
      "app_key" + app_key,
      "code" + code,
      "timestamp" + timestamp,
      "sign_method" + sign_method,
    ];
    var urlSorted = urlUnSort.sort();
    var base_string = api_path + urlSorted.toString().replace(/,/g, "");
    var sign = sha256.hmac(app_secret, base_string);
    var url =
      api_path_node +
      "?code=" +
      code +
      "&app_key=" +
      app_key +
      "&timestamp=" +
      timestamp +
      "&sign_method=" +
      sign_method +
      "&sign=" +
      sign.toUpperCase();
    console.log(url);

    var body = {
      code: code,
    };
    this.http.post<any>(url, body).subscribe(async (data) => {
      var filter = {
        companyCode: this.userInSessionStorage.companyCode,
        access_token: data.access_token,
        refresh_token: data.refresh_token,
        user_id: data.country_user_info[0].user_id,
        seller_id: data.country_user_info[0].seller_id,
        short_code: data.country_user_info[0].short_code,
        shop_name: null
      };
      filter.shop_name = await this.lazadaGetSeller(filter);
      this.http
        .post<any>("api/center/lazada/update", [filter])
        .subscribe((data) => {
          console.log(data);
        });
    });
  }
  public delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public async shopeeGetShopProfile(item: any) {
    var timestamp = new Date().getTime().toString().substring(0, 10);
    var api_path_node = "/api/shopee/shop/get_profile";
    var api_path = "/api/v2/shop/get_shop_info";
    var partner_id = 2001369;
    var partner_key =
      "954af541630d4a7e80272c7ac60e9f5a359adf73bca64466a3228100b7a02cab";
    var access_token = item.access_token;
    var shop_id = item.shop_id;
    var base_string =
      partner_id + api_path + timestamp + access_token + shop_id;
    var sign = sha256.hmac(partner_key, base_string);
    var url =
      api_path_node +
      "?partner_id=" +
      partner_id +
      "&timestamp=" +
      timestamp +
      "&access_token=" +
      access_token +
      "&shop_id=" +
      shop_id +
      "&sign=" +
      sign;
    const data = await this.http.get<any>(url).toPromise();
    return data ? data.shop_name : item.companyCode;
  }

  public async lazadaGetSeller(item: any) {
    var timestamp = new Date().getTime().toString();
    var api_path_node = "/api/lazada/seller/get_seller";
    var api_path = "/seller/get";
    var app_key = 102014;
    var app_secret = "LWTteWLA8vyCHP8FmheEZBKKTEPIrRBN";
    var access_token = item.access_token;
    var sign_method = "sha256";
    var urlUnSort = [
      "app_key" + app_key,
      "access_token" + access_token,
      "timestamp" + timestamp,
      "sign_method" + sign_method,
    ];
    var urlSorted = urlUnSort.sort();
    var base_string = api_path + urlSorted.toString().replace(/,/g, "");
    var sign = sha256.hmac(app_secret, base_string);
    var url =
      api_path_node +
      "?access_token=" +
      access_token +
      "&app_key=" +
      app_key +
      "&timestamp=" +
      timestamp +
      "&sign_method=" +
      sign_method +
      "&sign=" +
      sign.toUpperCase();
    const data = await this.http.get<any>(url).toPromise()
    return data.data.name
  }

  public async tiktokGetAuthorizedShop(token: string) {
    var timestamp = new Date().getTime().toString().substring(0, 10);
    var api_path_node = "/api/TikTokShop/get_authorized_shop";
    var api_path = "/authorization/202309/shops";
    var app_key = "67v3n8ga8cc3g";
    var app_secret =
      "7191d6dd9350e087fe082887564d29ec7a05da97";
    var commonPara = {
      "app_key": app_key,
      "timestamp": timestamp,
    }
    var sign = this.generateSignTiktok(api_path, commonPara, app_secret)
    var url =
      api_path_node +
      "?app_key=" +
      app_key +
      "&sign=" +
      sign +
      "&timestamp=" +
      timestamp
    const data = await this.http.get<any>(url, {
      headers: {
        "x-tts-access-token": token
      }
    }).toPromise();
    return data.data?.shops;
  }

  public generateSignTiktok(path: string, queries: any, secret: string): string {
    const keys = Object.keys(queries).sort();
    let input = path;
    for (const key of keys) {
      input += key + queries[key];
    }
    input = secret + input + secret;
    const hash = sha256.hmac(secret, input).toString();
    return hash
  }

}
