import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {

  constructor(private http: HttpClient) { }

  public userInLocalStorage: any;
  public superAdmin: any;
  public authen: any;
  public memo: any;
  public memoLabel: any;
  


  ngOnInit(): void {
    this.userInLocalStorage = JSON.parse(localStorage.getItem("info"));
    this.superAdmin = this.userInLocalStorage.superAdmin == "stradmin";
  }

  public async onFloatClick() {
    /* var page = window.location.href.split('/')[4].split('-').join('').split('transaction').join(''); */
    let pageData = window.location.href.split('/');
    let page = "";
    if(pageData[3] == "setup" || pageData[3] == "report"){
      page = pageData[5];
    }else{
      page = pageData[4];
    }
    page = page.split('-').join('').split('transaction').join('');
    if(sessionStorage.getItem('authen' + page)){
      this.authen = JSON.parse(sessionStorage.getItem('authen' + page));
    }else if (window.location.href.includes('open-api')){
      this.authen = JSON.parse(sessionStorage.getItem('authenorder'))
    }
    this.memoLabel = 'Memo : '+this.authen.menuTName
    this.memo = await this.getMemo();
  }
  public async getMemo() {
    const data = await this.http.post<any>("/api/center/menumaster/getbyid", { "menuMasterId": this.authen.menuMasterId }).toPromise();
    return data[0].memo;
  }

  public async updateMemo() {
    const data = await this.http.post<any>("/api/center/menumaster/updateMemo", [{ "menuMasterId": this.authen.menuMasterId, "memo": this.memo }]).toPromise();
    return data;
  }
}
