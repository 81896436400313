<body style="background-color:rgb(255, 255, 255)">
    <div class="sample-column card-wrapper">
        <igx-card>
            <igx-card-media height="400px">
                <img src="assets//image/auth-completed.jpg">
            </igx-card-media>
            <igx-card-header>
                <h3 igxCardHeaderTitle>{{ card.title  | translate  }}</h3>
                <h5 igxCardHeaderSubtitle>{{ card.subtitle  | translate  }}</h5>
            </igx-card-header>
            <igx-card-content>
                <p>{{ card.content  | translate }}</p>
            </igx-card-content>

            <igx-card-actions>
                <button igxButton igxRipple *ngFor="let button of card.buttons"(click)="backClicked()">{{ button | translate  }}</button>
               
                <button igxButton="icon"
                    igxRipple
                    [igxRippleCentered]="true"
                    *ngFor="let icon of card.icons">
                    <igx-icon>{{icon}}</igx-icon>
                </button>
            </igx-card-actions>

        </igx-card>
    </div>
</body>
