import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Card } from './card.blueprint';

@Component({
  selector: 'app-not-available',
  templateUrl: './not-available.component.html',
  styleUrls: ['./not-available.component.scss']
})
export class NotAvailableComponent implements OnInit {
  public authen: any;

  constructor(private _location: Location,private _router: Router) { }

  ngOnInit(): void {
  }
    public card = new Card({
        buttons: ["Home","StatusSystem"],
        icons: ["report_problem"],
        content: "เราตรวจสอบพบการทำงานที่ผิดปกติ เจ้าหน้าที่รับทราบปัญหาและกำลังพยายามเร่งแก้ไขปัญหาที่เกิดขึ้นอย่างเร็วที่สุด และจะกลับมาให้บริการเป็นปกติในไม่ช้า กลับสู่หน้าแรกหรือตรวจสอบสถานะของระบบได้ที่ปุ่มด้านล่าง",
        subtitle: "ระบบไม่พร้อมให้ใช้งานในขณะนี้",
        title: "503 Service Unavailable "
    });
    backClicked(page) {
      if(page=="Home"){
        this._router.navigate(["homepage"]);
      }else{
        this._router.navigate(["center/status-system"]);
      }
    }

}
