import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardPageService } from '../shared/authen/auth-guard-page.service';
import { AuthenCompletedComponent } from './authen-completed/authen-completed.component';
import { AuthenscreenComponent } from './authenscreen/authenscreen.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { NotAvailableComponent } from './not-available/not-available.component';
import { PageComponent } from './page.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  { path: 'notauthen', component: AuthenscreenComponent  },
  { path: 'not-authorized', component: NotAuthorizedComponent  },
  { path: 'not-available', component: NotAvailableComponent  },
  { path: 'authen-completed', component: AuthenCompletedComponent  },
  { path: 'reset-password', component: ResetPasswordComponent  },



  {
    path: '',
    component: PageComponent,canActivate : [AuthGuardPageService] ,
    children: [
      {
        path: 'homepage',
        loadChildren: () => import('./home-page/home-page.module').then(m => m.HomePageModule)
      },
      {
        path: 'setup',
        loadChildren: () => import('../page/setup/setup.module').then(m => m.SetupModule)
      },
      {
        path: 'report',
        loadChildren: () => import('../page/report/report.module').then(m => m.ReportModule)
      },
      {
        path: 'pivot',
        loadChildren: () => import('../page/pivot/pivot.module').then(m => m.PivotModule)
      },
      {
        path: 'account',
        loadChildren: () => import('../page/account/account.module').then(m => m.AccountModule)
      },
      {
        path: 'salesystem',
        loadChildren: () => import('./sale-system/sale-system.module').then(m => m.SaleSystemModule)
      },
      {
        path: 'support',
        loadChildren: () => import('./support/support.module').then(m => m.SupportModule)
      },
      {
        path: 'receive',
        loadChildren: () => import('./receive/receive.module').then(m => m.ReceiveModule)
      },
      {
        path: 'purchasesystem',
        loadChildren: () => import('./purchase-system/purchase-system.module').then(m => m.PurchaseSystemModule)
      },
      {
        path: 'finance',
        loadChildren: () => import('./finance/finance.module').then(m => m.FinanceModule)
      },
      {
        path: 'inventory',
        loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule)
      },
      {
        path: 'paymentsystem',
        loadChildren: () => import('./payment-system/payment-system.module').then(m => m.PaymentSystemModule)
      },
      {
        path: 'productionsystem',
        loadChildren: () => import('./production-system/production-system.module').then(m => m.ProductionSystemModule)
      },
      {
        path: 'taxsystem',
        loadChildren: () => import('./tax-system/tax-system.module').then(m => m.TaxSystemModule)
      },
      {
        path: 'center',
        loadChildren: () => import('./center/center.module').then(m => m.CenterModule)
      },
      {
        path: 'asset',
        loadChildren: () => import('./asset/asset.module').then(m => m.AssetModule)
      },
      {
        path: 'open-api',
        loadChildren: () => import('./open-api/open-api.module').then(m => m.OpenApiModule)
      },
      {
        path: 'budgetsystem',
        loadChildren: () => import('./budget-system/budget-system.module').then(m => m.BudgetSystemModule)
      },
      {
        path: 'calendar',
        loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule)
      },
      {
        path: 'faq',
        loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule)
      },
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PageRoutingModule { }