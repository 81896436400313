import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ButtonGroupAlignment, ConnectedPositioningStrategy, GlobalPositionStrategy, HorizontalAlignment, IgxBannerComponent, NoOpScrollStrategy, OverlaySettings, VerticalAlignment } from "@infragistics/igniteui-angular";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  public authen: any;
  public alignmenthorizontal = ButtonGroupAlignment.horizontal;
  public alignmentvertical = ButtonGroupAlignment.vertical;
  public openBanner: boolean = false;
  public bannerText: any;
  public serviceStatus: any;
  public serviceAuth: any;
  public superAdmin = false;
  public userName: any;
  public companyName: any;
  public userInSessionStorage: any;
  public menuMasterModel: any;

  public userInLocalStorage: any;
  public perpetualMode: any;
  translate: TranslateService;
  @ViewChild(IgxBannerComponent, { static: true })
  public banner: IgxBannerComponent;
  constructor(translate: TranslateService, private _router: Router, private http: HttpClient) {
    this.serviceStatus = {
      Production: true,
      Inventory: true,
      Purchase: true,
      Sale: true,
      Payment: true,
      Receive: true,
      Financial: true,
      Account: true,
      SystemTax: true,
      SystemAsset: true,
      SystemBudget: true,
    };
    this.serviceAuth = {
      Production: false,
      Inventory: false,
      Purchase: false,
      Sale: false,
      Payment: false,
      Receive: false,
      Financial: false,
      Account: false,
      SystemTax: false,
      SystemAsset: false,
      SystemBudget: false,
    };
    this.translate = translate;
  }

  ngOnInit() {
    this.getSession();
    this.statusServiceCheck();
    this.getConfigulation();
    this.getMenuMaster();

  }

  public async getSession() {
    while (localStorage.getItem("info") == null) {
      await this.delay(50);
    }
    while (sessionStorage.getItem("session") == null) {
      await this.delay(50);
    }
    this.userInLocalStorage = JSON.parse(localStorage.getItem("info"));
    this.userInSessionStorage = JSON.parse(sessionStorage.getItem("session"));
    this.superAdmin = this.userInLocalStorage.superAdmin == "stradmin";
    this.userName = this.userInLocalStorage.username;
    this.companyName = this.userInSessionStorage.companyName;
    this.perpetualMode = "N";
    if (this.userInSessionStorage.companyPerpetual != undefined) {
      if (this.userInSessionStorage.companyPerpetual == "Y") {
        this.perpetualMode = "Y";
      }
    }
    // if(this.superAdmin){
    //   this.Inventory2.push({ text: "TransportTracking", navigation: "inventory/delivery-tracking" })
    // }
  }

  public statusServiceCheck() {
    this.http.get<any>("api/statusService/ProductionSystem").subscribe(
      (status) => {
        this.serviceStatus.Production = !status;
        if (status != true && status != false) {
          if (status.errno == "ECONNREFUSED" || status.code == "ECONNREFUSED") {
            this.serviceStatus.Production = true;
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
    this.http.get<any>("api/statusService/Inventory").subscribe(
      (status) => {
        this.serviceStatus.Inventory = !status;
        if (status != true && status != false) {
          if (status.errno == "ECONNREFUSED" || status.code == "ECONNREFUSED") {
            this.serviceStatus.Inventory = true;
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
    this.http.get<any>("api/statusService/Purchasesystem").subscribe(
      (status) => {
        this.serviceStatus.Purchase = !status;
        if (status != true && status != false) {
          if (status.errno == "ECONNREFUSED" || status.code == "ECONNREFUSED") {
            this.serviceStatus.Purchase = true;
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
    this.http.get<any>("api/statusService/Salesystem").subscribe(
      (status) => {
        this.serviceStatus.Sale = !status;
        if (status != true && status != false) {
          if (status.errno == "ECONNREFUSED" || status.code == "ECONNREFUSED") {
            this.serviceStatus.Sale = true;
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
    this.http.get<any>("api/statusService/Payment").subscribe(
      (status) => {
        this.serviceStatus.Payment = !status;
        if (status != true && status != false) {
          if (status.errno == "ECONNREFUSED" || status.code == "ECONNREFUSED") {
            this.serviceStatus.Payment = true;
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
    this.http.get<any>("api/statusService/Receive").subscribe(
      (status) => {
        this.serviceStatus.Receive = !status;
        if (status != true && status != false) {
          if (status.errno == "ECONNREFUSED" || status.code == "ECONNREFUSED") {
            this.serviceStatus.Receive = true;
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
    this.http.get<any>("api/statusService/Financial").subscribe(
      (status) => {
        this.serviceStatus.Financial = !status;
        if (status != true && status != false) {
          if (status.errno == "ECONNREFUSED" || status.code == "ECONNREFUSED") {
            this.serviceStatus.Financial = true;
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
    this.http.get<any>("api/statusService/Account").subscribe(
      (status) => {
        this.serviceStatus.Account = !status;
        if (status != true && status != false) {
          if (status.errno == "ECONNREFUSED" || status.code == "ECONNREFUSED") {
            this.serviceStatus.Account = true;
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
    this.http.get<any>("api/statusService/SystemTax").subscribe(
      (status) => {
        this.serviceStatus.SystemTax = !status;
        if (status != true && status != false) {
          if (status.errno == "ECONNREFUSED" || status.code == "ECONNREFUSED") {
            this.serviceStatus.SystemTax = true;
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
    this.http.get<any>("api/statusService/SystemAsset").subscribe(
      (status) => {
        this.serviceStatus.SystemAsset = !status;
        if (status != true && status != false) {
          if (status.errno == "ECONNREFUSED" || status.code == "ECONNREFUSED") {
            this.serviceStatus.SystemAsset = true;
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  public async getConfigulation() {
    while (sessionStorage.getItem("session") == null) {
      await this.delay(50);
    }
    this.http
      .post<any>("api/center/Company/getById", {
        companyCode: this.userInSessionStorage.companyCode,
      })
      .subscribe(
        (data) => {
          if (data[0] != undefined) {
            if (data[0].broadcastStatus) {
              this.banner.open();
              this.bannerText = data[0].broadcastMessage;
            } else {
              this.banner.close();
              this.bannerText = "";
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  public Production1: Array<{ text: string; navigation: string }> = [
    { text: "Process", navigation: "productionsystem/product-procress" },
    { text: "ProcessDetailAndBOM", navigation: "productionsystem/product-procress-detail" },
    { text: "ErectionWorks", navigation: "productionsystem/erection-work" },
    { text: "SetupQc", navigation: "productionsystem/qc-method" },
    { text: "SetupQcInspection", navigation: "productionsystem/qc-inspect" },
    { text: "SetupProductionUnit", navigation: "productionsystem/production-unit" },
    { text: "EstimateProductionPlan", navigation: "productionsystem/estimate-production-plan" },
    { text: "DefectCodeSetup", navigation: "productionsystem/defect-code-setup" }
  ];

  public Production2: Array<{ text: string; navigation: string }> = [
    { text: "JobManufacturingOrder", navigation: "productionsystem/job-bom" },
    { text: "RequestProductAssembly", navigation: "productionsystem/request-product-assembly" },
    { text: "RequestProductAssemblyPreparerm", navigation: "productionsystem/request-product-assembly-preparerm" },
    { text: "RequestInvProdMFG", navigation: "productionsystem/product-assembly" },
    { text: "RequestProductAssemblyReceive", navigation: "productionsystem/request-product-assembly-receive" },
    { text: "QcDoc", navigation: "productionsystem/qc-doc" },
    { text: "ReceiptProdMFG", navigation: "productionsystem/product-assembly-receive" },
    { text: "Material Requirement Planing", navigation: "productionsystem/mrp" },
    { text: "Master Production Plan", navigation: "calendar/master-production-planning" },
  ];

  public Production3: Array<{ text: string; navigation: string }> = [
    { text: "JobBoq", navigation: "productionsystem/job-boq" },
    { text: "RequestProductAssemblyBoq", navigation: "productionsystem/request-product-assembly-boq" },
    { text: "RequestInvProdBoq", navigation: "productionsystem/product-assembly-boq" },
    { text: "SubJob", navigation: "productionsystem/sub-job" },
    { text: "BOQ", navigation: "setup/boqs/boq" },
    { text: "ProjectPlan", navigation: "setup/boqs/project-plan" },
  ];

  public Inventory: Array<{ text: string; navigation: string }> = [
    { text: "CheckStock", navigation: "inventory/check-stock" },
    { text: "ProductAdjustInvoice", navigation: "inventory/product-adjust-invoice" },
    { text: "RequestWarehouseAdjusting", navigation: "inventory/request-warehouse-adjusting" },
    { text: "WarehouseTransferringInvoice", navigation: "inventory/warehouse-adjusting" },
    { text: "ProductRequest", navigation: "inventory/product-request" },
    { text: "ProductTransfers", navigation: "inventory/product-transfers" },
    { text: "ProductRecipt", navigation: "inventory/product-recipt" },
    { text: "LotNo", navigation: "inventory/lot-master" },
    { text: "Serial", navigation: "inventory/serial" },
  ];

  public Inventory2: Array<{ text: string; navigation: string }> = [
    { text: "RequestInternalIssueInvoice", navigation: "inventory/request-internal-issue" },
    { text: "InternalIssueInvoice", navigation: "inventory/internal-issue" },
    { text: "RequestInternalReceive", navigation: "inventory/request-internal-receive" },
    { text: "InternalReceive", navigation: "inventory/internal-receive" },
    { text: "packingList", navigation: "inventory/packing-list" },
    { text: "TransportTracking", navigation: "inventory/delivery-tracking" },
    { text: "Packaging", navigation: "inventory/packaging" }
  ];

  public Purchase1: Array<{ text: string; navigation: string }> = [
    { text: "PurchaseRequest", navigation: "purchasesystem/purchase-request-invoice" },
    { text: "POPURCHASE", navigation: "purchasesystem/purchase-order" },
    { text: "QcReceiveProduct", navigation: "purchasesystem/qc-receive-product" },
    { text: "ProductReceivingDocument", navigation: "purchasesystem/product-receive-invoice" },
    { text: "CashPurchase", navigation: "purchasesystem/cash-product-purchase" },
  ];

  public Purchase2: Array<{ text: string; navigation: string }> = [
    { text: "DepositPayment", navigation: "purchasesystem/deposit-payment-invoice" },
    { text: "PurchaseRequestService", navigation: "purchasesystem/purchase-request-service" },
    { text: "PurchaseOrderService", navigation: "purchasesystem/purchase-order-service" },
    { text: "ServiceReceiving", navigation: "purchasesystem/service-receiving" },
    { text: "CashServicePurchase", navigation: "purchasesystem/cash-service-purchase" },
  ];

  public Sale1: Array<{ text: string; navigation: string }> = [
    { text: "QuotationProduct", navigation: "salesystem/quotation-product" },
    { text: "SaleOrderProduct", navigation: "salesystem/sale-order-product" },
    { text: "DepositReceiveDocument", navigation: "salesystem/deposit-receive-invoice" },
    { text: "DeliveryOrderTaxInvoice", navigation: "salesystem/delivery-order-invoice" },
    { text: "DeliveryOrder", navigation: "salesystem/delivery-order" },
    { text: "TaxInvoice", navigation: "salesystem/invoice" },
    { text: "CashProductSale", navigation: "salesystem/cash-sale" },
    { text: "SetupDebtCustomer", navigation: "salesystem/setup-dept-customer" },
    { text: "Sale Leads", navigation: "salesystem/sale-lead" },
  ];

  public Sale2: Array<{ text: string; navigation: string }> = [
    { text: "QuotationService", navigation: "salesystem/quotation-service" },
    { text: "SaleOrderService", navigation: "salesystem/sale-order-service" },
    { text: "ServiceBillingNote", navigation: "salesystem/service-billing-note" },
    { text: "FN06", navigation: "salesystem/rct-inv-service" },
    { text: "CashServiceSale", navigation: "salesystem/cash-service-sale" },
    { text: "SaleConsign", navigation: "salesystem/sale-consign" },
    { text: "ServiceJobManagement", navigation: "salesystem/service-job-management" },
    { text: "SO21", navigation: "salesystem/sale-order-product-ecommerce" },
  ];

  public Sale3: Array<{ text: string; navigation: string }> = [
    { text: "QuotationService", navigation: "salesystem/quotation-service" },
    { text: "SaleOrderService", navigation: "salesystem/sale-order-service" },
    { text: "ServiceBillingNote", navigation: "salesystem/service-billing-note" },
    { text: "FN06", navigation: "salesystem/rct-inv-service" },
    { text: "CashServiceSale", navigation: "salesystem/cash-service-sale" },
    { text: "SaleConsign", navigation: "salesystem/sale-consign-perpetual" },
    { text: "ServiceJobManagement", navigation: "salesystem/service-job-management" },
  ];

  public PaymentSystem1: Array<{ text: string; navigation: string }> = [
    { text: "PurchaseRecord", navigation: "paymentsystem/purchase-record" },
    { text: "BILLINGRECEIVE", navigation: "paymentsystem/billing-payment" },
    { text: "CreditNotefromSupplier", navigation: "paymentsystem/credit-note-from-supplier" },
    { text: "DebitNoteFromSupplier", navigation: "paymentsystem/debit-note-from-supplier" },
    { text: "PREPAREPAYMENTVOUCHER", navigation: "paymentsystem/prepare-payment-voucher" },
    { text: "AG19", navigation: "paymentsystem/payment-voucher" },
  ];

  public PaymentSystem2: Array<{ text: string; navigation: string }> = [
    { text: "PettyCash&ExpenseGenaral", navigation: "paymentsystem/petty-cash" },
    { text: "Otherexpanses&SetupDebtSupplier", navigation: "paymentsystem/other-expense" },
    { text: "PCreceived", navigation: "paymentsystem/petty-cash-receive" },
    { text: "AdvancePayment2", navigation: "paymentsystem/advance-payment" },
    { text: "AdvanceClearing", navigation: "paymentsystem/advance-clearing" },
  ];

  public ReceiveSystem: Array<{ text: string; navigation: string }> = [
    { text: "BILLINGORDER", navigation: "receive/billing-note" },
    { text: "CN", navigation: "receive/credit-note" },
    { text: "DebitNoteFromCustomer", navigation: "receive/debit-note" },
    { text: "Receipt", navigation: "receive/receipt" },
    { text: "ReceiveVoucher", navigation: "receive/receive-voucher" },
    { text: "OtherReceive", navigation: "receive/other-receive" },
  ];

  public FinancialSystem1: Array<{ text: string; navigation: string }> = [
    { text: "RCVCHQ", navigation: "finance/receive-cheque" },
    { text: "RegisterCheque", navigation: "finance/register-cheque" },
    { text: "BankTransfer", navigation: "finance/financial" },
  ];

  public FinancialSystem2: Array<{ text: string; navigation: string }> = [
    { text: "PaymentCheque", navigation: "finance/pay-cheque" },
    { text: "ReceiveCreditCard", navigation: "finance/receive-credit-card" },
    // { text: "PaymentChequeOnhand", navigation: "PaymentChequeOnhand" },
    { text: "BankStatement", navigation: "finance/bank-statement" },
  ];

  public AccountSystem1: Array<{ text: string; navigation: string }> = [
    { text: "VoucherBook", navigation: "account/voucher-book" },
    { text: "AdjustProductValue", navigation: "account/adjust-product-value" },
    { text: "AdjustProductionCost", navigation: "account/adjust-production-cost" },
    /* { text: "AdjustCostProduction", navigation: "productionsystem/adjust-cost-production" }, */
  ];

  public AccountSystem1ForPerpetual: Array<{ text: string; navigation: string }> = [
    { text: "VoucherBook", navigation: "account/voucher-book" },
  ];

  /*  public AccountSystem2: Array<{ text: string, navigation: string }> = [
     { text: "VoucherReturn", navigation: "VoucherReturn" },
   ]; */

  public SystemTax1: Array<{ text: string; navigation: string }> = [
    { text: "TaxBuyAmount", navigation: "report/revenuedepartmentreport/report-input-vat", },
    { text: "TaxSellAmount", navigation: "report/revenuedepartmentreport/report-output-vat" },
    { text: "RRD17", navigation: "report/revenuedepartmentreport/rrd17" },
    { text: "RRD18", navigation: "report/revenuedepartmentreport/rrd18" },
  ];

  public SystemTax2: Array<{ text: string; navigation: string }> = [
    { text: "DetailWithHolding", navigation: "taxsystem/payment-voucher-wht" },
    { text: "BPND1A", navigation: "report/revenuedepartmentreport/bpnd1-g" },
    { text: "PND1", navigation: "report/revenuedepartmentreport/pnd1" },
    { text: "PND1A", navigation: "report/revenuedepartmentreport/pnd1-g" },
    { text: "PND2", navigation: "report/revenuedepartmentreport/pnd2" },
    { text: "PND2G", navigation: "report/revenuedepartmentreport/pnd2-g" },
    { text: "PND3", navigation: "report/revenuedepartmentreport/pnd3" },
    { text: "PND53", navigation: "report/revenuedepartmentreport/pnd53" },
    { text: "Vat54Type", navigation: "taxsystem/payment-voucher-wht54" },
  ];

  public SystemTax3: Array<{ text: string; navigation: string }> = [
    // { text: "บันทึก ภ.ง.ด 1", navigation: "บันทึก ภ.ง.ด 1" },
    // { text: "SaveSSO", navigation: "SaveSSO" },
    // { text: "SocialInsuranceFund", navigation: "report/revenuedepartmentreport/social-insurance-fund" },
    { text: "TaxReturn", navigation: "taxsystem/tax-return-list" },
    { text: "SummaryTax", navigation: "report/revenuedepartmentreport/summary-tax" },
  ];

  public SystemAsset: Array<{ text: string; navigation: string }> = [
    { text: "AssetGroup", navigation: "asset/asset-group" },
    { text: "AssetCategoryName", navigation: "asset/asset-category" },
    { text: "AssetLocation", navigation: "asset/asset-location" },
    { text: "AssetRegister", navigation: "asset/asset-register" },
    { text: "UpdateAsset", navigation: "asset/update-asset" },
    { text: "CalculateDepreciation", navigation: "asset/calculate-depreciation" },
    { text: "AssetTransfer", navigation: "asset/asset-transfer" },
    { text: "AssetSale", navigation: "asset/asset-sale" },
  ];

  public SystemBudget: Array<{ text: string; navigation: string }> = [
    { text: "BudgetSource", navigation: "budgetsystem/budget-source" },
    { text: "BudgetSetup", navigation: "budgetsystem/budget-setup" },
    { text: "BudgetCompare", navigation: "budgetsystem/budget-compare" },
  ];

  public SystemSearch: Array<{ text: string; navigation: string }> = [
    { text: "PriceSale", navigation: "center/productprice" },
    { text: "SaleSystem-ReceiveSystem", navigation: "center/salesystemreceivesystem" },
    { text: "PurchaseSystem-PaymentSystem", navigation: "center/purchasesystempaymentsystem" },
    { text: "ProduceSystem", navigation: "center/production-system" },
  ];

  public AllPage: Array<{ text: string; navigation: string }> = [
    { text: "ติดตั้งกระบวนการผลิต", navigation: "productionsystem/product-procress" },
    { text: "ติดตั้งรายละเอียดกระบวนการผลิต", navigation: "productionsystem/product-procress-detail" },
    { text: "ติดตั้งเครื่องจักร", navigation: "productionsystem/erection-work" },
    { text: "Setup QC", navigation: "productionsystem/qc-method" },
    { text: "Setup QC Inspection", navigation: "productionsystem/qc-inspect" },
    { text: "ใบสั่งงานผลิตทั่วไป", navigation: "productionsystem/job-bom" },
    { text: "ใบเบิกเพื่อการผลิต", navigation: "productionsystem/product-assembly" },
    { text: "ใบรับของจากการผลิต", navigation: "productionsystem/product-assembly-receive" },
    { text: "Link IoT", navigation: "LinkIOT" },
    { text: "[CK] ตรวจนับสินค้า", navigation: "inventory/check-stock" },
    { text: "[AJP] ใบปรับปรุงยอดสินค้า", navigation: "inventory/product-adjust-invoice" },
    { text: "[WTP] ใบโอนสินค้าระหว่างคลัง", navigation: "inventory/warehouse-adjusting" },
    { text: "[DOD] ใบส่งของระหว่างสาขา", navigation: "inventory/product-transfers" },
    { text: "[ROD] ใบรับของระหว่างสาขา", navigation: "inventory/product-recipt" },
    { text: "[ISP] ใบเบิกใช้ภายใน", navigation: "inventory/internal-issue" },
    { text: "[IRP] ใบรับสินค้าภายใน", navigation: "inventory/internal-receive" },
    { text: "Lot No", navigation: "LotNo" },
    { text: "[PR] ใบขอซื้อ", navigation: "purchasesystem/purchase-request-invoice" },
    { text: "[PO] ใบสั่งซื้อ", navigation: "purchasesystem/purchase-order" },
    { text: "[DP] ใบมัดจำจ่าย", navigation: "purchasesystem/deposit-payment-invoice" },
    { text: "[PRV] ใบรับสินค้า", navigation: "purchasesystem/product-receive-invoice" },
    { text: "[CP] ซื้อสินค้าสด", navigation: "purchasesystem/cash-product-purchase" },
    { text: "[PBM] ใบตั้งหนี้ผู้จำหน่าย", navigation: "paymentsystem/other-expense" },
    { text: "[SPR] ใบขอซื้อบริการ", navigation: "purchasesystem/purchase-request-service" },
    { text: "[SPO] ใบสั่งซื้อบริการ", navigation: "purchasesystem/purchase-order-service" },
    { text: "[SRCV] ใบรับบริการ", navigation: "purchasesystem/service-receiving" },
    { text: "[CAP] ซื้อบริการสด", navigation: "purchasesystem/cash-service-purchase" },
    { text: "[QU] ใบเสนอราคา (สินค้า)", navigation: "salesystem/quotation-product" },
    { text: "[SO] ใบสั่งขาย (สินค้า)", navigation: "salesystem/sale-order-product" },
    { text: "[DR] ใบมัดจำรับ", navigation: "salesystem/deposit-receive-invoice" },
    { text: "[DOI] ใบส่งของ/ ใบกำกับภาษี", navigation: "salesystem/delivery-order-invoice" },
    { text: "[DO] ใบส่งของ", navigation: "salesystem/delivery-order" },
    { text: "[INV] ใบกำกับภาษี", navigation: "salesystem/invoice" },
    { text: "[POS] ขายสินค้าสด", navigation: "salesystem/cash-sale" },
    { text: "[OM] ใบตั้งหนี้ลูกค้า", navigation: "salesystem/setup-dept-customer" },
    { text: "Sale Leads", navigation: "salesystem/sale-lead" },
    { text: "[QT] ใบเสนอราคา (บริการ)", navigation: "salesystem/quotation-service" },
    { text: "[SOP] ใบสั่งขาย (บริการ)", navigation: "salesystem/sale-order-service" },
    { text: "[BNS] ใบแจ้งหนี้ (บริการ)", navigation: "salesystem/service-billing-note" },
    { text: "[RS] ใบเสร็จรับเงิน/ใบกำกับภาษี(บริการ)", navigation: "salesystem/rct-inv-service" },
    { text: "[CSRV] ขายบริการสด", navigation: "salesystem/cash-service-sale" },
    { text: "[VP] บันทึกบัญชีซื้อ", navigation: "paymentsystem/purchase-record" },
    { text: "[BP] ใบรับวางบิล", navigation: "BILLINGRECEIVE" },
    { text: "[CNS] ใบลดหนี้จากผู้จำหน่าย", navigation: "paymentsystem/credit-note-from-supplier" },
    { text: "[DNS] ใบเพิ่มหนี้จากผู้จำหน่าย", navigation: "paymentsystem/debit-note-from-supplier" },
    { text: "[PV] ใบสำคัญจ่าย", navigation: "paymentsystem/payment-voucher" },
    { text: "[PC/OEG] เงินสดย่อย / ค่าใช้จ่ายทั่วไป", navigation: "paymentsystem/petty-cash" },
    { text: "[OE/PBM] ค่าใช้จ่ายอื่น / ใบตั้งหนี้ผู้จำหน่าย", navigation: "paymentsystem/other-expense" },
    { text: "[PCR] รับเงินสดย่อย / PC Received", navigation: "PCreceived" },
    { text: "Advance Payment", navigation: "AdvancePayment2" },
    { text: "Advance Clearing", navigation: "AdvanceClearing" },
    { text: "[BL] ใบวางบิล", navigation: "receive/billing-note" },
    { text: "[CN] ใบลดหนี้จากลูกค้า", navigation: "receive/credit-note" },
    { text: "[DN] ใบเพิ่มหนี้จากลูกค้า", navigation: "DebitNoteFromCustomer" },
    { text: "[RC] ใบเสร็จรับเงิน", navigation: "receive/receipt" },
    { text: "[RV] ใบสำคัญรับ", navigation: "receive/receive-voucher" },
    { text: "[OR] รายรับอื่นๆ", navigation: "OtherReceive" },
    { text: "[RCHQ] เช็ครับ", navigation: "RCVCHQ" },
    { text: "[RCHQ/PCHQ] ลงทะเบียนเช็ค", navigation: "finance/register-cheque" },
    { text: "[BK] โอนเงินธนาคาร", navigation: "BankTransfer" },
    { text: "[PCHQ] เช็คจ่าย", navigation: "PaymentCheque" },
    { text: "เช็คจ่ายคงค้างในมือ", navigation: "PaymentChequeOnhand" },
    { text: "สมุดบัญชี", navigation: "account/voucher-book" },
    { text: "[OP] สมุดเปิดบัญชี", navigation: "account/voucher-book" },
    { text: "ใบปิดงวดบัญชี", navigation: "ClosingPeriod" },
    { text: "อนุมัติเอกสารบัญชี", navigation: "ApproveAccountDoc" },
    { text: "กลับรายการบัญชี", navigation: "VoucherReturn" },
    { text: "เชื่อมข้อมูลจากระบบโรงแรม", navigation: "ConnectHotel" },
    { text: "ภาษีมูลค่าเพิ่มภาษีซื้อ", navigation: "report/revenuedepartmentreport/report-input-vat" },
    { text: "ภาษีมูลค่าเพิ่มภาษีขาย", navigation: "report/revenuedepartmentreport/report-output-vat" },
    { text: "RRD17", navigation: "report/revenuedepartmentreport/rrd17" },
    { text: "รายละเอียดภาษีหัก ณ ที่จ่าย", navigation: "taxsystem/payment-voucher-wht" },
    { text: "หนังสือรับรอง ภ.ง.ด 1ก", navigation: "report/revenuedepartmentreport/bpnd1-g" },
    { text: "ภ.ง.ด 1", navigation: "report/revenuedepartmentreport/pnd1" },
    { text: "ภ.ง.ด 1ก", navigation: "report/revenuedepartmentreport/pnd1-g" },
    { text: "ภ.ง.ด 2", navigation: "report/revenuedepartmentreport/pnd2" },
    { text: "ภ.ง.ด 2ก", navigation: "report/revenuedepartmentreport/pnd2-g" },
    { text: "ภ.ง.ด 3", navigation: "report/revenuedepartmentreport/pnd3" },
    { text: "ภ.ง.ด 53", navigation: "report/revenuedepartmentreport/pnd53" },
    { text: "บันทึก ภ.ง.ด 1", navigation: "บันทึก ภ.ง.ด 1" },
    { text: "บันทึกจ่ายประกันสังคม", navigation: "SaveSSO" },
    { text: "การส่งเงินสมทบประกันสังคม", navigation: "SocialInsuranceFund" },
    { text: "ภาษีซื้อรอขอคืน", navigation: "TaxReturn" },
    { text: "สรุปยื่นภาษี", navigation: "report/revenuedepartmentreport/summary-tax" },
    { text: "กลุ่มสินทรัพย์", navigation: "AssetGroup" },
    { text: "ที่ตั้งสินทรัพย์", navigation: "AssetLocation" },
    { text: "หมวดหมู่สินทรัพย์", navigation: "AssetCategoryName" },
    { text: "ทะเบียนสินทรัพย์", navigation: "AssetRegister" },
    { text: "คำนวณค่าเสื่อม", navigation: "CalculateDepreciation" },
    { text: "รายงานตัดจำหน่ายทรัพย์สิน", navigation: "AssetSale" },
    { text: "ภาพรวมกิจการ", navigation: "setup/management-dashboard" },
    { text: "ข้อมูลการขาย", navigation: "setup/sale-dashboard" },
    { text: "ข้อมูลการซื้อ", navigation: "setup/procurement-dashboard" },
    { text: "ข้อมูลการเงิน", navigation: "setup/finance-dashboard" },
    { text: "ข้อมูลการผลิต", navigation: "setup/production-dashboard" },
    { text: "รายงานข้อมูลแผนก", navigation: "report/companyprofilereport/report-section" },
    { text: "รายงานลำดับเลขที่เอกสาร", navigation: "report/setupprofilereport/report-running-number" },
    { text: "รายงานรหัสพนักงาน", navigation: "report/companyprofilereport/report-employee" },
    { text: "Business Connect", navigation: "support/business-connect" },
    { text: "Folder", navigation: "support/Folder" },
    { text: "รายงานรหัสบัญชี", navigation: "report/setupprofilereport/report-account-code" },
    { text: "รายงานรหัสสินค้า", navigation: "report/setupprofilereport/report-product-code" },
    { text: "รายงานคลังสินค้า", navigation: "report/setupprofilereport/report-warehouse-code" },
    { text: "รายงานรหัสบริการ", navigation: "report/setupprofilereport/report-service-code" },
    { text: "รายงานรหัสลูกค้า", navigation: "report/setupprofilereport/report-customer-code" },
    { text: "รายงานรหัสผู้จำหน่าย", navigation: "report/setupprofilereport/report-supplier" },
    { text: "รายงานข้อมูลสมุดเงินฝาก", navigation: "report/setupprofilereport/report-deposit-book-profile" },
    { text: "รายงานรหัสงาน", navigation: "report/setupprofilereport/report-job-code" },
    { text: "รายงานงานประกอบ", navigation: "report/setupprofilereport/report-job-bom" },
    { text: "รายงานภาษีขาย", navigation: "report/revenuedepartmentreport/report-output-vat" },
    { text: "รายงานภาษีซื้อ", navigation: "report/revenuedepartmentreport/report-input-vat" },
    { text: "รายงานภาษีซื้อรอขอคืน", navigation: "report/revenuedepartmentreport/report-tax-return" },
    { text: "รายงานภาษีซื้อรอเรียกเก็บ", navigation: "report/revenuedepartmentreport/report-tax-return-waiting" },
    { text: "รายงานภ.ง.ด.3", navigation: "report/revenuedepartmentreport/pnd3" },
    { text: "รายงานภ.ง.ด.53", navigation: "report/revenuedepartmentreport/pnd53" },
    { text: "รายงานภ.ง.ด.1", navigation: "report/revenuedepartmentreport/pnd1" },
    { text: "รายงานภ.ง.ด.1ก.", navigation: "report/revenuedepartmentreport/pnd1-g" },
    { text: "รายงานภ.พ.30", navigation: "report/revenuedepartmentreport/rrd17" },
    { text: "รายงานภ.ง.ด.2", navigation: "report/revenuedepartmentreport/pnd2" },
    { text: "รายงานภ.ง.ด.2ก", navigation: "report/revenuedepartmentreport/pnd2-g" },
    { text: "รายงานลูกหนี้คงค้าง", navigation: "report/receivablereport/account-receivable-balance-report" },
    { text: "รายงานการ์ดลูกหนี้", navigation: "report/receivablereport/rar04-report" },
    { text: "รายงานวิเคราะห์อายุลูกหนี้", navigation: "report/receivablereport/rar13-report" },
    { text: "รายงานเจ้าหนี้คงค้าง", navigation: "report/payablereport/account-payable-balance-report" },
    { text: "รายงานการ์ดเจ้าหนี้", navigation: "report/payablereport/rap11-report" },
    { text: "รายงานวิเคราะห์อายุเจ้าหนี้", navigation: "report/payablereport/rap12-report" },
    { text: "รายงานสรุปใบขอซื้อแบบแสดงรายละเอียดสินค้า", navigation: "report/purchasereport/summary-purchase-request-product-report" },
    { text: "รายงานสรุปใบสั่งซื้อ", navigation: "report/purchasereport/summary-purchase-order-report" },
    { text: "รายงานสินค้าคงค้างตามใบสั่งซื้อ", navigation: "report/purchasereport/product-remainig-purchase-order-report" },
    { text: "รายงานสรุปใบรับสินค้า", navigation: "report/purchasereport/summary-product-receiving-document-report" },
    { text: "รายงานสรุปการซื้อสินค้าสด", navigation: "report/purchasereport/cash-purchase-report" },
    { text: "รายงานสรุปใบมัดจำจ่าย", navigation: "report/purchasereport/summary-deposit-payment-document-report" },
    { text: "รายงานสรุปใบรับบริการ", navigation: "report/purchasereport/summary-receiveing-service-report" },
    { text: "รายงานวางแผนการสั่งซื้อสินค้าสำเร็จรูป", navigation: "report/purchasereport/item-status-report" },
    { text: "รายงานวางแผนการสั่งซื้อวัตถุดิบเพื่อผลิต", navigation: "report/purchasereport/bom-order-report" },
    { text: "รายงานสรุปใบเสนอราคา (สินค้า)", navigation: "report/salereport/summary-quotation-product-report" },
    { text: "รายงานสรุปใบสั่งขาย", navigation: "report/salereport/summary-sale-order-report" },
    { text: "รายงานสรุปใบส่งสินค้า/ ใบกำกับภาษี", navigation: "report/salereport/summary-delivery-order-tax-invoice-report" },
    { text: "รายงานสรุปใบเสนอราคาบริการ", navigation: "report/salereport/summary-service-quotation-report" },
    { text: "รายงานสรุปใบสั่งขายบริการ", navigation: "report/salereport/summary-sale-order-service-report" },
    { text: "รายงานสรุปใบแจ้งหนี้บริการ", navigation: "report/salereport/summary-service-billing-note-report" },
    { text: "รายงานสรุปใบเสร็จรับเงิน/ใบกำกับภาษี", navigation: "report/salereport/summary-receipt-tax-invoice-service-report" },
    { text: "รายงานสรุปใบมัดจำรับ", navigation: "report/salereport/summary-deposit-receive-report" },
    { text: "รายงานสรุปการรับชำระจากการขายสด", navigation: "report/salereport/sale-receive-report" },
    { text: "รายงานต้นทุนการขนส่ง", navigation: "report/salereport/service-cost-report" },
    { text: "รายงานสรุป Flow ใบเสนอราคา", navigation: "report/salereport/qu-flow-report" },
    { text: "รายงานสรุปใบรับวางบิล", navigation: "report/financereport/summary-billing-payment-report" },
    { text: "รายงานสรุปใบสำคัญจ่าย", navigation: "report/financereport/summary-payment-voucher-report" },
    { text: "ใบสำคัญการเบิกชดเชยเงินสดย่อย", navigation: "report/financereport/reimbursement-petty-cash" },
    { text: "รายงานสรุปวงจรซื้อ-จ่ายชำระ", navigation: "report/financereport/summary-purchase-payment-system-by-supplier-report" },
    { text: "รายงานสรุปใบลดหนี้จากผู้จำหน่าย", navigation: "report/financereport/summary-credit-note-from-supplier-report" },
    { text: "รายงานสรุปเงินสดย่อย", navigation: "report/financereport/summary-petty-cash-report" },
    { text: "รายงานความเคลื่อนไหวการจ่ายเงินสดย่อย", navigation: "report/financereport/condition-print" },
    { text: "รายงานสรุปบันทึกบัญชีซื้อ", navigation: "report/financereport/rfn52" },
    { text: "รายงานสรุปค่าใช้จ่ายอื่นๆ", navigation: "report/financereport/rfn54" },
    { text: "รายงานสรุปค่าใช้จ่ายทั่วไป", navigation: "report/financereport/rfn55" },
    { text: "รายงานการโอนเงินธนาคาร", navigation: "report/financereport/rfn58" },
    { text: "รายงานการโอนเงินธนาคาร", navigation: "report/financereport/income-report" },
    { text: "รายงาน Advance Payment", navigation: "report/financereport/ar-report" },
    { text: "รายงานสรุปใบวางบิล", navigation: "report/financereport/summary-billing-note-report" },
    { text: "รายงานใบวางบิลคงค้าง", navigation: "report/financereport/outstanding-billing-report" },
    { text: "รายงานสรุปใบลดหนี้", navigation: "report/financereport/summary-credit-note-report" },
    { text: "รายงานสรุปใบเสร็จรับเงิน", navigation: "report/financereport/summary-receipt-report" },
    { text: "รายงานสรุปใบเสร็จรับเงิน/ใบกำกับภาษี", navigation: "report/financereport/summary-receipt-tax-invoice-report" },
    { text: "รายงานสรุปใบสำคัญรับ", navigation: "report/financereport/summary-receive-voucher-report" },
    { text: "รายงานสรุปใบรายรับอื่น", navigation: "report/financereport/summary-total-amount-for-other-revenue-report" },
    { text: "รายงานสรุปวงจรขาย-รับชำระ", navigation: "report/financereport/summary-sale-receive-system-report" },
    { text: "รายงานใบปรับปรุงยอดสินค้า", navigation: "report/inventoryreport/product-adjust-invoice-report" },
    { text: "รายงานสรุปมูลค่าสินค้าคงเหลือ", navigation: "report/inventoryreport/inventory-summary-value-report" },
    { text: "รายงานวิเคราะห์อายุสินค้า", navigation: "report/inventoryreport/product-aging-report" },
    { text: "รายงานมูลค่าสินค้าคงเหลือไม่แสดงราคา", navigation: "report/inventoryreport/inventory-summary-value-no-price-report" },
    { text: "รายงานรับจ่ายสินค้าและวัตถุดิบ", navigation: "report/inventoryreport/summary-product-purchase-report" },
    { text: "รายงานสรุปความเคลื่อนไหวของสินค้า-วัตถุดิบ", navigation: "report/inventoryreport/product-movement-report" },
    { text: "รายงานใบส่งของระหว่างสาขา", navigation: "report/inventoryreport/summary-internalissue-delivery-report" },
    { text: "รายงานใบรับของระหว่างสาขา", navigation: "report/inventoryreport/product-return-master-report" },
    { text: "รายงานใบโอนสินค้าระหว่างคลัง", navigation: "report/inventoryreport/warehouse-transferring-invoice-report" },
    { text: "รายงานความเคลื่อนไหววัตถุดิบรายเดือน", navigation: "report/inventoryreport/raw-mat-movement-by-month-report" },
    { text: "รายงานสรุปความเคลื่อนไหวสินค้าแยกตามลูกค้า", navigation: "report/inventoryreport/summary-product-movement-customer-report" },
    { text: "รายงาน Lot สินค้า", navigation: "report/inventoryreport/product-lot-report" },
    { text: "รายงานสรุปความเคลื่อนไหว Serial", navigation: "report/inventoryreport/serial-movement-report" },
    { text: "รายงานใบเบิกเพื่อผลิต", navigation: "report/productionreport/product-complete-receive-report" },
    { text: "รายงานใบรับจากการผลิต", navigation: "report/productionreport/receive-from-mfg-report" },
    { text: "รายงานสรุปยอดสั่งผลิต", navigation: "report/productionreport/saleorder-to-mfg-report" },
    { text: "รายงานสรุปจำนวนวัตถุดิบที่ใช้ในการผลิตและจำนวนที่ต้องสั่งซื้อ", navigation: "report/productionreport/rawmat-in-proces-and-reorder-report" },
    { text: "รายงานสรุปใบสั่งผลิต", navigation: "report/productionreport/job-order-mfg-report" },
    { text: "รายงานต้นทุนการผลิต", navigation: "report/productionreport/production-costs-report" },
    { text: "รายงานสินค้าคงค้างตามใบแจ้งผลิต", navigation: "report/productionreport/product-remaining-on-production-report" },
    { text: "รายงานสรุปต้นทุนทางอ้อม", navigation: "report/productionreport/indirect-job-cost-report" },
    { text: "รายงานสรุปต้นทุนประมาณการ", navigation: "report/productionreport/product-est-cost-report" },
    { text: "รายงานสรุป Work In Process", navigation: "report/productionreport/work-in-process-report" },
    { text: "รายงานกระบวนการผลิต", navigation: "report/productionreport/process-report" },
    { text: "รายงานวางแผนการผลิต", navigation: "report/productionreport/job-plan-report" },
    { text: "รายงานสรุปใบเบิก-รับจากการผลิตตามใบสั่งงาน", navigation: "report/productionreport/print-job-code-report" },
    { text: "รายงานสมุดรายวันทั่วไป", navigation: "report/accountreport/voucher-book-report" },
    { text: "รายงานสมุดขายสด", navigation: "report/accountreport/CashSaleBook" },
    { text: "รายงานสมุดขายเชื่อ", navigation: "report/accountreport/CreditSaleBook" },
    { text: "รายงานสมุดซื้อเชื่อ", navigation: "report/accountreport/CreditPurchaseBook" },
    { text: "รายงานสมุดแยกประเภท", navigation: "report/accountreport/accountreport/general-ledger" },
    { text: "รายงานสมุดเงินสดรับ", navigation: "report/accountreport/CashReceiveBook" },
    { text: "รายงานสมุดธนาคารรับ", navigation: "report/accountreport/BankReceiveBook" },
    { text: "รายงานสมุดเงินสดจ่าย", navigation: "report/accountreport/CashPaymentBook" },
    { text: "รายงานสมุดธนาคารจ่าย", navigation: "report/accountreport/BankPaymentBook" },
    { text: "รายงานรวมสมุดรายวัน", navigation: "report/accountreport/AllAccountBook" },
    { text: "รายงานใบปะหน้า", navigation: "report/accountreport/voucher-report" },
    { text: "รายงานงบทดลอง", navigation: "report/accountreport/trial-balance" },
    { text: "รายงานกระดาษทำการ", navigation: "report/accountreport/working-paper" },
    { text: "รายงานงบกำไรขาดทุน", navigation: "report/accountreport/income-statement-report" },
    { text: "รายงานงบแสดงฐานะการเงิน", navigation: "report/accountreport/rac27" },
    { text: "รายงานงบแสดงฐานะการเงินแยกตามประเภท", navigation: "report/accountreport/statement-of-financial-clissification" },
    { text: "รายงานตรวจสอบการลงบัญชี", navigation: "report/accountreport/rac30" },
    { text: "รายงานงบกระแสเงินสด", navigation: "report/accountreport/cash-flow-report" },
    { text: "รายงานข้อมูลที่มีการแก้ไข", navigation: "report/accountreport/edited-data-report" },
    { text: "รายงานประวัติการแก้ไข - ลบข้อมูล", navigation: "report/accountreport/history-edited-delete-data-report" },
    // { text: "รายงานประวัติการแก้ไขรหัสบัญชี", navigation: "report/accountreport/rac31" },
    { text: "รายงานสรุปเช็ครับ", navigation: "report/chequereport/summary-receive-cheque-report" },
    { text: "รายงานสรุปความเคลื่อนไหวเช็ค", navigation: "report/chequereport/summary-movement-cheque-report" },
    { text: "รายงานสรุปเช็คจ่าย", navigation: "report/chequereport/summary-payment-cheque-report" },
    { text: "รายงานสถานะเช็คคงค้าง", navigation: "report/chequereport/report-payment-cheque-onhand" },
    { text: "รายงานรหัสสินทรัพย์", navigation: "report/assetreport/assetcode-report" },
    { text: "รายงานคำนวณค่าเสื่อม", navigation: "report/assetreport/calculate-depreciation-report" },
    { text: "รายงานกลุ่ม/ประเภทสินทรัพย์", navigation: "report/assetreport/asset-grouptype-report" },
    { text: "ข้อมูลทั่วไปของกิจการ", navigation: "setup/company" },
    { text: "ติดตั้งบริษัทในเครือ", navigation: "setup/company-consolidate" },
    { text: "ข้อมูลสาขา", navigation: "setup/division" },
    { text: "ข้อมูลแผนก", navigation: "setup/section" },
    { text: "ข้อมูลงวดบัญชี", navigation: "setup/account-period" },
    { text: "กลุ่มเอกสาร", navigation: "setup/document-group" },
    { text: "ตำแหน่งพนักงาน", navigation: "setup/position" },
    { text: "รหัสพนักงาน", navigation: "setup/employee-master" },
    { text: "รายละเอียดพนักงาน", navigation: "setup/person" },
    { text: "แก้ไขรหัสผ่าน", navigation: "setup/changepassword" },
    { text: "กำหนดสิทธิ์ตามระบบ", navigation: "setup/authenbysystem" },
    { text: "กำหนดสิทธิ์ตามพนักงาน", navigation: "setup/AuthenEmployee" },
    { text: "ติดตั้งวงเงินอนุมัติ", navigation: "setup/InputMinMax" },
    { text: "Flow-AccCloud", navigation: "setup/Flow-AccCloud" },
    { text: "คู่มือการใช้งานระบบในภาพรวม", navigation: "setup/OSM" },
    { text: "คู่มือธุรกิจซื้อมาขายไป", navigation: "setup/TCSM" },
    { text: "คู่มือธุรกิจการผลิต", navigation: "setup/MCSM" },
    { text: "คู่มือธุรกิจบริการ", navigation: "setup/SCSM" },
    { text: "คู่มือการใช้งานระบบแก้ไขเอกสาร", navigation: "setup/Manual1" },
    { text: "คู่มือการใช้งานระบบ BI", navigation: "setup/Manual2" },
    { text: "คู่มือการใช้งานระบบขายหน้าร้าน", navigation: "setup/Manual3" },
    { text: "คู่มือการใช้งานระบบ Warehouse Management", navigation: "setup/WHMSM" },
    { text: "คู่มือการใช้งานระบบ PO Online", navigation: "setup/Manual4" },
    { text: "คู่มือการใช้งานระบบ PaySlip Online", navigation: "setup/Manual5" },
    { text: "คู่มือการตั้งค่า E-Mail", navigation: "setup/SetupEmail" },
    { text: "คู่มือการส่ง E-Mail", navigation: "setup/SendEmailSystemManual" },
    { text: "วิธีการเปิด Pop Up", navigation: "setup/PopUp" },
    { text: "กำหนดรหัสบัญชี", navigation: "setup/account-code" },
    { text: "คำอธิบายรายการ", navigation: "setup/account-division" },
    { text: "กำหนดชนิดบัญชีย่อย", navigation: "setup/account-main" },
    { text: "กำหนดบันทึกบัญชีแม่แบบ", navigation: "setup/account-template-all" },
    { text: "กำหนดรหัสบัญชีสำหรับการบันทึกบัญชีอัตโนมัติ", navigation: "setup/account-define" },
    { text: "กำหนดวงเงินสดย่อย", navigation: "setup/setup-advance-pm" },
    { text: "กลุ่มสินค้า", navigation: "setup/product-group" },
    { text: "ประเภทสินค้า", navigation: "setup/product-sub-group1" },
    { text: "หน่วยนับ", navigation: "setup/unit-of-measure" },
    { text: "กำหนดคลังสินค้า", navigation: "setup/warehouse" },
    { text: "กำหนดรุ่นสินค้า", navigation: "setup/product-model" },
    { text: "กำหนดรหัสยี่ห้อ", navigation: "setup/product-brand" },
    { text: "กำหนดกลุ่มขนาดสินค้า", navigation: "setup/product-size" },
    { text: "กำหนดกลุ่มรูปแบบสินค้า", navigation: "setup/product-pattern" },
    { text: "กำหนดเกรดสินค้า", navigation: "setup/product-grade" },
    { text: "กำหนดกลุ่มสินค้าอื่น ๆ", navigation: "setup/product-othergroup" },
    { text: "รหัสสินค้า", navigation: "setup/product" },
    { text: "กำหนดสูตรการผลิต", navigation: "setup/product-complementary" },
    { text: "ต้นทุนมาตรฐาน", navigation: "setup/standard-cost" },
    { text: "กลุ่มงานบริการ", navigation: "setup/service-group" },
    { text: "ชนิดบริการ", navigation: "setup/service-type" },
    { text: "รหัสงานบริการ", navigation: "setup/service" },
    { text: "กลุ่มผู้จำหน่าย", navigation: "setup/supplier-group" },
    { text: "วิเคราะห์คุณภาพสินค้าตาม Supplier", navigation: "setup/supplier" },
    { text: "ยอดยกมาเจ้าหนี้", navigation: "setup/opening-entry-supplier" },
    { text: "กลุ่มลูกค้า", navigation: "setup/customer-group" },
    { text: "รหัสลูกค้า", navigation: "setup/customer" },
    { text: "ยอดยกมาลูกหนี้", navigation: "setup/opening-entry-customer" },
    { text: "ระบบสมาชิก", navigation: "setup/member" },
    { text: "รหัสขนส่ง", navigation: "setup/deliver" },
    { text: "จัดรถขนส่งจากใบสั่งขาย", navigation: "setup/transport-from-so" },
    { text: "จัดรถขนส่งจากใบส่งของ", navigation: "setup/transport-from-do" },
    { text: "บันทึกต้นทุนขนส่ง", navigation: "setup/transport-service" },
    { text: "ติดตั้งกลุ่มพื้นที่การขาย", navigation: "setup/sale-area" },
    { text: "กำหนดรหัสธนาคาร", navigation: "setup/bank" },
    { text: "กำหนดสาขาธนาคาร", navigation: "setup/bank-branch" },
    { text: "ข้อมูลสมุดเงินฝาก", navigation: "setup/deposit-book-master" },
    { text: "ประเภทกลุ่มงาน", navigation: "setup/job-group" },
    { text: "รหัสงาน", navigation: "setup/job-code" },
    { text: "ค่าใช้จ่ายต่องาน", navigation: "setup/job-expense" },
    { text: "กำหนดประเภทการรับ", navigation: "setup/setreceivingtype" },
    { text: "กำหนดชื่อตามรายงานภาษี", navigation: "setup/nameinvat" },
    { text: "ภาษีหัก ณ ที่จ่าย", navigation: "setup/WithholdingTax" },
    { text: "รหัสสกุลเงินตราต่างประเทศ", navigation: "setup/currency" },
    { text: "จัดเตรียมข้อมูล (Excel)", navigation: "setup/PrepareDataExcel" },
  ];

  public customSettings: OverlaySettings = {
    positionStrategy: new GlobalPositionStrategy(
      {
        verticalDirection: VerticalAlignment.Middle,
        horizontalDirection: HorizontalAlignment.Center
      }),
    modal: true,
    closeOnOutsideClick: true,
  };

  public text: string;
  public overlaySettings = {
    positionStrategy: new ConnectedPositioningStrategy({ horizontalDirection: HorizontalAlignment.Left, horizontalStartPoint: HorizontalAlignment.Right, verticalStartPoint: VerticalAlignment.Bottom }),
    scrollStrategy: new NoOpScrollStrategy(),
  };

  public overlaySettingsSearch = {
    positionStrategy: new ConnectedPositioningStrategy({ horizontalDirection: HorizontalAlignment.Left, horizontalStartPoint: HorizontalAlignment.Center, verticalStartPoint: VerticalAlignment.Middle }),
    scrollStrategy: new NoOpScrollStrategy(),
  };

  public onSelection(eventArgs: any) {
    this.text = eventArgs.newSelection.value;
    eventArgs.cancel = true;
  }

  public async changeLanguage(lang: string) {
    while (sessionStorage.getItem("session") == null) {
      await this.delay(50);
    }
    console.log("[LOG] changeLanguage to " + lang);
    this.translate.use(lang);

    this.http
      .post<any>("/api/TblUserLogin/updateCurrentLang", {
        tblUserLoginId: this.userInSessionStorage.tblUserLoginId,
        currentLang: lang,
      })
      .subscribe((data) => {
        console.log(data);
      });
  }

  public async getMenuMaster() {
    while (sessionStorage.getItem("session") == null) {
      await this.delay(50);
    }
    this.menuMasterModel = this.userInSessionStorage.menuMasterModel;
    switch (this.userInSessionStorage.currentLang) {
      case "th-TH":
        this.menuMasterModel.forEach((element) => {
          if (
            element.menuDescription != null &&
            element.menuDescription != ""
          ) {
            element.menuName =
              "[" + element.menuDescription + "] " + element.menuTName;
          } else {
            element.menuName = element.menuTName;
          }
        });
        break;
      case "en-US":
        this.menuMasterModel.forEach((element) => {
          if (
            element.menuDescription != null &&
            element.menuDescription != ""
          ) {
            element.menuName =
              "[" + element.menuDescription + "] " + element.menuEName;
          } else {
            element.menuName = element.menuEName;
          }
        });
        break;
      default:
        this.menuMasterModel.forEach((element) => {
          if (
            element.menuDescription != null &&
            element.menuDescription != ""
          ) {
            element.menuName =
              "[" + element.menuDescription + "] " + element.menuTName;
          } else {
            element.menuName = element.menuTName;
          }
        });
        break;
    }
    /* this.http
      .post<any>("/api/center/menumaster/get", {})
      .subscribe(async (data) => {
        this.menuMasterModel = data;
        switch (this.userInSessionStorage.currentLang) {
          case "th-TH":
            this.menuMasterModel.forEach((element) => {
              if (
                element.menuDescription != null &&
                element.menuDescription != ""
              ) {
                element.menuName =
                  "[" + element.menuDescription + "] " + element.menuTName;
              } else {
                element.menuName = element.menuTName;
              }
            });
            break;
          case "en-US":
            this.menuMasterModel.forEach((element) => {
              if (
                element.menuDescription != null &&
                element.menuDescription != ""
              ) {
                element.menuName =
                  "[" + element.menuDescription + "] " + element.menuEName;
              } else {
                element.menuName = element.menuEName;
              }
            });
            break;
          default:
            this.menuMasterModel.forEach((element) => {
              if (
                element.menuDescription != null &&
                element.menuDescription != ""
              ) {
                element.menuName =
                  "[" + element.menuDescription + "] " + element.menuTName;
              } else {
                element.menuName = element.menuTName;
              }
            });
            break;
        }
      }); */
  }

  public delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public onHomeClick() {
    this._router.navigate(["homepage"]);
  }

  public onClick(navigation: string) {
    if (navigation == "login") {
      let cookies = JSON.parse(localStorage.getItem("cookies"));
      this.http
        .post<any>("/api/Login/Authentication/CheckUserLogout", {
          tblUserLoginId: this.userInSessionStorage.tblUserLoginId,
          superAdmin: this.userInLocalStorage.superAdmin,
          session: cookies,
        })
        .subscribe((data) => { });
      this.http.get<any>("/api/authen/logout").subscribe((data) => {
        localStorage.clear();
        sessionStorage.clear();
      });
      this._router.navigate([navigation]);
    } else if (navigation == "calendar/master-production-planning") {
      window.open(navigation);
    } else {
      this._router.navigate([navigation]);
    }

  }

  public handleSelection(event) {
    console.log(event);
    var menuSelected = this.menuMasterModel.find(
      (x) => x.menuMasterId == event.newValue
    );
    console.log(menuSelected);
    if (menuSelected.servicePath == "companyinfo") {
      menuSelected.servicePath = "setup";
    } else if (menuSelected.pagePath == "cost-center") {
      menuSelected.servicePath = "setup/account";
    }
    let newPath = this.changePath(menuSelected.pagePath);
    var urlPath = menuSelected.servicePath + "/" + newPath;
    this._router.navigate([urlPath]);
  }
  changePath(path) {
    switch (path) {
      case "quotation-product-labor":
        path = "quotation-product";
        break;
      case "product-receive-invoice-noprice":
        path = "product-receive-invoice";
        break;
      case "purchase-order-noprice":
        path = "purchase-order";
        break;
      case "bank-book":
      case "cash-book":
      case "return-voucher-book":
      case "bank-payment-book":
      case "cash-payment-book":
      case "general-journal":
      case "credit-purchase-book":
      case "jegplus":
      case "opening-entry-book":
      case "credit-sale-book":
        path = "voucher-book";
        break;
      case "setupdebtsupplier":
        path = "other-expense";
        break;
      case "expensegenaral":
        path = "petty-cash";
        break;
      case "cash-sale-no-vat":
        path = "cash-sale";
        break;
      case "purchase-request-invoice-noprice":
        path = "purchase-request-invoice";
        break;
    }
    return path;
  }
}
