import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot} from '@angular/router';


import { ActivatedRouteSnapshot } from '@angular/router';




@Injectable()
export class AuthGuardPageService  {

    constructor(private router: Router) {

    }
    canActivate(route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): boolean {
  
              if(state.url == "/"){
                this.router.navigate(['homepage']);
              }
              return true;

      }
   
}
