//VariableShare GenerateAuto Script : LastUpdate : 2021-07-20 12:05:24

export const environments = {
  environments: "",
  url_report: "",
};

export const VariableShare = {
  minSizeProductAdjustDetailId_ProductAdjustDetail: 0,
  maxSizeProductAdjustDetailId_ProductAdjustDetail: 19,
  patternProductAdjustDetailId_ProductAdjustDetail: "",
  minSizeProductAdjustDetailNo_ProductAdjustDetail: 0,
  maxSizeProductAdjustDetailNo_ProductAdjustDetail: 10,
  patternProductAdjustDetailNo_ProductAdjustDetail: "",
  minSizeProdQty_ProductAdjustDetail: 0,
  maxSizeProdQty_ProductAdjustDetail: 26,
  patternProdQty_ProductAdjustDetail: "",
  minSizeProdBaseQty_ProductAdjustDetail: 0,
  maxSizeProdBaseQty_ProductAdjustDetail: 26,
  patternProdBaseQty_ProductAdjustDetail: "",
  minSizeProdConvFactor_ProductAdjustDetail: 0,
  maxSizeProdConvFactor_ProductAdjustDetail: 26,
  patternProdConvFactor_ProductAdjustDetail: "",
  minSizeAcjType_ProductAdjustDetail: 0,
  maxSizeAcjType_ProductAdjustDetail: 10,
  patternAcjType_ProductAdjustDetail: "",
  minSizeCompanyCode_ProductAdjustDetail: 0,
  maxSizeCompanyCode_ProductAdjustDetail: 20,
  patternCompanyCode_ProductAdjustDetail: "[0-9a-zA-Z/-]+",
  minSizeProdLotNo_ProductAdjustDetail: 0,
  maxSizeProdLotNo_ProductAdjustDetail: 30,
  patternProdLotNo_ProductAdjustDetail: "",
  minSizeReferencDocNo_ProductAdjustDetail: 0,
  maxSizeReferencDocNo_ProductAdjustDetail: 30,
  patternReferencDocNo_ProductAdjustDetail: "",
  minSizeSerialNoText_ProductAdjustDetail: 0,
  maxSizeSerialNoText_ProductAdjustDetail: 9999,
  patternSerialNoText_ProductAdjustDetail: "",
  minSizeCost_ProductAdjustDetail: 0,
  maxSizeCost_ProductAdjustDetail: 26,
  patternCost_ProductAdjustDetail: "",
  minSizeUnitPrice_ProductAdjustDetail: 0,
  maxSizeUnitPrice_ProductAdjustDetail: 26,
  patternUnitPrice_ProductAdjustDetail: "",
  minSizeTotal_ProductAdjustDetail: 0,
  maxSizeTotal_ProductAdjustDetail: 26,
  patternTotal_ProductAdjustDetail: "",
  minSizeProductMaster1Id_ProductAdjustDetail: 0,
  maxSizeProductMaster1Id_ProductAdjustDetail: 19,
  patternProductMaster1Id_ProductAdjustDetail: "",
  minSizeWarehouseId_ProductAdjustDetail: 0,
  maxSizeWarehouseId_ProductAdjustDetail: 19,
  patternWarehouseId_ProductAdjustDetail: "",
  minSizeDivisionCode_ProductAdjustDetail: 0,
  maxSizeDivisionCode_ProductAdjustDetail: 19,
  patternDivisionCode_ProductAdjustDetail: "[0-9a-zA-Z/-]+",
  minSizeUnitOfMeasureId1_ProductAdjustDetail: 0,
  maxSizeUnitOfMeasureId1_ProductAdjustDetail: 19,
  patternUnitOfMeasureId1_ProductAdjustDetail: "",
  minSizeUnitOfMeasureId2_ProductAdjustDetail: 0,
  maxSizeUnitOfMeasureId2_ProductAdjustDetail: 19,
  patternUnitOfMeasureId2_ProductAdjustDetail: "",
  minSizeProductAdjustMasterId_ProductAdjustDetail: 0,
  maxSizeProductAdjustMasterId_ProductAdjustDetail: 19,
  patternProductAdjustMasterId_ProductAdjustDetail: "",
  minSizeLotNo_ProductAdjustDetail: 0,
  maxSizeLotNo_ProductAdjustDetail: 50,
  patternLotNo_ProductAdjustDetail: "",
  minSizeCost1_ProductAdjustDetail: 0,
  maxSizeCost1_ProductAdjustDetail: 26,
  patternCost1_ProductAdjustDetail: "",
  minSizeProductAdjustDetailId_ProductAdjustDetailJobMaster: 0,
  maxSizeProductAdjustDetailId_ProductAdjustDetailJobMaster: 19,
  patternProductAdjustDetailId_ProductAdjustDetailJobMaster: "",
  minSizeJobMasterId_ProductAdjustDetailJobMaster: 0,
  maxSizeJobMasterId_ProductAdjustDetailJobMaster: 19,
  patternJobMasterId_ProductAdjustDetailJobMaster: "",
  minSizeProductAdjustMasterId_ProductAdjustMaster: 0,
  maxSizeProductAdjustMasterId_ProductAdjustMaster: 19,
  patternProductAdjustMasterId_ProductAdjustMaster: "",
  minSizeAdjCode_ProductAdjustMaster: 0,
  maxSizeAdjCode_ProductAdjustMaster: 20,
  patternAdjCode_ProductAdjustMaster: "[0-9a-zA-Z/-]+",
  minSizeAdjDate_ProductAdjustMaster: 0,
  maxSizeAdjDate_ProductAdjustMaster: 20,
  patternAdjDate_ProductAdjustMaster: "",
  minSizeAdjReferNo_ProductAdjustMaster: 0,
  maxSizeAdjReferNo_ProductAdjustMaster: 20,
  patternAdjReferNo_ProductAdjustMaster: "",
  minSizeAdjStatus_ProductAdjustMaster: 0,
  maxSizeAdjStatus_ProductAdjustMaster: 2,
  patternAdjStatus_ProductAdjustMaster: "",
  minSizeAdjRemark_ProductAdjustMaster: 0,
  maxSizeAdjRemark_ProductAdjustMaster: 255,
  patternAdjRemark_ProductAdjustMaster: "",
  minSizeCreateOn_ProductAdjustMaster: 0,
  maxSizeCreateOn_ProductAdjustMaster: 20,
  patternCreateOn_ProductAdjustMaster: "",
  minSizeUpdateOn_ProductAdjustMaster: 0,
  maxSizeUpdateOn_ProductAdjustMaster: 20,
  patternUpdateOn_ProductAdjustMaster: "",
  minSizeCompanyCode_ProductAdjustMaster: 0,
  maxSizeCompanyCode_ProductAdjustMaster: 20,
  patternCompanyCode_ProductAdjustMaster: "[0-9a-zA-Z/-]+",
  minSizeAstatus_ProductAdjustMaster: 0,
  maxSizeAstatus_ProductAdjustMaster: 2,
  patternAstatus_ProductAdjustMaster: "",
  minSizeAdjDocGroupCode_ProductAdjustMaster: 0,
  maxSizeAdjDocGroupCode_ProductAdjustMaster: 19,
  patternAdjDocGroupCode_ProductAdjustMaster: "[0-9a-zA-Z/-]+",
  minSizeAdjDivision_ProductAdjustMaster: 0,
  maxSizeAdjDivision_ProductAdjustMaster: 19,
  patternAdjDivision_ProductAdjustMaster: "",
  minSizeAdjSection_ProductAdjustMaster: 0,
  maxSizeAdjSection_ProductAdjustMaster: 19,
  patternAdjSection_ProductAdjustMaster: "",
  minSizeAdjEmpCode_ProductAdjustMaster: 0,
  maxSizeAdjEmpCode_ProductAdjustMaster: 19,
  patternAdjEmpCode_ProductAdjustMaster: "[0-9a-zA-Z/-]+",
  minSizeCreateByEmpId_ProductAdjustMaster: 0,
  maxSizeCreateByEmpId_ProductAdjustMaster: 19,
  patternCreateByEmpId_ProductAdjustMaster: "",
  minSizeUpdateByEmpId_ProductAdjustMaster: 0,
  maxSizeUpdateByEmpId_ProductAdjustMaster: 19,
  patternUpdateByEmpId_ProductAdjustMaster: "",
  minSizeVoucherNo_ProductAdjustMaster: 0,
  maxSizeVoucherNo_ProductAdjustMaster: 50,
  patternVoucherNo_ProductAdjustMaster: "",
  minSizeTransactionType_ProductAdjustMaster: 0,
  maxSizeTransactionType_ProductAdjustMaster: 20,
  patternTransactionType_ProductAdjustMaster: "",
  minSizeProductionUnitId_ProductionUnit: 0,
  maxSizeProductionUnitId_ProductionUnit: 19,
  patternProductionUnitId_ProductionUnit: "",
  minSizePuCode_ProductionUnit: 0,
  maxSizePuCode_ProductionUnit: 20,
  patternPuCode_ProductionUnit: "[0-9a-zA-Z/-]+",
  minSizePuTName_ProductionUnit: 0,
  maxSizePuTName_ProductionUnit: 20,
  patternPuTName_ProductionUnit: "",
  minSizePuEName_ProductionUnit: 0,
  maxSizePuEName_ProductionUnit: 20,
  patternPuEName_ProductionUnit: "",
  minSizePuRemark_ProductionUnit: 0,
  maxSizePuRemark_ProductionUnit: 250,
  patternPuRemark_ProductionUnit: "",
  minSizeCompanyCode_ProductionUnit: 0,
  maxSizeCompanyCode_ProductionUnit: 20,
  patternCompanyCode_ProductionUnit: "[0-9a-zA-Z/-]+",
  minSizeQcMethodId_QCMethod: 0,
  maxSizeQcMethodId_QCMethod: 19,
  patternQcMethodId_QCMethod: "",
  minSizeQcCode_QCMethod: 0,
  maxSizeQcCode_QCMethod: 20,
  patternQcCode_QCMethod: "[0-9a-zA-Z/-]+",
  minSizeQcMethodName_QCMethod: 0,
  maxSizeQcMethodName_QCMethod: 255,
  patternQcMethodName_QCMethod: "",
  minSizeQcStdType_QCMethod: 0,
  maxSizeQcStdType_QCMethod: 2,
  patternQcStdType_QCMethod: "",
  minSizeQcStdValue1_QCMethod: 0,
  maxSizeQcStdValue1_QCMethod: 26,
  patternQcStdValue1_QCMethod: "[0-9.]+",
  minSizeQcStdValue2_QCMethod: 0,
  maxSizeQcStdValue2_QCMethod: 26,
  patternQcStdValue2_QCMethod: "[0-9.]+",
  minSizeQcRemark_QCMethod: 0,
  maxSizeQcRemark_QCMethod: 200,
  patternQcRemark_QCMethod: "",
  minSizeQcCancel_QCMethod: 0,
  maxSizeQcCancel_QCMethod: 2,
  patternQcCancel_QCMethod: "",
  minSizeCompanyCode_QCMethod: 0,
  maxSizeCompanyCode_QCMethod: 20,
  patternCompanyCode_QCMethod: "[0-9a-zA-Z/-]+",
  minSizeQcInspectId_QCInspect: 0,
  maxSizeQcInspectId_QCInspect: 19,
  patternQcInspectId_QCInspect: "",
  minSizeQcInspectCode_QCInspect: 0,
  maxSizeQcInspectCode_QCInspect: 20,
  patternQcInspectCode_QCInspect: "[0-9a-zA-Z/-]+",
  minSizeQcInspectName_QCInspect: 0,
  maxSizeQcInspectName_QCInspect: 100,
  patternQcInspectName_QCInspect: "",
  minSizeQcPass_QCInspect: 0,
  maxSizeQcPass_QCInspect: 20,
  patternQcPass_QCInspect: "",
  minSizeCompanyCode_QCInspect: 0,
  maxSizeCompanyCode_QCInspect: 20,
  patternCompanyCode_QCInspect: "[0-9a-zA-Z/-]+",
  minSizeDeliveryOrderDetailId_DeliveryOrderDetail: 0,
  maxSizeDeliveryOrderDetailId_DeliveryOrderDetail: 19,
  patternDeliveryOrderDetailId_DeliveryOrderDetail: "",
  minSizeDeliveryOrderDetailNo_DeliveryOrderDetail: 0,
  maxSizeDeliveryOrderDetailNo_DeliveryOrderDetail: 10,
  patternDeliveryOrderDetailNo_DeliveryOrderDetail: "",
  minSizeProdQTY_DeliveryOrderDetail: 0,
  maxSizeProdQTY_DeliveryOrderDetail: 26,
  patternProdQTY_DeliveryOrderDetail: "",
  minSizeProdConvFactor_DeliveryOrderDetail: 0,
  maxSizeProdConvFactor_DeliveryOrderDetail: 26,
  patternProdConvFactor_DeliveryOrderDetail: "",
  minSizeProdBaseQTY_DeliveryOrderDetail: 0,
  maxSizeProdBaseQTY_DeliveryOrderDetail: 26,
  patternProdBaseQTY_DeliveryOrderDetail: "",
  minSizeProdPrice_DeliveryOrderDetail: 0,
  maxSizeProdPrice_DeliveryOrderDetail: 26,
  patternProdPrice_DeliveryOrderDetail: "",
  minSizeProdDiscRate_DeliveryOrderDetail: 0,
  maxSizeProdDiscRate_DeliveryOrderDetail: 26,
  patternProdDiscRate_DeliveryOrderDetail: "",
  minSizeProdDiscAmt_DeliveryOrderDetail: 0,
  maxSizeProdDiscAmt_DeliveryOrderDetail: 26,
  patternProdDiscAmt_DeliveryOrderDetail: "",
  minSizeProdNetPrice_DeliveryOrderDetail: 0,
  maxSizeProdNetPrice_DeliveryOrderDetail: 26,
  patternProdNetPrice_DeliveryOrderDetail: "",
  minSizeProdReceiveDn_DeliveryOrderDetail: 0,
  maxSizeProdReceiveDn_DeliveryOrderDetail: 26,
  patternProdReceiveDn_DeliveryOrderDetail: "",
  minSizeSerialQty_DeliveryOrderDetail: 0,
  maxSizeSerialQty_DeliveryOrderDetail: 26,
  patternSerialQty_DeliveryOrderDetail: "",
  minSizeProdReceiveInvoiceQty_DeliveryOrderDetail: 0,
  maxSizeProdReceiveInvoiceQty_DeliveryOrderDetail: 26,
  patternProdReceiveInvoiceQty_DeliveryOrderDetail: "",
  minSizeProdReceiveInvoiceBaseQty_DeliveryOrderDetail: 0,
  maxSizeProdReceiveInvoiceBaseQty_DeliveryOrderDetail: 26,
  patternProdReceiveInvoiceBaseQty_DeliveryOrderDetail: "",
  minSizeDnDocNo_DeliveryOrderDetail: 0,
  maxSizeDnDocNo_DeliveryOrderDetail: 20,
  patternDnDocNo_DeliveryOrderDetail: "",
  minSizeSoDocNo_DeliveryOrderDetail: 0,
  maxSizeSoDocNo_DeliveryOrderDetail: 20,
  patternSoDocNo_DeliveryOrderDetail: "",
  minSizeSendDate_DeliveryOrderDetail: 0,
  maxSizeSendDate_DeliveryOrderDetail: 20,
  patternSendDate_DeliveryOrderDetail: "",
  minSizeInvoiceNo_DeliveryOrderDetail: 0,
  maxSizeInvoiceNo_DeliveryOrderDetail: 20,
  patternInvoiceNo_DeliveryOrderDetail: "",
  minSizeInvoiceDate_DeliveryOrderDetail: 0,
  maxSizeInvoiceDate_DeliveryOrderDetail: 20,
  patternInvoiceDate_DeliveryOrderDetail: "",
  minSizeProdVat_DeliveryOrderDetail: 0,
  maxSizeProdVat_DeliveryOrderDetail: 2,
  patternProdVat_DeliveryOrderDetail: "",
  minSizeSoDocIndex_DeliveryOrderDetail: 0,
  maxSizeSoDocIndex_DeliveryOrderDetail: 10,
  patternSoDocIndex_DeliveryOrderDetail: "",
  minSizeCompanyCode_DeliveryOrderDetail: 0,
  maxSizeCompanyCode_DeliveryOrderDetail: 20,
  patternCompanyCode_DeliveryOrderDetail: "[0-9a-zA-Z/-]+",
  minSizeProdDiscRate0_DeliveryOrderDetail: 0,
  maxSizeProdDiscRate0_DeliveryOrderDetail: 20,
  patternProdDiscRate0_DeliveryOrderDetail: "",
  minSizeProdItemDetail_DeliveryOrderDetail: 0,
  maxSizeProdItemDetail_DeliveryOrderDetail: 9999,
  patternProdItemDetail_DeliveryOrderDetail: "",
  minSizeSerialNo_DeliveryOrderDetail: 0,
  maxSizeSerialNo_DeliveryOrderDetail: 9999,
  patternSerialNo_DeliveryOrderDetail: "",
  minSizeSerialNoText_DeliveryOrderDetail: 0,
  maxSizeSerialNoText_DeliveryOrderDetail: 9999,
  patternSerialNoText_DeliveryOrderDetail: "",
  minSizeProdName_DeliveryOrderDetail: 0,
  maxSizeProdName_DeliveryOrderDetail: 350,
  patternProdName_DeliveryOrderDetail: "",
  minSizeCost_DeliveryOrderDetail: 0,
  maxSizeCost_DeliveryOrderDetail: 26,
  patternCost_DeliveryOrderDetail: "",
  minSizeProdReceiveCn_DeliveryOrderDetail: 0,
  maxSizeProdReceiveCn_DeliveryOrderDetail: 26,
  patternProdReceiveCn_DeliveryOrderDetail: "",
  minSizeQuDocNo_DeliveryOrderDetail: 0,
  maxSizeQuDocNo_DeliveryOrderDetail: 20,
  patternQuDocNo_DeliveryOrderDetail: "",
  minSizeProductMaster1Id_DeliveryOrderDetail: 0,
  maxSizeProductMaster1Id_DeliveryOrderDetail: 19,
  patternProductMaster1Id_DeliveryOrderDetail: "",
  minSizeWarehouseId_DeliveryOrderDetail: 0,
  maxSizeWarehouseId_DeliveryOrderDetail: 19,
  patternWarehouseId_DeliveryOrderDetail: "",
  minSizeUnitOfMeasureId1_DeliveryOrderDetail: 0,
  maxSizeUnitOfMeasureId1_DeliveryOrderDetail: 19,
  patternUnitOfMeasureId1_DeliveryOrderDetail: "",
  minSizeUnitOfMeasureId2_DeliveryOrderDetail: 0,
  maxSizeUnitOfMeasureId2_DeliveryOrderDetail: 19,
  patternUnitOfMeasureId2_DeliveryOrderDetail: "",
  minSizeDeliveryOrderMasterId_DeliveryOrderDetail: 0,
  maxSizeDeliveryOrderMasterId_DeliveryOrderDetail: 19,
  patternDeliveryOrderMasterId_DeliveryOrderDetail: "",
  minSizePoCode_DeliveryOrderDetail: 0,
  maxSizePoCode_DeliveryOrderDetail: 255,
  patternPoCode_DeliveryOrderDetail: "[0-9a-zA-Z/-]+",
  minSizeCost1_DeliveryOrderDetail: 0,
  maxSizeCost1_DeliveryOrderDetail: 26,
  patternCost1_DeliveryOrderDetail: "",
  minSizeLotNo_DeliveryOrderDetail: 0,
  maxSizeLotNo_DeliveryOrderDetail: 50,
  patternLotNo_DeliveryOrderDetail: "",
  minSizeLotMasterId_DeliveryOrderDetail: 0,
  maxSizeLotMasterId_DeliveryOrderDetail: 19,
  patternLotMasterId_DeliveryOrderDetail: "",
  minSizeDeliveryOrderMasterId_DeliveryOrderMaster: 0,
  maxSizeDeliveryOrderMasterId_DeliveryOrderMaster: 19,
  patternDeliveryOrderMasterId_DeliveryOrderMaster: "",
  minSizeDoDocNo_DeliveryOrderMaster: 0,
  maxSizeDoDocNo_DeliveryOrderMaster: 20,
  patternDoDocNo_DeliveryOrderMaster: "",
  minSizeDoDocDate_DeliveryOrderMaster: 0,
  maxSizeDoDocDate_DeliveryOrderMaster: 20,
  patternDoDocDate_DeliveryOrderMaster: "",
  minSizeDoCreditTerm_DeliveryOrderMaster: 0,
  maxSizeDoCreditTerm_DeliveryOrderMaster: 10,
  patternDoCreditTerm_DeliveryOrderMaster: "",
  minSizeDoTotal_DeliveryOrderMaster: 0,
  maxSizeDoTotal_DeliveryOrderMaster: 26,
  patternDoTotal_DeliveryOrderMaster: "",
  minSizeDoTotalNoVat_DeliveryOrderMaster: 0,
  maxSizeDoTotalNoVat_DeliveryOrderMaster: 26,
  patternDoTotalNoVat_DeliveryOrderMaster: "",
  minSizeDoDiscRate_DeliveryOrderMaster: 0,
  maxSizeDoDiscRate_DeliveryOrderMaster: 26,
  patternDoDiscRate_DeliveryOrderMaster: "",
  minSizeDoDiscAmt_DeliveryOrderMaster: 0,
  maxSizeDoDiscAmt_DeliveryOrderMaster: 26,
  patternDoDiscAmt_DeliveryOrderMaster: "",
  minSizeDoDepositNo_DeliveryOrderMaster: 0,
  maxSizeDoDepositNo_DeliveryOrderMaster: 500,
  patternDoDepositNo_DeliveryOrderMaster: "",
  minSizeDoDepositAmt_DeliveryOrderMaster: 0,
  maxSizeDoDepositAmt_DeliveryOrderMaster: 26,
  patternDoDepositAmt_DeliveryOrderMaster: "",
  minSizeDoVatRate_DeliveryOrderMaster: 0,
  maxSizeDoVatRate_DeliveryOrderMaster: 26,
  patternDoVatRate_DeliveryOrderMaster: "",
  minSizeDoVatAmt_DeliveryOrderMaster: 0,
  maxSizeDoVatAmt_DeliveryOrderMaster: 26,
  patternDoVatAmt_DeliveryOrderMaster: "",
  minSizeDoNetAmt_DeliveryOrderMaster: 0,
  maxSizeDoNetAmt_DeliveryOrderMaster: 26,
  patternDoNetAmt_DeliveryOrderMaster: "",
  minSizeCharge_DeliveryOrderMaster: 0,
  maxSizeCharge_DeliveryOrderMaster: 26,
  patternCharge_DeliveryOrderMaster: "",
  minSizeDoStatus_DeliveryOrderMaster: 0,
  maxSizeDoStatus_DeliveryOrderMaster: 2,
  patternDoStatus_DeliveryOrderMaster: "",
  minSizeRcStatus_DeliveryOrderMaster: 0,
  maxSizeRcStatus_DeliveryOrderMaster: 2,
  patternRcStatus_DeliveryOrderMaster: "",
  minSizeDoLinkDoc_DeliveryOrderMaster: 0,
  maxSizeDoLinkDoc_DeliveryOrderMaster: 20,
  patternDoLinkDoc_DeliveryOrderMaster: "",
  minSizeDoInvoiceDocNo_DeliveryOrderMaster: 0,
  maxSizeDoInvoiceDocNo_DeliveryOrderMaster: 20,
  patternDoInvoiceDocNo_DeliveryOrderMaster: "",
  minSizeCreateOn_DeliveryOrderMaster: 0,
  maxSizeCreateOn_DeliveryOrderMaster: 25,
  patternCreateOn_DeliveryOrderMaster: "",
  minSizeUpdateOn_DeliveryOrderMaster: 0,
  maxSizeUpdateOn_DeliveryOrderMaster: 25,
  patternUpdateOn_DeliveryOrderMaster: "",
  minSizeDoRemark_DeliveryOrderMaster: 0,
  maxSizeDoRemark_DeliveryOrderMaster: 500,
  patternDoRemark_DeliveryOrderMaster: "",
  minSizeDoVoucher_DeliveryOrderMaster: 0,
  maxSizeDoVoucher_DeliveryOrderMaster: 20,
  patternDoVoucher_DeliveryOrderMaster: "",
  minSizeVatInclude_DeliveryOrderMaster: 0,
  maxSizeVatInclude_DeliveryOrderMaster: 20,
  patternVatInclude_DeliveryOrderMaster: "",
  minSizeRefNumber_DeliveryOrderMaster: 0,
  maxSizeRefNumber_DeliveryOrderMaster: 30,
  patternRefNumber_DeliveryOrderMaster: "",
  minSizeDoBillingAmt_DeliveryOrderMaster: 0,
  maxSizeDoBillingAmt_DeliveryOrderMaster: 26,
  patternDoBillingAmt_DeliveryOrderMaster: "",
  minSizeDoBeforeChangeStatus_DeliveryOrderMaster: 0,
  maxSizeDoBeforeChangeStatus_DeliveryOrderMaster: 2,
  patternDoBeforeChangeStatus_DeliveryOrderMaster: "",
  minSizeDoType_DeliveryOrderMaster: 0,
  maxSizeDoType_DeliveryOrderMaster: 2,
  patternDoType_DeliveryOrderMaster: "",
  minSizeDoReceiptAmt_DeliveryOrderMaster: 0,
  maxSizeDoReceiptAmt_DeliveryOrderMaster: 26,
  patternDoReceiptAmt_DeliveryOrderMaster: "",
  minSizeCompanyCode_DeliveryOrderMaster: 0,
  maxSizeCompanyCode_DeliveryOrderMaster: 20,
  patternCompanyCode_DeliveryOrderMaster: "[0-9a-zA-Z/-]+",
  minSizeDoInvoiceTotal_DeliveryOrderMaster: 0,
  maxSizeDoInvoiceTotal_DeliveryOrderMaster: 26,
  patternDoInvoiceTotal_DeliveryOrderMaster: "",
  minSizeDoProdNetAmt_DeliveryOrderMaster: 0,
  maxSizeDoProdNetAmt_DeliveryOrderMaster: 26,
  patternDoProdNetAmt_DeliveryOrderMaster: "",
  minSizeExchangeRate_DeliveryOrderMaster: 0,
  maxSizeExchangeRate_DeliveryOrderMaster: 26,
  patternExchangeRate_DeliveryOrderMaster: "",
  minSizeExchageRateDate_DeliveryOrderMaster: 0,
  maxSizeExchageRateDate_DeliveryOrderMaster: 20,
  patternExchageRateDate_DeliveryOrderMaster: "",
  minSizeAstatus_DeliveryOrderMaster: 0,
  maxSizeAstatus_DeliveryOrderMaster: 2,
  patternAstatus_DeliveryOrderMaster: "",
  minSizeDoBookCode_DeliveryOrderMaster: 0,
  maxSizeDoBookCode_DeliveryOrderMaster: 20,
  patternDoBookCode_DeliveryOrderMaster: "[0-9a-zA-Z/-]+",
  minSizeDoWithHodingTax_DeliveryOrderMaster: 0,
  maxSizeDoWithHodingTax_DeliveryOrderMaster: 26,
  patternDoWithHodingTax_DeliveryOrderMaster: "",
  minSizeDoBillAddress_DeliveryOrderMaster: 0,
  maxSizeDoBillAddress_DeliveryOrderMaster: 9999,
  patternDoBillAddress_DeliveryOrderMaster: "",
  minSizeDoBillTName1_DeliveryOrderMaster: 0,
  maxSizeDoBillTName1_DeliveryOrderMaster: 9999,
  patternDoBillTName1_DeliveryOrderMaster: "",
  minSizeDoBillTelNo_DeliveryOrderMaster: 0,
  maxSizeDoBillTelNo_DeliveryOrderMaster: 50,
  patternDoBillTelNo_DeliveryOrderMaster: "",
  minSizeDivisionNo_DeliveryOrderMaster: 0,
  maxSizeDivisionNo_DeliveryOrderMaster: 10,
  patternDivisionNo_DeliveryOrderMaster: "",
  minSizeDivisionName_DeliveryOrderMaster: 0,
  maxSizeDivisionName_DeliveryOrderMaster: 100,
  patternDivisionName_DeliveryOrderMaster: "",
  minSizeDivisionAddress_DeliveryOrderMaster: 0,
  maxSizeDivisionAddress_DeliveryOrderMaster: 255,
  patternDivisionAddress_DeliveryOrderMaster: "",
  minSizeTaxIdNo_DeliveryOrderMaster: 0,
  maxSizeTaxIdNo_DeliveryOrderMaster: 50,
  patternTaxIdNo_DeliveryOrderMaster: "",
  minSizeVer_DeliveryOrderMaster: 0,
  maxSizeVer_DeliveryOrderMaster: 10,
  patternVer_DeliveryOrderMaster: "",
  minSizeBankDate_DeliveryOrderMaster: 0,
  maxSizeBankDate_DeliveryOrderMaster: 50,
  patternBankDate_DeliveryOrderMaster: "",
  minSizeCustomerBank_DeliveryOrderMaster: 0,
  maxSizeCustomerBank_DeliveryOrderMaster: 100,
  patternCustomerBank_DeliveryOrderMaster: "",
  minSizeDivisionBank_DeliveryOrderMaster: 0,
  maxSizeDivisionBank_DeliveryOrderMaster: 100,
  patternDivisionBank_DeliveryOrderMaster: "",
  minSizeCustomerBankTwo_DeliveryOrderMaster: 0,
  maxSizeCustomerBankTwo_DeliveryOrderMaster: 100,
  patternCustomerBankTwo_DeliveryOrderMaster: "",
  minSizeDivisionBankTwo_DeliveryOrderMaster: 0,
  maxSizeDivisionBankTwo_DeliveryOrderMaster: 100,
  patternDivisionBankTwo_DeliveryOrderMaster: "",
  minSizePrintStatus_DeliveryOrderMaster: 0,
  maxSizePrintStatus_DeliveryOrderMaster: 2,
  patternPrintStatus_DeliveryOrderMaster: "",
  minSizeDoDueDate_DeliveryOrderMaster: 0,
  maxSizeDoDueDate_DeliveryOrderMaster: 20,
  patternDoDueDate_DeliveryOrderMaster: "",
  minSizeCustName_DeliveryOrderMaster: 0,
  maxSizeCustName_DeliveryOrderMaster: 255,
  patternCustName_DeliveryOrderMaster: "",
  minSizeCurrencyId_DeliveryOrderMaster: 0,
  maxSizeCurrencyId_DeliveryOrderMaster: 19,
  patternCurrencyId_DeliveryOrderMaster: "",
  minSizeDocumentGroupId_DeliveryOrderMaster: 0,
  maxSizeDocumentGroupId_DeliveryOrderMaster: 19,
  patternDocumentGroupId_DeliveryOrderMaster: "",
  minSizeCustomerMasterId_DeliveryOrderMaster: 0,
  maxSizeCustomerMasterId_DeliveryOrderMaster: 19,
  patternCustomerMasterId_DeliveryOrderMaster: "",
  minSizeEmpMasterId_DeliveryOrderMaster: 0,
  maxSizeEmpMasterId_DeliveryOrderMaster: 19,
  patternEmpMasterId_DeliveryOrderMaster: "",
  minSizeDivisionId_DeliveryOrderMaster: 0,
  maxSizeDivisionId_DeliveryOrderMaster: 19,
  patternDivisionId_DeliveryOrderMaster: "",
  minSizeSectionId_DeliveryOrderMaster: 0,
  maxSizeSectionId_DeliveryOrderMaster: 19,
  patternSectionId_DeliveryOrderMaster: "",
  minSizeCreateByEmpId_DeliveryOrderMaster: 0,
  maxSizeCreateByEmpId_DeliveryOrderMaster: 19,
  patternCreateByEmpId_DeliveryOrderMaster: "",
  minSizeUpdateByEmpId_DeliveryOrderMaster: 0,
  maxSizeUpdateByEmpId_DeliveryOrderMaster: 19,
  patternUpdateByEmpId_DeliveryOrderMaster: "",
  minSizeDeliverId_DeliveryOrderMaster: 0,
  maxSizeDeliverId_DeliveryOrderMaster: 19,
  patternDeliverId_DeliveryOrderMaster: "",
  minSizeDriverId_DeliveryOrderMaster: 0,
  maxSizeDriverId_DeliveryOrderMaster: 19,
  patternDriverId_DeliveryOrderMaster: "",
  minSizeDeliverCode_DeliveryOrderMaster: 0,
  maxSizeDeliverCode_DeliveryOrderMaster: 20,
  patternDeliverCode_DeliveryOrderMaster: "[0-9a-zA-Z/-]+",
  minSizeDriverName_DeliveryOrderMaster: 0,
  maxSizeDriverName_DeliveryOrderMaster: 120,
  patternDriverName_DeliveryOrderMaster: "",
  minSizeDeliverStatus_DeliveryOrderMaster: 0,
  maxSizeDeliverStatus_DeliveryOrderMaster: 2,
  patternDeliverStatus_DeliveryOrderMaster: "",
  minSizeDeliverRemark_DeliveryOrderMaster: 0,
  maxSizeDeliverRemark_DeliveryOrderMaster: 200,
  patternDeliverRemark_DeliveryOrderMaster: "",
  minSizeDueDate_DeliveryOrderMaster: 0,
  maxSizeDueDate_DeliveryOrderMaster: 20,
  patternDueDate_DeliveryOrderMaster: "",
  minSizeDoBillTName_DeliveryOrderMaster: 0,
  maxSizeDoBillTName_DeliveryOrderMaster: 255,
  patternDoBillTName_DeliveryOrderMaster: "",
  minSizeArea1Id_DeliveryOrderMaster: 0,
  maxSizeArea1Id_DeliveryOrderMaster: 19,
  patternArea1Id_DeliveryOrderMaster: "",
  minSizeDepositVat_DeliveryOrderMaster: 0,
  maxSizeDepositVat_DeliveryOrderMaster: 50,
  patternDepositVat_DeliveryOrderMaster: "",
  minSizeCheckLot_DeliveryOrderMaster: 0,
  maxSizeCheckLot_DeliveryOrderMaster: 2,
  patternCheckLot_DeliveryOrderMaster: "",
  minSizeVatDrs_DeliveryOrderMaster: 0,
  maxSizeVatDrs_DeliveryOrderMaster: 500,
  patternVatDrs_DeliveryOrderMaster: "",
  minSizeVatRateDrs_DeliveryOrderMaster: 0,
  maxSizeVatRateDrs_DeliveryOrderMaster: 500,
  patternVatRateDrs_DeliveryOrderMaster: "",
  minSizeAmountDrs_DeliveryOrderMaster: 0,
  maxSizeAmountDrs_DeliveryOrderMaster: 500,
  patternAmountDrs_DeliveryOrderMaster: "",
  minSizeVatIncludeDrs_DeliveryOrderMaster: 0,
  maxSizeVatIncludeDrs_DeliveryOrderMaster: 500,
  patternVatIncludeDrs_DeliveryOrderMaster: "",
  minSizeBillingDate_DeliveryOrderMaster: 0,
  maxSizeBillingDate_DeliveryOrderMaster: 20,
  patternBillingDate_DeliveryOrderMaster: "",
  minSizeCustDelivery_DeliveryOrderMaster: 0,
  maxSizeCustDelivery_DeliveryOrderMaster: 250,
  patternCustDelivery_DeliveryOrderMaster: "",
  minSizeDeliveryOrderMasterId_DeliveryOrderMasterJobMaster: 0,
  maxSizeDeliveryOrderMasterId_DeliveryOrderMasterJobMaster: 19,
  patternDeliveryOrderMasterId_DeliveryOrderMasterJobMaster: "",
  minSizeJobMasterId_DeliveryOrderMasterJobMaster: 0,
  maxSizeJobMasterId_DeliveryOrderMasterJobMaster: 19,
  patternJobMasterId_DeliveryOrderMasterJobMaster: "",
  minSizeMemberId_Member: 0,
  maxSizeMemberId_Member: 19,
  patternMemberId_Member: "",
  minSizeMemberCode_Member: 0,
  maxSizeMemberCode_Member: 50,
  patternMemberCode_Member: "[0-9a-zA-Z/-]+",
  minSizeMemberName_Member: 0,
  maxSizeMemberName_Member: 150,
  patternMemberName_Member: "",
  minSizeMemberStatus_Member: 0,
  maxSizeMemberStatus_Member: 4,
  patternMemberStatus_Member: "",
  minSizeMemberLevel_Member: 0,
  maxSizeMemberLevel_Member: 20,
  patternMemberLevel_Member: "",
  minSizeStartDate_Member: 0,
  maxSizeStartDate_Member: 20,
  patternStartDate_Member: "",
  minSizeEndDate_Member: 0,
  maxSizeEndDate_Member: 20,
  patternEndDate_Member: "",
  minSizeCompanyCode_Member: 0,
  maxSizeCompanyCode_Member: 20,
  patternCompanyCode_Member: "[0-9a-zA-Z/-]+",
  minSizeMemberAddress_Member: 0,
  maxSizeMemberAddress_Member: 500,
  patternMemberAddress_Member: "",
  minSizeMemberTelNo_Member: 0,
  maxSizeMemberTelNo_Member: 100,
  patternMemberTelNo_Member: "",
  minSizeMemberTaxId_Member: 0,
  maxSizeMemberTaxId_Member: 13,
  patternMemberTaxId_Member: "",
  minSizeMemberLocation_Member: 0,
  maxSizeMemberLocation_Member: 50,
  patternMemberLocation_Member: "",
  minSizeAccountDefineId_AccDefineAccountCode: 0,
  maxSizeAccountDefineId_AccDefineAccountCode: 19,
  patternAccountDefineId_AccDefineAccountCode: "",
  minSizeAccountCodeId_AccDefineAccountCode: 0,
  maxSizeAccountCodeId_AccDefineAccountCode: 19,
  patternAccountCodeId_AccDefineAccountCode: "[0-9a-zA-Z/-]+",
  minSizeBkkAccountCodeId_AccDefineAccountCode: 0,
  maxSizeBkkAccountCodeId_AccDefineAccountCode: 19,
  patternBkkAccountCodeId_AccDefineAccountCode: "[0-9a-zA-Z/-]+",
  minSizeAccountCategoryId_AccountCategory: 0,
  maxSizeAccountCategoryId_AccountCategory: 19,
  patternAccountCategoryId_AccountCategory: "",
  minSizeCompanyCode_AccountCategory: 0,
  maxSizeCompanyCode_AccountCategory: 20,
  patternCompanyCode_AccountCategory: "[0-9a-zA-Z/-]+",
  minSizeAccountCodeId_AccountCode: 0,
  maxSizeAccountCodeId_AccountCode: 19,
  patternAccountCodeId_AccountCode: "[0-9a-zA-Z/-]+",
  minSizeAccCode_AccountCode: 0,
  maxSizeAccCode_AccountCode: 20,
  patternAccCode_AccountCode: "[0-9a-zA-Z/-]+",
  minSizeAccTName_AccountCode: 0,
  maxSizeAccTName_AccountCode: 150,
  patternAccTName_AccountCode: "",
  minSizeAccountGFMIsCode_AccountCode: 0,
  maxSizeAccountGFMIsCode_AccountCode: 20,
  patternAccountGFMIsCode_AccountCode: "[0-9a-zA-Z/-]+",
  minSizeAccountGFMIsName_AccountCode: 0,
  maxSizeAccountGFMIsName_AccountCode: 150,
  patternAccountGFMIsName_AccountCode: "",
  minSizeAccEName_AccountCode: 0,
  maxSizeAccEName_AccountCode: 150,
  patternAccEName_AccountCode: "",
  minSizeAccDivision_AccountCode: 0,
  maxSizeAccDivision_AccountCode: 150,
  patternAccDivision_AccountCode: "",
  minSizeAccJob_AccountCode: 0,
  maxSizeAccJob_AccountCode: 150,
  patternAccJob_AccountCode: "",
  minSizeAccAllocate_AccountCode: 0,
  maxSizeAccAllocate_AccountCode: 150,
  patternAccAllocate_AccountCode: "",
  minSizeCompanyCode_AccountCode: 0,
  maxSizeCompanyCode_AccountCode: 20,
  patternCompanyCode_AccountCode: "[0-9a-zA-Z/-]+",
  minSizeAccMainCode_AccountCode: 0,
  maxSizeAccMainCode_AccountCode: 20,
  patternAccMainCode_AccountCode: "[0-9a-zA-Z/-]+",
  minSizeAccountTypeId_AccountCode: 0,
  maxSizeAccountTypeId_AccountCode: 19,
  patternAccountTypeId_AccountCode: "",
  minSizeDivisionId_AccountCode: 0,
  maxSizeDivisionId_AccountCode: 19,
  patternDivisionId_AccountCode: "",
  minSizeAllocate_AccountCode: 0,
  maxSizeAllocate_AccountCode: 2,
  patternAllocate_AccountCode: "",
  minSizeAccountCTR_AccountCode: 0,
  maxSizeAccountCTR_AccountCode: 2,
  patternAccountCTR_AccountCode: "",
  minSizeDivisionCTRId_AccountCode: 0,
  maxSizeDivisionCTRId_AccountCode: 19,
  patternDivisionCTRId_AccountCode: "",
  minSizeSectionCTRId_AccountCode: 0,
  maxSizeSectionCTRId_AccountCode: 19,
  patternSectionCTRId_AccountCode: "",
  minSizeJobCTRId_AccountCode: 0,
  maxSizeJobCTRId_AccountCode: 19,
  patternJobCTRId_AccountCode: "",
  minSizeOldAccName_AccountCode: 0,
  maxSizeOldAccName_AccountCode: 200,
  patternOldAccName_AccountCode: "",
  minSizeCreateOn_AccountCode: 0,
  maxSizeCreateOn_AccountCode: 20,
  patternCreateOn_AccountCode: "",
  minSizeUpdateOn_AccountCode: 0,
  maxSizeUpdateOn_AccountCode: 20,
  patternUpdateOn_AccountCode: "",
  minSizeCreateByEMPId_AccountCode: 0,
  maxSizeCreateByEMPId_AccountCode: 19,
  patternCreateByEMPId_AccountCode: "",
  minSizeUpdateByEMPId_AccountCode: 0,
  maxSizeUpdateByEMPId_AccountCode: 19,
  patternUpdateByEMPId_AccountCode: "",
  minSizeAccountYear_AccountCode: 0,
  maxSizeAccountYear_AccountCode: 5,
  patternAccountYear_AccountCode: "",
  minSizeAccountDefineId_AccountDefine: 0,
  maxSizeAccountDefineId_AccountDefine: 19,
  patternAccountDefineId_AccountDefine: "",
  minSizeAccDefineCode_AccountDefine: 0,
  maxSizeAccDefineCode_AccountDefine: 255,
  patternAccDefineCode_AccountDefine: "[0-9a-zA-Z/-]+",
  minSizeAccDefineTName_AccountDefine: 0,
  maxSizeAccDefineTName_AccountDefine: 255,
  patternAccDefineTName_AccountDefine: "",
  minSizeAccDefineEName_AccountDefine: 0,
  maxSizeAccDefineEName_AccountDefine: 255,
  patternAccDefineEName_AccountDefine: "",
  minSizeAccDefineDescription_AccountDefine: 0,
  maxSizeAccDefineDescription_AccountDefine: 255,
  patternAccDefineDescription_AccountDefine: "",
  minSizeCompanyCode_AccountDefine: 0,
  maxSizeCompanyCode_AccountDefine: 20,
  patternCompanyCode_AccountDefine: "[0-9a-zA-Z/-]+",
  minSizeAccountDivisionId_AccountDivision: 0,
  maxSizeAccountDivisionId_AccountDivision: 19,
  patternAccountDivisionId_AccountDivision: "",
  minSizeCompanyCode_AccountDivision: 0,
  maxSizeCompanyCode_AccountDivision: 20,
  patternCompanyCode_AccountDivision: "[0-9a-zA-Z/-]+",
  minSizeAccountMainId_AccountMain: 0,
  maxSizeAccountMainId_AccountMain: 19,
  patternAccountMainId_AccountMain: "",
  minSizeAccTypeCode_AccountMain: 0,
  maxSizeAccTypeCode_AccountMain: 20,
  patternAccTypeCode_AccountMain: "[0-9a-zA-Z/-]+",
  minSizeCompanyCode_AccountMain: 0,
  maxSizeCompanyCode_AccountMain: 20,
  patternCompanyCode_AccountMain: "[0-9a-zA-Z/-]+",
  minSizeAccountPeriodId_AccountPeriod: 0,
  maxSizeAccountPeriodId_AccountPeriod: 19,
  patternAccountPeriodId_AccountPeriod: "",
  minSizePeriodYear_AccountPeriod: 0,
  maxSizePeriodYear_AccountPeriod: 255,
  patternPeriodYear_AccountPeriod: "",
  minSizePeriodNo_AccountPeriod: 0,
  maxSizePeriodNo_AccountPeriod: 10,
  patternPeriodNo_AccountPeriod: "",
  minSizePeriodSDate_AccountPeriod: 0,
  maxSizePeriodSDate_AccountPeriod: 255,
  patternPeriodSDate_AccountPeriod: "",
  minSizePeriodEDate_AccountPeriod: 0,
  maxSizePeriodEDate_AccountPeriod: 255,
  patternPeriodEDate_AccountPeriod: "",
  minSizePeriodStatus_AccountPeriod: 0,
  maxSizePeriodStatus_AccountPeriod: 255,
  patternPeriodStatus_AccountPeriod: "",
  minSizeRemark_AccountPeriod: 0,
  maxSizeRemark_AccountPeriod: 255,
  patternRemark_AccountPeriod: "",
  minSizeCompanyCode_AccountPeriod: 0,
  maxSizeCompanyCode_AccountPeriod: 20,
  patternCompanyCode_AccountPeriod: "[0-9a-zA-Z/-]+",
  minSizeFormCode_AccountPeriod: 0,
  maxSizeFormCode_AccountPeriod: 10,
  patternFormCode_AccountPeriod: "[0-9a-zA-Z/-]+",
  minSizeSystemId_AccountPeriod: 0,
  maxSizeSystemId_AccountPeriod: 19,
  patternSystemId_AccountPeriod: "",
  minSizePeriod_AccountPeriod: 0,
  maxSizePeriod_AccountPeriod: 10,
  patternPeriod_AccountPeriod: "[0-9]+",
  minSizeQuarter_AccountPeriod: 0,
  maxSizeQuarter_AccountPeriod: 10,
  patternQuarter_AccountPeriod: "",
  minSizeAccountTemplateDetailId_AccountTemplateDetail: 0,
  maxSizeAccountTemplateDetailId_AccountTemplateDetail: 19,
  patternAccountTemplateDetailId_AccountTemplateDetail: "",
  minSizeAccTemplateAccType_AccountTemplateDetail: 0,
  maxSizeAccTemplateAccType_AccountTemplateDetail: 255,
  patternAccTemplateAccType_AccountTemplateDetail: "",
  minSizeAccTemplateVat_AccountTemplateDetail: 0,
  maxSizeAccTemplateVat_AccountTemplateDetail: 255,
  patternAccTemplateVat_AccountTemplateDetail: "",
  minSizeCompanyCode_AccountTemplateDetail: 0,
  maxSizeCompanyCode_AccountTemplateDetail: 20,
  patternCompanyCode_AccountTemplateDetail: "[0-9a-zA-Z/-]+",
  minSizeAccountCodeId_AccountTemplateDetail: 0,
  maxSizeAccountCodeId_AccountTemplateDetail: 19,
  patternAccountCodeId_AccountTemplateDetail: "[0-9a-zA-Z/-]+",
  minSizeAccountTemplateId_AccountTemplateDetail: 0,
  maxSizeAccountTemplateId_AccountTemplateDetail: 19,
  patternAccountTemplateId_AccountTemplateDetail: "",
  minSizeFormulaLink_AccountTemplateDetail: 0,
  maxSizeFormulaLink_AccountTemplateDetail: 255,
  patternFormulaLink_AccountTemplateDetail: "",
  minSizeAccountTemplateId_AccountTemplate: 0,
  maxSizeAccountTemplateId_AccountTemplate: 19,
  patternAccountTemplateId_AccountTemplate: "",
  minSizeAccTemplateCode_AccountTemplate: 0,
  maxSizeAccTemplateCode_AccountTemplate: 255,
  patternAccTemplateCode_AccountTemplate: "[0-9a-zA-Z/-]+",
  minSizeAccTemplateName_AccountTemplate: 0,
  maxSizeAccTemplateName_AccountTemplate: 255,
  patternAccTemplateName_AccountTemplate: "",
  minSizeCompanyCode_AccountTemplate: 0,
  maxSizeCompanyCode_AccountTemplate: 20,
  patternCompanyCode_AccountTemplate: "[0-9a-zA-Z/-]+",
  minSizeJournalBookId_AccountTemplate: 0,
  maxSizeJournalBookId_AccountTemplate: 19,
  patternJournalBookId_AccountTemplate: "",
  minSizeLinkSys_AccountTemplate: 0,
  maxSizeLinkSys_AccountTemplate: 2,
  patternLinkSys_AccountTemplate: "",
  minSizeAccountTypeId_AccountType: 0,
  maxSizeAccountTypeId_AccountType: 19,
  patternAccountTypeId_AccountType: "",
  minSizeAccTypeCode_AccountType: 0,
  maxSizeAccTypeCode_AccountType: 20,
  patternAccTypeCode_AccountType: "[0-9a-zA-Z/-]+",
  minSizeAccTypeTName_AccountType: 0,
  maxSizeAccTypeTName_AccountType: 100,
  patternAccTypeTName_AccountType: "",
  minSizeAccTypeEName_AccountType: 0,
  maxSizeAccTypeEName_AccountType: 100,
  patternAccTypeEName_AccountType: "",
  minSizeAccTypeCompanytype_AccountType: 0,
  maxSizeAccTypeCompanytype_AccountType: 200,
  patternAccTypeCompanytype_AccountType: "",
  minSizeAccTypeENameReport_AccountType: 0,
  maxSizeAccTypeENameReport_AccountType: 200,
  patternAccTypeENameReport_AccountType: "",
  minSizeAccTypeTNameReport_AccountType: 0,
  maxSizeAccTypeTNameReport_AccountType: 200,
  patternAccTypeTNameReport_AccountType: "",
  minSizeCompanyCode_AccountType: 0,
  maxSizeCompanyCode_AccountType: 20,
  patternCompanyCode_AccountType: "[0-9a-zA-Z/-]+",
  minSizeAccGroupCode_AccountType: 0,
  maxSizeAccGroupCode_AccountType: 20,
  patternAccGroupCode_AccountType: "[0-9a-zA-Z/-]+",
  minSizeAccountCategoryId_AccountType: 0,
  maxSizeAccountCategoryId_AccountType: 19,
  patternAccountCategoryId_AccountType: "",
  minSizeAddressId_Address: 0,
  maxSizeAddressId_Address: 19,
  patternAddressId_Address: "",
  minSizeHouseNoStreet_Address: 0,
  maxSizeHouseNoStreet_Address: 500,
  patternHouseNoStreet_Address: "",
  minSizeDistrict_Address: 0,
  maxSizeDistrict_Address: 255,
  patternDistrict_Address: "",
  minSizeArea_Address: 0,
  maxSizeArea_Address: 255,
  patternArea_Address: "",
  minSizeProvinceName_Address: 0,
  maxSizeProvinceName_Address: 150,
  patternProvinceName_Address: "",
  minSizePostCode_Address: 0,
  maxSizePostCode_Address: 50,
  patternPostCode_Address: "[0-9a-zA-Z/-]+",
  minSizeTelNo_Address: 0,
  maxSizeTelNo_Address: 250,
  patternTelNo_Address: "",
  minSizeFaxNo_Address: 0,
  maxSizeFaxNo_Address: 100,
  patternFaxNo_Address: "",
  minSizeEmail_Address: 0,
  maxSizeEmail_Address: 100,
  patternEmail_Address: "",
  minSizeCompanyCode_Address: 0,
  maxSizeCompanyCode_Address: 20,
  patternCompanyCode_Address: "[0-9a-zA-Z/-]+",
  minSizeCountryMasterID_Address: 0,
  maxSizeCountryMasterID_Address: 19,
  patternCountryMasterID_Address: "",
  minSizeProvinceMasterId_Address: 0,
  maxSizeProvinceMasterId_Address: 19,
  patternProvinceMasterId_Address: "",
  minSizeAuthenSystemId_AuthenSystem: 0,
  maxSizeAuthenSystemId_AuthenSystem: 19,
  patternAuthenSystemId_AuthenSystem: "",
  minSizeStandardCode_AuthenSystem: 0,
  maxSizeStandardCode_AuthenSystem: 20,
  patternStandardCode_AuthenSystem: "[0-9a-zA-Z/-]+",
  minSizeStandardName_AuthenSystem: 0,
  maxSizeStandardName_AuthenSystem: 50,
  patternStandardName_AuthenSystem: "",
  minSizeAsRead_AuthenSystem: 0,
  maxSizeAsRead_AuthenSystem: 1,
  patternAsRead_AuthenSystem: "",
  minSizeAsWrite_AuthenSystem: 0,
  maxSizeAsWrite_AuthenSystem: 1,
  patternAsWrite_AuthenSystem: "",
  minSizeAsEdit_AuthenSystem: 0,
  maxSizeAsEdit_AuthenSystem: 1,
  patternAsEdit_AuthenSystem: "",
  minSizeAsDelete_AuthenSystem: 0,
  maxSizeAsDelete_AuthenSystem: 1,
  patternAsDelete_AuthenSystem: "",
  minSizeAsCancle_AuthenSystem: 0,
  maxSizeAsCancle_AuthenSystem: 1,
  patternAsCancle_AuthenSystem: "",
  minSizeRequireCode_AuthenSystem: 0,
  maxSizeRequireCode_AuthenSystem: 1,
  patternRequireCode_AuthenSystem: "[0-9a-zA-Z/-]+",
  minSizeAsPrint_AuthenSystem: 0,
  maxSizeAsPrint_AuthenSystem: 1,
  patternAsPrint_AuthenSystem: "",
  minSizeCreateOn_AuthenSystem: 0,
  maxSizeCreateOn_AuthenSystem: 20,
  patternCreateOn_AuthenSystem: "",
  minSizeUpdateOn_AuthenSystem: 0,
  maxSizeUpdateOn_AuthenSystem: 20,
  patternUpdateOn_AuthenSystem: "",
  minSizeCompanyCode_AuthenSystem: 0,
  maxSizeCompanyCode_AuthenSystem: 20,
  patternCompanyCode_AuthenSystem: "[0-9a-zA-Z/-]+",
  minSizeAsType_AuthenSystem: 0,
  maxSizeAsType_AuthenSystem: 2,
  patternAsType_AuthenSystem: "",
  minSizeDiscount_AuthenSystem: 0,
  maxSizeDiscount_AuthenSystem: 20,
  patternDiscount_AuthenSystem: "",
  minSizeCreateByEmpId_AuthenSystem: 0,
  maxSizeCreateByEmpId_AuthenSystem: 19,
  patternCreateByEmpId_AuthenSystem: "",
  minSizeUpdateByEmpId_AuthenSystem: 0,
  maxSizeUpdateByEmpId_AuthenSystem: 19,
  patternUpdateByEmpId_AuthenSystem: "",
  minSizeMenuMasterId_AuthenSystem: 0,
  maxSizeMenuMasterId_AuthenSystem: 19,
  patternMenuMasterId_AuthenSystem: "",
  minSizeTblUserLoginId_AuthenSystem: 0,
  maxSizeTblUserLoginId_AuthenSystem: 19,
  patternTblUserLoginId_AuthenSystem: "",
  minSizeBankBranchId_BankBranch: 0,
  maxSizeBankBranchId_BankBranch: 19,
  patternBankBranchId_BankBranch: "",
  minSizeBankBranchCode_BankBranch: 0,
  maxSizeBankBranchCode_BankBranch: 20,
  patternBankBranchCode_BankBranch: "[0-9a-zA-Z/-]+",
  minSizeBankBranchTName_BankBranch: 0,
  maxSizeBankBranchTName_BankBranch: 100,
  patternBankBranchTName_BankBranch: "",
  minSizeBankBranchEName_BankBranch: 0,
  maxSizeBankBranchEName_BankBranch: 100,
  patternBankBranchEName_BankBranch: "",
  minSizeBankName_BankBranch: 0,
  maxSizeBankName_BankBranch: 200,
  patternBankName_BankBranch: "",
  minSizeCompanyCode_BankBranch: 0,
  maxSizeCompanyCode_BankBranch: 20,
  patternCompanyCode_BankBranch: "[0-9a-zA-Z/-]+",
  minSizeBankId_BankBranch: 0,
  maxSizeBankId_BankBranch: 19,
  patternBankId_BankBranch: "",
  minSizeBankId_Bank: 0,
  maxSizeBankId_Bank: 19,
  patternBankId_Bank: "",
  minSizeBankCode_Bank: 0,
  maxSizeBankCode_Bank: 20,
  patternBankCode_Bank: "[0-9a-zA-Z/-]+",
  minSizeBankTName_Bank: 0,
  maxSizeBankTName_Bank: 100,
  patternBankTName_Bank: "",
  minSizeBankEName_Bank: 0,
  maxSizeBankEName_Bank: 100,
  patternBankEName_Bank: "",
  minSizeBankRemark_Bank: 0,
  maxSizeBankRemark_Bank: 200,
  patternBankRemark_Bank: "",
  minSizeCompanyCode_Bank: 0,
  maxSizeCompanyCode_Bank: 20,
  patternCompanyCode_Bank: "[0-9a-zA-Z/-]+",
  minSizeStatusSending_Bank: 0,
  maxSizeStatusSending_Bank: 1,
  patternStatusSending_Bank: "",
  minSizeVer_Bank: 0,
  maxSizeVer_Bank: 10,
  patternVer_Bank: "",
  minSizeBudgetSourceId_BudgetSource: 0,
  maxSizeBudgetSourceId_BudgetSource: 19,
  patternBudgetSourceId_BudgetSource: "",
  minSizeBudgetSourceCode_BudgetSource: 0,
  maxSizeBudgetSourceCode_BudgetSource: 20,
  patternBudgetSourceCode_BudgetSource: "[0-9a-zA-Z/-]+",
  minSizeBudgetSourceName_BudgetSource: 0,
  maxSizeBudgetSourceName_BudgetSource: 255,
  patternBudgetSourceName_BudgetSource: "",
  minSizeBudgetSourceEName_BudgetSource: 0,
  maxSizeBudgetSourceEName_BudgetSource: 255,
  patternBudgetSourceEName_BudgetSource: "",
  minSizeCompanyCode_BudgetSource: 0,
  maxSizeCompanyCode_BudgetSource: 255,
  patternCompanyCode_BudgetSource: "[0-9a-zA-Z/-]+",
  minSizeBudgetAmount_BudgetSource: 0,
  maxSizeBudgetAmount_BudgetSource: 26,
  patternBudgetAmount_BudgetSource: "[0-9]+",
  minSizeCompanyConsolidateId_CompanyConsolidate: 0,
  maxSizeCompanyConsolidateId_CompanyConsolidate: 19,
  patternCompanyConsolidateId_CompanyConsolidate: "",
  minSizeCompanyChildNo_CompanyConsolidate: 0,
  maxSizeCompanyChildNo_CompanyConsolidate: 10,
  patternCompanyChildNo_CompanyConsolidate: "",
  minSizeCompanyChildCode_CompanyConsolidate: 0,
  maxSizeCompanyChildCode_CompanyConsolidate: 20,
  patternCompanyChildCode_CompanyConsolidate: "[0-9a-zA-Z/-]+",
  minSizeCompanyChildName_CompanyConsolidate: 0,
  maxSizeCompanyChildName_CompanyConsolidate: 255,
  patternCompanyChildName_CompanyConsolidate: "",
  minSizeCompanyCode_CompanyConsolidate: 0,
  maxSizeCompanyCode_CompanyConsolidate: 255,
  patternCompanyCode_CompanyConsolidate: "[0-9a-zA-Z/-]+",
  minSizeCompanyId_Company: 0,
  maxSizeCompanyId_Company: 19,
  patternCompanyId_Company: "",
  minSizeCompanyCode_Company: 0,
  maxSizeCompanyCode_Company: 100,
  patternCompanyCode_Company: "[0-9a-zA-Z/-]+",
  minSizeCompanyTaxId_Company: 0,
  maxSizeCompanyTaxId_Company: 100,
  patternCompanyTaxId_Company: "[0-9a-zA-Z/-]+",
  minSizeCompanyTName_Company: 0,
  maxSizeCompanyTName_Company: 255,
  patternCompanyTName_Company: "",
  minSizeCompanyEName_Company: 0,
  maxSizeCompanyEName_Company: 255,
  patternCompanyEName_Company: "",
  minSizeCompanyBussTName_Company: 0,
  maxSizeCompanyBussTName_Company: 255,
  patternCompanyBussTName_Company: "",
  minSizeCompanyBussEName_Company: 0,
  maxSizeCompanyBussEName_Company: 255,
  patternCompanyBussEName_Company: "",
  minSizeCompanyDetail_Company: 0,
  maxSizeCompanyDetail_Company: 2000,
  patternCompanyDetail_Company: "",
  minSizeCompanyTAddress_Company: 0,
  maxSizeCompanyTAddress_Company: 255,
  patternCompanyTAddress_Company: "",
  minSizeCompanyEAddress_Company: 0,
  maxSizeCompanyEAddress_Company: 255,
  patternCompanyEAddress_Company: "",
  minSizeCompanyTelephone_Company: 0,
  maxSizeCompanyTelephone_Company: 100,
  patternCompanyTelephone_Company: "",
  minSizeCompanyFaxNo_Company: 0,
  maxSizeCompanyFaxNo_Company: 100,
  patternCompanyFaxNo_Company: "",
  minSizeCompanyEmail_Company: 0,
  maxSizeCompanyEmail_Company: 100,
  patternCompanyEmail_Company: "",
  minSizeCompanyHomePage_Company: 0,
  maxSizeCompanyHomePage_Company: 100,
  patternCompanyHomePage_Company: "",
  minSizeCompanyLogo_Company: 0,
  maxSizeCompanyLogo_Company: 255,
  patternCompanyLogo_Company: "",
  minSizeCompanyUserLimit_Company: 0,
  maxSizeCompanyUserLimit_Company: 10,
  patternCompanyUserLimit_Company: "",
  minSizeCompanyStartDate_Company: 0,
  maxSizeCompanyStartDate_Company: 100,
  patternCompanyStartDate_Company: "",
  minSizeCompanyEndDate_Company: 0,
  maxSizeCompanyEndDate_Company: 100,
  patternCompanyEndDate_Company: "",
  minSizeCompanyAccount_Company: 0,
  maxSizeCompanyAccount_Company: 10,
  patternCompanyAccount_Company: "",
  minSizeCompanyAutoApprove_Company: 0,
  maxSizeCompanyAutoApprove_Company: 2,
  patternCompanyAutoApprove_Company: "",
  minSizeCompanyUsePos_Company: 0,
  maxSizeCompanyUsePos_Company: 2,
  patternCompanyUsePos_Company: "",
  minSizePricingForCust_Company: 0,
  maxSizePricingForCust_Company: 2,
  patternPricingForCust_Company: "",
  minSizeUserAuthen_Company: 0,
  maxSizeUserAuthen_Company: 2,
  patternUserAuthen_Company: "",
  minSizeIsUseCost_Company: 0,
  maxSizeIsUseCost_Company: 1,
  patternIsUseCost_Company: "",
  minSizeStatus_Company: 0,
  maxSizeStatus_Company: 1,
  patternStatus_Company: "",
  minSizeTypeBusiness_Company: 0,
  maxSizeTypeBusiness_Company: 2,
  patternTypeBusiness_Company: "",
  minSizeIsUseProductDependonSupplier_Company: 0,
  maxSizeIsUseProductDependonSupplier_Company: 1,
  patternIsUseProductDependonSupplier_Company: "",
  minSizeCountryMasterId_Company: 0,
  maxSizeCountryMasterId_Company: 19,
  patternCountryMasterId_Company: "",
  minSizeCurrencyId_Company: 0,
  maxSizeCurrencyId_Company: 19,
  patternCurrencyId_Company: "",
  minSizeSsoAccount_Company: 0,
  maxSizeSsoAccount_Company: 20,
  patternSsoAccount_Company: "",
  minSizeSupProd_Company: 0,
  maxSizeSupProd_Company: 2,
  patternSupProd_Company: "",
  minSizeVatExcept_Company: 0,
  maxSizeVatExcept_Company: 2,
  patternVatExcept_Company: "",
  minSizeJobAuto_Company: 0,
  maxSizeJobAuto_Company: 2,
  patternJobAuto_Company: "",
  minSizeCountryMasterId_CountryMaster: 0,
  maxSizeCountryMasterId_CountryMaster: 19,
  patternCountryMasterId_CountryMaster: "",
  minSizeCode_CountryMaster: 0,
  maxSizeCode_CountryMaster: 100,
  patternCode_CountryMaster: "[0-9a-zA-Z/-]+",
  minSizetName_CountryMaster: 0,
  maxSizetName_CountryMaster: 255,
  patterntName_CountryMaster: "",
  minSizeeName_CountryMaster: 0,
  maxSizeeName_CountryMaster: 255,
  patterneName_CountryMaster: "",
  minSizeRemark_CountryMaster: 0,
  maxSizeRemark_CountryMaster: 255,
  patternRemark_CountryMaster: "",
  minSizeCurrencyId_Currency: 0,
  maxSizeCurrencyId_Currency: 19,
  patternCurrencyId_Currency: "",
  minSizeCurrencyCode_Currency: 0,
  maxSizeCurrencyCode_Currency: 20,
  patternCurrencyCode_Currency: "[0-9a-zA-Z/-]+",
  minSizeCurrencyTName_Currency: 0,
  maxSizeCurrencyTName_Currency: 50,
  patternCurrencyTName_Currency: "",
  minSizeCurrencyEName_Currency: 0,
  maxSizeCurrencyEName_Currency: 50,
  patternCurrencyEName_Currency: "",
  minSizeCompanyCode_Currency: 0,
  maxSizeCompanyCode_Currency: 20,
  patternCompanyCode_Currency: "[0-9a-zA-Z/-]+",
  minSizeDefaultCurrencyId_Currency: 0,
  maxSizeDefaultCurrencyId_Currency: 19,
  patternDefaultCurrencyId_Currency: "",
  minSizeCustomerMasterId_CustMasterAddress: 0,
  maxSizeCustomerMasterId_CustMasterAddress: 19,
  patternCustomerMasterId_CustMasterAddress: "",
  minSizeAddressId_CustMasterAddress: 0,
  maxSizeAddressId_CustMasterAddress: 19,
  patternAddressId_CustMasterAddress: "",
  minSizeCustomerMasterId_CustMasterCustContact: 0,
  maxSizeCustomerMasterId_CustMasterCustContact: 19,
  patternCustomerMasterId_CustMasterCustContact: "",
  minSizeCustomerContactId_CustMasterCustContact: 0,
  maxSizeCustomerContactId_CustMasterCustContact: 19,
  patternCustomerContactId_CustMasterCustContact: "",
  minSizeCustomerMasterId_CustMasterCustCredit: 0,
  maxSizeCustomerMasterId_CustMasterCustCredit: 19,
  patternCustomerMasterId_CustMasterCustCredit: "",
  minSizeCustomerCreditId_CustMasterCustCredit: 0,
  maxSizeCustomerCreditId_CustMasterCustCredit: 19,
  patternCustomerCreditId_CustMasterCustCredit: "",
  minSizeCustomerMasterId_CustMasterCustDetail: 0,
  maxSizeCustomerMasterId_CustMasterCustDetail: 19,
  patternCustomerMasterId_CustMasterCustDetail: "",
  minSizeCustomerDetailId_CustMasterCustDetail: 0,
  maxSizeCustomerDetailId_CustMasterCustDetail: 19,
  patternCustomerDetailId_CustMasterCustDetail: "",
  minSizeCustomerContactId_CustomerContact: 0,
  maxSizeCustomerContactId_CustomerContact: 19,
  patternCustomerContactId_CustomerContact: "",
  minSizeCustContPer_CustomerContact: 0,
  maxSizeCustContPer_CustomerContact: 100,
  patternCustContPer_CustomerContact: "",
  minSizeCustContTel_CustomerContact: 0,
  maxSizeCustContTel_CustomerContact: 80,
  patternCustContTel_CustomerContact: "",
  minSizeCustContPosition_CustomerContact: 0,
  maxSizeCustContPosition_CustomerContact: 255,
  patternCustContPosition_CustomerContact: "",
  minSizeCustContResponsibility_CustomerContact: 0,
  maxSizeCustContResponsibility_CustomerContact: 100,
  patternCustContResponsibility_CustomerContact: "",
  minSizeCustContAddress_CustomerContact: 0,
  maxSizeCustContAddress_CustomerContact: 255,
  patternCustContAddress_CustomerContact: "",
  minSizeCustContDefault_CustomerContact: 0,
  maxSizeCustContDefault_CustomerContact: 100,
  patternCustContDefault_CustomerContact: "",
  minSizeCompanyCode_CustomerContact: 0,
  maxSizeCompanyCode_CustomerContact: 20,
  patternCompanyCode_CustomerContact: "[0-9a-zA-Z/-]+",
  minSizeCustomerCreditId_CustomerCredit: 0,
  maxSizeCustomerCreditId_CustomerCredit: 19,
  patternCustomerCreditId_CustomerCredit: "",
  minSizeCustCreditTerm_CustomerCredit: 0,
  maxSizeCustCreditTerm_CustomerCredit: 10,
  patternCustCreditTerm_CustomerCredit: "[0-9]+",
  minSizeCustCreditLimit_CustomerCredit: 0,
  maxSizeCustCreditLimit_CustomerCredit: 26,
  patternCustCreditLimit_CustomerCredit: "[0-9]+",
  minSizeCustCreditBalance_CustomerCredit: 0,
  maxSizeCustCreditBalance_CustomerCredit: 26,
  patternCustCreditBalance_CustomerCredit: "",
  minSizeCustPricelevel_CustomerCredit: 0,
  maxSizeCustPricelevel_CustomerCredit: 10,
  patternCustPricelevel_CustomerCredit: "",
  minSizeCustDiscAmount_CustomerCredit: 0,
  maxSizeCustDiscAmount_CustomerCredit: 26,
  patternCustDiscAmount_CustomerCredit: "",
  minSizeCustDiscRate_CustomerCredit: 0,
  maxSizeCustDiscRate_CustomerCredit: 26,
  patternCustDiscRate_CustomerCredit: "",
  minSizeCustCustomCreditTerm_CustomerCredit: 0,
  maxSizeCustCustomCreditTerm_CustomerCredit: 10,
  patternCustCustomCreditTerm_CustomerCredit: "",
  minSizeCustAlertType_CustomerCredit: 0,
  maxSizeCustAlertType_CustomerCredit: 255,
  patternCustAlertType_CustomerCredit: "",
  minSizeCustDiscInTimeDay_CustomerCredit: 0,
  maxSizeCustDiscInTimeDay_CustomerCredit: 10,
  patternCustDiscInTimeDay_CustomerCredit: "",
  minSizeCustDiscInTimeRate_CustomerCredit: 0,
  maxSizeCustDiscInTimeRate_CustomerCredit: 26,
  patternCustDiscInTimeRate_CustomerCredit: "",
  minSizeCustBillCond_CustomerCredit: 0,
  maxSizeCustBillCond_CustomerCredit: 255,
  patternCustBillCond_CustomerCredit: "",
  minSizeCompanyCode_CustomerCredit: 0,
  maxSizeCompanyCode_CustomerCredit: 20,
  patternCompanyCode_CustomerCredit: "[0-9a-zA-Z/-]+",
  minSizeCustomerDetailId_CustomerDetail: 0,
  maxSizeCustomerDetailId_CustomerDetail: 19,
  patternCustomerDetailId_CustomerDetail: "",
  minSizeCustDate_CustomerDetail: 0,
  maxSizeCustDate_CustomerDetail: 100,
  patternCustDate_CustomerDetail: "",
  minSizeCustTaxIdNo_CustomerDetail: 0,
  maxSizeCustTaxIdNo_CustomerDetail: 100,
  patternCustTaxIdNo_CustomerDetail: "",
  minSizeCompanyCode_CustomerDetail: 0,
  maxSizeCompanyCode_CustomerDetail: 20,
  patternCompanyCode_CustomerDetail: "[0-9a-zA-Z/-]+",
  minSizeCustomerGroup_CustomerDetail: 0,
  maxSizeCustomerGroup_CustomerDetail: 19,
  patternCustomerGroup_CustomerDetail: "",
  minSizeAccountCode_CustomerDetail: 0,
  maxSizeAccountCode_CustomerDetail: 19,
  patternAccountCode_CustomerDetail: "[0-9a-zA-Z/-]+",
  minSizeCustomerGroupId_CustomerGroup: 0,
  maxSizeCustomerGroupId_CustomerGroup: 19,
  patternCustomerGroupId_CustomerGroup: "",
  minSizeCompanyCode_CustomerGroup: 0,
  maxSizeCompanyCode_CustomerGroup: 20,
  patternCompanyCode_CustomerGroup: "[0-9a-zA-Z/-]+",
  minSizeAccountCode_CustomerGroup: 0,
  maxSizeAccountCode_CustomerGroup: 19,
  patternAccountCode_CustomerGroup: "[0-9a-zA-Z/-]+",
  minSizeCustomerMasterId_CustomerMaster: 0,
  maxSizeCustomerMasterId_CustomerMaster: 19,
  patternCustomerMasterId_CustomerMaster: "",
  minSizeCustCode_CustomerMaster: 0,
  maxSizeCustCode_CustomerMaster: 50,
  patternCustCode_CustomerMaster: "[0-9a-zA-Z/-]+",
  minSizeCustTName_CustomerMaster: 0,
  maxSizeCustTName_CustomerMaster: 150,
  patternCustTName_CustomerMaster: "",
  minSizeCustEName_CustomerMaster: 0,
  maxSizeCustEName_CustomerMaster: 150,
  patternCustEName_CustomerMaster: "",
  minSizeCustAllowOver_CustomerMaster: 0,
  maxSizeCustAllowOver_CustomerMaster: 250,
  patternCustAllowOver_CustomerMaster: "",
  minSizeCustStatus_CustomerMaster: 0,
  maxSizeCustStatus_CustomerMaster: 15,
  patternCustStatus_CustomerMaster: "",
  minSizeCustBillAddress_CustomerMaster: 0,
  maxSizeCustBillAddress_CustomerMaster: 250,
  patternCustBillAddress_CustomerMaster: "",
  minSizeCustBillName_CustomerMaster: 0,
  maxSizeCustBillName_CustomerMaster: 250,
  patternCustBillName_CustomerMaster: "",
  minSizeCustDeliveryAddress_CustomerMaster: 0,
  maxSizeCustDeliveryAddress_CustomerMaster: 250,
  patternCustDeliveryAddress_CustomerMaster: "",
  minSizeCustCurrentBalance_CustomerMaster: 0,
  maxSizeCustCurrentBalance_CustomerMaster: 26,
  patternCustCurrentBalance_CustomerMaster: "",
  minSizeCompanyCode_CustomerMaster: 0,
  maxSizeCompanyCode_CustomerMaster: 20,
  patternCompanyCode_CustomerMaster: "[0-9a-zA-Z/-]+",
  minSizeDefaultC_CustomerMaster: 0,
  maxSizeDefaultC_CustomerMaster: 2,
  patternDefaultC_CustomerMaster: "",
  minSizeStatusSending_CustomerMaster: 0,
  maxSizeStatusSending_CustomerMaster: 1,
  patternStatusSending_CustomerMaster: "",
  minSizeVer_CustomerMaster: 0,
  maxSizeVer_CustomerMaster: 10,
  patternVer_CustomerMaster: "",
  minSizeNId_CustomerMaster: 0,
  maxSizeNId_CustomerMaster: 13,
  patternNId_CustomerMaster: "",
  minSizeBranchNumber_CustomerMaster: 0,
  maxSizeBranchNumber_CustomerMaster: 255,
  patternBranchNumber_CustomerMaster: "",
  minSizeBranchTitle_CustomerMaster: 0,
  maxSizeBranchTitle_CustomerMaster: 255,
  patternBranchTitle_CustomerMaster: "",
  minSizeBranchName_CustomerMaster: 0,
  maxSizeBranchName_CustomerMaster: 255,
  patternBranchName_CustomerMaster: "",
  minSizeBuildingName_CustomerMaster: 0,
  maxSizeBuildingName_CustomerMaster: 255,
  patternBuildingName_CustomerMaster: "",
  minSizeRoomNumber_CustomerMaster: 0,
  maxSizeRoomNumber_CustomerMaster: 255,
  patternRoomNumber_CustomerMaster: "",
  minSizeFloorNumber_CustomerMaster: 0,
  maxSizeFloorNumber_CustomerMaster: 255,
  patternFloorNumber_CustomerMaster: "",
  minSizeVillageName_CustomerMaster: 0,
  maxSizeVillageName_CustomerMaster: 255,
  patternVillageName_CustomerMaster: "",
  minSizeHouseNumber_CustomerMaster: 0,
  maxSizeHouseNumber_CustomerMaster: 255,
  patternHouseNumber_CustomerMaster: "",
  minSizeMooNumber_CustomerMaster: 0,
  maxSizeMooNumber_CustomerMaster: 255,
  patternMooNumber_CustomerMaster: "",
  minSizeSoiName_CustomerMaster: 0,
  maxSizeSoiName_CustomerMaster: 255,
  patternSoiName_CustomerMaster: "",
  minSizeStreetName_CustomerMaster: 0,
  maxSizeStreetName_CustomerMaster: 255,
  patternStreetName_CustomerMaster: "",
  minSizeThumbolName_CustomerMaster: 0,
  maxSizeThumbolName_CustomerMaster: 255,
  patternThumbolName_CustomerMaster: "",
  minSizeAmphurName_CustomerMaster: 0,
  maxSizeAmphurName_CustomerMaster: 255,
  patternAmphurName_CustomerMaster: "",
  minSizeProvinceName_CustomerMaster: 0,
  maxSizeProvinceName_CustomerMaster: 255,
  patternProvinceName_CustomerMaster: "",
  minSizePostCode_CustomerMaster: 0,
  maxSizePostCode_CustomerMaster: 255,
  patternPostCode_CustomerMaster: "[0-9a-zA-Z/-]+",
  minSizeBusinessFirstDate_CustomerMaster: 0,
  maxSizeBusinessFirstDate_CustomerMaster: 255,
  patternBusinessFirstDate_CustomerMaster: "",
  minSizeCurrencyId_CustomerMaster: 0,
  maxSizeCurrencyId_CustomerMaster: 19,
  patternCurrencyId_CustomerMaster: "",
  minSizeCountryMasterId_CustomerMaster: 0,
  maxSizeCountryMasterId_CustomerMaster: 19,
  patternCountryMasterId_CustomerMaster: "",
  minSizeCreateon_CustomerMaster: 0,
  maxSizeCreateon_CustomerMaster: 20,
  patternCreateon_CustomerMaster: "",
  minSizeUpdateOn_CustomerMaster: 0,
  maxSizeUpdateOn_CustomerMaster: 20,
  patternUpdateOn_CustomerMaster: "",
  minSizeCreateById_CustomerMaster: 0,
  maxSizeCreateById_CustomerMaster: 19,
  patternCreateById_CustomerMaster: "",
  minSizeUpdateById_CustomerMaster: 0,
  maxSizeUpdateById_CustomerMaster: 19,
  patternUpdateById_CustomerMaster: "",
  minSizeDivisionId_CustomerMaster: 0,
  maxSizeDivisionId_CustomerMaster: 19,
  patternDivisionId_CustomerMaster: "",
  minSizeSectionId_CustomerMaster: 0,
  maxSizeSectionId_CustomerMaster: 19,
  patternSectionId_CustomerMaster: "",
  minSizeCustBilladdresseName_CustomerMaster: 0,
  maxSizeCustBilladdresseName_CustomerMaster: 500,
  patternCustBilladdresseName_CustomerMaster: "",
  minSizeEmail_CustomerMaster: 0,
  maxSizeEmail_CustomerMaster: 100,
  patternEmail_CustomerMaster: "",
  minSizeValidPrice_CustomerMaster: 0,
  maxSizeValidPrice_CustomerMaster: 20,
  patternValidPrice_CustomerMaster: "",
  minSizeContactName_CustomerMaster: 0,
  maxSizeContactName_CustomerMaster: 200,
  patternContactName_CustomerMaster: "",
  minSizePassword_CustomerMaster: 0,
  maxSizePassword_CustomerMaster: 20,
  patternPassword_CustomerMaster: "",
  minSizePriceListMasterId_CustomerMaster: 0,
  maxSizePriceListMasterId_CustomerMaster: 19,
  patternPriceListMasterId_CustomerMaster: "",
  minSizeSaleArea1Id_CustomerMaster: 0,
  maxSizeSaleArea1Id_CustomerMaster: 19,
  patternSaleArea1Id_CustomerMaster: "",
  minSizeRemark_CustomerMaster: 0,
  maxSizeRemark_CustomerMaster: 500,
  patternRemark_CustomerMaster: "",
  minSizeBillingStatus_CustomerMaster: 0,
  maxSizeBillingStatus_CustomerMaster: 2,
  patternBillingStatus_CustomerMaster: "",
  minSizeBillingDate_CustomerMaster: 0,
  maxSizeBillingDate_CustomerMaster: 10,
  patternBillingDate_CustomerMaster: "",
  minSizeCustLocation_CustomerMaster: 0,
  maxSizeCustLocation_CustomerMaster: 50,
  patternCustLocation_CustomerMaster: "",
  minSizeCustomerOpeningMasterId_CustomerOpeningMaster: 0,
  maxSizeCustomerOpeningMasterId_CustomerOpeningMaster: 19,
  patternCustomerOpeningMasterId_CustomerOpeningMaster: "",
  minSizeCustOpenBillDocNo_CustomerOpeningMaster: 0,
  maxSizeCustOpenBillDocNo_CustomerOpeningMaster: 20,
  patternCustOpenBillDocNo_CustomerOpeningMaster: "",
  minSizeCustOpenBillDocDate_CustomerOpeningMaster: 0,
  maxSizeCustOpenBillDocDate_CustomerOpeningMaster: 20,
  patternCustOpenBillDocDate_CustomerOpeningMaster: "",
  minSizeCustOpenBillDueDate_CustomerOpeningMaster: 0,
  maxSizeCustOpenBillDueDate_CustomerOpeningMaster: 20,
  patternCustOpenBillDueDate_CustomerOpeningMaster: "",
  minSizeCustOpenBillTotal_CustomerOpeningMaster: 0,
  maxSizeCustOpenBillTotal_CustomerOpeningMaster: 26,
  patternCustOpenBillTotal_CustomerOpeningMaster: "^[-?0-9]+(.[0-9]{2})?$",
  minSizeCustOpenBillNetTotal_CustomerOpeningMaster: 0,
  maxSizeCustOpenBillNetTotal_CustomerOpeningMaster: 26,
  patternCustOpenBillNetTotal_CustomerOpeningMaster: "",
  minSizeCustOpenBillReceiptAmt_CustomerOpeningMaster: 0,
  maxSizeCustOpenBillReceiptAmt_CustomerOpeningMaster: 26,
  patternCustOpenBillReceiptAmt_CustomerOpeningMaster: "",
  minSizeCustOpenBillRemark_CustomerOpeningMaster: 0,
  maxSizeCustOpenBillRemark_CustomerOpeningMaster: 150,
  patternCustOpenBillRemark_CustomerOpeningMaster: "",
  minSizeCustOpenBillStatus_CustomerOpeningMaster: 0,
  maxSizeCustOpenBillStatus_CustomerOpeningMaster: 2,
  patternCustOpenBillStatus_CustomerOpeningMaster: "",
  minSizeCustOpenBillLinkDoc_CustomerOpeningMaster: 0,
  maxSizeCustOpenBillLinkDoc_CustomerOpeningMaster: 20,
  patternCustOpenBillLinkDoc_CustomerOpeningMaster: "",
  minSizeCustOpenBeforeChangeStatus_CustomerOpeningMaster: 0,
  maxSizeCustOpenBeforeChangeStatus_CustomerOpeningMaster: 2,
  patternCustOpenBeforeChangeStatus_CustomerOpeningMaster: "",
  minSizeCreateOn_CustomerOpeningMaster: 0,
  maxSizeCreateOn_CustomerOpeningMaster: 20,
  patternCreateOn_CustomerOpeningMaster: "",
  minSizeUpdateOn_CustomerOpeningMaster: 0,
  maxSizeUpdateOn_CustomerOpeningMaster: 20,
  patternUpdateOn_CustomerOpeningMaster: "",
  minSizeCustOpenLinkBillingNoteDocNo_CustomerOpeningMaster: 0,
  maxSizeCustOpenLinkBillingNoteDocNo_CustomerOpeningMaster: 20,
  patternCustOpenLinkBillingNoteDocNo_CustomerOpeningMaster: "",
  minSizeCompanyCode_CustomerOpeningMaster: 0,
  maxSizeCompanyCode_CustomerOpeningMaster: 20,
  patternCompanyCode_CustomerOpeningMaster: "[0-9a-zA-Z/-]+",
  minSizeAStatus_CustomerOpeningMaster: 0,
  maxSizeAStatus_CustomerOpeningMaster: 2,
  patternAStatus_CustomerOpeningMaster: "",
  minSizeDocumentGroupId_CustomerOpeningMaster: 0,
  maxSizeDocumentGroupId_CustomerOpeningMaster: 19,
  patternDocumentGroupId_CustomerOpeningMaster: "",
  minSizeCustomerMasterId_CustomerOpeningMaster: 0,
  maxSizeCustomerMasterId_CustomerOpeningMaster: 19,
  patternCustomerMasterId_CustomerOpeningMaster: "",
  minSizeOfficeRempMasterId_CustomerOpeningMaster: 0,
  maxSizeOfficeRempMasterId_CustomerOpeningMaster: 19,
  patternOfficeRempMasterId_CustomerOpeningMaster: "",
  minSizeDivisionId_CustomerOpeningMaster: 0,
  maxSizeDivisionId_CustomerOpeningMaster: 19,
  patternDivisionId_CustomerOpeningMaster: "",
  minSizeSectionId_CustomerOpeningMaster: 0,
  maxSizeSectionId_CustomerOpeningMaster: 19,
  patternSectionId_CustomerOpeningMaster: "",
  minSizeCreateByEmpId_CustomerOpeningMaster: 0,
  maxSizeCreateByEmpId_CustomerOpeningMaster: 19,
  patternCreateByEmpId_CustomerOpeningMaster: "",
  minSizeUpdateByEmpId_CustomerOpeningMaster: 0,
  maxSizeUpdateByEmpId_CustomerOpeningMaster: 19,
  patternUpdateByEmpId_CustomerOpeningMaster: "",
  minSizeExchangeRate_CustomerOpeningMaster: 0,
  maxSizeExchangeRate_CustomerOpeningMaster: 26,
  patternExchangeRate_CustomerOpeningMaster: "[0-9.]+",
  minSizeCustomerSubGroup1Id_CustomerSubGroup1: 0,
  maxSizeCustomerSubGroup1Id_CustomerSubGroup1: 19,
  patternCustomerSubGroup1Id_CustomerSubGroup1: "",
  minSizeCompanyCode_CustomerSubGroup1: 0,
  maxSizeCompanyCode_CustomerSubGroup1: 20,
  patternCompanyCode_CustomerSubGroup1: "[0-9a-zA-Z/-]+",
  minSizeCustomerSubGroup2Id_CustomerSubGroup2: 0,
  maxSizeCustomerSubGroup2Id_CustomerSubGroup2: 19,
  patternCustomerSubGroup2Id_CustomerSubGroup2: "",
  minSizeCompanyCode_CustomerSubGroup2: 0,
  maxSizeCompanyCode_CustomerSubGroup2: 20,
  patternCompanyCode_CustomerSubGroup2: "[0-9a-zA-Z/-]+",
  minSizeCustomerSubGroup3Id_CustomerSubGroup3: 0,
  maxSizeCustomerSubGroup3Id_CustomerSubGroup3: 19,
  patternCustomerSubGroup3Id_CustomerSubGroup3: "",
  minSizeCompanyCode_CustomerSubGroup3: 0,
  maxSizeCompanyCode_CustomerSubGroup3: 20,
  patternCompanyCode_CustomerSubGroup3: "[0-9a-zA-Z/-]+",
  minSizeDefaultCurrencyId_DefaultCurrency: 0,
  maxSizeDefaultCurrencyId_DefaultCurrency: 19,
  patternDefaultCurrencyId_DefaultCurrency: "",
  minSizeDefaultCurrencyCode_DefaultCurrency: 0,
  maxSizeDefaultCurrencyCode_DefaultCurrency: 20,
  patternDefaultCurrencyCode_DefaultCurrency: "[0-9a-zA-Z/-]+",
  minSizeDefaultCurrencyName_DefaultCurrency: 0,
  maxSizeDefaultCurrencyName_DefaultCurrency: 50,
  patternDefaultCurrencyName_DefaultCurrency: "",
  minSizeDeliverId_Deliver: 0,
  maxSizeDeliverId_Deliver: 19,
  patternDeliverId_Deliver: "",
  minSizeDeliverCode_Deliver: 0,
  maxSizeDeliverCode_Deliver: 20,
  patternDeliverCode_Deliver: "[0-9a-zA-Z/-]+",
  minSizeDeliverName_Deliver: 0,
  maxSizeDeliverName_Deliver: 255,
  patternDeliverName_Deliver: "",
  minSizeDeliverType_Deliver: 0,
  maxSizeDeliverType_Deliver: 20,
  patternDeliverType_Deliver: "",
  minSizeSize_Deliver: 0,
  maxSizeSize_Deliver: 26,
  patternSize_Deliver: "",
  minSizeCc_Deliver: 0,
  maxSizeCc_Deliver: 26,
  patternCc_Deliver: "",
  minSizeBrand_Deliver: 0,
  maxSizeBrand_Deliver: 50,
  patternBrand_Deliver: "",
  minSizeRemark_Deliver: 0,
  maxSizeRemark_Deliver: 1500,
  patternRemark_Deliver: "",
  minSizeRemark1_Deliver: 0,
  maxSizeRemark1_Deliver: 1500,
  patternRemark1_Deliver: "",
  minSizeRemark2_Deliver: 0,
  maxSizeRemark2_Deliver: 1500,
  patternRemark2_Deliver: "",
  minSizeRemark3_Deliver: 0,
  maxSizeRemark3_Deliver: 1500,
  patternRemark3_Deliver: "",
  minSizeRemark4_Deliver: 0,
  maxSizeRemark4_Deliver: 1500,
  patternRemark4_Deliver: "",
  minSizeLicenseDate_Deliver: 0,
  maxSizeLicenseDate_Deliver: 20,
  patternLicenseDate_Deliver: "",
  minSizeLicenseDate1_Deliver: 0,
  maxSizeLicenseDate1_Deliver: 20,
  patternLicenseDate1_Deliver: "",
  minSizeCompanyCode_Deliver: 0,
  maxSizeCompanyCode_Deliver: 20,
  patternCompanyCode_Deliver: "[0-9a-zA-Z/-]+",
  minSizeEmployeeMasterId_Deliver: 0,
  maxSizeEmployeeMasterId_Deliver: 19,
  patternEmployeeMasterId_Deliver: "",

  minSizeDeliveryDocumentId_DeliveryDocument: 0,
  maxSizeDeliveryDocumentId_DeliveryDocument: 19,
  patternDeliveryDocumentId_DeliveryDocument: "",
  minSizeBookingNo_DeliveryDocument: 0,
  maxSizeBookingNo_DeliveryDocument: 20,
  patternBookingNo_DeliveryDocument: "[0-9a-zA-Z/-]+",
  minSizeCompanyCode_DeliveryDocument: 0,
  maxSizeCompanyCode_DeliveryDocument: 20,
  patternCompanyCode_DeliveryDocument: "[0-9a-zA-Z/-]+",
  minSizeConsignee_DeliveryDocument: 0,
  maxSizeConsignee_DeliveryDocument: 200,
  patternConsignee_DeliveryDocument: "",
  minSizeExportReferrent_DeliveryDocument: 0,
  maxSizeExportReferrent_DeliveryDocument: 100,
  patternExportReferrent_DeliveryDocument: "",
  minSizeInittialCarriage_DeliveryDocument: 0,
  maxSizeInittialCarriage_DeliveryDocument: 100,
  patternInittialCarriage_DeliveryDocument: "",
  minSizePlaceOfInitialReceipt_DeliveryDocument: 0,
  maxSizePlaceOfInitialReceipt_DeliveryDocument: 100,
  patternPlaceOfInitialReceipt_DeliveryDocument: "",
  minSizeForwardBy_DeliveryDocument: 0,
  maxSizeForwardBy_DeliveryDocument: 100,
  patternForwardBy_DeliveryDocument: "",
  minSizeFreight_DeliveryDocument: 0,
  maxSizeFreight_DeliveryDocument: 100,
  patternFreight_DeliveryDocument: "",
  minSizeLiner_DeliveryDocument: 0,
  maxSizeLiner_DeliveryDocument: 100,
  patternLiner_DeliveryDocument: "",
  minSizeFeederVessel_DeliveryDocument: 0,
  maxSizeFeederVessel_DeliveryDocument: 100,
  patternFeederVessel_DeliveryDocument: "",
  minSizeFeederVoyage_DeliveryDocument: 0,
  maxSizeFeederVoyage_DeliveryDocument: 100,
  patternFeederVoyage_DeliveryDocument: "",
  minSizeMotherVessel_DeliveryDocument: 0,
  maxSizeMotherVessel_DeliveryDocument: 100,
  patternMotherVessel_DeliveryDocument: "",
  minSizeMotherVoyage_DeliveryDocument: 0,
  maxSizeMotherVoyage_DeliveryDocument: 100,
  patternMotherVoyage_DeliveryDocument: "",
  minSizeBookingRef_DeliveryDocument: 0,
  maxSizeBookingRef_DeliveryDocument: 100,
  patternBookingRef_DeliveryDocument: "",
  minSizePortOfLoading_DeliveryDocument: 0,
  maxSizePortOfLoading_DeliveryDocument: 100,
  patternPortOfLoading_DeliveryDocument: "",
  minSizeLoadingPierTerminal_DeliveryDocument: 0,
  maxSizeLoadingPierTerminal_DeliveryDocument: 100,
  patternLoadingPierTerminal_DeliveryDocument: "",
  minSizeEtaPkg_DeliveryDocument: 0,
  maxSizeEtaPkg_DeliveryDocument: 20,
  patternEtaPkg_DeliveryDocument: "",
  minSizeEtd_DeliveryDocument: 0,
  maxSizeEtd_DeliveryDocument: 20,
  patternEtd_DeliveryDocument: "",
  minSizeEta_DeliveryDocument: 0,
  maxSizeEta_DeliveryDocument: 20,
  patternEta_DeliveryDocument: "",
  minSizeDestination_DeliveryDocument: 0,
  maxSizeDestination_DeliveryDocument: 100,
  patternDestination_DeliveryDocument: "",
  minSizeCountryOfOrigin_DeliveryDocument: 0,
  maxSizeCountryOfOrigin_DeliveryDocument: 100,
  patternCountryOfOrigin_DeliveryDocument: "",
  minSizeContainerNo_DeliveryDocument: 0,
  maxSizeContainerNo_DeliveryDocument: 100,
  patternContainerNo_DeliveryDocument: "",
  minSizeGrossWeight_DeliveryDocument: 0,
  maxSizeGrossWeight_DeliveryDocument: 26,
  patternGrossWeight_DeliveryDocument: "",
  minSizeGrossVolumn_DeliveryDocument: 0,
  maxSizeGrossVolumn_DeliveryDocument: 26,
  patternGrossVolumn_DeliveryDocument: "",
  minSizeExportRemark_DeliveryDocument: 0,
  maxSizeExportRemark_DeliveryDocument: 250,
  patternExportRemark_DeliveryDocument: "",
  minSizeDeliveryOrderMasterId_DeliveryDocument: 0,
  maxSizeDeliveryOrderMasterId_DeliveryDocument: 19,
  patternDeliveryOrderMasterId_DeliveryDocument: "",
  minSizeProdQty_DeliveryOrderDetail: 0,
  maxSizeProdQty_DeliveryOrderDetail: 26,
  patternProdQty_DeliveryOrderDetail: "",
  minSizeProdConvfactor_DeliveryOrderDetail: 0,
  maxSizeProdConvfactor_DeliveryOrderDetail: 26,
  patternProdConvfactor_DeliveryOrderDetail: "",
  minSizeProdBaseQty_DeliveryOrderDetail: 0,
  maxSizeProdBaseQty_DeliveryOrderDetail: 26,
  patternProdBaseQty_DeliveryOrderDetail: "",
  minSizeProdReceivedn_DeliveryOrderDetail: 0,
  maxSizeProdReceivedn_DeliveryOrderDetail: 26,
  patternProdReceivedn_DeliveryOrderDetail: "",
  minSizeProdReceivecn_DeliveryOrderDetail: 0,
  maxSizeProdReceivecn_DeliveryOrderDetail: 26,
  patternProdReceivecn_DeliveryOrderDetail: "",
  minSizeWareHouseId_DeliveryOrderDetail: 0,
  maxSizeWareHouseId_DeliveryOrderDetail: 19,
  patternWareHouseId_DeliveryOrderDetail: "",
  minSizeAStatus_DeliveryOrderMaster: 0,
  maxSizeAStatus_DeliveryOrderMaster: 2,
  patternAStatus_DeliveryOrderMaster: "",
  minSizeDoWithhodingTax_DeliveryOrderMaster: 0,
  maxSizeDoWithhodingTax_DeliveryOrderMaster: 26,
  patternDoWithhodingTax_DeliveryOrderMaster: "",
  minSizeDepositBookMasterId_DepositBookMaster: 0,
  maxSizeDepositBookMasterId_DepositBookMaster: 19,
  patternDepositBookMasterId_DepositBookMaster: "",
  minSizeDepositBookCode_DepositBookMaster: 0,
  maxSizeDepositBookCode_DepositBookMaster: 30,
  patternDepositBookCode_DepositBookMaster: "[0-9a-zA-Z/-]+",
  minSizeBookTName_DepositBookMaster: 0,
  maxSizeBookTName_DepositBookMaster: 250,
  patternBookTName_DepositBookMaster: "",
  minSizeBookEName_DepositBookMaster: 0,
  maxSizeBookEName_DepositBookMaster: 250,
  patternBookEName_DepositBookMaster: "",
  minSizeCompanyCode_DepositBookMaster: 0,
  maxSizeCompanyCode_DepositBookMaster: 20,
  patternCompanyCode_DepositBookMaster: "[0-9a-zA-Z/-]+",
  minSizeBankBranchId_DepositBookMaster: 0,
  maxSizeBankBranchId_DepositBookMaster: 19,
  patternBankBranchId_DepositBookMaster: "",
  minSizeAccountCodeId_DepositBookMaster: 0,
  maxSizeAccountCodeId_DepositBookMaster: 19,
  patternAccountCodeId_DepositBookMaster: "[0-9a-zA-Z/-]+",
  minSizePassBookTypeId_DepositBookMaster: 0,
  maxSizePassBookTypeId_DepositBookMaster: 19,
  patternPassBookTypeId_DepositBookMaster: "",
  minSizeAccountIncomeID_DepositBookMaster: 0,
  maxSizeAccountIncomeID_DepositBookMaster: 19,
  patternAccountIncomeID_DepositBookMaster: "",
  minSizeToken_DepositBookMaster: 0,
  maxSizeToken_DepositBookMaster: 50,
  patternToken_DepositBookMaster: "",
  minSizeAccessToken_DepositBookMaster: 0,
  maxSizeAccessToken_DepositBookMaster: 50,
  patternAccessToken_DepositBookMaster: "",
  minSizeTokenCode_DepositBookMaster: 0,
  maxSizeTokenCode_DepositBookMaster: 150,
  patternTokenCode_DepositBookMaster: "[0-9a-zA-Z/-]+",
  minSizePromptPay_DepositBookMaster: 0,
  maxSizePromptPay_DepositBookMaster: 13,
  patternPromptPay_DepositBookMaster: "",
  minSizeDivisionCustDetailId_DivisionCustDetail: 0,
  maxSizeDivisionCustDetailId_DivisionCustDetail: 19,
  patternDivisionCustDetailId_DivisionCustDetail: "",
  minSizeDivisionNo_DivisionCustDetail: 0,
  maxSizeDivisionNo_DivisionCustDetail: 10,
  patternDivisionNo_DivisionCustDetail: "",
  minSizeDivisionName_DivisionCustDetail: 0,
  maxSizeDivisionName_DivisionCustDetail: 100,
  patternDivisionName_DivisionCustDetail: "",
  minSizeDivisionAddress_DivisionCustDetail: 0,
  maxSizeDivisionAddress_DivisionCustDetail: 255,
  patternDivisionAddress_DivisionCustDetail: "",
  minSizeCompanyCode_DivisionCustDetail: 0,
  maxSizeCompanyCode_DivisionCustDetail: 20,
  patternCompanyCode_DivisionCustDetail: "[0-9a-zA-Z/-]+",
  minSizeDivisionContact_DivisionCustDetail: 0,
  maxSizeDivisionContact_DivisionCustDetail: 50,
  patternDivisionContact_DivisionCustDetail: "",
  minSizeDivisionTel_DivisionCustDetail: 0,
  maxSizeDivisionTel_DivisionCustDetail: 100,
  patternDivisionTel_DivisionCustDetail: "",
  minSizeCustomerMasterId_DivisionCustDetail: 0,
  maxSizeCustomerMasterId_DivisionCustDetail: 19,
  patternCustomerMasterId_DivisionCustDetail: "",
  minSizeDivisionEName_DivisionCustDetail: 0,
  maxSizeDivisionEName_DivisionCustDetail: 100,
  patternDivisionEName_DivisionCustDetail: "",
  minSizeAreaId_DivisionCustDetail: 0,
  maxSizeAreaId_DivisionCustDetail: 19,
  patternAreaId_DivisionCustDetail: "",
  minSizeDivisionId_Division: 0,
  maxSizeDivisionId_Division: 19,
  patternDivisionId_Division: "",
  minSizeCompanyCode_Division: 0,
  maxSizeCompanyCode_Division: 20,
  patternCompanyCode_Division: "[0-9a-zA-Z/-]+",
  minSizePettyCashAmount_Division: 0,
  maxSizePettyCashAmount_Division: 26,
  patternPettyCashAmount_Division: "",
  minSizeAccountCodeId_Division: 0,
  maxSizeAccountCodeId_Division: 19,
  patternAccountCodeId_Division: "[0-9a-zA-Z/-]+",
  minSizePettyCashLimitAmount_Division: 0,
  maxSizePettyCashLimitAmount_Division: 26,
  patternPettyCashLimitAmount_Division: "",
  minSizeDivisionSuppDetailId_DivisionSuppDetail: 0,
  maxSizeDivisionSuppDetailId_DivisionSuppDetail: 19,
  patternDivisionSuppDetailId_DivisionSuppDetail: "",
  minSizeDivisionNo_DivisionSuppDetail: 0,
  maxSizeDivisionNo_DivisionSuppDetail: 10,
  patternDivisionNo_DivisionSuppDetail: "",
  minSizeDivisionName_DivisionSuppDetail: 0,
  maxSizeDivisionName_DivisionSuppDetail: 50,
  patternDivisionName_DivisionSuppDetail: "",
  minSizeDivisionAddress_DivisionSuppDetail: 0,
  maxSizeDivisionAddress_DivisionSuppDetail: 255,
  patternDivisionAddress_DivisionSuppDetail: "",
  minSizeCompanyCode_DivisionSuppDetail: 0,
  maxSizeCompanyCode_DivisionSuppDetail: 20,
  patternCompanyCode_DivisionSuppDetail: "[0-9a-zA-Z/-]+",
  minSizeDivisionContact_DivisionSuppDetail: 0,
  maxSizeDivisionContact_DivisionSuppDetail: 50,
  patternDivisionContact_DivisionSuppDetail: "",
  minSizeDivisionTel_DivisionSuppDetail: 0,
  maxSizeDivisionTel_DivisionSuppDetail: 100,
  patternDivisionTel_DivisionSuppDetail: "",
  minSizeSupplierMasterId_DivisionSuppDetail: 0,
  maxSizeSupplierMasterId_DivisionSuppDetail: 19,
  patternSupplierMasterId_DivisionSuppDetail: "",
  minSizeDivisionEName_DivisionSuppDetail: 0,
  maxSizeDivisionEName_DivisionSuppDetail: 50,
  patternDivisionEName_DivisionSuppDetail: "",
  minSizeDocumentGroupId_DocumentGroup: 0,
  maxSizeDocumentGroupId_DocumentGroup: 19,
  patternDocumentGroupId_DocumentGroup: "",
  minSizeCompanyCode_DocumentGroup: 0,
  maxSizeCompanyCode_DocumentGroup: 20,
  patternCompanyCode_DocumentGroup: "[0-9a-zA-Z/-]+",
  minSizeStatusSending_DocumentGroup: 0,
  maxSizeStatusSending_DocumentGroup: 1,
  patternStatusSending_DocumentGroup: "",
  minSizeVer_DocumentGroup: 0,
  maxSizeVer_DocumentGroup: 10,
  patternVer_DocumentGroup: "",
  minSizeEmployeeGroupId_EmployeeGroup: 0,
  maxSizeEmployeeGroupId_EmployeeGroup: 19,
  patternEmployeeGroupId_EmployeeGroup: "",
  minSizeCompanyCode_EmployeeGroup: 0,
  maxSizeCompanyCode_EmployeeGroup: 20,
  patternCompanyCode_EmployeeGroup: "[0-9a-zA-Z/-]+",
  minSizeLevel_EmployeeGroup: 0,
  maxSizeLevel_EmployeeGroup: 10,
  patternLevel_EmployeeGroup: "",
  minSizeEmployeeMasterId_EmployeeMasterEmployeeGroup: 0,
  maxSizeEmployeeMasterId_EmployeeMasterEmployeeGroup: 19,
  patternEmployeeMasterId_EmployeeMasterEmployeeGroup: "",
  minSizeEmployeeGroupId_EmployeeMasterEmployeeGroup: 0,
  maxSizeEmployeeGroupId_EmployeeMasterEmployeeGroup: 19,
  patternEmployeeGroupId_EmployeeMasterEmployeeGroup: "",
  minSizeEmployeeMasterId_EmployeeMaster: 0,
  maxSizeEmployeeMasterId_EmployeeMaster: 19,
  patternEmployeeMasterId_EmployeeMaster: "",
  minSizeEmpCode_EmployeeMaster: 0,
  maxSizeEmpCode_EmployeeMaster: 20,
  patternEmpCode_EmployeeMaster: "[0-9a-zA-Z/-]+",
  minSizeEmpTName_EmployeeMaster: 0,
  maxSizeEmpTName_EmployeeMaster: 150,
  patternEmpTName_EmployeeMaster: "",
  minSizeEmpEName_EmployeeMaster: 0,
  maxSizeEmpEName_EmployeeMaster: 150,
  patternEmpEName_EmployeeMaster: "",
  minSizeEmpAddress_EmployeeMaster: 0,
  maxSizeEmpAddress_EmployeeMaster: 255,
  patternEmpAddress_EmployeeMaster: "",
  minSizeEmpTelNo_EmployeeMaster: 0,
  maxSizeEmpTelNo_EmployeeMaster: 50,
  patternEmpTelNo_EmployeeMaster: "[0-9-]+",
  minSizeEmpMobileNo_EmployeeMaster: 0,
  maxSizeEmpMobileNo_EmployeeMaster: 50,
  patternEmpMobileNo_EmployeeMaster: "",
  minSizeEmail_EmployeeMaster: 0,
  maxSizeEmail_EmployeeMaster: 150,
  patternEmail_EmployeeMaster: "",
  minSizeEmpTaxId_EmployeeMaster: 0,
  maxSizeEmpTaxId_EmployeeMaster: 20,
  patternEmpTaxId_EmployeeMaster: "[0-9-]+",
  minSizeCardId_EmployeeMaster: 0,
  maxSizeCardId_EmployeeMaster: 13,
  patternCardId_EmployeeMaster: "[0-9]+",
  minSizeEmpStartDate_EmployeeMaster: 0,
  maxSizeEmpStartDate_EmployeeMaster: 20,
  patternEmpStartDate_EmployeeMaster: "",
  minSizeEmpSocialId_EmployeeMaster: 0,
  maxSizeEmpSocialId_EmployeeMaster: 20,
  patternEmpSocialId_EmployeeMaster: "[0-9-]+",
  minSizeEmpCarDriveNo_EmployeeMaster: 0,
  maxSizeEmpCarDriveNo_EmployeeMaster: 100,
  patternEmpCarDriveNo_EmployeeMaster: "[0-9-]+",
  minSizeEmpReferencePerson_EmployeeMaster: 0,
  maxSizeEmpReferencePerson_EmployeeMaster: 255,
  patternEmpReferencePerson_EmployeeMaster: "",
  minSizeEmpReferenceAddress_EmployeeMaster: 0,
  maxSizeEmpReferenceAddress_EmployeeMaster: 255,
  patternEmpReferenceAddress_EmployeeMaster: "",
  minSizeEmpPicture_EmployeeMaster: 0,
  maxSizeEmpPicture_EmployeeMaster: 255,
  patternEmpPicture_EmployeeMaster: "",
  minSizeEmpIsUser_EmployeeMaster: 0,
  maxSizeEmpIsUser_EmployeeMaster: 1,
  patternEmpIsUser_EmployeeMaster: "",
  minSizeCompanyCode_EmployeeMaster: 0,
  maxSizeCompanyCode_EmployeeMaster: 20,
  patternCompanyCode_EmployeeMaster: "[0-9a-zA-Z/-]+",
  minSizeEmpIsSalePerson_EmployeeMaster: 0,
  maxSizeEmpIsSalePerson_EmployeeMaster: 1,
  patternEmpIsSalePerson_EmployeeMaster: "",
  minSizeEmpStatus_EmployeeMaster: 0,
  maxSizeEmpStatus_EmployeeMaster: 2,
  patternEmpStatus_EmployeeMaster: "",
  minSizeSectionId_EmployeeMaster: 0,
  maxSizeSectionId_EmployeeMaster: 19,
  patternSectionId_EmployeeMaster: "",
  minSizeDivisionId_EmployeeMaster: 0,
  maxSizeDivisionId_EmployeeMaster: 19,
  patternDivisionId_EmployeeMaster: "",
  minSizeUserCode_Nec_EmployeeMaster: 0,
  patternUserCode_Nec_EmployeeMaster: "[0-9a-zA-Z/-]+",
  minSizeBackDate_EmployeeMaster: 0,
  maxSizeBackDate_EmployeeMaster: 2,
  patternBackDate_EmployeeMaster: "",
  minSizeIsEmpDriver_EmployeeMaster: 0,
  maxSizeIsEmpDriver_EmployeeMaster: 2,
  patternIsEmpDriver_EmployeeMaster: "",
  minSizeEmployeeArea_EmployeeMaster: 0,
  maxSizeEmployeeArea_EmployeeMaster: 2,
  patternEmployeeArea_EmployeeMaster: "",
  minSizeEmployeeMasterId_EmployeeMasterPosition: 0,
  maxSizeEmployeeMasterId_EmployeeMasterPosition: 19,
  patternEmployeeMasterId_EmployeeMasterPosition: "",
  minSizePositionId_EmployeeMasterPosition: 0,
  maxSizePositionId_EmployeeMasterPosition: 19,
  patternPositionId_EmployeeMasterPosition: "",
  minSizeJobBomId_JobBomMaster: 0,
  maxSizeJobBomId_JobBomMaster: 19,
  patternJobBomId_JobBomMaster: "",
  minSizeJobBomQty_JobBomMaster: 0,
  maxSizeJobBomQty_JobBomMaster: 26,
  patternJobBomQty_JobBomMaster: "",
  minSizeJobBomUseQty_JobBomMaster: 0,
  maxSizeJobBomUseQty_JobBomMaster: 26,
  patternJobBomUseQty_JobBomMaster: "",
  minSizeJobBomReceiveQty_JobBomMaster: 0,
  maxSizeJobBomReceiveQty_JobBomMaster: 26,
  patternJobBomReceiveQty_JobBomMaster: "",
  minSizeJobBomDrawingNo_JobBomMaster: 0,
  maxSizeJobBomDrawingNo_JobBomMaster: 255,
  patternJobBomDrawingNo_JobBomMaster: "",
  minSizeJobBomReferOrder_JobBomMaster: 0,
  maxSizeJobBomReferOrder_JobBomMaster: 255,
  patternJobBomReferOrder_JobBomMaster: "",
  minSizeJobBomLotNo_JobBomMaster: 0,
  maxSizeJobBomLotNo_JobBomMaster: 255,
  patternJobBomLotNo_JobBomMaster: "",
  minSizeRemark1_JobBomMaster: 0,
  maxSizeRemark1_JobBomMaster: 255,
  patternRemark1_JobBomMaster: "",
  minSizeRemark2_JobBomMaster: 0,
  maxSizeRemark2_JobBomMaster: 255,
  patternRemark2_JobBomMaster: "",
  minSizeRemark3_JobBomMaster: 0,
  maxSizeRemark3_JobBomMaster: 255,
  patternRemark3_JobBomMaster: "",
  minSizeRemark4_JobBomMaster: 0,
  maxSizeRemark4_JobBomMaster: 255,
  patternRemark4_JobBomMaster: "",
  minSizeJobGroupId_JobBomMaster: 0,
  maxSizeJobGroupId_JobBomMaster: 19,
  patternJobGroupId_JobBomMaster: "",
  minSizeProductMasterId_JobBomMaster: 0,
  maxSizeProductMasterId_JobBomMaster: 19,
  patternProductMasterId_JobBomMaster: "",
  minSizeDivisionId_JobBomMaster: 0,
  maxSizeDivisionId_JobBomMaster: 19,
  patternDivisionId_JobBomMaster: "",
  minSizeSectionId_JobBomMaster: 0,
  maxSizeSectionId_JobBomMaster: 19,
  patternSectionId_JobBomMaster: "",
  minSizePercentCompleted_JobBomMaster: 0,
  maxSizePercentCompleted_JobBomMaster: 24,
  patternPercentCompleted_JobBomMaster: "",
  minSizeJobProcessStatus_JobBomMaster: 0,
  maxSizeJobProcessStatus_JobBomMaster: 200,
  patternJobProcessStatus_JobBomMaster: "",
  minSizeNotAllowOver_JobBomMaster: 0,
  maxSizeNotAllowOver_JobBomMaster: 2,
  patternNotAllowOver_JobBomMaster: "",
  minSizePercentOver_JobBomMaster: 0,
  maxSizePercentOver_JobBomMaster: 26,
  patternPercentOver_JobBomMaster: "",
  minSizeCustomerMasterId_JobBomMaster: 0,
  maxSizeCustomerMasterId_JobBomMaster: 19,
  patternCustomerMasterId_JobBomMaster: "",
  minSizeCompanyCode_JobBomMaster: 0,
  maxSizeCompanyCode_JobBomMaster: 20,
  patternCompanyCode_JobBomMaster: "[0-9a-zA-Z/-]+",
  minSizeJobMasterId_JobBomMaster: 0,
  maxSizeJobMasterId_JobBomMaster: 19,
  patternJobMasterId_JobBomMaster: "",
  minSizeJobType_JobBomMaster: 0,
  maxSizeJobType_JobBomMaster: 20,
  patternJobType_JobBomMaster: "",
  minSizeSoDocNo_JobBomMaster: 0,
  maxSizeSoDocNo_JobBomMaster: 100,
  patternSoDocNo_JobBomMaster: "",
  minSizeSoDetailId_JobBomMaster: 0,
  maxSizeSoDetailId_JobBomMaster: 19,
  patternSoDetailId_JobBomMaster: "",
  minSizeJobDetialId_JobDetail: 0,
  maxSizeJobDetialId_JobDetail: 19,
  patternJobDetialId_JobDetail: "",
  minSizeJobDetail_JobDetail: 0,
  maxSizeJobDetail_JobDetail: 255,
  patternJobDetail_JobDetail: "",
  minSizeCompanyCode_JobDetail: 0,
  maxSizeCompanyCode_JobDetail: 20,
  patternCompanyCode_JobDetail: "[0-9a-zA-Z/-]+",
  minSizeJobGroupId_JobGroup: 0,
  maxSizeJobGroupId_JobGroup: 19,
  patternJobGroupId_JobGroup: "",
  minSizeCompanyCode_JobGroup: 0,
  maxSizeCompanyCode_JobGroup: 20,
  patternCompanyCode_JobGroup: "[0-9a-zA-Z/-]+",
  minSizeJobMasterId_JobMasterEmployeeMaster: 0,
  maxSizeJobMasterId_JobMasterEmployeeMaster: 19,
  patternJobMasterId_JobMasterEmployeeMaster: "",
  minSizeEmployeeMasterId_JobMasterEmployeeMaster: 0,
  maxSizeEmployeeMasterId_JobMasterEmployeeMaster: 19,
  patternEmployeeMasterId_JobMasterEmployeeMaster: "",
  minSizeJobMasterId_JobMaster: 0,
  maxSizeJobMasterId_JobMaster: 19,
  patternJobMasterId_JobMaster: "",
  minSizeJobCode_JobMaster: 0,
  maxSizeJobCode_JobMaster: 50,
  patternJobCode_JobMaster: "[0-9a-zA-Z/-]+",
  minSizeJobName_JobMaster: 0,
  maxSizeJobName_JobMaster: 255,
  patternJobName_JobMaster: "",
  minSizeJobTName_JobMaster: 0,
  maxSizeJobTName_JobMaster: 255,
  patternJobTName_JobMaster: "",
  minSizeJobEName_JobMaster: 0,
  maxSizeJobEName_JobMaster: 255,
  patternJobEName_JobMaster: "",
  minSizeJobDate_JobMaster: 0,
  maxSizeJobDate_JobMaster: 20,
  patternJobDate_JobMaster: "",
  minSizeJobDueDate_JobMaster: 0,
  maxSizeJobDueDate_JobMaster: 20,
  patternJobDueDate_JobMaster: "",
  minSizeJobDescription1_JobMaster: 0,
  maxSizeJobDescription1_JobMaster: 255,
  patternJobDescription1_JobMaster: "",
  minSizeJobDescription2_JobMaster: 0,
  maxSizeJobDescription2_JobMaster: 255,
  patternJobDescription2_JobMaster: "",
  minSizeJobDescription3_JobMaster: 0,
  maxSizeJobDescription3_JobMaster: 255,
  patternJobDescription3_JobMaster: "",
  minSizeJobDescription4_JobMaster: 0,
  maxSizeJobDescription4_JobMaster: 255,
  patternJobDescription4_JobMaster: "",
  minSizeCompanyCode_JobMaster: 0,
  maxSizeCompanyCode_JobMaster: 20,
  patternCompanyCode_JobMaster: "[0-9a-zA-Z/-]+",
  minSizeJobStatus_JobMaster: 0,
  maxSizeJobStatus_JobMaster: 2,
  patternJobStatus_JobMaster: "",
  minSizeJobType_JobMaster: 0,
  maxSizeJobType_JobMaster: 20,
  patternJobType_JobMaster: "",
  minSizeJobExpense_JobMaster: 0,
  maxSizeJobExpense_JobMaster: 26,
  patternJobExpense_JobMaster: "",
  minSizeJobSalary_JobMaster: 0,
  maxSizeJobSalary_JobMaster: 26,
  patternJobSalary_JobMaster: "",
  minSizeJobGroupId_JobMaster: 0,
  maxSizeJobGroupId_JobMaster: 19,
  patternJobGroupId_JobMaster: "",
  minSizeJobBomId_JobMaster: 0,
  maxSizeJobBomId_JobMaster: 19,
  patternJobBomId_JobMaster: "",
  minSizeJobParentMasterId_JobMaster: 0,
  maxSizeJobParentMasterId_JobMaster: 19,
  patternJobParentMasterId_JobMaster: "",
  minSizeDriverCost_JobMaster: 0,
  maxSizeDriverCost_JobMaster: 26,
  patternDriverCost_JobMaster: "[0-9.]+",
  minSizeBudget_JobMaster: 0,
  maxSizeBudget_JobMaster: 26,
  patternBudget_JobMaster: "[0-9.]+",
  minSizeBudgetSourceId_JobMaster: 0,
  maxSizeBudgetSourceId_JobMaster: 19,
  patternBudgetSourceId_JobMaster: "",
  minSizeType_JobMaster: 0,
  maxSizeType_JobMaster: 20,
  patternType_JobMaster: "",
  minSizeRmType_JobMaster: 0,
  maxSizeRmType_JobMaster: 2,
  patternRmType_JobMaster: "",
  minSizePoType_JobMaster: 0,
  maxSizePoType_JobMaster: 2,
  patternPoType_JobMaster: "",
  minSizeSupplierMasterId_JobMaster: 0,
  maxSizeSupplierMasterId_JobMaster: 19,
  patternSupplierMasterId_JobMaster: "",
  minSizeDivisionId_JobMaster: 0,
  maxSizeDivisionId_JobMaster: 19,
  patternDivisionId_JobMaster: "",
  minSizeSectionId_JobMaster: 0,
  maxSizeSectionId_JobMaster: 19,
  patternSectionId_JobMaster: "",
  minSizeJobDetailId_JobMasterJobDetail: 0,
  maxSizeJobDetailId_JobMasterJobDetail: 19,
  patternJobDetailId_JobMasterJobDetail: "",
  minSizeJobMasterId_JobMasterJobDetail: 0,
  maxSizeJobMasterId_JobMasterJobDetail: 19,
  patternJobMasterId_JobMasterJobDetail: "",
  minSizeJournalBookId_JournalBook: 0,
  maxSizeJournalBookId_JournalBook: 19,
  patternJournalBookId_JournalBook: "",
  minSizeCompanyCode_JournalBook: 0,
  maxSizeCompanyCode_JournalBook: 20,
  patternCompanyCode_JournalBook: "[0-9a-zA-Z/-]+",
  minSizePrefix_JournalBook: 0,
  maxSizePrefix_JournalBook: 255,
  patternPrefix_JournalBook: "",
  minSizeFormat_JournalBook: 0,
  maxSizeFormat_JournalBook: 255,
  patternFormat_JournalBook: "",
  minSizeMasterMoreId_MasterMore: 0,
  maxSizeMasterMoreId_MasterMore: 19,
  patternMasterMoreId_MasterMore: "",
  minSizeDate1_MasterMore: 0,
  maxSizeDate1_MasterMore: 50,
  patternDate1_MasterMore: "",
  minSizeDate2_MasterMore: 0,
  maxSizeDate2_MasterMore: 50,
  patternDate2_MasterMore: "",
  minSizeContactName_MasterMore: 0,
  maxSizeContactName_MasterMore: 255,
  patternContactName_MasterMore: "",
  minSizeRemark1_MasterMore: 0,
  maxSizeRemark1_MasterMore: 255,
  patternRemark1_MasterMore: "",
  minSizeRemark2_MasterMore: 0,
  maxSizeRemark2_MasterMore: 255,
  patternRemark2_MasterMore: "",
  minSizeRemark3_MasterMore: 0,
  maxSizeRemark3_MasterMore: 255,
  patternRemark3_MasterMore: "",
  minSizeDocumentNo_MasterMore: 0,
  maxSizeDocumentNo_MasterMore: 50,
  patternDocumentNo_MasterMore: "",
  minSizeDocumentType_MasterMore: 0,
  maxSizeDocumentType_MasterMore: 5,
  patternDocumentType_MasterMore: "",
  minSizeCompanyCode_MasterMore: 0,
  maxSizeCompanyCode_MasterMore: 20,
  patternCompanyCode_MasterMore: "[0-9a-zA-Z/-]+",
  minSizeUStatus_MasterMore: 0,
  maxSizeUStatus_MasterMore: 5,
  patternUStatus_MasterMore: "",
  minSizeMenuMasterId_MenuMaster: 0,
  maxSizeMenuMasterId_MenuMaster: 19,
  patternMenuMasterId_MenuMaster: "",
  minSizeMenuCode_MenuMaster: 0,
  maxSizeMenuCode_MenuMaster: 20,
  patternMenuCode_MenuMaster: "[0-9a-zA-Z/-]+",
  minSizeMenuTName_MenuMaster: 0,
  maxSizeMenuTName_MenuMaster: 100,
  patternMenuTName_MenuMaster: "",
  minSizeMenuEName_MenuMaster: 0,
  maxSizeMenuEName_MenuMaster: 100,
  patternMenuEName_MenuMaster: "",
  minSizeMenuStatus_MenuMaster: 0,
  maxSizeMenuStatus_MenuMaster: 1,
  patternMenuStatus_MenuMaster: "",
  minSizeMenuUrl_MenuMaster: 0,
  maxSizeMenuUrl_MenuMaster: 255,
  patternMenuUrl_MenuMaster: "",
  minSizeMenuToolTip_MenuMaster: 0,
  maxSizeMenuToolTip_MenuMaster: 100,
  patternMenuToolTip_MenuMaster: "",
  minSizeMenuIsShowMenu_MenuMaster: 0,
  maxSizeMenuIsShowMenu_MenuMaster: 1,
  patternMenuIsShowMenu_MenuMaster: "",
  minSizeMenuIsShowAuthen_MenuMaster: 0,
  maxSizeMenuIsShowAuthen_MenuMaster: 1,
  patternMenuIsShowAuthen_MenuMaster: "",
  minSizeMenuPicture_MenuMaster: 0,
  maxSizeMenuPicture_MenuMaster: 255,
  patternMenuPicture_MenuMaster: "",
  minSizeMenuGroup_MenuMaster: 0,
  maxSizeMenuGroup_MenuMaster: 150,
  patternMenuGroup_MenuMaster: "",
  minSizeMenuType_MenuMaster: 0,
  maxSizeMenuType_MenuMaster: 20,
  patternMenuType_MenuMaster: "",
  minSizeMenuDescription_MenuMaster: 0,
  maxSizeMenuDescription_MenuMaster: 150,
  patternMenuDescription_MenuMaster: "",
  minSizeMenuIsAll_MenuMaster: 0,
  maxSizeMenuIsAll_MenuMaster: 1,
  patternMenuIsAll_MenuMaster: "",
  minSizeMenuLangCode_MenuMaster: 0,
  maxSizeMenuLangCode_MenuMaster: 20,
  patternMenuLangCode_MenuMaster: "[0-9a-zA-Z/-]+",
  minSizeMenuParentId_MenuMaster: 0,
  maxSizeMenuParentId_MenuMaster: 19,
  patternMenuParentId_MenuMaster: "",
  minSizeNameInVatId_NameInVat: 0,
  maxSizeNameInVatId_NameInVat: 19,
  patternNameInVatId_NameInVat: "",
  minSizeCode_NameInVat: 0,
  maxSizeCode_NameInVat: 255,
  patternCode_NameInVat: "[0-9a-zA-Z/-]+",
  minSizeName_NameInVat: 0,
  maxSizeName_NameInVat: 255,
  patternName_NameInVat: "",
  minSizeAddress_NameInVat: 0,
  maxSizeAddress_NameInVat: 255,
  patternAddress_NameInVat: "",
  minSizeTaxId_NameInVat: 0,
  maxSizeTaxId_NameInVat: 255,
  patternTaxId_NameInVat: "",
  minSizeCardId_NameInVat: 0,
  maxSizeCardId_NameInVat: 255,
  patternCardId_NameInVat: "",
  minSizeCompanyCode_NameInVat: 0,
  maxSizeCompanyCode_NameInVat: 20,
  patternCompanyCode_NameInVat: "[0-9a-zA-Z/-]+",
  minSizeDivisionName_NameInVat: 0,
  maxSizeDivisionName_NameInVat: 500,
  patternDivisionName_NameInVat: "",
  minSizePassBookTypeId_PassBookType: 0,
  maxSizePassBookTypeId_PassBookType: 19,
  patternPassBookTypeId_PassBookType: "",
  minSizeCompanyCode_PassBookType: 0,
  maxSizeCompanyCode_PassBookType: 20,
  patternCompanyCode_PassBookType: "[0-9a-zA-Z/-]+",
  minSizePersonId_Person: 0,
  maxSizePersonId_Person: 19,
  patternPersonId_Person: "",
  minSizePersonCode_Person: 0,
  maxSizePersonCode_Person: 20,
  patternPersonCode_Person: "[0-9a-zA-Z/-]+",
  minSizePersonPrefix_Person: 0,
  maxSizePersonPrefix_Person: 20,
  patternPersonPrefix_Person: "",
  minSizePersonName_Person: 0,
  maxSizePersonName_Person: 200,
  patternPersonName_Person: "",
  minSizePersonSurname_Person: 0,
  maxSizePersonSurname_Person: 200,
  patternPersonSurname_Person: "",
  minSizePersonTaxId_Person: 0,
  maxSizePersonTaxId_Person: 20,
  patternPersonTaxId_Person: "",
  minSizeAddress1_Person: 0,
  maxSizeAddress1_Person: 255,
  patternAddress1_Person: "",
  minSizeAddress2_Person: 0,
  maxSizeAddress2_Person: 255,
  patternAddress2_Person: "",
  minSizePostalCode_Person: 0,
  maxSizePostalCode_Person: 20,
  patternPostalCode_Person: "[0-9a-zA-Z/-]+",
  minSizeSalary_Person: 0,
  maxSizeSalary_Person: 26,
  patternSalary_Person: "",
  minSizeWhtAmt_Person: 0,
  maxSizeWhtAmt_Person: 26,
  patternWhtAmt_Person: "",
  minSizeSso_Person: 0,
  maxSizeSso_Person: 20,
  patternSso_Person: "",
  minSizeSsoAmt_Person: 0,
  maxSizeSsoAmt_Person: 26,
  patternSsoAmt_Person: "",
  minSizePersonStatus_Person: 0,
  maxSizePersonStatus_Person: 1,
  patternPersonStatus_Person: "",
  minSizeCompanyCode_Person: 0,
  maxSizeCompanyCode_Person: 20,
  patternCompanyCode_Person: "[0-9a-zA-Z/-]+",
  minSizeEmpPassword_Person: 0,
  maxSizeEmpPassword_Person: 20,
  patternEmpPassword_Person: "",
  minSizePositionId_Position: 0,
  maxSizePositionId_Position: 19,
  patternPositionId_Position: "",
  minSizeCompanyCode_Position: 0,
  maxSizeCompanyCode_Position: 20,
  patternCompanyCode_Position: "[0-9a-zA-Z/-]+",
  minSizeStatusSending_Position: 0,
  maxSizeStatusSending_Position: 1,
  patternStatusSending_Position: "",
  minSizeVer_Position: 0,
  maxSizeVer_Position: 10,
  patternVer_Position: "",
  minSizePriceListMasterId_PriceListMaster: 0,
  maxSizePriceListMasterId_PriceListMaster: 19,
  patternPriceListMasterId_PriceListMaster: "",
  minSizePriceListCode_PriceListMaster: 0,
  maxSizePriceListCode_PriceListMaster: 20,
  patternPriceListCode_PriceListMaster: "[0-9a-zA-Z/-]+",
  minSizeIssDate_PriceListMaster: 0,
  maxSizeIssDate_PriceListMaster: 20,
  patternIssDate_PriceListMaster: "",
  minSizeRemark_PriceListMaster: 0,
  maxSizeRemark_PriceListMaster: 200,
  patternRemark_PriceListMaster: "",
  minSizeCompanyCode_PriceListMaster: 0,
  maxSizeCompanyCode_PriceListMaster: 20,
  patternCompanyCode_PriceListMaster: "[0-9a-zA-Z/-]+",
  minSizeProcessGroupId_ProcessGroup: 0,
  maxSizeProcessGroupId_ProcessGroup: 19,
  patternProcessGroupId_ProcessGroup: "",
  minSizeProcessGroupCode_ProcessGroup: 0,
  maxSizeProcessGroupCode_ProcessGroup: 50,
  patternProcessGroupCode_ProcessGroup: "[0-9a-zA-Z/-]+",
  minSizeProcessGroupName_ProcessGroup: 0,
  maxSizeProcessGroupName_ProcessGroup: 255,
  patternProcessGroupName_ProcessGroup: "",
  minSizeProcessGroupStatus_ProcessGroup: 0,
  maxSizeProcessGroupStatus_ProcessGroup: 1,
  patternProcessGroupStatus_ProcessGroup: "",
  minSizeRemark_ProcessGroup: 0,
  maxSizeRemark_ProcessGroup: 255,
  patternRemark_ProcessGroup: "",
  minSizeCompanyCode_ProcessGroup: 0,
  maxSizeCompanyCode_ProcessGroup: 20,
  patternCompanyCode_ProcessGroup: "[0-9a-zA-Z/-]+",
  minSizeStdTime_ProcessGroup: 0,
  maxSizeStdTime_ProcessGroup: 26,
  patternStdTime_ProcessGroup: "",
  minSizeProductMaster1Id_ProcessGroup: 0,
  maxSizeProductMaster1Id_ProcessGroup: 19,
  patternProductMaster1Id_ProcessGroup: "",
  minSizeReceive_ProcessGroup: 0,
  maxSizeReceive_ProcessGroup: 24,
  patternReceive_ProcessGroup: "",
  minSizeProdComplementaryId_ProductComplementary: 0,
  maxSizeProdComplementaryId_ProductComplementary: 19,
  patternProdComplementaryId_ProductComplementary: "",
  minSizeProdComplementaryNo_ProductComplementary: 0,
  maxSizeProdComplementaryNo_ProductComplementary: 10,
  patternProdComplementaryNo_ProductComplementary: "",
  minSizeProdComplementaryAmt_ProductComplementary: 0,
  maxSizeProdComplementaryAmt_ProductComplementary: 26,
  patternProdComplementaryAmt_ProductComplementary: "",
  minSizeStatusSending_ProductComplementary: 0,
  maxSizeStatusSending_ProductComplementary: 1,
  patternStatusSending_ProductComplementary: "",
  minSizeProductId_ProductComplementary: 0,
  maxSizeProductId_ProductComplementary: 19,
  patternProductId_ProductComplementary: "",
  minSizeUnitOfMeasureId_ProductComplementary: 0,
  maxSizeUnitOfMeasureId_ProductComplementary: 19,
  patternUnitOfMeasureId_ProductComplementary: "",
  minSizeProductMaster1Id_ProductComplementary: 0,
  maxSizeProductMaster1Id_ProductComplementary: 19,
  patternProductMaster1Id_ProductComplementary: "",
  minSizeSetupMch_ProductComplementary: 0,
  maxSizeSetupMch_ProductComplementary: 2,
  patternSetupMch_ProductComplementary: "",
  minSizeCustomerMasterId_ProductComplementary: 0,
  maxSizeCustomerMasterId_ProductComplementary: 19,
  patternCustomerMasterId_ProductComplementary: "",
  minSizeReverseBom_ProductComplementary: 0,
  maxSizeReverseBom_ProductComplementary: 2,
  patternReverseBom_ProductComplementary: "",
  minSizeBomRemark_ProductComplementary: 0,
  maxSizeBomRemark_ProductComplementary: 200,
  patternBomRemark_ProductComplementary: "",
  minSizeProductDescription1Id_ProductDescription1: 0,
  maxSizeProductDescription1Id_ProductDescription1: 19,
  patternProductDescription1Id_ProductDescription1: "",
  minSizeProdDescription_ProductDescription1: 0,
  maxSizeProdDescription_ProductDescription1: 150,
  patternProdDescription_ProductDescription1: "",
  minSizeProdDescType_ProductDescription1: 0,
  maxSizeProdDescType_ProductDescription1: 20,
  patternProdDescType_ProductDescription1: "",
  minSizeProdWarn_ProductDescription1: 0,
  maxSizeProdWarn_ProductDescription1: 100,
  patternProdWarn_ProductDescription1: "",
  minSizeCompanyCode_ProductDescription1: 0,
  maxSizeCompanyCode_ProductDescription1: 20,
  patternCompanyCode_ProductDescription1: "[0-9a-zA-Z/-]+",
  minSizeProductMaster1Id_ProductDescription1: 0,
  maxSizeProductMaster1Id_ProductDescription1: 19,
  patternProductMaster1Id_ProductDescription1: "",
  minSizeProductGroupId_ProductGroup: 0,
  maxSizeProductGroupId_ProductGroup: 19,
  patternProductGroupId_ProductGroup: "",
  minSizeCompanyCode_ProductGroup: 0,
  maxSizeCompanyCode_ProductGroup: 20,
  patternCompanyCode_ProductGroup: "[0-9a-zA-Z/-]+",
  minSizeStatusSending_ProductGroup: 0,
  maxSizeStatusSending_ProductGroup: 1,
  patternStatusSending_ProductGroup: "",
  minSizeVer_ProductGroup: 0,
  maxSizeVer_ProductGroup: 10,
  patternVer_ProductGroup: "",
  minSizeAccountCodeIncome_ProductGroup: 0,
  maxSizeAccountCodeIncome_ProductGroup: 19,
  patternAccountCodeIncome_ProductGroup: "[0-9a-zA-Z/-]+",
  minSizeAccountCodeId_ProductGroup: 0,
  maxSizeAccountCodeId_ProductGroup: 19,
  patternAccountCodeId_ProductGroup: "[0-9a-zA-Z/-]+",
  minSizeAccountCodeId2_ProductGroup: 0,
  maxSizeAccountCodeId2_ProductGroup: 19,
  patternAccountCodeId2_ProductGroup: "[0-9a-zA-Z/-]+",
  minSizeCostPercent_ProductGroup: 0,
  maxSizeCostPercent_ProductGroup: 26,
  patternCostPercent_ProductGroup: "",
  minSizeProductMaster1Id_ProductMaster1: 0,
  maxSizeProductMaster1Id_ProductMaster1: 19,
  patternProductMaster1Id_ProductMaster1: "",
  minSizeProdCode_ProductMaster1: 0,
  maxSizeProdCode_ProductMaster1: 50,
  patternProdCode_ProductMaster1: "[0-9a-zA-Z/-]+",
  minSizeProdUniqueCode_ProductMaster1: 0,
  maxSizeProdUniqueCode_ProductMaster1: 25,
  patternProdUniqueCode_ProductMaster1: "[0-9a-zA-Z/-]+",
  minSizeProdUniqueName_ProductMaster1: 0,
  maxSizeProdUniqueName_ProductMaster1: 100,
  patternProdUniqueName_ProductMaster1: "",
  minSizeProdTName_ProductMaster1: 0,
  maxSizeProdTName_ProductMaster1: 300,
  patternProdTName_ProductMaster1: "",
  minSizeProdEName_ProductMaster1: 0,
  maxSizeProdEName_ProductMaster1: 300,
  patternProdEName_ProductMaster1: "",
  minSizeProdMarketName_ProductMaster1: 0,
  maxSizeProdMarketName_ProductMaster1: 300,
  patternProdMarketName_ProductMaster1: "",
  minSizeProdBillName_ProductMaster1: 0,
  maxSizeProdBillName_ProductMaster1: 300,
  patternProdBillName_ProductMaster1: "",
  minSizeProdRemark_ProductMaster1: 0,
  maxSizeProdRemark_ProductMaster1: 350,
  patternProdRemark_ProductMaster1: "",
  minSizeProdTypeSize_ProductMaster1: 0,
  maxSizeProdTypeSize_ProductMaster1: 150,
  patternProdTypeSize_ProductMaster1: "",
  minSizeSetupCostSize_ProductMaster1: 0,
  maxSizeSetupCostSize_ProductMaster1: 26,
  patternSetupCostSize_ProductMaster1: "",
  minSizeLeadTimeSize_ProductMaster1: 0,
  maxSizeLeadTimeSize_ProductMaster1: 5,
  patternLeadTimeSize_ProductMaster1: "",
  minSizeProdProperty_ProductMaster1: 0,
  maxSizeProdProperty_ProductMaster1: 150,
  patternProdProperty_ProductMaster1: "",
  minSizeProdReqNo_ProductMaster1: 0,
  maxSizeProdReqNo_ProductMaster1: 20,
  patternProdReqNo_ProductMaster1: "",
  minSizeProdTypeMedicine_ProductMaster1: 0,
  maxSizeProdTypeMedicine_ProductMaster1: 2,
  patternProdTypeMedicine_ProductMaster1: "",
  minSizeProdConsignment_ProductMaster1: 0,
  maxSizeProdConsignment_ProductMaster1: 2,
  patternProdConsignment_ProductMaster1: "",
  minSizeWarrantyPeriod_ProductMaster1: 0,
  maxSizeWarrantyPeriod_ProductMaster1: 10,
  patternWarrantyPeriod_ProductMaster1: "",
  minSizeExpirePeriod_ProductMaster1: 0,
  maxSizeExpirePeriod_ProductMaster1: 10,
  patternExpirePeriod_ProductMaster1: "",
  minSizeProdMaxOrderQty_ProductMaster1: 0,
  maxSizeProdMaxOrderQty_ProductMaster1: 26,
  patternProdMaxOrderQty_ProductMaster1: "",
  minSizeProdReOrderQty_ProductMaster1: 0,
  maxSizeProdReOrderQty_ProductMaster1: 26,
  patternProdReOrderQty_ProductMaster1: "",
  minSizeProfitRate_ProductMaster1: 0,
  maxSizeProfitRate_ProductMaster1: 26,
  patternProfitRate_ProductMaster1: "",
  minSizeEstimatedCost_ProductMaster1: 0,
  maxSizeEstimatedCost_ProductMaster1: 26,
  patternEstimatedCost_ProductMaster1: "",
  minSizeStandardCost_ProductMaster1: 0,
  maxSizeStandardCost_ProductMaster1: 26,
  patternStandardCost_ProductMaster1: "",
  minSizeCostPercent_ProductMaster1: 0,
  maxSizeCostPercent_ProductMaster1: 26,
  patternCostPercent_ProductMaster1: "[0-9.]+",
  minSizeProdAllowMinus_ProductMaster1: 0,
  maxSizeProdAllowMinus_ProductMaster1: 1,
  patternProdAllowMinus_ProductMaster1: "",
  minSizeProdReOrderWarning_ProductMaster1: 0,
  maxSizeProdReOrderWarning_ProductMaster1: 1,
  patternProdReOrderWarning_ProductMaster1: "",
  minSizeProdeCommerce_ProductMaster1: 0,
  maxSizeProdeCommerce_ProductMaster1: 1,
  patternProdeCommerce_ProductMaster1: "",
  minSizeProdStatus_ProductMaster1: 0,
  maxSizeProdStatus_ProductMaster1: 1,
  patternProdStatus_ProductMaster1: "",
  minSizeProfitRateStatus_ProductMaster1: 0,
  maxSizeProfitRateStatus_ProductMaster1: 1,
  patternProfitRateStatus_ProductMaster1: "",
  minSizeProdLastSupplierCode_ProductMaster1: 0,
  maxSizeProdLastSupplierCode_ProductMaster1: 20,
  patternProdLastSupplierCode_ProductMaster1: "[0-9a-zA-Z/-]+",
  minSizeProdUniqueCode1_ProductMaster1: 0,
  maxSizeProdUniqueCode1_ProductMaster1: 25,
  patternProdUniqueCode1_ProductMaster1: "[0-9a-zA-Z/-]+",
  minSizeCompanyCode_ProductMaster1: 0,
  maxSizeCompanyCode_ProductMaster1: 20,
  patternCompanyCode_ProductMaster1: "[0-9a-zA-Z/-]+",
  minSizeProdComplementery_ProductMaster1: 0,
  maxSizeProdComplementery_ProductMaster1: 20,
  patternProdComplementery_ProductMaster1: "",
  minSizeProdVat_ProductMaster1: 0,
  maxSizeProdVat_ProductMaster1: 20,
  patternProdVat_ProductMaster1: "",
  minSizeProductCalcType_ProductMaster1: 0,
  maxSizeProductCalcType_ProductMaster1: 5,
  patternProductCalcType_ProductMaster1: "",
  minSizeStatusSerial_ProductMaster1: 0,
  maxSizeStatusSerial_ProductMaster1: 2,
  patternStatusSerial_ProductMaster1: "",
  minSizeStatusSending_ProductMaster1: 0,
  maxSizeStatusSending_ProductMaster1: 1,
  patternStatusSending_ProductMaster1: "",
  minSizeReferProdCode_ProductMaster1: 0,
  maxSizeReferProdCode_ProductMaster1: 25,
  patternReferProdCode_ProductMaster1: "[0-9a-zA-Z/-]+",
  minSizeVer_ProductMaster1: 0,
  maxSizeVer_ProductMaster1: 10,
  patternVer_ProductMaster1: "",
  minSizeIncludeInStock_ProductMaster1: 0,
  maxSizeIncludeInStock_ProductMaster1: 1,
  patternIncludeInStock_ProductMaster1: "",
  minSizeProductGroupId_ProductMaster1: 0,
  maxSizeProductGroupId_ProductMaster1: 19,
  patternProductGroupId_ProductMaster1: "",
  minSizeAccountCodeIncome_ProductMaster1: 0,
  maxSizeAccountCodeIncome_ProductMaster1: 19,
  patternAccountCodeIncome_ProductMaster1: "[0-9a-zA-Z/-]+",
  minSizeAccountCodeId_ProductMaster1: 0,
  maxSizeAccountCodeId_ProductMaster1: 19,
  patternAccountCodeId_ProductMaster1: "[0-9a-zA-Z/-]+",
  minSizeWarehouseId_ProductMaster1: 0,
  maxSizeWarehouseId_ProductMaster1: 19,
  patternWarehouseId_ProductMaster1: "",
  minSizeAccountCodeId2_ProductMaster1: 0,
  maxSizeAccountCodeId2_ProductMaster1: 19,
  patternAccountCodeId2_ProductMaster1: "[0-9a-zA-Z/-]+",
  minSizeProdPharmacyLabel_ProductMaster1: 0,
  maxSizeProdPharmacyLabel_ProductMaster1: 19,
  patternProdPharmacyLabel_ProductMaster1: "",
  minSizeSuppId_ProductMaster1: 0,
  maxSizeSuppId_ProductMaster1: 19,
  patternSuppId_ProductMaster1: "",
  minSizeProcessGroupId_ProductMaster1: 0,
  maxSizeProcessGroupId_ProductMaster1: 19,
  patternProcessGroupId_ProductMaster1: "",
  minSizeCreateOn_ProductMaster1: 0,
  maxSizeCreateOn_ProductMaster1: 20,
  patternCreateOn_ProductMaster1: "",
  minSizeUpdateOn_ProductMaster1: 0,
  maxSizeUpdateOn_ProductMaster1: 20,
  patternUpdateOn_ProductMaster1: "",
  minSizeCreateById_ProductMaster1: 0,
  maxSizeCreateById_ProductMaster1: 19,
  patternCreateById_ProductMaster1: "",
  minSizeUpdateById_ProductMaster1: 0,
  maxSizeUpdateById_ProductMaster1: 19,
  patternUpdateById_ProductMaster1: "",
  minSizeBomRcvAmt_ProductMaster1: 0,
  maxSizeBomRcvAmt_ProductMaster1: 26,
  patternBomRcvAmt_ProductMaster1: "",
  minSizeProductSet_ProductMaster1: 0,
  maxSizeProductSet_ProductMaster1: 2,
  patternProductSet_ProductMaster1: "",
  minSizeProdSale_ProductMaster1: 0,
  maxSizeProdSale_ProductMaster1: 2,
  patternProdSale_ProductMaster1: "",
  minSizeCustomerMasterId_ProductMaster1: 0,
  maxSizeCustomerMasterId_ProductMaster1: 19,
  patternCustomerMasterId_ProductMaster1: "",
  minSizeCheckLot_ProductMaster1: 0,
  maxSizeCheckLot_ProductMaster1: 2,
  patternCheckLot_ProductMaster1: "",
  minSizeWidth_ProductMaster1: 0,
  maxSizeWidth_ProductMaster1: 26,
  patternWidth_ProductMaster1: "[0-9.]+",
  minSizeLength_ProductMaster1: 0,
  maxSizeLength_ProductMaster1: 26,
  patternLength_ProductMaster1: "[0-9.]+",
  minSizeHeight_ProductMaster1: 0,
  maxSizeHeight_ProductMaster1: 26,
  patternHeight_ProductMaster1: "[0-9.]+",
  minSizeNetWeight_ProductMaster1: 0,
  maxSizeNetWeight_ProductMaster1: 26,
  patternNetWeight_ProductMaster1: "[0-9.]+",
  minSizeGrossWeight_ProductMaster1: 0,
  maxSizeGrossWeight_ProductMaster1: 26,
  patternGrossWeight_ProductMaster1: "[0-9.]+",
  minSizeNetVolume_ProductMaster1: 0,
  maxSizeNetVolume_ProductMaster1: 26,
  patternNetVolume_ProductMaster1: "[0-9.]+",
  minSizeProdECom_ProductMaster1: 0,
  maxSizeProdECom_ProductMaster1: 2,
  patternProdECom_ProductMaster1: "",
  minSizeQcMethodId_ProductMaster1: 0,
  maxSizeQcMethodId_ProductMaster1: 19,
  patternQcMethodId_ProductMaster1: "",
  minSizeProductUnit1Id_ProductMaster1Unit1: 0,
  maxSizeProductUnit1Id_ProductMaster1Unit1: 19,
  patternProductUnit1Id_ProductMaster1Unit1: "",
  minSizeProductMaster1Id_ProductMaster1Unit1: 0,
  maxSizeProductMaster1Id_ProductMaster1Unit1: 19,
  patternProductMaster1Id_ProductMaster1Unit1: "",
  minSizeProductModelId_ProductModel: 0,
  maxSizeProductModelId_ProductModel: 19,
  patternProductModelId_ProductModel: "",
  minSizeCompanyCode_ProductModel: 0,
  maxSizeCompanyCode_ProductModel: 20,
  patternCompanyCode_ProductModel: "[0-9a-zA-Z/-]+",
  minSizeProdBrandId_ProductBrand: 0,
  maxSizeProdBrandId_ProductBrand: 19,
  patternProdBrandId_ProductBrand: "",
  minSizeCompanyCode_ProductBrand: 0,
  maxSizeCompanyCode_ProductBrand: 20,
  patternCompanyCode_ProductBrand: "[0-9a-zA-Z/-]+",
  minSizeProdSizeId_ProductSize: 0,
  maxSizeProdSizeId_ProductSize: 19,
  patternProdSizeId_ProductSize: "",
  minSizeCompanyCode_ProductSize: 0,
  maxSizeCompanyCode_ProductSize: 20,
  patternCompanyCode_ProductSize: "[0-9a-zA-Z/-]+",
  minSizeProdPatternId_ProductPattern: 0,
  maxSizeProdPatternId_ProductPattern: 19,
  patternProdPatternId_ProductPattern: "",
  minSizeCompanyCode_ProductPattern: 0,
  maxSizeCompanyCode_ProductPattern: 20,
  patternCompanyCode_ProductPattern: "[0-9a-zA-Z/-]+",
  minSizeProdGradeId_ProductGrade: 0,
  maxSizeProdGradeId_ProductGrade: 19,
  patternProdGradeId_ProductGrade: "",
  minSizeCompanyCode_ProductGrade: 0,
  maxSizeCompanyCode_ProductGrade: 20,
  patternCompanyCode_ProductGrade: "[0-9a-zA-Z/-]+",
  minSizeProdOtherGroupId_ProductOtherGroup: 0,
  maxSizeProdOtherGroupId_ProductOtherGroup: 19,
  patternProdOtherGroupId_ProductOtherGroup: "",
  minSizeCompanyCode_ProductOtherGroup: 0,
  maxSizeCompanyCode_ProductOtherGroup: 20,
  patternCompanyCode_ProductOtherGroup: "[0-9a-zA-Z/-]+",
  minSizeProductReceiveInvoiceMasterId_ProductReceiveInvoiceMaster: 0,
  maxSizeProductReceiveInvoiceMasterId_ProductReceiveInvoiceMaster: 19,
  patternProductReceiveInvoiceMasterId_ProductReceiveInvoiceMaster: "",
  minSizeProdRecInvDocNo_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvDocNo_ProductReceiveInvoiceMaster: 30,
  patternProdRecInvDocNo_ProductReceiveInvoiceMaster: "",
  minSizeProdRecInvDocDate_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvDocDate_ProductReceiveInvoiceMaster: 20,
  patternProdRecInvDocDate_ProductReceiveInvoiceMaster: "",
  minSizeProdRecInvInvoiceNo_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvInvoiceNo_ProductReceiveInvoiceMaster: 30,
  patternProdRecInvInvoiceNo_ProductReceiveInvoiceMaster: "",
  minSizeProdRecInvInvoiceDate_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvInvoiceDate_ProductReceiveInvoiceMaster: 20,
  patternProdRecInvInvoiceDate_ProductReceiveInvoiceMaster: "",
  minSizeProdRecInvTotal_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvTotal_ProductReceiveInvoiceMaster: 26,
  patternProdRecInvTotal_ProductReceiveInvoiceMaster: "",
  minSizeProdRecInvTotalNoVat_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvTotalNoVat_ProductReceiveInvoiceMaster: 26,
  patternProdRecInvTotalNoVat_ProductReceiveInvoiceMaster: "",
  minSizeProdRecInvDiscrate_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvDiscrate_ProductReceiveInvoiceMaster: 26,
  patternProdRecInvDiscrate_ProductReceiveInvoiceMaster: "",
  minSizeProdRecInvDiscAmt_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvDiscAmt_ProductReceiveInvoiceMaster: 26,
  patternProdRecInvDiscAmt_ProductReceiveInvoiceMaster: "",
  minSizeProdRecInvDepositNo_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvDepositNo_ProductReceiveInvoiceMaster: 20,
  patternProdRecInvDepositNo_ProductReceiveInvoiceMaster: "",
  minSizeProdRecInvDepositAmt_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvDepositAmt_ProductReceiveInvoiceMaster: 26,
  patternProdRecInvDepositAmt_ProductReceiveInvoiceMaster: "",
  minSizeProdRecInvVatRate_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvVatRate_ProductReceiveInvoiceMaster: 26,
  patternProdRecInvVatRate_ProductReceiveInvoiceMaster: "",
  minSizeProdRecInvVatAmt_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvVatAmt_ProductReceiveInvoiceMaster: 26,
  patternProdRecInvVatAmt_ProductReceiveInvoiceMaster: "",
  minSizeCharge_ProductReceiveInvoiceMaster: 0,
  maxSizeCharge_ProductReceiveInvoiceMaster: 26,
  patternCharge_ProductReceiveInvoiceMaster: "",
  minSizeProdRecInvNetAmt_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvNetAmt_ProductReceiveInvoiceMaster: 26,
  patternProdRecInvNetAmt_ProductReceiveInvoiceMaster: "",
  minSizeProdRecInvPayAmt_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvPayAmt_ProductReceiveInvoiceMaster: 26,
  patternProdRecInvPayAmt_ProductReceiveInvoiceMaster: "",
  minSizeProdRecInvRemark_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvRemark_ProductReceiveInvoiceMaster: 500,
  patternProdRecInvRemark_ProductReceiveInvoiceMaster: "",
  minSizeProdRecInvStatus_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvStatus_ProductReceiveInvoiceMaster: 2,
  patternProdRecInvStatus_ProductReceiveInvoiceMaster: "",
  minSizeProdRecInvLinkDocNo_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvLinkDocNo_ProductReceiveInvoiceMaster: 20,
  patternProdRecInvLinkDocNo_ProductReceiveInvoiceMaster: "",
  minSizeProdRecInvReferPoNo_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvReferPoNo_ProductReceiveInvoiceMaster: 20,
  patternProdRecInvReferPoNo_ProductReceiveInvoiceMaster: "",
  minSizeProdRecInvCreateOn_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvCreateOn_ProductReceiveInvoiceMaster: 20,
  patternProdRecInvCreateOn_ProductReceiveInvoiceMaster: "",
  minSizeProdRecInvUpdateOn_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvUpdateOn_ProductReceiveInvoiceMaster: 20,
  patternProdRecInvUpdateOn_ProductReceiveInvoiceMaster: "",
  minSizeCompanyCode_ProductReceiveInvoiceMaster: 0,
  maxSizeCompanyCode_ProductReceiveInvoiceMaster: 20,
  patternCompanyCode_ProductReceiveInvoiceMaster: "[0-9a-zA-Z/-]+",
  minSizeExchangeRate_ProductReceiveInvoiceMaster: 0,
  maxSizeExchangeRate_ProductReceiveInvoiceMaster: 26,
  patternExchangeRate_ProductReceiveInvoiceMaster: "",
  minSizeExchageRateDate_ProductReceiveInvoiceMaster: 0,
  maxSizeExchageRateDate_ProductReceiveInvoiceMaster: 20,
  patternExchageRateDate_ProductReceiveInvoiceMaster: "",
  minSizeAStatus_ProductReceiveInvoiceMaster: 0,
  maxSizeAStatus_ProductReceiveInvoiceMaster: 2,
  patternAStatus_ProductReceiveInvoiceMaster: "",
  minSizeInvoiceType_ProductReceiveInvoiceMaster: 0,
  maxSizeInvoiceType_ProductReceiveInvoiceMaster: 5,
  patternInvoiceType_ProductReceiveInvoiceMaster: "",
  minSizePaymentType_ProductReceiveInvoiceMaster: 0,
  maxSizePaymentType_ProductReceiveInvoiceMaster: 10,
  patternPaymentType_ProductReceiveInvoiceMaster: "",
  minSizePaymentAmt_ProductReceiveInvoiceMaster: 0,
  maxSizePaymentAmt_ProductReceiveInvoiceMaster: 26,
  patternPaymentAmt_ProductReceiveInvoiceMaster: "",
  minSizeBankRemark_ProductReceiveInvoiceMaster: 0,
  maxSizeBankRemark_ProductReceiveInvoiceMaster: 150,
  patternBankRemark_ProductReceiveInvoiceMaster: "",
  minSizeChqNo_ProductReceiveInvoiceMaster: 0,
  maxSizeChqNo_ProductReceiveInvoiceMaster: 20,
  patternChqNo_ProductReceiveInvoiceMaster: "",
  minSizeChqDate_ProductReceiveInvoiceMaster: 0,
  maxSizeChqDate_ProductReceiveInvoiceMaster: 20,
  patternChqDate_ProductReceiveInvoiceMaster: "",
  minSizePaymentChequeDocNo_ProductReceiveInvoiceMaster: 0,
  maxSizePaymentChequeDocNo_ProductReceiveInvoiceMaster: 20,
  patternPaymentChequeDocNo_ProductReceiveInvoiceMaster: "",
  minSizeProdRecInvVoucherCode_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvVoucherCode_ProductReceiveInvoiceMaster: 20,
  patternProdRecInvVoucherCode_ProductReceiveInvoiceMaster: "[0-9a-zA-Z/-]+",
  minSizeProdRecBookCode_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecBookCode_ProductReceiveInvoiceMaster: 50,
  patternProdRecBookCode_ProductReceiveInvoiceMaster: "[0-9a-zA-Z/-]+",
  minSizeProdRecInvWithHodingTax_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvWithHodingTax_ProductReceiveInvoiceMaster: 26,
  patternProdRecInvWithHodingTax_ProductReceiveInvoiceMaster: "",
  minSizeProdRecVatInclude_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecVatInclude_ProductReceiveInvoiceMaster: 2,
  patternProdRecVatInclude_ProductReceiveInvoiceMaster: "",
  minSizeBillAddress_ProductReceiveInvoiceMaster: 0,
  maxSizeBillAddress_ProductReceiveInvoiceMaster: 9999,
  patternBillAddress_ProductReceiveInvoiceMaster: "",
  minSizeBillTName_ProductReceiveInvoiceMaster: 0,
  maxSizeBillTName_ProductReceiveInvoiceMaster: 9999,
  patternBillTName_ProductReceiveInvoiceMaster: "",
  minSizeBillTelNo_ProductReceiveInvoiceMaster: 0,
  maxSizeBillTelNo_ProductReceiveInvoiceMaster: 100,
  patternBillTelNo_ProductReceiveInvoiceMaster: "",
  minSizeVer_ProductReceiveInvoiceMaster: 0,
  maxSizeVer_ProductReceiveInvoiceMaster: 10,
  patternVer_ProductReceiveInvoiceMaster: "",
  minSizeDivisionNo_ProductReceiveInvoiceMaster: 0,
  maxSizeDivisionNo_ProductReceiveInvoiceMaster: 10,
  patternDivisionNo_ProductReceiveInvoiceMaster: "",
  minSizeDivisionName_ProductReceiveInvoiceMaster: 0,
  maxSizeDivisionName_ProductReceiveInvoiceMaster: 50,
  patternDivisionName_ProductReceiveInvoiceMaster: "",
  minSizeDivisionAddress_ProductReceiveInvoiceMaster: 0,
  maxSizeDivisionAddress_ProductReceiveInvoiceMaster: 255,
  patternDivisionAddress_ProductReceiveInvoiceMaster: "",
  minSizeTaxIdNo_ProductReceiveInvoiceMaster: 0,
  maxSizeTaxIdNo_ProductReceiveInvoiceMaster: 50,
  patternTaxIdNo_ProductReceiveInvoiceMaster: "",
  minSizePrintStatus_ProductReceiveInvoiceMaster: 0,
  maxSizePrintStatus_ProductReceiveInvoiceMaster: 2,
  patternPrintStatus_ProductReceiveInvoiceMaster: "",
  minSizeTaxReturnStatus_ProductReceiveInvoiceMaster: 0,
  maxSizeTaxReturnStatus_ProductReceiveInvoiceMaster: 1,
  patternTaxReturnStatus_ProductReceiveInvoiceMaster: "",
  minSizeCurrencyId_ProductReceiveInvoiceMaster: 0,
  maxSizeCurrencyId_ProductReceiveInvoiceMaster: 19,
  patternCurrencyId_ProductReceiveInvoiceMaster: "",
  minSizeDocumentGroupId_ProductReceiveInvoiceMaster: 0,
  maxSizeDocumentGroupId_ProductReceiveInvoiceMaster: 19,
  patternDocumentGroupId_ProductReceiveInvoiceMaster: "",
  minSizeSupplierMasterId_ProductReceiveInvoiceMaster: 0,
  maxSizeSupplierMasterId_ProductReceiveInvoiceMaster: 19,
  patternSupplierMasterId_ProductReceiveInvoiceMaster: "",
  minSizeEmployeeMasterId_ProductReceiveInvoiceMaster: 0,
  maxSizeEmployeeMasterId_ProductReceiveInvoiceMaster: 19,
  patternEmployeeMasterId_ProductReceiveInvoiceMaster: "",
  minSizeDivisionId_ProductReceiveInvoiceMaster: 0,
  maxSizeDivisionId_ProductReceiveInvoiceMaster: 19,
  patternDivisionId_ProductReceiveInvoiceMaster: "",
  minSizeSectionId_ProductReceiveInvoiceMaster: 0,
  maxSizeSectionId_ProductReceiveInvoiceMaster: 19,
  patternSectionId_ProductReceiveInvoiceMaster: "",
  minSizeWarehouseId_ProductReceiveInvoiceMaster: 0,
  maxSizeWarehouseId_ProductReceiveInvoiceMaster: 19,
  patternWarehouseId_ProductReceiveInvoiceMaster: "",
  minSizeCreateByEmpId_ProductReceiveInvoiceMaster: 0,
  maxSizeCreateByEmpId_ProductReceiveInvoiceMaster: 19,
  patternCreateByEmpId_ProductReceiveInvoiceMaster: "",
  minSizeUpdateByEmpId_ProductReceiveInvoiceMaster: 0,
  maxSizeUpdateByEmpId_ProductReceiveInvoiceMaster: 19,
  patternUpdateByEmpId_ProductReceiveInvoiceMaster: "",
  minSizeCashAmt_ProductReceiveInvoiceMaster: 0,
  maxSizeCashAmt_ProductReceiveInvoiceMaster: 26,
  patternCashAmt_ProductReceiveInvoiceMaster: "",
  minSizeBankAmt_ProductReceiveInvoiceMaster: 0,
  maxSizeBankAmt_ProductReceiveInvoiceMaster: 26,
  patternBankAmt_ProductReceiveInvoiceMaster: "",
  minSizeChequeAmt_ProductReceiveInvoiceMaster: 0,
  maxSizeChequeAmt_ProductReceiveInvoiceMaster: 26,
  patternChequeAmt_ProductReceiveInvoiceMaster: "",
  minSizeAdCost_ProductReceiveInvoiceMaster: 0,
  maxSizeAdCost_ProductReceiveInvoiceMaster: 26,
  patternAdCost_ProductReceiveInvoiceMaster: "",
  minSizeDueDate_ProductReceiveInvoiceMaster: 0,
  maxSizeDueDate_ProductReceiveInvoiceMaster: 20,
  patternDueDate_ProductReceiveInvoiceMaster: "",
  minSizeSuppCreditDay_ProductReceiveInvoiceMaster: 0,
  maxSizeSuppCreditDay_ProductReceiveInvoiceMaster: 10,
  patternSuppCreditDay_ProductReceiveInvoiceMaster: "",
  minSizeSuppAddress_ProductReceiveInvoiceMaster: 0,
  maxSizeSuppAddress_ProductReceiveInvoiceMaster: 250,
  patternSuppAddress_ProductReceiveInvoiceMaster: "",
  minSizeSuppDivisionNo_ProductReceiveInvoiceMaster: 0,
  maxSizeSuppDivisionNo_ProductReceiveInvoiceMaster: 20,
  patternSuppDivisionNo_ProductReceiveInvoiceMaster: "",
  minSizeDescription_ProductReceiveInvoiceMaster: 0,
  maxSizeDescription_ProductReceiveInvoiceMaster: 250,
  patternDescription_ProductReceiveInvoiceMaster: "",
  minSizeCpDepositNo_ProductReceiveInvoiceMaster: 0,
  maxSizeCpDepositNo_ProductReceiveInvoiceMaster: 250,
  patternCpDepositNo_ProductReceiveInvoiceMaster: "",
  minSizeCpDepositAmt_ProductReceiveInvoiceMaster: 0,
  maxSizeCpDepositAmt_ProductReceiveInvoiceMaster: 26,
  patternCpDepositAmt_ProductReceiveInvoiceMaster: "",
  minSizeCpWithHoldingTax_ProductReceiveInvoiceMaster: 0,
  maxSizeCpWithHoldingTax_ProductReceiveInvoiceMaster: 26,
  patternCpWithHoldingTax_ProductReceiveInvoiceMaster: "",
  minSizeProdRecInvNetAmtNet_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvNetAmtNet_ProductReceiveInvoiceMaster: 26,
  patternProdRecInvNetAmtNet_ProductReceiveInvoiceMaster: "",
  minSizePettyCashCode_ProductReceiveInvoiceMaster: 0,
  maxSizePettyCashCode_ProductReceiveInvoiceMaster: 20,
  patternPettyCashCode_ProductReceiveInvoiceMaster: "[0-9a-zA-Z/-]+",
  minSizePettyCashName_ProductReceiveInvoiceMaster: 0,
  maxSizePettyCashName_ProductReceiveInvoiceMaster: 255,
  patternPettyCashName_ProductReceiveInvoiceMaster: "",
  minSizePettyCashAmt_ProductReceiveInvoiceMaster: 0,
  maxSizePettyCashAmt_ProductReceiveInvoiceMaster: 26,
  patternPettyCashAmt_ProductReceiveInvoiceMaster: "",
  minSizePcAccountCode_ProductReceiveInvoiceMaster: 0,
  maxSizePcAccountCode_ProductReceiveInvoiceMaster: 20,
  patternPcAccountCode_ProductReceiveInvoiceMaster: "[0-9a-zA-Z/-]+",
  minSizeLotNo_ProductReceiveInvoiceMaster: 0,
  maxSizeLotNo_ProductReceiveInvoiceMaster: 50,
  patternLotNo_ProductReceiveInvoiceMaster: "",
  minSizeExpireDate_ProductReceiveInvoiceMaster: 0,
  maxSizeExpireDate_ProductReceiveInvoiceMaster: 20,
  patternExpireDate_ProductReceiveInvoiceMaster: "",
  minSizeProductRelateReplace1Id_ProductRelateReplace1: 0,
  maxSizeProductRelateReplace1Id_ProductRelateReplace1: 19,
  patternProductRelateReplace1Id_ProductRelateReplace1: "",
  minSizeProdDetailDesc_ProductRelateReplace1: 0,
  maxSizeProdDetailDesc_ProductRelateReplace1: 150,
  patternProdDetailDesc_ProductRelateReplace1: "",
  minSizeProdIsRelate_ProductRelateReplace1: 0,
  maxSizeProdIsRelate_ProductRelateReplace1: 1,
  patternProdIsRelate_ProductRelateReplace1: "",
  minSizeProdIsReplace_ProductRelateReplace1: 0,
  maxSizeProdIsReplace_ProductRelateReplace1: 1,
  patternProdIsReplace_ProductRelateReplace1: "",
  minSizeCompanyCode_ProductRelateReplace1: 0,
  maxSizeCompanyCode_ProductRelateReplace1: 20,
  patternCompanyCode_ProductRelateReplace1: "[0-9a-zA-Z/-]+",
  minSizeProdCode_ProductRelateReplace1: 0,
  maxSizeProdCode_ProductRelateReplace1: 19,
  patternProdCode_ProductRelateReplace1: "[0-9a-zA-Z/-]+",
  minSizeProductMaster1Id_ProductRelateReplace1: 0,
  maxSizeProductMaster1Id_ProductRelateReplace1: 19,
  patternProductMaster1Id_ProductRelateReplace1: "",
  minSizeProductStaticId_ProductStatic: 0,
  maxSizeProductStaticId_ProductStatic: 19,
  patternProductStaticId_ProductStatic: "",
  minSizeProdBalOnHand_ProductStatic: 0,
  maxSizeProdBalOnHand_ProductStatic: 26,
  patternProdBalOnHand_ProductStatic: "",
  minSizeProdQtyRequest_ProductStatic: 0,
  maxSizeProdQtyRequest_ProductStatic: 26,
  patternProdQtyRequest_ProductStatic: "",
  minSizeProdQtyProduction_ProductStatic: 0,
  maxSizeProdQtyProduction_ProductStatic: 26,
  patternProdQtyProduction_ProductStatic: "",
  minSizeProdQtyReserve_ProductStatic: 0,
  maxSizeProdQtyReserve_ProductStatic: 26,
  patternProdQtyReserve_ProductStatic: "",
  minSizeProdQtyRent_ProductStatic: 0,
  maxSizeProdQtyRent_ProductStatic: 26,
  patternProdQtyRent_ProductStatic: "",
  minSizeProdMinQty_ProductStatic: 0,
  maxSizeProdMinQty_ProductStatic: 26,
  patternProdMinQty_ProductStatic: "",
  minSizeDrawingNo_ProductStatic: 0,
  maxSizeDrawingNo_ProductStatic: 100,
  patternDrawingNo_ProductStatic: "",
  minSizeProdMaxQty_ProductStatic: 0,
  maxSizeProdMaxQty_ProductStatic: 26,
  patternProdMaxQty_ProductStatic: "",
  minSizeProdReOrderQty_ProductStatic: 0,
  maxSizeProdReOrderQty_ProductStatic: 26,
  patternProdReOrderQty_ProductStatic: "",
  minSizeProdSafetyStock_ProductStatic: 0,
  maxSizeProdSafetyStock_ProductStatic: 26,
  patternProdSafetyStock_ProductStatic: "",
  minSizeCompanyCode_ProductStatic: 0,
  maxSizeCompanyCode_ProductStatic: 20,
  patternCompanyCode_ProductStatic: "[0-9a-zA-Z/-]+",
  minSizeCost_ProductStatic: 0,
  maxSizeCost_ProductStatic: 26,
  patternCost_ProductStatic: "",
  minSizeStatusSending_ProductStatic: 0,
  maxSizeStatusSending_ProductStatic: 1,
  patternStatusSending_ProductStatic: "",
  minSizeVer_ProductStatic: 0,
  maxSizeVer_ProductStatic: 10,
  patternVer_ProductStatic: "",
  minSizeWarehouseId_ProductStatic: 0,
  maxSizeWarehouseId_ProductStatic: 19,
  patternWarehouseId_ProductStatic: "",
  minSizeBalance_ProductStatic: 0,
  maxSizeBalance_ProductStatic: 26,
  patternBalance_ProductStatic: "",
  minSizeProdPO_ProductStatic: 0,
  maxSizeProdPO_ProductStatic: 26,
  patternProdPO_ProductStatic: "",
  minSizeProductStaticId_ProductStaticMaster1: 0,
  maxSizeProductStaticId_ProductStaticMaster1: 19,
  patternProductStaticId_ProductStaticMaster1: "",
  minSizeProductMaster1Id_ProductStaticMaster1: 0,
  maxSizeProductMaster1Id_ProductStaticMaster1: 19,
  patternProductMaster1Id_ProductStaticMaster1: "",
  minSizeProductSubGroup1Id_ProductSubGroup1: 0,
  maxSizeProductSubGroup1Id_ProductSubGroup1: 19,
  patternProductSubGroup1Id_ProductSubGroup1: "",
  minSizeCompanyCode_ProductSubGroup1: 0,
  maxSizeCompanyCode_ProductSubGroup1: 20,
  patternCompanyCode_ProductSubGroup1: "[0-9a-zA-Z/-]+",
  minSizeStatusSending_ProductSubGroup1: 0,
  maxSizeStatusSending_ProductSubGroup1: 1,
  patternStatusSending_ProductSubGroup1: "",
  minSizeVer_ProductSubGroup1: 0,
  maxSizeVer_ProductSubGroup1: 10,
  patternVer_ProductSubGroup1: "",
  minSizeProductGroupId_ProductSubGroup1: 0,
  maxSizeProductGroupId_ProductSubGroup1: 19,
  patternProductGroupId_ProductSubGroup1: "",
  minSizeDiscount_ProductSubGroup1: 0,
  maxSizeDiscount_ProductSubGroup1: 26,
  patternDiscount_ProductSubGroup1: "",
  minSizeChkAllProduct_ProductSubGroup1: 0,
  maxSizeChkAllProduct_ProductSubGroup1: 2,
  patternChkAllProduct_ProductSubGroup1: "",
  minSizeProductUnit1Id_ProductUnit1: 0,
  maxSizeProductUnit1Id_ProductUnit1: 19,
  patternProductUnit1Id_ProductUnit1: "",
  minSizeProdUnitRatio_ProductUnit1: 0,
  maxSizeProdUnitRatio_ProductUnit1: 16,
  patternProdUnitRatio_ProductUnit1: "",
  minSizeProdUnitWidth_ProductUnit1: 0,
  maxSizeProdUnitWidth_ProductUnit1: 150,
  patternProdUnitWidth_ProductUnit1: "",
  minSizeProdUnitLength_ProductUnit1: 0,
  maxSizeProdUnitLength_ProductUnit1: 150,
  patternProdUnitLength_ProductUnit1: "",
  minSizeProdUnitHeight_ProductUnit1: 0,
  maxSizeProdUnitHeight_ProductUnit1: 150,
  patternProdUnitHeight_ProductUnit1: "",
  minSizeProdUnitWeight_ProductUnit1: 0,
  maxSizeProdUnitWeight_ProductUnit1: 150,
  patternProdUnitWeight_ProductUnit1: "",
  minSizeProdUnitVulume_ProductUnit1: 0,
  maxSizeProdUnitVulume_ProductUnit1: 150,
  patternProdUnitVulume_ProductUnit1: "",
  minSizeProdGrade_ProductUnit1: 0,
  maxSizeProdGrade_ProductUnit1: 150,
  patternProdGrade_ProductUnit1: "",
  minSizeProdUnitDescription_ProductUnit1: 0,
  maxSizeProdUnitDescription_ProductUnit1: 50,
  patternProdUnitDescription_ProductUnit1: "",
  minSizeProdUnitBarcode_ProductUnit1: 0,
  maxSizeProdUnitBarcode_ProductUnit1: 20,
  patternProdUnitBarcode_ProductUnit1: "[0-9a-zA-Z/-]+",
  minSizeProdUnitPricel1_ProductUnit1: 0,
  maxSizeProdUnitPricel1_ProductUnit1: 26,
  patternProdUnitPricel1_ProductUnit1: "",
  minSizeProdUnitPricel2_ProductUnit1: 0,
  maxSizeProdUnitPricel2_ProductUnit1: 26,
  patternProdUnitPricel2_ProductUnit1: "",
  minSizeProdUnitPricel3_ProductUnit1: 0,
  maxSizeProdUnitPricel3_ProductUnit1: 26,
  patternProdUnitPricel3_ProductUnit1: "",
  minSizeProdUnitPricel4_ProductUnit1: 0,
  maxSizeProdUnitPricel4_ProductUnit1: 26,
  patternProdUnitPricel4_ProductUnit1: "",
  minSizeProdUnitDiscountl1_ProductUnit1: 0,
  maxSizeProdUnitDiscountl1_ProductUnit1: 26,
  patternProdUnitDiscountl1_ProductUnit1: "",
  minSizeProdUnitDiscountl2_ProductUnit1: 0,
  maxSizeProdUnitDiscountl2_ProductUnit1: 26,
  patternProdUnitDiscountl2_ProductUnit1: "",
  minSizeProdUnitDiscountl3_ProductUnit1: 0,
  maxSizeProdUnitDiscountl3_ProductUnit1: 26,
  patternProdUnitDiscountl3_ProductUnit1: "",
  minSizeProdUnitDiscountl4_ProductUnit1: 0,
  maxSizeProdUnitDiscountl4_ProductUnit1: 26,
  patternProdUnitDiscountl4_ProductUnit1: "",
  minSizeProdUnitPurchasePrice_ProductUnit1: 0,
  maxSizeProdUnitPurchasePrice_ProductUnit1: 26,
  patternProdUnitPurchasePrice_ProductUnit1: "",
  minSizeProdUnitSaleUnit_ProductUnit1: 0,
  maxSizeProdUnitSaleUnit_ProductUnit1: 2,
  patternProdUnitSaleUnit_ProductUnit1: "",
  minSizeProdUnitPurchaseUnit_ProductUnit1: 0,
  maxSizeProdUnitPurchaseUnit_ProductUnit1: 2,
  patternProdUnitPurchaseUnit_ProductUnit1: "",
  minSizeCompanyCode_ProductUnit1: 0,
  maxSizeCompanyCode_ProductUnit1: 20,
  patternCompanyCode_ProductUnit1: "[0-9a-zA-Z/-]+",
  minSizeStatusSending_ProductUnit1: 0,
  maxSizeStatusSending_ProductUnit1: 1,
  patternStatusSending_ProductUnit1: "",
  minSizeProdUnitBaseBuy_ProductUnit1: 0,
  maxSizeProdUnitBaseBuy_ProductUnit1: 10,
  patternProdUnitBaseBuy_ProductUnit1: "",
  minSizeUnitOfMeasureId_ProductUnit1: 0,
  maxSizeUnitOfMeasureId_ProductUnit1: 19,
  patternUnitOfMeasureId_ProductUnit1: "",
  minSizeStatus_ProductUnit1: 0,
  maxSizeStatus_ProductUnit1: 2,
  patternStatus_ProductUnit1: "",
  minSizePurchaseRecordingDetailId_PurchaseRecordingDetail: 0,
  maxSizePurchaseRecordingDetailId_PurchaseRecordingDetail: 19,
  patternPurchaseRecordingDetailId_PurchaseRecordingDetail: "",
  minSizeNumber_PurchaseRecordingDetail: 0,
  maxSizeNumber_PurchaseRecordingDetail: 10,
  patternNumber_PurchaseRecordingDetail: "",
  minSizeDocNo_PurchaseRecordingDetail: 0,
  maxSizeDocNo_PurchaseRecordingDetail: 30,
  patternDocNo_PurchaseRecordingDetail: "",
  minSizeDocDate_PurchaseRecordingDetail: 0,
  maxSizeDocDate_PurchaseRecordingDetail: 20,
  patternDocDate_PurchaseRecordingDetail: "",
  minSizeDocType_PurchaseRecordingDetail: 0,
  maxSizeDocType_PurchaseRecordingDetail: 5,
  patternDocType_PurchaseRecordingDetail: "",
  minSizeInvoiceNo_PurchaseRecordingDetail: 0,
  maxSizeInvoiceNo_PurchaseRecordingDetail: 100,
  patternInvoiceNo_PurchaseRecordingDetail: "",
  minSizePurchaseRecordTotal_PurchaseRecordingDetail: 0,
  maxSizePurchaseRecordTotal_PurchaseRecordingDetail: 26,
  patternPurchaseRecordTotal_PurchaseRecordingDetail: "",
  minSizePurchaseRecordDiscRate_PurchaseRecordingDetail: 0,
  maxSizePurchaseRecordDiscRate_PurchaseRecordingDetail: 26,
  patternPurchaseRecordDiscRate_PurchaseRecordingDetail: "",
  minSizePurchaseRecordDiscAmt_PurchaseRecordingDetail: 0,
  maxSizePurchaseRecordDiscAmt_PurchaseRecordingDetail: 26,
  patternPurchaseRecordDiscAmt_PurchaseRecordingDetail: "",
  minSizePurchaseRecordDepositNo_PurchaseRecordingDetail: 0,
  maxSizePurchaseRecordDepositNo_PurchaseRecordingDetail: 20,
  patternPurchaseRecordDepositNo_PurchaseRecordingDetail: "",
  minSizePurchaseRecordDepositAmt_PurchaseRecordingDetail: 0,
  maxSizePurchaseRecordDepositAmt_PurchaseRecordingDetail: 26,
  patternPurchaseRecordDepositAmt_PurchaseRecordingDetail: "",
  minSizePurchaseRecordVatRate_PurchaseRecordingDetail: 0,
  maxSizePurchaseRecordVatRate_PurchaseRecordingDetail: 26,
  patternPurchaseRecordVatRate_PurchaseRecordingDetail: "",
  minSizePurchaseRecordVatAmt_PurchaseRecordingDetail: 0,
  maxSizePurchaseRecordVatAmt_PurchaseRecordingDetail: 26,
  patternPurchaseRecordVatAmt_PurchaseRecordingDetail: "",
  minSizePurchaseRecordNetAmt_PurchaseRecordingDetail: 0,
  maxSizePurchaseRecordNetAmt_PurchaseRecordingDetail: 26,
  patternPurchaseRecordNetAmt_PurchaseRecordingDetail: "",
  minSizePurchaseRecordPayAmt_PurchaseRecordingDetail: 0,
  maxSizePurchaseRecordPayAmt_PurchaseRecordingDetail: 26,
  patternPurchaseRecordPayAmt_PurchaseRecordingDetail: "",
  minSizeCompanyCode_PurchaseRecordingDetail: 0,
  maxSizeCompanyCode_PurchaseRecordingDetail: 20,
  patternCompanyCode_PurchaseRecordingDetail: "[0-9a-zA-Z/-]+",
  minSizeTaxReturn_PurchaseRecordingDetail: 0,
  maxSizeTaxReturn_PurchaseRecordingDetail: 2,
  patternTaxReturn_PurchaseRecordingDetail: "",
  minSizeBaseValue1_PurchaseRecordingDetail: 0,
  maxSizeBaseValue1_PurchaseRecordingDetail: 26,
  patternBaseValue1_PurchaseRecordingDetail: "",
  minSizeVatAmount1_PurchaseRecordingDetail: 0,
  maxSizeVatAmount1_PurchaseRecordingDetail: 26,
  patternVatAmount1_PurchaseRecordingDetail: "",
  minSizePurchaseRecordingMasterId_PurchaseRecordingDetail: 0,
  maxSizePurchaseRecordingMasterId_PurchaseRecordingDetail: 19,
  patternPurchaseRecordingMasterId_PurchaseRecordingDetail: "",
  minSizePurchaseRecordingMasterId_PurchaseRecordingMaster: 0,
  maxSizePurchaseRecordingMasterId_PurchaseRecordingMaster: 19,
  patternPurchaseRecordingMasterId_PurchaseRecordingMaster: "",
  minSizePurchaseRecordDocNo_PurchaseRecordingMaster: 0,
  maxSizePurchaseRecordDocNo_PurchaseRecordingMaster: 20,
  patternPurchaseRecordDocNo_PurchaseRecordingMaster: "",
  minSizePurchaseRecordDocDate_PurchaseRecordingMaster: 0,
  maxSizePurchaseRecordDocDate_PurchaseRecordingMaster: 20,
  patternPurchaseRecordDocDate_PurchaseRecordingMaster: "",
  minSizePurchaseRecordRemark_PurchaseRecordingMaster: 0,
  maxSizePurchaseRecordRemark_PurchaseRecordingMaster: 500,
  patternPurchaseRecordRemark_PurchaseRecordingMaster: "",
  minSizePurchaseRecordStatus_PurchaseRecordingMaster: 0,
  maxSizePurchaseRecordStatus_PurchaseRecordingMaster: 2,
  patternPurchaseRecordStatus_PurchaseRecordingMaster: "",
  minSizeCreateOn_PurchaseRecordingMaster: 0,
  maxSizeCreateOn_PurchaseRecordingMaster: 25,
  patternCreateOn_PurchaseRecordingMaster: "",
  minSizeUpdateOn_PurchaseRecordingMaster: 0,
  maxSizeUpdateOn_PurchaseRecordingMaster: 25,
  patternUpdateOn_PurchaseRecordingMaster: "",
  minSizePurchaseRecordTotal_PurchaseRecordingMaster: 0,
  maxSizePurchaseRecordTotal_PurchaseRecordingMaster: 26,
  patternPurchaseRecordTotal_PurchaseRecordingMaster: "",
  minSizePurchaseRecordDiscRate_PurchaseRecordingMaster: 0,
  maxSizePurchaseRecordDiscRate_PurchaseRecordingMaster: 26,
  patternPurchaseRecordDiscRate_PurchaseRecordingMaster: "",
  minSizePurchaseRecordDiscAmt_PurchaseRecordingMaster: 0,
  maxSizePurchaseRecordDiscAmt_PurchaseRecordingMaster: 26,
  patternPurchaseRecordDiscAmt_PurchaseRecordingMaster: "",
  minSizePurchaseRecordDepositNo_PurchaseRecordingMaster: 0,
  maxSizePurchaseRecordDepositNo_PurchaseRecordingMaster: 20,
  patternPurchaseRecordDepositNo_PurchaseRecordingMaster: "",
  minSizePurchaseRecordDepositAmt_PurchaseRecordingMaster: 0,
  maxSizePurchaseRecordDepositAmt_PurchaseRecordingMaster: 26,
  patternPurchaseRecordDepositAmt_PurchaseRecordingMaster: "",
  minSizePurchaseRecordVatRate_PurchaseRecordingMaster: 0,
  maxSizePurchaseRecordVatRate_PurchaseRecordingMaster: 26,
  patternPurchaseRecordVatRate_PurchaseRecordingMaster: "",
  minSizePurchaseRecordVatAmt_PurchaseRecordingMaster: 0,
  maxSizePurchaseRecordVatAmt_PurchaseRecordingMaster: 26,
  patternPurchaseRecordVatAmt_PurchaseRecordingMaster: "",
  minSizePurchaseRecordNetAmt_PurchaseRecordingMaster: 0,
  maxSizePurchaseRecordNetAmt_PurchaseRecordingMaster: 26,
  patternPurchaseRecordNetAmt_PurchaseRecordingMaster: "",
  minSizePurchaseRecordPayAmt_PurchaseRecordingMaster: 0,
  maxSizePurchaseRecordPayAmt_PurchaseRecordingMaster: 26,
  patternPurchaseRecordPayAmt_PurchaseRecordingMaster: "",
  minSizeVoucherCode_PurchaseRecordingMaster: 0,
  maxSizeVoucherCode_PurchaseRecordingMaster: 20,
  patternVoucherCode_PurchaseRecordingMaster: "[0-9a-zA-Z/-]+",
  minSizeCompanyCode_PurchaseRecordingMaster: 0,
  maxSizeCompanyCode_PurchaseRecordingMaster: 20,
  patternCompanyCode_PurchaseRecordingMaster: "[0-9a-zA-Z/-]+",
  minSizeExchangeRate_PurchaseRecordingMaster: 0,
  maxSizeExchangeRate_PurchaseRecordingMaster: 26,
  patternExchangeRate_PurchaseRecordingMaster: "",
  minSizeTaxReturn_PurchaseRecordingMaster: 0,
  maxSizeTaxReturn_PurchaseRecordingMaster: 2,
  patternTaxReturn_PurchaseRecordingMaster: "",
  minSizeBillingType_PurchaseRecordingMaster: 0,
  maxSizeBillingType_PurchaseRecordingMaster: 2,
  patternBillingType_PurchaseRecordingMaster: "",
  minSizeExchageRateDate_PurchaseRecordingMaster: 0,
  maxSizeExchageRateDate_PurchaseRecordingMaster: 20,
  patternExchageRateDate_PurchaseRecordingMaster: "",
  minSizeAStatus_PurchaseRecordingMaster: 0,
  maxSizeAStatus_PurchaseRecordingMaster: 2,
  patternAStatus_PurchaseRecordingMaster: "",
  minSizeDivisionNo_PurchaseRecordingMaster: 0,
  maxSizeDivisionNo_PurchaseRecordingMaster: 10,
  patternDivisionNo_PurchaseRecordingMaster: "",
  minSizeDivisionName_PurchaseRecordingMaster: 0,
  maxSizeDivisionName_PurchaseRecordingMaster: 50,
  patternDivisionName_PurchaseRecordingMaster: "",
  minSizeDivisionAddress_PurchaseRecordingMaster: 0,
  maxSizeDivisionAddress_PurchaseRecordingMaster: 255,
  patternDivisionAddress_PurchaseRecordingMaster: "",
  minSizeTaxIdNo_PurchaseRecordingMaster: 0,
  maxSizeTaxIdNo_PurchaseRecordingMaster: 50,
  patternTaxIdNo_PurchaseRecordingMaster: "",
  minSizeCurrencyId_PurchaseRecordingMaster: 0,
  maxSizeCurrencyId_PurchaseRecordingMaster: 19,
  patternCurrencyId_PurchaseRecordingMaster: "",
  minSizeDocumentGroupId_PurchaseRecordingMaster: 0,
  maxSizeDocumentGroupId_PurchaseRecordingMaster: 19,
  patternDocumentGroupId_PurchaseRecordingMaster: "",
  minSizeSupplierMasterId_PurchaseRecordingMaster: 0,
  maxSizeSupplierMasterId_PurchaseRecordingMaster: 19,
  patternSupplierMasterId_PurchaseRecordingMaster: "",
  minSizeAccountCodeId_PurchaseRecordingMaster: 0,
  maxSizeAccountCodeId_PurchaseRecordingMaster: 19,
  patternAccountCodeId_PurchaseRecordingMaster: "[0-9a-zA-Z/-]+",
  minSizeCreateByEmpId_PurchaseRecordingMaster: 0,
  maxSizeCreateByEmpId_PurchaseRecordingMaster: 19,
  patternCreateByEmpId_PurchaseRecordingMaster: "",
  minSizeUpdateByEmpId_PurchaseRecordingMaster: 0,
  maxSizeUpdateByEmpId_PurchaseRecordingMaster: 19,
  patternUpdateByEmpId_PurchaseRecordingMaster: "",
  minSizeProductReceiveInvoiceMasterId_PurchaseRecordingMaster: 0,
  maxSizeProductReceiveInvoiceMasterId_PurchaseRecordingMaster: 19,
  patternProductReceiveInvoiceMasterId_PurchaseRecordingMaster: "",
  minSizeRcvServiceMasterId_PurchaseRecordingMaster: 0,
  maxSizeRcvServiceMasterId_PurchaseRecordingMaster: 19,
  patternRcvServiceMasterId_PurchaseRecordingMaster: "",
  minSizePrintStatus_PurchaseRecordingMaster: 0,
  maxSizePrintStatus_PurchaseRecordingMaster: 2,
  patternPrintStatus_PurchaseRecordingMaster: "",
  minSizeDivisionId_PurchaseRecordingMaster: 0,
  maxSizeDivisionId_PurchaseRecordingMaster: 19,
  patternDivisionId_PurchaseRecordingMaster: "",
  minSizeSectionId_PurchaseRecordingMaster: 0,
  maxSizeSectionId_PurchaseRecordingMaster: 19,
  patternSectionId_PurchaseRecordingMaster: "",
  minSizeNoReFundTax_PurchaseRecordingMaster: 0,
  maxSizeNoReFundTax_PurchaseRecordingMaster: 2,
  patternNoReFundTax_PurchaseRecordingMaster: "",
  minSizeJobCode_PurchaseRecordingMaster: 0,
  maxSizeJobCode_PurchaseRecordingMaster: 50,
  patternJobCode_PurchaseRecordingMaster: "[0-9a-zA-Z/-]+",
  minSizeReceiveTypeId_ReceiveType: 0,
  maxSizeReceiveTypeId_ReceiveType: 19,
  patternReceiveTypeId_ReceiveType: "",
  minSizeReceiveTypeCode_ReceiveType: 0,
  maxSizeReceiveTypeCode_ReceiveType: 20,
  patternReceiveTypeCode_ReceiveType: "[0-9a-zA-Z/-]+",
  minSizeReceiveTypeName_ReceiveType: 0,
  maxSizeReceiveTypeName_ReceiveType: 150,
  patternReceiveTypeName_ReceiveType: "",
  minSizeCompanyCode_ReceiveType: 0,
  maxSizeCompanyCode_ReceiveType: 20,
  patternCompanyCode_ReceiveType: "[0-9a-zA-Z/-]+",
  minSizeSaleArea1Id_SaleArea1: 0,
  maxSizeSaleArea1Id_SaleArea1: 19,
  patternSaleArea1Id_SaleArea1: "",
  minSizeSaleAreaCode_SaleArea1: 0,
  maxSizeSaleAreaCode_SaleArea1: 20,
  patternSaleAreaCode_SaleArea1: "[0-9a-zA-Z/-]+",
  minSizeSaleAreaName_SaleArea1: 0,
  maxSizeSaleAreaName_SaleArea1: 250,
  patternSaleAreaName_SaleArea1: "",
  minSizeSaleAreaEName_SaleArea1: 0,
  maxSizeSaleAreaEName_SaleArea1: 250,
  patternSaleAreaEName_SaleArea1: "",
  minSizeRemark_SaleArea1: 0,
  maxSizeRemark_SaleArea1: 1500,
  patternRemark_SaleArea1: "",
  minSizeCompanyCode_SaleArea1: 0,
  maxSizeCompanyCode_SaleArea1: 20,
  patternCompanyCode_SaleArea1: "[0-9a-zA-Z/-]+",
  minSizeCustName_SaleConsignMaster: 0,
  maxSizeCustName_SaleConsignMaster: 255,
  patternCustName_SaleConsignMaster: "",
  minSizeDivisionAddress_SaleConsignMaster: 0,
  maxSizeDivisionAddress_SaleConsignMaster: 1000,
  patternDivisionAddress_SaleConsignMaster: "",
  minSizePoNo_SaleConsignMaster: 0,
  maxSizePoNo_SaleConsignMaster: 100,
  patternPoNo_SaleConsignMaster: "",
  minSizeCustDelivery_SaleConsignMaster: 0,
  maxSizeCustDelivery_SaleConsignMaster: 250,
  patternCustDelivery_SaleConsignMaster: "",
  minSizeSoRemark_SaleConsignMaster: 0,
  maxSizeSoRemark_SaleConsignMaster: 150,
  patternSoRemark_SaleConsignMaster: "",
  minSizeSaleOrderProductDetailId_SaleOrderProductDetail: 0,
  maxSizeSaleOrderProductDetailId_SaleOrderProductDetail: 19,
  patternSaleOrderProductDetailId_SaleOrderProductDetail: "",
  minSizeSaleOrderProductDetailNo_SaleOrderProductDetail: 0,
  maxSizeSaleOrderProductDetailNo_SaleOrderProductDetail: 10,
  patternSaleOrderProductDetailNo_SaleOrderProductDetail: "",
  minSizeProdQty_SaleOrderProductDetail: 0,
  maxSizeProdQty_SaleOrderProductDetail: 26,
  patternProdQty_SaleOrderProductDetail: "",
  minSizeProdDeliveryQty_SaleOrderProductDetail: 0,
  maxSizeProdDeliveryQty_SaleOrderProductDetail: 26,
  patternProdDeliveryQty_SaleOrderProductDetail: "",
  minSizeProdConvfactor_SaleOrderProductDetail: 0,
  maxSizeProdConvfactor_SaleOrderProductDetail: 26,
  patternProdConvfactor_SaleOrderProductDetail: "",
  minSizeProdBaseQty_SaleOrderProductDetail: 0,
  maxSizeProdBaseQty_SaleOrderProductDetail: 26,
  patternProdBaseQty_SaleOrderProductDetail: "",
  minSizeProdDeliveryBaseQty_SaleOrderProductDetail: 0,
  maxSizeProdDeliveryBaseQty_SaleOrderProductDetail: 26,
  patternProdDeliveryBaseQty_SaleOrderProductDetail: "",
  minSizeProdPrice_SaleOrderProductDetail: 0,
  maxSizeProdPrice_SaleOrderProductDetail: 26,
  patternProdPrice_SaleOrderProductDetail: "",
  minSizeProdDiscRate_SaleOrderProductDetail: 0,
  maxSizeProdDiscRate_SaleOrderProductDetail: 26,
  patternProdDiscRate_SaleOrderProductDetail: "",
  minSizeProdDiscAmt_SaleOrderProductDetail: 0,
  maxSizeProdDiscAmt_SaleOrderProductDetail: 26,
  patternProdDiscAmt_SaleOrderProductDetail: "",
  minSizeProdNetprice_SaleOrderProductDetail: 0,
  maxSizeProdNetprice_SaleOrderProductDetail: 26,
  patternProdNetprice_SaleOrderProductDetail: "",
  minSizeSerialQty_SaleOrderProductDetail: 0,
  maxSizeSerialQty_SaleOrderProductDetail: 26,
  patternSerialQty_SaleOrderProductDetail: "",
  minSizeJoReceiveQty_SaleOrderProductDetail: 0,
  maxSizeJoReceiveQty_SaleOrderProductDetail: 26,
  patternJoReceiveQty_SaleOrderProductDetail: "",
  minSizeJoReceiveBaseQty_SaleOrderProductDetail: 0,
  maxSizeJoReceiveBaseQty_SaleOrderProductDetail: 26,
  patternJoReceiveBaseQty_SaleOrderProductDetail: "",
  minSizeQuDocNo_SaleOrderProductDetail: 0,
  maxSizeQuDocNo_SaleOrderProductDetail: 20,
  patternQuDocNo_SaleOrderProductDetail: "",
  minSizeSendDate_SaleOrderProductDetail: 0,
  maxSizeSendDate_SaleOrderProductDetail: 20,
  patternSendDate_SaleOrderProductDetail: "",
  minSizeProdSoType_SaleOrderProductDetail: 0,
  maxSizeProdSoType_SaleOrderProductDetail: 10,
  patternProdSoType_SaleOrderProductDetail: "",
  minSizeQuDocIndex_SaleOrderProductDetail: 0,
  maxSizeQuDocIndex_SaleOrderProductDetail: 10,
  patternQuDocIndex_SaleOrderProductDetail: "",
  minSizeProdDeliveryDate_SaleOrderProductDetail: 0,
  maxSizeProdDeliveryDate_SaleOrderProductDetail: 25,
  patternProdDeliveryDate_SaleOrderProductDetail: "",
  minSizeProdDeliveryDateComplete_SaleOrderProductDetail: 0,
  maxSizeProdDeliveryDateComplete_SaleOrderProductDetail: 25,
  patternProdDeliveryDateComplete_SaleOrderProductDetail: "",
  minSizeProdLastDeliveryDocNo_SaleOrderProductDetail: 0,
  maxSizeProdLastDeliveryDocNo_SaleOrderProductDetail: 20,
  patternProdLastDeliveryDocNo_SaleOrderProductDetail: "",
  minSizeCompanyCode_SaleOrderProductDetail: 0,
  maxSizeCompanyCode_SaleOrderProductDetail: 20,
  patternCompanyCode_SaleOrderProductDetail: "[0-9a-zA-Z/-]+",
  minSizeProdDiscRate0_SaleOrderProductDetail: 0,
  maxSizeProdDiscRate0_SaleOrderProductDetail: 20,
  patternProdDiscRate0_SaleOrderProductDetail: "",
  minSizePoNo_SaleOrderProductDetail: 0,
  maxSizePoNo_SaleOrderProductDetail: 255,
  patternPoNo_SaleOrderProductDetail: "",
  minSizeProdName_SaleOrderProductDetail: 0,
  maxSizeProdName_SaleOrderProductDetail: 300,
  patternProdName_SaleOrderProductDetail: "",
  minSizeJoReference_SaleOrderProductDetail: 0,
  maxSizeJoReference_SaleOrderProductDetail: 255,
  patternJoReference_SaleOrderProductDetail: "",
  minSizeProdItemDetail_SaleOrderProductDetail: 0,
  maxSizeProdItemDetail_SaleOrderProductDetail: 9999,
  patternProdItemDetail_SaleOrderProductDetail: "",
  minSizeSerialNo_SaleOrderProductDetail: 0,
  maxSizeSerialNo_SaleOrderProductDetail: 255,
  patternSerialNo_SaleOrderProductDetail: "",
  minSizeSerialNoText_SaleOrderProductDetail: 0,
  maxSizeSerialNoText_SaleOrderProductDetail: 9999,
  patternSerialNoText_SaleOrderProductDetail: "",
  minSizeProductMaster1Id_SaleOrderProductDetail: 0,
  maxSizeProductMaster1Id_SaleOrderProductDetail: 19,
  patternProductMaster1Id_SaleOrderProductDetail: "",
  minSizeWarehouse1Id_SaleOrderProductDetail: 0,
  maxSizeWarehouse1Id_SaleOrderProductDetail: 19,
  patternWarehouse1Id_SaleOrderProductDetail: "",
  minSizeUnitOfMeasureId1_SaleOrderProductDetail: 0,
  maxSizeUnitOfMeasureId1_SaleOrderProductDetail: 19,
  patternUnitOfMeasureId1_SaleOrderProductDetail: "",
  minSizeUnitOfMeasureid2_SaleOrderProductDetail: 0,
  maxSizeUnitOfMeasureid2_SaleOrderProductDetail: 19,
  patternUnitOfMeasureid2_SaleOrderProductDetail: "",
  minSizeSaleOrderProductMasterId_SaleOrderProductDetail: 0,
  maxSizeSaleOrderProductMasterId_SaleOrderProductDetail: 19,
  patternSaleOrderProductMasterId_SaleOrderProductDetail: "",
  minSizeSoProdStatus_SaleOrderProductDetail: 0,
  maxSizeSoProdStatus_SaleOrderProductDetail: 1,
  patternSoProdStatus_SaleOrderProductDetail: "",
  minSizeProdQtyUse_SaleOrderProductDetail: 0,
  maxSizeProdQtyUse_SaleOrderProductDetail: 26,
  patternProdQtyUse_SaleOrderProductDetail: "",
  minSizeImageUrl_SaleOrderProductDetail: 0,
  maxSizeImageUrl_SaleOrderProductDetail: 255,
  patternImageUrl_SaleOrderProductDetail: "",
  minSizeSaleOrderProductMasterId_SaleOrderProductMaster: 0,
  maxSizeSaleOrderProductMasterId_SaleOrderProductMaster: 19,
  patternSaleOrderProductMasterId_SaleOrderProductMaster: "",
  minSizeSoDocNo_SaleOrderProductMaster: 0,
  maxSizeSoDocNo_SaleOrderProductMaster: 20,
  patternSoDocNo_SaleOrderProductMaster: "",
  minSizeSoDocDate_SaleOrderProductMaster: 0,
  maxSizeSoDocDate_SaleOrderProductMaster: 25,
  patternSoDocDate_SaleOrderProductMaster: "",
  minSizeSoCreditTerm_SaleOrderProductMaster: 0,
  maxSizeSoCreditTerm_SaleOrderProductMaster: 10,
  patternSoCreditTerm_SaleOrderProductMaster: "",
  minSizeSoTotal_SaleOrderProductMaster: 0,
  maxSizeSoTotal_SaleOrderProductMaster: 26,
  patternSoTotal_SaleOrderProductMaster: "",
  minSizeSoVatRate_SaleOrderProductMaster: 0,
  maxSizeSoVatRate_SaleOrderProductMaster: 26,
  patternSoVatRate_SaleOrderProductMaster: "",
  minSizeSoVatAmt_SaleOrderProductMaster: 0,
  maxSizeSoVatAmt_SaleOrderProductMaster: 26,
  patternSoVatAmt_SaleOrderProductMaster: "",
  minSizeSoNetAmt_SaleOrderProductMaster: 0,
  maxSizeSoNetAmt_SaleOrderProductMaster: 26,
  patternSoNetAmt_SaleOrderProductMaster: "",
  minSizeSoRemark_SaleOrderProductMaster: 0,
  maxSizeSoRemark_SaleOrderProductMaster: 500,
  patternSoRemark_SaleOrderProductMaster: "",
  minSizeSoStatus_SaleOrderProductMaster: 0,
  maxSizeSoStatus_SaleOrderProductMaster: 2,
  patternSoStatus_SaleOrderProductMaster: "",
  minSizeSoLinkDoc_SaleOrderProductMaster: 0,
  maxSizeSoLinkDoc_SaleOrderProductMaster: 20,
  patternSoLinkDoc_SaleOrderProductMaster: "",
  minSizeCreateOn_SaleOrderProductMaster: 0,
  maxSizeCreateOn_SaleOrderProductMaster: 25,
  patternCreateOn_SaleOrderProductMaster: "",
  minSizeUpdateOn_SaleOrderProductMaster: 0,
  maxSizeUpdateOn_SaleOrderProductMaster: 25,
  patternUpdateOn_SaleOrderProductMaster: "",
  minSizeSoBeforeChangeStatus_SaleOrderProductMaster: 0,
  maxSizeSoBeforeChangeStatus_SaleOrderProductMaster: 2,
  patternSoBeforeChangeStatus_SaleOrderProductMaster: "",
  minSizeSoDateComplete_SaleOrderProductMaster: 0,
  maxSizeSoDateComplete_SaleOrderProductMaster: 20,
  patternSoDateComplete_SaleOrderProductMaster: "",
  minSizeCompanyCode_SaleOrderProductMaster: 0,
  maxSizeCompanyCode_SaleOrderProductMaster: 20,
  patternCompanyCode_SaleOrderProductMaster: "[0-9a-zA-Z/-]+",
  minSizeVatInclude_SaleOrderProductMaster: 0,
  maxSizeVatInclude_SaleOrderProductMaster: 2,
  patternVatInclude_SaleOrderProductMaster: "",
  minSizeSoDiscountRate_SaleOrderProductMaster: 0,
  maxSizeSoDiscountRate_SaleOrderProductMaster: 26,
  patternSoDiscountRate_SaleOrderProductMaster: "",
  minSizeSoDiscountAmt_SaleOrderProductMaster: 0,
  maxSizeSoDiscountAmt_SaleOrderProductMaster: 26,
  patternSoDiscountAmt_SaleOrderProductMaster: "",
  minSizeExchangeRate_SaleOrderProductMaster: 0,
  maxSizeExchangeRate_SaleOrderProductMaster: 26,
  patternExchangeRate_SaleOrderProductMaster: "",
  minSizeExchageRateDate_SaleOrderProductMaster: 0,
  maxSizeExchageRateDate_SaleOrderProductMaster: 20,
  patternExchageRateDate_SaleOrderProductMaster: "",
  minSizeAStatus_SaleOrderProductMaster: 0,
  maxSizeAStatus_SaleOrderProductMaster: 2,
  patternAStatus_SaleOrderProductMaster: "",
  minSizeReviseNo_SaleOrderProductMaster: 0,
  maxSizeReviseNo_SaleOrderProductMaster: 10,
  patternReviseNo_SaleOrderProductMaster: "",
  minSizePrintStatus_SaleOrderProductMaster: 0,
  maxSizePrintStatus_SaleOrderProductMaster: 2,
  patternPrintStatus_SaleOrderProductMaster: "",
  minSizeJoStatus_SaleOrderProductMaster: 0,
  maxSizeJoStatus_SaleOrderProductMaster: 2,
  patternJoStatus_SaleOrderProductMaster: "",
  minSizeCustName_SaleOrderProductMaster: 0,
  maxSizeCustName_SaleOrderProductMaster: 255,
  patternCustName_SaleOrderProductMaster: "",
  minSizeCurrencyId_SaleOrderProductMaster: 0,
  maxSizeCurrencyId_SaleOrderProductMaster: 19,
  patternCurrencyId_SaleOrderProductMaster: "",
  minSizeDocumentGroupId_SaleOrderProductMaster: 0,
  maxSizeDocumentGroupId_SaleOrderProductMaster: 19,
  patternDocumentGroupId_SaleOrderProductMaster: "",
  minSizeCustomerMasterID_SaleOrderProductMaster: 0,
  maxSizeCustomerMasterID_SaleOrderProductMaster: 19,
  patternCustomerMasterID_SaleOrderProductMaster: "",
  minSizeSaleEmpMasterId_SaleOrderProductMaster: 0,
  maxSizeSaleEmpMasterId_SaleOrderProductMaster: 19,
  patternSaleEmpMasterId_SaleOrderProductMaster: "",
  minSizeDivisionId_SaleOrderProductMaster: 0,
  maxSizeDivisionId_SaleOrderProductMaster: 19,
  patternDivisionId_SaleOrderProductMaster: "",
  minSizeSectionId_SaleOrderProductMaster: 0,
  maxSizeSectionId_SaleOrderProductMaster: 19,
  patternSectionId_SaleOrderProductMaster: "",
  minSizeCreateByEmpId_SaleOrderProductMaster: 0,
  maxSizeCreateByEmpId_SaleOrderProductMaster: 19,
  patternCreateByEmpId_SaleOrderProductMaster: "",
  minSizeUpdateByEmpId_SaleOrderProductMaster: 0,
  maxSizeUpdateByEmpId_SaleOrderProductMaster: 19,
  patternUpdateByEmpId_SaleOrderProductMaster: "",
  minSizeCusDivision_SaleOrderProductMaster: 0,
  maxSizeCusDivision_SaleOrderProductMaster: 10,
  patternCusDivision_SaleOrderProductMaster: "",
  minSizeDivisionNo_SaleOrderProductMaster: 0,
  maxSizeDivisionNo_SaleOrderProductMaster: 20,
  patternDivisionNo_SaleOrderProductMaster: "",
  minSizeDivisionName_SaleOrderProductMaster: 0,
  maxSizeDivisionName_SaleOrderProductMaster: 1000,
  patternDivisionName_SaleOrderProductMaster: "",
  minSizeDivisionAddress_SaleOrderProductMaster: 0,
  maxSizeDivisionAddress_SaleOrderProductMaster: 255,
  patternDivisionAddress_SaleOrderProductMaster: "",
  minSizeDocumentDateClose_SaleOrderProductMaster: 0,
  maxSizeDocumentDateClose_SaleOrderProductMaster: 20,
  patternDocumentDateClose_SaleOrderProductMaster: "",
  minSizeUserCloseEmpId_SaleOrderProductMaster: 0,
  maxSizeUserCloseEmpId_SaleOrderProductMaster: 19,
  patternUserCloseEmpId_SaleOrderProductMaster: "",
  minSizeUserApprove_SaleOrderProductMaster: 0,
  maxSizeUserApprove_SaleOrderProductMaster: 20,
  patternUserApprove_SaleOrderProductMaster: "",
  minSizeDeliverId_SaleOrderProductMaster: 0,
  maxSizeDeliverId_SaleOrderProductMaster: 19,
  patternDeliverId_SaleOrderProductMaster: "",
  minSizeDriverId_SaleOrderProductMaster: 0,
  maxSizeDriverId_SaleOrderProductMaster: 19,
  patternDriverId_SaleOrderProductMaster: "",
  minSizeDeliverCode_SaleOrderProductMaster: 0,
  maxSizeDeliverCode_SaleOrderProductMaster: 20,
  patternDeliverCode_SaleOrderProductMaster: "[0-9a-zA-Z/-]+",
  minSizeDriverName_SaleOrderProductMaster: 0,
  maxSizeDriverName_SaleOrderProductMaster: 120,
  patternDriverName_SaleOrderProductMaster: "",
  minSizeDeliverStatus_SaleOrderProductMaster: 0,
  maxSizeDeliverStatus_SaleOrderProductMaster: 2,
  patternDeliverStatus_SaleOrderProductMaster: "",
  minSizeDeliverRemark_SaleOrderProductMaster: 0,
  maxSizeDeliverRemark_SaleOrderProductMaster: 200,
  patternDeliverRemark_SaleOrderProductMaster: "",
  minSizeAreaId_SaleOrderProductMaster: 0,
  maxSizeAreaId_SaleOrderProductMaster: 19,
  patternAreaId_SaleOrderProductMaster: "",
  minSizeSalePersonAreaId_SalePersonArea: 0,
  maxSizeSalePersonAreaId_SalePersonArea: 19,
  patternSalePersonAreaId_SalePersonArea: "",
  minSizeEmployeeMasterId_SalePersonArea: 0,
  maxSizeEmployeeMasterId_SalePersonArea: 19,
  patternEmployeeMasterId_SalePersonArea: "",
  minSizeSaleAreaId_SalePersonArea: 0,
  maxSizeSaleAreaId_SalePersonArea: 19,
  patternSaleAreaId_SalePersonArea: "",
  minSizeSectionId_Section: 0,
  maxSizeSectionId_Section: 19,
  patternSectionId_Section: "",
  minSizeCompanyCode_Section: 0,
  maxSizeCompanyCode_Section: 20,
  patternCompanyCode_Section: "[0-9a-zA-Z/-]+",
  minSizeDivisionId_Section: 0,
  maxSizeDivisionId_Section: 19,
  patternDivisionId_Section: "",
  minSizeServiceDetailId_ServDetailAccountCodePayment: 0,
  maxSizeServiceDetailId_ServDetailAccountCodePayment: 19,
  patternServiceDetailId_ServDetailAccountCodePayment: "",
  minSizeAccountCodeIdPayment_ServDetailAccountCodePayment: 0,
  maxSizeAccountCodeIdPayment_ServDetailAccountCodePayment: 19,
  patternAccountCodeIdPayment_ServDetailAccountCodePayment: "[0-9a-zA-Z/-]+",
  minSizeServiceDetailId_ServDetailServType: 0,
  maxSizeServiceDetailId_ServDetailServType: 19,
  patternServiceDetailId_ServDetailServType: "",
  minSizeServiceTypeId_ServDetailServType: 0,
  maxSizeServiceTypeId_ServDetailServType: 19,
  patternServiceTypeId_ServDetailServType: "",
  minSizeServiceBillingNoteDetailId_ServiceBillingNoteDetail: 0,
  maxSizeServiceBillingNoteDetailId_ServiceBillingNoteDetail: 19,
  patternServiceBillingNoteDetailId_ServiceBillingNoteDetail: "",
  minSizeServiceBillingNoteDetailNo_ServiceBillingNoteDetail: 0,
  maxSizeServiceBillingNoteDetailNo_ServiceBillingNoteDetail: 10,
  patternServiceBillingNoteDetailNo_ServiceBillingNoteDetail: "",
  minSizeServiceQty_ServiceBillingNoteDetail: 0,
  maxSizeServiceQty_ServiceBillingNoteDetail: 26,
  patternServiceQty_ServiceBillingNoteDetail: "",
  minSizeServiceReceiveCn_ServiceBillingNoteDetail: 0,
  maxSizeServiceReceiveCn_ServiceBillingNoteDetail: 26,
  patternServiceReceiveCn_ServiceBillingNoteDetail: "",
  minSizeServiceUnit_ServiceBillingNoteDetail: 0,
  maxSizeServiceUnit_ServiceBillingNoteDetail: 100,
  patternServiceUnit_ServiceBillingNoteDetail: "",
  minSizeServicePrice_ServiceBillingNoteDetail: 0,
  maxSizeServicePrice_ServiceBillingNoteDetail: 26,
  patternServicePrice_ServiceBillingNoteDetail: "",
  minSizeServiceDiscRate_ServiceBillingNoteDetail: 0,
  maxSizeServiceDiscRate_ServiceBillingNoteDetail: 26,
  patternServiceDiscRate_ServiceBillingNoteDetail: "",
  minSizeServiceDiscAmt_ServiceBillingNoteDetail: 0,
  maxSizeServiceDiscAmt_ServiceBillingNoteDetail: 26,
  patternServiceDiscAmt_ServiceBillingNoteDetail: "",
  minSizeServiceNetPrice_ServiceBillingNoteDetail: 0,
  maxSizeServiceNetPrice_ServiceBillingNoteDetail: 26,
  patternServiceNetPrice_ServiceBillingNoteDetail: "",
  minSizeServiceDiscRate0_ServiceBillingNoteDetail: 0,
  maxSizeServiceDiscRate0_ServiceBillingNoteDetail: 20,
  patternServiceDiscRate0_ServiceBillingNoteDetail: "",
  minSizeSoDocNo_ServiceBillingNoteDetail: 0,
  maxSizeSoDocNo_ServiceBillingNoteDetail: 20,
  patternSoDocNo_ServiceBillingNoteDetail: "",
  minSizeServiceVat_ServiceBillingNoteDetail: 0,
  maxSizeServiceVat_ServiceBillingNoteDetail: 2,
  patternServiceVat_ServiceBillingNoteDetail: "",
  minSizeQuDocNo_ServiceBillingNoteDetail: 0,
  maxSizeQuDocNo_ServiceBillingNoteDetail: 20,
  patternQuDocNo_ServiceBillingNoteDetail: "",
  minSizeSoDocIndex_ServiceBillingNoteDetail: 0,
  maxSizeSoDocIndex_ServiceBillingNoteDetail: 10,
  patternSoDocIndex_ServiceBillingNoteDetail: "",
  minSizeCompanyCode_ServiceBillingNoteDetail: 0,
  maxSizeCompanyCode_ServiceBillingNoteDetail: 20,
  patternCompanyCode_ServiceBillingNoteDetail: "[0-9a-zA-Z/-]+",
  minSizeServiceNewName_ServiceBillingNoteDetail: 0,
  maxSizeServiceNewName_ServiceBillingNoteDetail: 300,
  patternServiceNewName_ServiceBillingNoteDetail: "",
  minSizeServiceNewCode_ServiceBillingNoteDetail: 0,
  maxSizeServiceNewCode_ServiceBillingNoteDetail: 20,
  patternServiceNewCode_ServiceBillingNoteDetail: "[0-9a-zA-Z/-]+",
  minSizeServiceBillingItemDetail_ServiceBillingNoteDetail: 0,
  maxSizeServiceBillingItemDetail_ServiceBillingNoteDetail: 9999,
  patternServiceBillingItemDetail_ServiceBillingNoteDetail: "",
  minSizeServiceReceiveQty_ServiceBillingNoteDetail: 0,
  maxSizeServiceReceiveQty_ServiceBillingNoteDetail: 26,
  patternServiceReceiveQty_ServiceBillingNoteDetail: "",
  minSizeServiceMasterId_ServiceBillingNoteDetail: 0,
  maxSizeServiceMasterId_ServiceBillingNoteDetail: 19,
  patternServiceMasterId_ServiceBillingNoteDetail: "",
  minSizeServiceBillingNoteMasterId_ServiceBillingNoteDetail: 0,
  maxSizeServiceBillingNoteMasterId_ServiceBillingNoteDetail: 19,
  patternServiceBillingNoteMasterId_ServiceBillingNoteDetail: "",
  minSizeServiceReceiveRs_ServiceBillingNoteDetail: 0,
  maxSizeServiceReceiveRs_ServiceBillingNoteDetail: 26,
  patternServiceReceiveRs_ServiceBillingNoteDetail: "",
  minSizeReceivePriceBalance_ServiceBillingNoteDetail: 0,
  maxSizeReceivePriceBalance_ServiceBillingNoteDetail: 26,
  patternReceivePriceBalance_ServiceBillingNoteDetail: "",
  minSizePeriodNo_ServiceBillingNoteDetail: 0,
  maxSizePeriodNo_ServiceBillingNoteDetail: 10,
  patternPeriodNo_ServiceBillingNoteDetail: "",
  minSizeStartPeriodDate_ServiceBillingNoteDetail: 0,
  maxSizeStartPeriodDate_ServiceBillingNoteDetail: 20,
  patternStartPeriodDate_ServiceBillingNoteDetail: "",
  minSizeReturnPayDate_ServiceBillingNoteDetail: 0,
  maxSizeReturnPayDate_ServiceBillingNoteDetail: 20,
  patternReturnPayDate_ServiceBillingNoteDetail: "",
  minSizeStartNo_ServiceBillingNoteDetail: 0,
  maxSizeStartNo_ServiceBillingNoteDetail: 20,
  patternStartNo_ServiceBillingNoteDetail: "",
  minSizeEndNo_ServiceBillingNoteDetail: 0,
  maxSizeEndNo_ServiceBillingNoteDetail: 20,
  patternEndNo_ServiceBillingNoteDetail: "",
  minSizeTypeSe_ServiceBillingNoteDetail: 0,
  maxSizeTypeSe_ServiceBillingNoteDetail: 20,
  patternTypeSe_ServiceBillingNoteDetail: "",
  minSizeDeliverId_ServiceBillingNoteDetail: 0,
  maxSizeDeliverId_ServiceBillingNoteDetail: 19,
  patternDeliverId_ServiceBillingNoteDetail: "",
  minSizeDriverCode_ServiceBillingNoteDetail: 0,
  maxSizeDriverCode_ServiceBillingNoteDetail: 20,
  patternDriverCode_ServiceBillingNoteDetail: "[0-9a-zA-Z/-]+",
  minSizeDeliverCode_ServiceBillingNoteDetail: 0,
  maxSizeDeliverCode_ServiceBillingNoteDetail: 20,
  patternDeliverCode_ServiceBillingNoteDetail: "[0-9a-zA-Z/-]+",
  minSizeDriverName_ServiceBillingNoteDetail: 0,
  maxSizeDriverName_ServiceBillingNoteDetail: 120,
  patternDriverName_ServiceBillingNoteDetail: "",
  minSizeDeliverRemark_ServiceBillingNoteDetail: 0,
  maxSizeDeliverRemark_ServiceBillingNoteDetail: 200,
  patternDeliverRemark_ServiceBillingNoteDetail: "",
  minSizeCost_ServiceBillingNoteDetail: 0,
  maxSizeCost_ServiceBillingNoteDetail: 26,
  patternCost_ServiceBillingNoteDetail: "^[+-]?([0-9]+([.][0-9]{2,4}))$",
  minSizeQuDocNo1_ServiceBillingNoteDetail: 0,
  maxSizeQuDocNo1_ServiceBillingNoteDetail: 20,
  patternQuDocNo1_ServiceBillingNoteDetail: "",
  minSizeJobMasterId_ServiceBillingNoteDetail: 0,
  maxSizeJobMasterId_ServiceBillingNoteDetail: 19,
  patternJobMasterId_ServiceBillingNoteDetail: "",
  minSizeServiceBillingNoteMasterId_ServiceBillingNoteMaster: 0,
  maxSizeServiceBillingNoteMasterId_ServiceBillingNoteMaster: 19,
  patternServiceBillingNoteMasterId_ServiceBillingNoteMaster: "",
  minSizeServiceBillingDocNo_ServiceBillingNoteMaster: 0,
  maxSizeServiceBillingDocNo_ServiceBillingNoteMaster: 20,
  patternServiceBillingDocNo_ServiceBillingNoteMaster: "",
  minSizeServiceBillingDocDate_ServiceBillingNoteMaster: 0,
  maxSizeServiceBillingDocDate_ServiceBillingNoteMaster: 20,
  patternServiceBillingDocDate_ServiceBillingNoteMaster: "",
  minSizeServiceBillingCreditTerm_ServiceBillingNoteMaster: 0,
  maxSizeServiceBillingCreditTerm_ServiceBillingNoteMaster: 10,
  patternServiceBillingCreditTerm_ServiceBillingNoteMaster: "",
  minSizeServiceBillingTotal_ServiceBillingNoteMaster: 0,
  maxSizeServiceBillingTotal_ServiceBillingNoteMaster: 26,
  patternServiceBillingTotal_ServiceBillingNoteMaster: "",
  minSizeServiceBillingTotalNoVat_ServiceBillingNoteMaster: 0,
  maxSizeServiceBillingTotalNoVat_ServiceBillingNoteMaster: 26,
  patternServiceBillingTotalNoVat_ServiceBillingNoteMaster: "",
  minSizeServiceBillingDiscRate_ServiceBillingNoteMaster: 0,
  maxSizeServiceBillingDiscRate_ServiceBillingNoteMaster: 26,
  patternServiceBillingDiscRate_ServiceBillingNoteMaster: "",
  minSizeServiceBillingDiscAmt_ServiceBillingNoteMaster: 0,
  maxSizeServiceBillingDiscAmt_ServiceBillingNoteMaster: 26,
  patternServiceBillingDiscAmt_ServiceBillingNoteMaster: "",
  minSizeServiceBillDDuctrecivDpositNo_ServiceBillingNoteMaster: 0,
  maxSizeServiceBillDDuctrecivDpositNo_ServiceBillingNoteMaster: 500,
  patternServiceBillDDuctrecivDpositNo_ServiceBillingNoteMaster: "",
  minSizeServiceBillDDuctrecivDpositAmt_ServiceBillingNoteMaster: 0,
  maxSizeServiceBillDDuctrecivDpositAmt_ServiceBillingNoteMaster: 26,
  patternServiceBillDDuctrecivDpositAmt_ServiceBillingNoteMaster: "",
  minSizeServiceBillingVatRate_ServiceBillingNoteMaster: 0,
  maxSizeServiceBillingVatRate_ServiceBillingNoteMaster: 26,
  patternServiceBillingVatRate_ServiceBillingNoteMaster: "",
  minSizeServiceBillingVatAmt_ServiceBillingNoteMaster: 0,
  maxSizeServiceBillingVatAmt_ServiceBillingNoteMaster: 26,
  patternServiceBillingVatAmt_ServiceBillingNoteMaster: "",
  minSizeServiceBillingNetAmt_ServiceBillingNoteMaster: 0,
  maxSizeServiceBillingNetAmt_ServiceBillingNoteMaster: 26,
  patternServiceBillingNetAmt_ServiceBillingNoteMaster: "",
  minSizeReceiveAmtBank_ServiceBillingNoteMaster: 0,
  maxSizeReceiveAmtBank_ServiceBillingNoteMaster: 26,
  patternReceiveAmtBank_ServiceBillingNoteMaster: "",
  minSizeReceiveAmtCheque_ServiceBillingNoteMaster: 0,
  maxSizeReceiveAmtCheque_ServiceBillingNoteMaster: 26,
  patternReceiveAmtCheque_ServiceBillingNoteMaster: "",
  minSizeReceiveTypeBank_ServiceBillingNoteMaster: 0,
  maxSizeReceiveTypeBank_ServiceBillingNoteMaster: 2,
  patternReceiveTypeBank_ServiceBillingNoteMaster: "",
  minSizeReceiveTypeCheque_ServiceBillingNoteMaster: 0,
  maxSizeReceiveTypeCheque_ServiceBillingNoteMaster: 2,
  patternReceiveTypeCheque_ServiceBillingNoteMaster: "",
  minSizeBankRemarkCheque_ServiceBillingNoteMaster: 0,
  maxSizeBankRemarkCheque_ServiceBillingNoteMaster: 250,
  patternBankRemarkCheque_ServiceBillingNoteMaster: "",
  minSizeSbBookCodeCheque_ServiceBillingNoteMaster: 0,
  maxSizeSbBookCodeCheque_ServiceBillingNoteMaster: 50,
  patternSbBookCodeCheque_ServiceBillingNoteMaster: "[0-9a-zA-Z/-]+",
  minSizeBankDate_ServiceBillingNoteMaster: 0,
  maxSizeBankDate_ServiceBillingNoteMaster: 50,
  patternBankDate_ServiceBillingNoteMaster: "",
  minSizeCustomerBank_ServiceBillingNoteMaster: 0,
  maxSizeCustomerBank_ServiceBillingNoteMaster: 100,
  patternCustomerBank_ServiceBillingNoteMaster: "",
  minSizeDivisionBank_ServiceBillingNoteMaster: 0,
  maxSizeDivisionBank_ServiceBillingNoteMaster: 100,
  patternDivisionBank_ServiceBillingNoteMaster: "",
  minSizeServiceBillingRemark_ServiceBillingNoteMaster: 0,
  maxSizeServiceBillingRemark_ServiceBillingNoteMaster: 500,
  patternServiceBillingRemark_ServiceBillingNoteMaster: "",
  minSizeServiceBillingStatus_ServiceBillingNoteMaster: 0,
  maxSizeServiceBillingStatus_ServiceBillingNoteMaster: 2,
  patternServiceBillingStatus_ServiceBillingNoteMaster: "",
  minSizeServiceBillingLinkDoc_ServiceBillingNoteMaster: 0,
  maxSizeServiceBillingLinkDoc_ServiceBillingNoteMaster: 20,
  patternServiceBillingLinkDoc_ServiceBillingNoteMaster: "",
  minSizeCreateOn_ServiceBillingNoteMaster: 0,
  maxSizeCreateOn_ServiceBillingNoteMaster: 25,
  patternCreateOn_ServiceBillingNoteMaster: "",
  minSizeUpdateOn_ServiceBillingNoteMaster: 0,
  maxSizeUpdateOn_ServiceBillingNoteMaster: 25,
  patternUpdateOn_ServiceBillingNoteMaster: "",
  minSizeServiceBillingVoucherCode_ServiceBillingNoteMaster: 0,
  maxSizeServiceBillingVoucherCode_ServiceBillingNoteMaster: 20,
  patternServiceBillingVoucherCode_ServiceBillingNoteMaster: "[0-9a-zA-Z/-]+",
  minSizeVatInclude_ServiceBillingNoteMaster: 0,
  maxSizeVatInclude_ServiceBillingNoteMaster: 2,
  patternVatInclude_ServiceBillingNoteMaster: "",
  minSizeServiceBillingReceiptAmt_ServiceBillingNoteMaster: 0,
  maxSizeServiceBillingReceiptAmt_ServiceBillingNoteMaster: 26,
  patternServiceBillingReceiptAmt_ServiceBillingNoteMaster: "",
  minSizeCompanyCode_ServiceBillingNoteMaster: 0,
  maxSizeCompanyCode_ServiceBillingNoteMaster: 20,
  patternCompanyCode_ServiceBillingNoteMaster: "[0-9a-zA-Z/-]+",
  minSizeServiceBillingServiceNetAmt_ServiceBillingNoteMaster: 0,
  maxSizeServiceBillingServiceNetAmt_ServiceBillingNoteMaster: 26,
  patternServiceBillingServiceNetAmt_ServiceBillingNoteMaster: "",
  minSizeExchangeRate_ServiceBillingNoteMaster: 0,
  maxSizeExchangeRate_ServiceBillingNoteMaster: 26,
  patternExchangeRate_ServiceBillingNoteMaster: "",
  minSizeExchageRateDate_ServiceBillingNoteMaster: 0,
  maxSizeExchageRateDate_ServiceBillingNoteMaster: 20,
  patternExchageRateDate_ServiceBillingNoteMaster: "",
  minSizeAStatus_ServiceBillingNoteMaster: 0,
  maxSizeAStatus_ServiceBillingNoteMaster: 2,
  patternAStatus_ServiceBillingNoteMaster: "",
  minSizeSbType_ServiceBillingNoteMaster: 0,
  maxSizeSbType_ServiceBillingNoteMaster: 10,
  patternSbType_ServiceBillingNoteMaster: "",
  minSizeReceiveType_ServiceBillingNoteMaster: 0,
  maxSizeReceiveType_ServiceBillingNoteMaster: 10,
  patternReceiveType_ServiceBillingNoteMaster: "",
  minSizeReceiveAmt_ServiceBillingNoteMaster: 0,
  maxSizeReceiveAmt_ServiceBillingNoteMaster: 26,
  patternReceiveAmt_ServiceBillingNoteMaster: "",
  minSizeBankRemark_ServiceBillingNoteMaster: 0,
  maxSizeBankRemark_ServiceBillingNoteMaster: 150,
  patternBankRemark_ServiceBillingNoteMaster: "",
  minSizeChqNo_ServiceBillingNoteMaster: 0,
  maxSizeChqNo_ServiceBillingNoteMaster: 20,
  patternChqNo_ServiceBillingNoteMaster: "",
  minSizeChqDate_ServiceBillingNoteMaster: 0,
  maxSizeChqDate_ServiceBillingNoteMaster: 20,
  patternChqDate_ServiceBillingNoteMaster: "",
  minSizeReceiveChequeDocNo_ServiceBillingNoteMaster: 0,
  maxSizeReceiveChequeDocNo_ServiceBillingNoteMaster: 20,
  patternReceiveChequeDocNo_ServiceBillingNoteMaster: "",
  minSizeSbBookCode_ServiceBillingNoteMaster: 0,
  maxSizeSbBookCode_ServiceBillingNoteMaster: 50,
  patternSbBookCode_ServiceBillingNoteMaster: "[0-9a-zA-Z/-]+",
  minSizeBillAddress_ServiceBillingNoteMaster: 0,
  maxSizeBillAddress_ServiceBillingNoteMaster: 9999,
  patternBillAddress_ServiceBillingNoteMaster: "",
  minSizeBillTName_ServiceBillingNoteMaster: 0,
  maxSizeBillTName_ServiceBillingNoteMaster: 9999,
  patternBillTName_ServiceBillingNoteMaster: "",
  minSizeBillTelNo_ServiceBillingNoteMaster: 0,
  maxSizeBillTelNo_ServiceBillingNoteMaster: 20,
  patternBillTelNo_ServiceBillingNoteMaster: "",
  minSizeWithHodingTax_ServiceBillingNoteMaster: 0,
  maxSizeWithHodingTax_ServiceBillingNoteMaster: 26,
  patternWithHodingTax_ServiceBillingNoteMaster: "",
  minSizeDivisionNo_ServiceBillingNoteMaster: 0,
  maxSizeDivisionNo_ServiceBillingNoteMaster: 10,
  patternDivisionNo_ServiceBillingNoteMaster: "",
  minSizeDivisionName_ServiceBillingNoteMaster: 0,
  maxSizeDivisionName_ServiceBillingNoteMaster: 50,
  patternDivisionName_ServiceBillingNoteMaster: "",
  minSizeDivisionAddress_ServiceBillingNoteMaster: 0,
  maxSizeDivisionAddress_ServiceBillingNoteMaster: 255,
  patternDivisionAddress_ServiceBillingNoteMaster: "",
  minSizeTaxIdNo_ServiceBillingNoteMaster: 0,
  maxSizeTaxIdNo_ServiceBillingNoteMaster: 50,
  patternTaxIdNo_ServiceBillingNoteMaster: "",
  minSizePayChequeNo_ServiceBillingNoteMaster: 0,
  maxSizePayChequeNo_ServiceBillingNoteMaster: 50,
  patternPayChequeNo_ServiceBillingNoteMaster: "",
  minSizePayChequeAmt_ServiceBillingNoteMaster: 0,
  maxSizePayChequeAmt_ServiceBillingNoteMaster: 26,
  patternPayChequeAmt_ServiceBillingNoteMaster: "",
  minSizeCurrencyId_ServiceBillingNoteMaster: 0,
  maxSizeCurrencyId_ServiceBillingNoteMaster: 19,
  patternCurrencyId_ServiceBillingNoteMaster: "",
  minSizeDocumentGroupId_ServiceBillingNoteMaster: 0,
  maxSizeDocumentGroupId_ServiceBillingNoteMaster: 19,
  patternDocumentGroupId_ServiceBillingNoteMaster: "",
  minSizeCustomerMasterId_ServiceBillingNoteMaster: 0,
  maxSizeCustomerMasterId_ServiceBillingNoteMaster: 19,
  patternCustomerMasterId_ServiceBillingNoteMaster: "",
  minSizeEmpMasterId_ServiceBillingNoteMaster: 0,
  maxSizeEmpMasterId_ServiceBillingNoteMaster: 19,
  patternEmpMasterId_ServiceBillingNoteMaster: "",
  minSizeDivisionId_ServiceBillingNoteMaster: 0,
  maxSizeDivisionId_ServiceBillingNoteMaster: 19,
  patternDivisionId_ServiceBillingNoteMaster: "",
  minSizeSectionId_ServiceBillingNoteMaster: 0,
  maxSizeSectionId_ServiceBillingNoteMaster: 19,
  patternSectionId_ServiceBillingNoteMaster: "",
  minSizeCreateByEmpId_ServiceBillingNoteMaster: 0,
  maxSizeCreateByEmpId_ServiceBillingNoteMaster: 19,
  patternCreateByEmpId_ServiceBillingNoteMaster: "",
  minSizeUpdateByEmpId_ServiceBillingNoteMaster: 0,
  maxSizeUpdateByEmpId_ServiceBillingNoteMaster: 19,
  patternUpdateByEmpId_ServiceBillingNoteMaster: "",
  minSizeDepositNote_ServiceBillingNoteMaster: 0,
  maxSizeDepositNote_ServiceBillingNoteMaster: 2,
  patternDepositNote_ServiceBillingNoteMaster: "",
  minSizeBookCodeMoneyTransfer_ServiceBillingNoteMaster: 0,
  maxSizeBookCodeMoneyTransfer_ServiceBillingNoteMaster: 100,
  patternBookCodeMoneyTransfer_ServiceBillingNoteMaster: "[0-9a-zA-Z/-]+",
  minSizeBillTNameMoneyTransfer_ServiceBillingNoteMaster: 0,
  maxSizeBillTNameMoneyTransfer_ServiceBillingNoteMaster: 255,
  patternBillTNameMoneyTransfer_ServiceBillingNoteMaster: "",
  minSizeServiceBillingDate_ServiceBillingNoteMaster: 0,
  maxSizeServiceBillingDate_ServiceBillingNoteMaster: 20,
  patternServiceBillingDate_ServiceBillingNoteMaster: "",
  minSizeServiceBillingDDuctrecivCreditNo_ServiceBillingNoteMaster: 0,
  maxSizeServiceBillingDDuctrecivCreditNo_ServiceBillingNoteMaster: 20,
  patternServiceBillingDDuctrecivCreditNo_ServiceBillingNoteMaster: "",
  minSizeServiceBillingRecivCreditAmt_ServiceBillingNoteMaster: 0,
  maxSizeServiceBillingRecivCreditAmt_ServiceBillingNoteMaster: 26,
  patternServiceBillingRecivCreditAmt_ServiceBillingNoteMaster: "",
  minSizeMoneyTranferCode_ServiceBillingNoteMaster: 0,
  maxSizeMoneyTranferCode_ServiceBillingNoteMaster: 50,
  patternMoneyTranferCode_ServiceBillingNoteMaster: "[0-9a-zA-Z/-]+",
  minSizeMoneyTranferAccCode_ServiceBillingNoteMaster: 0,
  maxSizeMoneyTranferAccCode_ServiceBillingNoteMaster: 50,
  patternMoneyTranferAccCode_ServiceBillingNoteMaster: "[0-9a-zA-Z/-]+",
  minSizeAudited_ServiceBillingNoteMaster: 0,
  maxSizeAudited_ServiceBillingNoteMaster: 1,
  patternAudited_ServiceBillingNoteMaster: "",
  minSizeCancelDate_ServiceBillingNoteMaster: 0,
  maxSizeCancelDate_ServiceBillingNoteMaster: 25,
  patternCancelDate_ServiceBillingNoteMaster: "",
  minSizeCancelRemark_ServiceBillingNoteMaster: 0,
  maxSizeCancelRemark_ServiceBillingNoteMaster: 255,
  patternCancelRemark_ServiceBillingNoteMaster: "",
  minSizeServiceBillingDueDate_ServiceBillingNoteMaster: 0,
  maxSizeServiceBillingDueDate_ServiceBillingNoteMaster: 20,
  patternServiceBillingDueDate_ServiceBillingNoteMaster: "",
  minSizeMasterStatus_ServiceBillingNoteMaster: 0,
  maxSizeMasterStatus_ServiceBillingNoteMaster: 1,
  patternMasterStatus_ServiceBillingNoteMaster: "",
  minSizeMasterDescription_ServiceBillingNoteMaster: 0,
  maxSizeMasterDescription_ServiceBillingNoteMaster: 255,
  patternMasterDescription_ServiceBillingNoteMaster: "",
  minSizeInsteadDocument_ServiceBillingNoteMaster: 0,
  maxSizeInsteadDocument_ServiceBillingNoteMaster: 2,
  patternInsteadDocument_ServiceBillingNoteMaster: "",
  minSizeReplaceDocumentNo_ServiceBillingNoteMaster: 0,
  maxSizeReplaceDocumentNo_ServiceBillingNoteMaster: 50,
  patternReplaceDocumentNo_ServiceBillingNoteMaster: "",
  minSizeUpdateReferByPrice_ServiceBillingNoteMaster: 0,
  maxSizeUpdateReferByPrice_ServiceBillingNoteMaster: 1,
  patternUpdateReferByPrice_ServiceBillingNoteMaster: "",
  minSizePrintStatus_ServiceBillingNoteMaster: 0,
  maxSizePrintStatus_ServiceBillingNoteMaster: 2,
  patternPrintStatus_ServiceBillingNoteMaster: "",
  minSizeAcountRefuteAmt_ServiceBillingNoteMaster: 0,
  maxSizeAcountRefuteAmt_ServiceBillingNoteMaster: 26,
  patternAcountRefuteAmt_ServiceBillingNoteMaster: "",
  minSizeDeductReceiveDepositAmtRct_ServiceBillingNoteMaster: 0,
  maxSizeDeductReceiveDepositAmtRct_ServiceBillingNoteMaster: 26,
  patternDeductReceiveDepositAmtRct_ServiceBillingNoteMaster: "",
  minSizeCharge_ServiceBillingNoteMaster: 0,
  maxSizeCharge_ServiceBillingNoteMaster: 26,
  patternCharge_ServiceBillingNoteMaster: "",
  minSizeStartNo_ServiceBillingNoteMaster: 0,
  maxSizeStartNo_ServiceBillingNoteMaster: 20,
  patternStartNo_ServiceBillingNoteMaster: "",
  minSizeEndNo_ServiceBillingNoteMaster: 0,
  maxSizeEndNo_ServiceBillingNoteMaster: 20,
  patternEndNo_ServiceBillingNoteMaster: "",
  minSizeTypeSe_ServiceBillingNoteMaster: 0,
  maxSizeTypeSe_ServiceBillingNoteMaster: 20,
  patternTypeSe_ServiceBillingNoteMaster: "",
  minSizeBillingDate_ServiceBillingNoteMaster: 0,
  maxSizeBillingDate_ServiceBillingNoteMaster: 20,
  patternBillingDate_ServiceBillingNoteMaster: "",
  minSizeRemark1_ServiceBillingNoteMaster: 0,
  maxSizeRemark1_ServiceBillingNoteMaster: 255,
  patternRemark1_ServiceBillingNoteMaster: "",
  minSizeCustName_ServiceBillingNoteMaster: 0,
  maxSizeCustName_ServiceBillingNoteMaster: 150,
  patternCustName_ServiceBillingNoteMaster: "",
  minSizeCusDivision_ServiceBillingNoteMaster: 0,
  maxSizeCusDivision_ServiceBillingNoteMaster: 19,
  patternCusDivision_ServiceBillingNoteMaster: "",
  minSizeServiceDetailId_ServiceDetail: 0,
  maxSizeServiceDetailId_ServiceDetail: 19,
  patternServiceDetailId_ServiceDetail: "",
  minSizeServiceProcess_ServiceDetail: 0,
  maxSizeServiceProcess_ServiceDetail: 255,
  patternServiceProcess_ServiceDetail: "",
  minSizeServiceProperty_ServiceDetail: 0,
  maxSizeServiceProperty_ServiceDetail: 255,
  patternServiceProperty_ServiceDetail: "",
  minSizeCompanyCode_ServiceDetail: 0,
  maxSizeCompanyCode_ServiceDetail: 20,
  patternCompanyCode_ServiceDetail: "[0-9a-zA-Z/-]+",
  minSizeAccountCodeIdIncome_ServiceDetail: 0,
  maxSizeAccountCodeIdIncome_ServiceDetail: 19,
  patternAccountCodeIdIncome_ServiceDetail: "[0-9a-zA-Z/-]+",
  minSizeServiceGroupId_ServiceGroup: 0,
  maxSizeServiceGroupId_ServiceGroup: 19,
  patternServiceGroupId_ServiceGroup: "",
  minSizeCompanyCode_ServiceGroup: 0,
  maxSizeCompanyCode_ServiceGroup: 20,
  patternCompanyCode_ServiceGroup: "[0-9a-zA-Z/-]+",
  minSizeServiceMasterId_ServiceMaster: 0,
  maxSizeServiceMasterId_ServiceMaster: 19,
  patternServiceMasterId_ServiceMaster: "",
  minSizeServiceCode_ServiceMaster: 0,
  maxSizeServiceCode_ServiceMaster: 20,
  patternServiceCode_ServiceMaster: "[0-9a-zA-Z/-]+",
  minSizeServiceName1_ServiceMaster: 0,
  maxSizeServiceName1_ServiceMaster: 255,
  patternServiceName1_ServiceMaster: "",
  minSizeServiceName2_ServiceMaster: 0,
  maxSizeServiceName2_ServiceMaster: 255,
  patternServiceName2_ServiceMaster: "",
  minSizeServiceName3_ServiceMaster: 0,
  maxSizeServiceName3_ServiceMaster: 255,
  patternServiceName3_ServiceMaster: "",
  minSizeServiceName4_ServiceMaster: 0,
  maxSizeServiceName4_ServiceMaster: 255,
  patternServiceName4_ServiceMaster: "",
  minSizeServiceUnit_ServiceMaster: 0,
  maxSizeServiceUnit_ServiceMaster: 255,
  patternServiceUnit_ServiceMaster: "",
  minSizeServiceDetail_ServiceMaster: 0,
  maxSizeServiceDetail_ServiceMaster: 255,
  patternServiceDetail_ServiceMaster: "",
  minSizeServiceStatus_ServiceMaster: 0,
  maxSizeServiceStatus_ServiceMaster: 1,
  patternServiceStatus_ServiceMaster: "",
  minSizeServiceECommerce_ServiceMaster: 0,
  maxSizeServiceECommerce_ServiceMaster: 1,
  patternServiceECommerce_ServiceMaster: "",
  minSizeCompanyCode_ServiceMaster: 0,
  maxSizeCompanyCode_ServiceMaster: 20,
  patternCompanyCode_ServiceMaster: "[0-9a-zA-Z/-]+",
  minSizeServiceVat_ServiceMaster: 0,
  maxSizeServiceVat_ServiceMaster: 20,
  patternServiceVat_ServiceMaster: "",
  minSizeServiceGroupId_ServiceMaster: 0,
  maxSizeServiceGroupId_ServiceMaster: 19,
  patternServiceGroupId_ServiceMaster: "",
  minSizeWhtDataId_ServiceMaster: 0,
  maxSizeWhtDataId_ServiceMaster: 19,
  patternWhtDataId_ServiceMaster: "",
  minSizeCreateOn_ServiceMaster: 0,
  maxSizeCreateOn_ServiceMaster: 20,
  patternCreateOn_ServiceMaster: "",
  minSizeUpdateOn_ServiceMaster: 0,
  maxSizeUpdateOn_ServiceMaster: 20,
  patternUpdateOn_ServiceMaster: "",
  minSizeCreateById_ServiceMaster: 0,
  maxSizeCreateById_ServiceMaster: 19,
  patternCreateById_ServiceMaster: "",
  minSizeUpdateById_ServiceMaster: 0,
  maxSizeUpdateById_ServiceMaster: 19,
  patternUpdateById_ServiceMaster: "",
  minSizeServiceEUnit_ServiceMaster: 0,
  maxSizeServiceEUnit_ServiceMaster: 255,
  patternServiceEUnit_ServiceMaster: "",
  minSizeServicePriceLevelId_ServicePriceLevel: 0,
  maxSizeServicePriceLevelId_ServicePriceLevel: 19,
  patternServicePriceLevelId_ServicePriceLevel: "",
  minSizeServicePriceLevel1_ServicePriceLevel: 0,
  maxSizeServicePriceLevel1_ServicePriceLevel: 26,
  patternServicePriceLevel1_ServicePriceLevel: "[0-9.]+",
  minSizeServiceDiscountAmt1_ServicePriceLevel: 0,
  maxSizeServiceDiscountAmt1_ServicePriceLevel: 26,
  patternServiceDiscountAmt1_ServicePriceLevel: "[0-9.]+",
  minSizeServiceDiscountRate1_ServicePriceLevel: 0,
  maxSizeServiceDiscountRate1_ServicePriceLevel: 26,
  patternServiceDiscountRate1_ServicePriceLevel: "[0-9.]+",
  minSizeServicePriceLevel2_ServicePriceLevel: 0,
  maxSizeServicePriceLevel2_ServicePriceLevel: 26,
  patternServicePriceLevel2_ServicePriceLevel: "[0-9.]+",
  minSizeServiceDiscountAmt2_ServicePriceLevel: 0,
  maxSizeServiceDiscountAmt2_ServicePriceLevel: 26,
  patternServiceDiscountAmt2_ServicePriceLevel: "[0-9.]+",
  minSizeServiceDiscountRate2_ServicePriceLevel: 0,
  maxSizeServiceDiscountRate2_ServicePriceLevel: 26,
  patternServiceDiscountRate2_ServicePriceLevel: "[0-9.]+",
  minSizeServicePriceLevel3_ServicePriceLevel: 0,
  maxSizeServicePriceLevel3_ServicePriceLevel: 26,
  patternServicePriceLevel3_ServicePriceLevel: "[0-9.]+",
  minSizeServiceDiscountAmt3_ServicePriceLevel: 0,
  maxSizeServiceDiscountAmt3_ServicePriceLevel: 26,
  patternServiceDiscountAmt3_ServicePriceLevel: "[0-9.]+",
  minSizeServiceDiscountRate3_ServicePriceLevel: 0,
  maxSizeServiceDiscountRate3_ServicePriceLevel: 26,
  patternServiceDiscountRate3_ServicePriceLevel: "[0-9.]+",
  minSizeServicePriceLevel4_ServicePriceLevel: 0,
  maxSizeServicePriceLevel4_ServicePriceLevel: 26,
  patternServicePriceLevel4_ServicePriceLevel: "[0-9.]+",
  minSizeServiceDiscountAmt4_ServicePriceLevel: 0,
  maxSizeServiceDiscountAmt4_ServicePriceLevel: 26,
  patternServiceDiscountAmt4_ServicePriceLevel: "[0-9.]+",
  minSizeServiceDiscountRate4_ServicePriceLevel: 0,
  maxSizeServiceDiscountRate4_ServicePriceLevel: 26,
  patternServiceDiscountRate4_ServicePriceLevel: "[0-9.]+",
  minSizeCompanyCode_ServicePriceLevel: 0,
  maxSizeCompanyCode_ServicePriceLevel: 20,
  patternCompanyCode_ServicePriceLevel: "[0-9a-zA-Z/-]+",
  minSizeServiceTypeId_ServiceType: 0,
  maxSizeServiceTypeId_ServiceType: 19,
  patternServiceTypeId_ServiceType: "",
  minSizeCompanyCode_ServiceType: 0,
  maxSizeCompanyCode_ServiceType: 20,
  patternCompanyCode_ServiceType: "[0-9a-zA-Z/-]+",
  minSizeBoqGroupId_BoqGroup: 0,
  maxSizeBoqGroupId_BoqGroup: 19,
  patternBoqGroupId_BoqGroup: "",
  minSizeBoqCode_BoqGroup: 0,
  maxSizeBoqCode_BoqGroup: 20,
  patternBoqCode_BoqGroup: "[0-9a-zA-Z/-]+",
  minSizeBoqGroupName_BoqGroup: 0,
  maxSizeBoqGroupName_BoqGroup: 400,
  patternBoqGroupName_BoqGroup: "",
  minSizeRemark_BoqGroup: 0,
  maxSizeRemark_BoqGroup: 200,
  patternRemark_BoqGroup: "",
  minSizeCompanyCode_BoqGroup: 0,
  maxSizeCompanyCode_BoqGroup: 20,
  patternCompanyCode_BoqGroup: "[0-9a-zA-Z/-]+",
  minSizeBoqGroupEName_BoqGroup: 0,
  maxSizeBoqGroupEName_BoqGroup: 400,
  patternBoqGroupEName_BoqGroup: "",
  minSizeBoqSubGroupId_BoqSubGroup: 0,
  maxSizeBoqSubGroupId_BoqSubGroup: 19,
  patternBoqSubGroupId_BoqSubGroup: "",
  minSizeBoqSubCode_BoqSubGroup: 0,
  maxSizeBoqSubCode_BoqSubGroup: 20,
  patternBoqSubCode_BoqSubGroup: "[0-9a-zA-Z/-]+",
  minSizeBoqSubGroupName_BoqSubGroup: 0,
  maxSizeBoqSubGroupName_BoqSubGroup: 400,
  patternBoqSubGroupName_BoqSubGroup: "",
  minSizeRemark_BoqSubGroup: 0,
  maxSizeRemark_BoqSubGroup: 200,
  patternRemark_BoqSubGroup: "",
  minSizeCompanyCode_BoqSubGroup: 0,
  maxSizeCompanyCode_BoqSubGroup: 20,
  patternCompanyCode_BoqSubGroup: "[0-9a-zA-Z/-]+",
  minSizeBoqGroupId_BoqSubGroup: 0,
  maxSizeBoqGroupId_BoqSubGroup: 19,
  patternBoqGroupId_BoqSubGroup: "",
  minSizeBoqSubGroupEName_BoqSubGroup: 0,
  maxSizeBoqSubGroupEName_BoqSubGroup: 400,
  patternBoqSubGroupEName_BoqSubGroup: "",
  minSizeBoqMasterId_BoqMaster: 0,
  maxSizeBoqMasterId_BoqMaster: 19,
  patternBoqMasterId_BoqMaster: "",
  minSizeBoqCode_BoqMaster: 0,
  maxSizeBoqCode_BoqMaster: 20,
  patternBoqCode_BoqMaster: "[0-9a-zA-Z/-]+",
  minSizeBoqName_BoqMaster: 0,
  maxSizeBoqName_BoqMaster: 500,
  patternBoqName_BoqMaster: "",
  minSizeBoqName2_BoqMaster: 0,
  maxSizeBoqName2_BoqMaster: 500,
  patternBoqName2_BoqMaster: "",
  minSizeBoqDate_BoqMaster: 0,
  maxSizeBoqDate_BoqMaster: 20,
  patternBoqDate_BoqMaster: "",
  minSizeEstDate_BoqMaster: 0,
  maxSizeEstDate_BoqMaster: 20,
  patternEstDate_BoqMaster: "",
  minSizeEstDueDate_BoqMaster: 0,
  maxSizeEstDueDate_BoqMaster: 20,
  patternEstDueDate_BoqMaster: "",
  minSizeDocumentMasterId_BoqMaster: 0,
  maxSizeDocumentMasterId_BoqMaster: 19,
  patternDocumentMasterId_BoqMaster: "",
  minSizeCompanyCode_BoqMaster: 0,
  maxSizeCompanyCode_BoqMaster: 20,
  patternCompanyCode_BoqMaster: "[0-9a-zA-Z/-]+",
  minSizeBoqGroupId_BoqMaster: 0,
  maxSizeBoqGroupId_BoqMaster: 19,
  patternBoqGroupId_BoqMaster: "",
  minSizeVersion_BoqMaster: 0,
  maxSizeVersion_BoqMaster: 10,
  patternVersion_BoqMaster: "",
  minSizeBoqStatus_BoqMaster: 0,
  maxSizeBoqStatus_BoqMaster: 10,
  patternBoqStatus_BoqMaster: "",
  minSizeReferenceBoq_BoqMaster: 0,
  maxSizeReferenceBoq_BoqMaster: 20,
  patternReferenceBoq_BoqMaster: "",
  minSizeRemark1_BoqMaster: 0,
  maxSizeRemark1_BoqMaster: 50,
  patternRemark1_BoqMaster: "",
  minSizeRemark2_BoqMaster: 0,
  maxSizeRemark2_BoqMaster: 50,
  patternRemark2_BoqMaster: "",
  minSizeRemark3_BoqMaster: 0,
  maxSizeRemark3_BoqMaster: 50,
  patternRemark3_BoqMaster: "",
  minSizeRemark4_BoqMaster: 0,
  maxSizeRemark4_BoqMaster: 50,
  patternRemark4_BoqMaster: "",
  minSizeRemark5_BoqMaster: 0,
  maxSizeRemark5_BoqMaster: 50,
  patternRemark5_BoqMaster: "",
  minSizeRemark6_BoqMaster: 0,
  maxSizeRemark6_BoqMaster: 50,
  patternRemark6_BoqMaster: "",
  minSizeRemark7_BoqMaster: 0,
  maxSizeRemark7_BoqMaster: 50,
  patternRemark7_BoqMaster: "",
  minSizeRemark8_BoqMaster: 0,
  maxSizeRemark8_BoqMaster: 50,
  patternRemark8_BoqMaster: "",
  minSizeRemark9_BoqMaster: 0,
  maxSizeRemark9_BoqMaster: 50,
  patternRemark9_BoqMaster: "",
  minSizeAstatus_BoqMaster: 0,
  maxSizeAstatus_BoqMaster: 2,
  patternAstatus_BoqMaster: "",
  minSizeRemark10_BoqMaster: 0,
  maxSizeRemark10_BoqMaster: 50,
  patternRemark10_BoqMaster: "",
  minSizeRemark11_BoqMaster: 0,
  maxSizeRemark11_BoqMaster: 50,
  patternRemark11_BoqMaster: "",
  minSizeTotalMat_BoqMaster: 0,
  maxSizeTotalMat_BoqMaster: 26,
  patternTotalMat_BoqMaster: "",
  minSizeTotalLabor_BoqMaster: 0,
  maxSizeTotalLabor_BoqMaster: 26,
  patternTotalLabor_BoqMaster: "",
  minSizeServiceMasterId_ServMasterServDetail: 0,
  maxSizeServiceMasterId_ServMasterServDetail: 19,
  patternServiceMasterId_ServMasterServDetail: "",
  minSizeServiceDetailId_ServMasterServDetail: 0,
  maxSizeServiceDetailId_ServMasterServDetail: 19,
  patternServiceDetailId_ServMasterServDetail: "",
  minSizeServiceMasterId_ServMasterServPriceLv: 0,
  maxSizeServiceMasterId_ServMasterServPriceLv: 19,
  patternServiceMasterId_ServMasterServPriceLv: "",
  minSizeServicePriceLevelId_ServMasterServPriceLv: 0,
  maxSizeServicePriceLevelId_ServMasterServPriceLv: 19,
  patternServicePriceLevelId_ServMasterServPriceLv: "",
  minSizeAdvId_SetupAdvancePM: 0,
  maxSizeAdvId_SetupAdvancePM: 19,
  patternAdvId_SetupAdvancePM: "",
  minSizeAdvCode_SetupAdvancePM: 0,
  maxSizeAdvCode_SetupAdvancePM: 20,
  patternAdvCode_SetupAdvancePM: "[0-9a-zA-Z/-]+",
  minSizeAdvName_SetupAdvancePM: 0,
  maxSizeAdvName_SetupAdvancePM: 200,
  patternAdvName_SetupAdvancePM: "",
  minSizePcaMount_SetupAdvancePM: 0,
  maxSizePcaMount_SetupAdvancePM: 26,
  patternPcaMount_SetupAdvancePM: "[0-9.]+",
  minSizeRemark_SetupAdvancePM: 0,
  maxSizeRemark_SetupAdvancePM: 200,
  patternRemark_SetupAdvancePM: "",
  minSizeCompanyCode_SetupAdvancePM: 0,
  maxSizeCompanyCode_SetupAdvancePM: 20,
  patternCompanyCode_SetupAdvancePM: "[0-9a-zA-Z/-]+",
  minSizeJobMasterId_SetupAdvancePM: 0,
  maxSizeJobMasterId_SetupAdvancePM: 19,
  patternJobMasterId_SetupAdvancePM: "",
  minSizeDivisionId_SetupAdvancePM: 0,
  maxSizeDivisionId_SetupAdvancePM: 19,
  patternDivisionId_SetupAdvancePM: "",
  minSizeSectionId_SetupAdvancePM: 0,
  maxSizeSectionId_SetupAdvancePM: 19,
  patternSectionId_SetupAdvancePM: "",
  minSizeAccountCodeId_SetupAdvancePM: 0,
  maxSizeAccountCodeId_SetupAdvancePM: 19,
  patternAccountCodeId_SetupAdvancePM: "[0-9a-zA-Z/-]+",
  minSizeSupplierContactId_SupplierContact: 0,
  maxSizeSupplierContactId_SupplierContact: 19,
  patternSupplierContactId_SupplierContact: "",
  minSizeSuppContPer_SupplierContact: 0,
  maxSizeSuppContPer_SupplierContact: 255,
  patternSuppContPer_SupplierContact: "",
  minSizeSuppContTel_SupplierContact: 0,
  maxSizeSuppContTel_SupplierContact: 50,
  patternSuppContTel_SupplierContact: "",
  minSizeSuppContPosition_SupplierContact: 0,
  maxSizeSuppContPosition_SupplierContact: 150,
  patternSuppContPosition_SupplierContact: "",
  minSizeSuppContDivision_SupplierContact: 0,
  maxSizeSuppContDivision_SupplierContact: 150,
  patternSuppContDivision_SupplierContact: "",
  minSizeSuppContAddress_SupplierContact: 0,
  maxSizeSuppContAddress_SupplierContact: 250,
  patternSuppContAddress_SupplierContact: "",
  minSizeSuppContDefault_SupplierContact: 0,
  maxSizeSuppContDefault_SupplierContact: 250,
  patternSuppContDefault_SupplierContact: "",
  minSizeSuppContEmail_SupplierContact: 0,
  maxSizeSuppContEmail_SupplierContact: 150,
  patternSuppContEmail_SupplierContact: "",
  minSizeCompanyCode_SupplierContact: 0,
  maxSizeCompanyCode_SupplierContact: 20,
  patternCompanyCode_SupplierContact: "[0-9a-zA-Z/-]+",
  minSizeSupplierCreditId_SupplierCredit: 0,
  maxSizeSupplierCreditId_SupplierCredit: 19,
  patternSupplierCreditId_SupplierCredit: "",
  minSizeSuppCreditTerm_SupplierCredit: 0,
  maxSizeSuppCreditTerm_SupplierCredit: 26,
  patternSuppCreditTerm_SupplierCredit: "",
  minSizeSuppCreditDay_SupplierCredit: 0,
  maxSizeSuppCreditDay_SupplierCredit: 10,
  patternSuppCreditDay_SupplierCredit: "",
  minSizeSuppBillCondt_SupplierCredit: 0,
  maxSizeSuppBillCondt_SupplierCredit: 20,
  patternSuppBillCondt_SupplierCredit: "",
  minSizeSuppPayCondt_SupplierCredit: 0,
  maxSizeSuppPayCondt_SupplierCredit: 20,
  patternSuppPayCondt_SupplierCredit: "",
  minSizeSuppNameInVat_SupplierCredit: 0,
  maxSizeSuppNameInVat_SupplierCredit: 255,
  patternSuppNameInVat_SupplierCredit: "",
  minSizeSuppBillAddress_SupplierCredit: 0,
  maxSizeSuppBillAddress_SupplierCredit: 255,
  patternSuppBillAddress_SupplierCredit: "",
  minSizeSuppReferTaxPGD_SupplierCredit: 0,
  maxSizeSuppReferTaxPGD_SupplierCredit: 20,
  patternSuppReferTaxPGD_SupplierCredit: "",
  minSizeSuppCreditBalance_SupplierCredit: 0,
  maxSizeSuppCreditBalance_SupplierCredit: 26,
  patternSuppCreditBalance_SupplierCredit: "",
  minSizeCompanyCode_SupplierCredit: 0,
  maxSizeCompanyCode_SupplierCredit: 20,
  patternCompanyCode_SupplierCredit: "[0-9a-zA-Z/-]+",
  minSizeSupplierGroupId_SupplierGroup: 0,
  maxSizeSupplierGroupId_SupplierGroup: 19,
  patternSupplierGroupId_SupplierGroup: "",
  minSizeCompanyCode_SupplierGroup: 0,
  maxSizeCompanyCode_SupplierGroup: 20,
  patternCompanyCode_SupplierGroup: "[0-9a-zA-Z/-]+",
  minSizeAccountCodeId_SupplierGroup: 0,
  maxSizeAccountCodeId_SupplierGroup: 19,
  patternAccountCodeId_SupplierGroup: "[0-9a-zA-Z/-]+",
  minSizeSupplierMasterId_SupplierMasterAccountCode: 0,
  maxSizeSupplierMasterId_SupplierMasterAccountCode: 19,
  patternSupplierMasterId_SupplierMasterAccountCode: "",
  minSizeAccountCodeId_SupplierMasterAccountCode: 0,
  maxSizeAccountCodeId_SupplierMasterAccountCode: 19,
  patternAccountCodeId_SupplierMasterAccountCode: "[0-9a-zA-Z/-]+",
  minSizeSupplierMasterId_SupplierMasterAddress: 0,
  maxSizeSupplierMasterId_SupplierMasterAddress: 19,
  patternSupplierMasterId_SupplierMasterAddress: "",
  minSizeAddressId_SupplierMasterAddress: 0,
  maxSizeAddressId_SupplierMasterAddress: 19,
  patternAddressId_SupplierMasterAddress: "",
  minSizeSupplierMasterId_SupplierMasterEmployeeMaster: 0,
  maxSizeSupplierMasterId_SupplierMasterEmployeeMaster: 19,
  patternSupplierMasterId_SupplierMasterEmployeeMaster: "",
  minSizeEmployeeMasterId_SupplierMasterEmployeeMaster: 0,
  maxSizeEmployeeMasterId_SupplierMasterEmployeeMaster: 19,
  patternEmployeeMasterId_SupplierMasterEmployeeMaster: "",
  minSizeSupplierMasterId_SupplierMaster: 0,
  maxSizeSupplierMasterId_SupplierMaster: 19,
  patternSupplierMasterId_SupplierMaster: "",
  minSizeSuppCode_SupplierMaster: 0,
  maxSizeSuppCode_SupplierMaster: 50,
  patternSuppCode_SupplierMaster: "[0-9a-zA-Z/-]+",
  minSizeSuppTName_SupplierMaster: 0,
  maxSizeSuppTName_SupplierMaster: 255,
  patternSuppTName_SupplierMaster: "",
  minSizeSuppEName_SupplierMaster: 0,
  maxSizeSuppEName_SupplierMaster: 255,
  patternSuppEName_SupplierMaster: "",
  minSizeSuppIdNo_SupplierMaster: 0,
  maxSizeSuppIdNo_SupplierMaster: 255,
  patternSuppIdNo_SupplierMaster: "",
  minSizeSuppDate_SupplierMaster: 0,
  maxSizeSuppDate_SupplierMaster: 255,
  patternSuppDate_SupplierMaster: "",
  minSizeSuppType_SupplierMaster: 0,
  maxSizeSuppType_SupplierMaster: 2,
  patternSuppType_SupplierMaster: "",
  minSizeSuppCurrentBalance_SupplierMaster: 0,
  maxSizeSuppCurrentBalance_SupplierMaster: 26,
  patternSuppCurrentBalance_SupplierMaster: "",
  minSizeSuppStatus_SupplierMaster: 0,
  maxSizeSuppStatus_SupplierMaster: 1,
  patternSuppStatus_SupplierMaster: "",
  minSizeSuppAllowOver_SupplierMaster: 0,
  maxSizeSuppAllowOver_SupplierMaster: 1,
  patternSuppAllowOver_SupplierMaster: "",
  minSizeCompanyCode_SupplierMaster: 0,
  maxSizeCompanyCode_SupplierMaster: 20,
  patternCompanyCode_SupplierMaster: "[0-9a-zA-Z/-]+",
  minSizeDefaultC_SupplierMaster: 0,
  maxSizeDefaultC_SupplierMaster: 2,
  patternDefaultC_SupplierMaster: "",
  minSizeSipaType_SupplierMaster: 0,
  maxSizeSipaType_SupplierMaster: 4,
  patternSipaType_SupplierMaster: "",
  minSizeStatusSendingPos_SupplierMaster: 0,
  maxSizeStatusSendingPos_SupplierMaster: 1,
  patternStatusSendingPos_SupplierMaster: "",
  minSizeVer_SupplierMaster: 0,
  maxSizeVer_SupplierMaster: 10,
  patternVer_SupplierMaster: "",
  minSizeNid_SupplierMaster: 0,
  maxSizeNid_SupplierMaster: 13,
  patternNid_SupplierMaster: "",
  minSizeBranchNumber_SupplierMaster: 0,
  maxSizeBranchNumber_SupplierMaster: 255,
  patternBranchNumber_SupplierMaster: "",
  minSizeBranchTitle_SupplierMaster: 0,
  maxSizeBranchTitle_SupplierMaster: 255,
  patternBranchTitle_SupplierMaster: "",
  minSizeBranchName_SupplierMaster: 0,
  maxSizeBranchName_SupplierMaster: 255,
  patternBranchName_SupplierMaster: "",
  minSizeBuildingName_SupplierMaster: 0,
  maxSizeBuildingName_SupplierMaster: 255,
  patternBuildingName_SupplierMaster: "",
  minSizeRoomNumber_SupplierMaster: 0,
  maxSizeRoomNumber_SupplierMaster: 255,
  patternRoomNumber_SupplierMaster: "",
  minSizeFloorNumber_SupplierMaster: 0,
  maxSizeFloorNumber_SupplierMaster: 255,
  patternFloorNumber_SupplierMaster: "",
  minSizeVillageName_SupplierMaster: 0,
  maxSizeVillageName_SupplierMaster: 255,
  patternVillageName_SupplierMaster: "",
  minSizeHouseNumber_SupplierMaster: 0,
  maxSizeHouseNumber_SupplierMaster: 255,
  patternHouseNumber_SupplierMaster: "",
  minSizeMooNumber_SupplierMaster: 0,
  maxSizeMooNumber_SupplierMaster: 255,
  patternMooNumber_SupplierMaster: "",
  minSizeSoiName_SupplierMaster: 0,
  maxSizeSoiName_SupplierMaster: 255,
  patternSoiName_SupplierMaster: "",
  minSizeStreetName_SupplierMaster: 0,
  maxSizeStreetName_SupplierMaster: 255,
  patternStreetName_SupplierMaster: "",
  minSizeThumbolName_SupplierMaster: 0,
  maxSizeThumbolName_SupplierMaster: 255,
  patternThumbolName_SupplierMaster: "",
  minSizeAmphurName_SupplierMaster: 0,
  maxSizeAmphurName_SupplierMaster: 255,
  patternAmphurName_SupplierMaster: "",
  minSizeProvinceName_SupplierMaster: 0,
  maxSizeProvinceName_SupplierMaster: 255,
  patternProvinceName_SupplierMaster: "",
  minSizePostCode_SupplierMaster: 0,
  maxSizePostCode_SupplierMaster: 255,
  patternPostCode_SupplierMaster: "[0-9a-zA-Z/-]+",
  minSizeBusinessFirstDate_SupplierMaster: 0,
  maxSizeBusinessFirstDate_SupplierMaster: 255,
  patternBusinessFirstDate_SupplierMaster: "",
  minSizeCurrencyId_SupplierMaster: 0,
  maxSizeCurrencyId_SupplierMaster: 19,
  patternCurrencyId_SupplierMaster: "",
  minSizeSupplierOtherId_SupplierMaster: 0,
  maxSizeSupplierOtherId_SupplierMaster: 19,
  patternSupplierOtherId_SupplierMaster: "",
  minSizeSupplierPassword_SupplierMaster: 0,
  maxSizeSupplierPassword_SupplierMaster: 20,
  patternSupplierPassword_SupplierMaster: "",
  minSizeLeadTime_SupplierMaster: 0,
  maxSizeLeadTime_SupplierMaster: 20,
  patternLeadTime_SupplierMaster: "",
  minSizeSupplierGrade_SupplierMaster: 0,
  maxSizeSupplierGrade_SupplierMaster: 5,
  patternSupplierGrade_SupplierMaster: "",
  minSizeCreateOn_SupplierMaster: 0,
  maxSizeCreateOn_SupplierMaster: 20,
  patternCreateOn_SupplierMaster: "",
  minSizeUpdateOn_SupplierMaster: 0,
  maxSizeUpdateOn_SupplierMaster: 20,
  patternUpdateOn_SupplierMaster: "",
  minSizeCreateById_SupplierMaster: 0,
  maxSizeCreateById_SupplierMaster: 19,
  patternCreateById_SupplierMaster: "",
  minSizeUpdateById_SupplierMaster: 0,
  maxSizeUpdateById_SupplierMaster: 19,
  patternUpdateById_SupplierMaster: "",
  minSizeRemark_SupplierMaster: 0,
  maxSizeRemark_SupplierMaster: 500,
  patternRemark_SupplierMaster: "",
  minSizeSupplierMasterId_SupplierMasterSuppContact: 0,
  maxSizeSupplierMasterId_SupplierMasterSuppContact: 19,
  patternSupplierMasterId_SupplierMasterSuppContact: "",
  minSizeSupplierContactId_SupplierMasterSuppContact: 0,
  maxSizeSupplierContactId_SupplierMasterSuppContact: 19,
  patternSupplierContactId_SupplierMasterSuppContact: "",
  minSizeSupplierMasterId_SupplierMasterSupplierCredit: 0,
  maxSizeSupplierMasterId_SupplierMasterSupplierCredit: 19,
  patternSupplierMasterId_SupplierMasterSupplierCredit: "",
  minSizeSupplierCreditId_SupplierMasterSupplierCredit: 0,
  maxSizeSupplierCreditId_SupplierMasterSupplierCredit: 19,
  patternSupplierCreditId_SupplierMasterSupplierCredit: "",
  minSizeSupplierMasterId_SupplierMasterSupplierGroup: 0,
  maxSizeSupplierMasterId_SupplierMasterSupplierGroup: 19,
  patternSupplierMasterId_SupplierMasterSupplierGroup: "",
  minSizeSupplierGroupId_SupplierMasterSupplierGroup: 0,
  maxSizeSupplierGroupId_SupplierMasterSupplierGroup: 19,
  patternSupplierGroupId_SupplierMasterSupplierGroup: "",
  minSizeSupplierOpeningMasterId_SupplierOpeningMaster: 0,
  maxSizeSupplierOpeningMasterId_SupplierOpeningMaster: 19,
  patternSupplierOpeningMasterId_SupplierOpeningMaster: "",
  minSizeSuppOpenBillDocNo_SupplierOpeningMaster: 0,
  maxSizeSuppOpenBillDocNo_SupplierOpeningMaster: 20,
  patternSuppOpenBillDocNo_SupplierOpeningMaster: "",
  minSizeSuppOpenBillDocDate_SupplierOpeningMaster: 0,
  maxSizeSuppOpenBillDocDate_SupplierOpeningMaster: 20,
  patternSuppOpenBillDocDate_SupplierOpeningMaster: "",
  minSizeSuppOpenBillDueDate_SupplierOpeningMaster: 0,
  maxSizeSuppOpenBillDueDate_SupplierOpeningMaster: 20,
  patternSuppOpenBillDueDate_SupplierOpeningMaster: "",
  minSizeSuppOpenBillTotal_SupplierOpeningMaster: 0,
  maxSizeSuppOpenBillTotal_SupplierOpeningMaster: 26,
  patternSuppOpenBillTotal_SupplierOpeningMaster: "^[-?0-9]+(.[0-9]{2})?$",
  minSizeSuppOpenBillNetAmt_SupplierOpeningMaster: 0,
  maxSizeSuppOpenBillNetAmt_SupplierOpeningMaster: 26,
  patternSuppOpenBillNetAmt_SupplierOpeningMaster: "",
  minSizeSuppOpenBillTotalPay_SupplierOpeningMaster: 0,
  maxSizeSuppOpenBillTotalPay_SupplierOpeningMaster: 26,
  patternSuppOpenBillTotalPay_SupplierOpeningMaster: "",
  minSizeSuppOpenBillRemark_SupplierOpeningMaster: 0,
  maxSizeSuppOpenBillRemark_SupplierOpeningMaster: 150,
  patternSuppOpenBillRemark_SupplierOpeningMaster: "",
  minSizeSuppOpenBillStatus_SupplierOpeningMaster: 0,
  maxSizeSuppOpenBillStatus_SupplierOpeningMaster: 2,
  patternSuppOpenBillStatus_SupplierOpeningMaster: "",
  minSizeSuppOpenBillLinkDocNo_SupplierOpeningMaster: 0,
  maxSizeSuppOpenBillLinkDocNo_SupplierOpeningMaster: 20,
  patternSuppOpenBillLinkDocNo_SupplierOpeningMaster: "",
  minSizeCreateOn_SupplierOpeningMaster: 0,
  maxSizeCreateOn_SupplierOpeningMaster: 20,
  patternCreateOn_SupplierOpeningMaster: "",
  minSizeUpdateOn_SupplierOpeningMaster: 0,
  maxSizeUpdateOn_SupplierOpeningMaster: 20,
  patternUpdateOn_SupplierOpeningMaster: "",
  minSizeSuppOpenLinkBillingPayDocNo_SupplierOpeningMaster: 0,
  maxSizeSuppOpenLinkBillingPayDocNo_SupplierOpeningMaster: 20,
  patternSuppOpenLinkBillingPayDocNo_SupplierOpeningMaster: "",
  minSizeCompanyCode_SupplierOpeningMaster: 0,
  maxSizeCompanyCode_SupplierOpeningMaster: 20,
  patternCompanyCode_SupplierOpeningMaster: "[0-9a-zA-Z/-]+",
  minSizeAStatus_SupplierOpeningMaster: 0,
  maxSizeAStatus_SupplierOpeningMaster: 2,
  patternAStatus_SupplierOpeningMaster: "",
  minSizeDocumentGroupId_SupplierOpeningMaster: 0,
  maxSizeDocumentGroupId_SupplierOpeningMaster: 19,
  patternDocumentGroupId_SupplierOpeningMaster: "",
  minSizeSupplierMasterId_SupplierOpeningMaster: 0,
  maxSizeSupplierMasterId_SupplierOpeningMaster: 19,
  patternSupplierMasterId_SupplierOpeningMaster: "",
  minSizeOfficeRempMasterId_SupplierOpeningMaster: 0,
  maxSizeOfficeRempMasterId_SupplierOpeningMaster: 19,
  patternOfficeRempMasterId_SupplierOpeningMaster: "",
  minSizeDivisionId_SupplierOpeningMaster: 0,
  maxSizeDivisionId_SupplierOpeningMaster: 19,
  patternDivisionId_SupplierOpeningMaster: "",
  minSizeSectionId_SupplierOpeningMaster: 0,
  maxSizeSectionId_SupplierOpeningMaster: 19,
  patternSectionId_SupplierOpeningMaster: "",
  minSizeCreateByEmpId_SupplierOpeningMaster: 0,
  maxSizeCreateByEmpId_SupplierOpeningMaster: 19,
  patternCreateByEmpId_SupplierOpeningMaster: "",
  minSizeUpdateByEmpId_SupplierOpeningMaster: 0,
  maxSizeUpdateByEmpId_SupplierOpeningMaster: 19,
  patternUpdateByEmpId_SupplierOpeningMaster: "",
  minSizeExchangeRateDate_SupplierOpeningMaster: 0,
  maxSizeExchangeRateDate_SupplierOpeningMaster: 20,
  patternExchangeRateDate_SupplierOpeningMaster: "",
  minSizeExchangeRate_SupplierOpeningMaster: 0,
  maxSizeExchangeRate_SupplierOpeningMaster: 26,
  patternExchangeRate_SupplierOpeningMaster: "[0-9.]+",
  minSizeSupplierOpeningMasterId_SupplierOpeningMasterJobMast: 0,
  maxSizeSupplierOpeningMasterId_SupplierOpeningMasterJobMast: 19,
  patternSupplierOpeningMasterId_SupplierOpeningMasterJobMast: "",
  minSizeJobMasterId_SupplierOpeningMasterJobMast: 0,
  maxSizeJobMasterId_SupplierOpeningMasterJobMast: 19,
  patternJobMasterId_SupplierOpeningMasterJobMast: "",
  minSizeSupplierOtherId_SupplierOther: 0,
  maxSizeSupplierOtherId_SupplierOther: 19,
  patternSupplierOtherId_SupplierOther: "",
  minSizeSuppOther1_SupplierOther: 0,
  maxSizeSuppOther1_SupplierOther: 255,
  patternSuppOther1_SupplierOther: "",
  minSizeSuppOther2_SupplierOther: 0,
  maxSizeSuppOther2_SupplierOther: 255,
  patternSuppOther2_SupplierOther: "",
  minSizeSuppOther3_SupplierOther: 0,
  maxSizeSuppOther3_SupplierOther: 255,
  patternSuppOther3_SupplierOther: "",
  minSizeSuppOther4_SupplierOther: 0,
  maxSizeSuppOther4_SupplierOther: 255,
  patternSuppOther4_SupplierOther: "",
  minSizeSuppOther5_SupplierOther: 0,
  maxSizeSuppOther5_SupplierOther: 255,
  patternSuppOther5_SupplierOther: "",
  minSizeSuppOther6_SupplierOther: 0,
  maxSizeSuppOther6_SupplierOther: 255,
  patternSuppOther6_SupplierOther: "",
  minSizeSuppOther7_SupplierOther: 0,
  maxSizeSuppOther7_SupplierOther: 255,
  patternSuppOther7_SupplierOther: "",
  minSizeSuppOther8_SupplierOther: 0,
  maxSizeSuppOther8_SupplierOther: 255,
  patternSuppOther8_SupplierOther: "",
  minSizeCompanyCode_SupplierOther: 0,
  maxSizeCompanyCode_SupplierOther: 20,
  patternCompanyCode_SupplierOther: "[0-9a-zA-Z/-]+",
  minSizeTblUserLoginId_TblUserLogin: 0,
  maxSizeTblUserLoginId_TblUserLogin: 19,
  patternTblUserLoginId_TblUserLogin: "",
  minSizeUsername_TblUserLogin: 0,
  maxSizeUsername_TblUserLogin: 20,
  patternUsername_TblUserLogin: "",
  minSizePassword_TblUserLogin: 0,
  maxSizePassword_TblUserLogin: 100,
  patternPassword_TblUserLogin: "",
  minSizeCurrentLang_TblUserLogin: 0,
  maxSizeCurrentLang_TblUserLogin: 50,
  patternCurrentLang_TblUserLogin: "",
  minSizeStatus_TblUserLogin: 0,
  maxSizeStatus_TblUserLogin: 1,
  patternStatus_TblUserLogin: "",
  minSizeUserLastLogin_TblUserLogin: 0,
  maxSizeUserLastLogin_TblUserLogin: 20,
  patternUserLastLogin_TblUserLogin: "",
  minSizeUserLastLogout_TblUserLogin: 0,
  maxSizeUserLastLogout_TblUserLogin: 20,
  patternUserLastLogout_TblUserLogin: "",
  minSizeUserStartDate_TblUserLogin: 0,
  maxSizeUserStartDate_TblUserLogin: 10,
  patternUserStartDate_TblUserLogin: "",
  minSizeUserEndDate_TblUserLogin: 0,
  maxSizeUserEndDate_TblUserLogin: 10,
  patternUserEndDate_TblUserLogin: "",
  minSizeUserCode_TblUserLogin: 0,
  maxSizeUserCode_TblUserLogin: 20,
  patternUserCode_TblUserLogin: "[0-9a-zA-Z/-]+",
  minSizeUserDatabasePath_TblUserLogin: 0,
  maxSizeUserDatabasePath_TblUserLogin: 255,
  patternUserDatabasePath_TblUserLogin: "",
  minSizeCompanyCode_TblUserLogin: 0,
  maxSizeCompanyCode_TblUserLogin: 20,
  patternCompanyCode_TblUserLogin: "[0-9a-zA-Z/-]+",
  minSizeSessionId_TblUserLogin: 0,
  maxSizeSessionId_TblUserLogin: 50,
  patternSessionId_TblUserLogin: "",
  minSizeLoginDate_TblUserLogin: 0,
  maxSizeLoginDate_TblUserLogin: 10,
  patternLoginDate_TblUserLogin: "",
  minSizeLoginTime_TblUserLogin: 0,
  maxSizeLoginTime_TblUserLogin: 10,
  patternLoginTime_TblUserLogin: "",
  minSizeStatusSending_TblUserLogin: 0,
  maxSizeStatusSending_TblUserLogin: 1,
  patternStatusSending_TblUserLogin: "",
  minSizeUserCode_Nec_TblUserLogin: 0,
  patternUserCode_Nec_TblUserLogin: "[0-9a-zA-Z/-]+",
  minSizeDeviceId_TblUserLogin: 0,
  maxSizeDeviceId_TblUserLogin: 100,
  patternDeviceId_TblUserLogin: "",
  minSizeVer_TblUserLogin: 0,
  maxSizeVer_TblUserLogin: 10,
  patternVer_TblUserLogin: "",
  minSizeDeviceType_TblUserLogin: 0,
  maxSizeDeviceType_TblUserLogin: 20,
  patternDeviceType_TblUserLogin: "",
  minSizeTypeMasterId: 0,
  maxSizeTypeMasterId: 19,
  patternTypeMasterId: "",
  minSizeCode: 0,
  maxSizeCode: 20,
  patternCode: "[0-9a-zA-Z/-]+",
  minSizeName: 0,
  maxSizeName: 255,
  minSizetName: 0,
  maxSizetName: 255,
  patterntName: "",
  minSizeeName: 0,
  maxSizeeName: 255,
  patterneName: "",
  minSizeRemark: 0,
  maxSizeRemark: 1500,
  patternRemark: "",
  minSizeLocation: 0,
  maxSizeLocation: 100,
  patternLocation: "",
  minSizeCostCenterId_CostCenter: 0,
  maxSizeCostCenterId_CostCenter: 19,
  patternCostCenterId_CostCenter: "",
  minSizeCostCenterCode_CostCenter: 0,
  maxSizeCostCenterCode_CostCenter: 20,
  patternCostCenterCode_CostCenter: "",
  minSizeCostCenterName_CostCenter: 0,
  maxSizeCostCenterName_CostCenter: 120,
  patternCostCenterName_CostCenter: "",
  minSizeCostCenterEName_CostCenter: 0,
  maxSizeCostCenterEName_CostCenter: 120,
  patternCostCenterEName_CostCenter: "",
  minSizeCostCenterRemark_CostCenter: 0,
  maxSizeCostCenterRemark_CostCenter: 200,
  patternCostCenterRemark_CostCenter: "",
  minSizeCompanyCode_CostCenter: 0,
  maxSizeCompanyCode_CostCenter: 20,
  patternCompanyCode_CostCenter: "",
  minSizeCostCenterType_CostCenter: 0,
  maxSizeCostCenterType_CostCenter: 20,
  patternCostCenterType_CostCenter: "",
  minSizeUnitOfMeasureId_UnitOfMeasure: 0,
  maxSizeUnitOfMeasureId_UnitOfMeasure: 19,
  patternUnitOfMeasureId_UnitOfMeasure: "",
  minSizeIsBaseUnit_UnitOfMeasure: 0,
  maxSizeIsBaseUnit_UnitOfMeasure: 255,
  patternIsBaseUnit_UnitOfMeasure: "",
  minSizeCompanyCode_UnitOfMeasure: 0,
  maxSizeCompanyCode_UnitOfMeasure: 20,
  patternCompanyCode_UnitOfMeasure: "[0-9a-zA-Z/-]+",
  minSizeStatusSending_UnitOfMeasure: 0,
  maxSizeStatusSending_UnitOfMeasure: 1,
  patternStatusSending_UnitOfMeasure: "",
  minSizeVer_UnitOfMeasure: 0,
  maxSizeVer_UnitOfMeasure: 10,
  patternVer_UnitOfMeasure: "",
  minSizeStatus_UnitOfMeasure: 0,
  maxSizeStatus_UnitOfMeasure: 2,
  patternStatus_UnitOfMeasure: "",
  minSizeWarehouseId_Warehouse: 0,
  maxSizeWarehouseId_Warehouse: 19,
  patternWarehouseId_Warehouse: "",
  minSizeWhCode_Warehouse: 0,
  maxSizeWhCode_Warehouse: 50,
  patternWhCode_Warehouse: "[0-9a-zA-Z/-]+",
  minSizeWhTName_Warehouse: 0,
  maxSizeWhTName_Warehouse: 150,
  patternWhTName_Warehouse: "",
  minSizeWhEName_Warehouse: 0,
  maxSizeWhEName_Warehouse: 150,
  patternWhEName_Warehouse: "",
  minSizeWhContactName_Warehouse: 0,
  maxSizeWhContactName_Warehouse: 150,
  patternWhContactName_Warehouse: "",
  minSizeWhAddress1_Warehouse: 0,
  maxSizeWhAddress1_Warehouse: 150,
  patternWhAddress1_Warehouse: "",
  minSizeWhAddress2_Warehouse: 0,
  maxSizeWhAddress2_Warehouse: 150,
  patternWhAddress2_Warehouse: "",
  minSizeWhAddress3_Warehouse: 0,
  maxSizeWhAddress3_Warehouse: 150,
  patternWhAddress3_Warehouse: "",
  minSizeWhTelNo_Warehouse: 0,
  maxSizeWhTelNo_Warehouse: 150,
  patternWhTelNo_Warehouse: "",
  minSizeWhFaxNo_Warehouse: 0,
  maxSizeWhFaxNo_Warehouse: 50,
  patternWhFaxNo_Warehouse: "",
  minSizeWhEmail_Warehouse: 0,
  maxSizeWhEmail_Warehouse: 50,
  patternWhEmail_Warehouse: "",
  minSizeWhRemark_Warehouse: 0,
  maxSizeWhRemark_Warehouse: 255,
  patternWhRemark_Warehouse: "",
  minSizeCompanyCode_Warehouse: 0,
  maxSizeCompanyCode_Warehouse: 20,
  patternCompanyCode_Warehouse: "[0-9a-zA-Z/-]+",
  minSizeStatusSending_Warehouse: 0,
  maxSizeStatusSending_Warehouse: 1,
  patternStatusSending_Warehouse: "",
  minSizeVer_Warehouse: 0,
  maxSizeVer_Warehouse: 10,
  patternVer_Warehouse: "",
  minSizeDivisionId_Warehouse: 0,
  maxSizeDivisionId_Warehouse: 19,
  patternDivisionId_Warehouse: "",
  minSizeWhtDataId_WhtData: 0,
  maxSizeWhtDataId_WhtData: 19,
  patternWhtDataId_WhtData: "",
  minSizeCode_WhtData: 0,
  maxSizeCode_WhtData: 255,
  patternCode_WhtData: "[0-9a-zA-Z/-]+",
  minSizeName_WhtData: 0,
  maxSizeName_WhtData: 255,
  patternName_WhtData: "",
  minSizeeName_WhtData: 0,
  maxSizeeName_WhtData: 255,
  patterneName_WhtData: "",
  minSizeRate_WhtData: 0,
  maxSizeRate_WhtData: 26,
  patternRate_WhtData: "[0-9.]+",
  minSizeCompanyCode_WhtData: 0,
  maxSizeCompanyCode_WhtData: 255,
  patternCompanyCode_WhtData: "[0-9a-zA-Z/-]+",
  minSizeAccountCodeId_WhtData: 0,
  maxSizeAccountCodeId_WhtData: 19,
  patternAccountCodeId_WhtData: "[0-9a-zA-Z/-]+",
  minSizeStatusSending_WhtData: 0,
  maxSizeStatusSending_WhtData: 1,
  patternStatusSending_WhtData: "",
  minSizeVer_WhtData: 0,
  maxSizeVer_WhtData: 10,
  patternVer_WhtData: "",
  minSizeValue1_OptionMaster: 0,
  maxSizeValue1_OptionMaster: 255,
  patternValue1_OptionMaster: "",
  minSizeQUProductDetailId_QUProductDetail: 0,
  maxSizeQUProductDetailId_QUProductDetail: 19,
  patternQUProductDetailId_QUProductDetail: "",
  minSizeQUProductDetailNo_QUProductDetail: 0,
  maxSizeQUProductDetailNo_QUProductDetail: 10,
  patternQUProductDetailNo_QUProductDetail: "",
  minSizeProdQTY_QUProductDetail: 0,
  maxSizeProdQTY_QUProductDetail: 26,
  patternProdQTY_QUProductDetail: "",
  minSizeProdSaleQTY_QUProductDetail: 0,
  maxSizeProdSaleQTY_QUProductDetail: 26,
  patternProdSaleQTY_QUProductDetail: "",
  minSizeProdConvFactor_QUProductDetail: 0,
  maxSizeProdConvFactor_QUProductDetail: 26,
  patternProdConvFactor_QUProductDetail: "",
  minSizeProdBaseQTY_QUProductDetail: 0,
  maxSizeProdBaseQTY_QUProductDetail: 26,
  patternProdBaseQTY_QUProductDetail: "",
  minSizeProdSaleBaseQTY_QUProductDetail: 0,
  maxSizeProdSaleBaseQTY_QUProductDetail: 26,
  patternProdSaleBaseQTY_QUProductDetail: "",
  minSizeProdPrice_QUProductDetail: 0,
  maxSizeProdPrice_QUProductDetail: 26,
  patternProdPrice_QUProductDetail: "",
  minSizeProdDiscrate_QUProductDetail: 0,
  maxSizeProdDiscrate_QUProductDetail: 26,
  patternProdDiscrate_QUProductDetail: "",
  minSizeProdDiscAmt_QUProductDetail: 0,
  maxSizeProdDiscAmt_QUProductDetail: 26,
  patternProdDiscAmt_QUProductDetail: "",
  minSizeProdNetPrice_QUProductDetail: 0,
  maxSizeProdNetPrice_QUProductDetail: 26,
  patternProdNetPrice_QUProductDetail: "",
  minSizeProdSaleOrderDate_QUProductDetail: 0,
  maxSizeProdSaleOrderDate_QUProductDetail: 25,
  patternProdSaleOrderDate_QUProductDetail: "",
  minSizeProdSaleOrderDateComplete_QUProductDetail: 0,
  maxSizeProdSaleOrderDateComplete_QUProductDetail: 25,
  patternProdSaleOrderDateComplete_QUProductDetail: "",
  minSizeProdLastSaleOrderDocno_QUProductDetail: 0,
  maxSizeProdLastSaleOrderDocno_QUProductDetail: 20,
  patternProdLastSaleOrderDocno_QUProductDetail: "",
  minSizeCompanyCode_QUProductDetail: 0,
  maxSizeCompanyCode_QUProductDetail: 20,
  patternCompanyCode_QUProductDetail: "[0-9a-zA-Z/-]+",
  minSizeProdDisCrate0_QUProductDetail: 0,
  maxSizeProdDisCrate0_QUProductDetail: 20,
  patternProdDisCrate0_QUProductDetail: "",
  minSizeProdItemDetail_QUProductDetail: 0,
  maxSizeProdItemDetail_QUProductDetail: 9999,
  patternProdItemDetail_QUProductDetail: "",
  minSizeProductMaster1Id_QUProductDetail: 0,
  maxSizeProductMaster1Id_QUProductDetail: 19,
  patternProductMaster1Id_QUProductDetail: "",
  minSizeQuProductMasterId_QUProductDetail: 0,
  maxSizeQuProductMasterId_QUProductDetail: 19,
  patternQuProductMasterId_QUProductDetail: "",
  minSizeUnitOfMeasureId1_QUProductDetail: 0,
  maxSizeUnitOfMeasureId1_QUProductDetail: 19,
  patternUnitOfMeasureId1_QUProductDetail: "",
  minSizeUnitOfMeasureId2_QUProductDetail: 0,
  maxSizeUnitOfMeasureId2_QUProductDetail: 19,
  patternUnitOfMeasureId2_QUProductDetail: "",
  minSizeProdQTYUse_QUProductDetail: 0,
  maxSizeProdQTYUse_QUProductDetail: 26,
  patternProdQTYUse_QUProductDetail: "",
  minSizeImageUrl_QUProductDetail: 0,
  maxSizeImageUrl_QUProductDetail: 255,
  patternImageUrl_QUProductDetail: "",
  minSizeProdName_QUProductDetail: 0,
  maxSizeProdName_QUProductDetail: 300,
  patternProdName_QUProductDetail: "",
  minSizeProdName1_QUProductDetail: 0,
  maxSizeProdName1_QUProductDetail: 300,
  patternProdName1_QUProductDetail: "",
  minSizeJobMasterId_QUProductDetail: 0,
  maxSizeJobMasterId_QUProductDetail: 19,
  patternJobMasterId_QUProductDetail: "",
  minSizeSaleOrderServiceMasterId_SaleOrderServMasterJobMaster: 0,
  maxSizeSaleOrderServiceMasterId_SaleOrderServMasterJobMaster: 19,
  patternSaleOrderServiceMasterId_SaleOrderServMasterJobMaster: "",
  minSizeJobMasterId_SaleOrderServMasterJobMaster: 0,
  maxSizeJobMasterId_SaleOrderServMasterJobMaster: 19,
  patternJobMasterId_SaleOrderServMasterJobMaster: "",
  minSizeSaleOrderServiceDetailId_SaleOrderServiceDetail: 0,
  maxSizeSaleOrderServiceDetailId_SaleOrderServiceDetail: 19,
  patternSaleOrderServiceDetailId_SaleOrderServiceDetail: "",
  minSizeSaleOrderServiceDetailNo_SaleOrderServiceDetail: 0,
  maxSizeSaleOrderServiceDetailNo_SaleOrderServiceDetail: 10,
  patternSaleOrderServiceDetailNo_SaleOrderServiceDetail: "",
  minSizeServiceQty_SaleOrderServiceDetail: 0,
  maxSizeServiceQty_SaleOrderServiceDetail: 26,
  patternServiceQty_SaleOrderServiceDetail: "",
  minSizeServiceBillingQty_SaleOrderServiceDetail: 0,
  maxSizeServiceBillingQty_SaleOrderServiceDetail: 26,
  patternServiceBillingQty_SaleOrderServiceDetail: "",
  minSizeServiceUnit_SaleOrderServiceDetail: 0,
  maxSizeServiceUnit_SaleOrderServiceDetail: 100,
  patternServiceUnit_SaleOrderServiceDetail: "",
  minSizeServicePrice_SaleOrderServiceDetail: 0,
  maxSizeServicePrice_SaleOrderServiceDetail: 26,
  patternServicePrice_SaleOrderServiceDetail: "",
  minSizeServiceDiscRate_SaleOrderServiceDetail: 0,
  maxSizeServiceDiscRate_SaleOrderServiceDetail: 26,
  patternServiceDiscRate_SaleOrderServiceDetail: "",
  minSizeServiceDiscAmt_SaleOrderServiceDetail: 0,
  maxSizeServiceDiscAmt_SaleOrderServiceDetail: 26,
  patternServiceDiscAmt_SaleOrderServiceDetail: "",
  minSizeServiceNetPrice_SaleOrderServiceDetail: 0,
  maxSizeServiceNetPrice_SaleOrderServiceDetail: 26,
  patternServiceNetPrice_SaleOrderServiceDetail: "",
  minSizeServiceDiscRate0_SaleOrderServiceDetail: 0,
  maxSizeServiceDiscRate0_SaleOrderServiceDetail: 20,
  patternServiceDiscRate0_SaleOrderServiceDetail: "",
  minSizeQuDocNo_SaleOrderServiceDetail: 0,
  maxSizeQuDocNo_SaleOrderServiceDetail: 20,
  patternQuDocNo_SaleOrderServiceDetail: "",
  minSizeServiceVat_SaleOrderServiceDetail: 0,
  maxSizeServiceVat_SaleOrderServiceDetail: 2,
  patternServiceVat_SaleOrderServiceDetail: "",
  minSizeQuDocIndex_SaleOrderServiceDetail: 0,
  maxSizeQuDocIndex_SaleOrderServiceDetail: 10,
  patternQuDocIndex_SaleOrderServiceDetail: "",
  minSizeServicebnDocDate_SaleOrderServiceDetail: 0,
  maxSizeServicebnDocDate_SaleOrderServiceDetail: 20,
  patternServicebnDocDate_SaleOrderServiceDetail: "",
  minSizeServicebnDateComplete_SaleOrderServiceDetail: 0,
  maxSizeServicebnDateComplete_SaleOrderServiceDetail: 20,
  patternServicebnDateComplete_SaleOrderServiceDetail: "",
  minSizeServicebnDocNo_SaleOrderServiceDetail: 0,
  maxSizeServicebnDocNo_SaleOrderServiceDetail: 20,
  patternServicebnDocNo_SaleOrderServiceDetail: "",
  minSizeCompanyCode_SaleOrderServiceDetail: 0,
  maxSizeCompanyCode_SaleOrderServiceDetail: 20,
  patternCompanyCode_SaleOrderServiceDetail: "[0-9a-zA-Z/-]+",
  minSizeServiceItemDetail_SaleOrderServiceDetail: 0,
  maxSizeServiceItemDetail_SaleOrderServiceDetail: 9999,
  patternServiceItemDetail_SaleOrderServiceDetail: "",
  minSizeServiceNewCode_SaleOrderServiceDetail: 0,
  maxSizeServiceNewCode_SaleOrderServiceDetail: 20,
  patternServiceNewCode_SaleOrderServiceDetail: "[0-9a-zA-Z/-]+",
  minSizeServiceNewName_SaleOrderServiceDetail: 0,
  maxSizeServiceNewName_SaleOrderServiceDetail: 300,
  patternServiceNewName_SaleOrderServiceDetail: "",
  minSizeServiceMasterId_SaleOrderServiceDetail: 0,
  maxSizeServiceMasterId_SaleOrderServiceDetail: 19,
  patternServiceMasterId_SaleOrderServiceDetail: "",
  minSizeSaleOrderServiceMasterId_SaleOrderServiceDetail: 0,
  maxSizeSaleOrderServiceMasterId_SaleOrderServiceDetail: 19,
  patternSaleOrderServiceMasterId_SaleOrderServiceDetail: "",
  minSizeBillingPriceBalance_SaleOrderServiceDetail: 0,
  maxSizeBillingPriceBalance_SaleOrderServiceDetail: 26,
  patternBillingPriceBalance_SaleOrderServiceDetail: "",
  minSizePeriodNo_SaleOrderServiceDetail: 0,
  maxSizePeriodNo_SaleOrderServiceDetail: 10,
  patternPeriodNo_SaleOrderServiceDetail: "",
  minSizeStartPeriodDate_SaleOrderServiceDetail: 0,
  maxSizeStartPeriodDate_SaleOrderServiceDetail: 20,
  patternStartPeriodDate_SaleOrderServiceDetail: "",
  minSizeReturnPayDate_SaleOrderServiceDetail: 0,
  maxSizeReturnPayDate_SaleOrderServiceDetail: 20,
  patternReturnPayDate_SaleOrderServiceDetail: "",
  minSizeCalPeriodType_SaleOrderServiceDetail: 0,
  maxSizeCalPeriodType_SaleOrderServiceDetail: 1,
  patternCalPeriodType_SaleOrderServiceDetail: "",
  minSizeCalPeriodValue_SaleOrderServiceDetail: 0,
  maxSizeCalPeriodValue_SaleOrderServiceDetail: 26,
  patternCalPeriodValue_SaleOrderServiceDetail: "",
  minSizeSaleOrderServiceMasterId_SaleOrderServiceMaster: 0,
  maxSizeSaleOrderServiceMasterId_SaleOrderServiceMaster: 19,
  patternSaleOrderServiceMasterId_SaleOrderServiceMaster: "",
  minSizeSoDocNo_SaleOrderServiceMaster: 0,
  maxSizeSoDocNo_SaleOrderServiceMaster: 20,
  patternSoDocNo_SaleOrderServiceMaster: "",
  minSizeSoDocDate_SaleOrderServiceMaster: 0,
  maxSizeSoDocDate_SaleOrderServiceMaster: 25,
  patternSoDocDate_SaleOrderServiceMaster: "",
  minSizeSoCustName_SaleOrderServiceMaster: 0,
  patternSoCustName_SaleOrderServiceMaster: "",
  minSizeSoCreditTerm_SaleOrderServiceMaster: 0,
  maxSizeSoCreditTerm_SaleOrderServiceMaster: 10,
  patternSoCreditTerm_SaleOrderServiceMaster: "",
  minSizeSoTotal_SaleOrderServiceMaster: 0,
  maxSizeSoTotal_SaleOrderServiceMaster: 26,
  patternSoTotal_SaleOrderServiceMaster: "",
  minSizeSoTotalNoVat_SaleOrderServiceMaster: 0,
  maxSizeSoTotalNoVat_SaleOrderServiceMaster: 26,
  patternSoTotalNoVat_SaleOrderServiceMaster: "",
  minSizeSoVatRate_SaleOrderServiceMaster: 0,
  maxSizeSoVatRate_SaleOrderServiceMaster: 26,
  patternSoVatRate_SaleOrderServiceMaster: "",
  minSizeSoVatAmt_SaleOrderServiceMaster: 0,
  maxSizeSoVatAmt_SaleOrderServiceMaster: 26,
  patternSoVatAmt_SaleOrderServiceMaster: "",
  minSizeSoNetAmt_SaleOrderServiceMaster: 0,
  maxSizeSoNetAmt_SaleOrderServiceMaster: 26,
  patternSoNetAmt_SaleOrderServiceMaster: "",
  minSizeSoRemark_SaleOrderServiceMaster: 0,
  maxSizeSoRemark_SaleOrderServiceMaster: 1500,
  patternSoRemark_SaleOrderServiceMaster: "",
  minSizeSoStatus_SaleOrderServiceMaster: 0,
  maxSizeSoStatus_SaleOrderServiceMaster: 2,
  patternSoStatus_SaleOrderServiceMaster: "",
  minSizeSoLinkDoc_SaleOrderServiceMaster: 0,
  maxSizeSoLinkDoc_SaleOrderServiceMaster: 20,
  patternSoLinkDoc_SaleOrderServiceMaster: "",
  minSizeCreateOn_SaleOrderServiceMaster: 0,
  maxSizeCreateOn_SaleOrderServiceMaster: 25,
  patternCreateOn_SaleOrderServiceMaster: "",
  minSizeUpdateOn_SaleOrderServiceMaster: 0,
  maxSizeUpdateOn_SaleOrderServiceMaster: 25,
  patternUpdateOn_SaleOrderServiceMaster: "",
  minSizeSoDateComplete_SaleOrderServiceMaster: 0,
  maxSizeSoDateComplete_SaleOrderServiceMaster: 20,
  patternSoDateComplete_SaleOrderServiceMaster: "",
  minSizeCompanyCode_SaleOrderServiceMaster: 0,
  maxSizeCompanyCode_SaleOrderServiceMaster: 20,
  patternCompanyCode_SaleOrderServiceMaster: "[0-9a-zA-Z/-]+",
  minSizeVatInclude_SaleOrderServiceMaster: 0,
  maxSizeVatInclude_SaleOrderServiceMaster: 20,
  patternVatInclude_SaleOrderServiceMaster: "",
  minSizeSoDiscountRate_SaleOrderServiceMaster: 0,
  maxSizeSoDiscountRate_SaleOrderServiceMaster: 26,
  patternSoDiscountRate_SaleOrderServiceMaster: "",
  minSizeSoDiscountAmt_SaleOrderServiceMaster: 0,
  maxSizeSoDiscountAmt_SaleOrderServiceMaster: 26,
  patternSoDiscountAmt_SaleOrderServiceMaster: "",
  minSizeExchangeRate_SaleOrderServiceMaster: 0,
  maxSizeExchangeRate_SaleOrderServiceMaster: 26,
  patternExchangeRate_SaleOrderServiceMaster: "",
  minSizeExchageRateDate_SaleOrderServiceMaster: 0,
  maxSizeExchageRateDate_SaleOrderServiceMaster: 20,
  patternExchageRateDate_SaleOrderServiceMaster: "",
  minSizeAstatus_SaleOrderServiceMaster: 0,
  maxSizeAstatus_SaleOrderServiceMaster: 2,
  patternAstatus_SaleOrderServiceMaster: "",
  minSizeReviseNo_SaleOrderServiceMaster: 0,
  maxSizeReviseNo_SaleOrderServiceMaster: 10,
  patternReviseNo_SaleOrderServiceMaster: "",
  minSizeCurrencyId_SaleOrderServiceMaster: 0,
  maxSizeCurrencyId_SaleOrderServiceMaster: 19,
  patternCurrencyId_SaleOrderServiceMaster: "",
  minSizeDocumentGroupId_SaleOrderServiceMaster: 0,
  maxSizeDocumentGroupId_SaleOrderServiceMaster: 19,
  patternDocumentGroupId_SaleOrderServiceMaster: "",
  minSizeCustomerMasterId_SaleOrderServiceMaster: 0,
  maxSizeCustomerMasterId_SaleOrderServiceMaster: 19,
  patternCustomerMasterId_SaleOrderServiceMaster: "",
  minSizeSaleEmpMasterId_SaleOrderServiceMaster: 0,
  maxSizeSaleEmpMasterId_SaleOrderServiceMaster: 19,
  patternSaleEmpMasterId_SaleOrderServiceMaster: "",
  minSizeDivisionId_SaleOrderServiceMaster: 0,
  maxSizeDivisionId_SaleOrderServiceMaster: 19,
  patternDivisionId_SaleOrderServiceMaster: "",
  minSizeSectionId_SaleOrderServiceMaster: 0,
  maxSizeSectionId_SaleOrderServiceMaster: 19,
  patternSectionId_SaleOrderServiceMaster: "",
  minSizeCreateByEmpId_SaleOrderServiceMaster: 0,
  maxSizeCreateByEmpId_SaleOrderServiceMaster: 19,
  patternCreateByEmpId_SaleOrderServiceMaster: "",
  minSizeUpdateByEmpId_SaleOrderServiceMaster: 0,
  maxSizeUpdateByEmpId_SaleOrderServiceMaster: 19,
  patternUpdateByEmpId_SaleOrderServiceMaster: "",
  minSizeCusDivision_SaleOrderServiceMaster: 0,
  maxSizeCusDivision_SaleOrderServiceMaster: 10,
  patternCusDivision_SaleOrderServiceMaster: "",
  minSizeDivisionNo_SaleOrderServiceMaster: 0,
  maxSizeDivisionNo_SaleOrderServiceMaster: 20,
  patternDivisionNo_SaleOrderServiceMaster: "",
  minSizeDivisionName_SaleOrderServiceMaster: 0,
  maxSizeDivisionName_SaleOrderServiceMaster: 1000,
  patternDivisionName_SaleOrderServiceMaster: "",
  minSizeDivisionAddress_SaleOrderServiceMaster: 0,
  maxSizeDivisionAddress_SaleOrderServiceMaster: 1000,
  patternDivisionAddress_SaleOrderServiceMaster: "",
  minSizeIsUsePeriod_SaleOrderServiceMaster: 0,
  maxSizeIsUsePeriod_SaleOrderServiceMaster: 1,
  patternIsUsePeriod_SaleOrderServiceMaster: "",
  minSizePrintStatus_SaleOrderServiceMaster: 0,
  maxSizePrintStatus_SaleOrderServiceMaster: 2,
  patternPrintStatus_SaleOrderServiceMaster: "",
  minSizeDocumentDateClose_SaleOrderServiceMaster: 0,
  maxSizeDocumentDateClose_SaleOrderServiceMaster: 20,
  patternDocumentDateClose_SaleOrderServiceMaster: "",
  minSizeUserCloseEmpId_SaleOrderServiceMaster: 0,
  maxSizeUserCloseEmpId_SaleOrderServiceMaster: 19,
  patternUserCloseEmpId_SaleOrderServiceMaster: "",
  minSizeSoPayAmt_SaleOrderServiceMaster: 0,
  maxSizeSoPayAmt_SaleOrderServiceMaster: 26,
  patternSoPayAmt_SaleOrderServiceMaster: "",
  minSizeUserApprove_SaleOrderServiceMaster: 0,
  maxSizeUserApprove_SaleOrderServiceMaster: 20,
  patternUserApprove_SaleOrderServiceMaster: "",
  minSizeQuProductMasterId_QUProductMaster: 0,
  maxSizeQuProductMasterId_QUProductMaster: 19,
  patternQuProductMasterId_QUProductMaster: "",
  minSizeQuDocno_QUProductMaster: 0,
  maxSizeQuDocno_QUProductMaster: 20,
  patternQuDocno_QUProductMaster: "",
  minSizeQuDate_QUProductMaster: 0,
  maxSizeQuDate_QUProductMaster: 20,
  patternQuDate_QUProductMaster: "",
  minSizeQuCustName_QUProductMaster: 0,
  maxSizeQuCustName_QUProductMaster: 150,
  patternQuCustName_QUProductMaster: "",
  minSizeQuCustContact_QUProductMaster: 0,
  maxSizeQuCustContact_QUProductMaster: 150,
  patternQuCustContact_QUProductMaster: "",
  minSizeQuCustAddress_QUProductMaster: 0,
  maxSizeQuCustAddress_QUProductMaster: 255,
  patternQuCustAddress_QUProductMaster: "",
  minSizeQuCustCondt_QUProductMaster: 0,
  maxSizeQuCustCondt_QUProductMaster: 200,
  patternQuCustCondt_QUProductMaster: "",
  minSizeQuCustPriceLevel_QUProductMaster: 0,
  maxSizeQuCustPriceLevel_QUProductMaster: 10,
  patternQuCustPriceLevel_QUProductMaster: "",
  minSizeQuPriceTerm_QUProductMaster: 0,
  maxSizeQuPriceTerm_QUProductMaster: 10,
  patternQuPriceTerm_QUProductMaster: "",
  minSizeQuTotal_QUProductMaster: 0,
  maxSizeQuTotal_QUProductMaster: 26,
  patternQuTotal_QUProductMaster: "",
  minSizeQuDiscrate_QUProductMaster: 0,
  maxSizeQuDiscrate_QUProductMaster: 26,
  patternQuDiscrate_QUProductMaster: "",
  minSizeQuDiscAmt_QUProductMaster: 0,
  maxSizeQuDiscAmt_QUProductMaster: 26,
  patternQuDiscAmt_QUProductMaster: "",
  minSizeQuVatRate_QUProductMaster: 0,
  maxSizeQuVatRate_QUProductMaster: 26,
  patternQuVatRate_QUProductMaster: "",
  minSizeQuVatAmt_QUProductMaster: 0,
  maxSizeQuVatAmt_QUProductMaster: 26,
  patternQuVatAmt_QUProductMaster: "",
  minSizeQuNetPrice_QUProductMaster: 0,
  maxSizeQuNetPrice_QUProductMaster: 26,
  patternQuNetPrice_QUProductMaster: "",
  minSizeQuRemark_QUProductMaster: 0,
  maxSizeQuRemark_QUProductMaster: 1500,
  patternQuRemark_QUProductMaster: "",
  minSizeQuStatus_QUProductMaster: 0,
  maxSizeQuStatus_QUProductMaster: 2,
  patternQuStatus_QUProductMaster: "",
  minSizeQuLinkDocNo_QUProductMaster: 0,
  maxSizeQuLinkDocNo_QUProductMaster: 20,
  patternQuLinkDocNo_QUProductMaster: "",
  minSizeQuReferPONo_QUProductMaster: 0,
  maxSizeQuReferPONo_QUProductMaster: 50,
  patternQuReferPONo_QUProductMaster: "",
  minSizeCreteOn_QUProductMaster: 0,
  maxSizeCreteOn_QUProductMaster: 20,
  patternCreteOn_QUProductMaster: "",
  minSizeUpdateOn_QUProductMaster: 0,
  maxSizeUpdateOn_QUProductMaster: 20,
  patternUpdateOn_QUProductMaster: "",
  minSizeQuDateComplete_QUProductMaster: 0,
  maxSizeQuDateComplete_QUProductMaster: 20,
  patternQuDateComplete_QUProductMaster: "",
  minSizeCompanyCode_QUProductMaster: 0,
  maxSizeCompanyCode_QUProductMaster: 20,
  patternCompanyCode_QUProductMaster: "[0-9a-zA-Z/-]+",
  minSizeVatInclude_QUProductMaster: 0,
  maxSizeVatInclude_QUProductMaster: 2,
  patternVatInclude_QUProductMaster: "",
  minSizeExchangeRate_QUProductMaster: 0,
  maxSizeExchangeRate_QUProductMaster: 26,
  patternExchangeRate_QUProductMaster: "",
  minSizeExchageRateDate_QUProductMaster: 0,
  maxSizeExchageRateDate_QUProductMaster: 20,
  patternExchageRateDate_QUProductMaster: "",
  minSizeAstatus_QUProductMaster: 0,
  maxSizeAstatus_QUProductMaster: 2,
  patternAstatus_QUProductMaster: "",
  minSizeReviseNo_QUProductMaster: 0,
  maxSizeReviseNo_QUProductMaster: 10,
  patternReviseNo_QUProductMaster: "",
  minSizeCurrencyId_QUProductMaster: 0,
  maxSizeCurrencyId_QUProductMaster: 19,
  patternCurrencyId_QUProductMaster: "",
  minSizeDocumentGroupId_QUProductMaster: 0,
  maxSizeDocumentGroupId_QUProductMaster: 19,
  patternDocumentGroupId_QUProductMaster: "",
  minSizeCustomerMasterId_QUProductMaster: 0,
  maxSizeCustomerMasterId_QUProductMaster: 19,
  patternCustomerMasterId_QUProductMaster: "",
  minSizeSaleEmpMasterId_QUProductMaster: 0,
  maxSizeSaleEmpMasterId_QUProductMaster: 19,
  patternSaleEmpMasterId_QUProductMaster: "",
  minSizeDivisionId_QUProductMaster: 0,
  maxSizeDivisionId_QUProductMaster: 19,
  patternDivisionId_QUProductMaster: "",
  minSizeSectionId_QUProductMaster: 0,
  maxSizeSectionId_QUProductMaster: 19,
  patternSectionId_QUProductMaster: "",
  minSizeCreateByEmpId_QUProductMaster: 0,
  maxSizeCreateByEmpId_QUProductMaster: 19,
  patternCreateByEmpId_QUProductMaster: "",
  minSizeUpdateByEmpId_QUProductMaster: 0,
  maxSizeUpdateByEmpId_QUProductMaster: 19,
  patternUpdateByEmpId_QUProductMaster: "",
  minSizeReviseQUProduct_QUProductMaster: 0,
  maxSizeReviseQUProduct_QUProductMaster: 10,
  patternReviseQUProduct_QUProductMaster: "",
  minSizeReferenceDocumentQUProduct_QUProductMaster: 0,
  maxSizeReferenceDocumentQUProduct_QUProductMaster: 20,
  patternReferenceDocumentQUProduct_QUProductMaster: "",
  minSizeCusDivision_QUProductMaster: 0,
  maxSizeCusDivision_QUProductMaster: 10,
  patternCusDivision_QUProductMaster: "",
  minSizeDivisionNo_QUProductMaster: 0,
  maxSizeDivisionNo_QUProductMaster: 20,
  patternDivisionNo_QUProductMaster: "",
  minSizeDivisionName_QUProductMaster: 0,
  maxSizeDivisionName_QUProductMaster: 1000,
  patternDivisionName_QUProductMaster: "",
  minSizeDivisionAddress_QUProductMaster: 0,
  maxSizeDivisionAddress_QUProductMaster: 1000,
  patternDivisionAddress_QUProductMaster: "",
  minSizeDocumentDateClose_QUProductMaster: 0,
  maxSizeDocumentDateClose_QUProductMaster: 20,
  patternDocumentDateClose_QUProductMaster: "",
  minSizeUserCloseEmpId_QUProductMaster: 0,
  maxSizeUserCloseEmpId_QUProductMaster: 19,
  patternUserCloseEmpId_QUProductMaster: "",
  minSizeUserApprove_QUProductMaster: 0,
  maxSizeUserApprove_QUProductMaster: 20,
  patternUserApprove_QUProductMaster: "",
  minSizeUseByDocType_QUProductMaster: 0,
  maxSizeUseByDocType_QUProductMaster: 5,
  patternUseByDocType_QUProductMaster: "",
  minSizeQuServiceMasterId_QUServiceMasterJobMaster: 0,
  maxSizeQuServiceMasterId_QUServiceMasterJobMaster: 19,
  patternQuServiceMasterId_QUServiceMasterJobMaster: "",
  minSizeJobMasterId_QUServiceMasterJobMaster: 0,
  maxSizeJobMasterId_QUServiceMasterJobMaster: 19,
  patternJobMasterId_QUServiceMasterJobMaster: "",
  minSizeQuServiceDetailId_QUServiceDetail: 0,
  maxSizeQuServiceDetailId_QUServiceDetail: 19,
  patternQuServiceDetailId_QUServiceDetail: "",
  minSizeQuServiceDetailNo_QUServiceDetail: 0,
  maxSizeQuServiceDetailNo_QUServiceDetail: 10,
  patternQuServiceDetailNo_QUServiceDetail: "",
  minSizeServiceQTY_QUServiceDetail: 0,
  maxSizeServiceQTY_QUServiceDetail: 26,
  patternServiceQTY_QUServiceDetail: "",
  minSizeServiceSaleQTY_QUServiceDetail: 0,
  maxSizeServiceSaleQTY_QUServiceDetail: 26,
  patternServiceSaleQTY_QUServiceDetail: "",
  minSizeServiceUnit_QUServiceDetail: 0,
  maxSizeServiceUnit_QUServiceDetail: 255,
  patternServiceUnit_QUServiceDetail: "",
  minSizeServiceDiscrate_QUServiceDetail: 0,
  maxSizeServiceDiscrate_QUServiceDetail: 26,
  patternServiceDiscrate_QUServiceDetail: "",
  minSizeServiceDiscAmt_QUServiceDetail: 0,
  maxSizeServiceDiscAmt_QUServiceDetail: 26,
  patternServiceDiscAmt_QUServiceDetail: "",
  minSizeServiceNetPrice_QUServiceDetail: 0,
  maxSizeServiceNetPrice_QUServiceDetail: 26,
  patternServiceNetPrice_QUServiceDetail: "",
  minSizeServicePrice_QUServiceDetail: 0,
  maxSizeServicePrice_QUServiceDetail: 26,
  patternServicePrice_QUServiceDetail: "",
  minSizeServiceSODocDate_QUServiceDetail: 0,
  maxSizeServiceSODocDate_QUServiceDetail: 20,
  patternServiceSODocDate_QUServiceDetail: "",
  minSizeServiceDiscrate0_QUServiceDetail: 0,
  maxSizeServiceDiscrate0_QUServiceDetail: 20,
  patternServiceDiscrate0_QUServiceDetail: "",
  minSizeServiceVat_QUServiceDetail: 0,
  maxSizeServiceVat_QUServiceDetail: 2,
  patternServiceVat_QUServiceDetail: "",
  minSizeServiceSODateComplete_QUServiceDetail: 0,
  maxSizeServiceSODateComplete_QUServiceDetail: 20,
  patternServiceSODateComplete_QUServiceDetail: "",
  minSizeServiceSODocNo_QUServiceDetail: 0,
  maxSizeServiceSODocNo_QUServiceDetail: 20,
  patternServiceSODocNo_QUServiceDetail: "",
  minSizeCompanyCode_QUServiceDetail: 0,
  maxSizeCompanyCode_QUServiceDetail: 20,
  patternCompanyCode_QUServiceDetail: "[0-9a-zA-Z/-]+",
  minSizeServiceNewCode_QUServiceDetail: 0,
  maxSizeServiceNewCode_QUServiceDetail: 20,
  patternServiceNewCode_QUServiceDetail: "[0-9a-zA-Z/-]+",
  minSizeServiceNewName_QUServiceDetail: 0,
  maxSizeServiceNewName_QUServiceDetail: 300,
  patternServiceNewName_QUServiceDetail: "",
  minSizeServiceItemDetail_QUServiceDetail: 0,
  maxSizeServiceItemDetail_QUServiceDetail: 9999,
  patternServiceItemDetail_QUServiceDetail: "",
  minSizeServiceMasterId_QUServiceDetail: 0,
  maxSizeServiceMasterId_QUServiceDetail: 19,
  patternServiceMasterId_QUServiceDetail: "",
  minSizeQuServiceMasterId_QUServiceDetail: 0,
  maxSizeQuServiceMasterId_QUServiceDetail: 19,
  patternQuServiceMasterId_QUServiceDetail: "",
  minSizeJobMasterId_QUServiceDetail: 0,
  maxSizeJobMasterId_QUServiceDetail: 19,
  patternJobMasterId_QUServiceDetail: "",
  minSizeJobDetialId_QUServiceDetail: 0,
  patternJobDetialId_QUServiceDetail: "",
  minSizeLaborPrice_QUServiceDetail: 0,
  maxSizeLaborPrice_QUServiceDetail: 26,
  patternLaborPrice_QUServiceDetail: "",
  minSizeProdConvFactor_SaleOrderProductDetail: 0,
  maxSizeProdConvFactor_SaleOrderProductDetail: 26,
  patternProdConvFactor_SaleOrderProductDetail: "",
  minSizeProdNetPrice_SaleOrderProductDetail: 0,
  maxSizeProdNetPrice_SaleOrderProductDetail: 26,
  patternProdNetPrice_SaleOrderProductDetail: "",
  minSizeProdLastDeliveryDocno_SaleOrderProductDetail: 0,
  maxSizeProdLastDeliveryDocno_SaleOrderProductDetail: 20,
  patternProdLastDeliveryDocno_SaleOrderProductDetail: "",
  minSizeUnitOfMeasureId2_SaleOrderProductDetail: 0,
  maxSizeUnitOfMeasureId2_SaleOrderProductDetail: 19,
  patternUnitOfMeasureId2_SaleOrderProductDetail: "",
  minSizeJobMasterId_SaleOrderProductDetail: 0,
  maxSizeJobMasterId_SaleOrderProductDetail: 19,
  patternJobMasterId_SaleOrderProductDetail: "",
  minSizeJobDetailId_SaleOrderProductDetail: 0,
  maxSizeJobDetailId_SaleOrderProductDetail: 19,
  patternJobDetailId_SaleOrderProductDetail: "",
  minSizeCustomerMasterId_SaleOrderProductMaster: 0,
  maxSizeCustomerMasterId_SaleOrderProductMaster: 19,
  patternCustomerMasterId_SaleOrderProductMaster: "",
  minSizeMemberId_SaleOrderProductMaster: 0,
  maxSizeMemberId_SaleOrderProductMaster: 19,
  patternMemberId_SaleOrderProductMaster: "",
  minSizeUstatus_MasterMore: 0,
  maxSizeUstatus_MasterMore: 5,
  patternUstatus_MasterMore: "",
  minSizeRemark4_MasterMore: 0,
  maxSizeRemark4_MasterMore: 50,
  patternRemark4_MasterMore: "",
  minSizeRemark5_MasterMore: 0,
  maxSizeRemark5_MasterMore: 50,
  patternRemark5_MasterMore: "",
  minSizeRemark6_MasterMore: 0,
  maxSizeRemark6_MasterMore: 50,
  patternRemark6_MasterMore: "",
  minSizeRemark7_MasterMore: 0,
  maxSizeRemark7_MasterMore: 50,
  patternRemark7_MasterMore: "",
  minSizeRemark8_MasterMore: 0,
  maxSizeRemark8_MasterMore: 50,
  patternRemark8_MasterMore: "",
  minSizeRemark9_MasterMore: 0,
  maxSizeRemark9_MasterMore: 50,
  patternRemark9_MasterMore: "",
  minSizeRemark10_MasterMore: 0,
  maxSizeRemark10_MasterMore: 50,
  patternRemark10_MasterMore: "",
  minSizeRemark11_MasterMore: 0,
  maxSizeRemark11_MasterMore: 50,
  patternRemark11_MasterMore: "",
  minSizeRemark12_MasterMore: 0,
  maxSizeRemark12_MasterMore: 50,
  patternRemark12_MasterMore: "",
  minSizeRemark13_MasterMore: 0,
  maxSizeRemark13_MasterMore: 50,
  patternRemark13_MasterMore: "",
  minSizeRemark14_MasterMore: 0,
  maxSizeRemark14_MasterMore: 50,
  patternRemark14_MasterMore: "",
  minSizeRemark15_MasterMore: 0,
  maxSizeRemark15_MasterMore: 50,
  patternRemark15_MasterMore: "",
  minSizeQuServiceMasterId_QUServiceMaster: 0,
  maxSizeQuServiceMasterId_QUServiceMaster: 19,
  patternQuServiceMasterId_QUServiceMaster: "",
  minSizeQuDocNo_QUServiceMaster: 0,
  maxSizeQuDocNo_QUServiceMaster: 20,
  patternQuDocNo_QUServiceMaster: "",
  minSizeQuDate_QUServiceMaster: 0,
  maxSizeQuDate_QUServiceMaster: 20,
  patternQuDate_QUServiceMaster: "",
  minSizeQuCustName_QUServiceMaster: 0,
  maxSizeQuCustName_QUServiceMaster: 150,
  patternQuCustName_QUServiceMaster: "",
  minSizeQuCustCondt_QUServiceMaster: 0,
  maxSizeQuCustCondt_QUServiceMaster: 200,
  patternQuCustCondt_QUServiceMaster: "",
  minSizeQuCustContact_QUServiceMaster: 0,
  maxSizeQuCustContact_QUServiceMaster: 150,
  patternQuCustContact_QUServiceMaster: "",
  minSizeQuCustAddress_QUServiceMaster: 0,
  maxSizeQuCustAddress_QUServiceMaster: 255,
  patternQuCustAddress_QUServiceMaster: "",
  minSizeQuCustPriceLevel_QUServiceMaster: 0,
  maxSizeQuCustPriceLevel_QUServiceMaster: 10,
  patternQuCustPriceLevel_QUServiceMaster: "",
  minSizeQuPriceTerm_QUServiceMaster: 0,
  maxSizeQuPriceTerm_QUServiceMaster: 10,
  patternQuPriceTerm_QUServiceMaster: "",
  minSizeQuTotal_QUServiceMaster: 0,
  maxSizeQuTotal_QUServiceMaster: 26,
  patternQuTotal_QUServiceMaster: "",
  minSizeQuTotalNoVat_QUServiceMaster: 0,
  maxSizeQuTotalNoVat_QUServiceMaster: 26,
  patternQuTotalNoVat_QUServiceMaster: "",
  minSizeQuDiscrate_QUServiceMaster: 0,
  maxSizeQuDiscrate_QUServiceMaster: 26,
  patternQuDiscrate_QUServiceMaster: "",
  minSizeQuDiscAmt_QUServiceMaster: 0,
  maxSizeQuDiscAmt_QUServiceMaster: 26,
  patternQuDiscAmt_QUServiceMaster: "",
  minSizeQuVatRate_QUServiceMaster: 0,
  maxSizeQuVatRate_QUServiceMaster: 26,
  patternQuVatRate_QUServiceMaster: "",
  minSizeQuVatAmt_QUServiceMaster: 0,
  maxSizeQuVatAmt_QUServiceMaster: 26,
  patternQuVatAmt_QUServiceMaster: "",
  minSizeQuNetPrice_QUServiceMaster: 0,
  maxSizeQuNetPrice_QUServiceMaster: 26,
  patternQuNetPrice_QUServiceMaster: "",
  minSizeQuRemark_QUServiceMaster: 0,
  maxSizeQuRemark_QUServiceMaster: 300,
  patternQuRemark_QUServiceMaster: "",
  minSizeQuStatus_QUServiceMaster: 0,
  maxSizeQuStatus_QUServiceMaster: 2,
  patternQuStatus_QUServiceMaster: "",
  minSizeQuLinkDocNo_QUServiceMaster: 0,
  maxSizeQuLinkDocNo_QUServiceMaster: 20,
  patternQuLinkDocNo_QUServiceMaster: "",
  minSizeQuReferPONo_QUServiceMaster: 0,
  maxSizeQuReferPONo_QUServiceMaster: 50,
  patternQuReferPONo_QUServiceMaster: "",
  minSizeCreateOn_QUServiceMaster: 0,
  maxSizeCreateOn_QUServiceMaster: 20,
  patternCreateOn_QUServiceMaster: "",
  minSizeUpdateOn_QUServiceMaster: 0,
  maxSizeUpdateOn_QUServiceMaster: 20,
  patternUpdateOn_QUServiceMaster: "",
  minSizeQudateComplete_QUServiceMaster: 0,
  maxSizeQudateComplete_QUServiceMaster: 20,
  patternQudateComplete_QUServiceMaster: "",
  minSizeCompanyCode_QUServiceMaster: 0,
  maxSizeCompanyCode_QUServiceMaster: 20,
  patternCompanyCode_QUServiceMaster: "[0-9a-zA-Z/-]+",
  minSizeVatInclude_QUServiceMaster: 0,
  maxSizeVatInclude_QUServiceMaster: 2,
  patternVatInclude_QUServiceMaster: "",
  minSizeExchangeRate_QUServiceMaster: 0,
  maxSizeExchangeRate_QUServiceMaster: 26,
  patternExchangeRate_QUServiceMaster: "",
  minSizeExchageRateDate_QUServiceMaster: 0,
  maxSizeExchageRateDate_QUServiceMaster: 20,
  patternExchageRateDate_QUServiceMaster: "",
  minSizeAstatus_QUServiceMaster: 0,
  maxSizeAstatus_QUServiceMaster: 2,
  patternAstatus_QUServiceMaster: "",
  minSizeReviseNo_QUServiceMaster: 0,
  maxSizeReviseNo_QUServiceMaster: 10,
  patternReviseNo_QUServiceMaster: "",
  minSizeCurrencyId_QUServiceMaster: 0,
  maxSizeCurrencyId_QUServiceMaster: 19,
  patternCurrencyId_QUServiceMaster: "",
  minSizeDocumentGroupId_QUServiceMaster: 0,
  maxSizeDocumentGroupId_QUServiceMaster: 19,
  patternDocumentGroupId_QUServiceMaster: "",
  minSizeCustomerMasterId_QUServiceMaster: 0,
  maxSizeCustomerMasterId_QUServiceMaster: 19,
  patternCustomerMasterId_QUServiceMaster: "",
  minSizeSaleEmpMasterId_QUServiceMaster: 0,
  maxSizeSaleEmpMasterId_QUServiceMaster: 19,
  patternSaleEmpMasterId_QUServiceMaster: "",
  minSizeDivisionId_QUServiceMaster: 0,
  maxSizeDivisionId_QUServiceMaster: 19,
  patternDivisionId_QUServiceMaster: "",
  minSizeSectionId_QUServiceMaster: 0,
  maxSizeSectionId_QUServiceMaster: 19,
  patternSectionId_QUServiceMaster: "",
  minSizeCreateByEmpId_QUServiceMaster: 0,
  maxSizeCreateByEmpId_QUServiceMaster: 19,
  patternCreateByEmpId_QUServiceMaster: "",
  minSizeUpdateByEmpId_QUServiceMaster: 0,
  maxSizeUpdateByEmpId_QUServiceMaster: 19,
  patternUpdateByEmpId_QUServiceMaster: "",
  minSizeReviseQUService_QUServiceMaster: 0,
  maxSizeReviseQUService_QUServiceMaster: 10,
  patternReviseQUService_QUServiceMaster: "",
  minSizeReferenceDocumentQUService_QUServiceMaster: 0,
  maxSizeReferenceDocumentQUService_QUServiceMaster: 20,
  patternReferenceDocumentQUService_QUServiceMaster: "",
  minSizeCusDivision_QUServiceMaster: 0,
  maxSizeCusDivision_QUServiceMaster: 10,
  patternCusDivision_QUServiceMaster: "",
  minSizeDivisionNo_QUServiceMaster: 0,
  maxSizeDivisionNo_QUServiceMaster: 20,
  patternDivisionNo_QUServiceMaster: "",
  minSizeDivisionName_QUServiceMaster: 0,
  maxSizeDivisionName_QUServiceMaster: 1000,
  patternDivisionName_QUServiceMaster: "",
  minSizeDivisionAddress_QUServiceMaster: 0,
  maxSizeDivisionAddress_QUServiceMaster: 1000,
  patternDivisionAddress_QUServiceMaster: "",
  minSizePrintStatus_QUServiceMaster: 0,
  maxSizePrintStatus_QUServiceMaster: 2,
  patternPrintStatus_QUServiceMaster: "",
  minSizeDocumentDateClose_QUServiceMaster: 0,
  maxSizeDocumentDateClose_QUServiceMaster: 20,
  patternDocumentDateClose_QUServiceMaster: "",
  minSizeUserCloseEmpId_QUServiceMaster: 0,
  maxSizeUserCloseEmpId_QUServiceMaster: 19,
  patternUserCloseEmpId_QUServiceMaster: "",
  minSizeQuPayAmt_QUServiceMaster: 0,
  maxSizeQuPayAmt_QUServiceMaster: 26,
  patternQuPayAmt_QUServiceMaster: "",
  minSizeSoBnsType_QUServiceMaster: 0,
  maxSizeSoBnsType_QUServiceMaster: 3,
  patternSoBnsType_QUServiceMaster: "",
  minSizeUserApprove_QUServiceMaster: 0,
  maxSizeUserApprove_QUServiceMaster: 20,
  patternUserApprove_QUServiceMaster: "",
  minSizeQuProductMasterId_QUProductMasterJobMaster: 0,
  maxSizeQuProductMasterId_QUProductMasterJobMaster: 19,
  patternQuProductMasterId_QUProductMasterJobMaster: "",
  minSizeJobMasterId_QUProductMasterJobMaster: 0,
  maxSizeJobMasterId_QUProductMasterJobMaster: 19,
  patternJobMasterId_QUProductMasterJobMaster: "",
  minSizeProcessMasterId_ProcessMaster: 0,
  maxSizeProcessMasterId_ProcessMaster: 19,
  patternProcessMasterId_ProcessMaster: "",
  minSizeProcessMasterCode_ProcessMaster: 0,
  maxSizeProcessMasterCode_ProcessMaster: 20,
  patternProcessMasterCode_ProcessMaster: "[0-9a-zA-Z/-]+",
  minSizeProcessMasterName_ProcessMaster: 0,
  maxSizeProcessMasterName_ProcessMaster: 255,
  patternProcessMasterName_ProcessMaster: "",
  minSizeProcessMasterStatus_ProcessMaster: 0,
  maxSizeProcessMasterStatus_ProcessMaster: 1,
  patternProcessMasterStatus_ProcessMaster: "",
  minSizeRemark_ProcessMaster: 0,
  maxSizeRemark_ProcessMaster: 255,
  patternRemark_ProcessMaster: "",
  minSizeStdTime_ProcessMaster: 0,
  maxSizeStdTime_ProcessMaster: 26,
  patternStdTime_ProcessMaster: "",
  minSizeCompanyCode_ProcessMaster: 0,
  maxSizeCompanyCode_ProcessMaster: 20,
  patternCompanyCode_ProcessMaster: "[0-9a-zA-Z/-]+",
  minSizeLcost_ProcessMaster: 0,
  maxSizeLcost_ProcessMaster: 24,
  patternLcost_ProcessMaster: "[0-9.]+",
  minSizeOhcost_ProcessMaster: 0,
  maxSizeOhcost_ProcessMaster: 24,
  patternOhcost_ProcessMaster: "[0-9.]+",
  minSizeMachineId_Machine: 0,
  maxSizeMachineId_Machine: 19,
  patternMachineId_Machine: "",
  minSizeMachineCode_Machine: 0,
  maxSizeMachineCode_Machine: 25,
  patternMachineCode_Machine: "[0-9a-zA-Z/-]+",
  minSizeMachineName_Machine: 0,
  maxSizeMachineName_Machine: 200,
  patternMachineName_Machine: "",
  minSizeKwh_Machine: 0,
  maxSizeKwh_Machine: 24,
  patternKwh_Machine: "[0-9.]+",
  minSizeCostKwh_Machine: 0,
  maxSizeCostKwh_Machine: 24,
  patternCostKwh_Machine: "[0-9.]+",
  minSizeCapacity_Machine: 0,
  maxSizeCapacity_Machine: 24,
  patternCapacity_Machine: "[0-9.]+",
  minSizeCompanyCode_Machine: 0,
  maxSizeCompanyCode_Machine: 10,
  patternCompanyCode_Machine: "[0-9a-zA-Z/-]+",
  minSizeNa_Machine: 0,
  maxSizeNa_Machine: 10,
  patternNa_Machine: "",
  minSizeDivisionId_Machine: 0,
  maxSizeDivisionId_Machine: 19,
  patternDivisionId_Machine: "",
  minSizeSectionId_Machine: 0,
  maxSizeSectionId_Machine: 19,
  patternSectionId_Machine: "",
  minSizeSaleLeadDetailId_SaleLeadDetail: 0,
  maxSizeSaleLeadDetailId_SaleLeadDetail: 19,
  patternSaleLeadDetailId_SaleLeadDetail: "",
  minSizeVisitNo_SaleLeadDetail: 0,
  maxSizeVisitNo_SaleLeadDetail: 19,
  patternVisitNo_SaleLeadDetail: "",
  minSizeMeetingDate_SaleLeadDetail: 0,
  maxSizeMeetingDate_SaleLeadDetail: 20,
  patternMeetingDate_SaleLeadDetail: "",
  minSizeMeetingTime_SaleLeadDetail: 0,
  maxSizeMeetingTime_SaleLeadDetail: 20,
  patternMeetingTime_SaleLeadDetail: "",
  minSizeDetail_SaleLeadDetail: 0,
  maxSizeDetail_SaleLeadDetail: 250,
  patternDetail_SaleLeadDetail: "",
  minSizePercentSuccess_SaleLeadDetail: 0,
  maxSizePercentSuccess_SaleLeadDetail: 10,
  patternPercentSuccess_SaleLeadDetail: "",
  minSizeCompanyCode_SaleLeadDetail: 0,
  maxSizeCompanyCode_SaleLeadDetail: 20,
  patternCompanyCode_SaleLeadDetail: "[0-9a-zA-Z/-]+",
  minSizeSaleLeadId_SaleLeadDetail: 0,
  maxSizeSaleLeadId_SaleLeadDetail: 19,
  patternSaleLeadId_SaleLeadDetail: "",
  minSizeSaleLeadId_SaleLead: 0,
  maxSizeSaleLeadId_SaleLead: 19,
  patternSaleLeadId_SaleLead: "",
  minSizeLeadCode_SaleLead: 0,
  maxSizeLeadCode_SaleLead: 20,
  patternLeadCode_SaleLead: "[0-9a-zA-Z/-]+",
  minSizeLeadDate_SaleLead: 0,
  maxSizeLeadDate_SaleLead: 20,
  patternLeadDate_SaleLead: "",
  minSizeLeadType_SaleLead: 0,
  maxSizeLeadType_SaleLead: 20,
  patternLeadType_SaleLead: "",
  minSizeLeadName_SaleLead: 0,
  maxSizeLeadName_SaleLead: 100,
  patternLeadName_SaleLead: "",
  minSizeLeadAddress_SaleLead: 0,
  maxSizeLeadAddress_SaleLead: 200,
  patternLeadAddress_SaleLead: "",
  minSizeLeadLocation_SaleLead: 0,
  maxSizeLeadLocation_SaleLead: 100,
  patternLeadLocation_SaleLead: "",
  minSizeLeadTelNo_SaleLead: 0,
  maxSizeLeadTelNo_SaleLead: 50,
  patternLeadTelNo_SaleLead: "",
  minSizeContactName_SaleLead: 0,
  maxSizeContactName_SaleLead: 50,
  patternContactName_SaleLead: "",
  minSizeContactNo_SaleLead: 0,
  maxSizeContactNo_SaleLead: 50,
  patternContactNo_SaleLead: "",
  minSizeEmail_SaleLead: 0,
  maxSizeEmail_SaleLead: 50,
  patternEmail_SaleLead: "",
  minSizeDetail_SaleLead: 0,
  maxSizeDetail_SaleLead: 300,
  patternDetail_SaleLead: "",
  minSizeEmployeeMasterId_SaleLead: 0,
  maxSizeEmployeeMasterId_SaleLead: 19,
  patternEmployeeMasterId_SaleLead: "",
  minSizeCompanyCode_SaleLead: 0,
  maxSizeCompanyCode_SaleLead: 20,
  patternCompanyCode_SaleLead: "[0-9a-zA-Z/-]+",
  minSizePercentSuccess_SaleLead: 0,
  maxSizePercentSuccess_SaleLead: 10,
  patternPercentSuccess_SaleLead: "",
  minSizeMeeting_SaleLead: 0,
  maxSizeMeeting_SaleLead: 2,
  patternMeeting_SaleLead: "",
  minSizeMeetingDate_SaleLead: 0,
  maxSizeMeetingDate_SaleLead: 20,
  patternMeetingDate_SaleLead: "",
  minSizeLeadStatus_SaleLead: 0,
  maxSizeLeadStatus_SaleLead: 2,
  patternLeadStatus_SaleLead: "",
  minSizeMeetingTime_SaleLead: 0,
  maxSizeMeetingTime_SaleLead: 20,
  patternMeetingTime_SaleLead: "",
  minSizePettyCashMasterId_PettyCashMaster: 0,
  maxSizePettyCashMasterId_PettyCashMaster: 19,
  patternPettyCashMasterId_PettyCashMaster: "",
  minSizePettyCashDocNo_PettyCashMaster: 0,
  maxSizePettyCashDocNo_PettyCashMaster: 20,
  patternPettyCashDocNo_PettyCashMaster: "",
  minSizePettyCashDocDate_PettyCashMaster: 0,
  maxSizePettyCashDocDate_PettyCashMaster: 20,
  patternPettyCashDocDate_PettyCashMaster: "",
  minSizeDocType_PettyCashMaster: 0,
  maxSizeDocType_PettyCashMaster: 5,
  patternDocType_PettyCashMaster: "",
  minSizePettyCashVatReportTransaction_PettyCashMaster: 0,
  maxSizePettyCashVatReportTransaction_PettyCashMaster: 2,
  patternPettyCashVatReportTransaction_PettyCashMaster: "",
  minSizePettyCashNameInVat_PettyCashMaster: 0,
  maxSizePettyCashNameInVat_PettyCashMaster: 255,
  patternPettyCashNameInVat_PettyCashMaster: "",
  minSizePettyCashDateInVatReport_PettyCashMaster: 0,
  maxSizePettyCashDateInVatReport_PettyCashMaster: 20,
  patternPettyCashDateInVatReport_PettyCashMaster: "",
  minSizePettyCashTotal_PettyCashMaster: 0,
  maxSizePettyCashTotal_PettyCashMaster: 26,
  patternPettyCashTotal_PettyCashMaster: "",
  minSizePettyCashWitholdingTax_PettyCashMaster: 0,
  maxSizePettyCashWitholdingTax_PettyCashMaster: 26,
  patternPettyCashWitholdingTax_PettyCashMaster: "",
  minSizePettyCashDeDuctDiscount_PettyCashMaster: 0,
  maxSizePettyCashDeDuctDiscount_PettyCashMaster: 26,
  patternPettyCashDeDuctDiscount_PettyCashMaster: "",
  minSizePettyCashPlusDiscount_PettyCashMaster: 0,
  maxSizePettyCashPlusDiscount_PettyCashMaster: 26,
  patternPettyCashPlusDiscount_PettyCashMaster: "",
  minSizePettyCashNetAmt_PettyCashMaster: 0,
  maxSizePettyCashNetAmt_PettyCashMaster: 26,
  patternPettyCashNetAmt_PettyCashMaster: "",
  minSizePettyCashRemark_PettyCashMaster: 0,
  maxSizePettyCashRemark_PettyCashMaster: 9999,
  patternPettyCashRemark_PettyCashMaster: "",
  minSizePettyCashStatus_PettyCashMaster: 0,
  maxSizePettyCashStatus_PettyCashMaster: 2,
  patternPettyCashStatus_PettyCashMaster: "",
  minSizePettyCashLinkDocNo_PettyCashMaster: 0,
  maxSizePettyCashLinkDocNo_PettyCashMaster: 50,
  patternPettyCashLinkDocNo_PettyCashMaster: "",
  minSizePettyCashPaymentType_PettyCashMaster: 0,
  maxSizePettyCashPaymentType_PettyCashMaster: 10,
  patternPettyCashPaymentType_PettyCashMaster: "",
  minSizePayVoucherChqNo_PettyCashMaster: 0,
  maxSizePayVoucherChqNo_PettyCashMaster: 20,
  patternPayVoucherChqNo_PettyCashMaster: "",
  minSizePettyCashPayAmt_PettyCashMaster: 0,
  maxSizePettyCashPayAmt_PettyCashMaster: 26,
  patternPettyCashPayAmt_PettyCashMaster: "",
  minSizeCharge_PettyCashMaster: 0,
  maxSizeCharge_PettyCashMaster: 26,
  patternCharge_PettyCashMaster: "",
  minSizePettyCashBankRemark_PettyCashMaster: 0,
  maxSizePettyCashBankRemark_PettyCashMaster: 500,
  patternPettyCashBankRemark_PettyCashMaster: "",
  minSizePettyCashChqNo_PettyCashMaster: 0,
  maxSizePettyCashChqNo_PettyCashMaster: 20,
  patternPettyCashChqNo_PettyCashMaster: "",
  minSizePettyCashChqDate_PettyCashMaster: 0,
  maxSizePettyCashChqDate_PettyCashMaster: 20,
  patternPettyCashChqDate_PettyCashMaster: "",
  minSizeCreateOn_PettyCashMaster: 0,
  maxSizeCreateOn_PettyCashMaster: 20,
  patternCreateOn_PettyCashMaster: "",
  minSizeUpdateOn_PettyCashMaster: 0,
  maxSizeUpdateOn_PettyCashMaster: 20,
  patternUpdateOn_PettyCashMaster: "",
  minSizeVoucherNo_PettyCashMaster: 0,
  maxSizeVoucherNo_PettyCashMaster: 20,
  patternVoucherNo_PettyCashMaster: "",
  minSizeAccountCode_PettyCashMaster: 0,
  maxSizeAccountCode_PettyCashMaster: 20,
  patternAccountCode_PettyCashMaster: "[0-9a-zA-Z/-]+",
  minSizeDepositAccCode_PettyCashMaster: 0,
  maxSizeDepositAccCode_PettyCashMaster: 20,
  patternDepositAccCode_PettyCashMaster: "[0-9a-zA-Z/-]+",
  minSizeCompanyCode_PettyCashMaster: 0,
  maxSizeCompanyCode_PettyCashMaster: 20,
  patternCompanyCode_PettyCashMaster: "[0-9a-zA-Z/-]+",
  minSizeAstatus_PettyCashMaster: 0,
  maxSizeAstatus_PettyCashMaster: 2,
  patternAstatus_PettyCashMaster: "",
  minSizeSuppCode_PettyCashMaster: 0,
  maxSizeSuppCode_PettyCashMaster: 20,
  patternSuppCode_PettyCashMaster: "[0-9a-zA-Z/-]+",
  minSizeSuppName_PettyCashMaster: 0,
  maxSizeSuppName_PettyCashMaster: 255,
  patternSuppName_PettyCashMaster: "",
  minSizeReceiveeServiceCode_PettyCashMaster: 0,
  maxSizeReceiveeServiceCode_PettyCashMaster: 20,
  patternReceiveeServiceCode_PettyCashMaster: "[0-9a-zA-Z/-]+",
  minSizeDivisionNo_PettyCashMaster: 0,
  maxSizeDivisionNo_PettyCashMaster: 10,
  patternDivisionNo_PettyCashMaster: "",
  minSizeDivisionName_PettyCashMaster: 0,
  maxSizeDivisionName_PettyCashMaster: 50,
  patternDivisionName_PettyCashMaster: "",
  minSizeDivisionAddress_PettyCashMaster: 0,
  maxSizeDivisionAddress_PettyCashMaster: 255,
  patternDivisionAddress_PettyCashMaster: "",
  minSizeTaxIdNo_PettyCashMaster: 0,
  maxSizeTaxIdNo_PettyCashMaster: 50,
  patternTaxIdNo_PettyCashMaster: "",
  minSizeDocumentGroupId_PettyCashMaster: 0,
  maxSizeDocumentGroupId_PettyCashMaster: 19,
  patternDocumentGroupId_PettyCashMaster: "",
  minSizeEmployeeMasterId_PettyCashMaster: 0,
  maxSizeEmployeeMasterId_PettyCashMaster: 19,
  patternEmployeeMasterId_PettyCashMaster: "",
  minSizeDivisionId_PettyCashMaster: 0,
  maxSizeDivisionId_PettyCashMaster: 19,
  patternDivisionId_PettyCashMaster: "",
  minSizeSectionId_PettyCashMaster: 0,
  maxSizeSectionId_PettyCashMaster: 19,
  patternSectionId_PettyCashMaster: "",
  minSizeCreateByEmpId_PettyCashMaster: 0,
  maxSizeCreateByEmpId_PettyCashMaster: 19,
  patternCreateByEmpId_PettyCashMaster: "",
  minSizeUpdateByEmpId_PettyCashMaster: 0,
  maxSizeUpdateByEmpId_PettyCashMaster: 19,
  patternUpdateByEmpId_PettyCashMaster: "",
  minSizeMasterStatus_PettyCashMaster: 0,
  maxSizeMasterStatus_PettyCashMaster: 1,
  patternMasterStatus_PettyCashMaster: "",
  minSizeMasterDescription_PettyCashMaster: 0,
  maxSizeMasterDescription_PettyCashMaster: 255,
  patternMasterDescription_PettyCashMaster: "",
  minSizeAudited_PettyCashMaster: 0,
  maxSizeAudited_PettyCashMaster: 2,
  patternAudited_PettyCashMaster: "",
  minSizeUtilitiesExpense_PettyCashMaster: 0,
  maxSizeUtilitiesExpense_PettyCashMaster: 2,
  patternUtilitiesExpense_PettyCashMaster: "",
  minSizeContractNo_PettyCashMaster: 0,
  maxSizeContractNo_PettyCashMaster: 20,
  patternContractNo_PettyCashMaster: "",
  minSizeDivisionEmpId_PettyCashMaster: 0,
  maxSizeDivisionEmpId_PettyCashMaster: 19,
  patternDivisionEmpId_PettyCashMaster: "",
  minSizeSectionEmpId_PettyCashMaster: 0,
  maxSizeSectionEmpId_PettyCashMaster: 19,
  patternSectionEmpId_PettyCashMaster: "",
  minSizeDivisionSuppId_PettyCashMaster: 0,
  maxSizeDivisionSuppId_PettyCashMaster: 19,
  patternDivisionSuppId_PettyCashMaster: "",
  minSizePrintStatus_PettyCashMaster: 0,
  maxSizePrintStatus_PettyCashMaster: 2,
  patternPrintStatus_PettyCashMaster: "",
  minSizeExchangeRateDate_PettyCashMaster: 0,
  maxSizeExchangeRateDate_PettyCashMaster: 20,
  patternExchangeRateDate_PettyCashMaster: "",
  minSizeExchangeRate_PettyCashMaster: 0,
  maxSizeExchangeRate_PettyCashMaster: 26,
  patternExchangeRate_PettyCashMaster: "",
  minSizeCurrencyId_PettyCashMaster: 0,
  maxSizeCurrencyId_PettyCashMaster: 19,
  patternCurrencyId_PettyCashMaster: "",
  minSizePettyCashType_PettyCashMaster: 0,
  maxSizePettyCashType_PettyCashMaster: 20,
  patternPettyCashType_PettyCashMaster: "",
  minSizePettyCashCode_PettyCashMaster: 0,
  maxSizePettyCashCode_PettyCashMaster: 20,
  patternPettyCashCode_PettyCashMaster: "[0-9a-zA-Z/-]+",
  minSizePettyCashName_PettyCashMaster: 0,
  maxSizePettyCashName_PettyCashMaster: 255,
  patternPettyCashName_PettyCashMaster: "",
  minSizePettyCashAmt_PettyCashMaster: 0,
  maxSizePettyCashAmt_PettyCashMaster: 26,
  patternPettyCashAmt_PettyCashMaster: "",
  minSizePcAccountCode_PettyCashMaster: 0,
  maxSizePcAccountCode_PettyCashMaster: 20,
  patternPcAccountCode_PettyCashMaster: "[0-9a-zA-Z/-]+",
  minSizePayVoucherChqNoTwo_PettyCashMaster: 0,
  maxSizePayVoucherChqNoTwo_PettyCashMaster: 20,
  patternPayVoucherChqNoTwo_PettyCashMaster: "",
  minSizePettyCashChqNoTwo_PettyCashMaster: 0,
  maxSizePettyCashChqNoTwo_PettyCashMaster: 20,
  patternPettyCashChqNoTwo_PettyCashMaster: "",
  minSizePettyCashChqDateTwo_PettyCashMaster: 0,
  maxSizePettyCashChqDateTwo_PettyCashMaster: 20,
  patternPettyCashChqDateTwo_PettyCashMaster: "",
  minSizePayChqAmtTwo_PettyCashMaster: 0,
  maxSizePayChqAmtTwo_PettyCashMaster: 26,
  patternPayChqAmtTwo_PettyCashMaster: "",
  minSizeChqDepositBookIdTwo_PettyCashMaster: 0,
  maxSizeChqDepositBookIdTwo_PettyCashMaster: 19,
  patternChqDepositBookIdTwo_PettyCashMaster: "",
  minSizeWitholdingTaxType_PettyCashMaster: 0,
  maxSizeWitholdingTaxType_PettyCashMaster: 2,
  patternWitholdingTaxType_PettyCashMaster: "",
  minSizePayBankAmt_PettyCashMaster: 0,
  maxSizePayBankAmt_PettyCashMaster: 26,
  patternPayBankAmt_PettyCashMaster: "",
  minSizePayChqAmt_PettyCashMaster: 0,
  maxSizePayChqAmt_PettyCashMaster: 26,
  patternPayChqAmt_PettyCashMaster: "",
  minSizeChqDepositBookId_PettyCashMaster: 0,
  maxSizeChqDepositBookId_PettyCashMaster: 19,
  patternChqDepositBookId_PettyCashMaster: "",
  minSizeBillingPaymentMasterId_BillingPaymentMaster: 0,
  maxSizeBillingPaymentMasterId_BillingPaymentMaster: 19,
  patternBillingPaymentMasterId_BillingPaymentMaster: "",
  minSizeBillPayDocNo_BillingPaymentMaster: 0,
  maxSizeBillPayDocNo_BillingPaymentMaster: 20,
  patternBillPayDocNo_BillingPaymentMaster: "",
  minSizeBillPayDocDate_BillingPaymentMaster: 0,
  maxSizeBillPayDocDate_BillingPaymentMaster: 20,
  patternBillPayDocDate_BillingPaymentMaster: "",
  minSizeBillPayDueDate_BillingPaymentMaster: 0,
  maxSizeBillPayDueDate_BillingPaymentMaster: 20,
  patternBillPayDueDate_BillingPaymentMaster: "",
  minSizeBillPayTotal_BillingPaymentMaster: 0,
  maxSizeBillPayTotal_BillingPaymentMaster: 26,
  patternBillPayTotal_BillingPaymentMaster: "",
  minSizeBillPayDeDuctDepositNo_BillingPaymentMaster: 0,
  maxSizeBillPayDeDuctDepositNo_BillingPaymentMaster: 20,
  patternBillPayDeDuctDepositNo_BillingPaymentMaster: "",
  minSizeBillPayDeductDepositAmt_BillingPaymentMaster: 0,
  maxSizeBillPayDeductDepositAmt_BillingPaymentMaster: 26,
  patternBillPayDeductDepositAmt_BillingPaymentMaster: "",
  minSizeBillPayNetAmt_BillingPaymentMaster: 0,
  maxSizeBillPayNetAmt_BillingPaymentMaster: 26,
  patternBillPayNetAmt_BillingPaymentMaster: "",
  minSizeBillPayTotalPay_BillingPaymentMaster: 0,
  maxSizeBillPayTotalPay_BillingPaymentMaster: 26,
  patternBillPayTotalPay_BillingPaymentMaster: "",
  minSizeBillPayRemark_BillingPaymentMaster: 0,
  maxSizeBillPayRemark_BillingPaymentMaster: 150,
  patternBillPayRemark_BillingPaymentMaster: "",
  minSizeBillPayStatus_BillingPaymentMaster: 0,
  maxSizeBillPayStatus_BillingPaymentMaster: 2,
  patternBillPayStatus_BillingPaymentMaster: "",
  minSizeBillPayLinkDocNo_BillingPaymentMaster: 0,
  maxSizeBillPayLinkDocNo_BillingPaymentMaster: 20,
  patternBillPayLinkDocNo_BillingPaymentMaster: "",
  minSizeCreateOn_BillingPaymentMaster: 0,
  maxSizeCreateOn_BillingPaymentMaster: 20,
  patternCreateOn_BillingPaymentMaster: "",
  minSizeUpdateOn_BillingPaymentMaster: 0,
  maxSizeUpdateOn_BillingPaymentMaster: 20,
  patternUpdateOn_BillingPaymentMaster: "",
  minSizeCompanyCode_BillingPaymentMaster: 0,
  maxSizeCompanyCode_BillingPaymentMaster: 20,
  patternCompanyCode_BillingPaymentMaster: "[0-9a-zA-Z/-]+",
  minSizeExchangeRate_BillingPaymentMaster: 0,
  maxSizeExchangeRate_BillingPaymentMaster: 26,
  patternExchangeRate_BillingPaymentMaster: "",
  minSizeExchagerateDate_BillingPaymentMaster: 0,
  maxSizeExchagerateDate_BillingPaymentMaster: 20,
  patternExchagerateDate_BillingPaymentMaster: "",
  minSizeAstatus_BillingPaymentMaster: 0,
  maxSizeAstatus_BillingPaymentMaster: 2,
  patternAstatus_BillingPaymentMaster: "",
  minSizeReviseNo_BillingPaymentMaster: 0,
  maxSizeReviseNo_BillingPaymentMaster: 10,
  patternReviseNo_BillingPaymentMaster: "",
  minSizeBillingPaymentVoucherCode_BillingPaymentMaster: 0,
  maxSizeBillingPaymentVoucherCode_BillingPaymentMaster: 20,
  patternBillingPaymentVoucherCode_BillingPaymentMaster: "[0-9a-zA-Z/-]+",
  minSizeBillingPayCheckNameInVat_BillingPaymentMaster: 0,
  maxSizeBillingPayCheckNameInVat_BillingPaymentMaster: 2,
  patternBillingPayCheckNameInVat_BillingPaymentMaster: "",
  minSizeRefer_BillingPaymentMaster: 0,
  maxSizeRefer_BillingPaymentMaster: 2,
  patternRefer_BillingPaymentMaster: "",
  minSizeBillTaxAmt_BillingPaymentMaster: 0,
  maxSizeBillTaxAmt_BillingPaymentMaster: 26,
  patternBillTaxAmt_BillingPaymentMaster: "",
  minSizeNameInVat_BillingPaymentMaster: 0,
  maxSizeNameInVat_BillingPaymentMaster: 200,
  patternNameInVat_BillingPaymentMaster: "",
  minSizeWithHoldingTax_BillingPaymentMaster: 0,
  maxSizeWithHoldingTax_BillingPaymentMaster: 26,
  patternWithHoldingTax_BillingPaymentMaster: "",
  minSizeDeductDiscount_BillingPaymentMaster: 0,
  maxSizeDeductDiscount_BillingPaymentMaster: 26,
  patternDeductDiscount_BillingPaymentMaster: "",
  minSizePlusDiscount_BillingPaymentMaster: 0,
  maxSizePlusDiscount_BillingPaymentMaster: 26,
  patternPlusDiscount_BillingPaymentMaster: "",
  minSizeDivisionNo_BillingPaymentMaster: 0,
  maxSizeDivisionNo_BillingPaymentMaster: 10,
  patternDivisionNo_BillingPaymentMaster: "",
  minSizeDivisionName_BillingPaymentMaster: 0,
  maxSizeDivisionName_BillingPaymentMaster: 50,
  patternDivisionName_BillingPaymentMaster: "",
  minSizeDivisionAddress_BillingPaymentMaster: 0,
  maxSizeDivisionAddress_BillingPaymentMaster: 255,
  patternDivisionAddress_BillingPaymentMaster: "",
  minSizeTaxIdNo_BillingPaymentMaster: 0,
  maxSizeTaxIdNo_BillingPaymentMaster: 50,
  patternTaxIdNo_BillingPaymentMaster: "",
  minSizeCurrencyId_BillingPaymentMaster: 0,
  maxSizeCurrencyId_BillingPaymentMaster: 19,
  patternCurrencyId_BillingPaymentMaster: "",
  minSizeBillPayDocGroup_BillingPaymentMaster: 0,
  maxSizeBillPayDocGroup_BillingPaymentMaster: 19,
  patternBillPayDocGroup_BillingPaymentMaster: "",
  minSizeBillPaySuppCode_BillingPaymentMaster: 0,
  maxSizeBillPaySuppCode_BillingPaymentMaster: 19,
  patternBillPaySuppCode_BillingPaymentMaster: "[0-9a-zA-Z/-]+",
  minSizeBillPayProdReceivingOfficer_BillingPaymentMaster: 0,
  maxSizeBillPayProdReceivingOfficer_BillingPaymentMaster: 19,
  patternBillPayProdReceivingOfficer_BillingPaymentMaster: "",
  minSizeBillPayDivision_BillingPaymentMaster: 0,
  maxSizeBillPayDivision_BillingPaymentMaster: 19,
  patternBillPayDivision_BillingPaymentMaster: "",
  minSizeBillPaySection_BillingPaymentMaster: 0,
  maxSizeBillPaySection_BillingPaymentMaster: 19,
  patternBillPaySection_BillingPaymentMaster: "",
  minSizeBillPayJobCode_BillingPaymentMaster: 0,
  maxSizeBillPayJobCode_BillingPaymentMaster: 19,
  patternBillPayJobCode_BillingPaymentMaster: "[0-9a-zA-Z/-]+",
  minSizeCreateBy_BillingPaymentMaster: 0,
  maxSizeCreateBy_BillingPaymentMaster: 19,
  patternCreateBy_BillingPaymentMaster: "",
  minSizeUpdateBy_BillingPaymentMaster: 0,
  maxSizeUpdateBy_BillingPaymentMaster: 19,
  patternUpdateBy_BillingPaymentMaster: "",
  minSizeMasterStatus_BillingPaymentMaster: 0,
  maxSizeMasterStatus_BillingPaymentMaster: 1,
  patternMasterStatus_BillingPaymentMaster: "",
  minSizeMasterDescription_BillingPaymentMaster: 0,
  maxSizeMasterDescription_BillingPaymentMaster: 255,
  patternMasterDescription_BillingPaymentMaster: "",
  minSizeCreditTerm_BillingPaymentMaster: 0,
  maxSizeCreditTerm_BillingPaymentMaster: 10,
  patternCreditTerm_BillingPaymentMaster: "",
  minSizeCheckApproveDocument_BillingPaymentMaster: 0,
  maxSizeCheckApproveDocument_BillingPaymentMaster: 1,
  patternCheckApproveDocument_BillingPaymentMaster: "",
  minSizeBillPayDescription_BillingPaymentMaster: 0,
  maxSizeBillPayDescription_BillingPaymentMaster: 255,
  patternBillPayDescription_BillingPaymentMaster: "",
  minSizeUtilitiesExpense_BillingPaymentMaster: 0,
  maxSizeUtilitiesExpense_BillingPaymentMaster: 1,
  patternUtilitiesExpense_BillingPaymentMaster: "",
  minSizeContractNo_BillingPaymentMaster: 0,
  maxSizeContractNo_BillingPaymentMaster: 20,
  patternContractNo_BillingPaymentMaster: "",
  minSizeDivisionEmpId_BillingPaymentMaster: 0,
  maxSizeDivisionEmpId_BillingPaymentMaster: 19,
  patternDivisionEmpId_BillingPaymentMaster: "",
  minSizeSectionEmpId_BillingPaymentMaster: 0,
  maxSizeSectionEmpId_BillingPaymentMaster: 19,
  patternSectionEmpId_BillingPaymentMaster: "",
  minSizeDivisionSuppId_BillingPaymentMaster: 0,
  maxSizeDivisionSuppId_BillingPaymentMaster: 19,
  patternDivisionSuppId_BillingPaymentMaster: "",
  minSizeInvoiceCustomer_BillingPaymentMaster: 0,
  maxSizeInvoiceCustomer_BillingPaymentMaster: 50,
  patternInvoiceCustomer_BillingPaymentMaster: "",
  minSizePrintStatus_BillingPaymentMaster: 0,
  maxSizePrintStatus_BillingPaymentMaster: 2,
  patternPrintStatus_BillingPaymentMaster: "",
  minSizeSuppName_BillingPaymentMaster: 0,
  maxSizeSuppName_BillingPaymentMaster: 255,
  patternSuppName_BillingPaymentMaster: "",
  minSizeDepositReceivInvoiceMasterId_DepositReceiveInvoiceMaster: 0,
  maxSizeDepositReceivInvoiceMasterId_DepositReceiveInvoiceMaster: 19,
  patternDepositReceivInvoiceMasterId_DepositReceiveInvoiceMaster: "",
  minSizeDepositReceiveDocNo_DepositReceiveInvoiceMaster: 0,
  maxSizeDepositReceiveDocNo_DepositReceiveInvoiceMaster: 20,
  patternDepositReceiveDocNo_DepositReceiveInvoiceMaster: "",
  minSizeDepositReceiveDocDate_DepositReceiveInvoiceMaster: 0,
  maxSizeDepositReceiveDocDate_DepositReceiveInvoiceMaster: 20,
  patternDepositReceiveDocDate_DepositReceiveInvoiceMaster: "",
  minSizeDepositReceiveInvoiceNo_DepositReceiveInvoiceMaster: 0,
  maxSizeDepositReceiveInvoiceNo_DepositReceiveInvoiceMaster: 20,
  patternDepositReceiveInvoiceNo_DepositReceiveInvoiceMaster: "",
  minSizeDepositReceiveInvoiceDate_DepositReceiveInvoiceMaster: 0,
  maxSizeDepositReceiveInvoiceDate_DepositReceiveInvoiceMaster: 20,
  patternDepositReceiveInvoiceDate_DepositReceiveInvoiceMaster: "",
  minSizeDepositReceiveTotal_DepositReceiveInvoiceMaster: 0,
  maxSizeDepositReceiveTotal_DepositReceiveInvoiceMaster: 26,
  patternDepositReceiveTotal_DepositReceiveInvoiceMaster: "",
  minSizeDepositReceiveTotalNoVat_DepositReceiveInvoiceMaster: 0,
  maxSizeDepositReceiveTotalNoVat_DepositReceiveInvoiceMaster: 26,
  patternDepositReceiveTotalNoVat_DepositReceiveInvoiceMaster: "",
  minSizeDepositReceiveDiscRate_DepositReceiveInvoiceMaster: 0,
  maxSizeDepositReceiveDiscRate_DepositReceiveInvoiceMaster: 26,
  patternDepositReceiveDiscRate_DepositReceiveInvoiceMaster: "",
  minSizeDepositReceiveDiscAmt_DepositReceiveInvoiceMaster: 0,
  maxSizeDepositReceiveDiscAmt_DepositReceiveInvoiceMaster: 26,
  patternDepositReceiveDiscAmt_DepositReceiveInvoiceMaster: "",
  minSizeDepositReceiveVatRate_DepositReceiveInvoiceMaster: 0,
  maxSizeDepositReceiveVatRate_DepositReceiveInvoiceMaster: 26,
  patternDepositReceiveVatRate_DepositReceiveInvoiceMaster: "",
  minSizeDepositReceiveVatAmt_DepositReceiveInvoiceMaster: 0,
  maxSizeDepositReceiveVatAmt_DepositReceiveInvoiceMaster: 26,
  patternDepositReceiveVatAmt_DepositReceiveInvoiceMaster: "",
  minSizeReceiveAmt_DepositReceiveInvoiceMaster: 0,
  maxSizeReceiveAmt_DepositReceiveInvoiceMaster: 26,
  patternReceiveAmt_DepositReceiveInvoiceMaster: "",
  minSizeDepositReceiveNetAmt_DepositReceiveInvoiceMaster: 0,
  maxSizeDepositReceiveNetAmt_DepositReceiveInvoiceMaster: 26,
  patternDepositReceiveNetAmt_DepositReceiveInvoiceMaster: "",
  minSizeCharge_DepositReceiveInvoiceMaster: 0,
  maxSizeCharge_DepositReceiveInvoiceMaster: 26,
  patternCharge_DepositReceiveInvoiceMaster: "",
  minSizeDepositReceiveRemark_DepositReceiveInvoiceMaster: 0,
  maxSizeDepositReceiveRemark_DepositReceiveInvoiceMaster: 1000,
  patternDepositReceiveRemark_DepositReceiveInvoiceMaster: "",
  minSizeDepositReceiveStatus_DepositReceiveInvoiceMaster: 0,
  maxSizeDepositReceiveStatus_DepositReceiveInvoiceMaster: 2,
  patternDepositReceiveStatus_DepositReceiveInvoiceMaster: "",
  minSizeDepositReceiveLinkDocNo_DepositReceiveInvoiceMaster: 0,
  maxSizeDepositReceiveLinkDocNo_DepositReceiveInvoiceMaster: 20,
  patternDepositReceiveLinkDocNo_DepositReceiveInvoiceMaster: "",
  minSizeDepositReceivePaymentType_DepositReceiveInvoiceMaster: 0,
  maxSizeDepositReceivePaymentType_DepositReceiveInvoiceMaster: 5,
  patternDepositReceivePaymentType_DepositReceiveInvoiceMaster: "",
  minSizeDepositReceiveReceiveAmt_DepositReceiveInvoiceMaster: 0,
  maxSizeDepositReceiveReceiveAmt_DepositReceiveInvoiceMaster: 26,
  patternDepositReceiveReceiveAmt_DepositReceiveInvoiceMaster: "",
  minSizeDepositReceiveBankRemark_DepositReceiveInvoiceMaster: 0,
  maxSizeDepositReceiveBankRemark_DepositReceiveInvoiceMaster: 250,
  patternDepositReceiveBankRemark_DepositReceiveInvoiceMaster: "",
  minSizeDepositReceiveChqNo_DepositReceiveInvoiceMaster: 0,
  maxSizeDepositReceiveChqNo_DepositReceiveInvoiceMaster: 255,
  patternDepositReceiveChqNo_DepositReceiveInvoiceMaster: "",
  minSizeDepositReceiveChqDate_DepositReceiveInvoiceMaster: 0,
  maxSizeDepositReceiveChqDate_DepositReceiveInvoiceMaster: 20,
  patternDepositReceiveChqDate_DepositReceiveInvoiceMaster: "",
  minSizeCreateOn_DepositReceiveInvoiceMaster: 0,
  maxSizeCreateOn_DepositReceiveInvoiceMaster: 20,
  patternCreateOn_DepositReceiveInvoiceMaster: "",
  minSizeUpdateOn_DepositReceiveInvoiceMaster: 0,
  maxSizeUpdateOn_DepositReceiveInvoiceMaster: 20,
  patternUpdateOn_DepositReceiveInvoiceMaster: "",
  minSizeVoucherNo_DepositReceiveInvoiceMaster: 0,
  maxSizeVoucherNo_DepositReceiveInvoiceMaster: 20,
  patternVoucherNo_DepositReceiveInvoiceMaster: "",
  minSizeDepositReceiveReferDoc_DepositReceiveInvoiceMaster: 0,
  maxSizeDepositReceiveReferDoc_DepositReceiveInvoiceMaster: 20,
  patternDepositReceiveReferDoc_DepositReceiveInvoiceMaster: "",
  minSizeCompanyCode_DepositReceiveInvoiceMaster: 0,
  maxSizeCompanyCode_DepositReceiveInvoiceMaster: 20,
  patternCompanyCode_DepositReceiveInvoiceMaster: "[0-9a-zA-Z/-]+",
  minSizeVatInclude_DepositReceiveInvoiceMaster: 0,
  maxSizeVatInclude_DepositReceiveInvoiceMaster: 2,
  patternVatInclude_DepositReceiveInvoiceMaster: "",
  minSizeChequeReceiveDocNo_DepositReceiveInvoiceMaster: 0,
  maxSizeChequeReceiveDocNo_DepositReceiveInvoiceMaster: 20,
  patternChequeReceiveDocNo_DepositReceiveInvoiceMaster: "",
  minSizeExchangeRate_DepositReceiveInvoiceMaster: 0,
  maxSizeExchangeRate_DepositReceiveInvoiceMaster: 26,
  patternExchangeRate_DepositReceiveInvoiceMaster: "",
  minSizeExchageRateDate_DepositReceiveInvoiceMaster: 0,
  maxSizeExchageRateDate_DepositReceiveInvoiceMaster: 20,
  patternExchageRateDate_DepositReceiveInvoiceMaster: "",
  minSizeAstatus_DepositReceiveInvoiceMaster: 0,
  maxSizeAstatus_DepositReceiveInvoiceMaster: 2,
  patternAstatus_DepositReceiveInvoiceMaster: "",
  minSizeReviseNo_DepositReceiveInvoiceMaster: 0,
  maxSizeReviseNo_DepositReceiveInvoiceMaster: 10,
  patternReviseNo_DepositReceiveInvoiceMaster: "",
  minSizeDivisionBank_DepositReceiveInvoiceMaster: 0,
  maxSizeDivisionBank_DepositReceiveInvoiceMaster: 100,
  patternDivisionBank_DepositReceiveInvoiceMaster: "",
  minSizeCustBank_DepositReceiveInvoiceMaster: 0,
  maxSizeCustBank_DepositReceiveInvoiceMaster: 100,
  patternCustBank_DepositReceiveInvoiceMaster: "",
  minSizeDivisionNo_DepositReceiveInvoiceMaster: 0,
  maxSizeDivisionNo_DepositReceiveInvoiceMaster: 10,
  patternDivisionNo_DepositReceiveInvoiceMaster: "",
  minSizeDivisionName_DepositReceiveInvoiceMaster: 0,
  maxSizeDivisionName_DepositReceiveInvoiceMaster: 50,
  patternDivisionName_DepositReceiveInvoiceMaster: "",
  minSizeDivisionAddress_DepositReceiveInvoiceMaster: 0,
  maxSizeDivisionAddress_DepositReceiveInvoiceMaster: 255,
  patternDivisionAddress_DepositReceiveInvoiceMaster: "",
  minSizeTaxidNo_DepositReceiveInvoiceMaster: 0,
  maxSizeTaxidNo_DepositReceiveInvoiceMaster: 50,
  patternTaxidNo_DepositReceiveInvoiceMaster: "",
  minSizeWithholdingTax_DepositReceiveInvoiceMaster: 0,
  maxSizeWithholdingTax_DepositReceiveInvoiceMaster: 26,
  patternWithholdingTax_DepositReceiveInvoiceMaster: "",
  minSizeCurrencyId_DepositReceiveInvoiceMaster: 0,
  maxSizeCurrencyId_DepositReceiveInvoiceMaster: 19,
  patternCurrencyId_DepositReceiveInvoiceMaster: "",
  minSizeDocumentGroupId_DepositReceiveInvoiceMaster: 0,
  maxSizeDocumentGroupId_DepositReceiveInvoiceMaster: 19,
  patternDocumentGroupId_DepositReceiveInvoiceMaster: "",
  minSizeCustomerMasterId_DepositReceiveInvoiceMaster: 0,
  maxSizeCustomerMasterId_DepositReceiveInvoiceMaster: 19,
  patternCustomerMasterId_DepositReceiveInvoiceMaster: "",
  minSizeEmployeeMasterId_DepositReceiveInvoiceMaster: 0,
  maxSizeEmployeeMasterId_DepositReceiveInvoiceMaster: 19,
  patternEmployeeMasterId_DepositReceiveInvoiceMaster: "",
  minSizeDivisionId_DepositReceiveInvoiceMaster: 0,
  maxSizeDivisionId_DepositReceiveInvoiceMaster: 19,
  patternDivisionId_DepositReceiveInvoiceMaster: "",
  minSizeSectionId_DepositReceiveInvoiceMaster: 0,
  maxSizeSectionId_DepositReceiveInvoiceMaster: 19,
  patternSectionId_DepositReceiveInvoiceMaster: "",
  minSizeCreateByEmpId_DepositReceiveInvoiceMaster: 0,
  maxSizeCreateByEmpId_DepositReceiveInvoiceMaster: 19,
  patternCreateByEmpId_DepositReceiveInvoiceMaster: "",
  minSizeUpdateByEmpId_DepositReceiveInvoiceMaster: 0,
  maxSizeUpdateByEmpId_DepositReceiveInvoiceMaster: 19,
  patternUpdateByEmpId_DepositReceiveInvoiceMaster: "",
  minSizeAudited_DepositReceiveInvoiceMaster: 0,
  maxSizeAudited_DepositReceiveInvoiceMaster: 1,
  patternAudited_DepositReceiveInvoiceMaster: "",
  minSizePrintStatus_DepositReceiveInvoiceMaster: 0,
  maxSizePrintStatus_DepositReceiveInvoiceMaster: 2,
  patternPrintStatus_DepositReceiveInvoiceMaster: "",
  minSizeCashReceiveAmt_DepositReceiveInvoiceMaster: 0,
  maxSizeCashReceiveAmt_DepositReceiveInvoiceMaster: 26,
  patternCashReceiveAmt_DepositReceiveInvoiceMaster: "",
  minSizeBankReceiveAmt_DepositReceiveInvoiceMaster: 0,
  maxSizeBankReceiveAmt_DepositReceiveInvoiceMaster: 26,
  patternBankReceiveAmt_DepositReceiveInvoiceMaster: "",
  minSizeChequeReceiveAmt_DepositReceiveInvoiceMaster: 0,
  maxSizeChequeReceiveAmt_DepositReceiveInvoiceMaster: 26,
  patternChequeReceiveAmt_DepositReceiveInvoiceMaster: "",
  minSizeCheckTaxPending_DepositReceiveInvoiceMaster: 0,
  maxSizeCheckTaxPending_DepositReceiveInvoiceMaster: 2,
  patternCheckTaxPending_DepositReceiveInvoiceMaster: "",
  minSizeCustName_DepositReceiveInvoiceMaster: 0,
  maxSizeCustName_DepositReceiveInvoiceMaster: 255,
  patternCustName_DepositReceiveInvoiceMaster: "",
  minSizeCustDelivery_DepositReceiveInvoiceMaster: 0,
  maxSizeCustDelivery_DepositReceiveInvoiceMaster: 250,
  patternCustDelivery_DepositReceiveInvoiceMaster: "",
  minSizeDepositPaymentInvoiceMasterId_DepositPayMasterDepositBook: 0,
  maxSizeDepositPaymentInvoiceMasterId_DepositPayMasterDepositBook: 19,
  patternDepositPaymentInvoiceMasterId_DepositPayMasterDepositBook: "",
  minSizeDepositBookMasterId_DepositPayMasterDepositBook: 0,
  maxSizeDepositBookMasterId_DepositPayMasterDepositBook: 19,
  patternDepositBookMasterId_DepositPayMasterDepositBook: "",
  minSizeDepositPaymentInvoiceMasterId_DepositPayMasterJobMaster: 0,
  maxSizeDepositPaymentInvoiceMasterId_DepositPayMasterJobMaster: 19,
  patternDepositPaymentInvoiceMasterId_DepositPayMasterJobMaster: "",
  minSizeJobMasterId_DepositPayMasterJobMaster: 0,
  maxSizeJobMasterId_DepositPayMasterJobMaster: 19,
  patternJobMasterId_DepositPayMasterJobMaster: "",
  minSizeDepositPaymentInvoiceDetailId_DepositPaymentInvoiceDetail: 0,
  maxSizeDepositPaymentInvoiceDetailId_DepositPaymentInvoiceDetail: 19,
  patternDepositPaymentInvoiceDetailId_DepositPaymentInvoiceDetail: "",
  minSizeDetailNo_DepositPaymentInvoiceDetail: 0,
  maxSizeDetailNo_DepositPaymentInvoiceDetail: 10,
  patternDetailNo_DepositPaymentInvoiceDetail: "",
  minSizeProdName_DepositPaymentInvoiceDetail: 0,
  maxSizeProdName_DepositPaymentInvoiceDetail: 255,
  patternProdName_DepositPaymentInvoiceDetail: "",
  minSizeTotal_DepositPaymentInvoiceDetail: 0,
  maxSizeTotal_DepositPaymentInvoiceDetail: 26,
  patternTotal_DepositPaymentInvoiceDetail: "",
  minSizeCompanyCode_DepositPaymentInvoiceDetail: 0,
  maxSizeCompanyCode_DepositPaymentInvoiceDetail: 20,
  patternCompanyCode_DepositPaymentInvoiceDetail: "[0-9a-zA-Z/-]+",
  minSizeDepositPaymentInvoiceMasterId_DepositPaymentInvoiceDetail: 0,
  maxSizeDepositPaymentInvoiceMasterId_DepositPaymentInvoiceDetail: 19,
  patternDepositPaymentInvoiceMasterId_DepositPaymentInvoiceDetail: "",
  minSizeDepositPaymentInvoiceMasterId_DepositPaymentInvoiceMaster: 0,
  maxSizeDepositPaymentInvoiceMasterId_DepositPaymentInvoiceMaster: 19,
  patternDepositPaymentInvoiceMasterId_DepositPaymentInvoiceMaster: "",
  minSizeDepositPayDocNo_DepositPaymentInvoiceMaster: 0,
  maxSizeDepositPayDocNo_DepositPaymentInvoiceMaster: 20,
  patternDepositPayDocNo_DepositPaymentInvoiceMaster: "",
  minSizeDepositPayDocDate_DepositPaymentInvoiceMaster: 0,
  maxSizeDepositPayDocDate_DepositPaymentInvoiceMaster: 20,
  patternDepositPayDocDate_DepositPaymentInvoiceMaster: "",
  minSizeDepositPayInvoiceNo_DepositPaymentInvoiceMaster: 0,
  maxSizeDepositPayInvoiceNo_DepositPaymentInvoiceMaster: 20,
  patternDepositPayInvoiceNo_DepositPaymentInvoiceMaster: "",
  minSizeDepositPayInvoiceDate_DepositPaymentInvoiceMaster: 0,
  maxSizeDepositPayInvoiceDate_DepositPaymentInvoiceMaster: 255,
  patternDepositPayInvoiceDate_DepositPaymentInvoiceMaster: "",
  minSizeDepositPayTotal_DepositPaymentInvoiceMaster: 0,
  maxSizeDepositPayTotal_DepositPaymentInvoiceMaster: 26,
  patternDepositPayTotal_DepositPaymentInvoiceMaster: "",
  minSizeDepositPayDiscRate_DepositPaymentInvoiceMaster: 0,
  maxSizeDepositPayDiscRate_DepositPaymentInvoiceMaster: 26,
  patternDepositPayDiscRate_DepositPaymentInvoiceMaster: "",
  minSizeDepositPayDiscAmt_DepositPaymentInvoiceMaster: 0,
  maxSizeDepositPayDiscAmt_DepositPaymentInvoiceMaster: 26,
  patternDepositPayDiscAmt_DepositPaymentInvoiceMaster: "",
  minSizeDepositPayVatRate_DepositPaymentInvoiceMaster: 0,
  maxSizeDepositPayVatRate_DepositPaymentInvoiceMaster: 26,
  patternDepositPayVatRate_DepositPaymentInvoiceMaster: "",
  minSizeDepositPayVatAmt_DepositPaymentInvoiceMaster: 0,
  maxSizeDepositPayVatAmt_DepositPaymentInvoiceMaster: 26,
  patternDepositPayVatAmt_DepositPaymentInvoiceMaster: "",
  minSizeDepositPayNetAmt_DepositPaymentInvoiceMaster: 0,
  maxSizeDepositPayNetAmt_DepositPaymentInvoiceMaster: 26,
  patternDepositPayNetAmt_DepositPaymentInvoiceMaster: "",
  minSizeDepositReturnNetAmt_DepositPaymentInvoiceMaster: 0,
  maxSizeDepositReturnNetAmt_DepositPaymentInvoiceMaster: 26,
  patternDepositReturnNetAmt_DepositPaymentInvoiceMaster: "",
  minSizeDepositPayRemark_DepositPaymentInvoiceMaster: 0,
  maxSizeDepositPayRemark_DepositPaymentInvoiceMaster: 250,
  patternDepositPayRemark_DepositPaymentInvoiceMaster: "",
  minSizeDepositPayStatus_DepositPaymentInvoiceMaster: 0,
  maxSizeDepositPayStatus_DepositPaymentInvoiceMaster: 20,
  patternDepositPayStatus_DepositPaymentInvoiceMaster: "",
  minSizeDepositPayLinkDocNo_DepositPaymentInvoiceMaster: 0,
  maxSizeDepositPayLinkDocNo_DepositPaymentInvoiceMaster: 20,
  patternDepositPayLinkDocNo_DepositPaymentInvoiceMaster: "",
  minSizeDepositPayPaymentType_DepositPaymentInvoiceMaster: 0,
  maxSizeDepositPayPaymentType_DepositPaymentInvoiceMaster: 255,
  patternDepositPayPaymentType_DepositPaymentInvoiceMaster: "",
  minSizeDepositPayPayAmt_DepositPaymentInvoiceMaster: 0,
  maxSizeDepositPayPayAmt_DepositPaymentInvoiceMaster: 26,
  patternDepositPayPayAmt_DepositPaymentInvoiceMaster: "",
  minSizeCharge_DepositPaymentInvoiceMaster: 0,
  maxSizeCharge_DepositPaymentInvoiceMaster: 26,
  patternCharge_DepositPaymentInvoiceMaster: "",
  minSizeDepositPayBankRemark_DepositPaymentInvoiceMaster: 0,
  maxSizeDepositPayBankRemark_DepositPaymentInvoiceMaster: 255,
  patternDepositPayBankRemark_DepositPaymentInvoiceMaster: "",
  minSizeDepositPayChqNo_DepositPaymentInvoiceMaster: 0,
  maxSizeDepositPayChqNo_DepositPaymentInvoiceMaster: 255,
  patternDepositPayChqNo_DepositPaymentInvoiceMaster: "",
  minSizeDepositPayChqDate_DepositPaymentInvoiceMaster: 0,
  maxSizeDepositPayChqDate_DepositPaymentInvoiceMaster: 150,
  patternDepositPayChqDate_DepositPaymentInvoiceMaster: "",
  minSizeCreateOn_DepositPaymentInvoiceMaster: 0,
  maxSizeCreateOn_DepositPaymentInvoiceMaster: 20,
  patternCreateOn_DepositPaymentInvoiceMaster: "",
  minSizeUpdateOn_DepositPaymentInvoiceMaster: 0,
  maxSizeUpdateOn_DepositPaymentInvoiceMaster: 20,
  patternUpdateOn_DepositPaymentInvoiceMaster: "",
  minSizeDepositPayVoucherNo_DepositPaymentInvoiceMaster: 0,
  maxSizeDepositPayVoucherNo_DepositPaymentInvoiceMaster: 20,
  patternDepositPayVoucherNo_DepositPaymentInvoiceMaster: "",
  minSizeCompanyCode_DepositPaymentInvoiceMaster: 0,
  maxSizeCompanyCode_DepositPaymentInvoiceMaster: 20,
  patternCompanyCode_DepositPaymentInvoiceMaster: "[0-9a-zA-Z/-]+",
  minSizeExchangeRate_DepositPaymentInvoiceMaster: 0,
  maxSizeExchangeRate_DepositPaymentInvoiceMaster: 26,
  patternExchangeRate_DepositPaymentInvoiceMaster: "",
  minSizeExchageRateDate_DepositPaymentInvoiceMaster: 0,
  maxSizeExchageRateDate_DepositPaymentInvoiceMaster: 20,
  patternExchageRateDate_DepositPaymentInvoiceMaster: "",
  minSizeAstatus_DepositPaymentInvoiceMaster: 0,
  maxSizeAstatus_DepositPaymentInvoiceMaster: 2,
  patternAstatus_DepositPaymentInvoiceMaster: "",
  minSizePayChequeNo_DepositPaymentInvoiceMaster: 0,
  maxSizePayChequeNo_DepositPaymentInvoiceMaster: 20,
  patternPayChequeNo_DepositPaymentInvoiceMaster: "",
  minSizeVatInclude_DepositPaymentInvoiceMaster: 0,
  maxSizeVatInclude_DepositPaymentInvoiceMaster: 2,
  patternVatInclude_DepositPaymentInvoiceMaster: "",
  minSizeDivisionNo_DepositPaymentInvoiceMaster: 0,
  maxSizeDivisionNo_DepositPaymentInvoiceMaster: 10,
  patternDivisionNo_DepositPaymentInvoiceMaster: "",
  minSizeDivisionName_DepositPaymentInvoiceMaster: 0,
  maxSizeDivisionName_DepositPaymentInvoiceMaster: 50,
  patternDivisionName_DepositPaymentInvoiceMaster: "",
  minSizeDivisionAddress_DepositPaymentInvoiceMaster: 0,
  maxSizeDivisionAddress_DepositPaymentInvoiceMaster: 255,
  patternDivisionAddress_DepositPaymentInvoiceMaster: "",
  minSizeTaxIdNo_DepositPaymentInvoiceMaster: 0,
  maxSizeTaxIdNo_DepositPaymentInvoiceMaster: 50,
  patternTaxIdNo_DepositPaymentInvoiceMaster: "",
  minSizeWithholdingTaxAmt_DepositPaymentInvoiceMaster: 0,
  maxSizeWithholdingTaxAmt_DepositPaymentInvoiceMaster: 26,
  patternWithholdingTaxAmt_DepositPaymentInvoiceMaster: "",
  minSizeCurrencyId_DepositPaymentInvoiceMaster: 0,
  maxSizeCurrencyId_DepositPaymentInvoiceMaster: 19,
  patternCurrencyId_DepositPaymentInvoiceMaster: "",
  minSizeDocumentGroupId_DepositPaymentInvoiceMaster: 0,
  maxSizeDocumentGroupId_DepositPaymentInvoiceMaster: 19,
  patternDocumentGroupId_DepositPaymentInvoiceMaster: "",
  minSizeSupplierMasterId_DepositPaymentInvoiceMaster: 0,
  maxSizeSupplierMasterId_DepositPaymentInvoiceMaster: 19,
  patternSupplierMasterId_DepositPaymentInvoiceMaster: "",
  minSizeEmployeeMasterId_DepositPaymentInvoiceMaster: 0,
  maxSizeEmployeeMasterId_DepositPaymentInvoiceMaster: 19,
  patternEmployeeMasterId_DepositPaymentInvoiceMaster: "",
  minSizeDivisionId_DepositPaymentInvoiceMaster: 0,
  maxSizeDivisionId_DepositPaymentInvoiceMaster: 19,
  patternDivisionId_DepositPaymentInvoiceMaster: "",
  minSizeSectionId_DepositPaymentInvoiceMaster: 0,
  maxSizeSectionId_DepositPaymentInvoiceMaster: 19,
  patternSectionId_DepositPaymentInvoiceMaster: "",
  minSizeCreateByEmpId_DepositPaymentInvoiceMaster: 0,
  maxSizeCreateByEmpId_DepositPaymentInvoiceMaster: 19,
  patternCreateByEmpId_DepositPaymentInvoiceMaster: "",
  minSizeUpdateByEmpId_DepositPaymentInvoiceMaster: 0,
  maxSizeUpdateByEmpId_DepositPaymentInvoiceMaster: 19,
  patternUpdateByEmpId_DepositPaymentInvoiceMaster: "",
  minSizeTaxReturnType_DepositPaymentInvoiceMaster: 0,
  maxSizeTaxReturnType_DepositPaymentInvoiceMaster: 2,
  patternTaxReturnType_DepositPaymentInvoiceMaster: "",
  minSizeCashPayAmt_DepositPaymentInvoiceMaster: 0,
  maxSizeCashPayAmt_DepositPaymentInvoiceMaster: 26,
  patternCashPayAmt_DepositPaymentInvoiceMaster: "",
  minSizeBankPayAmt_DepositPaymentInvoiceMaster: 0,
  maxSizeBankPayAmt_DepositPaymentInvoiceMaster: 26,
  patternBankPayAmt_DepositPaymentInvoiceMaster: "",
  minSizeChequePayAmt_DepositPaymentInvoiceMaster: 0,
  maxSizeChequePayAmt_DepositPaymentInvoiceMaster: 26,
  patternChequePayAmt_DepositPaymentInvoiceMaster: "",
  minSizeNetPlusWithholdingTax_DepositPaymentInvoiceMaster: 0,
  maxSizeNetPlusWithholdingTax_DepositPaymentInvoiceMaster: 26,
  patternNetPlusWithholdingTax_DepositPaymentInvoiceMaster: "",
  minSizeNameInVat_DepositPaymentInvoiceMaster: 0,
  maxSizeNameInVat_DepositPaymentInvoiceMaster: 255,
  patternNameInVat_DepositPaymentInvoiceMaster: "",
  minSizeSuppName_DepositPaymentInvoiceMaster: 0,
  maxSizeSuppName_DepositPaymentInvoiceMaster: 255,
  patternSuppName_DepositPaymentInvoiceMaster: "",
  minSizeRctInvServiceMasterId_RCTInvServiceMaster: 0,
  maxSizeRctInvServiceMasterId_RCTInvServiceMaster: 19,
  patternRctInvServiceMasterId_RCTInvServiceMaster: "",
  minSizeRctDocNo_RCTInvServiceMaster: 0,
  maxSizeRctDocNo_RCTInvServiceMaster: 20,
  patternRctDocNo_RCTInvServiceMaster: "",
  minSizeRctDocDate_RCTInvServiceMaster: 0,
  maxSizeRctDocDate_RCTInvServiceMaster: 20,
  patternRctDocDate_RCTInvServiceMaster: "",
  minSizeRctStatus_RCTInvServiceMaster: 0,
  maxSizeRctStatus_RCTInvServiceMaster: 2,
  patternRctStatus_RCTInvServiceMaster: "",
  minSizeRctReferDoc_RCTInvServiceMaster: 0,
  maxSizeRctReferDoc_RCTInvServiceMaster: 30,
  patternRctReferDoc_RCTInvServiceMaster: "",
  minSizeRctRemark_RCTInvServiceMaster: 0,
  maxSizeRctRemark_RCTInvServiceMaster: 500,
  patternRctRemark_RCTInvServiceMaster: "",
  minSizeRctTotal_RCTInvServiceMaster: 0,
  maxSizeRctTotal_RCTInvServiceMaster: 26,
  patternRctTotal_RCTInvServiceMaster: "",
  minSizeRctTotalNoVat_RCTInvServiceMaster: 0,
  maxSizeRctTotalNoVat_RCTInvServiceMaster: 26,
  patternRctTotalNoVat_RCTInvServiceMaster: "",
  minSizeRctLinkDoc_RCTInvServiceMaster: 0,
  maxSizeRctLinkDoc_RCTInvServiceMaster: 20,
  patternRctLinkDoc_RCTInvServiceMaster: "",
  minSizeCreateOn_RCTInvServiceMaster: 0,
  maxSizeCreateOn_RCTInvServiceMaster: 20,
  patternCreateOn_RCTInvServiceMaster: "",
  minSizeUpdateOn_RCTInvServiceMaster: 0,
  maxSizeUpdateOn_RCTInvServiceMaster: 20,
  patternUpdateOn_RCTInvServiceMaster: "",
  minSizeVoucherNo_RCTInvServiceMaster: 0,
  maxSizeVoucherNo_RCTInvServiceMaster: 20,
  patternVoucherNo_RCTInvServiceMaster: "",
  minSizeRctBeforeChangeStatus_RCTInvServiceMaster: 0,
  maxSizeRctBeforeChangeStatus_RCTInvServiceMaster: 2,
  patternRctBeforeChangeStatus_RCTInvServiceMaster: "",
  minSizeReceiveAmt_RCTInvServiceMaster: 0,
  maxSizeReceiveAmt_RCTInvServiceMaster: 26,
  patternReceiveAmt_RCTInvServiceMaster: "",
  minSizeCompanyCode_RCTInvServiceMaster: 0,
  maxSizeCompanyCode_RCTInvServiceMaster: 20,
  patternCompanyCode_RCTInvServiceMaster: "[0-9a-zA-Z/-]+",
  minSizeExchangeRate_RCTInvServiceMaster: 0,
  maxSizeExchangeRate_RCTInvServiceMaster: 26,
  patternExchangeRate_RCTInvServiceMaster: "",
  minSizeExchageRateDate_RCTInvServiceMaster: 0,
  maxSizeExchageRateDate_RCTInvServiceMaster: 20,
  patternExchageRateDate_RCTInvServiceMaster: "",
  minSizeAstatus_RCTInvServiceMaster: 0,
  maxSizeAstatus_RCTInvServiceMaster: 2,
  patternAstatus_RCTInvServiceMaster: "",
  minSizeRctDiscAmt_RCTInvServiceMaster: 0,
  maxSizeRctDiscAmt_RCTInvServiceMaster: 26,
  patternRctDiscAmt_RCTInvServiceMaster: "",
  minSizeRctDiscRate_RCTInvServiceMaster: 0,
  maxSizeRctDiscRate_RCTInvServiceMaster: 26,
  patternRctDiscRate_RCTInvServiceMaster: "",
  minSizeDeductReceiveDepositNo_RCTInvServiceMaster: 0,
  maxSizeDeductReceiveDepositNo_RCTInvServiceMaster: 500,
  patternDeductReceiveDepositNo_RCTInvServiceMaster: "",
  minSizeDeductReceiveDepositAmt_RCTInvServiceMaster: 0,
  maxSizeDeductReceiveDepositAmt_RCTInvServiceMaster: 26,
  patternDeductReceiveDepositAmt_RCTInvServiceMaster: "",
  minSizeVatInclude_RCTInvServiceMaster: 0,
  maxSizeVatInclude_RCTInvServiceMaster: 2,
  patternVatInclude_RCTInvServiceMaster: "",
  minSizeRctVatRate_RCTInvServiceMaster: 0,
  maxSizeRctVatRate_RCTInvServiceMaster: 26,
  patternRctVatRate_RCTInvServiceMaster: "",
  minSizeRctVatAmt_RCTInvServiceMaster: 0,
  maxSizeRctVatAmt_RCTInvServiceMaster: 26,
  patternRctVatAmt_RCTInvServiceMaster: "",
  minSizeRctNetAmt_RCTInvServiceMaster: 0,
  maxSizeRctNetAmt_RCTInvServiceMaster: 26,
  patternRctNetAmt_RCTInvServiceMaster: "",
  minSizeDivisionNo_RCTInvServiceMaster: 0,
  maxSizeDivisionNo_RCTInvServiceMaster: 10,
  patternDivisionNo_RCTInvServiceMaster: "",
  minSizeDivisionName_RCTInvServiceMaster: 0,
  maxSizeDivisionName_RCTInvServiceMaster: 50,
  patternDivisionName_RCTInvServiceMaster: "",
  minSizeDivisionAddress_RCTInvServiceMaster: 0,
  maxSizeDivisionAddress_RCTInvServiceMaster: 255,
  patternDivisionAddress_RCTInvServiceMaster: "",
  minSizeTaxIdNo_RCTInvServiceMaster: 0,
  maxSizeTaxIdNo_RCTInvServiceMaster: 50,
  patternTaxIdNo_RCTInvServiceMaster: "",
  minSizeCurrencyId_RCTInvServiceMaster: 0,
  maxSizeCurrencyId_RCTInvServiceMaster: 19,
  patternCurrencyId_RCTInvServiceMaster: "",
  minSizeDocumentGroup_RCTInvServiceMaster: 0,
  maxSizeDocumentGroup_RCTInvServiceMaster: 19,
  patternDocumentGroup_RCTInvServiceMaster: "",
  minSizeCustomerMasterId_RCTInvServiceMaster: 0,
  maxSizeCustomerMasterId_RCTInvServiceMaster: 19,
  patternCustomerMasterId_RCTInvServiceMaster: "",
  minSizeEmployeeMasterId_RCTInvServiceMaster: 0,
  maxSizeEmployeeMasterId_RCTInvServiceMaster: 19,
  patternEmployeeMasterId_RCTInvServiceMaster: "",
  minSizeDivisionId_RCTInvServiceMaster: 0,
  maxSizeDivisionId_RCTInvServiceMaster: 19,
  patternDivisionId_RCTInvServiceMaster: "",
  minSizeSectionId_RCTInvServiceMaster: 0,
  maxSizeSectionId_RCTInvServiceMaster: 19,
  patternSectionId_RCTInvServiceMaster: "",
  minSizeCreateByEmpId_RCTInvServiceMaster: 0,
  maxSizeCreateByEmpId_RCTInvServiceMaster: 19,
  patternCreateByEmpId_RCTInvServiceMaster: "",
  minSizeUpdateByEmpId_RCTInvServiceMaster: 0,
  maxSizeUpdateByEmpId_RCTInvServiceMaster: 19,
  patternUpdateByEmpId_RCTInvServiceMaster: "",
  minSizeReceiveType_RCTInvServiceMaster: 0,
  maxSizeReceiveType_RCTInvServiceMaster: 5,
  patternReceiveType_RCTInvServiceMaster: "",
  minSizeReceiveAmtPayment_RCTInvServiceMaster: 0,
  maxSizeReceiveAmtPayment_RCTInvServiceMaster: 26,
  patternReceiveAmtPayment_RCTInvServiceMaster: "",
  minSizeReceiveDepositBookCode_RCTInvServiceMaster: 0,
  maxSizeReceiveDepositBookCode_RCTInvServiceMaster: 20,
  patternReceiveDepositBookCode_RCTInvServiceMaster: "[0-9a-zA-Z/-]+",
  minSizeBankDate_RCTInvServiceMaster: 0,
  maxSizeBankDate_RCTInvServiceMaster: 50,
  patternBankDate_RCTInvServiceMaster: "",
  minSizeBankRemank_RCTInvServiceMaster: 0,
  maxSizeBankRemank_RCTInvServiceMaster: 150,
  patternBankRemank_RCTInvServiceMaster: "",
  minSizeChqNo_RCTInvServiceMaster: 0,
  maxSizeChqNo_RCTInvServiceMaster: 20,
  patternChqNo_RCTInvServiceMaster: "",
  minSizeCustBankCode_RCTInvServiceMaster: 0,
  maxSizeCustBankCode_RCTInvServiceMaster: 100,
  patternCustBankCode_RCTInvServiceMaster: "[0-9a-zA-Z/-]+",
  minSizeDivisionCustBank_RCTInvServiceMaster: 0,
  maxSizeDivisionCustBank_RCTInvServiceMaster: 100,
  patternDivisionCustBank_RCTInvServiceMaster: "",
  minSizePayChqNo_RCTInvServiceMaster: 0,
  maxSizePayChqNo_RCTInvServiceMaster: 50,
  patternPayChqNo_RCTInvServiceMaster: "",
  minSizeWithHoldingTax_RCTInvServiceMaster: 0,
  maxSizeWithHoldingTax_RCTInvServiceMaster: 26,
  patternWithHoldingTax_RCTInvServiceMaster: "",
  minSizeUpdateReferByPrice_RCTInvServiceMaster: 0,
  maxSizeUpdateReferByPrice_RCTInvServiceMaster: 1,
  patternUpdateReferByPrice_RCTInvServiceMaster: "",
  minSizePrintStatus_RCTInvServiceMaster: 0,
  maxSizePrintStatus_RCTInvServiceMaster: 2,
  patternPrintStatus_RCTInvServiceMaster: "",
  minSizeDeductReceiveDepositNo_Bns_RCTInvServiceMaster: 0,
  patternDeductReceiveDepositNo_Bns_RCTInvServiceMaster: "",
  minSizeDeductReceiveDepositAmt_Bns_RCTInvServiceMaster: 0,
  patternDeductReceiveDepositAmt_Bns_RCTInvServiceMaster: "",
  minSizeBillingPayAmt_RCTInvServiceMaster: 0,
  maxSizeBillingPayAmt_RCTInvServiceMaster: 26,
  patternBillingPayAmt_RCTInvServiceMaster: "",
  minSizeRctDueDate_RCTInvServiceMaster: 0,
  maxSizeRctDueDate_RCTInvServiceMaster: 20,
  patternRctDueDate_RCTInvServiceMaster: "",
  minSizeCreditTerm_RCTInvServiceMaster: 0,
  maxSizeCreditTerm_RCTInvServiceMaster: 10,
  patternCreditTerm_RCTInvServiceMaster: "",
  minSizeRemark1_RCTInvServiceMaster: 0,
  maxSizeRemark1_RCTInvServiceMaster: 255,
  patternRemark1_RCTInvServiceMaster: "",
  minSizeCusDivision_RCTInvServiceMaster: 0,
  maxSizeCusDivision_RCTInvServiceMaster: 19,
  patternCusDivision_RCTInvServiceMaster: "",
  minSizeCustName_RCTInvServiceMaster: 0,
  maxSizeCustName_RCTInvServiceMaster: 150,
  patternCustName_RCTInvServiceMaster: "",
  minSizeRctInvServiceMasterId_RCTInvServiceMasterJobMaster: 0,
  maxSizeRctInvServiceMasterId_RCTInvServiceMasterJobMaster: 19,
  patternRctInvServiceMasterId_RCTInvServiceMasterJobMaster: "",
  minSizeJobMasterId_RCTInvServiceMasterJobMaster: 0,
  maxSizeJobMasterId_RCTInvServiceMasterJobMaster: 19,
  patternJobMasterId_RCTInvServiceMasterJobMaster: "",
  minSizeCheckProductStockDetailId_CheckProductStockDetail: 0,
  maxSizeCheckProductStockDetailId_CheckProductStockDetail: 19,
  patternCheckProductStockDetailId_CheckProductStockDetail: "",
  minSizeProdCode_CheckProductStockDetail: 0,
  maxSizeProdCode_CheckProductStockDetail: 30,
  patternProdCode_CheckProductStockDetail: "[0-9a-zA-Z/-]+",
  minSizeProdName_CheckProductStockDetail: 0,
  maxSizeProdName_CheckProductStockDetail: 100,
  patternProdName_CheckProductStockDetail: "",
  minSizeUnitCode_CheckProductStockDetail: 0,
  maxSizeUnitCode_CheckProductStockDetail: 30,
  patternUnitCode_CheckProductStockDetail: "[0-9a-zA-Z/-]+",
  minSizeUnitName_CheckProductStockDetail: 0,
  maxSizeUnitName_CheckProductStockDetail: 100,
  patternUnitName_CheckProductStockDetail: "",
  minSizeCompanyCode_CheckProductStockDetail: 0,
  maxSizeCompanyCode_CheckProductStockDetail: 20,
  patternCompanyCode_CheckProductStockDetail: "[0-9a-zA-Z/-]+",
  minSizeDescription_CheckProductStockDetail: 0,
  maxSizeDescription_CheckProductStockDetail: 255,
  patternDescription_CheckProductStockDetail: "",
  minSizeAdjStatus_CheckProductStockDetail: 0,
  maxSizeAdjStatus_CheckProductStockDetail: 2,
  patternAdjStatus_CheckProductStockDetail: "",
  minSizeProdBalOnHand_CheckProductStockDetail: 0,
  maxSizeProdBalOnHand_CheckProductStockDetail: 26,
  patternProdBalOnHand_CheckProductStockDetail: "",
  minSizeProdCount_CheckProductStockDetail: 0,
  maxSizeProdCount_CheckProductStockDetail: 26,
  patternProdCount_CheckProductStockDetail: "",
  minSizeDiffernce_CheckProductStockDetail: 0,
  maxSizeDiffernce_CheckProductStockDetail: 26,
  patternDiffernce_CheckProductStockDetail: "",
  minSizeCheckProductStockMasterId_CheckProductStockDetail: 0,
  maxSizeCheckProductStockMasterId_CheckProductStockDetail: 19,
  patternCheckProductStockMasterId_CheckProductStockDetail: "",
  minSizeProdCodeId_CheckProductStockDetail: 0,
  maxSizeProdCodeId_CheckProductStockDetail: 19,
  patternProdCodeId_CheckProductStockDetail: "[0-9a-zA-Z/-]+",
  minSizeLotNo_CheckProductStockDetail: 0,
  maxSizeLotNo_CheckProductStockDetail: 50,
  patternLotNo_CheckProductStockDetail: "",
  minSizeUnitOfMeasureId_CheckProductStockDetail: 0,
  maxSizeUnitOfMeasureId_CheckProductStockDetail: 19,
  patternUnitOfMeasureId_CheckProductStockDetail: "",
  minSizeProdQty_CheckProductStockDetail: 0,
  maxSizeProdQty_CheckProductStockDetail: 26,
  patternProdQty_CheckProductStockDetail: "",
  minSizeProdConvFactor_CheckProductStockDetail: 0,
  maxSizeProdConvFactor_CheckProductStockDetail: 26,
  patternProdConvFactor_CheckProductStockDetail: "",
  minSizeSourceType_CheckProductStockDetail: 0,
  maxSizeSourceType_CheckProductStockDetail: 2,
  patternSourceType_CheckProductStockDetail: "",
  minSizeCheckProductStockMasterId_CheckProductStockMaster: 0,
  maxSizeCheckProductStockMasterId_CheckProductStockMaster: 19,
  patternCheckProductStockMasterId_CheckProductStockMaster: "",
  minSizeDocNo_CheckProductStockMaster: 0,
  maxSizeDocNo_CheckProductStockMaster: 30,
  patternDocNo_CheckProductStockMaster: "",
  minSizeDocDate_CheckProductStockMaster: 0,
  maxSizeDocDate_CheckProductStockMaster: 50,
  patternDocDate_CheckProductStockMaster: "",
  minSizeCreateOn_CheckProductStockMaster: 0,
  maxSizeCreateOn_CheckProductStockMaster: 50,
  patternCreateOn_CheckProductStockMaster: "",
  minSizeUpdateOn_CheckProductStockMaster: 0,
  maxSizeUpdateOn_CheckProductStockMaster: 50,
  patternUpdateOn_CheckProductStockMaster: "",
  minSizeDocStatus_CheckProductStockMaster: 0,
  maxSizeDocStatus_CheckProductStockMaster: 2,
  patternDocStatus_CheckProductStockMaster: "",
  minSizeAstatus_CheckProductStockMaster: 0,
  maxSizeAstatus_CheckProductStockMaster: 2,
  patternAstatus_CheckProductStockMaster: "",
  minSizeCompanyCode_CheckProductStockMaster: 0,
  maxSizeCompanyCode_CheckProductStockMaster: 20,
  patternCompanyCode_CheckProductStockMaster: "[0-9a-zA-Z/-]+",
  minSizeRemark_CheckProductStockMaster: 0,
  maxSizeRemark_CheckProductStockMaster: 255,
  patternRemark_CheckProductStockMaster: "",
  minSizeWarehouseId_CheckProductStockMaster: 0,
  maxSizeWarehouseId_CheckProductStockMaster: 19,
  patternWarehouseId_CheckProductStockMaster: "",
  minSizeCreateByEmpId_CheckProductStockMaster: 0,
  maxSizeCreateByEmpId_CheckProductStockMaster: 19,
  patternCreateByEmpId_CheckProductStockMaster: "",
  minSizeUpdateByEmpId_CheckProductStockMaster: 0,
  maxSizeUpdateByEmpId_CheckProductStockMaster: 19,
  patternUpdateByEmpId_CheckProductStockMaster: "",
  minSizeDivisionId_CheckProductStockMaster: 0,
  maxSizeDivisionId_CheckProductStockMaster: 19,
  patternDivisionId_CheckProductStockMaster: "",
  minSizeEmployeeMasterId_CheckProductStockMaster: 0,
  maxSizeEmployeeMasterId_CheckProductStockMaster: 19,
  patternEmployeeMasterId_CheckProductStockMaster: "",
  minSizeLocationMasterId_CheckProductStockMaster: 0,
  maxSizeLocationMasterId_CheckProductStockMaster: 19,
  patternLocationMasterId_CheckProductStockMaster: "",
  minSizeProductInternalIssueDetailId_InternalDetailInternalMaster: 0,
  maxSizeProductInternalIssueDetailId_InternalDetailInternalMaster: 19,
  patternProductInternalIssueDetailId_InternalDetailInternalMaster: "",
  minSizeProductInternalIssueMasterId_InternalDetailInternalMaster: 0,
  maxSizeProductInternalIssueMasterId_InternalDetailInternalMaster: 19,
  patternProductInternalIssueMasterId_InternalDetailInternalMaster: "",
  minSizeProductInternalIssueMasterId_InternalIssueMasterJobMaster: 0,
  maxSizeProductInternalIssueMasterId_InternalIssueMasterJobMaster: 19,
  patternProductInternalIssueMasterId_InternalIssueMasterJobMaster: "",
  minSizeJobMasterId_InternalIssueMasterJobMaster: 0,
  maxSizeJobMasterId_InternalIssueMasterJobMaster: 19,
  patternJobMasterId_InternalIssueMasterJobMaster: "",
  minSizeCustomerMasterId_JobMaster: 0,
  maxSizeCustomerMasterId_JobMaster: 19,
  patternCustomerMasterId_JobMaster: "",
  minSizeCustCode_JobMaster: 0,
  maxSizeCustCode_JobMaster: 20,
  patternCustCode_JobMaster: "[0-9a-zA-Z/-]+",
  minSizeCustAddress_JobMaster: 0,
  maxSizeCustAddress_JobMaster: 250,
  patternCustAddress_JobMaster: "",
  minSizeCustLocation_JobMaster: 0,
  maxSizeCustLocation_JobMaster: 50,
  patternCustLocation_JobMaster: "",
  minSizeLotMasterId_LotMaster: 0,
  maxSizeLotMasterId_LotMaster: 19,
  patternLotMasterId_LotMaster: "",
  minSizeLotNo_LotMaster: 0,
  maxSizeLotNo_LotMaster: 50,
  patternLotNo_LotMaster: "",
  minSizeDocNo_LotMaster: 0,
  maxSizeDocNo_LotMaster: 20,
  patternDocNo_LotMaster: "",
  minSizeDocDate_LotMaster: 0,
  maxSizeDocDate_LotMaster: 20,
  patternDocDate_LotMaster: "",
  minSizeExpireDate_LotMaster: 0,
  maxSizeExpireDate_LotMaster: 20,
  patternExpireDate_LotMaster: "",
  minSizeLotQTY_LotMaster: 0,
  maxSizeLotQTY_LotMaster: 16,
  patternLotQTY_LotMaster: "",
  minSizeLotBaseQTY_LotMaster: 0,
  maxSizeLotBaseQTY_LotMaster: 16,
  patternLotBaseQTY_LotMaster: "",
  minSizeLotUseQTY_LotMaster: 0,
  maxSizeLotUseQTY_LotMaster: 16,
  patternLotUseQTY_LotMaster: "",
  minSizeLotUseBaseQTY_LotMaster: 0,
  maxSizeLotUseBaseQTY_LotMaster: 16,
  patternLotUseBaseQTY_LotMaster: "",
  minSizeCost_LotMaster: 0,
  maxSizeCost_LotMaster: 20,
  patternCost_LotMaster: "",
  minSizeCompanyCode_LotMaster: 0,
  maxSizeCompanyCode_LotMaster: 20,
  patternCompanyCode_LotMaster: "[0-9a-zA-Z/-]+",
  minSizeLotStatus_LotMaster: 0,
  maxSizeLotStatus_LotMaster: 20,
  patternLotStatus_LotMaster: "",
  minSizeProductMaster1Id_LotMaster: 0,
  maxSizeProductMaster1Id_LotMaster: 19,
  patternProductMaster1Id_LotMaster: "",
  minSizeDivisionId_LotMaster: 0,
  maxSizeDivisionId_LotMaster: 19,
  patternDivisionId_LotMaster: "",
  minSizeWarehouseId_LotMaster: 0,
  maxSizeWarehouseId_LotMaster: 19,
  patternWarehouseId_LotMaster: "",
  minSizeLocationId_LotMaster: 0,
  maxSizeLocationId_LotMaster: 19,
  patternLocationId_LotMaster: "",
  minSizeProdCode_LotMaster: 0,
  maxSizeProdCode_LotMaster: 20,
  patternProdCode_LotMaster: "[0-9a-zA-Z/-]+",
  minSizeRemark_LotMaster: 0,
  maxSizeRemark_LotMaster: 500,
  patternRemark_LotMaster: "",
  minSizeWhCode_LotMaster: 0,
  maxSizeWhCode_LotMaster: 20,
  patternWhCode_LotMaster: "[0-9a-zA-Z/-]+",
  minSizeJobMasterId_LotMaster: 0,
  maxSizeJobMasterId_LotMaster: 19,
  patternJobMasterId_LotMaster: "",
  minSizeProcessMasterId_LotMaster: 0,
  maxSizeProcessMasterId_LotMaster: 19,
  patternProcessMasterId_LotMaster: "",
  minSizeProductInternalIssueDetailId_ProductInternalIssueDetail: 0,
  maxSizeProductInternalIssueDetailId_ProductInternalIssueDetail: 19,
  patternProductInternalIssueDetailId_ProductInternalIssueDetail: "",
  minSizeNumber_ProductInternalIssueDetail: 0,
  maxSizeNumber_ProductInternalIssueDetail: 10,
  patternNumber_ProductInternalIssueDetail: "",
  minSizeProdQty_ProductInternalIssueDetail: 0,
  maxSizeProdQty_ProductInternalIssueDetail: 26,
  patternProdQty_ProductInternalIssueDetail: "",
  minSizeProdReturnQty_ProductInternalIssueDetail: 0,
  maxSizeProdReturnQty_ProductInternalIssueDetail: 26,
  patternProdReturnQty_ProductInternalIssueDetail: "",
  minSizeProdConvfactor_ProductInternalIssueDetail: 0,
  maxSizeProdConvfactor_ProductInternalIssueDetail: 26,
  patternProdConvfactor_ProductInternalIssueDetail: "",
  minSizeProdBaseQty_ProductInternalIssueDetail: 0,
  maxSizeProdBaseQty_ProductInternalIssueDetail: 26,
  patternProdBaseQty_ProductInternalIssueDetail: "",
  minSizeProdReturnBaseQty_ProductInternalIssueDetail: 0,
  maxSizeProdReturnBaseQty_ProductInternalIssueDetail: 26,
  patternProdReturnBaseQty_ProductInternalIssueDetail: "",
  minSizeType_ProductInternalIssueDetail: 0,
  maxSizeType_ProductInternalIssueDetail: 1,
  patternType_ProductInternalIssueDetail: "",
  minSizeOwnerName_ProductInternalIssueDetail: 0,
  maxSizeOwnerName_ProductInternalIssueDetail: 150,
  patternOwnerName_ProductInternalIssueDetail: "",
  minSizeSerialNo_ProductInternalIssueDetail: 0,
  maxSizeSerialNo_ProductInternalIssueDetail: 255,
  patternSerialNo_ProductInternalIssueDetail: "",
  minSizeSerialNoText_ProductInternalIssueDetail: 0,
  maxSizeSerialNoText_ProductInternalIssueDetail: 9999,
  patternSerialNoText_ProductInternalIssueDetail: "",
  minSizeContractNo_ProductInternalIssueDetail: 0,
  maxSizeContractNo_ProductInternalIssueDetail: 100,
  patternContractNo_ProductInternalIssueDetail: "",
  minSizeContractDate_ProductInternalIssueDetail: 0,
  maxSizeContractDate_ProductInternalIssueDetail: 20,
  patternContractDate_ProductInternalIssueDetail: "",
  minSizeRemark_ProductInternalIssueDetail: 0,
  maxSizeRemark_ProductInternalIssueDetail: 150,
  patternRemark_ProductInternalIssueDetail: "",
  minSizeCompanyCode_ProductInternalIssueDetail: 0,
  maxSizeCompanyCode_ProductInternalIssueDetail: 20,
  patternCompanyCode_ProductInternalIssueDetail: "[0-9a-zA-Z/-]+",
  minSizeCost_ProductInternalIssueDetail: 0,
  maxSizeCost_ProductInternalIssueDetail: 26,
  patternCost_ProductInternalIssueDetail: "",
  minSizeJobMasterId_ProductInternalIssueDetail: 0,
  maxSizeJobMasterId_ProductInternalIssueDetail: 19,
  patternJobMasterId_ProductInternalIssueDetail: "",
  minSizeProductMaster1Id_ProductInternalIssueDetail: 0,
  maxSizeProductMaster1Id_ProductInternalIssueDetail: 19,
  patternProductMaster1Id_ProductInternalIssueDetail: "",
  minSizeWarehouseId_ProductInternalIssueDetail: 0,
  maxSizeWarehouseId_ProductInternalIssueDetail: 19,
  patternWarehouseId_ProductInternalIssueDetail: "",
  minSizeUnitOfMeasureId1_ProductInternalIssueDetail: 0,
  maxSizeUnitOfMeasureId1_ProductInternalIssueDetail: 19,
  patternUnitOfMeasureId1_ProductInternalIssueDetail: "",
  minSizeUnitOfMeasureId2_ProductInternalIssueDetail: 0,
  maxSizeUnitOfMeasureId2_ProductInternalIssueDetail: 19,
  patternUnitOfMeasureId2_ProductInternalIssueDetail: "",
  minSizeCost1_ProductInternalIssueDetail: 0,
  maxSizeCost1_ProductInternalIssueDetail: 16,
  patternCost1_ProductInternalIssueDetail: "",
  minSizeLotNo_ProductInternalIssueDetail: 0,
  maxSizeLotNo_ProductInternalIssueDetail: 50,
  patternLotNo_ProductInternalIssueDetail: "",
  minSizeIrpDocRefNo_ProductInternalIssueDetail: 0,
  maxSizeIrpDocRefNo_ProductInternalIssueDetail: 20,
  patternIrpDocRefNo_ProductInternalIssueDetail: "",
  minSizeLotNoNew_ProductInternalIssueDetail: 0,
  maxSizeLotNoNew_ProductInternalIssueDetail: 50,
  patternLotNoNew_ProductInternalIssueDetail: "",
  minSizeLotMasterId_ProductInternalIssueDetail: 0,
  maxSizeLotMasterId_ProductInternalIssueDetail: 19,
  patternLotMasterId_ProductInternalIssueDetail: "",
  minSizeProductInternalIssueMasterId_ProductInternalIssueMaster: 0,
  maxSizeProductInternalIssueMasterId_ProductInternalIssueMaster: 19,
  patternProductInternalIssueMasterId_ProductInternalIssueMaster: "",
  minSizeIspCode_ProductInternalIssueMaster: 0,
  maxSizeIspCode_ProductInternalIssueMaster: 20,
  patternIspCode_ProductInternalIssueMaster: "[0-9a-zA-Z/-]+",
  minSizeIspDate_ProductInternalIssueMaster: 0,
  maxSizeIspDate_ProductInternalIssueMaster: 20,
  patternIspDate_ProductInternalIssueMaster: "",
  minSizeIspReferNo_ProductInternalIssueMaster: 0,
  maxSizeIspReferNo_ProductInternalIssueMaster: 100,
  patternIspReferNo_ProductInternalIssueMaster: "",
  minSizeIspStatus_ProductInternalIssueMaster: 0,
  maxSizeIspStatus_ProductInternalIssueMaster: 1,
  patternIspStatus_ProductInternalIssueMaster: "",
  minSizeIspRemark_ProductInternalIssueMaster: 0,
  maxSizeIspRemark_ProductInternalIssueMaster: 255,
  patternIspRemark_ProductInternalIssueMaster: "",
  minSizeCreateOn_ProductInternalIssueMaster: 0,
  maxSizeCreateOn_ProductInternalIssueMaster: 20,
  patternCreateOn_ProductInternalIssueMaster: "",
  minSizeUpdateOn_ProductInternalIssueMaster: 0,
  maxSizeUpdateOn_ProductInternalIssueMaster: 20,
  patternUpdateOn_ProductInternalIssueMaster: "",
  minSizeCompanyCode_ProductInternalIssueMaster: 0,
  maxSizeCompanyCode_ProductInternalIssueMaster: 20,
  patternCompanyCode_ProductInternalIssueMaster: "[0-9a-zA-Z/-]+",
  minSizeDocType_ProductInternalIssueMaster: 0,
  maxSizeDocType_ProductInternalIssueMaster: 10,
  patternDocType_ProductInternalIssueMaster: "",
  minSizeType_ProductInternalIssueMaster: 0,
  maxSizeType_ProductInternalIssueMaster: 5,
  patternType_ProductInternalIssueMaster: "",
  minSizeAstatus_ProductInternalIssueMaster: 0,
  maxSizeAstatus_ProductInternalIssueMaster: 2,
  patternAstatus_ProductInternalIssueMaster: "",
  minSizeDocumentGroupId_ProductInternalIssueMaster: 0,
  maxSizeDocumentGroupId_ProductInternalIssueMaster: 19,
  patternDocumentGroupId_ProductInternalIssueMaster: "",
  minSizeDivisionId_ProductInternalIssueMaster: 0,
  maxSizeDivisionId_ProductInternalIssueMaster: 19,
  patternDivisionId_ProductInternalIssueMaster: "",
  minSizeSectionId_ProductInternalIssueMaster: 0,
  maxSizeSectionId_ProductInternalIssueMaster: 19,
  patternSectionId_ProductInternalIssueMaster: "",
  minSizeEmployeeMasterId3_ProductInternalIssueMaster: 0,
  maxSizeEmployeeMasterId3_ProductInternalIssueMaster: 19,
  patternEmployeeMasterId3_ProductInternalIssueMaster: "",
  minSizeWarehouse1Id_ProductInternalIssueMaster: 0,
  maxSizeWarehouse1Id_ProductInternalIssueMaster: 19,
  patternWarehouse1Id_ProductInternalIssueMaster: "",
  minSizeWarehouse2Id_ProductInternalIssueMaster: 0,
  maxSizeWarehouse2Id_ProductInternalIssueMaster: 19,
  patternWarehouse2Id_ProductInternalIssueMaster: "",
  minSizeDivision2Id_ProductInternalIssueMaster: 0,
  maxSizeDivision2Id_ProductInternalIssueMaster: 19,
  patternDivision2Id_ProductInternalIssueMaster: "",
  minSizeSection2Id_ProductInternalIssueMaster: 0,
  maxSizeSection2Id_ProductInternalIssueMaster: 19,
  patternSection2Id_ProductInternalIssueMaster: "",
  minSizeReceiveTypeCode_ProductInternalIssueMaster: 0,
  maxSizeReceiveTypeCode_ProductInternalIssueMaster: 20,
  patternReceiveTypeCode_ProductInternalIssueMaster: "[0-9a-zA-Z/-]+",
  minSizeUserApprove_ProductInternalIssueMaster: 0,
  maxSizeUserApprove_ProductInternalIssueMaster: 20,
  patternUserApprove_ProductInternalIssueMaster: "",
  minSizeCustomerMasterId_ProductInternalIssueMaster: 0,
  maxSizeCustomerMasterId_ProductInternalIssueMaster: 19,
  patternCustomerMasterId_ProductInternalIssueMaster: "",
  minSizeCheckLot_ProductInternalIssueMaster: 0,
  maxSizeCheckLot_ProductInternalIssueMaster: 2,
  patternCheckLot_ProductInternalIssueMaster: "",
  minSizeProductLotId_ProductLot: 0,
  maxSizeProductLotId_ProductLot: 19,
  patternProductLotId_ProductLot: "",
  minSizeProdLotNo_ProductLot: 0,
  maxSizeProdLotNo_ProductLot: 30,
  patternProdLotNo_ProductLot: "",
  minSizeProdCode_ProductLot: 0,
  maxSizeProdCode_ProductLot: 50,
  patternProdCode_ProductLot: "[0-9a-zA-Z/-]+",
  minSizeProdQty_ProductLot: 0,
  maxSizeProdQty_ProductLot: 26,
  patternProdQty_ProductLot: "",
  minSizeProdPrice_ProductLot: 0,
  maxSizeProdPrice_ProductLot: 26,
  patternProdPrice_ProductLot: "",
  minSizeApprove_ProductLot: 0,
  maxSizeApprove_ProductLot: 2,
  patternApprove_ProductLot: "",
  minSizeCompanyCode_ProductLot: 0,
  maxSizeCompanyCode_ProductLot: 20,
  patternCompanyCode_ProductLot: "[0-9a-zA-Z/-]+",
  minSizeProdBaseQty_ProductLot: 0,
  maxSizeProdBaseQty_ProductLot: 26,
  patternProdBaseQty_ProductLot: "",
  minSizeProdUseBaseQty_ProductLot: 0,
  maxSizeProdUseBaseQty_ProductLot: 26,
  patternProdUseBaseQty_ProductLot: "",
  minSizeProdBasePrice_ProductLot: 0,
  maxSizeProdBasePrice_ProductLot: 26,
  patternProdBasePrice_ProductLot: "",
  minSizeDocDate_ProductLot: 0,
  maxSizeDocDate_ProductLot: 20,
  patternDocDate_ProductLot: "",
  minSizeCreateOn_ProductLot: 0,
  maxSizeCreateOn_ProductLot: 20,
  patternCreateOn_ProductLot: "",
  minSizeDivisionCode_ProductLot: 0,
  maxSizeDivisionCode_ProductLot: 20,
  patternDivisionCode_ProductLot: "[0-9a-zA-Z/-]+",
  minSizeJobCode_ProductLot: 0,
  maxSizeJobCode_ProductLot: 20,
  patternJobCode_ProductLot: "[0-9a-zA-Z/-]+",
  minSizeWhCode_ProductLot: 0,
  maxSizeWhCode_ProductLot: 20,
  patternWhCode_ProductLot: "[0-9a-zA-Z/-]+",
  minSizeProdConvfactor_ProductLot: 0,
  maxSizeProdConvfactor_ProductLot: 26,
  patternProdConvfactor_ProductLot: "",
  minSizeProdUnit_ProductLot: 0,
  maxSizeProdUnit_ProductLot: 20,
  patternProdUnit_ProductLot: "",
  minSizeProdBaseUnit_ProductLot: 0,
  maxSizeProdBaseUnit_ProductLot: 20,
  patternProdBaseUnit_ProductLot: "",
  minSizeVer_ProductLot: 0,
  maxSizeVer_ProductLot: 10,
  patternVer_ProductLot: "",
  minSizeRemark_ProductLot: 0,
  maxSizeRemark_ProductLot: 1000,
  patternRemark_ProductLot: "",
  minSizeLotNo_ProductLot: 0,
  maxSizeLotNo_ProductLot: 50,
  patternLotNo_ProductLot: "",
  minSizeExpireDate_ProductLot: 0,
  maxSizeExpireDate_ProductLot: 20,
  patternExpireDate_ProductLot: "",
  minSizeDocumentNo_ProductLot: 0,
  maxSizeDocumentNo_ProductLot: 20,
  patternDocumentNo_ProductLot: "",
  minSizeStatus_ProductLot: 0,
  maxSizeStatus_ProductLot: 2,
  patternStatus_ProductLot: "",
  minSizeProductMaster1Id_ProductLot: 0,
  maxSizeProductMaster1Id_ProductLot: 19,
  patternProductMaster1Id_ProductLot: "",
  minSizeProdUseBaseQtyLot_ProductLot: 0,
  maxSizeProdUseBaseQtyLot_ProductLot: 26,
  patternProdUseBaseQtyLot_ProductLot: "",
  minSizeProductmaster1s_ProductLot: 0,
  maxSizeProductmaster1s_ProductLot: 19,
  patternProductmaster1s_ProductLot: "",
  minSizeProductReturnDetailId_ProductReturnDetail: 0,
  maxSizeProductReturnDetailId_ProductReturnDetail: 19,
  patternProductReturnDetailId_ProductReturnDetail: "",
  minSizeNumber_ProductReturnDetail: 0,
  maxSizeNumber_ProductReturnDetail: 10,
  patternNumber_ProductReturnDetail: "",
  minSizeProdQty_ProductReturnDetail: 0,
  maxSizeProdQty_ProductReturnDetail: 26,
  patternProdQty_ProductReturnDetail: "",
  minSizeProdConvfactor_ProductReturnDetail: 0,
  maxSizeProdConvfactor_ProductReturnDetail: 26,
  patternProdConvfactor_ProductReturnDetail: "",
  minSizeProdBaseQty_ProductReturnDetail: 0,
  maxSizeProdBaseQty_ProductReturnDetail: 26,
  patternProdBaseQty_ProductReturnDetail: "",
  minSizeType_ProductReturnDetail: 0,
  maxSizeType_ProductReturnDetail: 1,
  patternType_ProductReturnDetail: "",
  minSizeCompanyCode_ProductReturnDetail: 0,
  maxSizeCompanyCode_ProductReturnDetail: 20,
  patternCompanyCode_ProductReturnDetail: "[0-9a-zA-Z/-]+",
  minSizeSerialNo_ProductReturnDetail: 0,
  maxSizeSerialNo_ProductReturnDetail: 255,
  patternSerialNo_ProductReturnDetail: "",
  minSizeSerialNoText_ProductReturnDetail: 0,
  maxSizeSerialNoText_ProductReturnDetail: 9999,
  patternSerialNoText_ProductReturnDetail: "",
  minSizeProdLotNo_ProductReturnDetail: 0,
  maxSizeProdLotNo_ProductReturnDetail: 30,
  patternProdLotNo_ProductReturnDetail: "",
  minSizeCost_ProductReturnDetail: 0,
  maxSizeCost_ProductReturnDetail: 26,
  patternCost_ProductReturnDetail: "",
  minSizeCostBkk_ProductReturnDetail: 0,
  maxSizeCostBkk_ProductReturnDetail: 26,
  patternCostBkk_ProductReturnDetail: "",
  minSizeIssueDetailNo_ProductReturnDetail: 0,
  maxSizeIssueDetailNo_ProductReturnDetail: 10,
  patternIssueDetailNo_ProductReturnDetail: "",
  minSizeProductMaster1Id_ProductReturnDetail: 0,
  maxSizeProductMaster1Id_ProductReturnDetail: 19,
  patternProductMaster1Id_ProductReturnDetail: "",
  minSizeWarehouseId_ProductReturnDetail: 0,
  maxSizeWarehouseId_ProductReturnDetail: 19,
  patternWarehouseId_ProductReturnDetail: "",
  minSizeUnitOfMeasureId1_ProductReturnDetail: 0,
  maxSizeUnitOfMeasureId1_ProductReturnDetail: 19,
  patternUnitOfMeasureId1_ProductReturnDetail: "",
  minSizeUnitOfMeasureId2_ProductReturnDetail: 0,
  maxSizeUnitOfMeasureId2_ProductReturnDetail: 19,
  patternUnitOfMeasureId2_ProductReturnDetail: "",
  minSizeJobMaster_ProductReturnDetail: 0,
  maxSizeJobMaster_ProductReturnDetail: 19,
  patternJobMaster_ProductReturnDetail: "",
  minSizeCost1_ProductReturnDetail: 0,
  maxSizeCost1_ProductReturnDetail: 26,
  patternCost1_ProductReturnDetail: "",
  minSizeRemark_ProductReturnDetail: 0,
  maxSizeRemark_ProductReturnDetail: 150,
  patternRemark_ProductReturnDetail: "",
  minSizeLotNo_ProductReturnDetail: 0,
  maxSizeLotNo_ProductReturnDetail: 50,
  patternLotNo_ProductReturnDetail: "",
  minSizeRcvBalance_ProductReturnDetail: 0,
  maxSizeRcvBalance_ProductReturnDetail: 26,
  patternRcvBalance_ProductReturnDetail: "",
  minSizeProdPrice_ProductReturnDetail: 0,
  maxSizeProdPrice_ProductReturnDetail: 26,
  patternProdPrice_ProductReturnDetail: "",
  minSizeLotNoNew_ProductReturnDetail: 0,
  maxSizeLotNoNew_ProductReturnDetail: 50,
  patternLotNoNew_ProductReturnDetail: "",
  minSizeExpireDate_ProductReturnDetail: 0,
  maxSizeExpireDate_ProductReturnDetail: 20,
  patternExpireDate_ProductReturnDetail: "",
  minSizeProductReturnMasterId_ProductReturnMaster: 0,
  maxSizeProductReturnMasterId_ProductReturnMaster: 19,
  patternProductReturnMasterId_ProductReturnMaster: "",
  minSizeIrpCode_ProductReturnMaster: 0,
  maxSizeIrpCode_ProductReturnMaster: 20,
  patternIrpCode_ProductReturnMaster: "[0-9a-zA-Z/-]+",
  minSizeIrpDate_ProductReturnMaster: 0,
  maxSizeIrpDate_ProductReturnMaster: 20,
  patternIrpDate_ProductReturnMaster: "",
  minSizeIrpStatus_ProductReturnMaster: 0,
  maxSizeIrpStatus_ProductReturnMaster: 1,
  patternIrpStatus_ProductReturnMaster: "",
  minSizeIrpRemark_ProductReturnMaster: 0,
  maxSizeIrpRemark_ProductReturnMaster: 1000,
  patternIrpRemark_ProductReturnMaster: "",
  minSizeCreateOn_ProductReturnMaster: 0,
  maxSizeCreateOn_ProductReturnMaster: 20,
  patternCreateOn_ProductReturnMaster: "",
  minSizeUpdateOn_ProductReturnMaster: 0,
  maxSizeUpdateOn_ProductReturnMaster: 20,
  patternUpdateOn_ProductReturnMaster: "",
  minSizeCompanyCode_ProductReturnMaster: 0,
  maxSizeCompanyCode_ProductReturnMaster: 20,
  patternCompanyCode_ProductReturnMaster: "[0-9a-zA-Z/-]+",
  minSizeDocType_ProductReturnMaster: 0,
  maxSizeDocType_ProductReturnMaster: 10,
  patternDocType_ProductReturnMaster: "",
  minSizeAstatus_ProductReturnMaster: 0,
  maxSizeAstatus_ProductReturnMaster: 2,
  patternAstatus_ProductReturnMaster: "",
  minSizeWhCode_ProductReturnMaster: 0,
  maxSizeWhCode_ProductReturnMaster: 19,
  patternWhCode_ProductReturnMaster: "[0-9a-zA-Z/-]+",
  minSizeProductInternalIssueMasterId_ProductReturnMaster: 0,
  maxSizeProductInternalIssueMasterId_ProductReturnMaster: 19,
  patternProductInternalIssueMasterId_ProductReturnMaster: "",
  minSizeDocumentGroupId_ProductReturnMaster: 0,
  maxSizeDocumentGroupId_ProductReturnMaster: 19,
  patternDocumentGroupId_ProductReturnMaster: "",
  minSizeDivisionId_ProductReturnMaster: 0,
  maxSizeDivisionId_ProductReturnMaster: 19,
  patternDivisionId_ProductReturnMaster: "",
  minSizeSectionId_ProductReturnMaster: 0,
  maxSizeSectionId_ProductReturnMaster: 19,
  patternSectionId_ProductReturnMaster: "",
  minSizeEmployeeMasterId3_ProductReturnMaster: 0,
  maxSizeEmployeeMasterId3_ProductReturnMaster: 19,
  patternEmployeeMasterId3_ProductReturnMaster: "",
  minSizeIrpRefDocNo_ProductReturnMaster: 0,
  maxSizeIrpRefDocNo_ProductReturnMaster: 50,
  patternIrpRefDocNo_ProductReturnMaster: "",
  minSizeReceiveTypeCode_ProductReturnMaster: 0,
  maxSizeReceiveTypeCode_ProductReturnMaster: 20,
  patternReceiveTypeCode_ProductReturnMaster: "[0-9a-zA-Z/-]+",
  minSizeReturner_ProductReturnMaster: 0,
  maxSizeReturner_ProductReturnMaster: 100,
  patternReturner_ProductReturnMaster: "",
  minSizeDivisionReturn_ProductReturnMaster: 0,
  maxSizeDivisionReturn_ProductReturnMaster: 100,
  patternDivisionReturn_ProductReturnMaster: "",
  minSizeDateReturn_ProductReturnMaster: 0,
  maxSizeDateReturn_ProductReturnMaster: 20,
  patternDateReturn_ProductReturnMaster: "",
  minSizeCheckReturnFromRequest_ProductReturnMaster: 0,
  maxSizeCheckReturnFromRequest_ProductReturnMaster: 2,
  patternCheckReturnFromRequest_ProductReturnMaster: "",
  minSizeProductReturnMasterId_ProductReturnMasterJobMaster: 0,
  maxSizeProductReturnMasterId_ProductReturnMasterJobMaster: 19,
  patternProductReturnMasterId_ProductReturnMasterJobMaster: "",
  minSizeJobMasterId_ProductReturnMasterJobMaster: 0,
  maxSizeJobMasterId_ProductReturnMasterJobMaster: 19,
  patternJobMasterId_ProductReturnMasterJobMaster: "",
  minSizeProductTransactionLotId_ProductTransactionLot: 0,
  maxSizeProductTransactionLotId_ProductTransactionLot: 19,
  patternProductTransactionLotId_ProductTransactionLot: "",
  minSizeDocNo_ProductTransactionLot: 0,
  maxSizeDocNo_ProductTransactionLot: 20,
  patternDocNo_ProductTransactionLot: "",
  minSizeDocDate_ProductTransactionLot: 0,
  maxSizeDocDate_ProductTransactionLot: 20,
  patternDocDate_ProductTransactionLot: "",
  minSizeProductLotId_ProductTransactionLot: 0,
  maxSizeProductLotId_ProductTransactionLot: 19,
  patternProductLotId_ProductTransactionLot: "",
  minSizeProdLotNo_ProductTransactionLot: 0,
  maxSizeProdLotNo_ProductTransactionLot: 30,
  patternProdLotNo_ProductTransactionLot: "",
  minSizeProdBaseQty_ProductTransactionLot: 0,
  maxSizeProdBaseQty_ProductTransactionLot: 26,
  patternProdBaseQty_ProductTransactionLot: "",
  minSizeProdCostPrice_ProductTransactionLot: 0,
  maxSizeProdCostPrice_ProductTransactionLot: 26,
  patternProdCostPrice_ProductTransactionLot: "",
  minSizeApproveDate_ProductTransactionLot: 0,
  maxSizeApproveDate_ProductTransactionLot: 20,
  patternApproveDate_ProductTransactionLot: "",
  minSizeCompanyCode_ProductTransactionLot: 0,
  maxSizeCompanyCode_ProductTransactionLot: 20,
  patternCompanyCode_ProductTransactionLot: "[0-9a-zA-Z/-]+",
  minSizeTypeCalculate_ProductTransactionLot: 0,
  maxSizeTypeCalculate_ProductTransactionLot: 5,
  patternTypeCalculate_ProductTransactionLot: "",
  minSizeDivisionCode_ProductTransactionLot: 0,
  maxSizeDivisionCode_ProductTransactionLot: 20,
  patternDivisionCode_ProductTransactionLot: "[0-9a-zA-Z/-]+",
  minSizeProdCode_ProductTransactionLot: 0,
  maxSizeProdCode_ProductTransactionLot: 25,
  patternProdCode_ProductTransactionLot: "[0-9a-zA-Z/-]+",
  minSizeJobCode_ProductTransactionLot: 0,
  maxSizeJobCode_ProductTransactionLot: 20,
  patternJobCode_ProductTransactionLot: "[0-9a-zA-Z/-]+",
  minSizeWhCode_ProductTransactionLot: 0,
  maxSizeWhCode_ProductTransactionLot: 20,
  patternWhCode_ProductTransactionLot: "[0-9a-zA-Z/-]+",
  minSizeProdConvfactor_ProductTransactionLot: 0,
  maxSizeProdConvfactor_ProductTransactionLot: 26,
  patternProdConvfactor_ProductTransactionLot: "",
  minSizeProdUnit_ProductTransactionLot: 0,
  maxSizeProdUnit_ProductTransactionLot: 20,
  patternProdUnit_ProductTransactionLot: "",
  minSizeProdBaseUnit_ProductTransactionLot: 0,
  maxSizeProdBaseUnit_ProductTransactionLot: 20,
  patternProdBaseUnit_ProductTransactionLot: "",
  minSizeProdQty_ProductTransactionLot: 0,
  maxSizeProdQty_ProductTransactionLot: 26,
  patternProdQty_ProductTransactionLot: "",
  minSizeRemark_ProductTransactionLot: 0,
  maxSizeRemark_ProductTransactionLot: 500,
  patternRemark_ProductTransactionLot: "",
  minSizeLotNo_ProductTransactionLot: 0,
  maxSizeLotNo_ProductTransactionLot: 50,
  patternLotNo_ProductTransactionLot: "",
  minSizeExpireDate_ProductTransactionLot: 0,
  maxSizeExpireDate_ProductTransactionLot: 50,
  patternExpireDate_ProductTransactionLot: "",
  minSizeProductMaster1Id_ProductTransactionLot: 0,
  maxSizeProductMaster1Id_ProductTransactionLot: 19,
  patternProductMaster1Id_ProductTransactionLot: "",
  minSizeProductTransferDetailId_ProductTransferDetail: 0,
  maxSizeProductTransferDetailId_ProductTransferDetail: 19,
  patternProductTransferDetailId_ProductTransferDetail: "",
  minSizeNumber_ProductTransferDetail: 0,
  maxSizeNumber_ProductTransferDetail: 10,
  patternNumber_ProductTransferDetail: "",
  minSizeProdQty_ProductTransferDetail: 0,
  maxSizeProdQty_ProductTransferDetail: 26,
  patternProdQty_ProductTransferDetail: "",
  minSizeProdConvFactor_ProductTransferDetail: 0,
  maxSizeProdConvFactor_ProductTransferDetail: 26,
  patternProdConvFactor_ProductTransferDetail: "",
  minSizeProdBaseQty_ProductTransferDetail: 0,
  maxSizeProdBaseQty_ProductTransferDetail: 26,
  patternProdBaseQty_ProductTransferDetail: "",
  minSizeCost_ProductTransferDetail: 0,
  maxSizeCost_ProductTransferDetail: 26,
  patternCost_ProductTransferDetail: "",
  minSizeCompanyCode_ProductTransferDetail: 0,
  maxSizeCompanyCode_ProductTransferDetail: 20,
  patternCompanyCode_ProductTransferDetail: "[0-9a-zA-Z/-]+",
  minSizeSerialNo_ProductTransferDetail: 0,
  maxSizeSerialNo_ProductTransferDetail: 255,
  patternSerialNo_ProductTransferDetail: "",
  minSizeSerialNoText_ProductTransferDetail: 0,
  maxSizeSerialNoText_ProductTransferDetail: 9999,
  patternSerialNoText_ProductTransferDetail: "",
  minSizeDivisionDestinationId_ProductTransferDetail: 0,
  maxSizeDivisionDestinationId_ProductTransferDetail: 19,
  patternDivisionDestinationId_ProductTransferDetail: "",
  minSizeDivisionSourceId_ProductTransferDetail: 0,
  maxSizeDivisionSourceId_ProductTransferDetail: 19,
  patternDivisionSourceId_ProductTransferDetail: "",
  minSizeProductMaster1Id_ProductTransferDetail: 0,
  maxSizeProductMaster1Id_ProductTransferDetail: 19,
  patternProductMaster1Id_ProductTransferDetail: "",
  minSizeWarehouseId1_ProductTransferDetail: 0,
  maxSizeWarehouseId1_ProductTransferDetail: 19,
  patternWarehouseId1_ProductTransferDetail: "",
  minSizeWarehouseId2_ProductTransferDetail: 0,
  maxSizeWarehouseId2_ProductTransferDetail: 19,
  patternWarehouseId2_ProductTransferDetail: "",
  minSizeJobMasterId_ProductTransferDetail: 0,
  maxSizeJobMasterId_ProductTransferDetail: 19,
  patternJobMasterId_ProductTransferDetail: "",
  minSizeUnitOfMeasureId_ProductTransferDetail: 0,
  maxSizeUnitOfMeasureId_ProductTransferDetail: 19,
  patternUnitOfMeasureId_ProductTransferDetail: "",
  minSizeUnitOfMeasureId1_ProductTransferDetail: 0,
  maxSizeUnitOfMeasureId1_ProductTransferDetail: 19,
  patternUnitOfMeasureId1_ProductTransferDetail: "",
  minSizeCost1_ProductTransferDetail: 0,
  maxSizeCost1_ProductTransferDetail: 26,
  patternCost1_ProductTransferDetail: "",
  minSizeProdNetPrice_ProductTransferDetail: 0,
  maxSizeProdNetPrice_ProductTransferDetail: 26,
  patternProdNetPrice_ProductTransferDetail: "",
  minSizeRemark_ProductTransferDetail: 0,
  maxSizeRemark_ProductTransferDetail: 255,
  patternRemark_ProductTransferDetail: "",
  minSizeLotNo_ProductTransferDetail: 0,
  maxSizeLotNo_ProductTransferDetail: 50,
  patternLotNo_ProductTransferDetail: "",
  minSizeLotMasterId_ProductTransferDetail: 0,
  maxSizeLotMasterId_ProductTransferDetail: 19,
  patternLotMasterId_ProductTransferDetail: "",
  minSizeProductTransferMasterId_ProductTransferMaster: 0,
  maxSizeProductTransferMasterId_ProductTransferMaster: 19,
  patternProductTransferMasterId_ProductTransferMaster: "",
  minSizeTrpCode_ProductTransferMaster: 0,
  maxSizeTrpCode_ProductTransferMaster: 20,
  patternTrpCode_ProductTransferMaster: "[0-9a-zA-Z/-]+",
  minSizeTrpDate_ProductTransferMaster: 0,
  maxSizeTrpDate_ProductTransferMaster: 20,
  patternTrpDate_ProductTransferMaster: "",
  minSizeTrpReferNo_ProductTransferMaster: 0,
  maxSizeTrpReferNo_ProductTransferMaster: 100,
  patternTrpReferNo_ProductTransferMaster: "",
  minSizeTrpStatus_ProductTransferMaster: 0,
  maxSizeTrpStatus_ProductTransferMaster: 1,
  patternTrpStatus_ProductTransferMaster: "",
  minSizeTrpRemark_ProductTransferMaster: 0,
  maxSizeTrpRemark_ProductTransferMaster: 255,
  patternTrpRemark_ProductTransferMaster: "",
  minSizeCreateOn_ProductTransferMaster: 0,
  maxSizeCreateOn_ProductTransferMaster: 20,
  patternCreateOn_ProductTransferMaster: "",
  minSizeUpdateOn_ProductTransferMaster: 0,
  maxSizeUpdateOn_ProductTransferMaster: 20,
  patternUpdateOn_ProductTransferMaster: "",
  minSizeCompanyCode_ProductTransferMaster: 0,
  maxSizeCompanyCode_ProductTransferMaster: 20,
  patternCompanyCode_ProductTransferMaster: "[0-9a-zA-Z/-]+",
  minSizeAstatus_ProductTransferMaster: 0,
  maxSizeAstatus_ProductTransferMaster: 2,
  patternAstatus_ProductTransferMaster: "",
  minSizeDocumentGroupId_ProductTransferMaster: 0,
  maxSizeDocumentGroupId_ProductTransferMaster: 19,
  patternDocumentGroupId_ProductTransferMaster: "",
  minSizeDivisionId_ProductTransferMaster: 0,
  maxSizeDivisionId_ProductTransferMaster: 19,
  patternDivisionId_ProductTransferMaster: "",
  minSizeSectionId_ProductTransferMaster: 0,
  maxSizeSectionId_ProductTransferMaster: 19,
  patternSectionId_ProductTransferMaster: "",
  minSizeEmployeeMasterId3_ProductTransferMaster: 0,
  maxSizeEmployeeMasterId3_ProductTransferMaster: 19,
  patternEmployeeMasterId3_ProductTransferMaster: "",
  minSizeTrpTotal_ProductTransferMaster: 0,
  maxSizeTrpTotal_ProductTransferMaster: 26,
  patternTrpTotal_ProductTransferMaster: "",
  minSizeDivisionSourceId_ProductTransferMaster: 0,
  maxSizeDivisionSourceId_ProductTransferMaster: 19,
  patternDivisionSourceId_ProductTransferMaster: "",
  minSizeDivisionDestinationId_ProductTransferMaster: 0,
  maxSizeDivisionDestinationId_ProductTransferMaster: 19,
  patternDivisionDestinationId_ProductTransferMaster: "",
  minSizeWhSourceId_ProductTransferMaster: 0,
  maxSizeWhSourceId_ProductTransferMaster: 19,
  patternWhSourceId_ProductTransferMaster: "",
  minSizeWhDestinationId_ProductTransferMaster: 0,
  maxSizeWhDestinationId_ProductTransferMaster: 19,
  patternWhDestinationId_ProductTransferMaster: "",
  minSizeJobMasterId_ProductTransferMaster: 0,
  maxSizeJobMasterId_ProductTransferMaster: 19,
  patternJobMasterId_ProductTransferMaster: "",
  minSizeRequestInvoiceDetailId_RequestInvoiceDetail: 0,
  maxSizeRequestInvoiceDetailId_RequestInvoiceDetail: 19,
  patternRequestInvoiceDetailId_RequestInvoiceDetail: "",
  minSizeDetailNo_RequestInvoiceDetail: 0,
  maxSizeDetailNo_RequestInvoiceDetail: 10,
  patternDetailNo_RequestInvoiceDetail: "",
  minSizeProdDescription_RequestInvoiceDetail: 0,
  maxSizeProdDescription_RequestInvoiceDetail: 150,
  patternProdDescription_RequestInvoiceDetail: "",
  minSizeProdUnit_RequestInvoiceDetail: 0,
  maxSizeProdUnit_RequestInvoiceDetail: 30,
  patternProdUnit_RequestInvoiceDetail: "",
  minSizeProdQty_RequestInvoiceDetail: 0,
  maxSizeProdQty_RequestInvoiceDetail: 26,
  patternProdQty_RequestInvoiceDetail: "",
  minSizeStatus_RequestInvoiceDetail: 0,
  maxSizeStatus_RequestInvoiceDetail: 2,
  patternStatus_RequestInvoiceDetail: "",
  minSizeCompanyCode_RequestInvoiceDetail: 0,
  maxSizeCompanyCode_RequestInvoiceDetail: 20,
  patternCompanyCode_RequestInvoiceDetail: "[0-9a-zA-Z/-]+",
  minSizeRequestInvoicMasterId_RequestInvoiceDetail: 0,
  maxSizeRequestInvoicMasterId_RequestInvoiceDetail: 19,
  patternRequestInvoicMasterId_RequestInvoiceDetail: "",
  minSizeRequestInvoicMasterId_RequestInvoicMaster: 0,
  maxSizeRequestInvoicMasterId_RequestInvoicMaster: 19,
  patternRequestInvoicMasterId_RequestInvoicMaster: "",
  minSizeReqInvDocNo_RequestInvoicMaster: 0,
  maxSizeReqInvDocNo_RequestInvoicMaster: 20,
  patternReqInvDocNo_RequestInvoicMaster: "",
  minSizeReqInvDocDate_RequestInvoicMaster: 0,
  maxSizeReqInvDocDate_RequestInvoicMaster: 20,
  patternReqInvDocDate_RequestInvoicMaster: "",
  minSizeReqInvReferDocument_RequestInvoicMaster: 0,
  maxSizeReqInvReferDocument_RequestInvoicMaster: 20,
  patternReqInvReferDocument_RequestInvoicMaster: "",
  minSizeReqInvDateRequire_RequestInvoicMaster: 0,
  maxSizeReqInvDateRequire_RequestInvoicMaster: 20,
  patternReqInvDateRequire_RequestInvoicMaster: "",
  minSizeReqInvRemark_RequestInvoicMaster: 0,
  maxSizeReqInvRemark_RequestInvoicMaster: 150,
  patternReqInvRemark_RequestInvoicMaster: "",
  minSizeReqInvStatus_RequestInvoicMaster: 0,
  maxSizeReqInvStatus_RequestInvoicMaster: 2,
  patternReqInvStatus_RequestInvoicMaster: "",
  minSizeReqInvLinkDocNo_RequestInvoicMaster: 0,
  maxSizeReqInvLinkDocNo_RequestInvoicMaster: 20,
  patternReqInvLinkDocNo_RequestInvoicMaster: "",
  minSizeCreateOn_RequestInvoicMaster: 0,
  maxSizeCreateOn_RequestInvoicMaster: 20,
  patternCreateOn_RequestInvoicMaster: "",
  minSizeUpdateOn_RequestInvoicMaster: 0,
  maxSizeUpdateOn_RequestInvoicMaster: 20,
  patternUpdateOn_RequestInvoicMaster: "",
  minSizeCompanyCode_RequestInvoicMaster: 0,
  maxSizeCompanyCode_RequestInvoicMaster: 20,
  patternCompanyCode_RequestInvoicMaster: "[0-9a-zA-Z/-]+",
  minSizeAstatus_RequestInvoicMaster: 0,
  maxSizeAstatus_RequestInvoicMaster: 2,
  patternAstatus_RequestInvoicMaster: "",
  minSizeDocumentGroupId_RequestInvoicMaster: 0,
  maxSizeDocumentGroupId_RequestInvoicMaster: 19,
  patternDocumentGroupId_RequestInvoicMaster: "",
  minSizeRequesterEmpId_RequestInvoicMaster: 0,
  maxSizeRequesterEmpId_RequestInvoicMaster: 19,
  patternRequesterEmpId_RequestInvoicMaster: "",
  minSizeDivisionId_RequestInvoicMaster: 0,
  maxSizeDivisionId_RequestInvoicMaster: 19,
  patternDivisionId_RequestInvoicMaster: "",
  minSizeSectionId_RequestInvoicMaster: 0,
  maxSizeSectionId_RequestInvoicMaster: 19,
  patternSectionId_RequestInvoicMaster: "",
  minSizeCreateByEmpId_RequestInvoicMaster: 0,
  maxSizeCreateByEmpId_RequestInvoicMaster: 19,
  patternCreateByEmpId_RequestInvoicMaster: "",
  minSizeUpdateByEmpId_RequestInvoicMaster: 0,
  maxSizeUpdateByEmpId_RequestInvoicMaster: 19,
  patternUpdateByEmpId_RequestInvoicMaster: "",
  minSizeRequestInvoicMasterId_RequestInvoicMasterJobMaster: 0,
  maxSizeRequestInvoicMasterId_RequestInvoicMasterJobMaster: 19,
  patternRequestInvoicMasterId_RequestInvoicMasterJobMaster: "",
  minSizeJobMasterId_RequestInvoicMasterJobMaster: 0,
  maxSizeJobMasterId_RequestInvoicMasterJobMaster: 19,
  patternJobMasterId_RequestInvoicMasterJobMaster: "",
  minSizeProductReturnDetailId_ReturnDetailReturnMaster: 0,
  maxSizeProductReturnDetailId_ReturnDetailReturnMaster: 19,
  patternProductReturnDetailId_ReturnDetailReturnMaster: "",
  minSizeProductReturnMasterId_ReturnDetailReturnMaster: 0,
  maxSizeProductReturnMasterId_ReturnDetailReturnMaster: 19,
  patternProductReturnMasterId_ReturnDetailReturnMaster: "",
  minSizeProductTransferDetailId_TransferDetailTransferMaster: 0,
  maxSizeProductTransferDetailId_TransferDetailTransferMaster: 19,
  patternProductTransferDetailId_TransferDetailTransferMaster: "",
  minSizeProductTransferMasterId_TransferDetailTransferMaster: 0,
  maxSizeProductTransferMasterId_TransferDetailTransferMaster: 19,
  patternProductTransferMasterId_TransferDetailTransferMaster: "",
  patternRefer_AdvancePayment: "",
  minSizeRefer_AdvancePayment: 0,
  maxSizeRefer_AdvancePayment: 20,
  minSizePurchaseOrderMasterId_PurchaseOrderMaster: 0,
  maxSizePurchaseOrderMasterId_PurchaseOrderMaster: 19,
  patternPurchaseOrderMasterId_PurchaseOrderMaster: "",
  minSizePoDocNo_PurchaseOrderMaster: 0,
  maxSizePoDocNo_PurchaseOrderMaster: 20,
  patternPoDocNo_PurchaseOrderMaster: "",
  minSizePoDocDate_PurchaseOrderMaster: 0,
  maxSizePoDocDate_PurchaseOrderMaster: 20,
  patternPoDocDate_PurchaseOrderMaster: "",
  minSizePoDateRequire_PurchaseOrderMaster: 0,
  maxSizePoDateRequire_PurchaseOrderMaster: 20,
  patternPoDateRequire_PurchaseOrderMaster: "",
  minSizePoTotal_PurchaseOrderMaster: 0,
  maxSizePoTotal_PurchaseOrderMaster: 26,
  patternPoTotal_PurchaseOrderMaster: "",
  minSizePoTotalNoVat_PurchaseOrderMaster: 0,
  maxSizePoTotalNoVat_PurchaseOrderMaster: 26,
  patternPoTotalNoVat_PurchaseOrderMaster: "",
  minSizePoDiscRate_PurchaseOrderMaster: 0,
  maxSizePoDiscRate_PurchaseOrderMaster: 26,
  patternPoDiscRate_PurchaseOrderMaster: "",
  minSizePoDiscAmt_PurchaseOrderMaster: 0,
  maxSizePoDiscAmt_PurchaseOrderMaster: 26,
  patternPoDiscAmt_PurchaseOrderMaster: "",
  minSizePoVatRate_PurchaseOrderMaster: 0,
  maxSizePoVatRate_PurchaseOrderMaster: 26,
  patternPoVatRate_PurchaseOrderMaster: "",
  minSizePoVatAmt_PurchaseOrderMaster: 0,
  maxSizePoVatAmt_PurchaseOrderMaster: 26,
  patternPoVatAmt_PurchaseOrderMaster: "",
  minSizePoNetAmt_PurchaseOrderMaster: 0,
  maxSizePoNetAmt_PurchaseOrderMaster: 26,
  patternPoNetAmt_PurchaseOrderMaster: "",
  minSizePoRemark_PurchaseOrderMaster: 0,
  maxSizePoRemark_PurchaseOrderMaster: 1000,
  patternPoRemark_PurchaseOrderMaster: "",
  minSizePoStatus_PurchaseOrderMaster: 0,
  maxSizePoStatus_PurchaseOrderMaster: 2,
  patternPoStatus_PurchaseOrderMaster: "",
  minSizePoLinkDocNO_PurchaseOrderMaster: 0,
  maxSizePoLinkDocNO_PurchaseOrderMaster: 20,
  patternPoLinkDocNO_PurchaseOrderMaster: "",
  minSizeCreateOn_PurchaseOrderMaster: 0,
  maxSizeCreateOn_PurchaseOrderMaster: 20,
  patternCreateOn_PurchaseOrderMaster: "",
  minSizeUpdateOn_PurchaseOrderMaster: 0,
  maxSizeUpdateOn_PurchaseOrderMaster: 20,
  patternUpdateOn_PurchaseOrderMaster: "",
  minSizeCompanyCode_PurchaseOrderMaster: 0,
  maxSizeCompanyCode_PurchaseOrderMaster: 20,
  patternCompanyCode_PurchaseOrderMaster: "[0-9a-zA-Z/-]+",
  minSizeVatInclude_PurchaseOrderMaster: 0,
  maxSizeVatInclude_PurchaseOrderMaster: 2,
  patternVatInclude_PurchaseOrderMaster: "",
  minSizeOperateBy_PurchaseOrderMaster: 0,
  maxSizeOperateBy_PurchaseOrderMaster: 3,
  patternOperateBy_PurchaseOrderMaster: "",
  minSizeExchangeRate_PurchaseOrderMaster: 0,
  maxSizeExchangeRate_PurchaseOrderMaster: 26,
  patternExchangeRate_PurchaseOrderMaster: "",
  minSizeExchageRateDate_PurchaseOrderMaster: 0,
  maxSizeExchageRateDate_PurchaseOrderMaster: 20,
  patternExchageRateDate_PurchaseOrderMaster: "",
  minSizeAstatus_PurchaseOrderMaster: 0,
  maxSizeAstatus_PurchaseOrderMaster: 2,
  patternAstatus_PurchaseOrderMaster: "",
  minSizeReviseNo_PurchaseOrderMaster: 0,
  maxSizeReviseNo_PurchaseOrderMaster: 10,
  patternReviseNo_PurchaseOrderMaster: "",
  minSizePrintStatus_PurchaseOrderMaster: 0,
  maxSizePrintStatus_PurchaseOrderMaster: 2,
  patternPrintStatus_PurchaseOrderMaster: "",
  minSizeCurrencyId_PurchaseOrderMaster: 0,
  maxSizeCurrencyId_PurchaseOrderMaster: 19,
  patternCurrencyId_PurchaseOrderMaster: "",
  minSizeDocumentGroupId_PurchaseOrderMaster: 0,
  maxSizeDocumentGroupId_PurchaseOrderMaster: 19,
  patternDocumentGroupId_PurchaseOrderMaster: "",
  minSizeSupplierMasterId_PurchaseOrderMaster: 0,
  maxSizeSupplierMasterId_PurchaseOrderMaster: 19,
  patternSupplierMasterId_PurchaseOrderMaster: "",
  minSizeEmployeeMasterId_PurchaseOrderMaster: 0,
  maxSizeEmployeeMasterId_PurchaseOrderMaster: 19,
  patternEmployeeMasterId_PurchaseOrderMaster: "",
  minSizeDivisionId_PurchaseOrderMaster: 0,
  maxSizeDivisionId_PurchaseOrderMaster: 19,
  patternDivisionId_PurchaseOrderMaster: "",
  minSizeSectionId_PurchaseOrderMaster: 0,
  maxSizeSectionId_PurchaseOrderMaster: 19,
  patternSectionId_PurchaseOrderMaster: "",
  minSizeCreateByEmpId_PurchaseOrderMaster: 0,
  maxSizeCreateByEmpId_PurchaseOrderMaster: 19,
  patternCreateByEmpId_PurchaseOrderMaster: "",
  minSizeUpdateByEmpId_PurchaseOrderMaster: 0,
  maxSizeUpdateByEmpId_PurchaseOrderMaster: 19,
  patternUpdateByEmpId_PurchaseOrderMaster: "",
  minSizePoDeliveryDate_PurchaseOrderMaster: 0,
  maxSizePoDeliveryDate_PurchaseOrderMaster: 20,
  patternPoDeliveryDate_PurchaseOrderMaster: "",
  minSizeDocumentDateClose_PurchaseOrderMaster: 0,
  maxSizeDocumentDateClose_PurchaseOrderMaster: 20,
  patternDocumentDateClose_PurchaseOrderMaster: "",
  minSizeUserCloseEmpId_PurchaseOrderMaster: 0,
  maxSizeUserCloseEmpId_PurchaseOrderMaster: 19,
  patternUserCloseEmpId_PurchaseOrderMaster: "",
  minSizeUserApprove_PurchaseOrderMaster: 0,
  maxSizeUserApprove_PurchaseOrderMaster: 20,
  patternUserApprove_PurchaseOrderMaster: "",
  minSizeSuppAddress_PurchaseOrderMaster: 0,
  maxSizeSuppAddress_PurchaseOrderMaster: 250,
  patternSuppAddress_PurchaseOrderMaster: "",
  minSizeSuppDivisionNo_PurchaseOrderMaster: 0,
  maxSizeSuppDivisionNo_PurchaseOrderMaster: 20,
  patternSuppDivisionNo_PurchaseOrderMaster: "",
  minSizeDivisionSuppDetailId_PurchaseOrderMaster: 0,
  maxSizeDivisionSuppDetailId_PurchaseOrderMaster: 19,
  patternDivisionSuppDetailId_PurchaseOrderMaster: "",
  minSizeSuppTName_PurchaseOrderMaster: 0,
  maxSizeSuppTName_PurchaseOrderMaster: 255,
  patternSuppTName_PurchaseOrderMaster: "",
  minSizeVoucherMasterId_VoucherMaster: 0,
  maxSizeVoucherMasterId_VoucherMaster: 19,
  patternVoucherMasterId_VoucherMaster: "",
  minSizeVoucherCode_VoucherMaster: 0,
  maxSizeVoucherCode_VoucherMaster: 50,
  patternVoucherCode_VoucherMaster: "[0-9a-zA-Z/-]+",
  minSizeVoucherCodeOld_VoucherMaster: 0,
  maxSizeVoucherCodeOld_VoucherMaster: 50,
  patternVoucherCodeOld_VoucherMaster: "[0-9a-zA-Z/-]+",
  minSizeVoucherDate_VoucherMaster: 0,
  maxSizeVoucherDate_VoucherMaster: 30,
  patternVoucherDate_VoucherMaster: "",
  minSizeVoucherDocRefNo_VoucherMaster: 0,
  maxSizeVoucherDocRefNo_VoucherMaster: 100,
  patternVoucherDocRefNo_VoucherMaster: "",
  minSizeVoucherDebit_VoucherMaster: 0,
  maxSizeVoucherDebit_VoucherMaster: 26,
  patternVoucherDebit_VoucherMaster: "",
  minSizeVoucherCredit_VoucherMaster: 0,
  maxSizeVoucherCredit_VoucherMaster: 26,
  patternVoucherCredit_VoucherMaster: "",
  minSizeCreateBy_VoucherMaster: 0,
  maxSizeCreateBy_VoucherMaster: 50,
  patternCreateBy_VoucherMaster: "",
  minSizeCreateOn_VoucherMaster: 0,
  maxSizeCreateOn_VoucherMaster: 50,
  patternCreateOn_VoucherMaster: "",
  minSizeUpdateBy_VoucherMaster: 0,
  maxSizeUpdateBy_VoucherMaster: 50,
  patternUpdateBy_VoucherMaster: "",
  minSizeUpdateOn_VoucherMaster: 0,
  maxSizeUpdateOn_VoucherMaster: 50,
  patternUpdateOn_VoucherMaster: "",
  minSizeAudited_VoucherMaster: 0,
  maxSizeAudited_VoucherMaster: 2,
  patternAudited_VoucherMaster: "",
  minSizeVoucherBookType_VoucherMaster: 0,
  maxSizeVoucherBookType_VoucherMaster: 5,
  patternVoucherBookType_VoucherMaster: "",
  minSizeVoucherStatus_VoucherMaster: 0,
  maxSizeVoucherStatus_VoucherMaster: 5,
  patternVoucherStatus_VoucherMaster: "",
  minSizeVoucherLinkDocno_VoucherMaster: 0,
  maxSizeVoucherLinkDocno_VoucherMaster: 20,
  patternVoucherLinkDocno_VoucherMaster: "",
  minSizeVoucherLinkDocType_VoucherMaster: 0,
  maxSizeVoucherLinkDocType_VoucherMaster: 255,
  patternVoucherLinkDocType_VoucherMaster: "",
  minSizeVoucherAuto_VoucherMaster: 0,
  maxSizeVoucherAuto_VoucherMaster: 10,
  patternVoucherAuto_VoucherMaster: "",
  minSizeCompanyCode_VoucherMaster: 0,
  maxSizeCompanyCode_VoucherMaster: 20,
  patternCompanyCode_VoucherMaster: "[0-9a-zA-Z/-]+",
  minSizeVoucherChequeDocNo_VoucherMaster: 0,
  maxSizeVoucherChequeDocNo_VoucherMaster: 20,
  patternVoucherChequeDocNo_VoucherMaster: "",
  minSizeAstatus_VoucherMaster: 0,
  maxSizeAstatus_VoucherMaster: 2,
  patternAstatus_VoucherMaster: "",
  minSizeReviseNo_VoucherMaster: 0,
  maxSizeReviseNo_VoucherMaster: 10,
  patternReviseNo_VoucherMaster: "",
  minSizeVer_VoucherMaster: 0,
  maxSizeVer_VoucherMaster: 10,
  patternVer_VoucherMaster: "",
  minSizeEserviceDocNo_VoucherMaster: 0,
  maxSizeEserviceDocNo_VoucherMaster: 50,
  patternEserviceDocNo_VoucherMaster: "",
  minSizePayToName_VoucherMaster: 0,
  maxSizePayToName_VoucherMaster: 9999,
  patternPayToName_VoucherMaster: "",
  minSizeAppend_VoucherMaster: 0,
  maxSizeAppend_VoucherMaster: 9999,
  patternAppend_VoucherMaster: "",
  minSizeDivisionId_VoucherMaster: 0,
  maxSizeDivisionId_VoucherMaster: 19,
  patternDivisionId_VoucherMaster: "",
  minSizeSectionId_VoucherMaster: 0,
  maxSizeSectionId_VoucherMaster: 19,
  patternSectionId_VoucherMaster: "",
  minSizeDivisionNo_VoucherMaster: 0,
  maxSizeDivisionNo_VoucherMaster: 10,
  patternDivisionNo_VoucherMaster: "",
  minSizeDivisionName_VoucherMaster: 0,
  maxSizeDivisionName_VoucherMaster: 255,
  patternDivisionName_VoucherMaster: "",
  minSizeDivisionAddress_VoucherMaster: 0,
  maxSizeDivisionAddress_VoucherMaster: 255,
  patternDivisionAddress_VoucherMaster: "",
  minSizeTaxIdNo_VoucherMaster: 0,
  maxSizeTaxIdNo_VoucherMaster: 50,
  patternTaxIdNo_VoucherMaster: "",
  minSizeRemark1_VoucherMaster: 0,
  maxSizeRemark1_VoucherMaster: 250,
  patternRemark1_VoucherMaster: "",
  minSizeRemark2_VoucherMaster: 0,
  maxSizeRemark2_VoucherMaster: 250,
  patternRemark2_VoucherMaster: "",
  minSizeMasterStatus_VoucherMaster: 0,
  maxSizeMasterStatus_VoucherMaster: 1,
  patternMasterStatus_VoucherMaster: "",
  minSizeMasterDescription_VoucherMaster: 0,
  maxSizeMasterDescription_VoucherMaster: 255,
  patternMasterDescription_VoucherMaster: "",
  minSizeDocumentGroupId_VoucherMaster: 0,
  maxSizeDocumentGroupId_VoucherMaster: 19,
  patternDocumentGroupId_VoucherMaster: "",
  minSizeVoucherRemark1_VoucherMaster: 0,
  maxSizeVoucherRemark1_VoucherMaster: 255,
  patternVoucherRemark1_VoucherMaster: "",
  minSizeVoucherDescription1_VoucherMaster: 0,
  maxSizeVoucherDescription1_VoucherMaster: 255,
  patternVoucherDescription1_VoucherMaster: "",
  minSizeVoucherRemark_VoucherMaster: 0,
  maxSizeVoucherRemark_VoucherMaster: 1000,
  patternVoucherRemark_VoucherMaster: "",
  minSizeVoucherDescription_VoucherMaster: 0,
  maxSizeVoucherDescription_VoucherMaster: 2000,
  patternVoucherDescription_VoucherMaster: "",
  minSizePostDate_VoucherMaster: 0,
  maxSizePostDate_VoucherMaster: 30,
  patternPostDate_VoucherMaster: "",
  minSizePostBy_VoucherMaster: 0,
  maxSizePostBy_VoucherMaster: 50,
  patternPostBy_VoucherMaster: "",
  minSizeStatusBeforeTaxPreview_VoucherMaster: 0,
  maxSizeStatusBeforeTaxPreview_VoucherMaster: 5,
  patternStatusBeforeTaxPreview_VoucherMaster: "",
  minSizePurchaseServiceMasterId_PurchaseServiceMaster: 0,
  maxSizePurchaseServiceMasterId_PurchaseServiceMaster: 19,
  patternPurchaseServiceMasterId_PurchaseServiceMaster: "",
  minSizePurchaseServiceDocNo_PurchaseServiceMaster: 0,
  maxSizePurchaseServiceDocNo_PurchaseServiceMaster: 20,
  patternPurchaseServiceDocNo_PurchaseServiceMaster: "",
  minSizePurchaseServiceDocDate_PurchaseServiceMaster: 0,
  maxSizePurchaseServiceDocDate_PurchaseServiceMaster: 20,
  patternPurchaseServiceDocDate_PurchaseServiceMaster: "",
  minSizePurchaseServiceDateRequest_PurchaseServiceMaster: 0,
  maxSizePurchaseServiceDateRequest_PurchaseServiceMaster: 20,
  patternPurchaseServiceDateRequest_PurchaseServiceMaster: "",
  minSizePurchaseServiceRemark_PurchaseServiceMaster: 0,
  maxSizePurchaseServiceRemark_PurchaseServiceMaster: 1000,
  patternPurchaseServiceRemark_PurchaseServiceMaster: "",
  minSizePurchaseServiceStatus_PurchaseServiceMaster: 0,
  maxSizePurchaseServiceStatus_PurchaseServiceMaster: 2,
  patternPurchaseServiceStatus_PurchaseServiceMaster: "",
  minSizePurchaseServiceLinkDocNo_PurchaseServiceMaster: 0,
  maxSizePurchaseServiceLinkDocNo_PurchaseServiceMaster: 20,
  patternPurchaseServiceLinkDocNo_PurchaseServiceMaster: "",
  minSizeCreateOn_PurchaseServiceMaster: 0,
  maxSizeCreateOn_PurchaseServiceMaster: 20,
  patternCreateOn_PurchaseServiceMaster: "",
  minSizeUpdateOn_PurchaseServiceMaster: 0,
  maxSizeUpdateOn_PurchaseServiceMaster: 20,
  patternUpdateOn_PurchaseServiceMaster: "",
  minSizeCompanyCode_PurchaseServiceMaster: 0,
  maxSizeCompanyCode_PurchaseServiceMaster: 20,
  patternCompanyCode_PurchaseServiceMaster: "[0-9a-zA-Z/-]+",
  minSizeAstatus_PurchaseServiceMaster: 0,
  maxSizeAstatus_PurchaseServiceMaster: 2,
  patternAstatus_PurchaseServiceMaster: "",
  minSizeReviseNo_PurchaseServiceMaster: 0,
  maxSizeReviseNo_PurchaseServiceMaster: 10,
  patternReviseNo_PurchaseServiceMaster: "",
  minSizeDocumentGroupId_PurchaseServiceMaster: 0,
  maxSizeDocumentGroupId_PurchaseServiceMaster: 19,
  patternDocumentGroupId_PurchaseServiceMaster: "",
  minSizeEmployeeMasterId_PurchaseServiceMaster: 0,
  maxSizeEmployeeMasterId_PurchaseServiceMaster: 19,
  patternEmployeeMasterId_PurchaseServiceMaster: "",
  minSizeDivisionId_PurchaseServiceMaster: 0,
  maxSizeDivisionId_PurchaseServiceMaster: 19,
  patternDivisionId_PurchaseServiceMaster: "",
  minSizeSectionId_PurchaseServiceMaster: 0,
  maxSizeSectionId_PurchaseServiceMaster: 19,
  patternSectionId_PurchaseServiceMaster: "",
  minSizeCreateByEmpId_PurchaseServiceMaster: 0,
  maxSizeCreateByEmpId_PurchaseServiceMaster: 19,
  patternCreateByEmpId_PurchaseServiceMaster: "",
  minSizeUpdateByEmpId_PurchaseServiceMaster: 0,
  maxSizeUpdateByEmpId_PurchaseServiceMaster: 19,
  patternUpdateByEmpId_PurchaseServiceMaster: "",
  minSizeJobMasterId_PurchaseServiceMaster: 0,
  maxSizeJobMasterId_PurchaseServiceMaster: 19,
  patternJobMasterId_PurchaseServiceMaster: "",
  minSizePrintStatus_PurchaseServiceMaster: 0,
  maxSizePrintStatus_PurchaseServiceMaster: 2,
  patternPrintStatus_PurchaseServiceMaster: "",
  minSizePrTotal_PurchaseServiceMaster: 0,
  maxSizePrTotal_PurchaseServiceMaster: 26,
  patternPrTotal_PurchaseServiceMaster: "",
  minSizeUserApprove_PurchaseServiceMaster: 0,
  maxSizeUserApprove_PurchaseServiceMaster: 20,
  patternUserApprove_PurchaseServiceMaster: "",
  minSizeDocumentDateClose_PurchaseServiceMaster: 0,
  maxSizeDocumentDateClose_PurchaseServiceMaster: 20,
  patternDocumentDateClose_PurchaseServiceMaster: "",
  minSizeUserCloseEmpId_PurchaseServiceMaster: 0,
  maxSizeUserCloseEmpId_PurchaseServiceMaster: 19,
  patternUserCloseEmpId_PurchaseServiceMaster: "",
  minSizeProdRecInvDiscRate_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvDiscRate_ProductReceiveInvoiceMaster: 26,
  patternProdRecInvDiscRate_ProductReceiveInvoiceMaster: "",
  minSizeAstatus_ProductReceiveInvoiceMaster: 0,
  maxSizeAstatus_ProductReceiveInvoiceMaster: 2,
  patternAstatus_ProductReceiveInvoiceMaster: "",
  minSizeUserCloseEmpId_ProductReceiveInvoiceMaster: 0,
  maxSizeUserCloseEmpId_ProductReceiveInvoiceMaster: 19,
  patternUserCloseEmpId_ProductReceiveInvoiceMaster: "",
  minSizeDocumentDateClose_ProductReceiveInvoiceMaster: 0,
  maxSizeDocumentDateClose_ProductReceiveInvoiceMaster: 20,
  patternDocumentDateClose_ProductReceiveInvoiceMaster: "",
  minSizeProdRecInvTotalBeforeVat_ProductReceiveInvoiceMaster: 0,
  maxSizeProdRecInvTotalBeforeVat_ProductReceiveInvoiceMaster: 26,
  patternProdRecInvTotalBeforeVat_ProductReceiveInvoiceMaster: "",
  minSizeSuppTName_ProductReceiveInvoiceMaster: 0,
  maxSizeSuppTName_ProductReceiveInvoiceMaster: 255,
  patternSuppTName_ProductReceiveInvoiceMaster: "",
  minSizePrintStatus_DepositPaymentInvoiceMaster: 0,
  maxSizePrintStatus_DepositPaymentInvoiceMaster: 2,
  patternPrintStatus_DepositPaymentInvoiceMaster: "",
  minSizeChqDepositBookId_DepositPaymentInvoiceMaster: 0,
  maxSizeChqDepositBookId_DepositPaymentInvoiceMaster: 19,
  patternChqDepositBookId_DepositPaymentInvoiceMaster: "",
  minSizeWitholdingTaxType_DepositPaymentInvoiceMaster: 0,
  maxSizeWitholdingTaxType_DepositPaymentInvoiceMaster: 2,
  patternWitholdingTaxType_DepositPaymentInvoiceMaster: "",
  minSizeSuppReferTaxPgd_SupplierCredit: 0,
  maxSizeSuppReferTaxPgd_SupplierCredit: 20,
  patternSuppReferTaxPgd_SupplierCredit: "",
  minSizeOtherPaymentId_OtherPayment: 0,
  maxSizeOtherPaymentId_OtherPayment: 19,
  patternOtherPaymentId_OtherPayment: "",
  minSizeOtherPaymentCode_OtherPayment: 0,
  maxSizeOtherPaymentCode_OtherPayment: 30,
  patternOtherPaymentCode_OtherPayment: "[0-9a-zA-Z/-]+",
  minSizeOtherPaymentAmount_OtherPayment: 0,
  maxSizeOtherPaymentAmount_OtherPayment: 26,
  patternOtherPaymentAmount_OtherPayment: "",
  minSizePlusOtherPaymentAmount_OtherPayment: 0,
  maxSizePlusOtherPaymentAmount_OtherPayment: 26,
  patternPlusOtherPaymentAmount_OtherPayment: "",
  minSizeCompanyCode_OtherPayment: 0,
  maxSizeCompanyCode_OtherPayment: 20,
  patternCompanyCode_OtherPayment: "[0-9a-zA-Z/-]+",
  minSizeAccountCodeId_OtherPayment: 0,
  maxSizeAccountCodeId_OtherPayment: 19,
  patternAccountCodeId_OtherPayment: "[0-9a-zA-Z/-]+",
  minSizePaymentVoucherMasterId_OtherPayment: 0,
  maxSizePaymentVoucherMasterId_OtherPayment: 19,
  patternPaymentVoucherMasterId_OtherPayment: "",
  minSizeDepositPaymentInvoiceMasterId_OtherPayment: 0,
  maxSizeDepositPaymentInvoiceMasterId_OtherPayment: 19,
  patternDepositPaymentInvoiceMasterId_OtherPayment: "",
  minSizeProductReceiveInvoiceMasterId_OtherPayment: 0,
  maxSizeProductReceiveInvoiceMasterId_OtherPayment: 19,
  patternProductReceiveInvoiceMasterId_OtherPayment: "",
  minSizeDeliveryOrderMasterId_OtherPayment: 0,
  maxSizeDeliveryOrderMasterId_OtherPayment: 19,
  patternDeliveryOrderMasterId_OtherPayment: "",
  minSizeRcvServiceMasterId_OtherPayment: 0,
  maxSizeRcvServiceMasterId_OtherPayment: 19,
  patternRcvServiceMasterId_OtherPayment: "",
  minSizeServiceBillingNoteMasterId_OtherPayment: 0,
  maxSizeServiceBillingNoteMasterId_OtherPayment: 19,
  patternServiceBillingNoteMasterId_OtherPayment: "",
  minSizeProductReceiveInvoiceMasterId_ProductRecInvMasterJobMaster: 0,
  maxSizeProductReceiveInvoiceMasterId_ProductRecInvMasterJobMaster: 19,
  patternProductReceiveInvoiceMasterId_ProductRecInvMasterJobMaster: "",
  minSizeJobMasterId_ProductRecInvMasterJobMaster: 0,
  maxSizeJobMasterId_ProductRecInvMasterJobMaster: 19,
  patternJobMasterId_ProductRecInvMasterJobMaster: "",
  minSizeDeliveryOrderReceiveId_DeliveryOrderRecDepositBook: 0,
  maxSizeDeliveryOrderReceiveId_DeliveryOrderRecDepositBook: 19,
  patternDeliveryOrderReceiveId_DeliveryOrderRecDepositBook: "",
  minSizeDepositBookMasterId_DeliveryOrderRecDepositBook: 0,
  maxSizeDepositBookMasterId_DeliveryOrderRecDepositBook: 19,
  patternDepositBookMasterId_DeliveryOrderRecDepositBook: "",
  minSizeRcvServiceDetailId_RCVServiceDetail: 0,
  maxSizeRcvServiceDetailId_RCVServiceDetail: 19,
  patternRcvServiceDetailId_RCVServiceDetail: "",
  minSizeRcvServiceDetailNo_RCVServiceDetail: 0,
  maxSizeRcvServiceDetailNo_RCVServiceDetail: 10,
  patternRcvServiceDetailNo_RCVServiceDetail: "",
  minSizeServiceQTY_RCVServiceDetail: 0,
  maxSizeServiceQTY_RCVServiceDetail: 26,
  patternServiceQTY_RCVServiceDetail: "",
  minSizeServiceCn_RCVServiceDetail: 0,
  maxSizeServiceCn_RCVServiceDetail: 26,
  patternServiceCn_RCVServiceDetail: "",
  minSizeServiceUnit_RCVServiceDetail: 0,
  maxSizeServiceUnit_RCVServiceDetail: 100,
  patternServiceUnit_RCVServiceDetail: "",
  minSizeServicePrice_RCVServiceDetail: 0,
  maxSizeServicePrice_RCVServiceDetail: 26,
  patternServicePrice_RCVServiceDetail: "",
  minSizeServiceDiscRate_RCVServiceDetail: 0,
  maxSizeServiceDiscRate_RCVServiceDetail: 26,
  patternServiceDiscRate_RCVServiceDetail: "",
  minSizeServiceDiscAmt_RCVServiceDetail: 0,
  maxSizeServiceDiscAmt_RCVServiceDetail: 26,
  patternServiceDiscAmt_RCVServiceDetail: "",
  minSizeServiceNetPrice_RCVServiceDetail: 0,
  maxSizeServiceNetPrice_RCVServiceDetail: 26,
  patternServiceNetPrice_RCVServiceDetail: "",
  minSizePoDocNo_RCVServiceDetail: 0,
  maxSizePoDocNo_RCVServiceDetail: 20,
  patternPoDocNo_RCVServiceDetail: "",
  minSizeQuDocNo_RCVServiceDetail: 0,
  maxSizeQuDocNo_RCVServiceDetail: 20,
  patternQuDocNo_RCVServiceDetail: "",
  minSizeServiceVat_RCVServiceDetail: 0,
  maxSizeServiceVat_RCVServiceDetail: 2,
  patternServiceVat_RCVServiceDetail: "",
  minSizePoDocIndex_RCVServiceDetail: 0,
  maxSizePoDocIndex_RCVServiceDetail: 10,
  patternPoDocIndex_RCVServiceDetail: "",
  minSizeServiceDiscRate0_RCVServiceDetail: 0,
  maxSizeServiceDiscRate0_RCVServiceDetail: 20,
  patternServiceDiscRate0_RCVServiceDetail: "",
  minSizeCompanyCode_RCVServiceDetail: 0,
  maxSizeCompanyCode_RCVServiceDetail: 20,
  patternCompanyCode_RCVServiceDetail: "[0-9a-zA-Z/-]+",
  minSizeRcvServiceItemDetail_RCVServiceDetail: 0,
  maxSizeRcvServiceItemDetail_RCVServiceDetail: 9999,
  patternRcvServiceItemDetail_RCVServiceDetail: "",
  minSizeServiceMasterId_RCVServiceDetail: 0,
  maxSizeServiceMasterId_RCVServiceDetail: 19,
  patternServiceMasterId_RCVServiceDetail: "",
  minSizeRcvServiceMasterId_RCVServiceDetail: 0,
  maxSizeRcvServiceMasterId_RCVServiceDetail: 19,
  patternRcvServiceMasterId_RCVServiceDetail: "",
  minSizeServiceName_RCVServiceDetail: 0,
  maxSizeServiceName_RCVServiceDetail: 500,
  patternServiceName_RCVServiceDetail: "",
  minSizeServiceReceiveDate_RCVServiceDetail: 0,
  maxSizeServiceReceiveDate_RCVServiceDetail: 20,
  patternServiceReceiveDate_RCVServiceDetail: "",
  minSizePoServiceMasterId_PoServiceMasterJobMaster: 0,
  maxSizePoServiceMasterId_PoServiceMasterJobMaster: 19,
  patternPoServiceMasterId_PoServiceMasterJobMaster: "",
  minSizeJobMasterId_PoServiceMasterJobMaster: 0,
  maxSizeJobMasterId_PoServiceMasterJobMaster: 19,
  patternJobMasterId_PoServiceMasterJobMaster: "",
  minSizeRcvServiceMasterId_RCVServiceMaster: 0,
  maxSizeRcvServiceMasterId_RCVServiceMaster: 19,
  patternRcvServiceMasterId_RCVServiceMaster: "",
  minSizeRcvServiceDocNo_RCVServiceMaster: 0,
  maxSizeRcvServiceDocNo_RCVServiceMaster: 20,
  patternRcvServiceDocNo_RCVServiceMaster: "",
  minSizeRcvServiceDocDate_RCVServiceMaster: 0,
  maxSizeRcvServiceDocDate_RCVServiceMaster: 20,
  patternRcvServiceDocDate_RCVServiceMaster: "",
  minSizeRcvServiceInvoiceNo_RCVServiceMaster: 0,
  maxSizeRcvServiceInvoiceNo_RCVServiceMaster: 20,
  patternRcvServiceInvoiceNo_RCVServiceMaster: "",
  minSizeRcvServiceInvoiceDate_RCVServiceMaster: 0,
  maxSizeRcvServiceInvoiceDate_RCVServiceMaster: 20,
  patternRcvServiceInvoiceDate_RCVServiceMaster: "",
  minSizeRcvServiceTotal_RCVServiceMaster: 0,
  maxSizeRcvServiceTotal_RCVServiceMaster: 26,
  patternRcvServiceTotal_RCVServiceMaster: "",
  minSizeRcvServiceTotalNoVat_RCVServiceMaster: 0,
  maxSizeRcvServiceTotalNoVat_RCVServiceMaster: 26,
  patternRcvServiceTotalNoVat_RCVServiceMaster: "",
  minSizeRcvServiceDiscRate_RCVServiceMaster: 0,
  maxSizeRcvServiceDiscRate_RCVServiceMaster: 26,
  patternRcvServiceDiscRate_RCVServiceMaster: "",
  minSizeRcvServiceDiscAmt_RCVServiceMaster: 0,
  maxSizeRcvServiceDiscAmt_RCVServiceMaster: 26,
  patternRcvServiceDiscAmt_RCVServiceMaster: "",
  minSizeRcvServiceDepositNo_RCVServiceMaster: 0,
  maxSizeRcvServiceDepositNo_RCVServiceMaster: 20,
  patternRcvServiceDepositNo_RCVServiceMaster: "",
  minSizeRcvServiceDepositAmt_RCVServiceMaster: 0,
  maxSizeRcvServiceDepositAmt_RCVServiceMaster: 26,
  patternRcvServiceDepositAmt_RCVServiceMaster: "",
  minSizeRcvServiceVatRate_RCVServiceMaster: 0,
  maxSizeRcvServiceVatRate_RCVServiceMaster: 26,
  patternRcvServiceVatRate_RCVServiceMaster: "",
  minSizeRcvServiceVatAmt_RCVServiceMaster: 0,
  maxSizeRcvServiceVatAmt_RCVServiceMaster: 26,
  patternRcvServiceVatAmt_RCVServiceMaster: "",
  minSizeRcvServiceNetAmt_RCVServiceMaster: 0,
  maxSizeRcvServiceNetAmt_RCVServiceMaster: 26,
  patternRcvServiceNetAmt_RCVServiceMaster: "",
  minSizeRcvServicePayAmt_RCVServiceMaster: 0,
  maxSizeRcvServicePayAmt_RCVServiceMaster: 26,
  patternRcvServicePayAmt_RCVServiceMaster: "",
  minSizeRcvServiceRemark_RCVServiceMaster: 0,
  maxSizeRcvServiceRemark_RCVServiceMaster: 1000,
  patternRcvServiceRemark_RCVServiceMaster: "",
  minSizeRcvServiceStatus_RCVServiceMaster: 0,
  maxSizeRcvServiceStatus_RCVServiceMaster: 2,
  patternRcvServiceStatus_RCVServiceMaster: "",
  minSizeRcvServiceLinkDocNo_RCVServiceMaster: 0,
  maxSizeRcvServiceLinkDocNo_RCVServiceMaster: 20,
  patternRcvServiceLinkDocNo_RCVServiceMaster: "",
  minSizeRcvServiceCreateOn_RCVServiceMaster: 0,
  maxSizeRcvServiceCreateOn_RCVServiceMaster: 20,
  patternRcvServiceCreateOn_RCVServiceMaster: "",
  minSizeRcvServiceUpdateOn_RCVServiceMaster: 0,
  maxSizeRcvServiceUpdateOn_RCVServiceMaster: 20,
  patternRcvServiceUpdateOn_RCVServiceMaster: "",
  minSizeRcvServiceReferPoNo_RCVServiceMaster: 0,
  maxSizeRcvServiceReferPoNo_RCVServiceMaster: 20,
  patternRcvServiceReferPoNo_RCVServiceMaster: "",
  minSizeCompanyCode_RCVServiceMaster: 0,
  maxSizeCompanyCode_RCVServiceMaster: 20,
  patternCompanyCode_RCVServiceMaster: "[0-9a-zA-Z/-]+",
  minSizeExchangeRate_RCVServiceMaster: 0,
  maxSizeExchangeRate_RCVServiceMaster: 26,
  patternExchangeRate_RCVServiceMaster: "",
  minSizeExchageRateDate_RCVServiceMaster: 0,
  maxSizeExchageRateDate_RCVServiceMaster: 20,
  patternExchageRateDate_RCVServiceMaster: "",
  minSizeAstatus_RCVServiceMaster: 0,
  maxSizeAstatus_RCVServiceMaster: 2,
  patternAstatus_RCVServiceMaster: "",
  minSizeInvoiceType_RCVServiceMaster: 0,
  maxSizeInvoiceType_RCVServiceMaster: 5,
  patternInvoiceType_RCVServiceMaster: "",
  minSizePaymentType_RCVServiceMaster: 0,
  maxSizePaymentType_RCVServiceMaster: 2,
  patternPaymentType_RCVServiceMaster: "",
  minSizePaymentAmt_RCVServiceMaster: 0,
  maxSizePaymentAmt_RCVServiceMaster: 26,
  patternPaymentAmt_RCVServiceMaster: "",
  minSizeBankRemark_RCVServiceMaster: 0,
  maxSizeBankRemark_RCVServiceMaster: 150,
  patternBankRemark_RCVServiceMaster: "",
  minSizeChqNo_RCVServiceMaster: 0,
  maxSizeChqNo_RCVServiceMaster: 20,
  patternChqNo_RCVServiceMaster: "",
  minSizeChqDate_RCVServiceMaster: 0,
  maxSizeChqDate_RCVServiceMaster: 20,
  patternChqDate_RCVServiceMaster: "",
  minSizePaymentChequeDocNo_RCVServiceMaster: 0,
  maxSizePaymentChequeDocNo_RCVServiceMaster: 20,
  patternPaymentChequeDocNo_RCVServiceMaster: "",
  minSizeRcvServiceVoucherNo_RCVServiceMaster: 0,
  maxSizeRcvServiceVoucherNo_RCVServiceMaster: 20,
  patternRcvServiceVoucherNo_RCVServiceMaster: "",
  minSizeRcvWithhodingTax_RCVServiceMaster: 0,
  maxSizeRcvWithhodingTax_RCVServiceMaster: 26,
  patternRcvWithhodingTax_RCVServiceMaster: "",
  minSizeVatInclude_RCVServiceMaster: 0,
  maxSizeVatInclude_RCVServiceMaster: 2,
  patternVatInclude_RCVServiceMaster: "",
  minSizeBillAddress_RCVServiceMaster: 0,
  maxSizeBillAddress_RCVServiceMaster: 9999,
  patternBillAddress_RCVServiceMaster: "",
  minSizeBillTName_RCVServiceMaster: 0,
  maxSizeBillTName_RCVServiceMaster: 9999,
  patternBillTName_RCVServiceMaster: "",
  minSizeBillTelNo_RCVServiceMaster: 0,
  maxSizeBillTelNo_RCVServiceMaster: 100,
  patternBillTelNo_RCVServiceMaster: "",
  minSizeBookCode_RCVServiceMaster: 0,
  maxSizeBookCode_RCVServiceMaster: 20,
  patternBookCode_RCVServiceMaster: "[0-9a-zA-Z/-]+",
  minSizeDivisionNo_RCVServiceMaster: 0,
  maxSizeDivisionNo_RCVServiceMaster: 10,
  patternDivisionNo_RCVServiceMaster: "",
  minSizeDivisionName_RCVServiceMaster: 0,
  maxSizeDivisionName_RCVServiceMaster: 50,
  patternDivisionName_RCVServiceMaster: "",
  minSizeDivisionAddress_RCVServiceMaster: 0,
  maxSizeDivisionAddress_RCVServiceMaster: 255,
  patternDivisionAddress_RCVServiceMaster: "",
  minSizeTaxIdNo_RCVServiceMaster: 0,
  maxSizeTaxIdNo_RCVServiceMaster: 50,
  patternTaxIdNo_RCVServiceMaster: "",
  minSizeCharge_RCVServiceMaster: 0,
  maxSizeCharge_RCVServiceMaster: 26,
  patternCharge_RCVServiceMaster: "",
  minSizeCurrencyId_RCVServiceMaster: 0,
  maxSizeCurrencyId_RCVServiceMaster: 19,
  patternCurrencyId_RCVServiceMaster: "",
  minSizeDocumentGroupId_RCVServiceMaster: 0,
  maxSizeDocumentGroupId_RCVServiceMaster: 19,
  patternDocumentGroupId_RCVServiceMaster: "",
  minSizeSupplierMasterId_RCVServiceMaster: 0,
  maxSizeSupplierMasterId_RCVServiceMaster: 19,
  patternSupplierMasterId_RCVServiceMaster: "",
  minSizeEmployeeMasterId_RCVServiceMaster: 0,
  maxSizeEmployeeMasterId_RCVServiceMaster: 19,
  patternEmployeeMasterId_RCVServiceMaster: "",
  minSizeDivisionId_RCVServiceMaster: 0,
  maxSizeDivisionId_RCVServiceMaster: 19,
  patternDivisionId_RCVServiceMaster: "",
  minSizeSectionId_RCVServiceMaster: 0,
  maxSizeSectionId_RCVServiceMaster: 19,
  patternSectionId_RCVServiceMaster: "",
  minSizeWarehouseId_RCVServiceMaster: 0,
  maxSizeWarehouseId_RCVServiceMaster: 19,
  patternWarehouseId_RCVServiceMaster: "",
  minSizeCreateByEmpId_RCVServiceMaster: 0,
  maxSizeCreateByEmpId_RCVServiceMaster: 19,
  patternCreateByEmpId_RCVServiceMaster: "",
  minSizeUpdateByEmpId_RCVServiceMaster: 0,
  maxSizeUpdateByEmpId_RCVServiceMaster: 19,
  patternUpdateByEmpId_RCVServiceMaster: "",
  minSizePrintStatus_RCVServiceMaster: 0,
  maxSizePrintStatus_RCVServiceMaster: 2,
  patternPrintStatus_RCVServiceMaster: "",
  minSizeSuppAddress_RCVServiceMaster: 0,
  maxSizeSuppAddress_RCVServiceMaster: 200,
  patternSuppAddress_RCVServiceMaster: "",
  minSizeSuppDivisionNo_RCVServiceMaster: 0,
  maxSizeSuppDivisionNo_RCVServiceMaster: 20,
  patternSuppDivisionNo_RCVServiceMaster: "",
  minSizePettyCashCode_RCVServiceMaster: 0,
  maxSizePettyCashCode_RCVServiceMaster: 20,
  patternPettyCashCode_RCVServiceMaster: "[0-9a-zA-Z/-]+",
  minSizePettyCashName_RCVServiceMaster: 0,
  maxSizePettyCashName_RCVServiceMaster: 255,
  patternPettyCashName_RCVServiceMaster: "",
  minSizePcAccountCode_RCVServiceMaster: 0,
  maxSizePcAccountCode_RCVServiceMaster: 20,
  patternPcAccountCode_RCVServiceMaster: "[0-9a-zA-Z/-]+",
  minSizeDueDate_RCVServiceMaster: 0,
  maxSizeDueDate_RCVServiceMaster: 20,
  patternDueDate_RCVServiceMaster: "",
  minSizeSuppName_RCVServiceMaster: 0,
  maxSizeSuppName_RCVServiceMaster: 150,
  patternSuppName_RCVServiceMaster: "",
  minSizeChqAmt_RCVServiceMaster: 0,
  maxSizeChqAmt_RCVServiceMaster: 26,
  patternChqAmt_RCVServiceMaster: "",
  minSizeChqDepositBookId_RCVServiceMaster: 0,
  maxSizeChqDepositBookId_RCVServiceMaster: 19,
  patternChqDepositBookId_RCVServiceMaster: "",
  minSizeBankAmt_RCVServiceMaster: 0,
  maxSizeBankAmt_RCVServiceMaster: 26,
  patternBankAmt_RCVServiceMaster: "",
  minSizeRcvServiceDateRequire_RCVServiceMaster: 0,
  maxSizeRcvServiceDateRequire_RCVServiceMaster: 20,
  patternRcvServiceDateRequire_RCVServiceMaster: "",
  minSizeWitholdingTaxType_RCVServiceMaster: 0,
  maxSizeWitholdingTaxType_RCVServiceMaster: 2,
  patternWitholdingTaxType_RCVServiceMaster: "",
  minSizeTaxReturn_RCVServiceMaster: 0,
  maxSizeTaxReturn_RCVServiceMaster: 2,
  patternTaxReturn_RCVServiceMaster: "",
  minSizePettyCashAmt_RCVServiceMaster: 0,
  maxSizePettyCashAmt_RCVServiceMaster: 26,
  patternPettyCashAmt_RCVServiceMaster: "",
  minSizePurchaseOrderDetailId_PurchaseOrderDetail: 0,
  maxSizePurchaseOrderDetailId_PurchaseOrderDetail: 19,
  patternPurchaseOrderDetailId_PurchaseOrderDetail: "",
  minSizeDetailNo_PurchaseOrderDetail: 0,
  maxSizeDetailNo_PurchaseOrderDetail: 10,
  patternDetailNo_PurchaseOrderDetail: "",
  minSizeProdQTY_PurchaseOrderDetail: 0,
  maxSizeProdQTY_PurchaseOrderDetail: 26,
  patternProdQTY_PurchaseOrderDetail: "",
  minSizeProdReceiveQty_PurchaseOrderDetail: 0,
  maxSizeProdReceiveQty_PurchaseOrderDetail: 26,
  patternProdReceiveQty_PurchaseOrderDetail: "",
  minSizeProdConvFactor_PurchaseOrderDetail: 0,
  maxSizeProdConvFactor_PurchaseOrderDetail: 26,
  patternProdConvFactor_PurchaseOrderDetail: "",
  minSizeProdBaseQty_PurchaseOrderDetail: 0,
  maxSizeProdBaseQty_PurchaseOrderDetail: 26,
  patternProdBaseQty_PurchaseOrderDetail: "",
  minSizeProdReceiveBaseQty_PurchaseOrderDetail: 0,
  maxSizeProdReceiveBaseQty_PurchaseOrderDetail: 26,
  patternProdReceiveBaseQty_PurchaseOrderDetail: "",
  minSizeProdPrice_PurchaseOrderDetail: 0,
  maxSizeProdPrice_PurchaseOrderDetail: 26,
  patternProdPrice_PurchaseOrderDetail: "",
  minSizeProdDiscRate_PurchaseOrderDetail: 0,
  maxSizeProdDiscRate_PurchaseOrderDetail: 26,
  patternProdDiscRate_PurchaseOrderDetail: "",
  minSizeProdDiscAmt_PurchaseOrderDetail: 0,
  maxSizeProdDiscAmt_PurchaseOrderDetail: 26,
  patternProdDiscAmt_PurchaseOrderDetail: "",
  minSizeProdNetPrice_PurchaseOrderDetail: 0,
  maxSizeProdNetPrice_PurchaseOrderDetail: 26,
  patternProdNetPrice_PurchaseOrderDetail: "",
  minSizePrDetailNo_PurchaseOrderDetail: 0,
  maxSizePrDetailNo_PurchaseOrderDetail: 10,
  patternPrDetailNo_PurchaseOrderDetail: "",
  minSizeProdDiscRate0_PurchaseOrderDetail: 0,
  maxSizeProdDiscRate0_PurchaseOrderDetail: 20,
  patternProdDiscRate0_PurchaseOrderDetail: "",
  minSizeRecieveDate_PurchaseOrderDetail: 0,
  maxSizeRecieveDate_PurchaseOrderDetail: 20,
  patternRecieveDate_PurchaseOrderDetail: "",
  minSizeProdReceiveDate_PurchaseOrderDetail: 0,
  maxSizeProdReceiveDate_PurchaseOrderDetail: 20,
  patternProdReceiveDate_PurchaseOrderDetail: "",
  minSizeProdVat_PurchaseOrderDetail: 0,
  maxSizeProdVat_PurchaseOrderDetail: 2,
  patternProdVat_PurchaseOrderDetail: "",
  minSizeProdReciveDateComplete_PurchaseOrderDetail: 0,
  maxSizeProdReciveDateComplete_PurchaseOrderDetail: 20,
  patternProdReciveDateComplete_PurchaseOrderDetail: "",
  minSizeProdPurchaseRequestDocNo_PurchaseOrderDetail: 0,
  maxSizeProdPurchaseRequestDocNo_PurchaseOrderDetail: 20,
  patternProdPurchaseRequestDocNo_PurchaseOrderDetail: "",
  minSizeCompanyCode_PurchaseOrderDetail: 0,
  maxSizeCompanyCode_PurchaseOrderDetail: 20,
  patternCompanyCode_PurchaseOrderDetail: "[0-9a-zA-Z/-]+",
  minSizeProdItemDetail_PurchaseOrderDetail: 0,
  maxSizeProdItemDetail_PurchaseOrderDetail: 9999,
  patternProdItemDetail_PurchaseOrderDetail: "",
  minSizeProdName_PurchaseOrderDetail: 0,
  maxSizeProdName_PurchaseOrderDetail: 300,
  patternProdName_PurchaseOrderDetail: "",
  minSizeProductMaster1Id_PurchaseOrderDetail: 0,
  maxSizeProductMaster1Id_PurchaseOrderDetail: 19,
  patternProductMaster1Id_PurchaseOrderDetail: "",
  minSizeUnitOfMeasureId1_PurchaseOrderDetail: 0,
  maxSizeUnitOfMeasureId1_PurchaseOrderDetail: 19,
  patternUnitOfMeasureId1_PurchaseOrderDetail: "",
  minSizeUnitOfMeasureId2_PurchaseOrderDetail: 0,
  maxSizeUnitOfMeasureId2_PurchaseOrderDetail: 19,
  patternUnitOfMeasureId2_PurchaseOrderDetail: "",
  minSizePurchaseOrderMasterId_PurchaseOrderDetail: 0,
  maxSizePurchaseOrderMasterId_PurchaseOrderDetail: 19,
  patternPurchaseOrderMasterId_PurchaseOrderDetail: "",
  minSizeJobMasterId_PurchaseOrderDetail: 0,
  maxSizeJobMasterId_PurchaseOrderDetail: 19,
  patternJobMasterId_PurchaseOrderDetail: "",
  minSizeWhCode_PurchaseOrderDetail: 0,
  maxSizeWhCode_PurchaseOrderDetail: 20,
  patternWhCode_PurchaseOrderDetail: "[0-9a-zA-Z/-]+",
  minSizePoCustName_PurchaseOrderDetail: 0,
  maxSizePoCustName_PurchaseOrderDetail: 255,
  patternPoCustName_PurchaseOrderDetail: "",
  minSizeDueDate_PurchaseOrderDetail: 0,
  maxSizeDueDate_PurchaseOrderDetail: 20,
  patternDueDate_PurchaseOrderDetail: "",
  minSizeApproveMasterId_ApproveMaster: 0,
  maxSizeApproveMasterId_ApproveMaster: 19,
  patternApproveMasterId_ApproveMaster: "",
  minSizeDocumentMasterId_ApproveMaster: 0,
  maxSizeDocumentMasterId_ApproveMaster: 19,
  patternDocumentMasterId_ApproveMaster: "",
  minSizeDocumentNo_ApproveMaster: 0,
  maxSizeDocumentNo_ApproveMaster: 20,
  patternDocumentNo_ApproveMaster: "",
  minSizeRefer_ApproveMaster: 0,
  maxSizeRefer_ApproveMaster: 20,
  patternRefer_ApproveMaster: "",
  minSizeEmpApproveId_ApproveMaster: 0,
  maxSizeEmpApproveId_ApproveMaster: 19,
  patternEmpApproveId_ApproveMaster: "",
  minSizeEmpApproveCode_ApproveMaster: 0,
  maxSizeEmpApproveCode_ApproveMaster: 20,
  patternEmpApproveCode_ApproveMaster: "[0-9a-zA-Z/-]+",
  minSizeEmpApproveTime_ApproveMaster: 0,
  maxSizeEmpApproveTime_ApproveMaster: 20,
  patternEmpApproveTime_ApproveMaster: "",
  minSizeCompanyCode_ApproveMaster: 0,
  maxSizeCompanyCode_ApproveMaster: 20,
  patternCompanyCode_ApproveMaster: "[0-9a-zA-Z/-]+",
  minSizeApproveStatus_ApproveMaster: 0,
  maxSizeApproveStatus_ApproveMaster: 2,
  patternApproveStatus_ApproveMaster: "",
  minSizeVoucherDetailId_VoucherDetail: 0,
  maxSizeVoucherDetailId_VoucherDetail: 19,
  patternVoucherDetailId_VoucherDetail: "",
  minSizeVoucherDetailNo_VoucherDetail: 0,
  maxSizeVoucherDetailNo_VoucherDetail: 10,
  patternVoucherDetailNo_VoucherDetail: "",
  minSizeDebit_VoucherDetail: 0,
  maxSizeDebit_VoucherDetail: 26,
  patternDebit_VoucherDetail: "",
  minSizeCredit_VoucherDetail: 0,
  maxSizeCredit_VoucherDetail: 26,
  patternCredit_VoucherDetail: "",
  minSizeChequeNo_VoucherDetail: 0,
  maxSizeChequeNo_VoucherDetail: 200,
  patternChequeNo_VoucherDetail: "",
  minSizeChequeDate_VoucherDetail: 0,
  maxSizeChequeDate_VoucherDetail: 200,
  patternChequeDate_VoucherDetail: "",
  minSizeBaseValue_VoucherDetail: 0,
  maxSizeBaseValue_VoucherDetail: 26,
  patternBaseValue_VoucherDetail: "",
  minSizeVatAmount_VoucherDetail: 0,
  maxSizeVatAmount_VoucherDetail: 26,
  patternVatAmount_VoucherDetail: "",
  minSizeVatRate_VoucherDetail: 0,
  maxSizeVatRate_VoucherDetail: 26,
  patternVatRate_VoucherDetail: "",
  minSizeDocNo_VoucherDetail: 0,
  maxSizeDocNo_VoucherDetail: 255,
  patternDocNo_VoucherDetail: "",
  minSizeDocDate_VoucherDetail: 0,
  maxSizeDocDate_VoucherDetail: 20,
  patternDocDate_VoucherDetail: "",
  minSizeNameInVat_VoucherDetail: 0,
  maxSizeNameInVat_VoucherDetail: 250,
  patternNameInVat_VoucherDetail: "",
  minSizeVatType_VoucherDetail: 0,
  maxSizeVatType_VoucherDetail: 30,
  patternVatType_VoucherDetail: "",
  minSizeMatch_VoucherDetail: 0,
  maxSizeMatch_VoucherDetail: 20,
  patternMatch_VoucherDetail: "",
  minSizeCompanyCode_VoucherDetail: 0,
  maxSizeCompanyCode_VoucherDetail: 20,
  patternCompanyCode_VoucherDetail: "[0-9a-zA-Z/-]+",
  minSizeProdCode_VoucherDetail: 0,
  maxSizeProdCode_VoucherDetail: 25,
  patternProdCode_VoucherDetail: "[0-9a-zA-Z/-]+",
  minSizeTaxReturn_VoucherDetail: 0,
  maxSizeTaxReturn_VoucherDetail: 2,
  patternTaxReturn_VoucherDetail: "",
  minSizeDivisionNo_VoucherDetail: 0,
  maxSizeDivisionNo_VoucherDetail: 20,
  patternDivisionNo_VoucherDetail: "",
  minSizeDivisionName_VoucherDetail: 0,
  maxSizeDivisionName_VoucherDetail: 500,
  patternDivisionName_VoucherDetail: "",
  minSizeDivisionAddress_VoucherDetail: 0,
  maxSizeDivisionAddress_VoucherDetail: 500,
  patternDivisionAddress_VoucherDetail: "",
  minSizeTaxIdNo_VoucherDetail: 0,
  maxSizeTaxIdNo_VoucherDetail: 50,
  patternTaxIdNo_VoucherDetail: "",
  minSizeDetailDescription_VoucherDetail: 0,
  maxSizeDetailDescription_VoucherDetail: 255,
  patternDetailDescription_VoucherDetail: "",
  minSizeBaseValue1_VoucherDetail: 0,
  maxSizeBaseValue1_VoucherDetail: 26,
  patternBaseValue1_VoucherDetail: "",
  minSizeVatAmount1_VoucherDetail: 0,
  maxSizeVatAmount1_VoucherDetail: 26,
  patternVatAmount1_VoucherDetail: "",
  minSizeJobMasterId_VoucherDetail: 0,
  maxSizeJobMasterId_VoucherDetail: 19,
  patternJobMasterId_VoucherDetail: "",
  minSizeVoucherMasterId_VoucherDetail: 0,
  maxSizeVoucherMasterId_VoucherDetail: 19,
  patternVoucherMasterId_VoucherDetail: "",
  minSizeAccountCodeId_VoucherDetail: 0,
  maxSizeAccountCodeId_VoucherDetail: 19,
  patternAccountCodeId_VoucherDetail: "[0-9a-zA-Z/-]+",
  minSizeDivisionId_VoucherDetail: 0,
  maxSizeDivisionId_VoucherDetail: 19,
  patternDivisionId_VoucherDetail: "",
  minSizeSectionId_VoucherDetail: 0,
  maxSizeSectionId_VoucherDetail: 19,
  patternSectionId_VoucherDetail: "",
  minSizeAccountDivisionName_VoucherDetail: 0,
  maxSizeAccountDivisionName_VoucherDetail: 255,
  patternAccountDivisionName_VoucherDetail: "",
  minSizeTaxReturnH_VoucherDetail: 0,
  maxSizeTaxReturnH_VoucherDetail: 2,
  patternTaxReturnH_VoucherDetail: "",
  minSizeDivisionId1_VoucherDetail: 0,
  maxSizeDivisionId1_VoucherDetail: 10,
  patternDivisionId1_VoucherDetail: "",
  minSizeSectionId1_VoucherDetail: 0,
  maxSizeSectionId1_VoucherDetail: 10,
  patternSectionId1_VoucherDetail: "",
  minSizeDivisionSuppname_VoucherDetail: 0,
  maxSizeDivisionSuppname_VoucherDetail: 255,
  patternDivisionSuppname_VoucherDetail: "",
  minSizeProductSupplierPriceId_ProductSupplierPrice: 0,
  maxSizeProductSupplierPriceId_ProductSupplierPrice: 19,
  patternProductSupplierPriceId_ProductSupplierPrice: "",
  minSizeProdSuppCode_ProductSupplierPrice: 0,
  maxSizeProdSuppCode_ProductSupplierPrice: 20,
  patternProdSuppCode_ProductSupplierPrice: "[0-9a-zA-Z/-]+",
  minSizeProdPurchaseUnitPrice_ProductSupplierPrice: 0,
  maxSizeProdPurchaseUnitPrice_ProductSupplierPrice: 26,
  patternProdPurchaseUnitPrice_ProductSupplierPrice: "",
  minSizeProdSuppDefault_ProductSupplierPrice: 0,
  maxSizeProdSuppDefault_ProductSupplierPrice: 2,
  patternProdSuppDefault_ProductSupplierPrice: "",
  minSizeCompanyCode_ProductSupplierPrice: 0,
  maxSizeCompanyCode_ProductSupplierPrice: 20,
  patternCompanyCode_ProductSupplierPrice: "[0-9a-zA-Z/-]+",
  minSizeUnitOfMeasureId_ProductSupplierPrice: 0,
  maxSizeUnitOfMeasureId_ProductSupplierPrice: 19,
  patternUnitOfMeasureId_ProductSupplierPrice: "",
  minSizePurchaseServiceDetailId_PurchaseServiceDetail: 0,
  maxSizePurchaseServiceDetailId_PurchaseServiceDetail: 19,
  patternPurchaseServiceDetailId_PurchaseServiceDetail: "",
  minSizeDetailNo_PurchaseServiceDetail: 0,
  maxSizeDetailNo_PurchaseServiceDetail: 2,
  patternDetailNo_PurchaseServiceDetail: "",
  minSizeServiceName_PurchaseServiceDetail: 0,
  maxSizeServiceName_PurchaseServiceDetail: 150,
  patternServiceName_PurchaseServiceDetail: "",
  minSizeServicePrice_PurchaseServiceDetail: 0,
  maxSizeServicePrice_PurchaseServiceDetail: 26,
  patternServicePrice_PurchaseServiceDetail: "",
  minSizeServiceQTY_PurchaseServiceDetail: 0,
  maxSizeServiceQTY_PurchaseServiceDetail: 26,
  patternServiceQTY_PurchaseServiceDetail: "",
  minSizeServiceBillingQTY_PurchaseServiceDetail: 0,
  maxSizeServiceBillingQTY_PurchaseServiceDetail: 26,
  patternServiceBillingQTY_PurchaseServiceDetail: "",
  minSizeServiceUnit_PurchaseServiceDetail: 0,
  maxSizeServiceUnit_PurchaseServiceDetail: 100,
  patternServiceUnit_PurchaseServiceDetail: "",
  minSizeLastPurchaseOrderDocNo_PurchaseServiceDetail: 0,
  maxSizeLastPurchaseOrderDocNo_PurchaseServiceDetail: 20,
  patternLastPurchaseOrderDocNo_PurchaseServiceDetail: "",
  minSizeReferDocNo_PurchaseServiceDetail: 0,
  maxSizeReferDocNo_PurchaseServiceDetail: 30,
  patternReferDocNo_PurchaseServiceDetail: "",
  minSizeCompanyCode_PurchaseServiceDetail: 0,
  maxSizeCompanyCode_PurchaseServiceDetail: 20,
  patternCompanyCode_PurchaseServiceDetail: "[0-9a-zA-Z/-]+",
  minSizeServiceDiscRate0_PurchaseServiceDetail: 0,
  maxSizeServiceDiscRate0_PurchaseServiceDetail: 20,
  patternServiceDiscRate0_PurchaseServiceDetail: "",
  minSizeServiceDiscRate_PurchaseServiceDetail: 0,
  maxSizeServiceDiscRate_PurchaseServiceDetail: 26,
  patternServiceDiscRate_PurchaseServiceDetail: "",
  minSizePurchaseServiceDateRequest_PurchaseServiceDetail: 0,
  maxSizePurchaseServiceDateRequest_PurchaseServiceDetail: 20,
  patternPurchaseServiceDateRequest_PurchaseServiceDetail: "",
  minSizeServiceDiscAmt_PurchaseServiceDetail: 0,
  maxSizeServiceDiscAmt_PurchaseServiceDetail: 26,
  patternServiceDiscAmt_PurchaseServiceDetail: "",
  minSizeServiceBillingPrice_PurchaseServiceDetail: 0,
  maxSizeServiceBillingPrice_PurchaseServiceDetail: 26,
  patternServiceBillingPrice_PurchaseServiceDetail: "",
  minSizeServiceNetPrice_PurchaseServiceDetail: 0,
  maxSizeServiceNetPrice_PurchaseServiceDetail: 26,
  patternServiceNetPrice_PurchaseServiceDetail: "",
  minSizeServiceDetail_PurchaseServiceDetail: 0,
  maxSizeServiceDetail_PurchaseServiceDetail: 250,
  patternServiceDetail_PurchaseServiceDetail: "",
  minSizePurchaseServiceMasterId_PurchaseServiceDetail: 0,
  maxSizePurchaseServiceMasterId_PurchaseServiceDetail: 19,
  patternPurchaseServiceMasterId_PurchaseServiceDetail: "",
  minSizeServiceMasterId_PurchaseServiceDetail: 0,
  maxSizeServiceMasterId_PurchaseServiceDetail: 19,
  patternServiceMasterId_PurchaseServiceDetail: "",
  minSizeJobMasterId_PurchaseServiceDetail: 0,
  maxSizeJobMasterId_PurchaseServiceDetail: 19,
  patternJobMasterId_PurchaseServiceDetail: "",
  minSizeJobDetailId_PurchaseServiceDetail: 0,
  maxSizeJobDetailId_PurchaseServiceDetail: 19,
  patternJobDetailId_PurchaseServiceDetail: "",
  minSizeProductReceiveInvoiceDetailId_ProductReceiveInvoiceDetail: 0,
  maxSizeProductReceiveInvoiceDetailId_ProductReceiveInvoiceDetail: 19,
  patternProductReceiveInvoiceDetailId_ProductReceiveInvoiceDetail: "",
  minSizeDetailNo_ProductReceiveInvoiceDetail: 0,
  maxSizeDetailNo_ProductReceiveInvoiceDetail: 10,
  patternDetailNo_ProductReceiveInvoiceDetail: "",
  minSizeProdQTY_ProductReceiveInvoiceDetail: 0,
  maxSizeProdQTY_ProductReceiveInvoiceDetail: 26,
  patternProdQTY_ProductReceiveInvoiceDetail: "",
  minSizeProdConvFactor_ProductReceiveInvoiceDetail: 0,
  maxSizeProdConvFactor_ProductReceiveInvoiceDetail: 26,
  patternProdConvFactor_ProductReceiveInvoiceDetail: "",
  minSizeProdBaseQty_ProductReceiveInvoiceDetail: 0,
  maxSizeProdBaseQty_ProductReceiveInvoiceDetail: 26,
  patternProdBaseQty_ProductReceiveInvoiceDetail: "",
  minSizeProdPrice_ProductReceiveInvoiceDetail: 0,
  maxSizeProdPrice_ProductReceiveInvoiceDetail: 26,
  patternProdPrice_ProductReceiveInvoiceDetail: "",
  minSizeProdDiscRate_ProductReceiveInvoiceDetail: 0,
  maxSizeProdDiscRate_ProductReceiveInvoiceDetail: 26,
  patternProdDiscRate_ProductReceiveInvoiceDetail: "",
  minSizeProdDiscAmt_ProductReceiveInvoiceDetail: 0,
  maxSizeProdDiscAmt_ProductReceiveInvoiceDetail: 26,
  patternProdDiscAmt_ProductReceiveInvoiceDetail: "",
  minSizeProdNetPrice_ProductReceiveInvoiceDetail: 0,
  maxSizeProdNetPrice_ProductReceiveInvoiceDetail: 26,
  patternProdNetPrice_ProductReceiveInvoiceDetail: "",
  minSizePoDocNo_ProductReceiveInvoiceDetail: 0,
  maxSizePoDocNo_ProductReceiveInvoiceDetail: 20,
  patternPoDocNo_ProductReceiveInvoiceDetail: "",
  minSizeProdDiscRate0_ProductReceiveInvoiceDetail: 0,
  maxSizeProdDiscRate0_ProductReceiveInvoiceDetail: 20,
  patternProdDiscRate0_ProductReceiveInvoiceDetail: "",
  minSizeProdVat_ProductReceiveInvoiceDetail: 0,
  maxSizeProdVat_ProductReceiveInvoiceDetail: 2,
  patternProdVat_ProductReceiveInvoiceDetail: "",
  minSizePoDocIndex_ProductReceiveInvoiceDetail: 0,
  maxSizePoDocIndex_ProductReceiveInvoiceDetail: 10,
  patternPoDocIndex_ProductReceiveInvoiceDetail: "",
  minSizeCompanyCode_ProductReceiveInvoiceDetail: 0,
  maxSizeCompanyCode_ProductReceiveInvoiceDetail: 20,
  patternCompanyCode_ProductReceiveInvoiceDetail: "[0-9a-zA-Z/-]+",
  minSizePoIncludeVat_ProductReceiveInvoiceDetail: 0,
  maxSizePoIncludeVat_ProductReceiveInvoiceDetail: 2,
  patternPoIncludeVat_ProductReceiveInvoiceDetail: "",
  minSizeSerialNo_ProductReceiveInvoiceDetail: 0,
  maxSizeSerialNo_ProductReceiveInvoiceDetail: 255,
  patternSerialNo_ProductReceiveInvoiceDetail: "",
  minSizeSerialNoText_ProductReceiveInvoiceDetail: 0,
  maxSizeSerialNoText_ProductReceiveInvoiceDetail: 9999,
  patternSerialNoText_ProductReceiveInvoiceDetail: "",
  minSizeProdLotNo_ProductReceiveInvoiceDetail: 0,
  maxSizeProdLotNo_ProductReceiveInvoiceDetail: 30,
  patternProdLotNo_ProductReceiveInvoiceDetail: "",
  minSizeInvoiceNo_ProductReceiveInvoiceDetail: 0,
  maxSizeInvoiceNo_ProductReceiveInvoiceDetail: 20,
  patternInvoiceNo_ProductReceiveInvoiceDetail: "",
  minSizeInvoiceDate_ProductReceiveInvoiceDetail: 0,
  maxSizeInvoiceDate_ProductReceiveInvoiceDetail: 20,
  patternInvoiceDate_ProductReceiveInvoiceDetail: "",
  minSizeWhCode_ProductReceiveInvoiceDetail: 0,
  maxSizeWhCode_ProductReceiveInvoiceDetail: 20,
  patternWhCode_ProductReceiveInvoiceDetail: "[0-9a-zA-Z/-]+",
  minSizeProdReceiveCn_ProductReceiveInvoiceDetail: 0,
  maxSizeProdReceiveCn_ProductReceiveInvoiceDetail: 26,
  patternProdReceiveCn_ProductReceiveInvoiceDetail: "",
  minSizeSerialQTY_ProductReceiveInvoiceDetail: 0,
  maxSizeSerialQTY_ProductReceiveInvoiceDetail: 26,
  patternSerialQTY_ProductReceiveInvoiceDetail: "",
  minSizeProdName_ProductReceiveInvoiceDetail: 0,
  maxSizeProdName_ProductReceiveInvoiceDetail: 300,
  patternProdName_ProductReceiveInvoiceDetail: "",
  minSizeProductMaster1Id_ProductReceiveInvoiceDetail: 0,
  maxSizeProductMaster1Id_ProductReceiveInvoiceDetail: 19,
  patternProductMaster1Id_ProductReceiveInvoiceDetail: "",
  minSizeProductReceiveInvoiceMasterId_ProductReceiveInvoiceDetail: 0,
  maxSizeProductReceiveInvoiceMasterId_ProductReceiveInvoiceDetail: 19,
  patternProductReceiveInvoiceMasterId_ProductReceiveInvoiceDetail: "",
  minSizeUnitOfMeasureId1_ProductReceiveInvoiceDetail: 0,
  maxSizeUnitOfMeasureId1_ProductReceiveInvoiceDetail: 19,
  patternUnitOfMeasureId1_ProductReceiveInvoiceDetail: "",
  minSizeUnitOfMeasureId2_ProductReceiveInvoiceDetail: 0,
  maxSizeUnitOfMeasureId2_ProductReceiveInvoiceDetail: 19,
  patternUnitOfMeasureId2_ProductReceiveInvoiceDetail: "",
  minSizeJobMasterId_ProductReceiveInvoiceDetail: 0,
  maxSizeJobMasterId_ProductReceiveInvoiceDetail: 19,
  patternJobMasterId_ProductReceiveInvoiceDetail: "",
  minSizeProdItemDetail_ProductReceiveInvoiceDetail: 0,
  maxSizeProdItemDetail_ProductReceiveInvoiceDetail: 399,
  patternProdItemDetail_ProductReceiveInvoiceDetail: "",
  minSizeProdNewPrice_ProductReceiveInvoiceDetail: 0,
  maxSizeProdNewPrice_ProductReceiveInvoiceDetail: 26,
  patternProdNewPrice_ProductReceiveInvoiceDetail: "",
  minSizeAdcost_Detail_ProductReceiveInvoiceDetail: 0,
  patternAdcost_Detail_ProductReceiveInvoiceDetail: "",
  minSizeDescription_ProductReceiveInvoiceDetail: 0,
  maxSizeDescription_ProductReceiveInvoiceDetail: 1000,
  patternDescription_ProductReceiveInvoiceDetail: "",
  minSizeCustName_ProductReceiveInvoiceDetail: 0,
  maxSizeCustName_ProductReceiveInvoiceDetail: 255,
  patternCustName_ProductReceiveInvoiceDetail: "",
  minSizeLotNo_ProductReceiveInvoiceDetail: 0,
  maxSizeLotNo_ProductReceiveInvoiceDetail: 50,
  patternLotNo_ProductReceiveInvoiceDetail: "",
  minSizeExpireDate_ProductReceiveInvoiceDetail: 0,
  maxSizeExpireDate_ProductReceiveInvoiceDetail: 20,
  patternExpireDate_ProductReceiveInvoiceDetail: "",
  minSizeLocationId_ProductReceiveInvoiceDetail: 0,
  maxSizeLocationId_ProductReceiveInvoiceDetail: 19,
  patternLocationId_ProductReceiveInvoiceDetail: "",
  minSizePayChequeId_PayCheque: 0,
  maxSizePayChequeId_PayCheque: 19,
  patternPayChequeId_PayCheque: "",
  minSizeDocNo_PayCheque: 0,
  maxSizeDocNo_PayCheque: 20,
  patternDocNo_PayCheque: "",
  minSizeDocDate_PayCheque: 0,
  maxSizeDocDate_PayCheque: 20,
  patternDocDate_PayCheque: "",
  minSizeChequeNo_PayCheque: 0,
  maxSizeChequeNo_PayCheque: 20,
  patternChequeNo_PayCheque: "",
  minSizeChequeDate_PayCheque: 0,
  maxSizeChequeDate_PayCheque: 20,
  patternChequeDate_PayCheque: "",
  minSizeChequeAmount_PayCheque: 0,
  maxSizeChequeAmount_PayCheque: 26,
  patternChequeAmount_PayCheque: "",
  minSizeCharge_PayCheque: 0,
  maxSizeCharge_PayCheque: 26,
  patternCharge_PayCheque: "",
  minSizeChequeStatus_PayCheque: 0,
  maxSizeChequeStatus_PayCheque: 2,
  patternChequeStatus_PayCheque: "",
  minSizeChequeBringFoward_PayCheque: 0,
  maxSizeChequeBringFoward_PayCheque: 2,
  patternChequeBringFoward_PayCheque: "",
  minSizeChequeRemark_PayCheque: 0,
  maxSizeChequeRemark_PayCheque: 9999,
  patternChequeRemark_PayCheque: "",
  minSizeChequeRegisterNumber_PayCheque: 0,
  maxSizeChequeRegisterNumber_PayCheque: 20,
  patternChequeRegisterNumber_PayCheque: "",
  minSizeChequeRegisterDate_PayCheque: 0,
  maxSizeChequeRegisterDate_PayCheque: 20,
  patternChequeRegisterDate_PayCheque: "",
  minSizeCreateOn_PayCheque: 0,
  maxSizeCreateOn_PayCheque: 20,
  patternCreateOn_PayCheque: "",
  minSizeUpdateOn_PayCheque: 0,
  maxSizeUpdateOn_PayCheque: 20,
  patternUpdateOn_PayCheque: "",
  minSizeCompanyCode_PayCheque: 0,
  maxSizeCompanyCode_PayCheque: 20,
  patternCompanyCode_PayCheque: "[0-9a-zA-Z/-]+",
  minSizeChequePayVoucherNo_PayCheque: 0,
  maxSizeChequePayVoucherNo_PayCheque: 20,
  patternChequePayVoucherNo_PayCheque: "",
  minSizeChequeAuto_PayCheque: 0,
  maxSizeChequeAuto_PayCheque: 20,
  patternChequeAuto_PayCheque: "",
  minSizePrintStatus_PayCheque: 0,
  maxSizePrintStatus_PayCheque: 2,
  patternPrintStatus_PayCheque: "",
  minSizePayAbleName_PayCheque: 0,
  maxSizePayAbleName_PayCheque: 255,
  patternPayAbleName_PayCheque: "",
  minSizeChequeReconcileDate_PayCheque: 0,
  maxSizeChequeReconcileDate_PayCheque: 20,
  patternChequeReconcileDate_PayCheque: "",
  minSizeDepositBookMasterId_PayCheque: 0,
  maxSizeDepositBookMasterId_PayCheque: 19,
  patternDepositBookMasterId_PayCheque: "",
  minSizeDocumentGroupId_PayCheque: 0,
  maxSizeDocumentGroupId_PayCheque: 19,
  patternDocumentGroupId_PayCheque: "",
  minSizeDivisionId_PayCheque: 0,
  maxSizeDivisionId_PayCheque: 19,
  patternDivisionId_PayCheque: "",
  minSizeSectionId_PayCheque: 0,
  maxSizeSectionId_PayCheque: 19,
  patternSectionId_PayCheque: "",
  minSizeSupplierMasterId_PayCheque: 0,
  maxSizeSupplierMasterId_PayCheque: 19,
  patternSupplierMasterId_PayCheque: "",
  minSizeChequeReceive_PayCheque: 0,
  maxSizeChequeReceive_PayCheque: 100,
  patternChequeReceive_PayCheque: "",
  minSizeChequeReceiveDate_PayCheque: 0,
  maxSizeChequeReceiveDate_PayCheque: 20,
  patternChequeReceiveDate_PayCheque: "",
  minSizeChequeReceiveStatus_PayCheque: 0,
  maxSizeChequeReceiveStatus_PayCheque: 5,
  patternChequeReceiveStatus_PayCheque: "",
  minSizeContractNo_PayCheque: 0,
  maxSizeContractNo_PayCheque: 20,
  patternContractNo_PayCheque: "",
  minSizeAppend_PayCheque: 0,
  maxSizeAppend_PayCheque: 1000,
  patternAppend_PayCheque: "",
  minSizeDescriptionCancelCheque_PayCheque: 0,
  maxSizeDescriptionCancelCheque_PayCheque: 255,
  patternDescriptionCancelCheque_PayCheque: "",
  minSizeWhtId_PaymentVoucherWht: 0,
  maxSizeWhtId_PaymentVoucherWht: 19,
  patternWhtId_PaymentVoucherWht: "",
  minSizeWhtCode_PaymentVoucherWht: 0,
  maxSizeWhtCode_PaymentVoucherWht: 255,
  patternWhtCode_PaymentVoucherWht: "[0-9a-zA-Z/-]+",
  minSizeWhtDate_PaymentVoucherWht: 0,
  maxSizeWhtDate_PaymentVoucherWht: 255,
  patternWhtDate_PaymentVoucherWht: "",
  minSizeWhtSupName_PaymentVoucherWht: 0,
  maxSizeWhtSupName_PaymentVoucherWht: 255,
  patternWhtSupName_PaymentVoucherWht: "",
  minSizeWhtAddress_PaymentVoucherWht: 0,
  maxSizeWhtAddress_PaymentVoucherWht: 255,
  patternWhtAddress_PaymentVoucherWht: "",
  minSizeWhtPersonTaxId_PaymentVoucherWht: 0,
  maxSizeWhtPersonTaxId_PaymentVoucherWht: 255,
  patternWhtPersonTaxId_PaymentVoucherWht: "",
  minSizeWhtCompanyTaxId_PaymentVoucherWht: 0,
  maxSizeWhtCompanyTaxId_PaymentVoucherWht: 255,
  patternWhtCompanyTaxId_PaymentVoucherWht: "",
  minSizeWhtGroup_PaymentVoucherWht: 0,
  maxSizeWhtGroup_PaymentVoucherWht: 10,
  patternWhtGroup_PaymentVoucherWht: "",
  minSizeWhtType_PaymentVoucherWht: 0,
  maxSizeWhtType_PaymentVoucherWht: 255,
  patternWhtType_PaymentVoucherWht: "",
  minSizeEmployeeName_PaymentVoucherWht: 0,
  maxSizeEmployeeName_PaymentVoucherWht: 50,
  patternEmployeeName_PaymentVoucherWht: "",
  minSizeWhtRate_PaymentVoucherWht: 0,
  maxSizeWhtRate_PaymentVoucherWht: 26,
  patternWhtRate_PaymentVoucherWht: "",
  minSizeWhtAmount_PaymentVoucherWht: 0,
  maxSizeWhtAmount_PaymentVoucherWht: 26,
  patternWhtAmount_PaymentVoucherWht: "",
  minSizeWhtTax_PaymentVoucherWht: 0,
  maxSizeWhtTax_PaymentVoucherWht: 26,
  patternWhtTax_PaymentVoucherWht: "",
  minSizeWhtPayType_PaymentVoucherWht: 0,
  maxSizeWhtPayType_PaymentVoucherWht: 10,
  patternWhtPayType_PaymentVoucherWht: "",
  minSizeCompanyCode_PaymentVoucherWht: 0,
  maxSizeCompanyCode_PaymentVoucherWht: 255,
  patternCompanyCode_PaymentVoucherWht: "[0-9a-zA-Z/-]+",
  minSizeReferanceNo_PaymentVoucherWht: 0,
  maxSizeReferanceNo_PaymentVoucherWht: 50,
  patternReferanceNo_PaymentVoucherWht: "",
  minSizeEmployeePrefix_PaymentVoucherWht: 0,
  maxSizeEmployeePrefix_PaymentVoucherWht: 20,
  patternEmployeePrefix_PaymentVoucherWht: "",
  minSizeEmployeeSurName_PaymentVoucherWht: 0,
  maxSizeEmployeeSurName_PaymentVoucherWht: 250,
  patternEmployeeSurName_PaymentVoucherWht: "",
  minSizeEmpAddress_PaymentVoucherWht: 0,
  maxSizeEmpAddress_PaymentVoucherWht: 255,
  patternEmpAddress_PaymentVoucherWht: "",
  minSizePostCode_PaymentVoucherWht: 0,
  maxSizePostCode_PaymentVoucherWht: 20,
  patternPostCode_PaymentVoucherWht: "[0-9a-zA-Z/-]+",
  minSizeMonthTax_PaymentVoucherWht: 0,
  maxSizeMonthTax_PaymentVoucherWht: 5,
  patternMonthTax_PaymentVoucherWht: "",
  minSizeYearTax_PaymentVoucherWht: 0,
  maxSizeYearTax_PaymentVoucherWht: 10,
  patternYearTax_PaymentVoucherWht: "",
  minSizeIncomeCode_PaymentVoucherWht: 0,
  maxSizeIncomeCode_PaymentVoucherWht: 10,
  patternIncomeCode_PaymentVoucherWht: "[0-9a-zA-Z/-]+",
  minSizePaymentVoucherMasterId_PaymentVoucherWht: 0,
  maxSizePaymentVoucherMasterId_PaymentVoucherWht: 19,
  patternPaymentVoucherMasterId_PaymentVoucherWht: "",
  minSizeVat54Type_PaymentVoucherWht: 0,
  maxSizeVat54Type_PaymentVoucherWht: 2,
  patternVat54Type_PaymentVoucherWht: "",
  minSizeAmountProfit_PaymentVoucherWht: 0,
  maxSizeAmountProfit_PaymentVoucherWht: 26,
  patternAmountProfit_PaymentVoucherWht: "",
  minSizeVatProfit_PaymentVoucherWht: 0,
  maxSizeVatProfit_PaymentVoucherWht: 26,
  patternVatProfit_PaymentVoucherWht: "",
  minSizeProfitDate_PaymentVoucherWht: 0,
  maxSizeProfitDate_PaymentVoucherWht: 20,
  patternProfitDate_PaymentVoucherWht: "",
  minSizeDocExchange_PaymentVoucherWht: 0,
  maxSizeDocExchange_PaymentVoucherWht: 200,
  patternDocExchange_PaymentVoucherWht: "",
  minSizeChkAdd_PaymentVoucherWht: 0,
  maxSizeChkAdd_PaymentVoucherWht: 2,
  patternChkAdd_PaymentVoucherWht: "",
  minSizeNumberIssue_PaymentVoucherWht: 0,
  maxSizeNumberIssue_PaymentVoucherWht: 10,
  patternNumberIssue_PaymentVoucherWht: "",
  minSizeWhtStatus_PaymentVoucherWht: 0,
  maxSizeWhtStatus_PaymentVoucherWht: 1,
  patternWhtStatus_PaymentVoucherWht: "",
  minSizeChequeNo_PaymentVoucherWht: 0,
  maxSizeChequeNo_PaymentVoucherWht: 20,
  patternChequeNo_PaymentVoucherWht: "",
  minSizeDivisionCode_PaymentVoucherWht: 0,
  maxSizeDivisionCode_PaymentVoucherWht: 20,
  patternDivisionCode_PaymentVoucherWht: "[0-9a-zA-Z/-]+",
  minSizeDivisionSupplier_PaymentVoucherWht: 0,
  maxSizeDivisionSupplier_PaymentVoucherWht: 20,
  patternDivisionSupplier_PaymentVoucherWht: "",
  minSizeSuppCode_PaymentVoucherWht: 0,
  maxSizeSuppCode_PaymentVoucherWht: 50,
  patternSuppCode_PaymentVoucherWht: "[0-9a-zA-Z/-]+",
  minSizeWhtNo_PaymentVoucherWht: 0,
  maxSizeWhtNo_PaymentVoucherWht: 26,
  patternWhtNo_PaymentVoucherWht: "",
  minSizeReferenceDocNo_PaymentVoucherWht: 0,
  maxSizeReferenceDocNo_PaymentVoucherWht: 20,
  patternReferenceDocNo_PaymentVoucherWht: "",
  minSizeSsfAmount_PaymentVoucherWht: 0,
  maxSizeSsfAmount_PaymentVoucherWht: 26,
  patternSsfAmount_PaymentVoucherWht: "",
  minSizePfAmount_PaymentVoucherWht: 0,
  maxSizePfAmount_PaymentVoucherWht: 26,
  patternPfAmount_PaymentVoucherWht: "",
  minSizeSupplierMasterId_PaymentVoucherWht: 0,
  maxSizeSupplierMasterId_PaymentVoucherWht: 19,
  patternSupplierMasterId_PaymentVoucherWht: "",
  minSizeCustomerMasterId_PaymentVoucherWht: 0,
  maxSizeCustomerMasterId_PaymentVoucherWht: 19,
  patternCustomerMasterId_PaymentVoucherWht: "",
  minSizeDivisionSCId_PaymentVoucherWht: 0,
  maxSizeDivisionSCId_PaymentVoucherWht: 19,
  patternDivisionSCId_PaymentVoucherWht: "",
  minSizeDivisionId_PaymentVoucherWht: 0,
  maxSizeDivisionId_PaymentVoucherWht: 19,
  patternDivisionId_PaymentVoucherWht: "",
  minSizeEmployeeMasterId_PaymentVoucherWht: 0,
  maxSizeEmployeeMasterId_PaymentVoucherWht: 19,
  patternEmployeeMasterId_PaymentVoucherWht: "",
  minSizePoServiceMasterId_PoServiceMaster: 0,
  maxSizePoServiceMasterId_PoServiceMaster: 19,
  patternPoServiceMasterId_PoServiceMaster: "",
  minSizePosDocNo_PoServiceMaster: 0,
  maxSizePosDocNo_PoServiceMaster: 20,
  patternPosDocNo_PoServiceMaster: "",
  minSizePosDocDate_PoServiceMaster: 0,
  maxSizePosDocDate_PoServiceMaster: 20,
  patternPosDocDate_PoServiceMaster: "",
  minSizePosDateRequire_PoServiceMaster: 0,
  maxSizePosDateRequire_PoServiceMaster: 20,
  patternPosDateRequire_PoServiceMaster: "",
  minSizePosTotal_PoServiceMaster: 0,
  maxSizePosTotal_PoServiceMaster: 26,
  patternPosTotal_PoServiceMaster: "",
  minSizePosTotalNovat_PoServiceMaster: 0,
  maxSizePosTotalNovat_PoServiceMaster: 26,
  patternPosTotalNovat_PoServiceMaster: "",
  minSizePosDiscRate_PoServiceMaster: 0,
  maxSizePosDiscRate_PoServiceMaster: 26,
  patternPosDiscRate_PoServiceMaster: "",
  minSizePosDiscAmt_PoServiceMaster: 0,
  maxSizePosDiscAmt_PoServiceMaster: 26,
  patternPosDiscAmt_PoServiceMaster: "",
  minSizePosVatRate_PoServiceMaster: 0,
  maxSizePosVatRate_PoServiceMaster: 26,
  patternPosVatRate_PoServiceMaster: "",
  minSizePosVatAmt_PoServiceMaster: 0,
  maxSizePosVatAmt_PoServiceMaster: 26,
  patternPosVatAmt_PoServiceMaster: "",
  minSizePosNetAmt_PoServiceMaster: 0,
  maxSizePosNetAmt_PoServiceMaster: 26,
  patternPosNetAmt_PoServiceMaster: "",
  minSizePosRemark_PoServiceMaster: 0,
  maxSizePosRemark_PoServiceMaster: 1000,
  patternPosRemark_PoServiceMaster: "",
  minSizePosStatus_PoServiceMaster: 0,
  maxSizePosStatus_PoServiceMaster: 2,
  patternPosStatus_PoServiceMaster: "",
  minSizePosLinkDocNo_PoServiceMaster: 0,
  maxSizePosLinkDocNo_PoServiceMaster: 20,
  patternPosLinkDocNo_PoServiceMaster: "",
  minSizeCreateOn_PoServiceMaster: 0,
  maxSizeCreateOn_PoServiceMaster: 20,
  patternCreateOn_PoServiceMaster: "",
  minSizeUpdateOn_PoServiceMaster: 0,
  maxSizeUpdateOn_PoServiceMaster: 20,
  patternUpdateOn_PoServiceMaster: "",
  minSizeCompanyCode_PoServiceMaster: 0,
  maxSizeCompanyCode_PoServiceMaster: 20,
  patternCompanyCode_PoServiceMaster: "[0-9a-zA-Z/-]+",
  minSizeVatInclude_PoServiceMaster: 0,
  maxSizeVatInclude_PoServiceMaster: 2,
  patternVatInclude_PoServiceMaster: "",
  minSizeExchangeRate_PoServiceMaster: 0,
  maxSizeExchangeRate_PoServiceMaster: 26,
  patternExchangeRate_PoServiceMaster: "",
  minSizeExchageRateDate_PoServiceMaster: 0,
  maxSizeExchageRateDate_PoServiceMaster: 20,
  patternExchageRateDate_PoServiceMaster: "",
  minSizeAstatus_PoServiceMaster: 0,
  maxSizeAstatus_PoServiceMaster: 2,
  patternAstatus_PoServiceMaster: "",
  minSizeReviseNo_PoServiceMaster: 0,
  maxSizeReviseNo_PoServiceMaster: 10,
  patternReviseNo_PoServiceMaster: "",
  minSizeCurrencyId_PoServiceMaster: 0,
  maxSizeCurrencyId_PoServiceMaster: 19,
  patternCurrencyId_PoServiceMaster: "",
  minSizeDocumentGroupId_PoServiceMaster: 0,
  maxSizeDocumentGroupId_PoServiceMaster: 19,
  patternDocumentGroupId_PoServiceMaster: "",
  minSizeSupplierMasterId_PoServiceMaster: 0,
  maxSizeSupplierMasterId_PoServiceMaster: 19,
  patternSupplierMasterId_PoServiceMaster: "",
  minSizeEmployeeMasterId_PoServiceMaster: 0,
  maxSizeEmployeeMasterId_PoServiceMaster: 19,
  patternEmployeeMasterId_PoServiceMaster: "",
  minSizeDivisionId_PoServiceMaster: 0,
  maxSizeDivisionId_PoServiceMaster: 19,
  patternDivisionId_PoServiceMaster: "",
  minSizeSectionId_PoServiceMaster: 0,
  maxSizeSectionId_PoServiceMaster: 19,
  patternSectionId_PoServiceMaster: "",
  minSizeCreateByEmpId_PoServiceMaster: 0,
  maxSizeCreateByEmpId_PoServiceMaster: 19,
  patternCreateByEmpId_PoServiceMaster: "",
  minSizeUpdateByEmpId_PoServiceMaster: 0,
  maxSizeUpdateByEmpId_PoServiceMaster: 19,
  patternUpdateByEmpId_PoServiceMaster: "",
  minSizePrintStatus_PoServiceMaster: 0,
  maxSizePrintStatus_PoServiceMaster: 2,
  patternPrintStatus_PoServiceMaster: "",
  minSizeSuppAddress_PoServiceMaster: 0,
  maxSizeSuppAddress_PoServiceMaster: 200,
  patternSuppAddress_PoServiceMaster: "",
  minSizeSuppDivisionNo_PoServiceMaster: 0,
  maxSizeSuppDivisionNo_PoServiceMaster: 20,
  patternSuppDivisionNo_PoServiceMaster: "",
  minSizeUserApprove_PoServiceMaster: 0,
  maxSizeUserApprove_PoServiceMaster: 20,
  patternUserApprove_PoServiceMaster: "",
  minSizeSuppTName_PoServiceMaster: 0,
  maxSizeSuppTName_PoServiceMaster: 255,
  patternSuppTName_PoServiceMaster: "",
  minSizePrProdUse_SaleOrderProductDetail: 0,
  maxSizePrProdUse_SaleOrderProductDetail: 26,
  patternPrProdUse_SaleOrderProductDetail: "",
  minSizePurchaseRequestInvoiceMasterId_PurchaseReqInvMasterJobMaster: 0,
  maxSizePurchaseRequestInvoiceMasterId_PurchaseReqInvMasterJobMaster: 19,
  patternPurchaseRequestInvoiceMasterId_PurchaseReqInvMasterJobMaster: "",
  minSizeJobMasterId_PurchaseReqInvMasterJobMaster: 0,
  maxSizeJobMasterId_PurchaseReqInvMasterJobMaster: 19,
  patternJobMasterId_PurchaseReqInvMasterJobMaster: "",
  minSizeSaleOrderProductMasterId_SaleOrderProdMasterJobMaster: 0,
  maxSizeSaleOrderProductMasterId_SaleOrderProdMasterJobMaster: 19,
  patternSaleOrderProductMasterId_SaleOrderProdMasterJobMaster: "",
  minSizeJobMasterId_SaleOrderProdMasterJobMaster: 0,
  maxSizeJobMasterId_SaleOrderProdMasterJobMaster: 19,
  patternJobMasterId_SaleOrderProdMasterJobMaster: "",
  minSizePurchaseRequestInvoiceDetailId_PurchaseRequestInvoiceDetail: 0,
  maxSizePurchaseRequestInvoiceDetailId_PurchaseRequestInvoiceDetail: 19,
  patternPurchaseRequestInvoiceDetailId_PurchaseRequestInvoiceDetail: "",
  minSizeDetailNo_PurchaseRequestInvoiceDetail: 0,
  maxSizeDetailNo_PurchaseRequestInvoiceDetail: 255,
  patternDetailNo_PurchaseRequestInvoiceDetail: "",
  minSizeProdQty_PurchaseRequestInvoiceDetail: 0,
  maxSizeProdQty_PurchaseRequestInvoiceDetail: 26,
  patternProdQty_PurchaseRequestInvoiceDetail: "",
  minSizeProdConvFactor_PurchaseRequestInvoiceDetail: 0,
  maxSizeProdConvFactor_PurchaseRequestInvoiceDetail: 26,
  patternProdConvFactor_PurchaseRequestInvoiceDetail: "",
  minSizeProdBaseQty_PurchaseRequestInvoiceDetail: 0,
  maxSizeProdBaseQty_PurchaseRequestInvoiceDetail: 26,
  patternProdBaseQty_PurchaseRequestInvoiceDetail: "",
  minSizeProdOrderQty_PurchaseRequestInvoiceDetail: 0,
  maxSizeProdOrderQty_PurchaseRequestInvoiceDetail: 26,
  patternProdOrderQty_PurchaseRequestInvoiceDetail: "",
  minSizeProdOrderBaseQty_PurchaseRequestInvoiceDetail: 0,
  maxSizeProdOrderBaseQty_PurchaseRequestInvoiceDetail: 26,
  patternProdOrderBaseQty_PurchaseRequestInvoiceDetail: "",
  minSizeLastPurchaseOrderDocNo_PurchaseRequestInvoiceDetail: 0,
  maxSizeLastPurchaseOrderDocNo_PurchaseRequestInvoiceDetail: 20,
  patternLastPurchaseOrderDocNo_PurchaseRequestInvoiceDetail: "",
  minSizeReferDocNo_PurchaseRequestInvoiceDetail: 0,
  maxSizeReferDocNo_PurchaseRequestInvoiceDetail: 30,
  patternReferDocNo_PurchaseRequestInvoiceDetail: "",
  minSizeCompanyCode_PurchaseRequestInvoiceDetail: 0,
  maxSizeCompanyCode_PurchaseRequestInvoiceDetail: 20,
  patternCompanyCode_PurchaseRequestInvoiceDetail: "[0-9a-zA-Z/-]+",
  minSizeProdDiscRate0_PurchaseRequestInvoiceDetail: 0,
  maxSizeProdDiscRate0_PurchaseRequestInvoiceDetail: 20,
  patternProdDiscRate0_PurchaseRequestInvoiceDetail: "",
  minSizePurReqDateRequest_PurchaseRequestInvoiceDetail: 0,
  maxSizePurReqDateRequest_PurchaseRequestInvoiceDetail: 20,
  patternPurReqDateRequest_PurchaseRequestInvoiceDetail: "",
  minSizeProdItemDetail_PurchaseRequestInvoiceDetail: 0,
  maxSizeProdItemDetail_PurchaseRequestInvoiceDetail: 9999,
  patternProdItemDetail_PurchaseRequestInvoiceDetail: "",
  minSizeProdName_PurchaseRequestInvoiceDetail: 0,
  maxSizeProdName_PurchaseRequestInvoiceDetail: 150,
  patternProdName_PurchaseRequestInvoiceDetail: "",
  minSizeProdPrice_PurchaseRequestInvoiceDetail: 0,
  maxSizeProdPrice_PurchaseRequestInvoiceDetail: 26,
  patternProdPrice_PurchaseRequestInvoiceDetail: "",
  minSizeProdDiscRate_PurchaseRequestInvoiceDetail: 0,
  maxSizeProdDiscRate_PurchaseRequestInvoiceDetail: 26,
  patternProdDiscRate_PurchaseRequestInvoiceDetail: "",
  minSizeProdDiscAmt_PurchaseRequestInvoiceDetail: 0,
  maxSizeProdDiscAmt_PurchaseRequestInvoiceDetail: 26,
  patternProdDiscAmt_PurchaseRequestInvoiceDetail: "",
  minSizeProdNetPrice_PurchaseRequestInvoiceDetail: 0,
  maxSizeProdNetPrice_PurchaseRequestInvoiceDetail: 26,
  patternProdNetPrice_PurchaseRequestInvoiceDetail: "",
  minSizeProductMaster1Id_PurchaseRequestInvoiceDetail: 0,
  maxSizeProductMaster1Id_PurchaseRequestInvoiceDetail: 19,
  patternProductMaster1Id_PurchaseRequestInvoiceDetail: "",
  minSizePurchaseRequestInvoiceMasterId_PurchaseRequestInvoiceDetail: 0,
  maxSizePurchaseRequestInvoiceMasterId_PurchaseRequestInvoiceDetail: 19,
  patternPurchaseRequestInvoiceMasterId_PurchaseRequestInvoiceDetail: "",
  minSizeUnitOfMeasureId1_PurchaseRequestInvoiceDetail: 0,
  maxSizeUnitOfMeasureId1_PurchaseRequestInvoiceDetail: 19,
  patternUnitOfMeasureId1_PurchaseRequestInvoiceDetail: "",
  minSizeUnitOfMeasureId2_PurchaseRequestInvoiceDetail: 0,
  maxSizeUnitOfMeasureId2_PurchaseRequestInvoiceDetail: 19,
  patternUnitOfMeasureId2_PurchaseRequestInvoiceDetail: "",
  minSizeWareHouseId_PurchaseRequestInvoiceDetail: 0,
  maxSizeWareHouseId_PurchaseRequestInvoiceDetail: 19,
  patternWareHouseId_PurchaseRequestInvoiceDetail: "",
  minSizeJobMasterId_PurchaseRequestInvoiceDetail: 0,
  maxSizeJobMasterId_PurchaseRequestInvoiceDetail: 19,
  patternJobMasterId_PurchaseRequestInvoiceDetail: "",
  minSizeSupplierMasterId_PurchaseRequestInvoiceDetail: 0,
  maxSizeSupplierMasterId_PurchaseRequestInvoiceDetail: 19,
  patternSupplierMasterId_PurchaseRequestInvoiceDetail: "",
  minSizePurReqInvoiceCompareId_PurchaseRequestInvoiceDetail: 0,
  maxSizePurReqInvoiceCompareId_PurchaseRequestInvoiceDetail: 19,
  patternPurReqInvoiceCompareId_PurchaseRequestInvoiceDetail: "",
  minSizeSoMasterId_PurchaseRequestInvoiceDetail: 0,
  maxSizeSoMasterId_PurchaseRequestInvoiceDetail: 19,
  patternSoMasterId_PurchaseRequestInvoiceDetail: "",
  minSizeSoDetailId_PurchaseRequestInvoiceDetail: 0,
  maxSizeSoDetailId_PurchaseRequestInvoiceDetail: 19,
  patternSoDetailId_PurchaseRequestInvoiceDetail: "",
  minSizeSoDocNo_PurchaseRequestInvoiceDetail: 0,
  maxSizeSoDocNo_PurchaseRequestInvoiceDetail: 20,
  patternSoDocNo_PurchaseRequestInvoiceDetail: "",
  minSizePoServiceDetailId_PoServiceDetail: 0,
  maxSizePoServiceDetailId_PoServiceDetail: 19,
  patternPoServiceDetailId_PoServiceDetail: "",
  minSizePoServiceDetailNo_PoServiceDetail: 0,
  maxSizePoServiceDetailNo_PoServiceDetail: 10,
  patternPoServiceDetailNo_PoServiceDetail: "",
  minSizeServiceQTY_PoServiceDetail: 0,
  maxSizeServiceQTY_PoServiceDetail: 26,
  patternServiceQTY_PoServiceDetail: "",
  minSizeServiceBillingQTY_PoServiceDetail: 0,
  maxSizeServiceBillingQTY_PoServiceDetail: 26,
  patternServiceBillingQTY_PoServiceDetail: "",
  minSizeServiceUnit_PoServiceDetail: 0,
  maxSizeServiceUnit_PoServiceDetail: 100,
  patternServiceUnit_PoServiceDetail: "",
  minSizeServicePrice_PoServiceDetail: 0,
  maxSizeServicePrice_PoServiceDetail: 26,
  patternServicePrice_PoServiceDetail: "",
  minSizeServiceDiscRate_PoServiceDetail: 0,
  maxSizeServiceDiscRate_PoServiceDetail: 26,
  patternServiceDiscRate_PoServiceDetail: "",
  minSizeServiceDiscAmt_PoServiceDetail: 0,
  maxSizeServiceDiscAmt_PoServiceDetail: 26,
  patternServiceDiscAmt_PoServiceDetail: "",
  minSizeServiceNetPrice_PoServiceDetail: 0,
  maxSizeServiceNetPrice_PoServiceDetail: 26,
  patternServiceNetPrice_PoServiceDetail: "",
  minSizeQuDocNo_PoServiceDetail: 0,
  maxSizeQuDocNo_PoServiceDetail: 20,
  patternQuDocNo_PoServiceDetail: "",
  minSizeServiceVat_PoServiceDetail: 0,
  maxSizeServiceVat_PoServiceDetail: 2,
  patternServiceVat_PoServiceDetail: "",
  minSizeQuDocIndex_PoServiceDetail: 0,
  maxSizeQuDocIndex_PoServiceDetail: 10,
  patternQuDocIndex_PoServiceDetail: "",
  minSizeServiceBnDocDate_PoServiceDetail: 0,
  maxSizeServiceBnDocDate_PoServiceDetail: 20,
  patternServiceBnDocDate_PoServiceDetail: "",
  minSizeServiceBnDateComplete_PoServiceDetail: 0,
  maxSizeServiceBnDateComplete_PoServiceDetail: 20,
  patternServiceBnDateComplete_PoServiceDetail: "",
  minSizeServiceBnDocNo_PoServiceDetail: 0,
  maxSizeServiceBnDocNo_PoServiceDetail: 20,
  patternServiceBnDocNo_PoServiceDetail: "",
  minSizeCompanyCode_PoServiceDetail: 0,
  maxSizeCompanyCode_PoServiceDetail: 20,
  patternCompanyCode_PoServiceDetail: "[0-9a-zA-Z/-]+",
  minSizeServiceDiscRate0_PoServiceDetail: 0,
  maxSizeServiceDiscRate0_PoServiceDetail: 20,
  patternServiceDiscRate0_PoServiceDetail: "",
  minSizeServiceItemDetail_PoServiceDetail: 0,
  maxSizeServiceItemDetail_PoServiceDetail: 9999,
  patternServiceItemDetail_PoServiceDetail: "",
  minSizeServiceMasterId_PoServiceDetail: 0,
  maxSizeServiceMasterId_PoServiceDetail: 19,
  patternServiceMasterId_PoServiceDetail: "",
  minSizePoServiceMasterId_PoServiceDetail: 0,
  maxSizePoServiceMasterId_PoServiceDetail: 19,
  patternPoServiceMasterId_PoServiceDetail: "",
  minSizeServiceName_PoServiceDetail: 0,
  maxSizeServiceName_PoServiceDetail: 255,
  patternServiceName_PoServiceDetail: "",
  minSizeDocRefNo_PoServiceDetail: 0,
  maxSizeDocRefNo_PoServiceDetail: 20,
  patternDocRefNo_PoServiceDetail: "",
  minSizeSprDocIndex_PoServiceDetail: 0,
  maxSizeSprDocIndex_PoServiceDetail: 2,
  patternSprDocIndex_PoServiceDetail: "",
  minSizeServiceReceivePrice_PoServiceDetail: 0,
  maxSizeServiceReceivePrice_PoServiceDetail: 26,
  patternServiceReceivePrice_PoServiceDetail: "",
  minSizeBkPrice_PoServiceDetail: 0,
  maxSizeBkPrice_PoServiceDetail: 24,
  patternBkPrice_PoServiceDetail: "",
  minSizeServiceReceiveDate_PoServiceDetail: 0,
  maxSizeServiceReceiveDate_PoServiceDetail: 20,
  patternServiceReceiveDate_PoServiceDetail: "",
  minSizeJobMasterId_PoServiceDetail: 0,
  maxSizeJobMasterId_PoServiceDetail: 19,
  patternJobMasterId_PoServiceDetail: "",
  minSizeJobDetailId_PoServiceDetail: 0,
  maxSizeJobDetailId_PoServiceDetail: 19,
  patternJobDetailId_PoServiceDetail: "",
  minSizeDeliveryOrderReceiveId_DeliveryOrderRecDepositBookOne: 0,
  maxSizeDeliveryOrderReceiveId_DeliveryOrderRecDepositBookOne: 19,
  patternDeliveryOrderReceiveId_DeliveryOrderRecDepositBookOne: "",
  minSizeDepositBookMasterIdOne_DeliveryOrderRecDepositBookOne: 0,
  maxSizeDepositBookMasterIdOne_DeliveryOrderRecDepositBookOne: 19,
  patternDepositBookMasterIdOne_DeliveryOrderRecDepositBookOne: "",
  minSizeVoucherMasterId_VoucherMasterAccountTemplate: 0,
  maxSizeVoucherMasterId_VoucherMasterAccountTemplate: 19,
  patternVoucherMasterId_VoucherMasterAccountTemplate: "",
  minSizeAccountTemplateId_VoucherMasterAccountTemplate: 0,
  maxSizeAccountTemplateId_VoucherMasterAccountTemplate: 19,
  patternAccountTemplateId_VoucherMasterAccountTemplate: "",
  minSizeRcvServiceMasterId_RCVServiceMasterJobMaster: 0,
  maxSizeRcvServiceMasterId_RCVServiceMasterJobMaster: 19,
  patternRcvServiceMasterId_RCVServiceMasterJobMaster: "",
  minSizeJobMasterId_RCVServiceMasterJobMaster: 0,
  maxSizeJobMasterId_RCVServiceMasterJobMaster: 19,
  patternJobMasterId_RCVServiceMasterJobMaster: "",
  minSizeAstatus_SaleOrderProductMaster: 0,
  maxSizeAstatus_SaleOrderProductMaster: 2,
  patternAstatus_SaleOrderProductMaster: "",
  minSizePoNo_SaleOrderProductMaster: 0,
  maxSizePoNo_SaleOrderProductMaster: 100,
  patternPoNo_SaleOrderProductMaster: "",
  minSizeCustDelivery_SaleOrderProductMaster: 0,
  maxSizeCustDelivery_SaleOrderProductMaster: 250,
  patternCustDelivery_SaleOrderProductMaster: "",
  minSizePrType_SaleOrderProductMaster: 0,
  maxSizePrType_SaleOrderProductMaster: 20,
  patternPrType_SaleOrderProductMaster: "",
  minSizePayChequeId_PayChequeJobMaster: 0,
  maxSizePayChequeId_PayChequeJobMaster: 19,
  patternPayChequeId_PayChequeJobMaster: "",
  minSizeJobMasterId_PayChequeJobMaster: 0,
  maxSizeJobMasterId_PayChequeJobMaster: 19,
  patternJobMasterId_PayChequeJobMaster: "",
  minSizeNId_SupplierMaster: 0,
  maxSizeNId_SupplierMaster: 13,
  patternNId_SupplierMaster: "",
  minSizePurchaseOrderMasterId_PurchaseOrderMasterJobMaster: 0,
  maxSizePurchaseOrderMasterId_PurchaseOrderMasterJobMaster: 19,
  patternPurchaseOrderMasterId_PurchaseOrderMasterJobMaster: "",
  minSizeJobMasterId_PurchaseOrderMasterJobMaster: 0,
  maxSizeJobMasterId_PurchaseOrderMasterJobMaster: 19,
  patternJobMasterId_PurchaseOrderMasterJobMaster: "",
  minSizeVoucherMasterId_VoucherMasterJobMaster: 0,
  maxSizeVoucherMasterId_VoucherMasterJobMaster: 19,
  patternVoucherMasterId_VoucherMasterJobMaster: "",
  minSizeJobMasterId_VoucherMasterJobMaster: 0,
  maxSizeJobMasterId_VoucherMasterJobMaster: 19,
  patternJobMasterId_VoucherMasterJobMaster: "",
  minSizePurchaseRequestInvoiceMasterId_PurchaseRequestInvoiceMaster: 0,
  maxSizePurchaseRequestInvoiceMasterId_PurchaseRequestInvoiceMaster: 19,
  patternPurchaseRequestInvoiceMasterId_PurchaseRequestInvoiceMaster: "",
  minSizePurReqDocNo_PurchaseRequestInvoiceMaster: 0,
  maxSizePurReqDocNo_PurchaseRequestInvoiceMaster: 20,
  patternPurReqDocNo_PurchaseRequestInvoiceMaster: "",
  minSizePurReqDocDate_PurchaseRequestInvoiceMaster: 0,
  maxSizePurReqDocDate_PurchaseRequestInvoiceMaster: 20,
  patternPurReqDocDate_PurchaseRequestInvoiceMaster: "",
  minSizePurReqDateRequest_PurchaseRequestInvoiceMaster: 0,
  maxSizePurReqDateRequest_PurchaseRequestInvoiceMaster: 20,
  patternPurReqDateRequest_PurchaseRequestInvoiceMaster: "",
  minSizePurReqRemark_PurchaseRequestInvoiceMaster: 0,
  maxSizePurReqRemark_PurchaseRequestInvoiceMaster: 1000,
  patternPurReqRemark_PurchaseRequestInvoiceMaster: "",
  minSizePurReqStatus_PurchaseRequestInvoiceMaster: 0,
  maxSizePurReqStatus_PurchaseRequestInvoiceMaster: 2,
  patternPurReqStatus_PurchaseRequestInvoiceMaster: "",
  minSizePurReqLinkDocNo_PurchaseRequestInvoiceMaster: 0,
  maxSizePurReqLinkDocNo_PurchaseRequestInvoiceMaster: 20,
  patternPurReqLinkDocNo_PurchaseRequestInvoiceMaster: "",
  minSizeCreateOn_PurchaseRequestInvoiceMaster: 0,
  maxSizeCreateOn_PurchaseRequestInvoiceMaster: 20,
  patternCreateOn_PurchaseRequestInvoiceMaster: "",
  minSizeUpdateOn_PurchaseRequestInvoiceMaster: 0,
  maxSizeUpdateOn_PurchaseRequestInvoiceMaster: 20,
  patternUpdateOn_PurchaseRequestInvoiceMaster: "",
  minSizeCompanyCode_PurchaseRequestInvoiceMaster: 0,
  maxSizeCompanyCode_PurchaseRequestInvoiceMaster: 20,
  patternCompanyCode_PurchaseRequestInvoiceMaster: "[0-9a-zA-Z/-]+",
  minSizeAStatus_PurchaseRequestInvoiceMaster: 0,
  maxSizeAStatus_PurchaseRequestInvoiceMaster: 2,
  patternAStatus_PurchaseRequestInvoiceMaster: "",
  minSizePrintStatus_PurchaseRequestInvoiceMaster: 0,
  maxSizePrintStatus_PurchaseRequestInvoiceMaster: 2,
  patternPrintStatus_PurchaseRequestInvoiceMaster: "",
  minSizeReviseNo_PurchaseRequestInvoiceMaster: 0,
  maxSizeReviseNo_PurchaseRequestInvoiceMaster: 10,
  patternReviseNo_PurchaseRequestInvoiceMaster: "",
  minSizeDocumentGroupId_PurchaseRequestInvoiceMaster: 0,
  maxSizeDocumentGroupId_PurchaseRequestInvoiceMaster: 19,
  patternDocumentGroupId_PurchaseRequestInvoiceMaster: "",
  minSizeEmployeeMasterId_PurchaseRequestInvoiceMaster: 0,
  maxSizeEmployeeMasterId_PurchaseRequestInvoiceMaster: 19,
  patternEmployeeMasterId_PurchaseRequestInvoiceMaster: "",
  minSizeDivisionId_PurchaseRequestInvoiceMaster: 0,
  maxSizeDivisionId_PurchaseRequestInvoiceMaster: 19,
  patternDivisionId_PurchaseRequestInvoiceMaster: "",
  minSizeSectionId_PurchaseRequestInvoiceMaster: 0,
  maxSizeSectionId_PurchaseRequestInvoiceMaster: 19,
  patternSectionId_PurchaseRequestInvoiceMaster: "",
  minSizeCreateByEmpId_PurchaseRequestInvoiceMaster: 0,
  maxSizeCreateByEmpId_PurchaseRequestInvoiceMaster: 19,
  patternCreateByEmpId_PurchaseRequestInvoiceMaster: "",
  minSizeUpdateByEmpId_PurchaseRequestInvoiceMaster: 0,
  maxSizeUpdateByEmpId_PurchaseRequestInvoiceMaster: 19,
  patternUpdateByEmpId_PurchaseRequestInvoiceMaster: "",
  minSizePurchaseRequestInvoiceSupplierId_PurchaseRequestInvoiceMaster: 0,
  maxSizePurchaseRequestInvoiceSupplierId_PurchaseRequestInvoiceMaster: 19,
  patternPurchaseRequestInvoiceSupplierId_PurchaseRequestInvoiceMaster: "",
  minSizeDocumentDateClose_PurchaseRequestInvoiceMaster: 0,
  maxSizeDocumentDateClose_PurchaseRequestInvoiceMaster: 20,
  patternDocumentDateClose_PurchaseRequestInvoiceMaster: "",
  minSizeUserCloseEmpId_PurchaseRequestInvoiceMaster: 0,
  maxSizeUserCloseEmpId_PurchaseRequestInvoiceMaster: 19,
  patternUserCloseEmpId_PurchaseRequestInvoiceMaster: "",
  minSizeUserApprove_PurchaseRequestInvoiceMaster: 0,
  maxSizeUserApprove_PurchaseRequestInvoiceMaster: 20,
  patternUserApprove_PurchaseRequestInvoiceMaster: "",
  minSizePrTotal_PurchaseRequestInvoiceMaster: 0,
  maxSizePrTotal_PurchaseRequestInvoiceMaster: 26,
  patternPrTotal_PurchaseRequestInvoiceMaster: "",
  minSizePurchaseDepositId_PurchaseDeposit: 0,
  maxSizePurchaseDepositId_PurchaseDeposit: 19,
  patternPurchaseDepositId_PurchaseDeposit: "",
  minSizePayAmt_PurchaseDeposit: 0,
  maxSizePayAmt_PurchaseDeposit: 24,
  patternPayAmt_PurchaseDeposit: "",
  minSizeCompanyCode_PurchaseDeposit: 0,
  maxSizeCompanyCode_PurchaseDeposit: 20,
  patternCompanyCode_PurchaseDeposit: "[0-9a-zA-Z/-]+",
  minSizeProductReceiveInvoiceMasterId_PurchaseDeposit: 0,
  maxSizeProductReceiveInvoiceMasterId_PurchaseDeposit: 19,
  patternProductReceiveInvoiceMasterId_PurchaseDeposit: "",
  minSizeDepositPaymentInvoiceMasterId_PurchaseDeposit: 0,
  maxSizeDepositPaymentInvoiceMasterId_PurchaseDeposit: 19,
  patternDepositPaymentInvoiceMasterId_PurchaseDeposit: "",
  minSizeRcvServiceMasterId_PurchaseDeposit: 0,
  maxSizeRcvServiceMasterId_PurchaseDeposit: 19,
  patternRcvServiceMasterId_PurchaseDeposit: "",
  minSizeDeliveryOrderReceiveId_DeliveryOrderReceive: 0,
  maxSizeDeliveryOrderReceiveId_DeliveryOrderReceive: 19,
  patternDeliveryOrderReceiveId_DeliveryOrderReceive: "",
  minSizeDoDocNo_DeliveryOrderReceive: 0,
  maxSizeDoDocNo_DeliveryOrderReceive: 20,
  patternDoDocNo_DeliveryOrderReceive: "",
  minSizeCashAmt_DeliveryOrderReceive: 0,
  maxSizeCashAmt_DeliveryOrderReceive: 26,
  patternCashAmt_DeliveryOrderReceive: "",
  minSizeBankRemark_DeliveryOrderReceive: 0,
  maxSizeBankRemark_DeliveryOrderReceive: 150,
  patternBankRemark_DeliveryOrderReceive: "",
  minSizeBankAmt_DeliveryOrderReceive: 0,
  maxSizeBankAmt_DeliveryOrderReceive: 26,
  patternBankAmt_DeliveryOrderReceive: "",
  minSizeCompanyCode_DeliveryOrderReceive: 0,
  maxSizeCompanyCode_DeliveryOrderReceive: 20,
  patternCompanyCode_DeliveryOrderReceive: "[0-9a-zA-Z/-]+",
  minSizeChqNoOne_DeliveryOrderReceive: 0,
  maxSizeChqNoOne_DeliveryOrderReceive: 20,
  patternChqNoOne_DeliveryOrderReceive: "",
  minSizeChqDateOne_DeliveryOrderReceive: 0,
  maxSizeChqDateOne_DeliveryOrderReceive: 20,
  patternChqDateOne_DeliveryOrderReceive: "",
  minSizeChqAmtOne_DeliveryOrderReceive: 0,
  maxSizeChqAmtOne_DeliveryOrderReceive: 26,
  patternChqAmtOne_DeliveryOrderReceive: "",
  minSizeChqNoTwo_DeliveryOrderReceive: 0,
  maxSizeChqNoTwo_DeliveryOrderReceive: 20,
  patternChqNoTwo_DeliveryOrderReceive: "",
  minSizeChqDateTwo_DeliveryOrderReceive: 0,
  maxSizeChqDateTwo_DeliveryOrderReceive: 20,
  patternChqDateTwo_DeliveryOrderReceive: "",
  minSizeChqAmtTwo_DeliveryOrderReceive: 0,
  maxSizeChqAmtTwo_DeliveryOrderReceive: 26,
  patternChqAmtTwo_DeliveryOrderReceive: "",
  minSizeChqRemark_DeliveryOrderReceive: 0,
  maxSizeChqRemark_DeliveryOrderReceive: 150,
  patternChqRemark_DeliveryOrderReceive: "",
  minSizeReceiveChequeNoOne_DeliveryOrderReceive: 0,
  maxSizeReceiveChequeNoOne_DeliveryOrderReceive: 20,
  patternReceiveChequeNoOne_DeliveryOrderReceive: "",
  minSizeReceiveChequeNoTwo_DeliveryOrderReceive: 0,
  maxSizeReceiveChequeNoTwo_DeliveryOrderReceive: 20,
  patternReceiveChequeNoTwo_DeliveryOrderReceive: "",
  minSizeProdQTY_ProductLot: 0,
  maxSizeProdQTY_ProductLot: 26,
  patternProdQTY_ProductLot: "",
  minSizeProdBaseQTY_ProductLot: 0,
  maxSizeProdBaseQTY_ProductLot: 26,
  patternProdBaseQTY_ProductLot: "",
  minSizeProdUseBaseQTY_ProductLot: 0,
  maxSizeProdUseBaseQTY_ProductLot: 26,
  patternProdUseBaseQTY_ProductLot: "",
  minSizeProdConvFactor_ProductLot: 0,
  maxSizeProdConvFactor_ProductLot: 26,
  patternProdConvFactor_ProductLot: "",
  minSizeProdUseBaseQTYLot_ProductLot: 0,
  maxSizeProdUseBaseQTYLot_ProductLot: 26,
  patternProdUseBaseQTYLot_ProductLot: "",
  minSizeProductMaster1S_ProductLot: 0,
  maxSizeProductMaster1S_ProductLot: 19,
  patternProductMaster1S_ProductLot: "",
  minSizeReceiveChequeId_ReceiveCheque: 0,
  maxSizeReceiveChequeId_ReceiveCheque: 19,
  patternReceiveChequeId_ReceiveCheque: "",
  minSizeDocNo_ReceiveCheque: 0,
  maxSizeDocNo_ReceiveCheque: 20,
  patternDocNo_ReceiveCheque: "",
  minSizeDocDate_ReceiveCheque: 0,
  maxSizeDocDate_ReceiveCheque: 20,
  patternDocDate_ReceiveCheque: "",
  minSizeChequeNo_ReceiveCheque: 0,
  maxSizeChequeNo_ReceiveCheque: 20,
  patternChequeNo_ReceiveCheque: "",
  minSizeChequeDate_ReceiveCheque: 0,
  maxSizeChequeDate_ReceiveCheque: 20,
  patternChequeDate_ReceiveCheque: "",
  minSizeChequeAmount_ReceiveCheque: 0,
  maxSizeChequeAmount_ReceiveCheque: 26,
  patternChequeAmount_ReceiveCheque: "",
  minSizeCharge_ReceiveCheque: 0,
  maxSizeCharge_ReceiveCheque: 26,
  patternCharge_ReceiveCheque: "",
  minSizeChequeStatus_ReceiveCheque: 0,
  maxSizeChequeStatus_ReceiveCheque: 2,
  patternChequeStatus_ReceiveCheque: "",
  minSizeChequeBringFoward_ReceiveCheque: 0,
  maxSizeChequeBringFoward_ReceiveCheque: 2,
  patternChequeBringFoward_ReceiveCheque: "",
  minSizeChequeRemark_ReceiveCheque: 0,
  maxSizeChequeRemark_ReceiveCheque: 150,
  patternChequeRemark_ReceiveCheque: "",
  minSizeChequeReceiveVoucherNo_ReceiveCheque: 0,
  maxSizeChequeReceiveVoucherNo_ReceiveCheque: 255,
  patternChequeReceiveVoucherNo_ReceiveCheque: "",
  minSizeChequeRegisterNumber_ReceiveCheque: 0,
  maxSizeChequeRegisterNumber_ReceiveCheque: 20,
  patternChequeRegisterNumber_ReceiveCheque: "",
  minSizeChequeRegisterDate_ReceiveCheque: 0,
  maxSizeChequeRegisterDate_ReceiveCheque: 20,
  patternChequeRegisterDate_ReceiveCheque: "",
  minSizeCreateOn_ReceiveCheque: 0,
  maxSizeCreateOn_ReceiveCheque: 20,
  patternCreateOn_ReceiveCheque: "",
  minSizeUpdateOn_ReceiveCheque: 0,
  maxSizeUpdateOn_ReceiveCheque: 20,
  patternUpdateOn_ReceiveCheque: "",
  minSizeCompanyCode_ReceiveCheque: 0,
  maxSizeCompanyCode_ReceiveCheque: 20,
  patternCompanyCode_ReceiveCheque: "[0-9a-zA-Z/-]+",
  minSizeChequeAuto_ReceiveCheque: 0,
  maxSizeChequeAuto_ReceiveCheque: 20,
  patternChequeAuto_ReceiveCheque: "",
  minSizeChequeReconcileDate_ReceiveCheque: 0,
  maxSizeChequeReconcileDate_ReceiveCheque: 20,
  patternChequeReconcileDate_ReceiveCheque: "",
  minSizeDivisionBank_ReceiveCheque: 0,
  maxSizeDivisionBank_ReceiveCheque: 100,
  patternDivisionBank_ReceiveCheque: "",
  minSizeCustBank_ReceiveCheque: 0,
  maxSizeCustBank_ReceiveCheque: 100,
  patternCustBank_ReceiveCheque: "",
  minSizeBillTName_ReceiveCheque: 0,
  maxSizeBillTName_ReceiveCheque: 9999,
  patternBillTName_ReceiveCheque: "",
  minSizeDepositBookMasterId_ReceiveCheque: 0,
  maxSizeDepositBookMasterId_ReceiveCheque: 19,
  patternDepositBookMasterId_ReceiveCheque: "",
  minSizeDocumentGroupId_ReceiveCheque: 0,
  maxSizeDocumentGroupId_ReceiveCheque: 19,
  patternDocumentGroupId_ReceiveCheque: "",
  minSizeDivisionId_ReceiveCheque: 0,
  maxSizeDivisionId_ReceiveCheque: 19,
  patternDivisionId_ReceiveCheque: "",
  minSizeSectionId_ReceiveCheque: 0,
  maxSizeSectionId_ReceiveCheque: 19,
  patternSectionId_ReceiveCheque: "",
  minSizeCustomerMasterId_ReceiveCheque: 0,
  maxSizeCustomerMasterId_ReceiveCheque: 19,
  patternCustomerMasterId_ReceiveCheque: "",
  minSizeCreateByEmpId_ReceiveCheque: 0,
  maxSizeCreateByEmpId_ReceiveCheque: 19,
  patternCreateByEmpId_ReceiveCheque: "",
  minSizeUpdateByEmpId_ReceiveCheque: 0,
  maxSizeUpdateByEmpId_ReceiveCheque: 19,
  patternUpdateByEmpId_ReceiveCheque: "",
  minSizeOtherReceiveDetailId_OtherReceiveDetail: 0,
  maxSizeOtherReceiveDetailId_OtherReceiveDetail: 19,
  patternOtherReceiveDetailId_OtherReceiveDetail: "",
  minSizeOtherReceiveDetailNo_OtherReceiveDetail: 0,
  maxSizeOtherReceiveDetailNo_OtherReceiveDetail: 10,
  patternOtherReceiveDetailNo_OtherReceiveDetail: "",
  minSizeOtherReceiveInvoiceNo_OtherReceiveDetail: 0,
  maxSizeOtherReceiveInvoiceNo_OtherReceiveDetail: 20,
  patternOtherReceiveInvoiceNo_OtherReceiveDetail: "",
  minSizeOtherReceiveInvoiceDate_OtherReceiveDetail: 0,
  maxSizeOtherReceiveInvoiceDate_OtherReceiveDetail: 20,
  patternOtherReceiveInvoiceDate_OtherReceiveDetail: "",
  minSizeOtherReceiveInvoiceTotal_OtherReceiveDetail: 0,
  maxSizeOtherReceiveInvoiceTotal_OtherReceiveDetail: 26,
  patternOtherReceiveInvoiceTotal_OtherReceiveDetail: "",
  minSizeOtherReceiveInvoiceVat_OtherReceiveDetail: 0,
  maxSizeOtherReceiveInvoiceVat_OtherReceiveDetail: 26,
  patternOtherReceiveInvoiceVat_OtherReceiveDetail: "",
  minSizeCompanyCode_OtherReceiveDetail: 0,
  maxSizeCompanyCode_OtherReceiveDetail: 20,
  patternCompanyCode_OtherReceiveDetail: "[0-9a-zA-Z/-]+",
  minSizeAccountCodeId_OtherReceiveDetail: 0,
  maxSizeAccountCodeId_OtherReceiveDetail: 19,
  patternAccountCodeId_OtherReceiveDetail: "[0-9a-zA-Z/-]+",
  minSizeOtherReceiveMasterId_OtherReceiveDetail: 0,
  maxSizeOtherReceiveMasterId_OtherReceiveDetail: 19,
  patternOtherReceiveMasterId_OtherReceiveDetail: "",
  minSizeCheckVat_OtherReceiveDetail: 0,
  maxSizeCheckVat_OtherReceiveDetail: 2,
  patternCheckVat_OtherReceiveDetail: "",
  minSizeAccName_OtherReceiveDetail: 0,
  maxSizeAccName_OtherReceiveDetail: 255,
  patternAccName_OtherReceiveDetail: "",
  minSizeAmt_OtherReceiveDetail: 0,
  maxSizeAmt_OtherReceiveDetail: 26,
  patternAmt_OtherReceiveDetail: "",
  minSizeStartDate_OtherReceiveDetail: 0,
  maxSizeStartDate_OtherReceiveDetail: 20,
  patternStartDate_OtherReceiveDetail: "",
  minSizeEndDate_OtherReceiveDetail: 0,
  maxSizeEndDate_OtherReceiveDetail: 20,
  patternEndDate_OtherReceiveDetail: "",
  minSizeDays_OtherReceiveDetail: 0,
  maxSizeDays_OtherReceiveDetail: 10,
  patternDays_OtherReceiveDetail: "",
  minSizeCalcAmt_OtherReceiveDetail: 0,
  maxSizeCalcAmt_OtherReceiveDetail: 26,
  patternCalcAmt_OtherReceiveDetail: "",
  minSizeInterest_OtherReceiveDetail: 0,
  maxSizeInterest_OtherReceiveDetail: 26,
  patternInterest_OtherReceiveDetail: "",
  minSizeAStatus_VoucherMaster: 0,
  maxSizeAStatus_VoucherMaster: 2,
  patternAStatus_VoucherMaster: "",
  minSizeCreditNoteMasterId_CreditNoteMasterJobMaster: 0,
  maxSizeCreditNoteMasterId_CreditNoteMasterJobMaster: 19,
  patternCreditNoteMasterId_CreditNoteMasterJobMaster: "",
  minSizeJobMasterId_CreditNoteMasterJobMaster: 0,
  maxSizeJobMasterId_CreditNoteMasterJobMaster: 19,
  patternJobMasterId_CreditNoteMasterJobMaster: "",
  minSizeBillingNoteDetailId_BillingNoteDetail: 0,
  maxSizeBillingNoteDetailId_BillingNoteDetail: 19,
  patternBillingNoteDetailId_BillingNoteDetail: "",
  minSizeBillingNoteDetailNo_BillingNoteDetail: 0,
  maxSizeBillingNoteDetailNo_BillingNoteDetail: 10,
  patternBillingNoteDetailNo_BillingNoteDetail: "",
  minSizeDoDocAmt_BillingNoteDetail: 0,
  maxSizeDoDocAmt_BillingNoteDetail: 26,
  patternDoDocAmt_BillingNoteDetail: "",
  minSizeDoDocRemain_BillingNoteDetail: 0,
  maxSizeDoDocRemain_BillingNoteDetail: 26,
  patternDoDocRemain_BillingNoteDetail: "",
  minSizeBillingNoteAmt_BillingNoteDetail: 0,
  maxSizeBillingNoteAmt_BillingNoteDetail: 26,
  patternBillingNoteAmt_BillingNoteDetail: "",
  minSizeReceiptAmt_BillingNoteDetail: 0,
  maxSizeReceiptAmt_BillingNoteDetail: 26,
  patternReceiptAmt_BillingNoteDetail: "",
  minSizeCompanyCode_BillingNoteDetail: 0,
  maxSizeCompanyCode_BillingNoteDetail: 20,
  patternCompanyCode_BillingNoteDetail: "[0-9a-zA-Z/-]+",
  minSizeRefType_BillingNoteDetail: 0,
  maxSizeRefType_BillingNoteDetail: 3,
  patternRefType_BillingNoteDetail: "",
  minSizeDoDueDate_BillingNoteDetail: 0,
  maxSizeDoDueDate_BillingNoteDetail: 20,
  patternDoDueDate_BillingNoteDetail: "",
  minSizeDeliveryOrderMasterId_BillingNoteDetail: 0,
  maxSizeDeliveryOrderMasterId_BillingNoteDetail: 19,
  patternDeliveryOrderMasterId_BillingNoteDetail: "",
  minSizeCreditNoteMasterId_BillingNoteDetail: 0,
  maxSizeCreditNoteMasterId_BillingNoteDetail: 19,
  patternCreditNoteMasterId_BillingNoteDetail: "",
  minSizeBillingNoteMasterId_BillingNoteDetail: 0,
  maxSizeBillingNoteMasterId_BillingNoteDetail: 19,
  patternBillingNoteMasterId_BillingNoteDetail: "",
  minSizeInvoiceMasterId_BillingNoteDetail: 0,
  maxSizeInvoiceMasterId_BillingNoteDetail: 19,
  patternInvoiceMasterId_BillingNoteDetail: "",
  minSizeRctinvserviceMasterId_BillingNoteDetail: 0,
  maxSizeRctinvserviceMasterId_BillingNoteDetail: 19,
  patternRctinvserviceMasterId_BillingNoteDetail: "",
  minSizeOtherReceiveMasterId_BillingNoteDetail: 0,
  maxSizeOtherReceiveMasterId_BillingNoteDetail: 19,
  patternOtherReceiveMasterId_BillingNoteDetail: "",
  minSizeDepositReceiveInvoiceMasterId_BillingNoteDetail: 0,
  maxSizeDepositReceiveInvoiceMasterId_BillingNoteDetail: 19,
  patternDepositReceiveInvoiceMasterId_BillingNoteDetail: "",
  minSizeDocumentNo_BillingNoteDetail: 0,
  maxSizeDocumentNo_BillingNoteDetail: 20,
  patternDocumentNo_BillingNoteDetail: "",
  minSizeServiceBillingNoteMasterId_BillingNoteDetail: 0,
  maxSizeServiceBillingNoteMasterId_BillingNoteDetail: 19,
  patternServiceBillingNoteMasterId_BillingNoteDetail: "",
  minSizeCreditNoteDetailId_CreditNoteDetail: 0,
  maxSizeCreditNoteDetailId_CreditNoteDetail: 19,
  patternCreditNoteDetailId_CreditNoteDetail: "",
  minSizeCreditNoteDetailNo_CreditNoteDetail: 0,
  maxSizeCreditNoteDetailNo_CreditNoteDetail: 10,
  patternCreditNoteDetailNo_CreditNoteDetail: "",
  minSizeProdQTY_CreditNoteDetail: 0,
  maxSizeProdQTY_CreditNoteDetail: 26,
  patternProdQTY_CreditNoteDetail: "",
  minSizeProdConvFactor_CreditNoteDetail: 0,
  maxSizeProdConvFactor_CreditNoteDetail: 26,
  patternProdConvFactor_CreditNoteDetail: "",
  minSizeProdBaseQty_CreditNoteDetail: 0,
  maxSizeProdBaseQty_CreditNoteDetail: 26,
  patternProdBaseQty_CreditNoteDetail: "",
  minSizeProdPrice_CreditNoteDetail: 0,
  maxSizeProdPrice_CreditNoteDetail: 26,
  patternProdPrice_CreditNoteDetail: "",
  minSizeProdNetPrice_CreditNoteDetail: 0,
  maxSizeProdNetPrice_CreditNoteDetail: 26,
  patternProdNetPrice_CreditNoteDetail: "",
  minSizeDocTotal_CreditNoteDetail: 0,
  maxSizeDocTotal_CreditNoteDetail: 26,
  patternDocTotal_CreditNoteDetail: "",
  minSizeDocDiscAmt_CreditNoteDetail: 0,
  maxSizeDocDiscAmt_CreditNoteDetail: 26,
  patternDocDiscAmt_CreditNoteDetail: "",
  minSizeIsReturn_CreditNoteDetail: 0,
  maxSizeIsReturn_CreditNoteDetail: 2,
  patternIsReturn_CreditNoteDetail: "",
  minSizeDocType_CreditNoteDetail: 0,
  maxSizeDocType_CreditNoteDetail: 3,
  patternDocType_CreditNoteDetail: "",
  minSizeSerialNoText_CreditNoteDetail: 0,
  maxSizeSerialNoText_CreditNoteDetail: 9999,
  patternSerialNoText_CreditNoteDetail: "",
  minSizeProdDescription_CreditNoteDetail: 0,
  maxSizeProdDescription_CreditNoteDetail: 300,
  patternProdDescription_CreditNoteDetail: "",
  minSizeCompanyCode_CreditNoteDetail: 0,
  maxSizeCompanyCode_CreditNoteDetail: 20,
  patternCompanyCode_CreditNoteDetail: "[0-9a-zA-Z/-]+",
  minSizeSerialNo_CreditNoteDetail: 0,
  maxSizeSerialNo_CreditNoteDetail: 255,
  patternSerialNo_CreditNoteDetail: "",
  minSizeProdLotNo_CreditNoteDetail: 0,
  maxSizeProdLotNo_CreditNoteDetail: 30,
  patternProdLotNo_CreditNoteDetail: "",
  minSizeProductReceiveDocNo_CreditNoteDetail: 0,
  maxSizeProductReceiveDocNo_CreditNoteDetail: 20,
  patternProductReceiveDocNo_CreditNoteDetail: "",
  minSizeProductReceiveDocIndex_CreditNoteDetail: 0,
  maxSizeProductReceiveDocIndex_CreditNoteDetail: 10,
  patternProductReceiveDocIndex_CreditNoteDetail: "",
  minSizeSerialQty_CreditNoteDetail: 0,
  maxSizeSerialQty_CreditNoteDetail: 26,
  patternSerialQty_CreditNoteDetail: "",
  minSizeProductMaster1Id_CreditNoteDetail: 0,
  maxSizeProductMaster1Id_CreditNoteDetail: 19,
  patternProductMaster1Id_CreditNoteDetail: "",
  minSizeWarehouseId_CreditNoteDetail: 0,
  maxSizeWarehouseId_CreditNoteDetail: 19,
  patternWarehouseId_CreditNoteDetail: "",
  minSizeUnitOfMeasureId1_CreditNoteDetail: 0,
  maxSizeUnitOfMeasureId1_CreditNoteDetail: 19,
  patternUnitOfMeasureId1_CreditNoteDetail: "",
  minSizeUnitOfMeasureId2_CreditNoteDetail: 0,
  maxSizeUnitOfMeasureId2_CreditNoteDetail: 19,
  patternUnitOfMeasureId2_CreditNoteDetail: "",
  minSizeCreditNoteMasterId_CreditNoteDetail: 0,
  maxSizeCreditNoteMasterId_CreditNoteDetail: 19,
  patternCreditNoteMasterId_CreditNoteDetail: "",
  minSizeServiceMasterId_CreditNoteDetail: 0,
  maxSizeServiceMasterId_CreditNoteDetail: 19,
  patternServiceMasterId_CreditNoteDetail: "",
  minSizeReceiveProductPriceFromRv_CreditNoteDetail: 0,
  maxSizeReceiveProductPriceFromRv_CreditNoteDetail: 26,
  patternReceiveProductPriceFromRv_CreditNoteDetail: "",
  minSizeCost_CreditNoteDetail: 0,
  maxSizeCost_CreditNoteDetail: 26,
  patternCost_CreditNoteDetail: "",
  minSizeLotNo_CreditNoteDetail: 0,
  maxSizeLotNo_CreditNoteDetail: 50,
  patternLotNo_CreditNoteDetail: "",
  minSizeExpireDate_CreditNoteDetail: 0,
  maxSizeExpireDate_CreditNoteDetail: 20,
  patternExpireDate_CreditNoteDetail: "",
  minSizeLotMasterId_CreditNoteDetail: 0,
  maxSizeLotMasterId_CreditNoteDetail: 19,
  patternLotMasterId_CreditNoteDetail: "",
  minSizeUnitCount_CreditNoteDetail: 0,
  maxSizeUnitCount_CreditNoteDetail: 150,
  patternUnitCount_CreditNoteDetail: "",
  minSizeVat_CreditNoteDetail: 0,
  maxSizeVat_CreditNoteDetail: 2,
  patternVat_CreditNoteDetail: "",
  minSizeCustomerVat_CustomerMaster: 0,
  maxSizeCustomerVat_CustomerMaster: 2,
  patternCustomerVat_CustomerMaster: "",
  minSizeAccTypeCompanyType_AccountType: 0,
  maxSizeAccTypeCompanyType_AccountType: 200,
  patternAccTypeCompanyType_AccountType: "",
  minSizeReceiptDetailId_ReceiptDetail: 0,
  maxSizeReceiptDetailId_ReceiptDetail: 19,
  patternReceiptDetailId_ReceiptDetail: "",
  minSizeReceiptDetailNo_ReceiptDetail: 0,
  maxSizeReceiptDetailNo_ReceiptDetail: 10,
  patternReceiptDetailNo_ReceiptDetail: "",
  minSizeBillingNoteDocNo_ReceiptDetail: 0,
  maxSizeBillingNoteDocNo_ReceiptDetail: 20,
  patternBillingNoteDocNo_ReceiptDetail: "",
  minSizeDocAmt_ReceiptDetail: 0,
  maxSizeDocAmt_ReceiptDetail: 26,
  patternDocAmt_ReceiptDetail: "",
  minSizeDocRemain_ReceiptDetail: 0,
  maxSizeDocRemain_ReceiptDetail: 26,
  patternDocRemain_ReceiptDetail: "",
  minSizeReceiptAmt_ReceiptDetail: 0,
  maxSizeReceiptAmt_ReceiptDetail: 26,
  patternReceiptAmt_ReceiptDetail: "",
  minSizeCompanyCode_ReceiptDetail: 0,
  maxSizeCompanyCode_ReceiptDetail: 20,
  patternCompanyCode_ReceiptDetail: "[0-9a-zA-Z/-]+",
  minSizeDeliveryOrderMasterId_ReceiptDetail: 0,
  maxSizeDeliveryOrderMasterId_ReceiptDetail: 19,
  patternDeliveryOrderMasterId_ReceiptDetail: "",
  minSizeReceiptMasterId_ReceiptDetail: 0,
  maxSizeReceiptMasterId_ReceiptDetail: 19,
  patternReceiptMasterId_ReceiptDetail: "",
  minSizeCreditNoteMasterId_ReceiptDetail: 0,
  maxSizeCreditNoteMasterId_ReceiptDetail: 19,
  patternCreditNoteMasterId_ReceiptDetail: "",
  minSizeInvoiceMasterId_ReceiptDetail: 0,
  maxSizeInvoiceMasterId_ReceiptDetail: 19,
  patternInvoiceMasterId_ReceiptDetail: "",
  minSizeRctInvServiceMasterid_ReceiptDetail: 0,
  maxSizeRctInvServiceMasterid_ReceiptDetail: 19,
  patternRctInvServiceMasterid_ReceiptDetail: "",
  minSizeOtherReceiveMasterId_ReceiptDetail: 0,
  maxSizeOtherReceiveMasterId_ReceiptDetail: 19,
  patternOtherReceiveMasterId_ReceiptDetail: "",
  minSizeDetailReceive_ReceiptDetail: 0,
  maxSizeDetailReceive_ReceiptDetail: 26,
  patternDetailReceive_ReceiptDetail: "",
  minSizeDepositReceiveInvoiceMasterId_ReceiptDetail: 0,
  maxSizeDepositReceiveInvoiceMasterId_ReceiptDetail: 19,
  patternDepositReceiveInvoiceMasterId_ReceiptDetail: "",
  minSizeBillingNoteDetailId_ReceiptDetail: 0,
  maxSizeBillingNoteDetailId_ReceiptDetail: 19,
  patternBillingNoteDetailId_ReceiptDetail: "",
  minSizeServiceBillingNoteMasterId_ReceiptDetail: 0,
  maxSizeServiceBillingNoteMasterId_ReceiptDetail: 19,
  patternServiceBillingNoteMasterId_ReceiptDetail: "",
  minSizeReceiptMasterId_ReceiptMaster: 0,
  maxSizeReceiptMasterId_ReceiptMaster: 19,
  patternReceiptMasterId_ReceiptMaster: "",
  minSizeReceiptDocNo_ReceiptMaster: 0,
  maxSizeReceiptDocNo_ReceiptMaster: 20,
  patternReceiptDocNo_ReceiptMaster: "",
  minSizeReceiptDocDate_ReceiptMaster: 0,
  maxSizeReceiptDocDate_ReceiptMaster: 20,
  patternReceiptDocDate_ReceiptMaster: "",
  minSizeReceiptReferDoc_ReceiptMaster: 0,
  maxSizeReceiptReferDoc_ReceiptMaster: 20,
  patternReceiptReferDoc_ReceiptMaster: "",
  minSizeReceiptRemark_ReceiptMaster: 0,
  maxSizeReceiptRemark_ReceiptMaster: 500,
  patternReceiptRemark_ReceiptMaster: "",
  minSizeReceiptStatus_ReceiptMaster: 0,
  maxSizeReceiptStatus_ReceiptMaster: 2,
  patternReceiptStatus_ReceiptMaster: "",
  minSizeReceiptLinkDoc_ReceiptMaster: 0,
  maxSizeReceiptLinkDoc_ReceiptMaster: 20,
  patternReceiptLinkDoc_ReceiptMaster: "",
  minSizeReceiptToTal_ReceiptMaster: 0,
  maxSizeReceiptToTal_ReceiptMaster: 26,
  patternReceiptToTal_ReceiptMaster: "",
  minSizeCreateOn_ReceiptMaster: 0,
  maxSizeCreateOn_ReceiptMaster: 20,
  patternCreateOn_ReceiptMaster: "",
  minSizeUpdateOn_ReceiptMaster: 0,
  maxSizeUpdateOn_ReceiptMaster: 20,
  patternUpdateOn_ReceiptMaster: "",
  minSizeReceiptCreditNoteDocNo_ReceiptMaster: 0,
  maxSizeReceiptCreditNoteDocNo_ReceiptMaster: 20,
  patternReceiptCreditNoteDocNo_ReceiptMaster: "",
  minSizeReceiptCreditNoteDocDate_ReceiptMaster: 0,
  maxSizeReceiptCreditNoteDocDate_ReceiptMaster: 20,
  patternReceiptCreditNoteDocDate_ReceiptMaster: "",
  minSizeReceiptCreditNoteDocAmt_ReceiptMaster: 0,
  maxSizeReceiptCreditNoteDocAmt_ReceiptMaster: 26,
  patternReceiptCreditNoteDocAmt_ReceiptMaster: "",
  minSizeReceiptNetAmt_ReceiptMaster: 0,
  maxSizeReceiptNetAmt_ReceiptMaster: 26,
  patternReceiptNetAmt_ReceiptMaster: "",
  minSizeReceiveAmt_ReceiptMaster: 0,
  maxSizeReceiveAmt_ReceiptMaster: 26,
  patternReceiveAmt_ReceiptMaster: "",
  minSizeReceiptBeforeChangeStatus_ReceiptMaster: 0,
  maxSizeReceiptBeforeChangeStatus_ReceiptMaster: 2,
  patternReceiptBeforeChangeStatus_ReceiptMaster: "",
  minSizeCompanyCode_ReceiptMaster: 0,
  maxSizeCompanyCode_ReceiptMaster: 20,
  patternCompanyCode_ReceiptMaster: "[0-9a-zA-Z/-]+",
  minSizeExchangeRate_ReceiptMaster: 0,
  maxSizeExchangeRate_ReceiptMaster: 26,
  patternExchangeRate_ReceiptMaster: "",
  minSizeExchageRateDate_ReceiptMaster: 0,
  maxSizeExchageRateDate_ReceiptMaster: 20,
  patternExchageRateDate_ReceiptMaster: "",
  minSizeAStatus_ReceiptMaster: 0,
  maxSizeAStatus_ReceiptMaster: 2,
  patternAStatus_ReceiptMaster: "",
  minSizeReviseNo_ReceiptMaster: 0,
  maxSizeReviseNo_ReceiptMaster: 10,
  patternReviseNo_ReceiptMaster: "",
  minSizeDivisionNo_ReceiptMaster: 0,
  maxSizeDivisionNo_ReceiptMaster: 10,
  patternDivisionNo_ReceiptMaster: "",
  minSizeDivisionName_ReceiptMaster: 0,
  maxSizeDivisionName_ReceiptMaster: 50,
  patternDivisionName_ReceiptMaster: "",
  minSizeDivisionAddress_ReceiptMaster: 0,
  maxSizeDivisionAddress_ReceiptMaster: 255,
  patternDivisionAddress_ReceiptMaster: "",
  minSizeTaxIdNo_ReceiptMaster: 0,
  maxSizeTaxIdNo_ReceiptMaster: 50,
  patternTaxIdNo_ReceiptMaster: "",
  minSizeCurrencyId_ReceiptMaster: 0,
  maxSizeCurrencyId_ReceiptMaster: 19,
  patternCurrencyId_ReceiptMaster: "",
  minSizeDocumentGroupId_ReceiptMaster: 0,
  maxSizeDocumentGroupId_ReceiptMaster: 19,
  patternDocumentGroupId_ReceiptMaster: "",
  minSizeCustomerMasterId_ReceiptMaster: 0,
  maxSizeCustomerMasterId_ReceiptMaster: 19,
  patternCustomerMasterId_ReceiptMaster: "",
  minSizeEmployeeMasterId_ReceiptMaster: 0,
  maxSizeEmployeeMasterId_ReceiptMaster: 19,
  patternEmployeeMasterId_ReceiptMaster: "",
  minSizeDivisionId_ReceiptMaster: 0,
  maxSizeDivisionId_ReceiptMaster: 19,
  patternDivisionId_ReceiptMaster: "",
  minSizeSectionId_ReceiptMaster: 0,
  maxSizeSectionId_ReceiptMaster: 19,
  patternSectionId_ReceiptMaster: "",
  minSizeCreateByEmpId_ReceiptMaster: 0,
  maxSizeCreateByEmpId_ReceiptMaster: 19,
  patternCreateByEmpId_ReceiptMaster: "",
  minSizeUpdateByEmpId_ReceiptMaster: 0,
  maxSizeUpdateByEmpId_ReceiptMaster: 19,
  patternUpdateByEmpId_ReceiptMaster: "",
  minSizePrintStatus_ReceiptMaster: 0,
  maxSizePrintStatus_ReceiptMaster: 2,
  patternPrintStatus_ReceiptMaster: "",
  minSizeRcDiscAmt_ReceiptMaster: 0,
  maxSizeRcDiscAmt_ReceiptMaster: 26,
  patternRcDiscAmt_ReceiptMaster: "",
  minSizeRcNetAmt_ReceiptMaster: 0,
  maxSizeRcNetAmt_ReceiptMaster: 26,
  patternRcNetAmt_ReceiptMaster: "",
  minSizeDivisionCustDetailId_ReceiptMaster: 0,
  maxSizeDivisionCustDetailId_ReceiptMaster: 19,
  patternDivisionCustDetailId_ReceiptMaster: "",
  minSizeOtherReceiveMasterId_OtherReceiveMaster: 0,
  maxSizeOtherReceiveMasterId_OtherReceiveMaster: 19,
  patternOtherReceiveMasterId_OtherReceiveMaster: "",
  minSizeOtherRcvDocno_OtherReceiveMaster: 0,
  maxSizeOtherRcvDocno_OtherReceiveMaster: 20,
  patternOtherRcvDocno_OtherReceiveMaster: "",
  minSizeOtherRcvDocDate_OtherReceiveMaster: 0,
  maxSizeOtherRcvDocDate_OtherReceiveMaster: 20,
  patternOtherRcvDocDate_OtherReceiveMaster: "",
  minSizeOtherRcvIsVatSaleReport_OtherReceiveMaster: 0,
  maxSizeOtherRcvIsVatSaleReport_OtherReceiveMaster: 2,
  patternOtherRcvIsVatSaleReport_OtherReceiveMaster: "",
  minSizeOtherRcvNameInVat_OtherReceiveMaster: 0,
  maxSizeOtherRcvNameInVat_OtherReceiveMaster: 255,
  patternOtherRcvNameInVat_OtherReceiveMaster: "",
  minSizeOtherRcvVatReportDate_OtherReceiveMaster: 0,
  maxSizeOtherRcvVatReportDate_OtherReceiveMaster: 20,
  patternOtherRcvVatReportDate_OtherReceiveMaster: "",
  minSizeOtherRcvTotal_OtherReceiveMaster: 0,
  maxSizeOtherRcvTotal_OtherReceiveMaster: 26,
  patternOtherRcvTotal_OtherReceiveMaster: "",
  minSizeOtherRcvWitholdingTax_OtherReceiveMaster: 0,
  maxSizeOtherRcvWitholdingTax_OtherReceiveMaster: 26,
  patternOtherRcvWitholdingTax_OtherReceiveMaster: "",
  minSizeOtherRcvPlusDiscount_OtherReceiveMaster: 0,
  maxSizeOtherRcvPlusDiscount_OtherReceiveMaster: 26,
  patternOtherRcvPlusDiscount_OtherReceiveMaster: "",
  minSizeOtherRcvDeductDiscount_OtherReceiveMaster: 0,
  maxSizeOtherRcvDeductDiscount_OtherReceiveMaster: 26,
  patternOtherRcvDeductDiscount_OtherReceiveMaster: "",
  minSizeOtherRcvNetAmt_OtherReceiveMaster: 0,
  maxSizeOtherRcvNetAmt_OtherReceiveMaster: 26,
  patternOtherRcvNetAmt_OtherReceiveMaster: "",
  minSizeCharge_OtherReceiveMaster: 0,
  maxSizeCharge_OtherReceiveMaster: 26,
  patternCharge_OtherReceiveMaster: "",
  minSizeOtherRcvRemark_OtherReceiveMaster: 0,
  maxSizeOtherRcvRemark_OtherReceiveMaster: 150,
  patternOtherRcvRemark_OtherReceiveMaster: "",
  minSizeOtherRcvStatus_OtherReceiveMaster: 0,
  maxSizeOtherRcvStatus_OtherReceiveMaster: 2,
  patternOtherRcvStatus_OtherReceiveMaster: "",
  minSizeOtherRcvLinkDoc_OtherReceiveMaster: 0,
  maxSizeOtherRcvLinkDoc_OtherReceiveMaster: 20,
  patternOtherRcvLinkDoc_OtherReceiveMaster: "",
  minSizeOtherRcvReceiveType_OtherReceiveMaster: 0,
  maxSizeOtherRcvReceiveType_OtherReceiveMaster: 2,
  patternOtherRcvReceiveType_OtherReceiveMaster: "",
  minSizeOtherRcvReceiveAmt_OtherReceiveMaster: 0,
  maxSizeOtherRcvReceiveAmt_OtherReceiveMaster: 26,
  patternOtherRcvReceiveAmt_OtherReceiveMaster: "",
  minSizeOtherRcvBankRemark_OtherReceiveMaster: 0,
  maxSizeOtherRcvBankRemark_OtherReceiveMaster: 250,
  patternOtherRcvBankRemark_OtherReceiveMaster: "",
  minSizeOtherRcvChqno_OtherReceiveMaster: 0,
  maxSizeOtherRcvChqno_OtherReceiveMaster: 20,
  patternOtherRcvChqno_OtherReceiveMaster: "",
  minSizeOtherRcvChqDate_OtherReceiveMaster: 0,
  maxSizeOtherRcvChqDate_OtherReceiveMaster: 20,
  patternOtherRcvChqDate_OtherReceiveMaster: "",
  minSizeVoucherNo_OtherReceiveMaster: 0,
  maxSizeVoucherNo_OtherReceiveMaster: 20,
  patternVoucherNo_OtherReceiveMaster: "",
  minSizeCreateOn_OtherReceiveMaster: 0,
  maxSizeCreateOn_OtherReceiveMaster: 20,
  patternCreateOn_OtherReceiveMaster: "",
  minSizeUpdateOn_OtherReceiveMaster: 0,
  maxSizeUpdateOn_OtherReceiveMaster: 20,
  patternUpdateOn_OtherReceiveMaster: "",
  minSizeCompanyCode_OtherReceiveMaster: 0,
  maxSizeCompanyCode_OtherReceiveMaster: 20,
  patternCompanyCode_OtherReceiveMaster: "[0-9a-zA-Z/-]+",
  minSizeAStatus_OtherReceiveMaster: 0,
  maxSizeAStatus_OtherReceiveMaster: 2,
  patternAStatus_OtherReceiveMaster: "",
  minSizeReceiveChequeDocNo_OtherReceiveMaster: 0,
  maxSizeReceiveChequeDocNo_OtherReceiveMaster: 20,
  patternReceiveChequeDocNo_OtherReceiveMaster: "",
  minSizeDivisionBank_OtherReceiveMaster: 0,
  maxSizeDivisionBank_OtherReceiveMaster: 100,
  patternDivisionBank_OtherReceiveMaster: "",
  minSizeCustBank_OtherReceiveMaster: 0,
  maxSizeCustBank_OtherReceiveMaster: 100,
  patternCustBank_OtherReceiveMaster: "",
  minSizeDivisionNo_OtherReceiveMaster: 0,
  maxSizeDivisionNo_OtherReceiveMaster: 10,
  patternDivisionNo_OtherReceiveMaster: "",
  minSizeDivisionName_OtherReceiveMaster: 0,
  maxSizeDivisionName_OtherReceiveMaster: 50,
  patternDivisionName_OtherReceiveMaster: "",
  minSizeDivisionAddress_OtherReceiveMaster: 0,
  maxSizeDivisionAddress_OtherReceiveMaster: 255,
  patternDivisionAddress_OtherReceiveMaster: "",
  minSizeTaxIdNo_OtherReceiveMaster: 0,
  maxSizeTaxIdNo_OtherReceiveMaster: 50,
  patternTaxIdNo_OtherReceiveMaster: "",
  minSizeDocumentGroupId_OtherReceiveMaster: 0,
  maxSizeDocumentGroupId_OtherReceiveMaster: 19,
  patternDocumentGroupId_OtherReceiveMaster: "",
  minSizeEmpMasterId_OtherReceiveMaster: 0,
  maxSizeEmpMasterId_OtherReceiveMaster: 19,
  patternEmpMasterId_OtherReceiveMaster: "",
  minSizeDivisionId_OtherReceiveMaster: 0,
  maxSizeDivisionId_OtherReceiveMaster: 19,
  patternDivisionId_OtherReceiveMaster: "",
  minSizeSectionId_OtherReceiveMaster: 0,
  maxSizeSectionId_OtherReceiveMaster: 19,
  patternSectionId_OtherReceiveMaster: "",
  minSizeCreateByEmpId_OtherReceiveMaster: 0,
  maxSizeCreateByEmpId_OtherReceiveMaster: 19,
  patternCreateByEmpId_OtherReceiveMaster: "",
  minSizeUpdateByEmpId_OtherReceiveMaster: 0,
  maxSizeUpdateByEmpId_OtherReceiveMaster: 19,
  patternUpdateByEmpId_OtherReceiveMaster: "",
  minSizeCustomerMasterId_OtherReceiveMaster: 0,
  maxSizeCustomerMasterId_OtherReceiveMaster: 19,
  patternCustomerMasterId_OtherReceiveMaster: "",
  minSizeOtherRcvType_OtherReceiveMaster: 0,
  maxSizeOtherRcvType_OtherReceiveMaster: 5,
  patternOtherRcvType_OtherReceiveMaster: "",
  minSizePrintStatus_OtherReceiveMaster: 0,
  maxSizePrintStatus_OtherReceiveMaster: 2,
  patternPrintStatus_OtherReceiveMaster: "",
  minSizeExchangeRateDate_OtherReceiveMaster: 0,
  maxSizeExchangeRateDate_OtherReceiveMaster: 20,
  patternExchangeRateDate_OtherReceiveMaster: "",
  minSizeExchangeRate_OtherReceiveMaster: 0,
  maxSizeExchangeRate_OtherReceiveMaster: 26,
  patternExchangeRate_OtherReceiveMaster: "",
  minSizeCurrencyId_OtherReceiveMaster: 0,
  maxSizeCurrencyId_OtherReceiveMaster: 19,
  patternCurrencyId_OtherReceiveMaster: "",
  minSizeRemark1_OtherReceiveMaster: 0,
  maxSizeRemark1_OtherReceiveMaster: 255,
  patternRemark1_OtherReceiveMaster: "",
  minSizeCustName_OtherReceiveMaster: 0,
  maxSizeCustName_OtherReceiveMaster: 255,
  patternCustName_OtherReceiveMaster: "",
  minSizeDuedate_OtherReceiveMaster: 0,
  maxSizeDuedate_OtherReceiveMaster: 20,
  patternDuedate_OtherReceiveMaster: "",
  minSizeBankAmt_OtherReceiveMaster: 0,
  maxSizeBankAmt_OtherReceiveMaster: 26,
  patternBankAmt_OtherReceiveMaster: "",
  minSizeChequeAmt_OtherReceiveMaster: 0,
  maxSizeChequeAmt_OtherReceiveMaster: 26,
  patternChequeAmt_OtherReceiveMaster: "",
  minSizeChequeDepositBookId_OtherReceiveMaster: 0,
  maxSizeChequeDepositBookId_OtherReceiveMaster: 19,
  patternChequeDepositBookId_OtherReceiveMaster: "",
  minSizeCashAmt_OtherReceiveMaster: 0,
  maxSizeCashAmt_OtherReceiveMaster: 26,
  patternCashAmt_OtherReceiveMaster: "",
  minSizeChequeTransectionId_ChequeTransection: 0,
  maxSizeChequeTransectionId_ChequeTransection: 19,
  patternChequeTransectionId_ChequeTransection: "",
  minSizeDetailNo_ChequeTransection: 0,
  maxSizeDetailNo_ChequeTransection: 10,
  patternDetailNo_ChequeTransection: "",
  minSizeDocNo_ChequeTransection: 0,
  maxSizeDocNo_ChequeTransection: 30,
  patternDocNo_ChequeTransection: "",
  minSizeChequeNo_ChequeTransection: 0,
  maxSizeChequeNo_ChequeTransection: 20,
  patternChequeNo_ChequeTransection: "",
  minSizeBankName_ChequeTransection: 0,
  maxSizeBankName_ChequeTransection: 255,
  patternBankName_ChequeTransection: "",
  minSizeBankDivision_ChequeTransection: 0,
  maxSizeBankDivision_ChequeTransection: 255,
  patternBankDivision_ChequeTransection: "",
  minSizeChequeDate_ChequeTransection: 0,
  maxSizeChequeDate_ChequeTransection: 20,
  patternChequeDate_ChequeTransection: "",
  minSizeVoucherChequeDocNo_ChequeTransection: 0,
  maxSizeVoucherChequeDocNo_ChequeTransection: 30,
  patternVoucherChequeDocNo_ChequeTransection: "",
  minSizeAmount_ChequeTransection: 0,
  maxSizeAmount_ChequeTransection: 26,
  patternAmount_ChequeTransection: "",
  minSizeCompanyCode_ChequeTransection: 0,
  maxSizeCompanyCode_ChequeTransection: 20,
  patternCompanyCode_ChequeTransection: "[0-9a-zA-Z/-]+",
  minSizeDivisionBank_ChequeTransection: 0,
  maxSizeDivisionBank_ChequeTransection: 100,
  patternDivisionBank_ChequeTransection: "",
  minSizeCustBank_ChequeTransection: 0,
  maxSizeCustBank_ChequeTransection: 100,
  patternCustBank_ChequeTransection: "",
  minSizePayAbleName_ChequeTransection: 0,
  maxSizePayAbleName_ChequeTransection: 255,
  patternPayAbleName_ChequeTransection: "",
  minSizeRemark_ChequeTransection: 0,
  maxSizeRemark_ChequeTransection: 255,
  patternRemark_ChequeTransection: "",
  minSizeDepositBookMasterId_ChequeTransection: 0,
  maxSizeDepositBookMasterId_ChequeTransection: 19,
  patternDepositBookMasterId_ChequeTransection: "",
  minSizeBankId_ChequeTransection: 0,
  maxSizeBankId_ChequeTransection: 19,
  patternBankId_ChequeTransection: "",
  minSizeBillingNoteMasterId_BillingNoteMaster: 0,
  maxSizeBillingNoteMasterId_BillingNoteMaster: 19,
  patternBillingNoteMasterId_BillingNoteMaster: "",
  minSizeBillingNoteDocNo_BillingNoteMaster: 0,
  maxSizeBillingNoteDocNo_BillingNoteMaster: 20,
  patternBillingNoteDocNo_BillingNoteMaster: "",
  minSizeBillingNoteDocDate_BillingNoteMaster: 0,
  maxSizeBillingNoteDocDate_BillingNoteMaster: 20,
  patternBillingNoteDocDate_BillingNoteMaster: "",
  minSizeBillingNoteDueDate_BillingNoteMaster: 0,
  maxSizeBillingNoteDueDate_BillingNoteMaster: 20,
  patternBillingNoteDueDate_BillingNoteMaster: "",
  minSizeBillingNoteToTal_BillingNoteMaster: 0,
  maxSizeBillingNoteToTal_BillingNoteMaster: 26,
  patternBillingNoteToTal_BillingNoteMaster: "",
  minSizeBillingNoteCreditNoteDocNo_BillingNoteMaster: 0,
  maxSizeBillingNoteCreditNoteDocNo_BillingNoteMaster: 20,
  patternBillingNoteCreditNoteDocNo_BillingNoteMaster: "",
  minSizeBillingNoteCreditNoteDocAmt_BillingNoteMaster: 0,
  maxSizeBillingNoteCreditNoteDocAmt_BillingNoteMaster: 26,
  patternBillingNoteCreditNoteDocAmt_BillingNoteMaster: "",
  minSizeBillingNoteNetTotal_BillingNoteMaster: 0,
  maxSizeBillingNoteNetTotal_BillingNoteMaster: 26,
  patternBillingNoteNetTotal_BillingNoteMaster: "",
  minSizeBillingNoteReceiptAmt_BillingNoteMaster: 0,
  maxSizeBillingNoteReceiptAmt_BillingNoteMaster: 26,
  patternBillingNoteReceiptAmt_BillingNoteMaster: "",
  minSizeBillingNoteRemark_BillingNoteMaster: 0,
  maxSizeBillingNoteRemark_BillingNoteMaster: 500,
  patternBillingNoteRemark_BillingNoteMaster: "",
  minSizeBillingNoteStatus_BillingNoteMaster: 0,
  maxSizeBillingNoteStatus_BillingNoteMaster: 2,
  patternBillingNoteStatus_BillingNoteMaster: "",
  minSizeBillingNoteBeforeChangeStatus_BillingNoteMaster: 0,
  maxSizeBillingNoteBeforeChangeStatus_BillingNoteMaster: 2,
  patternBillingNoteBeforeChangeStatus_BillingNoteMaster: "",
  minSizeBillingNoteLinkDoc_BillingNoteMaster: 0,
  maxSizeBillingNoteLinkDoc_BillingNoteMaster: 20,
  patternBillingNoteLinkDoc_BillingNoteMaster: "",
  minSizeCreateOn_BillingNoteMaster: 0,
  maxSizeCreateOn_BillingNoteMaster: 20,
  patternCreateOn_BillingNoteMaster: "",
  minSizeUpdateOn_BillingNoteMaster: 0,
  maxSizeUpdateOn_BillingNoteMaster: 20,
  patternUpdateOn_BillingNoteMaster: "",
  minSizeCompanyCode_BillingNoteMaster: 0,
  maxSizeCompanyCode_BillingNoteMaster: 20,
  patternCompanyCode_BillingNoteMaster: "[0-9a-zA-Z/-]+",
  minSizeExchangeRate_BillingNoteMaster: 0,
  maxSizeExchangeRate_BillingNoteMaster: 26,
  patternExchangeRate_BillingNoteMaster: "",
  minSizeExchageRateDate_BillingNoteMaster: 0,
  maxSizeExchageRateDate_BillingNoteMaster: 20,
  patternExchageRateDate_BillingNoteMaster: "",
  minSizeAStatus_BillingNoteMaster: 0,
  maxSizeAStatus_BillingNoteMaster: 2,
  patternAStatus_BillingNoteMaster: "",
  minSizeReviseNo_BillingNoteMaster: 0,
  maxSizeReviseNo_BillingNoteMaster: 10,
  patternReviseNo_BillingNoteMaster: "",
  minSizeUseByDocType_BillingNoteMaster: 0,
  maxSizeUseByDocType_BillingNoteMaster: 3,
  patternUseByDocType_BillingNoteMaster: "",
  minSizeCurrencyId_BillingNoteMaster: 0,
  maxSizeCurrencyId_BillingNoteMaster: 19,
  patternCurrencyId_BillingNoteMaster: "",
  minSizeDocumentGroupId_BillingNoteMaster: 0,
  maxSizeDocumentGroupId_BillingNoteMaster: 19,
  patternDocumentGroupId_BillingNoteMaster: "",
  minSizeCustomerMasterId_BillingNoteMaster: 0,
  maxSizeCustomerMasterId_BillingNoteMaster: 19,
  patternCustomerMasterId_BillingNoteMaster: "",
  minSizeOfficerEmpMasterId_BillingNoteMaster: 0,
  maxSizeOfficerEmpMasterId_BillingNoteMaster: 19,
  patternOfficerEmpMasterId_BillingNoteMaster: "",
  minSizeDivisionId_BillingNoteMaster: 0,
  maxSizeDivisionId_BillingNoteMaster: 19,
  patternDivisionId_BillingNoteMaster: "",
  minSizeSectionId_BillingNoteMaster: 0,
  maxSizeSectionId_BillingNoteMaster: 19,
  patternSectionId_BillingNoteMaster: "",
  minSizeCreateByEmpId_BillingNoteMaster: 0,
  maxSizeCreateByEmpId_BillingNoteMaster: 19,
  patternCreateByEmpId_BillingNoteMaster: "",
  minSizeUpdateByEmpId_BillingNoteMaster: 0,
  maxSizeUpdateByEmpId_BillingNoteMaster: 19,
  patternUpdateByEmpId_BillingNoteMaster: "",
  minSizeDivisionNo_BillingNoteMaster: 0,
  maxSizeDivisionNo_BillingNoteMaster: 20,
  patternDivisionNo_BillingNoteMaster: "",
  minSizeDivisionName_BillingNoteMaster: 0,
  maxSizeDivisionName_BillingNoteMaster: 1000,
  patternDivisionName_BillingNoteMaster: "",
  minSizeDivisionAddress_BillingNoteMaster: 0,
  maxSizeDivisionAddress_BillingNoteMaster: 1000,
  patternDivisionAddress_BillingNoteMaster: "",
  minSizePrintStatus_BillingNoteMaster: 0,
  maxSizePrintStatus_BillingNoteMaster: 2,
  patternPrintStatus_BillingNoteMaster: "",
  minSizeBlDiscAmt_BillingNoteMaster: 0,
  maxSizeBlDiscAmt_BillingNoteMaster: 26,
  patternBlDiscAmt_BillingNoteMaster: "",
  minSizeBlNetAmt_BillingNoteMaster: 0,
  maxSizeBlNetAmt_BillingNoteMaster: 26,
  patternBlNetAmt_BillingNoteMaster: "",
  minSizeDivisionCustDetailId_BillingNoteMaster: 0,
  maxSizeDivisionCustDetailId_BillingNoteMaster: 19,
  patternDivisionCustDetailId_BillingNoteMaster: "",
  minSizeCreditNoteMasterId_CreditNoteMaster: 0,
  maxSizeCreditNoteMasterId_CreditNoteMaster: 19,
  patternCreditNoteMasterId_CreditNoteMaster: "",
  minSizeCnDocNo_CreditNoteMaster: 0,
  maxSizeCnDocNo_CreditNoteMaster: 20,
  patternCnDocNo_CreditNoteMaster: "",
  minSizeCnDocDate_CreditNoteMaster: 0,
  maxSizeCnDocDate_CreditNoteMaster: 20,
  patternCnDocDate_CreditNoteMaster: "",
  minSizeCnInvoiceNo_CreditNoteMaster: 0,
  maxSizeCnInvoiceNo_CreditNoteMaster: 20,
  patternCnInvoiceNo_CreditNoteMaster: "",
  minSizeCnInvoiceDate_CreditNoteMaster: 0,
  maxSizeCnInvoiceDate_CreditNoteMaster: 20,
  patternCnInvoiceDate_CreditNoteMaster: "",
  minSizeDocType_CreditNoteMaster: 0,
  maxSizeDocType_CreditNoteMaster: 2,
  patternDocType_CreditNoteMaster: "",
  minSizeCnTotal_CreditNoteMaster: 0,
  maxSizeCnTotal_CreditNoteMaster: 26,
  patternCnTotal_CreditNoteMaster: "",
  minSizeCnVatRate_CreditNoteMaster: 0,
  maxSizeCnVatRate_CreditNoteMaster: 26,
  patternCnVatRate_CreditNoteMaster: "",
  minSizeCnVatAmt_CreditNoteMaster: 0,
  maxSizeCnVatAmt_CreditNoteMaster: 26,
  patternCnVatAmt_CreditNoteMaster: "",
  minSizeCnNetPrice_CreditNoteMaster: 0,
  maxSizeCnNetPrice_CreditNoteMaster: 26,
  patternCnNetPrice_CreditNoteMaster: "",
  minSizeCnReceivePrice_CreditNoteMaster: 0,
  maxSizeCnReceivePrice_CreditNoteMaster: 26,
  patternCnReceivePrice_CreditNoteMaster: "",
  minSizeAmountOfOriginalDoc_CreditNoteMaster: 0,
  maxSizeAmountOfOriginalDoc_CreditNoteMaster: 26,
  patternAmountOfOriginalDoc_CreditNoteMaster: "",
  minSizeCorrectAmount_CreditNoteMaster: 0,
  maxSizeCorrectAmount_CreditNoteMaster: 26,
  patternCorrectAmount_CreditNoteMaster: "",
  minSizeCnDiscAmt_CreditNoteMaster: 0,
  maxSizeCnDiscAmt_CreditNoteMaster: 26,
  patternCnDiscAmt_CreditNoteMaster: "",
  minSizeDivisionNo_CreditNoteMaster: 0,
  maxSizeDivisionNo_CreditNoteMaster: 10,
  patternDivisionNo_CreditNoteMaster: "",
  minSizeDivisionName_CreditNoteMaster: 0,
  maxSizeDivisionName_CreditNoteMaster: 50,
  patternDivisionName_CreditNoteMaster: "",
  minSizeDivisionAddress_CreditNoteMaster: 0,
  maxSizeDivisionAddress_CreditNoteMaster: 255,
  patternDivisionAddress_CreditNoteMaster: "",
  minSizeTaxIdNo_CreditNoteMaster: 0,
  maxSizeTaxIdNo_CreditNoteMaster: 50,
  patternTaxIdNo_CreditNoteMaster: "",
  minSizeCnRemark_CreditNoteMaster: 0,
  maxSizeCnRemark_CreditNoteMaster: 250,
  patternCnRemark_CreditNoteMaster: "",
  minSizeCnStatus_CreditNoteMaster: 0,
  maxSizeCnStatus_CreditNoteMaster: 2,
  patternCnStatus_CreditNoteMaster: "",
  minSizeCnLinkDocNo_CreditNoteMaster: 0,
  maxSizeCnLinkDocNo_CreditNoteMaster: 20,
  patternCnLinkDocNo_CreditNoteMaster: "",
  minSizeCreateOn_CreditNoteMaster: 0,
  maxSizeCreateOn_CreditNoteMaster: 20,
  patternCreateOn_CreditNoteMaster: "",
  minSizeUpdateOn_CreditNoteMaster: 0,
  maxSizeUpdateOn_CreditNoteMaster: 20,
  patternUpdateOn_CreditNoteMaster: "",
  minSizeCnBeforeChangeStatus_CreditNoteMaster: 0,
  maxSizeCnBeforeChangeStatus_CreditNoteMaster: 2,
  patternCnBeforeChangeStatus_CreditNoteMaster: "",
  minSizeCnVoucherNo_CreditNoteMaster: 0,
  maxSizeCnVoucherNo_CreditNoteMaster: 20,
  patternCnVoucherNo_CreditNoteMaster: "",
  minSizeCompanyCode_CreditNoteMaster: 0,
  maxSizeCompanyCode_CreditNoteMaster: 20,
  patternCompanyCode_CreditNoteMaster: "[0-9a-zA-Z/-]+",
  minSizeExchangeRate_CreditNoteMaster: 0,
  maxSizeExchangeRate_CreditNoteMaster: 26,
  patternExchangeRate_CreditNoteMaster: "",
  minSizeExchageRateDate_CreditNoteMaster: 0,
  maxSizeExchageRateDate_CreditNoteMaster: 20,
  patternExchageRateDate_CreditNoteMaster: "",
  minSizeAstatus_CreditNoteMaster: 0,
  maxSizeAstatus_CreditNoteMaster: 2,
  patternAstatus_CreditNoteMaster: "",
  minSizeCnName_CreditNoteMaster: 0,
  maxSizeCnName_CreditNoteMaster: 9999,
  patternCnName_CreditNoteMaster: "",
  minSizeCnAddress_CreditNoteMaster: 0,
  maxSizeCnAddress_CreditNoteMaster: 9999,
  patternCnAddress_CreditNoteMaster: "",
  minSizeCnTelNo_CreditNoteMaster: 0,
  maxSizeCnTelNo_CreditNoteMaster: 100,
  patternCnTelNo_CreditNoteMaster: "",
  minSizeVatInclude_CreditNoteMaster: 0,
  maxSizeVatInclude_CreditNoteMaster: 2,
  patternVatInclude_CreditNoteMaster: "",
  minSizeCurrencyId_CreditNoteMaster: 0,
  maxSizeCurrencyId_CreditNoteMaster: 19,
  patternCurrencyId_CreditNoteMaster: "",
  minSizeDocumentGroupId_CreditNoteMaster: 0,
  maxSizeDocumentGroupId_CreditNoteMaster: 19,
  patternDocumentGroupId_CreditNoteMaster: "",
  minSizeCustomerMasterId_CreditNoteMaster: 0,
  maxSizeCustomerMasterId_CreditNoteMaster: 19,
  patternCustomerMasterId_CreditNoteMaster: "",
  minSizeEmpMasterId_CreditNoteMaster: 0,
  maxSizeEmpMasterId_CreditNoteMaster: 19,
  patternEmpMasterId_CreditNoteMaster: "",
  minSizeDivisionId_CreditNoteMaster: 0,
  maxSizeDivisionId_CreditNoteMaster: 19,
  patternDivisionId_CreditNoteMaster: "",
  minSizeSectionId_CreditNoteMaster: 0,
  maxSizeSectionId_CreditNoteMaster: 19,
  patternSectionId_CreditNoteMaster: "",
  minSizeCreateByEmpId_CreditNoteMaster: 0,
  maxSizeCreateByEmpId_CreditNoteMaster: 19,
  patternCreateByEmpId_CreditNoteMaster: "",
  minSizeUpdateByEmpId_CreditNoteMaster: 0,
  maxSizeUpdateByEmpId_CreditNoteMaster: 19,
  patternUpdateByEmpId_CreditNoteMaster: "",
  minSizeIsCheckPayBy_CreditNoteMaster: 0,
  maxSizeIsCheckPayBy_CreditNoteMaster: 1,
  patternIsCheckPayBy_CreditNoteMaster: "",
  minSizeDivisionBank_CreditNoteMaster: 0,
  maxSizeDivisionBank_CreditNoteMaster: 100,
  patternDivisionBank_CreditNoteMaster: "",
  minSizeCustBank_CreditNoteMaster: 0,
  maxSizeCustBank_CreditNoteMaster: 100,
  patternCustBank_CreditNoteMaster: "",
  minSizeCnPaymentType_CreditNoteMaster: 0,
  maxSizeCnPaymentType_CreditNoteMaster: 20,
  patternCnPaymentType_CreditNoteMaster: "",
  minSizeCnPayAmt_CreditNoteMaster: 0,
  maxSizeCnPayAmt_CreditNoteMaster: 26,
  patternCnPayAmt_CreditNoteMaster: "",
  minSizeCharge_CreditNoteMaster: 0,
  maxSizeCharge_CreditNoteMaster: 26,
  patternCharge_CreditNoteMaster: "",
  minSizeCnChqNo_CreditNoteMaster: 0,
  maxSizeCnChqNo_CreditNoteMaster: 255,
  patternCnChqNo_CreditNoteMaster: "",
  minSizeCnChqDate_CreditNoteMaster: 0,
  maxSizeCnChqDate_CreditNoteMaster: 20,
  patternCnChqDate_CreditNoteMaster: "",
  minSizeCnBankRemark_CreditNoteMaster: 0,
  maxSizeCnBankRemark_CreditNoteMaster: 100,
  patternCnBankRemark_CreditNoteMaster: "",
  minSizeChequePayDocNo_CreditNoteMaster: 0,
  maxSizeChequePayDocNo_CreditNoteMaster: 20,
  patternChequePayDocNo_CreditNoteMaster: "",
  minSizeDepositBookId_CreditNoteMaster: 0,
  maxSizeDepositBookId_CreditNoteMaster: 19,
  patternDepositBookId_CreditNoteMaster: "",
  minSizeAudited_CreditNoteMaster: 0,
  maxSizeAudited_CreditNoteMaster: 1,
  patternAudited_CreditNoteMaster: "",
  minSizePrintStatus_CreditNoteMaster: 0,
  maxSizePrintStatus_CreditNoteMaster: 2,
  patternPrintStatus_CreditNoteMaster: "",
  minSizeCnReceiptAmt_CreditNoteMaster: 0,
  maxSizeCnReceiptAmt_CreditNoteMaster: 26,
  patternCnReceiptAmt_CreditNoteMaster: "",
  minSizeDepositAmt_CreditNoteMaster: 0,
  maxSizeDepositAmt_CreditNoteMaster: 26,
  patternDepositAmt_CreditNoteMaster: "",
  minSizeCashAmt_CreditNoteMaster: 0,
  maxSizeCashAmt_CreditNoteMaster: 26,
  patternCashAmt_CreditNoteMaster: "",
  minSizeBankAmt_CreditNoteMaster: 0,
  maxSizeBankAmt_CreditNoteMaster: 26,
  patternBankAmt_CreditNoteMaster: "",
  minSizeChequeAmt_CreditNoteMaster: 0,
  maxSizeChequeAmt_CreditNoteMaster: 26,
  patternChequeAmt_CreditNoteMaster: "",
  minSizeChequeBankId_CreditNoteMaster: 0,
  maxSizeChequeBankId_CreditNoteMaster: 26,
  patternChequeBankId_CreditNoteMaster: "",
  minSizeReceiveVoucherMasterId_ReceiveVoucherMaster: 0,
  maxSizeReceiveVoucherMasterId_ReceiveVoucherMaster: 19,
  patternReceiveVoucherMasterId_ReceiveVoucherMaster: "",
  minSizeReceiveVoucherDocNo_ReceiveVoucherMaster: 0,
  maxSizeReceiveVoucherDocNo_ReceiveVoucherMaster: 20,
  patternReceiveVoucherDocNo_ReceiveVoucherMaster: "",
  minSizeReceiveVoucherDocDate_ReceiveVoucherMaster: 0,
  maxSizeReceiveVoucherDocDate_ReceiveVoucherMaster: 20,
  patternReceiveVoucherDocDate_ReceiveVoucherMaster: "",
  minSizeReceiveVoucherReceiveDate_ReceiveVoucherMaster: 0,
  maxSizeReceiveVoucherReceiveDate_ReceiveVoucherMaster: 20,
  patternReceiveVoucherReceiveDate_ReceiveVoucherMaster: "",
  minSizeReceiveVoucherTotal_ReceiveVoucherMaster: 0,
  maxSizeReceiveVoucherTotal_ReceiveVoucherMaster: 26,
  patternReceiveVoucherTotal_ReceiveVoucherMaster: "",
  minSizeReceiveVoucherWitholdingTax_ReceiveVoucherMaster: 0,
  maxSizeReceiveVoucherWitholdingTax_ReceiveVoucherMaster: 26,
  patternReceiveVoucherWitholdingTax_ReceiveVoucherMaster: "",
  minSizeReceiveVoucherPlusDiscount_ReceiveVoucherMaster: 0,
  maxSizeReceiveVoucherPlusDiscount_ReceiveVoucherMaster: 26,
  patternReceiveVoucherPlusDiscount_ReceiveVoucherMaster: "",
  minSizeReceiveVoucherDeductDiscount_ReceiveVoucherMaster: 0,
  maxSizeReceiveVoucherDeductDiscount_ReceiveVoucherMaster: 26,
  patternReceiveVoucherDeductDiscount_ReceiveVoucherMaster: "",
  minSizeReceiveVoucherNetAmt_ReceiveVoucherMaster: 0,
  maxSizeReceiveVoucherNetAmt_ReceiveVoucherMaster: 26,
  patternReceiveVoucherNetAmt_ReceiveVoucherMaster: "",
  minSizeReceiveVoucherRemark_ReceiveVoucherMaster: 0,
  maxSizeReceiveVoucherRemark_ReceiveVoucherMaster: 250,
  patternReceiveVoucherRemark_ReceiveVoucherMaster: "",
  minSizeReceiveVoucherStatus_ReceiveVoucherMaster: 0,
  maxSizeReceiveVoucherStatus_ReceiveVoucherMaster: 2,
  patternReceiveVoucherStatus_ReceiveVoucherMaster: "",
  minSizeReceiveVoucherLinkDoc_ReceiveVoucherMaster: 0,
  maxSizeReceiveVoucherLinkDoc_ReceiveVoucherMaster: 20,
  patternReceiveVoucherLinkDoc_ReceiveVoucherMaster: "",
  minSizeReceiveVoucherReceiveType_ReceiveVoucherMaster: 0,
  maxSizeReceiveVoucherReceiveType_ReceiveVoucherMaster: 2,
  patternReceiveVoucherReceiveType_ReceiveVoucherMaster: "",
  minSizeReceiveVoucherReceiveAmt_ReceiveVoucherMaster: 0,
  maxSizeReceiveVoucherReceiveAmt_ReceiveVoucherMaster: 26,
  patternReceiveVoucherReceiveAmt_ReceiveVoucherMaster: "",
  minSizeCharge_ReceiveVoucherMaster: 0,
  maxSizeCharge_ReceiveVoucherMaster: 26,
  patternCharge_ReceiveVoucherMaster: "",
  minSizeReceiveVoucherBankRemark_ReceiveVoucherMaster: 0,
  maxSizeReceiveVoucherBankRemark_ReceiveVoucherMaster: 250,
  patternReceiveVoucherBankRemark_ReceiveVoucherMaster: "",
  minSizeReceiveVoucherChqno_ReceiveVoucherMaster: 0,
  maxSizeReceiveVoucherChqno_ReceiveVoucherMaster: 20,
  patternReceiveVoucherChqno_ReceiveVoucherMaster: "",
  minSizeReceiveVoucherChqdate_ReceiveVoucherMaster: 0,
  maxSizeReceiveVoucherChqdate_ReceiveVoucherMaster: 20,
  patternReceiveVoucherChqdate_ReceiveVoucherMaster: "",
  minSizeCreateOn_ReceiveVoucherMaster: 0,
  maxSizeCreateOn_ReceiveVoucherMaster: 20,
  patternCreateOn_ReceiveVoucherMaster: "",
  minSizeUpdateOn_ReceiveVoucherMaster: 0,
  maxSizeUpdateOn_ReceiveVoucherMaster: 20,
  patternUpdateOn_ReceiveVoucherMaster: "",
  minSizeVoucherNo_ReceiveVoucherMaster: 0,
  maxSizeVoucherNo_ReceiveVoucherMaster: 20,
  patternVoucherNo_ReceiveVoucherMaster: "",
  minSizeRecivVoucherBeforeChangeStatus_ReceiveVoucherMaster: 0,
  maxSizeRecivVoucherBeforeChangeStatus_ReceiveVoucherMaster: 2,
  patternRecivVoucherBeforeChangeStatus_ReceiveVoucherMaster: "",
  minSizeCompanyCode_ReceiveVoucherMaster: 0,
  maxSizeCompanyCode_ReceiveVoucherMaster: 20,
  patternCompanyCode_ReceiveVoucherMaster: "[0-9a-zA-Z/-]+",
  minSizeReceiveChequeDocNo_ReceiveVoucherMaster: 0,
  maxSizeReceiveChequeDocNo_ReceiveVoucherMaster: 20,
  patternReceiveChequeDocNo_ReceiveVoucherMaster: "",
  minSizeExchangeRate_ReceiveVoucherMaster: 0,
  maxSizeExchangeRate_ReceiveVoucherMaster: 26,
  patternExchangeRate_ReceiveVoucherMaster: "",
  minSizeExchageRateDate_ReceiveVoucherMaster: 0,
  maxSizeExchageRateDate_ReceiveVoucherMaster: 20,
  patternExchageRateDate_ReceiveVoucherMaster: "",
  minSizeAStatus_ReceiveVoucherMaster: 0,
  maxSizeAStatus_ReceiveVoucherMaster: 2,
  patternAStatus_ReceiveVoucherMaster: "",
  minSizeReviseNo_ReceiveVoucherMaster: 0,
  maxSizeReviseNo_ReceiveVoucherMaster: 10,
  patternReviseNo_ReceiveVoucherMaster: "",
  minSizeDivisionBank_ReceiveVoucherMaster: 0,
  maxSizeDivisionBank_ReceiveVoucherMaster: 100,
  patternDivisionBank_ReceiveVoucherMaster: "",
  minSizeCustBank_ReceiveVoucherMaster: 0,
  maxSizeCustBank_ReceiveVoucherMaster: 100,
  patternCustBank_ReceiveVoucherMaster: "",
  minSizeChequeRemark_ReceiveVoucherMaster: 0,
  maxSizeChequeRemark_ReceiveVoucherMaster: 500,
  patternChequeRemark_ReceiveVoucherMaster: "",
  minSizeDeductListOther_ReceiveVoucherMaster: 0,
  maxSizeDeductListOther_ReceiveVoucherMaster: 26,
  patternDeductListOther_ReceiveVoucherMaster: "",
  minSizePlusListOther_ReceiveVoucherMaster: 0,
  maxSizePlusListOther_ReceiveVoucherMaster: 26,
  patternPlusListOther_ReceiveVoucherMaster: "",
  minSizeCurrencyId_ReceiveVoucherMaster: 0,
  maxSizeCurrencyId_ReceiveVoucherMaster: 19,
  patternCurrencyId_ReceiveVoucherMaster: "",
  minSizeDocumentGroupId_ReceiveVoucherMaster: 0,
  maxSizeDocumentGroupId_ReceiveVoucherMaster: 19,
  patternDocumentGroupId_ReceiveVoucherMaster: "",
  minSizeCustomerMasterId_ReceiveVoucherMaster: 0,
  maxSizeCustomerMasterId_ReceiveVoucherMaster: 19,
  patternCustomerMasterId_ReceiveVoucherMaster: "",
  minSizeEmpMasterId_ReceiveVoucherMaster: 0,
  maxSizeEmpMasterId_ReceiveVoucherMaster: 19,
  patternEmpMasterId_ReceiveVoucherMaster: "",
  minSizeDivisionId_ReceiveVoucherMaster: 0,
  maxSizeDivisionId_ReceiveVoucherMaster: 19,
  patternDivisionId_ReceiveVoucherMaster: "",
  minSizeSectionId_ReceiveVoucherMaster: 0,
  maxSizeSectionId_ReceiveVoucherMaster: 19,
  patternSectionId_ReceiveVoucherMaster: "",
  minSizeCreateByEmpId_ReceiveVoucherMaster: 0,
  maxSizeCreateByEmpId_ReceiveVoucherMaster: 19,
  patternCreateByEmpId_ReceiveVoucherMaster: "",
  minSizeUpdateByEmpId_ReceiveVoucherMaster: 0,
  maxSizeUpdateByEmpId_ReceiveVoucherMaster: 19,
  patternUpdateByEmpId_ReceiveVoucherMaster: "",
  minSizeAudited_ReceiveVoucherMaster: 0,
  maxSizeAudited_ReceiveVoucherMaster: 1,
  patternAudited_ReceiveVoucherMaster: "",
  minSizeReceiveType_ReceiveVoucherMaster: 0,
  maxSizeReceiveType_ReceiveVoucherMaster: 2,
  patternReceiveType_ReceiveVoucherMaster: "",
  minSizeDivisionNo_ReceiveVoucherMaster: 0,
  maxSizeDivisionNo_ReceiveVoucherMaster: 20,
  patternDivisionNo_ReceiveVoucherMaster: "",
  minSizeDivisionName_ReceiveVoucherMaster: 0,
  maxSizeDivisionName_ReceiveVoucherMaster: 1000,
  patternDivisionName_ReceiveVoucherMaster: "",
  minSizeDivisionAddress_ReceiveVoucherMaster: 0,
  maxSizeDivisionAddress_ReceiveVoucherMaster: 2000,
  patternDivisionAddress_ReceiveVoucherMaster: "",
  minSizePrintStatus_ReceiveVoucherMaster: 0,
  maxSizePrintStatus_ReceiveVoucherMaster: 2,
  patternPrintStatus_ReceiveVoucherMaster: "",
  minSizeBankDate_ReceiveVoucherMaster: 0,
  maxSizeBankDate_ReceiveVoucherMaster: 20,
  patternBankDate_ReceiveVoucherMaster: "",
  minSizeRemark1_ReceiveVoucherMaster: 0,
  maxSizeRemark1_ReceiveVoucherMaster: 255,
  patternRemark1_ReceiveVoucherMaster: "",
  minSizeDivisionCustDetailId_ReceiveVoucherMaster: 0,
  maxSizeDivisionCustDetailId_ReceiveVoucherMaster: 19,
  patternDivisionCustDetailId_ReceiveVoucherMaster: "",
  minSizeCashAmount_ReceiveVoucherMaster: 0,
  maxSizeCashAmount_ReceiveVoucherMaster: 26,
  patternCashAmount_ReceiveVoucherMaster: "",
  minSizeBankAmount_ReceiveVoucherMaster: 0,
  maxSizeBankAmount_ReceiveVoucherMaster: 26,
  patternBankAmount_ReceiveVoucherMaster: "",
  minSizeChequeAmount_ReceiveVoucherMaster: 0,
  maxSizeChequeAmount_ReceiveVoucherMaster: 26,
  patternChequeAmount_ReceiveVoucherMaster: "",
  minSizeDepositBookMasterId_ReceiveVoucherMaster: 0,
  maxSizeDepositBookMasterId_ReceiveVoucherMaster: 19,
  patternDepositBookMasterId_ReceiveVoucherMaster: "",
  minSizeDocNo_ReceiveVoucherMaster: 0,
  maxSizeDocNo_ReceiveVoucherMaster: 20,
  patternDocNo_ReceiveVoucherMaster: "",
  minSizeDocDate_ReceiveVoucherMaster: 0,
  maxSizeDocDate_ReceiveVoucherMaster: 20,
  patternDocDate_ReceiveVoucherMaster: "",
  minSizeAmount_ReceiveVoucherMaster: 0,
  maxSizeAmount_ReceiveVoucherMaster: 26,
  patternAmount_ReceiveVoucherMaster: "",
  minSizeTax_ReceiveVoucherMaster: 0,
  maxSizeTax_ReceiveVoucherMaster: 26,
  patternTax_ReceiveVoucherMaster: "",
  minSizeVatRate_ReceiveVoucherMaster: 0,
  maxSizeVatRate_ReceiveVoucherMaster: 26,
  patternVatRate_ReceiveVoucherMaster: "",
  minSizeNameInVat_ReceiveVoucherMaster: 0,
  maxSizeNameInVat_ReceiveVoucherMaster: 255,
  patternNameInVat_ReceiveVoucherMaster: "",
  minSizeTaxIdNo_ReceiveVoucherMaster: 0,
  maxSizeTaxIdNo_ReceiveVoucherMaster: 20,
  patternTaxIdNo_ReceiveVoucherMaster: "",
  minSizeDivisionSuppName_ReceiveVoucherMaster: 0,
  maxSizeDivisionSuppName_ReceiveVoucherMaster: 255,
  patternDivisionSuppName_ReceiveVoucherMaster: "",
  minSizeVatType_ReceiveVoucherMaster: 0,
  maxSizeVatType_ReceiveVoucherMaster: 20,
  patternVatType_ReceiveVoucherMaster: "",
  minSizeTaxReturn_ReceiveVoucherMaster: 0,
  maxSizeTaxReturn_ReceiveVoucherMaster: 5,
  patternTaxReturn_ReceiveVoucherMaster: "",
  minSizeOtherReceiveMasterId_OtherReceiveMasterJobMaster: 0,
  maxSizeOtherReceiveMasterId_OtherReceiveMasterJobMaster: 19,
  patternOtherReceiveMasterId_OtherReceiveMasterJobMaster: "",
  minSizeJobMasterId_OtherReceiveMasterJobMaster: 0,
  maxSizeJobMasterId_OtherReceiveMasterJobMaster: 19,
  patternJobMasterId_OtherReceiveMasterJobMaster: "",
  minSizeAccountDefineId_AccDefineCategory: 0,
  maxSizeAccountDefineId_AccDefineCategory: 19,
  patternAccountDefineId_AccDefineCategory: "",
  minSizeCategoryId_AccDefineCategory: 0,
  maxSizeCategoryId_AccDefineCategory: 19,
  patternCategoryId_AccDefineCategory: "",
  minSizeOtherReceiveId_OtherReceive: 0,
  maxSizeOtherReceiveId_OtherReceive: 19,
  patternOtherReceiveId_OtherReceive: "",
  minSizeOtherReceiveCode_OtherReceive: 0,
  maxSizeOtherReceiveCode_OtherReceive: 30,
  patternOtherReceiveCode_OtherReceive: "[0-9a-zA-Z/-]+",
  minSizeOtherReceiveAmount_OtherReceive: 0,
  maxSizeOtherReceiveAmount_OtherReceive: 26,
  patternOtherReceiveAmount_OtherReceive: "",
  minSizePlusOtherReceiveAmount_OtherReceive: 0,
  maxSizePlusOtherReceiveAmount_OtherReceive: 26,
  patternPlusOtherReceiveAmount_OtherReceive: "",
  minSizeCompanyCode_OtherReceive: 0,
  maxSizeCompanyCode_OtherReceive: 20,
  patternCompanyCode_OtherReceive: "[0-9a-zA-Z/-]+",
  minSizeAccountCodeId_OtherReceive: 0,
  maxSizeAccountCodeId_OtherReceive: 19,
  patternAccountCodeId_OtherReceive: "[0-9a-zA-Z/-]+",
  minSizeReceiveVoucherMasterId_OtherReceive: 0,
  maxSizeReceiveVoucherMasterId_OtherReceive: 19,
  patternReceiveVoucherMasterId_OtherReceive: "",
  minSizeQcDate_LotMaster: 0,
  maxSizeQcDate_LotMaster: 10,
  patternQcDate_LotMaster: "",
  minSizeRecieveChequeId_RecieveChequeJobMaster: 0,
  maxSizeRecieveChequeId_RecieveChequeJobMaster: 19,
  patternRecieveChequeId_RecieveChequeJobMaster: "",
  minSizeJobMasterId_RecieveChequeJobMaster: 0,
  maxSizeJobMasterId_RecieveChequeJobMaster: 19,
  patternJobMasterId_RecieveChequeJobMaster: "",
  minSizeStatus_Section: 0,
  maxSizeStatus_Section: 2,
  patternStatus_Section: "",
  minSizeCustPriceLevel_CustomerCredit: 0,
  maxSizeCustPriceLevel_CustomerCredit: 10,
  patternCustPriceLevel_CustomerCredit: "",
  minSizeReceiveVoucherDetailId_ReceiveVoucherDetail: 0,
  maxSizeReceiveVoucherDetailId_ReceiveVoucherDetail: 19,
  patternReceiveVoucherDetailId_ReceiveVoucherDetail: "",
  minSizeReceiveVoucherDetailNo_ReceiveVoucherDetail: 0,
  maxSizeReceiveVoucherDetailNo_ReceiveVoucherDetail: 10,
  patternReceiveVoucherDetailNo_ReceiveVoucherDetail: "",
  minSizeReceiptDocNo_ReceiveVoucherDetail: 0,
  maxSizeReceiptDocNo_ReceiveVoucherDetail: 20,
  patternReceiptDocNo_ReceiveVoucherDetail: "",
  minSizeReceiptDocDate_ReceiveVoucherDetail: 0,
  maxSizeReceiptDocDate_ReceiveVoucherDetail: 50,
  patternReceiptDocDate_ReceiveVoucherDetail: "",
  minSizeDiscreption_ReceiveVoucherDetail: 0,
  maxSizeDiscreption_ReceiveVoucherDetail: 255,
  patternDiscreption_ReceiveVoucherDetail: "",
  minSizeReceiptDocType_ReceiveVoucherDetail: 0,
  maxSizeReceiptDocType_ReceiveVoucherDetail: 3,
  patternReceiptDocType_ReceiveVoucherDetail: "",
  minSizeReceiptDocRefNo_ReceiveVoucherDetail: 0,
  maxSizeReceiptDocRefNo_ReceiveVoucherDetail: 20,
  patternReceiptDocRefNo_ReceiveVoucherDetail: "",
  minSizeReceiptDocAmt_ReceiveVoucherDetail: 0,
  maxSizeReceiptDocAmt_ReceiveVoucherDetail: 26,
  patternReceiptDocAmt_ReceiveVoucherDetail: "",
  minSizeReceiptDocRemain_ReceiveVoucherDetail: 0,
  maxSizeReceiptDocRemain_ReceiveVoucherDetail: 26,
  patternReceiptDocRemain_ReceiveVoucherDetail: "",
  minSizeReceiveVoucherAmt_ReceiveVoucherDetail: 0,
  maxSizeReceiveVoucherAmt_ReceiveVoucherDetail: 26,
  patternReceiveVoucherAmt_ReceiveVoucherDetail: "",
  minSizeCompanyCode_ReceiveVoucherDetail: 0,
  maxSizeCompanyCode_ReceiveVoucherDetail: 20,
  patternCompanyCode_ReceiveVoucherDetail: "[0-9a-zA-Z/-]+",
  minSizeReceiveVoucherMasterId_ReceiveVoucherDetail: 0,
  maxSizeReceiveVoucherMasterId_ReceiveVoucherDetail: 19,
  patternReceiveVoucherMasterId_ReceiveVoucherDetail: "",
  minSizeExchangeRate_ReceiveVoucherDetail: 0,
  maxSizeExchangeRate_ReceiveVoucherDetail: 26,
  patternExchangeRate_ReceiveVoucherDetail: "",
  minSizeExchangeRateDate_ReceiveVoucherDetail: 0,
  maxSizeExchangeRateDate_ReceiveVoucherDetail: 20,
  patternExchangeRateDate_ReceiveVoucherDetail: "",
  minSizeBlDocNo_ReceiveVoucherDetail: 0,
  maxSizeBlDocNo_ReceiveVoucherDetail: 20,
  patternBlDocNo_ReceiveVoucherDetail: "",
  minSizeRcDetailNo_ReceiveVoucherDetail: 0,
  maxSizeRcDetailNo_ReceiveVoucherDetail: 10,
  patternRcDetailNo_ReceiveVoucherDetail: "",
  minSizeReceiptDetailId_ReceiveVoucherDetail: 0,
  maxSizeReceiptDetailId_ReceiveVoucherDetail: 19,
  patternReceiptDetailId_ReceiveVoucherDetail: "",
  minSizeBillingNoteDetailId_ReceiveVoucherDetail: 0,
  maxSizeBillingNoteDetailId_ReceiveVoucherDetail: 19,
  patternBillingNoteDetailId_ReceiveVoucherDetail: "",
  minSizeServiceBillingNoteMasterId_ReceiveVoucherDetail: 0,
  maxSizeServiceBillingNoteMasterId_ReceiveVoucherDetail: 19,
  patternServiceBillingNoteMasterId_ReceiveVoucherDetail: "",
  minSizeDeliveryOrderMasterId_ReceiveVoucherDetail: 0,
  maxSizeDeliveryOrderMasterId_ReceiveVoucherDetail: 19,
  patternDeliveryOrderMasterId_ReceiveVoucherDetail: "",
  minSizeCreditNoteMasterId_ReceiveVoucherDetail: 0,
  maxSizeCreditNoteMasterId_ReceiveVoucherDetail: 19,
  patternCreditNoteMasterId_ReceiveVoucherDetail: "",
  minSizeInvoiceMasterId_ReceiveVoucherDetail: 0,
  maxSizeInvoiceMasterId_ReceiveVoucherDetail: 19,
  patternInvoiceMasterId_ReceiveVoucherDetail: "",
  minSizeRctInvServiceMasterId_ReceiveVoucherDetail: 0,
  maxSizeRctInvServiceMasterId_ReceiveVoucherDetail: 19,
  patternRctInvServiceMasterId_ReceiveVoucherDetail: "",
  minSizeOtherReceiveMasterId_ReceiveVoucherDetail: 0,
  maxSizeOtherReceiveMasterId_ReceiveVoucherDetail: 19,
  patternOtherReceiveMasterId_ReceiveVoucherDetail: "",
  minSizeDepositReceivInvoiceMasterId_ReceiveVoucherDetail: 0,
  maxSizeDepositReceivInvoiceMasterId_ReceiveVoucherDetail: 19,
  patternDepositReceivInvoiceMasterId_ReceiveVoucherDetail: "",
  minSizeOtherReceiveMasterId_OtherReceiveMasterDepositBook: 0,
  maxSizeOtherReceiveMasterId_OtherReceiveMasterDepositBook: 19,
  patternOtherReceiveMasterId_OtherReceiveMasterDepositBook: "",
  minSizeDepositBookMasterId_OtherReceiveMasterDepositBook: 0,
  maxSizeDepositBookMasterId_OtherReceiveMasterDepositBook: 19,
  patternDepositBookMasterId_OtherReceiveMasterDepositBook: "",
  minSizeInvoiceDate_PurchaseRecordingDetail: 0,
  maxSizeInvoiceDate_PurchaseRecordingDetail: 20,
  patternInvoiceDate_PurchaseRecordingDetail: "",
  minSizeProdRecVatInclude_PurchaseRecordingDetail: 0,
  maxSizeProdRecVatInclude_PurchaseRecordingDetail: 4,
  patternProdRecVatInclude_PurchaseRecordingDetail: "",
  minSizeExchageRateDate_BillingPaymentMaster: 0,
  maxSizeExchageRateDate_BillingPaymentMaster: 20,
  patternExchageRateDate_BillingPaymentMaster: "",
  minSizeAStatus_BillingPaymentMaster: 0,
  maxSizeAStatus_BillingPaymentMaster: 2,
  patternAStatus_BillingPaymentMaster: "",
  minSizeAdvancePaymentId_AdvancePayment: 0,
  maxSizeAdvancePaymentId_AdvancePayment: 19,
  patternAdvancePaymentId_AdvancePayment: "",
  minSizeAdvancePaymentDocNo_AdvancePayment: 0,
  maxSizeAdvancePaymentDocNo_AdvancePayment: 20,
  patternAdvancePaymentDocNo_AdvancePayment: "",
  minSizeAdvancePaymentDocDate_AdvancePayment: 0,
  maxSizeAdvancePaymentDocDate_AdvancePayment: 20,
  patternAdvancePaymentDocDate_AdvancePayment: "",
  minSizeDocType_AdvancePayment: 0,
  maxSizeDocType_AdvancePayment: 20,
  patternDocType_AdvancePayment: "",
  minSizeAdvancePaymentTotal_AdvancePayment: 0,
  maxSizeAdvancePaymentTotal_AdvancePayment: 26,
  patternAdvancePaymentTotal_AdvancePayment: "",
  minSizeAdvRemark_AdvancePayment: 0,
  maxSizeAdvRemark_AdvancePayment: 500,
  patternAdvRemark_AdvancePayment: "",
  minSizeAdvStatus_AdvancePayment: 0,
  maxSizeAdvStatus_AdvancePayment: 20,
  patternAdvStatus_AdvancePayment: "",
  minSizeAdvLinkDocNo_AdvancePayment: 0,
  maxSizeAdvLinkDocNo_AdvancePayment: 20,
  patternAdvLinkDocNo_AdvancePayment: "",
  minSizeAdvNetPay_AdvancePayment: 0,
  maxSizeAdvNetPay_AdvancePayment: 26,
  patternAdvNetPay_AdvancePayment: "",
  minSizeCreditterm_AdvancePayment: 0,
  maxSizeCreditterm_AdvancePayment: 26,
  patternCreditterm_AdvancePayment: "",
  minSizeStartDate_AdvancePayment: 0,
  maxSizeStartDate_AdvancePayment: 20,
  patternStartDate_AdvancePayment: "",
  minSizeDueDate_AdvancePayment: 0,
  maxSizeDueDate_AdvancePayment: 20,
  patternDueDate_AdvancePayment: "",
  minSizeCreateOn_AdvancePayment: 0,
  maxSizeCreateOn_AdvancePayment: 20,
  patternCreateOn_AdvancePayment: "",
  minSizeUpdateOn_AdvancePayment: 0,
  maxSizeUpdateOn_AdvancePayment: 20,
  patternUpdateOn_AdvancePayment: "",
  minSizeVoucherNo_AdvancePayment: 0,
  maxSizeVoucherNo_AdvancePayment: 20,
  patternVoucherNo_AdvancePayment: "",
  minSizeCompanyCode_AdvancePayment: 0,
  maxSizeCompanyCode_AdvancePayment: 20,
  patternCompanyCode_AdvancePayment: "[0-9a-zA-Z/-]+",
  minSizeAstatus_AdvancePayment: 0,
  maxSizeAstatus_AdvancePayment: 20,
  patternAstatus_AdvancePayment: "",
  minSizeDivisionNo_AdvancePayment: 0,
  maxSizeDivisionNo_AdvancePayment: 20,
  patternDivisionNo_AdvancePayment: "",
  minSizeDivisionName_AdvancePayment: 0,
  maxSizeDivisionName_AdvancePayment: 50,
  patternDivisionName_AdvancePayment: "",
  minSizeDivisionAddress_AdvancePayment: 0,
  maxSizeDivisionAddress_AdvancePayment: 255,
  patternDivisionAddress_AdvancePayment: "",
  minSizeMasterStatus_AdvancePayment: 0,
  maxSizeMasterStatus_AdvancePayment: 20,
  patternMasterStatus_AdvancePayment: "",
  minSizeMasterDescription_AdvancePayment: 0,
  maxSizeMasterDescription_AdvancePayment: 20,
  patternMasterDescription_AdvancePayment: "",
  minSizePrintStatus_AdvancePayment: 0,
  maxSizePrintStatus_AdvancePayment: 20,
  patternPrintStatus_AdvancePayment: "",
  minSizeJobCode_AdvancePayment: 0,
  maxSizeJobCode_AdvancePayment: 20,
  patternJobCode_AdvancePayment: "[0-9a-zA-Z/-]+",
  minSizeAccountCode_AdvancePayment: 0,
  maxSizeAccountCode_AdvancePayment: 20,
  patternAccountCode_AdvancePayment: "[0-9a-zA-Z/-]+",
  minSizePettyCashReceiveMasterId_AdvancePayment: 0,
  maxSizePettyCashReceiveMasterId_AdvancePayment: 19,
  patternPettyCashReceiveMasterId_AdvancePayment: "",
  minSizePettyCashReturnDocNo_AdvancePayment: 0,
  maxSizePettyCashReturnDocNo_AdvancePayment: 20,
  patternPettyCashReturnDocNo_AdvancePayment: "",
  minSizeAdvId_AdvancePayment: 0,
  maxSizeAdvId_AdvancePayment: 19,
  patternAdvId_AdvancePayment: "",
  minSizeDocumentGroupId_AdvancePayment: 0,
  maxSizeDocumentGroupId_AdvancePayment: 19,
  patternDocumentGroupId_AdvancePayment: "",
  minSizeEmployeeMasterOfficeId_AdvancePayment: 0,
  maxSizeEmployeeMasterOfficeId_AdvancePayment: 19,
  patternEmployeeMasterOfficeId_AdvancePayment: "",
  minSizeEmployeeMasterId_AdvancePayment: 0,
  maxSizeEmployeeMasterId_AdvancePayment: 19,
  patternEmployeeMasterId_AdvancePayment: "",
  minSizeDivisionId_AdvancePayment: 0,
  maxSizeDivisionId_AdvancePayment: 19,
  patternDivisionId_AdvancePayment: "",
  minSizeSectionId_AdvancePayment: 0,
  maxSizeSectionId_AdvancePayment: 19,
  patternSectionId_AdvancePayment: "",
  minSizePcrDivisionId_AdvancePayment: 0,
  maxSizePcrDivisionId_AdvancePayment: 19,
  patternPcrDivisionId_AdvancePayment: "",
  minSizePcrSectionId_AdvancePayment: 0,
  maxSizePcrSectionId_AdvancePayment: 19,
  patternPcrSectionId_AdvancePayment: "",
  minSizeCreateByEmpId_AdvancePayment: 0,
  maxSizeCreateByEmpId_AdvancePayment: 19,
  patternCreateByEmpId_AdvancePayment: "",
  minSizeUpdateByEmpId_AdvancePayment: 0,
  maxSizeUpdateByEmpId_AdvancePayment: 19,
  patternUpdateByEmpId_AdvancePayment: "",
  minSizeJobMasterId_AdvancePayment: 0,
  maxSizeJobMasterId_AdvancePayment: 19,
  patternJobMasterId_AdvancePayment: "",
  minSizePaymentVoucherMasterId_PaymentVouMasterDepositBook: 0,
  maxSizePaymentVoucherMasterId_PaymentVouMasterDepositBook: 19,
  patternPaymentVoucherMasterId_PaymentVouMasterDepositBook: "",
  minSizeDepositBookMasterId_PaymentVouMasterDepositBook: 0,
  maxSizeDepositBookMasterId_PaymentVouMasterDepositBook: 19,
  patternDepositBookMasterId_PaymentVouMasterDepositBook: "",
  minSizeCreditNoteFromSupplierDetailId_CreditNoteFromSupplierDetail: 0,
  maxSizeCreditNoteFromSupplierDetailId_CreditNoteFromSupplierDetail: 19,
  patternCreditNoteFromSupplierDetailId_CreditNoteFromSupplierDetail: "",
  minSizeCreditNoteFromSupplierDetailNo_CreditNoteFromSupplierDetail: 0,
  maxSizeCreditNoteFromSupplierDetailNo_CreditNoteFromSupplierDetail: 10,
  patternCreditNoteFromSupplierDetailNo_CreditNoteFromSupplierDetail: "",
  minSizeDetail_CreditNoteFromSupplierDetail: 0,
  maxSizeDetail_CreditNoteFromSupplierDetail: 255,
  patternDetail_CreditNoteFromSupplierDetail: "",
  minSizeUnitCount_CreditNoteFromSupplierDetail: 0,
  maxSizeUnitCount_CreditNoteFromSupplierDetail: 150,
  patternUnitCount_CreditNoteFromSupplierDetail: "",
  minSizeQuantity_CreditNoteFromSupplierDetail: 0,
  maxSizeQuantity_CreditNoteFromSupplierDetail: 26,
  patternQuantity_CreditNoteFromSupplierDetail: "",
  minSizeUnitPrice_CreditNoteFromSupplierDetail: 0,
  maxSizeUnitPrice_CreditNoteFromSupplierDetail: 26,
  patternUnitPrice_CreditNoteFromSupplierDetail: "",
  minSizeTotal_CreditNoteFromSupplierDetail: 0,
  maxSizeTotal_CreditNoteFromSupplierDetail: 26,
  patternTotal_CreditNoteFromSupplierDetail: "",
  minSizeCompanyCode_CreditNoteFromSupplierDetail: 0,
  maxSizeCompanyCode_CreditNoteFromSupplierDetail: 20,
  patternCompanyCode_CreditNoteFromSupplierDetail: "[0-9a-zA-Z/-]+",
  minSizeSerialNo_CreditNoteFromSupplierDetail: 0,
  maxSizeSerialNo_CreditNoteFromSupplierDetail: 255,
  patternSerialNo_CreditNoteFromSupplierDetail: "",
  minSizeCost_CreditNoteFromSupplierDetail: 0,
  maxSizeCost_CreditNoteFromSupplierDetail: 26,
  patternCost_CreditNoteFromSupplierDetail: "",
  minSizeProdConvFactor_CreditNoteFromSupplierDetail: 0,
  maxSizeProdConvFactor_CreditNoteFromSupplierDetail: 26,
  patternProdConvFactor_CreditNoteFromSupplierDetail: "",
  minSizeProdBaseQty_CreditNoteFromSupplierDetail: 0,
  maxSizeProdBaseQty_CreditNoteFromSupplierDetail: 26,
  patternProdBaseQty_CreditNoteFromSupplierDetail: "",
  minSizeIsReturn_CreditNoteFromSupplierDetail: 0,
  maxSizeIsReturn_CreditNoteFromSupplierDetail: 2,
  patternIsReturn_CreditNoteFromSupplierDetail: "",
  minSizeSerialNoText_CreditNoteFromSupplierDetail: 0,
  maxSizeSerialNoText_CreditNoteFromSupplierDetail: 9999,
  patternSerialNoText_CreditNoteFromSupplierDetail: "",
  minSizeProdDescription_CreditNoteFromSupplierDetail: 0,
  maxSizeProdDescription_CreditNoteFromSupplierDetail: 150,
  patternProdDescription_CreditNoteFromSupplierDetail: "",
  minSizeProductReceiveDocNo_CreditNoteFromSupplierDetail: 0,
  maxSizeProductReceiveDocNo_CreditNoteFromSupplierDetail: 20,
  patternProductReceiveDocNo_CreditNoteFromSupplierDetail: "",
  minSizeProductReceiveDocIndex_CreditNoteFromSupplierDetail: 0,
  maxSizeProductReceiveDocIndex_CreditNoteFromSupplierDetail: 10,
  patternProductReceiveDocIndex_CreditNoteFromSupplierDetail: "",
  minSizeProductMaster1Id_CreditNoteFromSupplierDetail: 0,
  maxSizeProductMaster1Id_CreditNoteFromSupplierDetail: 19,
  patternProductMaster1Id_CreditNoteFromSupplierDetail: "",
  minSizeWarehouseId_CreditNoteFromSupplierDetail: 0,
  maxSizeWarehouseId_CreditNoteFromSupplierDetail: 19,
  patternWarehouseId_CreditNoteFromSupplierDetail: "",
  minSizeUnitOfMeasureId1_CreditNoteFromSupplierDetail: 0,
  maxSizeUnitOfMeasureId1_CreditNoteFromSupplierDetail: 19,
  patternUnitOfMeasureId1_CreditNoteFromSupplierDetail: "",
  minSizeUnitOfMeasureId2_CreditNoteFromSupplierDetail: 0,
  maxSizeUnitOfMeasureId2_CreditNoteFromSupplierDetail: 19,
  patternUnitOfMeasureId2_CreditNoteFromSupplierDetail: "",
  minSizeCreditNoteSupplierMasterId_CreditNoteFromSupplierDetail: 0,
  maxSizeCreditNoteSupplierMasterId_CreditNoteFromSupplierDetail: 19,
  patternCreditNoteSupplierMasterId_CreditNoteFromSupplierDetail: "",
  minSizeCreditType_CreditNoteFromSupplierDetail: 0,
  maxSizeCreditType_CreditNoteFromSupplierDetail: 50,
  patternCreditType_CreditNoteFromSupplierDetail: "",
  minSizeServiceMasterId_CreditNoteFromSupplierDetail: 0,
  maxSizeServiceMasterId_CreditNoteFromSupplierDetail: 19,
  patternServiceMasterId_CreditNoteFromSupplierDetail: "",
  minSizeLotNo_CreditNoteFromSupplierDetail: 0,
  maxSizeLotNo_CreditNoteFromSupplierDetail: 50,
  patternLotNo_CreditNoteFromSupplierDetail: "",
  minSizeExpireDate_CreditNoteFromSupplierDetail: 0,
  maxSizeExpireDate_CreditNoteFromSupplierDetail: 50,
  patternExpireDate_CreditNoteFromSupplierDetail: "",
  minSizeLotMasterId_CreditNoteFromSupplierDetail: 0,
  maxSizeLotMasterId_CreditNoteFromSupplierDetail: 19,
  patternLotMasterId_CreditNoteFromSupplierDetail: "",
  minSizeVat_CreditNoteFromSupplierDetail: 0,
  maxSizeVat_CreditNoteFromSupplierDetail: 2,
  patternVat_CreditNoteFromSupplierDetail: "",
  minSizeRcvServiceDetailId_RcvServiceDetail: 0,
  maxSizeRcvServiceDetailId_RcvServiceDetail: 19,
  patternRcvServiceDetailId_RcvServiceDetail: "",
  minSizeRcvServiceDetailNo_RcvServiceDetail: 0,
  maxSizeRcvServiceDetailNo_RcvServiceDetail: 10,
  patternRcvServiceDetailNo_RcvServiceDetail: "",
  minSizeServiceQty_RcvServiceDetail: 0,
  maxSizeServiceQty_RcvServiceDetail: 26,
  patternServiceQty_RcvServiceDetail: "",
  minSizeServiceCn_RcvServiceDetail: 0,
  maxSizeServiceCn_RcvServiceDetail: 26,
  patternServiceCn_RcvServiceDetail: "",
  minSizeServiceUnit_RcvServiceDetail: 0,
  maxSizeServiceUnit_RcvServiceDetail: 100,
  patternServiceUnit_RcvServiceDetail: "",
  minSizeServicePrice_RcvServiceDetail: 0,
  maxSizeServicePrice_RcvServiceDetail: 26,
  patternServicePrice_RcvServiceDetail: "",
  minSizeServiceDiscRate_RcvServiceDetail: 0,
  maxSizeServiceDiscRate_RcvServiceDetail: 26,
  patternServiceDiscRate_RcvServiceDetail: "",
  minSizeServiceDiscAmt_RcvServiceDetail: 0,
  maxSizeServiceDiscAmt_RcvServiceDetail: 26,
  patternServiceDiscAmt_RcvServiceDetail: "",
  minSizeServiceNetPrice_RcvServiceDetail: 0,
  maxSizeServiceNetPrice_RcvServiceDetail: 26,
  patternServiceNetPrice_RcvServiceDetail: "",
  minSizePoDocNo_RcvServiceDetail: 0,
  maxSizePoDocNo_RcvServiceDetail: 20,
  patternPoDocNo_RcvServiceDetail: "",
  minSizeQuDocNo_RcvServiceDetail: 0,
  maxSizeQuDocNo_RcvServiceDetail: 20,
  patternQuDocNo_RcvServiceDetail: "",
  minSizeServiceVat_RcvServiceDetail: 0,
  maxSizeServiceVat_RcvServiceDetail: 2,
  patternServiceVat_RcvServiceDetail: "",
  minSizePoDocIndex_RcvServiceDetail: 0,
  maxSizePoDocIndex_RcvServiceDetail: 10,
  patternPoDocIndex_RcvServiceDetail: "",
  minSizeServiceDiscRate0_RcvServiceDetail: 0,
  maxSizeServiceDiscRate0_RcvServiceDetail: 20,
  patternServiceDiscRate0_RcvServiceDetail: "",
  minSizeCompanyCode_RcvServiceDetail: 0,
  maxSizeCompanyCode_RcvServiceDetail: 20,
  patternCompanyCode_RcvServiceDetail: "[0-9a-zA-Z/-]+",
  minSizeRcvServiceItemDetail_RcvServiceDetail: 0,
  maxSizeRcvServiceItemDetail_RcvServiceDetail: 9999,
  patternRcvServiceItemDetail_RcvServiceDetail: "",
  minSizeServiceMasterId_RcvServiceDetail: 0,
  maxSizeServiceMasterId_RcvServiceDetail: 19,
  patternServiceMasterId_RcvServiceDetail: "",
  minSizeRcvServiceMasterId_RcvServiceDetail: 0,
  maxSizeRcvServiceMasterId_RcvServiceDetail: 19,
  patternRcvServiceMasterId_RcvServiceDetail: "",
  minSizeServiceName_RcvServiceDetail: 0,
  maxSizeServiceName_RcvServiceDetail: 500,
  patternServiceName_RcvServiceDetail: "",
  minSizePaymentVoucherMasterId_PaymentVoucherMaster: 0,
  maxSizePaymentVoucherMasterId_PaymentVoucherMaster: 19,
  patternPaymentVoucherMasterId_PaymentVoucherMaster: "",
  minSizePayVoucherDocNo_PaymentVoucherMaster: 0,
  maxSizePayVoucherDocNo_PaymentVoucherMaster: 30,
  patternPayVoucherDocNo_PaymentVoucherMaster: "",
  minSizePayVoucherDocDate_PaymentVoucherMaster: 0,
  maxSizePayVoucherDocDate_PaymentVoucherMaster: 20,
  patternPayVoucherDocDate_PaymentVoucherMaster: "",
  minSizePayVoucherPaymentDate_PaymentVoucherMaster: 0,
  maxSizePayVoucherPaymentDate_PaymentVoucherMaster: 20,
  patternPayVoucherPaymentDate_PaymentVoucherMaster: "",
  minSizePayVoucherTotal_PaymentVoucherMaster: 0,
  maxSizePayVoucherTotal_PaymentVoucherMaster: 26,
  patternPayVoucherTotal_PaymentVoucherMaster: "",
  minSizePayVoucherWitholdingTax_PaymentVoucherMaster: 0,
  maxSizePayVoucherWitholdingTax_PaymentVoucherMaster: 26,
  patternPayVoucherWitholdingTax_PaymentVoucherMaster: "",
  minSizePayVoucherPlusDiscount_PaymentVoucherMaster: 0,
  maxSizePayVoucherPlusDiscount_PaymentVoucherMaster: 26,
  patternPayVoucherPlusDiscount_PaymentVoucherMaster: "",
  minSizePayVoucherDeductDiscount_PaymentVoucherMaster: 0,
  maxSizePayVoucherDeductDiscount_PaymentVoucherMaster: 26,
  patternPayVoucherDeductDiscount_PaymentVoucherMaster: "",
  minSizePayVoucherNetAmt_PaymentVoucherMaster: 0,
  maxSizePayVoucherNetAmt_PaymentVoucherMaster: 26,
  patternPayVoucherNetAmt_PaymentVoucherMaster: "",
  minSizePayVoucherStatus_PaymentVoucherMaster: 0,
  maxSizePayVoucherStatus_PaymentVoucherMaster: 2,
  patternPayVoucherStatus_PaymentVoucherMaster: "",
  minSizePayVoucherLinkDoc_PaymentVoucherMaster: 0,
  maxSizePayVoucherLinkDoc_PaymentVoucherMaster: 30,
  patternPayVoucherLinkDoc_PaymentVoucherMaster: "",
  minSizePayVoucherPaymentType_PaymentVoucherMaster: 0,
  maxSizePayVoucherPaymentType_PaymentVoucherMaster: 10,
  patternPayVoucherPaymentType_PaymentVoucherMaster: "",
  minSizePayVoucherPayAmt_PaymentVoucherMaster: 0,
  maxSizePayVoucherPayAmt_PaymentVoucherMaster: 26,
  patternPayVoucherPayAmt_PaymentVoucherMaster: "",
  minSizeCharge_PaymentVoucherMaster: 0,
  maxSizeCharge_PaymentVoucherMaster: 26,
  patternCharge_PaymentVoucherMaster: "",
  minSizePayVoucherBackRemark_PaymentVoucherMaster: 0,
  maxSizePayVoucherBackRemark_PaymentVoucherMaster: 255,
  patternPayVoucherBackRemark_PaymentVoucherMaster: "",
  minSizePayVoucherChqNo_PaymentVoucherMaster: 0,
  maxSizePayVoucherChqNo_PaymentVoucherMaster: 20,
  patternPayVoucherChqNo_PaymentVoucherMaster: "",
  minSizePayVoucherChqDate_PaymentVoucherMaster: 0,
  maxSizePayVoucherChqDate_PaymentVoucherMaster: 20,
  patternPayVoucherChqDate_PaymentVoucherMaster: "",
  minSizeCreateOn_PaymentVoucherMaster: 0,
  maxSizeCreateOn_PaymentVoucherMaster: 20,
  patternCreateOn_PaymentVoucherMaster: "",
  minSizeUpdateOn_PaymentVoucherMaster: 0,
  maxSizeUpdateOn_PaymentVoucherMaster: 20,
  patternUpdateOn_PaymentVoucherMaster: "",
  minSizeVoucherNo_PaymentVoucherMaster: 0,
  maxSizeVoucherNo_PaymentVoucherMaster: 20,
  patternVoucherNo_PaymentVoucherMaster: "",
  minSizeAccountCode_PaymentVoucherMaster: 0,
  maxSizeAccountCode_PaymentVoucherMaster: 20,
  patternAccountCode_PaymentVoucherMaster: "[0-9a-zA-Z/-]+",
  minSizeDepositAcccode_PaymentVoucherMaster: 0,
  maxSizeDepositAcccode_PaymentVoucherMaster: 20,
  patternDepositAcccode_PaymentVoucherMaster: "[0-9a-zA-Z/-]+",
  minSizeCompanyCode_PaymentVoucherMaster: 0,
  maxSizeCompanyCode_PaymentVoucherMaster: 20,
  patternCompanyCode_PaymentVoucherMaster: "[0-9a-zA-Z/-]+",
  minSizeVoucherChequeDocNo_PaymentVoucherMaster: 0,
  maxSizeVoucherChequeDocNo_PaymentVoucherMaster: 20,
  patternVoucherChequeDocNo_PaymentVoucherMaster: "",
  minSizeExchangeRate_PaymentVoucherMaster: 0,
  maxSizeExchangeRate_PaymentVoucherMaster: 26,
  patternExchangeRate_PaymentVoucherMaster: "",
  minSizeExchageRateDate_PaymentVoucherMaster: 0,
  maxSizeExchageRateDate_PaymentVoucherMaster: 20,
  patternExchageRateDate_PaymentVoucherMaster: "",
  minSizeAStatus_PaymentVoucherMaster: 0,
  maxSizeAStatus_PaymentVoucherMaster: 2,
  patternAStatus_PaymentVoucherMaster: "",
  minSizeReviseNo_PaymentVoucherMaster: 0,
  maxSizeReviseNo_PaymentVoucherMaster: 10,
  patternReviseNo_PaymentVoucherMaster: "",
  minSizeIncludeVat_PaymentVoucherMaster: 0,
  maxSizeIncludeVat_PaymentVoucherMaster: 2,
  patternIncludeVat_PaymentVoucherMaster: "",
  minSizeChequeRemark_PaymentVoucherMaster: 0,
  maxSizeChequeRemark_PaymentVoucherMaster: 9999,
  patternChequeRemark_PaymentVoucherMaster: "",
  minSizeWorks_PaymentVoucherMaster: 0,
  maxSizeWorks_PaymentVoucherMaster: 2,
  patternWorks_PaymentVoucherMaster: "",
  minSizeBoxNo_PaymentVoucherMaster: 0,
  maxSizeBoxNo_PaymentVoucherMaster: 10,
  patternBoxNo_PaymentVoucherMaster: "",
  minSizeReferCode_PaymentVoucherMaster: 0,
  maxSizeReferCode_PaymentVoucherMaster: 50,
  patternReferCode_PaymentVoucherMaster: "[0-9a-zA-Z/-]+",
  minSizeRegisControlRemark_PaymentVoucherMaster: 0,
  maxSizeRegisControlRemark_PaymentVoucherMaster: 255,
  patternRegisControlRemark_PaymentVoucherMaster: "",
  minSizeRegisControlDescription_PaymentVoucherMaster: 0,
  maxSizeRegisControlDescription_PaymentVoucherMaster: 255,
  patternRegisControlDescription_PaymentVoucherMaster: "",
  minSizeBorrowDate_PaymentVoucherMaster: 0,
  maxSizeBorrowDate_PaymentVoucherMaster: 20,
  patternBorrowDate_PaymentVoucherMaster: "",
  minSizeBorrower_PaymentVoucherMaster: 0,
  maxSizeBorrower_PaymentVoucherMaster: 150,
  patternBorrower_PaymentVoucherMaster: "",
  minSizeBorrowSection_PaymentVoucherMaster: 0,
  maxSizeBorrowSection_PaymentVoucherMaster: 255,
  patternBorrowSection_PaymentVoucherMaster: "",
  minSizeCatalogue_PaymentVoucherMaster: 0,
  maxSizeCatalogue_PaymentVoucherMaster: 9999,
  patternCatalogue_PaymentVoucherMaster: "",
  minSizeGiver_PaymentVoucherMaster: 0,
  maxSizeGiver_PaymentVoucherMaster: 150,
  patternGiver_PaymentVoucherMaster: "",
  minSizeReturner_PaymentVoucherMaster: 0,
  maxSizeReturner_PaymentVoucherMaster: 150,
  patternReturner_PaymentVoucherMaster: "",
  minSizeReturnDate_PaymentVoucherMaster: 0,
  maxSizeReturnDate_PaymentVoucherMaster: 20,
  patternReturnDate_PaymentVoucherMaster: "",
  minSizeReceiver_PaymentVoucherMaster: 0,
  maxSizeReceiver_PaymentVoucherMaster: 150,
  patternReceiver_PaymentVoucherMaster: "",
  minSizeCurrencyId_PaymentVoucherMaster: 0,
  maxSizeCurrencyId_PaymentVoucherMaster: 19,
  patternCurrencyId_PaymentVoucherMaster: "",
  minSizeDocumentgroupId_PaymentVoucherMaster: 0,
  maxSizeDocumentgroupId_PaymentVoucherMaster: 19,
  patternDocumentgroupId_PaymentVoucherMaster: "",
  minSizeSupplierMasterId_PaymentVoucherMaster: 0,
  maxSizeSupplierMasterId_PaymentVoucherMaster: 19,
  patternSupplierMasterId_PaymentVoucherMaster: "",
  minSizeEmployeeMasterId_PaymentVoucherMaster: 0,
  maxSizeEmployeeMasterId_PaymentVoucherMaster: 19,
  patternEmployeeMasterId_PaymentVoucherMaster: "",
  minSizeDivisionId_PaymentVoucherMaster: 0,
  maxSizeDivisionId_PaymentVoucherMaster: 19,
  patternDivisionId_PaymentVoucherMaster: "",
  minSizeSectionId_PaymentVoucherMaster: 0,
  maxSizeSectionId_PaymentVoucherMaster: 19,
  patternSectionId_PaymentVoucherMaster: "",
  minSizeCreateByEmpId_PaymentVoucherMaster: 0,
  maxSizeCreateByEmpId_PaymentVoucherMaster: 19,
  patternCreateByEmpId_PaymentVoucherMaster: "",
  minSizeUpdateByEmpId_PaymentVoucherMaster: 0,
  maxSizeUpdateByEmpId_PaymentVoucherMaster: 19,
  patternUpdateByEmpId_PaymentVoucherMaster: "",
  minSizePvRemark_PaymentVoucherMaster: 0,
  maxSizePvRemark_PaymentVoucherMaster: 250,
  patternPvRemark_PaymentVoucherMaster: "",
  minSizePayVoucherRemark_PaymentVoucherMaster: 0,
  maxSizePayVoucherRemark_PaymentVoucherMaster: 255,
  patternPayVoucherRemark_PaymentVoucherMaster: "",
  minSizeCheckDocumentApprove_PaymentVoucherMaster: 0,
  maxSizeCheckDocumentApprove_PaymentVoucherMaster: 2,
  patternCheckDocumentApprove_PaymentVoucherMaster: "",
  minSizeReferenNo_PaymentVoucherMaster: 0,
  maxSizeReferenNo_PaymentVoucherMaster: 20,
  patternReferenNo_PaymentVoucherMaster: "",
  minSizePayReqCode_PaymentVoucherMaster: 0,
  maxSizePayReqCode_PaymentVoucherMaster: 20,
  patternPayReqCode_PaymentVoucherMaster: "[0-9a-zA-Z/-]+",
  minSizePayReqName_PaymentVoucherMaster: 0,
  maxSizePayReqName_PaymentVoucherMaster: 150,
  patternPayReqName_PaymentVoucherMaster: "",
  minSizeContactNumber_PaymentVoucherMaster: 0,
  maxSizeContactNumber_PaymentVoucherMaster: 10,
  patternContactNumber_PaymentVoucherMaster: "",
  minSizeUtilitiesExpense_PaymentVoucherMaster: 0,
  maxSizeUtilitiesExpense_PaymentVoucherMaster: 1,
  patternUtilitiesExpense_PaymentVoucherMaster: "",
  minSizeContractNo_PaymentVoucherMaster: 0,
  maxSizeContractNo_PaymentVoucherMaster: 20,
  patternContractNo_PaymentVoucherMaster: "",
  minSizeDivisionEmpId_PaymentVoucherMaster: 0,
  maxSizeDivisionEmpId_PaymentVoucherMaster: 19,
  patternDivisionEmpId_PaymentVoucherMaster: "",
  minSizeSectionEmpId_PaymentVoucherMaster: 0,
  maxSizeSectionEmpId_PaymentVoucherMaster: 19,
  patternSectionEmpId_PaymentVoucherMaster: "",
  minSizeWhtGroup_PaymentVoucherMaster: 0,
  maxSizeWhtGroup_PaymentVoucherMaster: 10,
  patternWhtGroup_PaymentVoucherMaster: "",
  minSizeAppend_PaymentVoucherMaster: 0,
  maxSizeAppend_PaymentVoucherMaster: 1000,
  patternAppend_PaymentVoucherMaster: "",
  minSizePayVoucherBeforeTotal_PaymentVoucherMaster: 0,
  maxSizePayVoucherBeforeTotal_PaymentVoucherMaster: 26,
  patternPayVoucherBeforeTotal_PaymentVoucherMaster: "",
  minSizePayVoucherVatAmt_PaymentVoucherMaster: 0,
  maxSizePayVoucherVatAmt_PaymentVoucherMaster: 26,
  patternPayVoucherVatAmt_PaymentVoucherMaster: "",
  minSizePrintStatus_PaymentVoucherMaster: 0,
  maxSizePrintStatus_PaymentVoucherMaster: 2,
  patternPrintStatus_PaymentVoucherMaster: "",
  minSizeDivisionNo_PaymentVoucherMaster: 0,
  maxSizeDivisionNo_PaymentVoucherMaster: 10,
  patternDivisionNo_PaymentVoucherMaster: "",
  minSizeDivisionName_PaymentVoucherMaster: 0,
  maxSizeDivisionName_PaymentVoucherMaster: 50,
  patternDivisionName_PaymentVoucherMaster: "",
  minSizePayVoucherPayBankAmt_PaymentVoucherMaster: 0,
  maxSizePayVoucherPayBankAmt_PaymentVoucherMaster: 26,
  patternPayVoucherPayBankAmt_PaymentVoucherMaster: "",
  minSizePayVoucherPayChqAmt_PaymentVoucherMaster: 0,
  maxSizePayVoucherPayChqAmt_PaymentVoucherMaster: 26,
  patternPayVoucherPayChqAmt_PaymentVoucherMaster: "",
  minSizeBeforeStatus_PaymentVoucherMaster: 0,
  maxSizeBeforeStatus_PaymentVoucherMaster: 3,
  patternBeforeStatus_PaymentVoucherMaster: "",
  minSizeDivisionSuppDetailId_PaymentVoucherMaster: 0,
  maxSizeDivisionSuppDetailId_PaymentVoucherMaster: 19,
  patternDivisionSuppDetailId_PaymentVoucherMaster: "",
  minSizeSupplierName_PaymentVoucherMaster: 0,
  maxSizeSupplierName_PaymentVoucherMaster: 255,
  patternSupplierName_PaymentVoucherMaster: "",
  minSizePlusListOther_PaymentVoucherMaster: 0,
  maxSizePlusListOther_PaymentVoucherMaster: 26,
  patternPlusListOther_PaymentVoucherMaster: "",
  minSizeDeductListOther_PaymentVoucherMaster: 0,
  maxSizeDeductListOther_PaymentVoucherMaster: 26,
  patternDeductListOther_PaymentVoucherMaster: "",
  minSizePettyCashReceiveMasterId_PettyCashReceiveMaster: 0,
  maxSizePettyCashReceiveMasterId_PettyCashReceiveMaster: 19,
  patternPettyCashReceiveMasterId_PettyCashReceiveMaster: "",
  minSizePettyCashReceiveDocNo_PettyCashReceiveMaster: 0,
  maxSizePettyCashReceiveDocNo_PettyCashReceiveMaster: 20,
  patternPettyCashReceiveDocNo_PettyCashReceiveMaster: "",
  minSizePettyCashReceiveDocDate_PettyCashReceiveMaster: 0,
  maxSizePettyCashReceiveDocDate_PettyCashReceiveMaster: 20,
  patternPettyCashReceiveDocDate_PettyCashReceiveMaster: "",
  minSizeDocType_PettyCashReceiveMaster: 0,
  maxSizeDocType_PettyCashReceiveMaster: 5,
  patternDocType_PettyCashReceiveMaster: "",
  minSizePettyCashTotal_PettyCashReceiveMaster: 0,
  maxSizePettyCashTotal_PettyCashReceiveMaster: 26,
  patternPettyCashTotal_PettyCashReceiveMaster: "",
  minSizePettyCashRemark_PettyCashReceiveMaster: 0,
  maxSizePettyCashRemark_PettyCashReceiveMaster: 255,
  patternPettyCashRemark_PettyCashReceiveMaster: "",
  minSizePettyCashStatus_PettyCashReceiveMaster: 0,
  maxSizePettyCashStatus_PettyCashReceiveMaster: 2,
  patternPettyCashStatus_PettyCashReceiveMaster: "",
  minSizePettyCashLinkDocNo_PettyCashReceiveMaster: 0,
  maxSizePettyCashLinkDocNo_PettyCashReceiveMaster: 50,
  patternPettyCashLinkDocNo_PettyCashReceiveMaster: "",
  minSizePettyCashPaymentType_PettyCashReceiveMaster: 0,
  maxSizePettyCashPaymentType_PettyCashReceiveMaster: 2,
  patternPettyCashPaymentType_PettyCashReceiveMaster: "",
  minSizePayVoucherChqNo_PettyCashReceiveMaster: 0,
  maxSizePayVoucherChqNo_PettyCashReceiveMaster: 20,
  patternPayVoucherChqNo_PettyCashReceiveMaster: "",
  minSizePettyCashPayAmt_PettyCashReceiveMaster: 0,
  maxSizePettyCashPayAmt_PettyCashReceiveMaster: 26,
  patternPettyCashPayAmt_PettyCashReceiveMaster: "",
  minSizeCharge_PettyCashReceiveMaster: 0,
  maxSizeCharge_PettyCashReceiveMaster: 26,
  patternCharge_PettyCashReceiveMaster: "",
  minSizePettyCashBankRemark_PettyCashReceiveMaster: 0,
  maxSizePettyCashBankRemark_PettyCashReceiveMaster: 255,
  patternPettyCashBankRemark_PettyCashReceiveMaster: "",
  minSizePettyCashChqNo_PettyCashReceiveMaster: 0,
  maxSizePettyCashChqNo_PettyCashReceiveMaster: 20,
  patternPettyCashChqNo_PettyCashReceiveMaster: "",
  minSizePettyCashChqDate_PettyCashReceiveMaster: 0,
  maxSizePettyCashChqDate_PettyCashReceiveMaster: 20,
  patternPettyCashChqDate_PettyCashReceiveMaster: "",
  minSizeCreateOn_PettyCashReceiveMaster: 0,
  maxSizeCreateOn_PettyCashReceiveMaster: 20,
  patternCreateOn_PettyCashReceiveMaster: "",
  minSizeUpdateOn_PettyCashReceiveMaster: 0,
  maxSizeUpdateOn_PettyCashReceiveMaster: 20,
  patternUpdateOn_PettyCashReceiveMaster: "",
  minSizeVoucherNo_PettyCashReceiveMaster: 0,
  maxSizeVoucherNo_PettyCashReceiveMaster: 20,
  patternVoucherNo_PettyCashReceiveMaster: "",
  minSizeAccountCode_PettyCashReceiveMaster: 0,
  maxSizeAccountCode_PettyCashReceiveMaster: 20,
  patternAccountCode_PettyCashReceiveMaster: "[0-9a-zA-Z/-]+",
  minSizeCompanyCode_PettyCashReceiveMaster: 0,
  maxSizeCompanyCode_PettyCashReceiveMaster: 20,
  patternCompanyCode_PettyCashReceiveMaster: "[0-9a-zA-Z/-]+",
  minSizeAstatus_PettyCashReceiveMaster: 0,
  maxSizeAstatus_PettyCashReceiveMaster: 2,
  patternAstatus_PettyCashReceiveMaster: "",
  minSizeDivisionNo_PettyCashReceiveMaster: 0,
  maxSizeDivisionNo_PettyCashReceiveMaster: 10,
  patternDivisionNo_PettyCashReceiveMaster: "",
  minSizeDivisionName_PettyCashReceiveMaster: 0,
  maxSizeDivisionName_PettyCashReceiveMaster: 50,
  patternDivisionName_PettyCashReceiveMaster: "",
  minSizeDivisionAddress_PettyCashReceiveMaster: 0,
  maxSizeDivisionAddress_PettyCashReceiveMaster: 255,
  patternDivisionAddress_PettyCashReceiveMaster: "",
  minSizeAudited_PettyCashReceiveMaster: 0,
  maxSizeAudited_PettyCashReceiveMaster: 2,
  patternAudited_PettyCashReceiveMaster: "",
  minSizePrintStatus_PettyCashReceiveMaster: 0,
  maxSizePrintStatus_PettyCashReceiveMaster: 2,
  patternPrintStatus_PettyCashReceiveMaster: "",
  minSizePettyCashType_PettyCashReceiveMaster: 0,
  maxSizePettyCashType_PettyCashReceiveMaster: 20,
  patternPettyCashType_PettyCashReceiveMaster: "",
  minSizeDocumentGroupId_PettyCashReceiveMaster: 0,
  maxSizeDocumentGroupId_PettyCashReceiveMaster: 19,
  patternDocumentGroupId_PettyCashReceiveMaster: "",
  minSizeEmpMasterId_PettyCashReceiveMaster: 0,
  maxSizeEmpMasterId_PettyCashReceiveMaster: 19,
  patternEmpMasterId_PettyCashReceiveMaster: "",
  minSizeEmpdivisionId_PettyCashReceiveMaster: 0,
  maxSizeEmpdivisionId_PettyCashReceiveMaster: 19,
  patternEmpdivisionId_PettyCashReceiveMaster: "",
  minSizeEmpSectionId_PettyCashReceiveMaster: 0,
  maxSizeEmpSectionId_PettyCashReceiveMaster: 19,
  patternEmpSectionId_PettyCashReceiveMaster: "",
  minSizeCreateByEmpId_PettyCashReceiveMaster: 0,
  maxSizeCreateByEmpId_PettyCashReceiveMaster: 19,
  patternCreateByEmpId_PettyCashReceiveMaster: "",
  minSizeUpdateByEmpId_PettyCashReceiveMaster: 0,
  maxSizeUpdateByEmpId_PettyCashReceiveMaster: 19,
  patternUpdateByEmpId_PettyCashReceiveMaster: "",
  minSizeDepositBookMasterId_PettyCashReceiveMaster: 0,
  maxSizeDepositBookMasterId_PettyCashReceiveMaster: 19,
  patternDepositBookMasterId_PettyCashReceiveMaster: "",
  minSizeJobMasterId_PettyCashReceiveMaster: 0,
  maxSizeJobMasterId_PettyCashReceiveMaster: 19,
  patternJobMasterId_PettyCashReceiveMaster: "",
  minSizeBankAmt_PettyCashReceiveMaster: 0,
  maxSizeBankAmt_PettyCashReceiveMaster: 26,
  patternBankAmt_PettyCashReceiveMaster: "",
  minSizeChqAmt_PettyCashReceiveMaster: 0,
  maxSizeChqAmt_PettyCashReceiveMaster: 26,
  patternChqAmt_PettyCashReceiveMaster: "",
  minSizeChqDepositBookId_PettyCashReceiveMaster: 0,
  maxSizeChqDepositBookId_PettyCashReceiveMaster: 19,
  patternChqDepositBookId_PettyCashReceiveMaster: "",
  minSizeBillingPaymentDetailId_BillingPaymentDetail: 0,
  maxSizeBillingPaymentDetailId_BillingPaymentDetail: 19,
  patternBillingPaymentDetailId_BillingPaymentDetail: "",
  minSizeDetailNo_BillingPaymentDetail: 0,
  maxSizeDetailNo_BillingPaymentDetail: 10,
  patternDetailNo_BillingPaymentDetail: "",
  minSizeProdReceiveInvoiceDocAmt_BillingPaymentDetail: 0,
  maxSizeProdReceiveInvoiceDocAmt_BillingPaymentDetail: 26,
  patternProdReceiveInvoiceDocAmt_BillingPaymentDetail: "",
  minSizeProdReceiveInvoiceDocRemain_BillingPaymentDetail: 0,
  maxSizeProdReceiveInvoiceDocRemain_BillingPaymentDetail: 26,
  patternProdReceiveInvoiceDocRemain_BillingPaymentDetail: "",
  minSizeBillingPayAmt_BillingPaymentDetail: 0,
  maxSizeBillingPayAmt_BillingPaymentDetail: 26,
  patternBillingPayAmt_BillingPaymentDetail: "",
  minSizeCompanyCode_BillingPaymentDetail: 0,
  maxSizeCompanyCode_BillingPaymentDetail: 20,
  patternCompanyCode_BillingPaymentDetail: "[0-9a-zA-Z/-]+",
  minSizeType_BillingPaymentDetail: 0,
  maxSizeType_BillingPaymentDetail: 3,
  patternType_BillingPaymentDetail: "",
  minSizeProductReceiveInvoiceMasterId_BillingPaymentDetail: 0,
  maxSizeProductReceiveInvoiceMasterId_BillingPaymentDetail: 19,
  patternProductReceiveInvoiceMasterId_BillingPaymentDetail: "",
  minSizeBillingPaymentMasterId_BillingPaymentDetail: 0,
  maxSizeBillingPaymentMasterId_BillingPaymentDetail: 19,
  patternBillingPaymentMasterId_BillingPaymentDetail: "",
  minSizeRcvServiceMasterId_BillingPaymentDetail: 0,
  maxSizeRcvServiceMasterId_BillingPaymentDetail: 19,
  patternRcvServiceMasterId_BillingPaymentDetail: "",
  minSizeReferrenceDocNo_BillingPaymentDetail: 0,
  maxSizeReferrenceDocNo_BillingPaymentDetail: 50,
  patternReferrenceDocNo_BillingPaymentDetail: "",
  minSizeReferrenceDocDate_BillingPaymentDetail: 0,
  maxSizeReferrenceDocDate_BillingPaymentDetail: 20,
  patternReferrenceDocDate_BillingPaymentDetail: "",
  minSizePvPayAmt_BillingPaymentDetail: 0,
  maxSizePvPayAmt_BillingPaymentDetail: 26,
  patternPvPayAmt_BillingPaymentDetail: "",
  minSizeCreditNoteSupplierMasteriId_BillingPaymentDetail: 0,
  maxSizeCreditNoteSupplierMasteriId_BillingPaymentDetail: 19,
  patternCreditNoteSupplierMasteriId_BillingPaymentDetail: "",
  minSizeOtherExpenseId_BillingPaymentDetail: 0,
  maxSizeOtherExpenseId_BillingPaymentDetail: 19,
  patternOtherExpenseId_BillingPaymentDetail: "",
  minSizeSupplierOpeningMasterId_BillingPaymentDetail: 0,
  maxSizeSupplierOpeningMasterId_BillingPaymentDetail: 19,
  patternSupplierOpeningMasterId_BillingPaymentDetail: "",
  minSizeDocType_BillingPaymentDetail: 0,
  maxSizeDocType_BillingPaymentDetail: 5,
  patternDocType_BillingPaymentDetail: "",
  minSizeBillDocNo_BillingPaymentDetail: 0,
  maxSizeBillDocNo_BillingPaymentDetail: 30,
  patternBillDocNo_BillingPaymentDetail: "",
  minSizeBillDocDate_BillingPaymentDetail: 0,
  maxSizeBillDocDate_BillingPaymentDetail: 30,
  patternBillDocDate_BillingPaymentDetail: "",
  minSizeExchangeRate_BillingPaymentDetail: 0,
  maxSizeExchangeRate_BillingPaymentDetail: 26,
  patternExchangeRate_BillingPaymentDetail: "",
  minSizeExchageRateDate_BillingPaymentDetail: 0,
  maxSizeExchageRateDate_BillingPaymentDetail: 255,
  patternExchageRateDate_BillingPaymentDetail: "",
  minSizeCreditNoteSupplierMasterId_CreditNoteFromSupplierMaster: 0,
  maxSizeCreditNoteSupplierMasterId_CreditNoteFromSupplierMaster: 19,
  patternCreditNoteSupplierMasterId_CreditNoteFromSupplierMaster: "",
  minSizeCreditNoteSupplierDocNo_CreditNoteFromSupplierMaster: 0,
  maxSizeCreditNoteSupplierDocNo_CreditNoteFromSupplierMaster: 20,
  patternCreditNoteSupplierDocNo_CreditNoteFromSupplierMaster: "",
  minSizeCreditNoteSupplierDocDate_CreditNoteFromSupplierMaster: 0,
  maxSizeCreditNoteSupplierDocDate_CreditNoteFromSupplierMaster: 20,
  patternCreditNoteSupplierDocDate_CreditNoteFromSupplierMaster: "",
  minSizeCreditNoteSupplierInvoiceNo_CreditNoteFromSupplierMaster: 0,
  maxSizeCreditNoteSupplierInvoiceNo_CreditNoteFromSupplierMaster: 20,
  patternCreditNoteSupplierInvoiceNo_CreditNoteFromSupplierMaster: "",
  minSizeCreditNoteSupplierInvoiceDate_CreditNoteFromSupplierMaster: 0,
  maxSizeCreditNoteSupplierInvoiceDate_CreditNoteFromSupplierMaster: 20,
  patternCreditNoteSupplierInvoiceDate_CreditNoteFromSupplierMaster: "",
  minSizeDocType_CreditNoteFromSupplierMaster: 0,
  maxSizeDocType_CreditNoteFromSupplierMaster: 2,
  patternDocType_CreditNoteFromSupplierMaster: "",
  minSizeCreditnoteSupplierTotal_CreditNoteFromSupplierMaster: 0,
  maxSizeCreditnoteSupplierTotal_CreditNoteFromSupplierMaster: 26,
  patternCreditnoteSupplierTotal_CreditNoteFromSupplierMaster: "",
  minSizeCreditNoteSupplierVatRate_CreditNoteFromSupplierMaster: 0,
  maxSizeCreditNoteSupplierVatRate_CreditNoteFromSupplierMaster: 26,
  patternCreditNoteSupplierVatRate_CreditNoteFromSupplierMaster: "",
  minSizeCreditNoteSupplierVatAmt_CreditNoteFromSupplierMaster: 0,
  maxSizeCreditNoteSupplierVatAmt_CreditNoteFromSupplierMaster: 26,
  patternCreditNoteSupplierVatAmt_CreditNoteFromSupplierMaster: "",
  minSizeCreditNoteSupplierNetAmt_CreditNoteFromSupplierMaster: 0,
  maxSizeCreditNoteSupplierNetAmt_CreditNoteFromSupplierMaster: 26,
  patternCreditNoteSupplierNetAmt_CreditNoteFromSupplierMaster: "",
  minSizeCreditNoteSupplierRemark_CreditNoteFromSupplierMaster: 0,
  maxSizeCreditNoteSupplierRemark_CreditNoteFromSupplierMaster: 150,
  patternCreditNoteSupplierRemark_CreditNoteFromSupplierMaster: "",
  minSizeCreditNoteSupplierStatus_CreditNoteFromSupplierMaster: 0,
  maxSizeCreditNoteSupplierStatus_CreditNoteFromSupplierMaster: 2,
  patternCreditNoteSupplierStatus_CreditNoteFromSupplierMaster: "",
  minSizeCreditNoteSupplierLinkDocNo_CreditNoteFromSupplierMaster: 0,
  maxSizeCreditNoteSupplierLinkDocNo_CreditNoteFromSupplierMaster: 20,
  patternCreditNoteSupplierLinkDocNo_CreditNoteFromSupplierMaster: "",
  minSizeCreateOn_CreditNoteFromSupplierMaster: 0,
  maxSizeCreateOn_CreditNoteFromSupplierMaster: 20,
  patternCreateOn_CreditNoteFromSupplierMaster: "",
  minSizeUpdateOn_CreditNoteFromSupplierMaster: 0,
  maxSizeUpdateOn_CreditNoteFromSupplierMaster: 20,
  patternUpdateOn_CreditNoteFromSupplierMaster: "",
  minSizeVoucherNo_CreditNoteFromSupplierMaster: 0,
  maxSizeVoucherNo_CreditNoteFromSupplierMaster: 20,
  patternVoucherNo_CreditNoteFromSupplierMaster: "",
  minSizeAccountCode_CreditNoteFromSupplierMaster: 0,
  maxSizeAccountCode_CreditNoteFromSupplierMaster: 20,
  patternAccountCode_CreditNoteFromSupplierMaster: "[0-9a-zA-Z/-]+",
  minSizeCompanyCode_CreditNoteFromSupplierMaster: 0,
  maxSizeCompanyCode_CreditNoteFromSupplierMaster: 20,
  patternCompanyCode_CreditNoteFromSupplierMaster: "[0-9a-zA-Z/-]+",
  minSizeExchangeRate_CreditNoteFromSupplierMaster: 0,
  maxSizeExchangeRate_CreditNoteFromSupplierMaster: 26,
  patternExchangeRate_CreditNoteFromSupplierMaster: "",
  minSizeExchageRateDate_CreditNoteFromSupplierMaster: 0,
  maxSizeExchageRateDate_CreditNoteFromSupplierMaster: 20,
  patternExchageRateDate_CreditNoteFromSupplierMaster: "",
  minSizeAstatus_CreditNoteFromSupplierMaster: 0,
  maxSizeAstatus_CreditNoteFromSupplierMaster: 2,
  patternAstatus_CreditNoteFromSupplierMaster: "",
  minSizeReviseNo_CreditNoteFromSupplierMaster: 0,
  maxSizeReviseNo_CreditNoteFromSupplierMaster: 10,
  patternReviseNo_CreditNoteFromSupplierMaster: "",
  minSizeDivisionNo_CreditNoteFromSupplierMaster: 0,
  maxSizeDivisionNo_CreditNoteFromSupplierMaster: 10,
  patternDivisionNo_CreditNoteFromSupplierMaster: "",
  minSizeDivisionName_CreditNoteFromSupplierMaster: 0,
  maxSizeDivisionName_CreditNoteFromSupplierMaster: 50,
  patternDivisionName_CreditNoteFromSupplierMaster: "",
  minSizeDivisionAddress_CreditNoteFromSupplierMaster: 0,
  maxSizeDivisionAddress_CreditNoteFromSupplierMaster: 255,
  patternDivisionAddress_CreditNoteFromSupplierMaster: "",
  minSizeTaxidNo_CreditNoteFromSupplierMaster: 0,
  maxSizeTaxidNo_CreditNoteFromSupplierMaster: 50,
  patternTaxidNo_CreditNoteFromSupplierMaster: "",
  minSizeVatInclude_CreditNoteFromSupplierMaster: 0,
  maxSizeVatInclude_CreditNoteFromSupplierMaster: 2,
  patternVatInclude_CreditNoteFromSupplierMaster: "",
  minSizeTaxReturnStatus_CreditNoteFromSupplierMaster: 0,
  maxSizeTaxReturnStatus_CreditNoteFromSupplierMaster: 1,
  patternTaxReturnStatus_CreditNoteFromSupplierMaster: "",
  minSizeCurrencyId_CreditNoteFromSupplierMaster: 0,
  maxSizeCurrencyId_CreditNoteFromSupplierMaster: 19,
  patternCurrencyId_CreditNoteFromSupplierMaster: "",
  minSizeDocumentGroupId_CreditNoteFromSupplierMaster: 0,
  maxSizeDocumentGroupId_CreditNoteFromSupplierMaster: 19,
  patternDocumentGroupId_CreditNoteFromSupplierMaster: "",
  minSizeSupplierMasterId_CreditNoteFromSupplierMaster: 0,
  maxSizeSupplierMasterId_CreditNoteFromSupplierMaster: 19,
  patternSupplierMasterId_CreditNoteFromSupplierMaster: "",
  minSizeEmployeeMasterId_CreditNoteFromSupplierMaster: 0,
  maxSizeEmployeeMasterId_CreditNoteFromSupplierMaster: 19,
  patternEmployeeMasterId_CreditNoteFromSupplierMaster: "",
  minSizeDivisionId_CreditNoteFromSupplierMaster: 0,
  maxSizeDivisionId_CreditNoteFromSupplierMaster: 19,
  patternDivisionId_CreditNoteFromSupplierMaster: "",
  minSizeSectionId_CreditNoteFromSupplierMaster: 0,
  maxSizeSectionId_CreditNoteFromSupplierMaster: 19,
  patternSectionId_CreditNoteFromSupplierMaster: "",
  minSizeCreateByEmpId_CreditNoteFromSupplierMaster: 0,
  maxSizeCreateByEmpId_CreditNoteFromSupplierMaster: 19,
  patternCreateByEmpId_CreditNoteFromSupplierMaster: "",
  minSizeUpdateByEmpId_CreditNoteFromSupplierMaster: 0,
  maxSizeUpdateByEmpId_CreditNoteFromSupplierMaster: 19,
  patternUpdateByEmpId_CreditNoteFromSupplierMaster: "",
  minSizeTotalPay_CreditNoteFromSupplierMaster: 0,
  maxSizeTotalPay_CreditNoteFromSupplierMaster: 26,
  patternTotalPay_CreditNoteFromSupplierMaster: "",
  minSizePrintStatus_CreditNoteFromSupplierMaster: 0,
  maxSizePrintStatus_CreditNoteFromSupplierMaster: 2,
  patternPrintStatus_CreditNoteFromSupplierMaster: "",
  minSizeReceiveType_CreditNoteFromSupplierMaster: 0,
  maxSizeReceiveType_CreditNoteFromSupplierMaster: 2,
  patternReceiveType_CreditNoteFromSupplierMaster: "",
  minSizeBankDate_CreditNoteFromSupplierMaster: 0,
  maxSizeBankDate_CreditNoteFromSupplierMaster: 20,
  patternBankDate_CreditNoteFromSupplierMaster: "",
  minSizeBankRemank_CreditNoteFromSupplierMaster: 0,
  maxSizeBankRemank_CreditNoteFromSupplierMaster: 255,
  patternBankRemank_CreditNoteFromSupplierMaster: "",
  minSizeChqNo_CreditNoteFromSupplierMaster: 0,
  maxSizeChqNo_CreditNoteFromSupplierMaster: 20,
  patternChqNo_CreditNoteFromSupplierMaster: "",
  minSizeCustBankCode_CreditNoteFromSupplierMaster: 0,
  maxSizeCustBankCode_CreditNoteFromSupplierMaster: 20,
  patternCustBankCode_CreditNoteFromSupplierMaster: "[0-9a-zA-Z/-]+",
  minSizeDivisionCustBank_CreditNoteFromSupplierMaster: 0,
  maxSizeDivisionCustBank_CreditNoteFromSupplierMaster: 20,
  patternDivisionCustBank_CreditNoteFromSupplierMaster: "",
  minSizePayChqNo_CreditNoteFromSupplierMaster: 0,
  maxSizePayChqNo_CreditNoteFromSupplierMaster: 20,
  patternPayChqNo_CreditNoteFromSupplierMaster: "",
  minSizeCreditNoteSupplierPayAmt_CreditNoteFromSupplierMaster: 0,
  maxSizeCreditNoteSupplierPayAmt_CreditNoteFromSupplierMaster: 26,
  patternCreditNoteSupplierPayAmt_CreditNoteFromSupplierMaster: "",
  minSizeReceiveDepositBookCode_CreditNoteFromSupplierMaster: 0,
  maxSizeReceiveDepositBookCode_CreditNoteFromSupplierMaster: 50,
  patternReceiveDepositBookCode_CreditNoteFromSupplierMaster: "[0-9a-zA-Z/-]+",
  minSizeBankCode_CreditNoteFromSupplierMaster: 0,
  maxSizeBankCode_CreditNoteFromSupplierMaster: 20,
  patternBankCode_CreditNoteFromSupplierMaster: "[0-9a-zA-Z/-]+",
  minSizeBankTName_CreditNoteFromSupplierMaster: 0,
  maxSizeBankTName_CreditNoteFromSupplierMaster: 255,
  patternBankTName_CreditNoteFromSupplierMaster: "",
  minSizeBankFee_CreditNoteFromSupplierMaster: 0,
  maxSizeBankFee_CreditNoteFromSupplierMaster: 26,
  patternBankFee_CreditNoteFromSupplierMaster: "",
  minSizeCreditNoteSupplierWitholdingTax_CreditNoteFromSupplierMaster: 0,
  maxSizeCreditNoteSupplierWitholdingTax_CreditNoteFromSupplierMaster: 26,
  patternCreditNoteSupplierWitholdingTax_CreditNoteFromSupplierMaster: "",
  minSizeAmountOfOriginalDoc_CreditNoteFromSupplierMaster: 0,
  maxSizeAmountOfOriginalDoc_CreditNoteFromSupplierMaster: 26,
  patternAmountOfOriginalDoc_CreditNoteFromSupplierMaster: "",
  minSizeCashAmt_CreditNoteFromSupplierMaster: 0,
  maxSizeCashAmt_CreditNoteFromSupplierMaster: 26,
  patternCashAmt_CreditNoteFromSupplierMaster: "",
  minSizeBankAmt_CreditNoteFromSupplierMaster: 0,
  maxSizeBankAmt_CreditNoteFromSupplierMaster: 26,
  patternBankAmt_CreditNoteFromSupplierMaster: "",
  minSizeChequeAmt_CreditNoteFromSupplierMaster: 0,
  maxSizeChequeAmt_CreditNoteFromSupplierMaster: 26,
  patternChequeAmt_CreditNoteFromSupplierMaster: "",
  minSizeBankId_CreditNoteFromSupplierMaster: 0,
  maxSizeBankId_CreditNoteFromSupplierMaster: 19,
  patternBankId_CreditNoteFromSupplierMaster: "",
  minSizeChequeBankId_CreditNoteFromSupplierMaster: 0,
  maxSizeChequeBankId_CreditNoteFromSupplierMaster: 19,
  patternChequeBankId_CreditNoteFromSupplierMaster: "",
  minSizePettyCashMasterId_PettyCashMasterDepositBook: 0,
  maxSizePettyCashMasterId_PettyCashMasterDepositBook: 19,
  patternPettyCashMasterId_PettyCashMasterDepositBook: "",
  minSizeDepositBookMasterId_PettyCashMasterDepositBook: 0,
  maxSizeDepositBookMasterId_PettyCashMasterDepositBook: 19,
  patternDepositBookMasterId_PettyCashMasterDepositBook: "",
  minSizePaymentVoucherDetailId_PaymentVoucherDetail: 0,
  maxSizePaymentVoucherDetailId_PaymentVoucherDetail: 19,
  patternPaymentVoucherDetailId_PaymentVoucherDetail: "",
  minSizePaymentVoudetailNo_PaymentVoucherDetail: 0,
  maxSizePaymentVoudetailNo_PaymentVoucherDetail: 10,
  patternPaymentVoudetailNo_PaymentVoucherDetail: "",
  minSizeBillPayDocAmt_PaymentVoucherDetail: 0,
  maxSizeBillPayDocAmt_PaymentVoucherDetail: 26,
  patternBillPayDocAmt_PaymentVoucherDetail: "",
  minSizeBillPayDocRemain_PaymentVoucherDetail: 0,
  maxSizeBillPayDocRemain_PaymentVoucherDetail: 26,
  patternBillPayDocRemain_PaymentVoucherDetail: "",
  minSizePayVoucherAmt_PaymentVoucherDetail: 0,
  maxSizePayVoucherAmt_PaymentVoucherDetail: 26,
  patternPayVoucherAmt_PaymentVoucherDetail: "",
  minSizeCompanyCode_PaymentVoucherDetail: 0,
  maxSizeCompanyCode_PaymentVoucherDetail: 20,
  patternCompanyCode_PaymentVoucherDetail: "[0-9a-zA-Z/-]+",
  minSizeDocType_PaymentVoucherDetail: 0,
  maxSizeDocType_PaymentVoucherDetail: 5,
  patternDocType_PaymentVoucherDetail: "",
  minSizeBillDocNo_PaymentVoucherDetail: 0,
  maxSizeBillDocNo_PaymentVoucherDetail: 100,
  patternBillDocNo_PaymentVoucherDetail: "",
  minSizeBillDocDate_PaymentVoucherDetail: 0,
  maxSizeBillDocDate_PaymentVoucherDetail: 100,
  patternBillDocDate_PaymentVoucherDetail: "",
  minSizeBillingPaymentMasterId_PaymentVoucherDetail: 0,
  maxSizeBillingPaymentMasterId_PaymentVoucherDetail: 19,
  patternBillingPaymentMasterId_PaymentVoucherDetail: "",
  minSizeRcvServiceMasterId_PaymentVoucherDetail: 0,
  maxSizeRcvServiceMasterId_PaymentVoucherDetail: 19,
  patternRcvServiceMasterId_PaymentVoucherDetail: "",
  minSizeProdReceiveInvoiceId_PaymentVoucherDetail: 0,
  maxSizeProdReceiveInvoiceId_PaymentVoucherDetail: 19,
  patternProdReceiveInvoiceId_PaymentVoucherDetail: "",
  minSizeForeignPurchaseDocNo_PaymentVoucherDetail: 0,
  maxSizeForeignPurchaseDocNo_PaymentVoucherDetail: 19,
  patternForeignPurchaseDocNo_PaymentVoucherDetail: "",
  minSizePurchaseRecordingMasterId_PaymentVoucherDetail: 0,
  maxSizePurchaseRecordingMasterId_PaymentVoucherDetail: 19,
  patternPurchaseRecordingMasterId_PaymentVoucherDetail: "",
  minSizePaymentVoucherMasterId_PaymentVoucherDetail: 0,
  maxSizePaymentVoucherMasterId_PaymentVoucherDetail: 19,
  patternPaymentVoucherMasterId_PaymentVoucherDetail: "",
  minSizeExchangeRate_PaymentVoucherDetail: 0,
  maxSizeExchangeRate_PaymentVoucherDetail: 26,
  patternExchangeRate_PaymentVoucherDetail: "",
  minSizeExchageRateDate_PaymentVoucherDetail: 0,
  maxSizeExchageRateDate_PaymentVoucherDetail: 20,
  patternExchageRateDate_PaymentVoucherDetail: "",
  minSizeRowCountDocument_PaymentVoucherDetail: 0,
  maxSizeRowCountDocument_PaymentVoucherDetail: 10,
  patternRowCountDocument_PaymentVoucherDetail: "",
  minSizeCreditNoteSupplierMasterId_PaymentVoucherDetail: 0,
  maxSizeCreditNoteSupplierMasterId_PaymentVoucherDetail: 19,
  patternCreditNoteSupplierMasterId_PaymentVoucherDetail: "",
  minSizeDepositPaymentInvoiceMasterId_PaymentVoucherDetail: 0,
  maxSizeDepositPaymentInvoiceMasterId_PaymentVoucherDetail: 19,
  patternDepositPaymentInvoiceMasterId_PaymentVoucherDetail: "",
  minSizeBpDetailId_PaymentVoucherDetail: 0,
  maxSizeBpDetailId_PaymentVoucherDetail: 19,
  patternBpDetailId_PaymentVoucherDetail: "",
  minSizeOtherExpenseId_PaymentVoucherDetail: 0,
  maxSizeOtherExpenseId_PaymentVoucherDetail: 19,
  patternOtherExpenseId_PaymentVoucherDetail: "",
  minSizeSupplierOpeningMasterId_PaymentVoucherDetail: 0,
  maxSizeSupplierOpeningMasterId_PaymentVoucherDetail: 19,
  patternSupplierOpeningMasterId_PaymentVoucherDetail: "",
  minSizePettyCashReceiveDetailId_PettyCashReceiveDetail: 0,
  maxSizePettyCashReceiveDetailId_PettyCashReceiveDetail: 19,
  patternPettyCashReceiveDetailId_PettyCashReceiveDetail: "",
  minSizePettyCashReceiveDetailNo_PettyCashReceiveDetail: 0,
  maxSizePettyCashReceiveDetailNo_PettyCashReceiveDetail: 10,
  patternPettyCashReceiveDetailNo_PettyCashReceiveDetail: "",
  minSizeAdvId_PettyCashReceiveDetail: 0,
  maxSizeAdvId_PettyCashReceiveDetail: 19,
  patternAdvId_PettyCashReceiveDetail: "",
  minSizeAdvCode_PettyCashReceiveDetail: 0,
  maxSizeAdvCode_PettyCashReceiveDetail: 20,
  patternAdvCode_PettyCashReceiveDetail: "[0-9a-zA-Z/-]+",
  minSizeAdvName_PettyCashReceiveDetail: 0,
  maxSizeAdvName_PettyCashReceiveDetail: 200,
  patternAdvName_PettyCashReceiveDetail: "",
  minSizeTotalAmt_PettyCashReceiveDetail: 0,
  maxSizeTotalAmt_PettyCashReceiveDetail: 26,
  patternTotalAmt_PettyCashReceiveDetail: "",
  minSizeCompanyCode_PettyCashReceiveDetail: 0,
  maxSizeCompanyCode_PettyCashReceiveDetail: 20,
  patternCompanyCode_PettyCashReceiveDetail: "[0-9a-zA-Z/-]+",
  minSizeDescription_PettyCashReceiveDetail: 0,
  maxSizeDescription_PettyCashReceiveDetail: 255,
  patternDescription_PettyCashReceiveDetail: "",
  minSizeDivisionId_PettyCashReceiveDetail: 0,
  maxSizeDivisionId_PettyCashReceiveDetail: 19,
  patternDivisionId_PettyCashReceiveDetail: "",
  minSizeDivisionCode_PettyCashReceiveDetail: 0,
  maxSizeDivisionCode_PettyCashReceiveDetail: 20,
  patternDivisionCode_PettyCashReceiveDetail: "[0-9a-zA-Z/-]+",
  minSizeDivisionName_PettyCashReceiveDetail: 0,
  maxSizeDivisionName_PettyCashReceiveDetail: 255,
  patternDivisionName_PettyCashReceiveDetail: "",
  minSizeSectionId_PettyCashReceiveDetail: 0,
  maxSizeSectionId_PettyCashReceiveDetail: 19,
  patternSectionId_PettyCashReceiveDetail: "",
  minSizeSectionCode_PettyCashReceiveDetail: 0,
  maxSizeSectionCode_PettyCashReceiveDetail: 20,
  patternSectionCode_PettyCashReceiveDetail: "[0-9a-zA-Z/-]+",
  minSizeSectionName_PettyCashReceiveDetail: 0,
  maxSizeSectionName_PettyCashReceiveDetail: 255,
  patternSectionName_PettyCashReceiveDetail: "",
  minSizeJobMasterId_PettyCashReceiveDetail: 0,
  maxSizeJobMasterId_PettyCashReceiveDetail: 19,
  patternJobMasterId_PettyCashReceiveDetail: "",
  minSizePettyCashReceiveMasterId_PettyCashReceiveDetail: 0,
  maxSizePettyCashReceiveMasterId_PettyCashReceiveDetail: 19,
  patternPettyCashReceiveMasterId_PettyCashReceiveDetail: "",
  minSizeNoRefundTax_PurchaseRecordingMaster: 0,
  maxSizeNoRefundTax_PurchaseRecordingMaster: 2,
  patternNoRefundTax_PurchaseRecordingMaster: "",
  minSizeJobMasterId_PurchaseRecordingMaster: 0,
  maxSizeJobMasterId_PurchaseRecordingMaster: 19,
  patternJobMasterId_PurchaseRecordingMaster: "",
  minSizeDivisionSuppDetailId_PurchaseRecordingMaster: 0,
  maxSizeDivisionSuppDetailId_PurchaseRecordingMaster: 19,
  patternDivisionSuppDetailId_PurchaseRecordingMaster: "",
  minSizeEmployeeMasterId_PurchaseRecordingMaster: 0,
  maxSizeEmployeeMasterId_PurchaseRecordingMaster: 19,
  patternEmployeeMasterId_PurchaseRecordingMaster: "",
  minSizePettyCashDetailId_PettyCashDetail: 0,
  maxSizePettyCashDetailId_PettyCashDetail: 19,
  patternPettyCashDetailId_PettyCashDetail: "",
  minSizePettyCashDetailNo_PettyCashDetail: 0,
  maxSizePettyCashDetailNo_PettyCashDetail: 10,
  patternPettyCashDetailNo_PettyCashDetail: "",
  minSizeInvoiceNo_PettyCashDetail: 0,
  maxSizeInvoiceNo_PettyCashDetail: 30,
  patternInvoiceNo_PettyCashDetail: "",
  minSizeTaxReturn_PettyCashDetail: 0,
  maxSizeTaxReturn_PettyCashDetail: 2,
  patternTaxReturn_PettyCashDetail: "",
  minSizeInvoiceDate_PettyCashDetail: 0,
  maxSizeInvoiceDate_PettyCashDetail: 20,
  patternInvoiceDate_PettyCashDetail: "",
  minSizeTotalBeforeVatAmt_PettyCashDetail: 0,
  maxSizeTotalBeforeVatAmt_PettyCashDetail: 26,
  patternTotalBeforeVatAmt_PettyCashDetail: "",
  minSizeVatAmt_PettyCashDetail: 0,
  maxSizeVatAmt_PettyCashDetail: 26,
  patternVatAmt_PettyCashDetail: "",
  minSizeTotalAmt_PettyCashDetail: 0,
  maxSizeTotalAmt_PettyCashDetail: 26,
  patternTotalAmt_PettyCashDetail: "",
  minSizeCompanyCode_PettyCashDetail: 0,
  maxSizeCompanyCode_PettyCashDetail: 20,
  patternCompanyCode_PettyCashDetail: "[0-9a-zA-Z/-]+",
  minSizeSuppName_PettyCashDetail: 0,
  maxSizeSuppName_PettyCashDetail: 255,
  patternSuppName_PettyCashDetail: "",
  minSizeTaxIdNo_PettyCashDetail: 0,
  maxSizeTaxIdNo_PettyCashDetail: 50,
  patternTaxIdNo_PettyCashDetail: "",
  minSizeSectionCode_PettyCashDetail: 0,
  maxSizeSectionCode_PettyCashDetail: 20,
  patternSectionCode_PettyCashDetail: "[0-9a-zA-Z/-]+",
  minSizeSectionName_PettyCashDetail: 0,
  maxSizeSectionName_PettyCashDetail: 255,
  patternSectionName_PettyCashDetail: "",
  minSizeAccountCodeId_PettyCashDetail: 0,
  maxSizeAccountCodeId_PettyCashDetail: 19,
  patternAccountCodeId_PettyCashDetail: "[0-9a-zA-Z/-]+",
  minSizeJobMasterId_PettyCashDetail: 0,
  maxSizeJobMasterId_PettyCashDetail: 19,
  patternJobMasterId_PettyCashDetail: "",
  minSizePettyCashMasterId_PettyCashDetail: 0,
  maxSizePettyCashMasterId_PettyCashDetail: 19,
  patternPettyCashMasterId_PettyCashDetail: "",
  minSizeExpenseTypeCode_PettyCashDetail: 0,
  maxSizeExpenseTypeCode_PettyCashDetail: 3,
  patternExpenseTypeCode_PettyCashDetail: "[0-9a-zA-Z/-]+",
  minSizeExpenseTypeName_PettyCashDetail: 0,
  maxSizeExpenseTypeName_PettyCashDetail: 150,
  patternExpenseTypeName_PettyCashDetail: "",
  minSizeDivisionSuppId_PettyCashDetail: 0,
  maxSizeDivisionSuppId_PettyCashDetail: 19,
  patternDivisionSuppId_PettyCashDetail: "",
  minSizeDivisionSuppName_PettyCashDetail: 0,
  maxSizeDivisionSuppName_PettyCashDetail: 255,
  patternDivisionSuppName_PettyCashDetail: "",
  minSizeAccountName_PettyCashDetail: 0,
  maxSizeAccountName_PettyCashDetail: 255,
  patternAccountName_PettyCashDetail: "",
  minSizeDivisionCode_PettyCashDetail: 0,
  maxSizeDivisionCode_PettyCashDetail: 20,
  patternDivisionCode_PettyCashDetail: "[0-9a-zA-Z/-]+",
  minSizeDivisionName_PettyCashDetail: 0,
  maxSizeDivisionName_PettyCashDetail: 255,
  patternDivisionName_PettyCashDetail: "",
  minSizeRemark_PettyCashDetail: 0,
  maxSizeRemark_PettyCashDetail: 250,
  patternRemark_PettyCashDetail: "",
  minSizeProdQty_ProductReceiveInvoiceDetail: 0,
  maxSizeProdQty_ProductReceiveInvoiceDetail: 26,
  patternProdQty_ProductReceiveInvoiceDetail: "",
  minSizeSerialQty_ProductReceiveInvoiceDetail: 0,
  maxSizeSerialQty_ProductReceiveInvoiceDetail: 26,
  patternSerialQty_ProductReceiveInvoiceDetail: "",
  minSizeAdCost_Detail_ProductReceiveInvoiceDetail: 0,
  patternAdCost_Detail_ProductReceiveInvoiceDetail: "",
  minSizeVat54TypeId_Vat54Type: 0,
  maxSizeVat54TypeId_Vat54Type: 19,
  patternVat54TypeId_Vat54Type: "",
  minSizeVat54TypeCode_Vat54Type: 0,
  maxSizeVat54TypeCode_Vat54Type: 2,
  patternVat54TypeCode_Vat54Type: "[0-9a-zA-Z/-]+",
  minSizeVat54TypeName_Vat54Type: 0,
  maxSizeVat54TypeName_Vat54Type: 250,
  patternVat54TypeName_Vat54Type: "",
  minSizeVat54TypeStatus_Vat54Type: 0,
  maxSizeVat54TypeStatus_Vat54Type: 1,
  patternVat54TypeStatus_Vat54Type: "",
  minSizeOtherExpenseId_OtherExpense: 0,
  maxSizeOtherExpenseId_OtherExpense: 19,
  patternOtherExpenseId_OtherExpense: "",
  minSizeOhterExpenseNo_OtherExpense: 0,
  maxSizeOhterExpenseNo_OtherExpense: 20,
  patternOhterExpenseNo_OtherExpense: "",
  minSizeInvoiceNo_OtherExpense: 0,
  maxSizeInvoiceNo_OtherExpense: 50,
  patternInvoiceNo_OtherExpense: "",
  minSizeInvoiceDate_OtherExpense: 0,
  maxSizeInvoiceDate_OtherExpense: 20,
  patternInvoiceDate_OtherExpense: "",
  minSizeTotalBeforeVatAmt_OtherExpense: 0,
  maxSizeTotalBeforeVatAmt_OtherExpense: 26,
  patternTotalBeforeVatAmt_OtherExpense: "",
  minSizeVatAmt_OtherExpense: 0,
  maxSizeVatAmt_OtherExpense: 26,
  patternVatAmt_OtherExpense: "",
  minSizeTotalAmt_OtherExpense: 0,
  maxSizeTotalAmt_OtherExpense: 26,
  patternTotalAmt_OtherExpense: "",
  minSizeCreateOn_OtherExpense: 0,
  maxSizeCreateOn_OtherExpense: 20,
  patternCreateOn_OtherExpense: "",
  minSizeUpdateOn_OtherExpense: 0,
  maxSizeUpdateOn_OtherExpense: 20,
  patternUpdateOn_OtherExpense: "",
  minSizeCompanyCode_OtherExpense: 0,
  maxSizeCompanyCode_OtherExpense: 20,
  patternCompanyCode_OtherExpense: "[0-9a-zA-Z/-]+",
  minSizeTaxReturn_OtherExpense: 0,
  maxSizeTaxReturn_OtherExpense: 2,
  patternTaxReturn_OtherExpense: "",
  minSizeOhterExpenseStatus_OtherExpense: 0,
  maxSizeOhterExpenseStatus_OtherExpense: 2,
  patternOhterExpenseStatus_OtherExpense: "",
  minSizeSuppName_OtherExpense: 0,
  maxSizeSuppName_OtherExpense: 255,
  patternSuppName_OtherExpense: "",
  minSizeTaxIdNo_OtherExpense: 0,
  maxSizeTaxIdNo_OtherExpense: 50,
  patternTaxIdNo_OtherExpense: "",
  minSizeSectionCode_OtherExpense: 0,
  maxSizeSectionCode_OtherExpense: 20,
  patternSectionCode_OtherExpense: "[0-9a-zA-Z/-]+",
  minSizeJobCode_OtherExpense: 0,
  maxSizeJobCode_OtherExpense: 20,
  patternJobCode_OtherExpense: "[0-9a-zA-Z/-]+",
  minSizeSectionName_OtherExpense: 0,
  maxSizeSectionName_OtherExpense: 255,
  patternSectionName_OtherExpense: "",
  minSizeJobName_OtherExpense: 0,
  maxSizeJobName_OtherExpense: 255,
  patternJobName_OtherExpense: "",
  minSizeAccountCodeId_OtherExpense: 0,
  maxSizeAccountCodeId_OtherExpense: 19,
  patternAccountCodeId_OtherExpense: "[0-9a-zA-Z/-]+",
  minSizeBillingPaymentMasterId_OtherExpense: 0,
  maxSizeBillingPaymentMasterId_OtherExpense: 19,
  patternBillingPaymentMasterId_OtherExpense: "",
  minSizeCreateBy_OtherExpense: 0,
  maxSizeCreateBy_OtherExpense: 19,
  patternCreateBy_OtherExpense: "",
  minSizeUpdateBy_OtherExpense: 0,
  maxSizeUpdateBy_OtherExpense: 19,
  patternUpdateBy_OtherExpense: "",
  minSizeDivisionSuppId_OtherExpense: 0,
  maxSizeDivisionSuppId_OtherExpense: 19,
  patternDivisionSuppId_OtherExpense: "",
  minSizeDivisionSuppName_OtherExpense: 0,
  maxSizeDivisionSuppName_OtherExpense: 255,
  patternDivisionSuppName_OtherExpense: "",
  minSizeAccountName_OtherExpense: 0,
  maxSizeAccountName_OtherExpense: 255,
  patternAccountName_OtherExpense: "",
  minSizeDivisionCode_OtherExpense: 0,
  maxSizeDivisionCode_OtherExpense: 20,
  patternDivisionCode_OtherExpense: "[0-9a-zA-Z/-]+",
  minSizeDivisionName_OtherExpense: 0,
  maxSizeDivisionName_OtherExpense: 255,
  patternDivisionName_OtherExpense: "",
  minSizeRemark_OtherExpense: 0,
  maxSizeRemark_OtherExpense: 250,
  patternRemark_OtherExpense: "",
  minSizeCustomerMasterId_LotMaster: 0,
  maxSizeCustomerMasterId_LotMaster: 19,
  patternCustomerMasterId_LotMaster: "",
  minSizePaymentVoucherMasterId_PettyCashMasterJobCode: 0,
  maxSizePaymentVoucherMasterId_PettyCashMasterJobCode: 19,
  patternPaymentVoucherMasterId_PettyCashMasterJobCode: "",
  minSizeJobMasterId_PettyCashMasterJobCode: 0,
  maxSizeJobMasterId_PettyCashMasterJobCode: 19,
  patternJobMasterId_PettyCashMasterJobCode: "",
  minSizePettyCashReturnMasterId_PettyCashReturnMaster: 0,
  maxSizePettyCashReturnMasterId_PettyCashReturnMaster: 19,
  patternPettyCashReturnMasterId_PettyCashReturnMaster: "",
  minSizePcReturnDocNo_PettyCashReturnMaster: 0,
  maxSizePcReturnDocNo_PettyCashReturnMaster: 20,
  patternPcReturnDocNo_PettyCashReturnMaster: "",
  minSizePcReturnDocDate_PettyCashReturnMaster: 0,
  maxSizePcReturnDocDate_PettyCashReturnMaster: 20,
  patternPcReturnDocDate_PettyCashReturnMaster: "",
  minSizeDocType_PettyCashReturnMaster: 0,
  maxSizeDocType_PettyCashReturnMaster: 5,
  patternDocType_PettyCashReturnMaster: "",
  minSizePcReturnTotal_PettyCashReturnMaster: 0,
  maxSizePcReturnTotal_PettyCashReturnMaster: 26,
  patternPcReturnTotal_PettyCashReturnMaster: "",
  minSizeAdvancePaymentDeducting_PettyCashReturnMaster: 0,
  maxSizeAdvancePaymentDeducting_PettyCashReturnMaster: 26,
  patternAdvancePaymentDeducting_PettyCashReturnMaster: "",
  minSizePcReturnNetAmt_PettyCashReturnMaster: 0,
  maxSizePcReturnNetAmt_PettyCashReturnMaster: 26,
  patternPcReturnNetAmt_PettyCashReturnMaster: "",
  minSizePcReturnRemark_PettyCashReturnMaster: 0,
  maxSizePcReturnRemark_PettyCashReturnMaster: 250,
  patternPcReturnRemark_PettyCashReturnMaster: "",
  minSizePcReturnStatus_PettyCashReturnMaster: 0,
  maxSizePcReturnStatus_PettyCashReturnMaster: 2,
  patternPcReturnStatus_PettyCashReturnMaster: "",
  minSizePcReturnLinkDocNo_PettyCashReturnMaster: 0,
  maxSizePcReturnLinkDocNo_PettyCashReturnMaster: 30,
  patternPcReturnLinkDocNo_PettyCashReturnMaster: "",
  minSizeCreateOn_PettyCashReturnMaster: 0,
  maxSizeCreateOn_PettyCashReturnMaster: 20,
  patternCreateOn_PettyCashReturnMaster: "",
  minSizeUpdateOn_PettyCashReturnMaster: 0,
  maxSizeUpdateOn_PettyCashReturnMaster: 20,
  patternUpdateOn_PettyCashReturnMaster: "",
  minSizeVoucherNo_PettyCashReturnMaster: 0,
  maxSizeVoucherNo_PettyCashReturnMaster: 20,
  patternVoucherNo_PettyCashReturnMaster: "",
  minSizeJobCode_PettyCashReturnMaster: 0,
  maxSizeJobCode_PettyCashReturnMaster: 20,
  patternJobCode_PettyCashReturnMaster: "[0-9a-zA-Z/-]+",
  minSizeCompanyCode_PettyCashReturnMaster: 0,
  maxSizeCompanyCode_PettyCashReturnMaster: 20,
  patternCompanyCode_PettyCashReturnMaster: "[0-9a-zA-Z/-]+",
  minSizeAstatus_PettyCashReturnMaster: 0,
  maxSizeAstatus_PettyCashReturnMaster: 2,
  patternAstatus_PettyCashReturnMaster: "",
  minSizePrintStatus_PettyCashReturnMaster: 0,
  maxSizePrintStatus_PettyCashReturnMaster: 2,
  patternPrintStatus_PettyCashReturnMaster: "",
  minSizeJobMasterId_PettyCashReturnMaster: 0,
  maxSizeJobMasterId_PettyCashReturnMaster: 19,
  patternJobMasterId_PettyCashReturnMaster: "",
  minSizeAdvId_PettyCashReturnMaster: 0,
  maxSizeAdvId_PettyCashReturnMaster: 19,
  patternAdvId_PettyCashReturnMaster: "",
  minSizeDocumentGroupId_PettyCashReturnMaster: 0,
  maxSizeDocumentGroupId_PettyCashReturnMaster: 19,
  patternDocumentGroupId_PettyCashReturnMaster: "",
  minSizeEmployeeMasterOfficeId_PettyCashReturnMaster: 0,
  maxSizeEmployeeMasterOfficeId_PettyCashReturnMaster: 19,
  patternEmployeeMasterOfficeId_PettyCashReturnMaster: "",
  minSizeEmployeeMasterId_PettyCashReturnMaster: 0,
  maxSizeEmployeeMasterId_PettyCashReturnMaster: 19,
  patternEmployeeMasterId_PettyCashReturnMaster: "",
  minSizeDivisionOfficeId_PettyCashReturnMaster: 0,
  maxSizeDivisionOfficeId_PettyCashReturnMaster: 19,
  patternDivisionOfficeId_PettyCashReturnMaster: "",
  minSizeSectionOfficeId_PettyCashReturnMaster: 0,
  maxSizeSectionOfficeId_PettyCashReturnMaster: 19,
  patternSectionOfficeId_PettyCashReturnMaster: "",
  minSizeCreateByEmpId_PettyCashReturnMaster: 0,
  maxSizeCreateByEmpId_PettyCashReturnMaster: 19,
  patternCreateByEmpId_PettyCashReturnMaster: "",
  minSizeUpdateByEmpId_PettyCashReturnMaster: 0,
  maxSizeUpdateByEmpId_PettyCashReturnMaster: 19,
  patternUpdateByEmpId_PettyCashReturnMaster: "",
  minSizeDivisionEmpId_PettyCashReturnMaster: 0,
  maxSizeDivisionEmpId_PettyCashReturnMaster: 19,
  patternDivisionEmpId_PettyCashReturnMaster: "",
  minSizeSectionEmpId_PettyCashReturnMaster: 0,
  maxSizeSectionEmpId_PettyCashReturnMaster: 19,
  patternSectionEmpId_PettyCashReturnMaster: "",
  minSizePettyCashWitholdingTax_PettyCashReturnMaster: 0,
  maxSizePettyCashWitholdingTax_PettyCashReturnMaster: 26,
  patternPettyCashWitholdingTax_PettyCashReturnMaster: "",
  minSizeWitholdingTaxType_PettyCashReturnMaster: 0,
  maxSizeWitholdingTaxType_PettyCashReturnMaster: 2,
  patternWitholdingTaxType_PettyCashReturnMaster: "",
  minSizeAdvReferenceId_AdvReference: 0,
  maxSizeAdvReferenceId_AdvReference: 19,
  patternAdvReferenceId_AdvReference: "",
  minSizeAdvancePaymentDocNo_AdvReference: 0,
  maxSizeAdvancePaymentDocNo_AdvReference: 20,
  patternAdvancePaymentDocNo_AdvReference: "",
  minSizeAdvCode_AdvReference: 0,
  maxSizeAdvCode_AdvReference: 20,
  patternAdvCode_AdvReference: "[0-9a-zA-Z/-]+",
  minSizeDivisionNo_AdvReference: 0,
  maxSizeDivisionNo_AdvReference: 20,
  patternDivisionNo_AdvReference: "",
  minSizeDivisionName_AdvReference: 0,
  maxSizeDivisionName_AdvReference: 255,
  patternDivisionName_AdvReference: "",
  minSizeSectionNo_AdvReference: 0,
  maxSizeSectionNo_AdvReference: 20,
  patternSectionNo_AdvReference: "",
  minSizeSectionName_AdvReference: 0,
  maxSizeSectionName_AdvReference: 255,
  patternSectionName_AdvReference: "",
  minSizeJobCode_AdvReference: 0,
  maxSizeJobCode_AdvReference: 20,
  patternJobCode_AdvReference: "[0-9a-zA-Z/-]+",
  minSizeJobName_AdvReference: 0,
  maxSizeJobName_AdvReference: 255,
  patternJobName_AdvReference: "",
  minSizeAmount_AdvReference: 0,
  maxSizeAmount_AdvReference: 26,
  patternAmount_AdvReference: "",
  minSizeCompanyCode_AdvReference: 0,
  maxSizeCompanyCode_AdvReference: 20,
  patternCompanyCode_AdvReference: "[0-9a-zA-Z/-]+",
  minSizePettyCashReturnDocNo_AdvReference: 0,
  maxSizePettyCashReturnDocNo_AdvReference: 20,
  patternPettyCashReturnDocNo_AdvReference: "",
  minSizePettyCashReturnDetailId_PettyCashReturnDetail: 0,
  maxSizePettyCashReturnDetailId_PettyCashReturnDetail: 19,
  patternPettyCashReturnDetailId_PettyCashReturnDetail: "",
  minSizePettyCashReturnDetailNo_PettyCashReturnDetail: 0,
  maxSizePettyCashReturnDetailNo_PettyCashReturnDetail: 10,
  patternPettyCashReturnDetailNo_PettyCashReturnDetail: "",
  minSizeInvoiceNo_PettyCashReturnDetail: 0,
  maxSizeInvoiceNo_PettyCashReturnDetail: 30,
  patternInvoiceNo_PettyCashReturnDetail: "",
  minSizeTaxReturn_PettyCashReturnDetail: 0,
  maxSizeTaxReturn_PettyCashReturnDetail: 2,
  patternTaxReturn_PettyCashReturnDetail: "",
  minSizeInvoiceDate_PettyCashReturnDetail: 0,
  maxSizeInvoiceDate_PettyCashReturnDetail: 20,
  patternInvoiceDate_PettyCashReturnDetail: "",
  minSizeTotalBeforeVatAmt_PettyCashReturnDetail: 0,
  maxSizeTotalBeforeVatAmt_PettyCashReturnDetail: 26,
  patternTotalBeforeVatAmt_PettyCashReturnDetail: "",
  minSizeVatAmt_PettyCashReturnDetail: 0,
  maxSizeVatAmt_PettyCashReturnDetail: 26,
  patternVatAmt_PettyCashReturnDetail: "",
  minSizeTotalAmt_PettyCashReturnDetail: 0,
  maxSizeTotalAmt_PettyCashReturnDetail: 26,
  patternTotalAmt_PettyCashReturnDetail: "",
  minSizeCompanyCode_PettyCashReturnDetail: 0,
  maxSizeCompanyCode_PettyCashReturnDetail: 20,
  patternCompanyCode_PettyCashReturnDetail: "[0-9a-zA-Z/-]+",
  minSizeSuppName_PettyCashReturnDetail: 0,
  maxSizeSuppName_PettyCashReturnDetail: 255,
  patternSuppName_PettyCashReturnDetail: "",
  minSizeSuppdivisionName_PettyCashReturnDetail: 0,
  maxSizeSuppdivisionName_PettyCashReturnDetail: 255,
  patternSuppdivisionName_PettyCashReturnDetail: "",
  minSizeTaxIdNo_PettyCashReturnDetail: 0,
  maxSizeTaxIdNo_PettyCashReturnDetail: 50,
  patternTaxIdNo_PettyCashReturnDetail: "",
  minSizeSectionCode_PettyCashReturnDetail: 0,
  maxSizeSectionCode_PettyCashReturnDetail: 20,
  patternSectionCode_PettyCashReturnDetail: "[0-9a-zA-Z/-]+",
  minSizeSectionName_PettyCashReturnDetail: 0,
  maxSizeSectionName_PettyCashReturnDetail: 255,
  patternSectionName_PettyCashReturnDetail: "",
  minSizeExpenseTypeCode_PettyCashReturnDetail: 0,
  maxSizeExpenseTypeCode_PettyCashReturnDetail: 3,
  patternExpenseTypeCode_PettyCashReturnDetail: "[0-9a-zA-Z/-]+",
  minSizeExpenseTypeName_PettyCashReturnDetail: 0,
  maxSizeExpenseTypeName_PettyCashReturnDetail: 150,
  patternExpenseTypeName_PettyCashReturnDetail: "",
  minSizeAccountName_PettyCashReturnDetail: 0,
  maxSizeAccountName_PettyCashReturnDetail: 255,
  patternAccountName_PettyCashReturnDetail: "",
  minSizeAccountCodeId_PettyCashReturnDetail: 0,
  maxSizeAccountCodeId_PettyCashReturnDetail: 19,
  patternAccountCodeId_PettyCashReturnDetail: "[0-9a-zA-Z/-]+",
  minSizePettyCashReturnMasterId_PettyCashReturnDetail: 0,
  maxSizePettyCashReturnMasterId_PettyCashReturnDetail: 19,
  patternPettyCashReturnMasterId_PettyCashReturnDetail: "",
  minSizeDivisionCode_PettyCashReturnDetail: 0,
  maxSizeDivisionCode_PettyCashReturnDetail: 20,
  patternDivisionCode_PettyCashReturnDetail: "[0-9a-zA-Z/-]+",
  minSizeDivisionName_PettyCashReturnDetail: 0,
  maxSizeDivisionName_PettyCashReturnDetail: 255,
  patternDivisionName_PettyCashReturnDetail: "",
  minSizeJobMasterId_PettyCashReturnDetail: 0,
  maxSizeJobMasterId_PettyCashReturnDetail: 19,
  patternJobMasterId_PettyCashReturnDetail: "",
  minSizeRemark_PettyCashReturnDetail: 0,
  maxSizeRemark_PettyCashReturnDetail: 250,
  patternRemark_PettyCashReturnDetail: "",
  minSizePaymentVoucherMasterId_PaymentVouMasterJobMaster: 0,
  maxSizePaymentVoucherMasterId_PaymentVouMasterJobMaster: 19,
  patternPaymentVoucherMasterId_PaymentVouMasterJobMaster: "",
  minSizeJobMasterId_PaymentVouMasterJobMaster: 0,
  maxSizeJobMasterId_PaymentVouMasterJobMaster: 19,
  patternJobMasterId_PaymentVouMasterJobMaster: "",
  minSizeCreditNoteSupplierMasterId_CreditNoteMastFromSuppJobMast: 0,
  maxSizeCreditNoteSupplierMasterId_CreditNoteMastFromSuppJobMast: 19,
  patternCreditNoteSupplierMasterId_CreditNoteMastFromSuppJobMast: "",
  minSizeJobMasterId_CreditNoteMastFromSuppJobMast: 0,
  maxSizeJobMasterId_CreditNoteMastFromSuppJobMast: 19,
  patternJobMasterId_CreditNoteMastFromSuppJobMast: "",
  minSizePurchaseRecordDepositId_PurchaseRecordDeposit: 0,
  maxSizePurchaseRecordDepositId_PurchaseRecordDeposit: 19,
  patternPurchaseRecordDepositId_PurchaseRecordDeposit: "",
  minSizePurchaseRecordingMasterId_PurchaseRecordDeposit: 0,
  maxSizePurchaseRecordingMasterId_PurchaseRecordDeposit: 19,
  patternPurchaseRecordingMasterId_PurchaseRecordDeposit: "",
  minSizeDepositPaymentInvoiceMasterId_PurchaseRecordDeposit: 0,
  maxSizeDepositPaymentInvoiceMasterId_PurchaseRecordDeposit: 19,
  patternDepositPaymentInvoiceMasterId_PurchaseRecordDeposit: "",
  minSizeDepositAmount_PurchaseRecordDeposit: 0,
  maxSizeDepositAmount_PurchaseRecordDeposit: 14,
  patternDepositAmount_PurchaseRecordDeposit: "",
  minSizeDeliverAreaName_DeliverArea: 0,
  maxSizeDeliverAreaName_DeliverArea: 200,
  patternDeliverAreaName_DeliverArea: "",
  minSizeRemark_DeliverArea: 0,
  maxSizeRemark_DeliverArea: 200,
  patternRemark_DeliverArea: "",
  minSizeDobjName_DeliverObjective: 0,
  maxSizeDobjName_DeliverObjective: 200,
  patternDobjName_DeliverObjective: "",
  minSizeDobjRemark_DeliverObjective: 0,
  maxSizeDobjRemark_DeliverObjective: 200,
  patternDobjRemark_DeliverObjective: "",
  minSizeContactName_DeliveryTracking: 0,
  maxSizeContactName_DeliveryTracking: 200,
  patternContactName_DeliveryTracking: "",
  minSizeDeliveryAddress_DeliveryTracking: 0,
  maxSizeDeliveryAddress_DeliveryTracking: 200,
  patternDeliveryAddress_DeliveryTracking: "",
  minSizeContactNo_DeliveryTracking: 0,
  maxSizeContactNo_DeliveryTracking: 100,
  patternContactNo_DeliveryTracking: "",
  minSizeLocation_DeliveryTracking: 0,
  maxSizeLocation_DeliveryTracking: 100,
  patternLocation_DeliveryTracking: "",
  minSizeDescription_DeliveryTracking: 0,
  maxSizeDescription_DeliveryTracking: 300,
  patternDescription_DeliveryTracking: "",
  minSizeLocation_SaleOrder: 0,
  maxSizeLocation_SaleOrder: 100,
  patternLocation_SaleOrder: "",
  minSizePackageCode_PackageType: 0,
  maxSizePackageCode_PackageType: 20,
  patternPackageCode_PackageType: "",
  minSizePackageName_PackageType: 0,
  maxSizePackageName_PackageType: 200,
  patternPackageName_PackageType: "",
  minSizePackageSize_PackageType: 0,
  maxSizePackageSize_PackageType: 100,
  patternPackageSize_PackageType: "",
  minSizeSaleStageCode_SaleStage: 0,
  maxSizeSaleStageCode_SaleStage: 20,
  patternSaleStageCode_SaleStage: "[0-9A-Z/-]+",
  minSizeSaleStageName_SaleStage: 0,
  maxSizeSaleStageName_SaleStage: 200,
  patternSaleStageName_SaleStage: "",
  minSizeSaleStageRemark_SaleStage: 0,
  maxSizeSaleStageRemark_SaleStage: 200,
  patternSaleStageRemark_SaleStage: "",
  minSizeSaleStageNo_SaleStage: 0,
  maxSizeSaleStageNo_SaleStage: 20,
  patternSaleStageNo_SaleStage: "[0-9]+",
  minSizeSaleTeamCode_SaleTeam: 0,
  maxSizeSaleTeamCode_SaleTeam: 50,
  patternSaleTeamCode_SaleTeam: "[0-9A-Z/-]+",
  minSizeSaleTeamName_SaleTeam: 0,
  maxSizeSaleTeamName_SaleTeam: 255,
  patternSaleTeamName_SaleTeam: "",
  minSizeSaleTeamEName_SaleTeam: 0,
  maxSizeSaleTeamEName_SaleTeam: 255,
  patternSaleTeamEName_SaleTeam: "",
  minSizeSaleTeamRemark_SaleTeam: 0,
  maxSizeSaleTeamRemark_SaleTeam: 500,
  patternSaleTeamRemark_SaleTeam: "",
  minSizeLeadCustomerCode_LeadCustomer: 0,
  maxSizeLeadCustomerCode_LeadCustomer: 20,
  patternLeadCustomerCode_LeadCustomer: "[a-zA-Z]+",
  minSizeLeadCustomerName_LeadCustomer: 0,
  maxSizeLeadCustomerName_LeadCustomer: 200,
  patternLeadCustomerName_LeadCustomer: "",
  minSizeLeadCustomerEName_LeadCustomer: 0,
  maxSizeLeadCustomerEName_LeadCustomer: 200,
  patternLeadCustomerEName_LeadCustomer: "",
  minSizeLeadCustomerAddress_LeadCustomer: 0,
  maxSizeLeadCustomerAddress_LeadCustomer: 500,
  patternLeadCustomerAddress_LeadCustomer: "",
  minSizeLeadCustomerTelNo_LeadCustomer: 0,
  maxSizeLeadCustomerTelNo_LeadCustomer: 100,
  patternLeadCustomerTelNo_LeadCustomer: "",
  minSizeLeadCustomerEmail_LeadCustomer: 0,
  maxSizeLeadCustomerEmail_LeadCustomer: 100,
  patternLeadCustomerEmail_LeadCustomer: "",
  minSizeLeadCustomerWebSite_LeadCustomer: 0,
  maxSizeLeadCustomerWebSite_LeadCustomer: 100,
  patternLeadCustomerWebSite_LeadCustomer: "",
  minSizeLeadCustomerTaxId_LeadCustomer: 0,
  maxSizeLeadCustomerTaxId_LeadCustomer: 100,
  patternLeadCustomerTaxId_LeadCustomer: "",
  minSizeLeadCustomerCustCode_LeadCustomer: 0,
  maxSizeLeadCustomerCustCode_LeadCustomer: 20,
  patternLeadCustomerCustCode_LeadCustomer: "[0-9a-zA-Z/-]+",
  minSizeLeadCustomerContactPerson_LeadCustomer: 0,
  maxSizeLeadCustomerContactPerson_LeadCustomer: 200,
  patternLeadCustomerContactPerson_LeadCustomer: "",
  minSizeLeadCustomerContactPersonTelNo_LeadCustomer: 0,
  maxSizeLeadCustomerContactPersonTelNo_LeadCustomer: 100,
  patternLeadCustomerContactPersonTelNo_LeadCustomer: "",
  minSizeLeadCustomerRemark_LeadCustomer: 0,
  maxSizeLeadCustomerRemark_LeadCustomer: 1000,
  patternLeadCustomerRemark_LeadCustomer: "",
  minSizeLeadCustomerLocation_LeadCustomer: 0,
  maxSizeLeadCustomerLocation_LeadCustomer: 100,
  patternLeadCustomerLocation_LeadCustomer: "",
  minSizeActivityActivityCode_Activity: 0,
  maxSizeActivityActivityCode_Activity: 20,
  patternActivityActivityCode_Activity: "[0-9a-zA-Z/-]+",
  minSizeActivityActivityName_Activity: 0,
  maxSizeActivityActivityName_Activity: 200,
  patternActivityActivityName_Activity: "",
  minSizeActivityActivityRemark_Activity: 0,
  maxSizeActivityActivityRemark_Activity: 1000,
  patternActivityActivityRemark_Activity: "",
  minSizeActivityLeadLocation_Activity: 0,
  maxSizeActivityLeadLocation_Activity: 50,
  patternActivityLeadLocation_Activity: "",
  minSizeTaskAddress_Task: 0,
  maxSizeTaskAddress_Task: 500,
  patternTaskAddress_Task: "",
  minSizeTaskLocation_Task: 0,
  maxSizeTaskLocation_Task: 100,
  patternTaskLocation_Task: "",
  minSizeTaskTelNo_Task: 0,
  maxSizeTaskTelNo_Task: 100,
  patternTaskTelNo_Task: "",
  minSizeTaskContactPerson_Task: 0,
  maxSizeTaskContactPerson_Task: 200,
  patternTaskContactPerson_Task: "",
  minSizeTaskContactPersonTelNo_Task: 0,
  maxSizeTaskContactPersonTelNo_Task: 100,
  patternTaskContactPersonTelNo_Task: "",
  minSizeTaskEmail_Task: 0,
  maxSizeTaskEmail_Task: 50,
  patternTaskEmail_Task: "",
  minSizePlatformCode: 0,
  maxSizePlatformCode: 200,
  patternPlatformCode: "",
  minSizePlatformName: 0,
  maxSizePlatformName: 200,
  patternPlatformName: "",
  minSizePlatformRemark: 0,
  maxSizePlatformRemark: 200,
  patternPlatformRemark: "",
};
