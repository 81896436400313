import { HttpClient } from "@angular/common/http";
import { Component, QueryList, ViewChildren } from "@angular/core";
import { Router } from "@angular/router";
import { IgxExpansionPanelComponent } from "@infragistics/igniteui-angular";

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss", "../../../../page/shared/shared.component.scss"],
})
export class SidenavComponent {
  public superAdmin:boolean = false;
  public isUserAdmin:boolean = false;
  public perpetualMode: any;

  constructor(private _router: Router, private http: HttpClient) {}

  public userInSessionStorage: any;
  public userInLocalStorage: any;
  public serviceStatus;

  async ngOnInit() {
    while (sessionStorage.getItem("session") == null) {
      await this.delay(300);
    }
    this.userInSessionStorage = JSON.parse(sessionStorage.getItem("session"));
    this.userInLocalStorage = JSON.parse(localStorage.getItem("info"));
    this.superAdmin = this.userInLocalStorage.superAdmin == "stradmin";
    this.perpetualMode = "N";
    if(this.userInSessionStorage.companyPerpetual != undefined){
      if(this.userInSessionStorage.companyPerpetual == "Y"){
        this.perpetualMode = "Y";
      }
    }
    if ((this.userInLocalStorage.username.toUpperCase() == "ADMIN" && !this.userInSessionStorage.companyPartialSystem) || this.superAdmin) {
      this.isUserAdmin = true;
    } else {
      this.isUserAdmin = false;
    }
    // if(this.superAdmin){
    //   this.Transport.push({ text: "TransportArea", navigation: "setup/transport-area" })
    //   this.Transport.push({ text: "TransportObjective", navigation: "setup/transport-objective" })
    // }
  }

  public collapseModel = {
    dashboard: true,
    companyProfile: true,
    setupProfile: true,
    reportAndAnalysis: true,
    support: true,
    analysis: true,
    extension: true,
  };

  public navItems = [
    { name: "dashboard", tag: "dashboard" },
    { name: "apartment", tag: "company" },
    { name: "settings", tag: "setup" },
    { name: "description", tag: "report" },
    { name: "analytics", tag: "analysis" },
    { name: "extension", tag: "extension" },
    { name: "contact_support", tag: "support" },
  ];

  public dashboard: Array<{ text: string; navigation: string }> = [
    { text: "CompanyDashboard", navigation: "setup/setup/management-dashboard" },
    { text: "SaleInformation", navigation: "setup/setup/sale-dashboard" },
    { text: "PurchaseData", navigation: "setup/setup/procurement-dashboard" },
    { text: "FinancialDashboard", navigation: "setup/setup/finance-dashboard" },
    { text: "ProductionDashboard", navigation: "setup/setup/production-dashboard" },
  ];

  public reportanalysis: Array<{ text: string; navigation: string }> = [
    { text: "Data Analysis", navigation: "https://analysis.acccloud.me/login" },
    /* { text: "Dashboard Analysis", navigation: "https://analysis.acccloud.me/login" } */
  ];

  public extension: Array<{ text: string; navigation: string; }> = [
    /* { text: "Mobile App", navigation: "support/mobile-app" }, */
    /* { text: "BusinessConnect", navigation: "support/business-connect"  }, *///not this version
    { text: "Warehouse Management", navigation: "warehouse-management" },
    { text: "eCommerce Platform", navigation: "open-api/home" },
    { text: "Website", navigation: "e-commerce" },
    { text: "Supply Chain ", navigation: "supply-chain" },
    { text: "Payroll", navigation: "payroll/calendar-leave" },
    { text: "Point of Sale", navigation: "pos/pos-division" },
    /* { text: "Power Bi", navigation: "powerbi/" }, */
    { text: "API", navigation: "setup/setup/api-document/" },
    { text: "AccCloud Drive", navigation: "https://application.acccloud.me/drive/auth/login" },
    { text: "AccCloud CRM", navigation: "https://acccloud.me/crm/login" },
  ];

  public report: Array<{ text: string; navigation: string }> = [
    { text: "SectionInfo", navigation: "report/companyprofilereport/report-section" },
    { text: "EmpCode", navigation: "report/companyprofilereport/report-employee" },
  ];

  public support: Array<{ text: string; navigation: string }> = [
    { text: "BusinessConnect", navigation: "support/business-connect" },
    { text: "ContactUs", navigation: "support/contact-us" },
    { text: "Folder", navigation: "support/Folder" },
  ];

  public faq: Array<{ text: string; navigation: string }> = [
    { text: "DocStatus", navigation: "faq/faq-center/doc-status" },
    { text: "CompanyProfileSystem",  navigation: "faq/faq-center/company-profile" },
    { text: "SetupProfileSystem",  navigation: "faq/faq-center/setup-profile" },
    { text: "ProduceSystem",  navigation: "faq/faq-center/produce-system" },
    { text: "InventorySystem",  navigation: "faq/faq-center/inventory-system" },
    { text: "PurchaseSystem",  navigation: "faq/faq-center/purchase-system" },
    { text: "SaleSystem",  navigation: "faq/faq-center/sale-system" },
    { text: "PaymentSystem",  navigation: "faq/faq-center/payment-system" },
    { text: "ReceiveSystem",  navigation: "faq/faq-center/receive-system" },
    { text: "FinancialSystem",  navigation: "faq/faq-center/financial-system" },
    { text: "AccountSystem",  navigation: "faq/faq-center/account-system" },
    { text: "SystemTax",  navigation: "faq/faq-center/tax-system" },
    { text: "SystemAsset",  navigation: "faq/faq-center/asset-system" },
    { text: "SystemBudget",  navigation: "faq/faq-center/budget-system" },
  ];

  public vdo: Array<{ text: string; navigation: string }> = [
    { text: "guideAccCloud", navigation: "https://www.youtube.com/playlist?list=PLoQMnJU9Nrk7YrtuYa9rwhq-fG8wsMDR3" },
    { text: "guideProduction", navigation: "https://www.youtube.com/watch?v=JOLlsLzlkC0" },
    { text: "guideRevision", navigation: "https://www.youtube.com/watch?v=oYtkGveG_TQ" },
    { text: "guideAnalysis", navigation: "https://www.youtube.com/watch?v=o2gGDEV6U5Y" },
  ];

  public businessType: Array<{ text: string; navigation: string }> = [
    { text: "assemblyProductionBusiness", navigation: "business" },
    { text: "processingBusiness", navigation: "business" },
    { text: "buyAndSellBusiness", navigation: "business" },
    { text: "exportBusiness", navigation: "business" },
    { text: "serviceBusiness", navigation: "business" },
    { text: "constructionBusiness", navigation: "business" },
  ];

  public document: Array<{ text: string; navigation: string }> = [
    {text:"Flow AccCloud"  , navigation:"https://drive.google.com/drive/folders/1cqWWHSrBmrB_O5Jm6F1u4bHQNELtgg1S?fbclid=IwAR20wCqw1VdQoh7ajduzTb_AmtFwRbhpHtTj4jIDoxL6obGohIJXfJIFJRI"},
    /* {text:"ContactUs"  , navigation:"support/contact-us"},
    {text:"Folder"  , navigation:"support/Folder"} */
  ];

  public setupprofile: Array<{ text: string; navigation: string }> = [
    { text: "AccountCodeReport", navigation: "report/setupprofilereport/report-account-code" },
    { text: "ProductCodeReport", navigation: "report/setupprofilereport/report-product-code" },
    { text: "WarehouseReport", navigation: "report/setupprofilereport/report-warehouse-code" },
    { text: "RSM10", navigation: "report/setupprofilereport/report-service-code" },
    { text: "CustomerCodeReport", navigation: "report/setupprofilereport/report-customer-code" },
    { text: "SupplierCodeReport", navigation: "report/setupprofilereport/report-supplier" },
    { text: "DepositBook", navigation: "report/setupprofilereport/report-deposit-book-profile" },
    { text: "JobCode", navigation: "report/setupprofilereport/report-job-code" },
    { text: "RSM57", navigation: "report/setupprofilereport/report-job-bom" },
    { text: "PriceList", navigation: "report/setupprofilereport/report-price-table" },
    { text: "ProdBomReport", navigation: "report/setupprofilereport/prod-bom-report" },
  ];

  public revenuedepartmentreport: Array<{ text: string; navigation: string }> = [
    { text: "OutputVatReport", navigation: "report/revenuedepartmentreport/report-output-vat" },
    { text: "InputVatReport", navigation: "report/revenuedepartmentreport/report-input-vat" },
    { text: "InputVatReturnReport", navigation: "report/revenuedepartmentreport/report-tax-return" },
    { text: "TaxReturnWaitReport", navigation: "report/revenuedepartmentreport/report-tax-return-waiting" },
    { text: "PND3", navigation: "report/revenuedepartmentreport/pnd3" },
    { text: "PND53", navigation: "report/revenuedepartmentreport/pnd53" },
    { text: "BPND1A", navigation: "report/revenuedepartmentreport/bpnd1-g" },
    { text: "PND1", navigation: "report/revenuedepartmentreport/pnd1" },
    { text: "PND1G", navigation: "report/revenuedepartmentreport/pnd1-g" },
    { text: "RRD17", navigation: "report/revenuedepartmentreport/rrd17" },
    { text: "PND2", navigation: "report/revenuedepartmentreport/pnd2" },
    { text: "PND2G", navigation: "report/revenuedepartmentreport/pnd2-g" },
    { text: "SocialInsuranceFund", navigation: "report/revenuedepartmentreport/social-insurance-fund" },
    { text: "SummaryTax", navigation: "report/revenuedepartmentreport/summary-tax" },
  ];

  public accountReceivablereport: Array<{ text: string; navigation: string }> = [
    { text: "AccountReceivableBalanceReport", navigation: "report/receivablereport/account-receivable-balance-report" },
    { text: "RAR04", navigation: "report/receivablereport/rar04-report" },
    { text: "RAR13", navigation: "report/receivablereport/rar13-report" },
  ];

  public tradeaccountpayables: Array<{ text: string; navigation: string }> = [
    { text: "AccountPayableBalanceReport", navigation: "report/payablereport/account-payable-balance-report" },
    { text: "RAP11", navigation: "report/payablereport/rap11-report" },
    { text: "RAP12", navigation: "report/payablereport/rap12-report" },
  ];

  public PurchaseSystemProfile: Array<{ text: string; navigation: string }> = [
    { text: "SummaryPurchaseRequestDocumentReportwithProductDetails", navigation: "report/purchasereport/summary-purchase-request-product-report" },
    { text: "SummaryPurchaseOrderReport", navigation: "report/purchasereport/summary-purchase-order-report" },
    { text: "ProductsRemainingonPurchaseOrderReport", navigation: "report/purchasereport/product-remainig-purchase-order-report" },
    { text: "SummaryProductReceivingDocumentReport", navigation: "report/purchasereport/summary-product-receiving-document-report" },
    { text: "RPSUMPVD", navigation: "report/purchasereport/cash-purchase-report" },
    { text: "SummaryDepositPaymentDocumentReport", navigation: "report/purchasereport/summary-deposit-payment-document-report" },
    { text: "RPO25", navigation: "report/purchasereport/summary-receiveing-service-report" },
    { text: "ItemStatusReport", navigation: "report/purchasereport/item-status-report" },
    { text: "BOMOrderReport", navigation: "report/purchasereport/bom-order-report" },
    // { text: "รายงานสรุปใบสั่งซื้อ", navigation: "report/purchasereport/summary-purchase-order-product-report" },
  ];

  public SaleSystemProfile: Array<{ text: string; navigation: string }> = [
    { text: "SummaryQuotationReportProduct", navigation: "report/salereport/summary-quotation-product-report" },
    { text: "SummarySaleOrderReport", navigation: "report/salereport/summary-sale-order-report" },
    { text: "SummaryDeliveryOrderTaxInvoiceReport", navigation: "report/salereport/summary-delivery-order-tax-Invoice-report" },
    { text: "SummaryServiceQuotationReport", navigation: "report/salereport/summary-service-quotation-report" },
    { text: "SummarySaleOrderReportService", navigation: "report/salereport/summary-sale-order-service-report" },
    { text: "SummaryServiceBillingNoteReport", navigation: "report/salereport/summary-service-billing-note-report" },
    { text: "SummaryReceiptTaxInvoiceReport", navigation: "report/salereport/summary-receipt-tax-invoice-service-report" },
    { text: "RSO37", navigation: "report/salereport/summary-deposit-receive-report" },
    { text: "RSR001", navigation: "report/salereport/sale-receive-report" },
    { text: "ServiceCost", navigation: "report/salereport/service-cost-report" },
    { text: "QUFlow", navigation: "report/salereport/qu-flow-report" },
    { text: "ConsignmentRecordReport", navigation: "report/salereport/consignment-record-report" },
    { text: "SaleLeadReport", navigation: "report/salereport/sale-lead-report" },
    { text: "SaleConsignReport", navigation: "report/salereport/sale-consign-report" },
    //{ text: "OMReport", navigation: "report/salereport/summary-total-amount-for-om-report" }, //ยังไม่ใช้
  ];

  public FN09: Array<{ text: string; navigation: string }> = [
    // ระบบการเงินจ่าย
    { text: "SummaryBillingPaymentReport", navigation: "report/financereport/summary-billing-payment-report" },
    { text: "SummaryPaymentVoucherReport", navigation: "report/financereport/summary-payment-voucher-report" },
    { text: "ReimbursementPettyCash", navigation: "report/financereport/reimbursement-petty-cash" },
    { text: "RFN15", navigation: "report/financereport/summary-purchase-payment-system-by-supplier-report" },
    { text: "SummaryCreditNotefromSupplierReport", navigation: "report/financereport/summary-credit-note-from-supplier-report" },
    { text: "SummaryPettyCashReport", navigation: "report/financereport/summary-petty-cash-report" },
    { text: "MovingPettyCash", navigation: "report/financereport/condition-print" },
    { text: "RFN52", navigation: "report/financereport/rfn52" },
    { text: "RFN54", navigation: "report/financereport/rfn54" },
    { text: "RFN55", navigation: "report/financereport/rfn55" },
    { text: "RFN58", navigation: "report/financereport/rfn58" },
    { text: "IncomeReport", navigation: "report/financereport/income-report" },
    { text: "APReport", navigation: "report/financereport/ar-report" },
  ];

  public FN02: Array<{ text: string; navigation: string }> = [
    // ระบบการเงินรับ
    { text: "SummaryBillingNoteReport", navigation: "report/financereport/summary-billing-note-report" },
    /* { text: "OutstandingBillingReport", navigation: "report/financereport/outstanding-billing-report" }, */
    { text: "SummaryCreditNoteReport", navigation: "report/financereport/summary-credit-note-report" },
    { text: "SummaryReceiptReport", navigation: "report/financereport/summary-receipt-report" },
    { text: "SummaryReceiptTaxInvoiceReport", navigation: "report/financereport/summary-receipt-tax-invoice-report" },
    { text: "SummaryReceiveVoucherReport", navigation: "report/financereport/summary-receive-voucher-report" },
    { text: "SummaryTotalAmountforOtherRevenueReport", navigation: "report/financereport/summary-total-amount-for-other-revenue-report" },
    { text: "RFN53", navigation: "report/financereport/summary-sale-receive-system-report" },
  ];

  public InventorySystem: Array<{ text: string; navigation: string }> = [
    { text: "RIV05", navigation: "report/inventoryreport/product-adjust-invoice-report" },
    { text: "RIV06", navigation: "report/inventoryreport/inventory-summary-value-report" },
    { text: "RIV05S", navigation: "report/inventoryreport/inventory-summary-value-no-price-report" },
    { text: "RIV07", navigation: "report/inventoryreport/summary-product-purchase-report" },
    { text: "RIV10", navigation: "report/inventoryreport/product-movement-report" },
    { text: "RIV26", navigation: "report/inventoryreport/product-movement-noprice-report" },
    { text: "RIV22", navigation: "report/inventoryreport/summary-internalissue-delivery-report" },
    { text: "RIV23", navigation: "report/inventoryreport/product-return-master-report" },
    { text: "RIV50", navigation: "report/inventoryreport/summary-request-internal-issue-report" },
    { text: "RIV40", navigation: "report/inventoryreport/summary-internal-issue-report" },
    { text: "RIV51", navigation: "report/inventoryreport/summary-request-internal-receive-report" },
    { text: "RIV41", navigation: "report/inventoryreport/summary-internal-receive-report" },
    { text: "RIV24", navigation: "report/inventoryreport/warehouse-transferring-invoice-report" },
    { text: "RawMatMovementByMonth", navigation: "report/inventoryreport/raw-mat-movement-by-month-report" },
    { text: "SummaryProductMovementCustomerReport", navigation: "report/inventoryreport/summary-product-movement-customer-report" },
    { text: "รายงาน Lot สินค้า", navigation: "report/inventoryreport/product-lot-report" },
    { text: "ProductTransportReport", navigation: "report/inventoryreport/product-transport-report" },
    { text: "ProductRequestTransferWarehouseReport", navigation: "report/inventoryreport/product-request-transfer-warehouse-report" },
    { text: "CheckStockReport", navigation: "report/inventoryreport/check-stock-report" },
    { text: "ProdRequestDeliveryReport", navigation: "report/inventoryreport/prod-request-delivery-report" },
    { text: "DeliveryTrackingReport", navigation: "report/inventoryreport/delivery-tracking-report" },
    { text: "ProcessGroupReport", navigation: "report/inventoryreport/process-group-report" },
    { text: "ProductAgingReport", navigation: "report/inventoryreport/product-aging-report" },
    { text: "LotProductRawMaterialReport", navigation: "report/inventoryreport/lotproduct-raw-material-report" },
    { text: "SerialMovement", navigation: "report/inventoryreport/serial-movement-report" },
  ];

  public InventorySystemForPerpetual: Array<{ text: string; navigation: string }> = [
    { text: "CheckcostProductReport", navigation: "report/inventoryreport/checkcost-product-report" },
  ];

  public ProcessSystem: Array<{ text: string; navigation: string }> = [
    { text: "RIV30", navigation: "report/productionreport/product-complete-receive-report" },
    { text: "RIV31", navigation: "report/productionreport/receive-from-mfg-report" },
    { text: "RIV33", navigation: "report/productionreport/saleorder-to-mfg-report" },
    { text: "RIV34", navigation: "report/productionreport/rawmat-in-proces-and-reorder-report" },
    { text: "RIV36", navigation: "report/productionreport/job-order-mfg-report" },
    { text: "ProductionCostsReport", navigation: "report/productionreport/production-costs-report" },
    { text: "ProductRemainingonProductionReport", navigation: "report/productionreport/product-remaining-on-production-report" },
    { text: "INDIRECTJOBCOSTREPORT", navigation: "report/productionreport/indirect-job-cost-report" },
    { text: "ProductESTCOST", navigation: "report/productionreport/product-est-cost-report" },
    { text: "WorkInProcessReport", navigation: "report/productionreport/work-in-process-report" },
    { text: "ProcessReport", navigation: "report/productionreport/process-report" },
    { text: "JobPlanReport", navigation: "report/productionreport/job-plan-report" },
    { text: "PrintJobCode", navigation: "report/productionreport/print-job-code-report" },
    { text: "ProductQualityReport", navigation: "report/productionreport/product-quality-report" },
    { text: "ProcessReport2", navigation: "report/productionreport/process-install-report" },
    { text: "InstallMachineReport", navigation: "report/productionreport/install-machine-report" },
    { text: "QcInspectReport", navigation: "report/productionreport/qc-inspect-report" },
    { text: "ProductionUnitReport", navigation: "report/productionreport/production-unit-report" },
    { text: "DefectCauseReport", navigation: "report/productionreport/defect-cause-report" },
    { text: "RequestProductReceiveReport", navigation: "report/productionreport/request-product-receive-report" },
    { text: "RequestProductReport", navigation: "report/productionreport/request-product-report" },
    { text: "SubjobReport", navigation: "report/productionreport/subjob-report" },
    { text: "ServiceConstructionReport", navigation: "report/productionreport/service-construction-report" },
    { text: "ProjectPlanReport", navigation: "report/productionreport/project-plan-report" },
    { text: "InstallBOQReport", navigation: "report/productionreport/install-boq-report" },
    { text: "RequestConstructionServiceReport", navigation: "report/productionreport/construction-service-req-report" },
    { text: "ConstructionServiceReport", navigation: "report/productionreport/construction-service-report" },
  ];

  public ProcessSystemForPerpetual: Array<{ text: string; navigation: string }> = [
    { text: "RIV30", navigation: "report/productionreport/product-complete-receive-report" },
    { text: "RIV31", navigation: "report/productionreport/receive-from-mfg-report" },
    { text: "RIV33", navigation: "report/productionreport/saleorder-to-mfg-report" },
    { text: "RIV34", navigation: "report/productionreport/rawmat-in-proces-and-reorder-report" },
    { text: "RIV36", navigation: "report/productionreport/job-order-mfg-report" },
    { text: "ProductionCostsReport", navigation: "report/productionreport/production-costs-report" },
    { text: "ProductRemainingonProductionReport", navigation: "report/productionreport/product-remaining-on-production-report" },
    { text: "INDIRECTJOBCOSTREPORT", navigation: "report/productionreport/indirect-job-cost-report" },
    { text: "ProductESTCOST", navigation: "report/productionreport/product-est-cost-report" },
    { text: "WorkInProcessReport", navigation: "report/productionreport/work-in-process-report" },
    { text: "ProcessReport", navigation: "report/productionreport/process-report" },
    { text: "JobPlanReport", navigation: "report/productionreport/job-plan-report" },
    { text: "PrintJobCode", navigation: "report/productionreport/print-job-code-report" },
    { text: "ProductQualityReport", navigation: "report/productionreport/product-quality-report" },
    { text: "ProcessReport2", navigation: "report/productionreport/process-install-report" },
    { text: "InstallMachineReport", navigation: "report/productionreport/install-machine-report" },
    { text: "QcInspectReport", navigation: "report/productionreport/qc-inspect-report" },
    { text: "ProductionUnitReport", navigation: "report/productionreport/production-unit-report" },
    { text: "DefectCauseReport", navigation: "report/productionreport/defect-cause-report" },
    { text: "RequestProductReceiveReport", navigation: "report/productionreport/request-product-receive-report" },
    { text: "RequestProductReport", navigation: "report/productionreport/request-product-report" },
    { text: "SubjobReport", navigation: "report/productionreport/subjob-report" },
    { text: "ServiceConstructionReport", navigation: "report/productionreport/service-construction-report" },
    { text: "ProjectPlanReport", navigation: "report/productionreport/project-plan-report" },
    { text: "InstallBOQReport", navigation: "report/productionreport/install-boq-report" },
    { text: "RequestConstructionServiceReport", navigation: "report/productionreport/construction-service-req-report" },
    { text: "ConstructionServiceReport", navigation: "report/productionreport/construction-service-report" },
    { text: "EstimateCostCompareActualCost", navigation: "report/productionreport/estimate-cost-compare-report" },
  ];

  public AccountSystem: Array<{ text: string; navigation: string }> = [
    { text: "VoucherBook", navigation: "report/accountreport/voucher-book-report" },
    // {text:"GeneralJournal"  , navigation:"report/accountreport/GeneralJournal"},
    // {text:"CashSaleBook"  , navigation:"report/accountreport/CashSaleBook"},
    // {text:"CreditSaleBook"  , navigation:"report/accountreport/CreditSaleBook"},
    // {text:"CreditPurchaseBook"  , navigation:"report/accountreport/CreditPurchaseBook"}-led,
    { text: "GeneralLedger", navigation: "report/accountreport/general-ledger" },
    // {text:"CashReceiveBook"  , navigation:"report/accountreport/CashReceiveBook"},
    // {text:"BankReceiveBook"  , navigation:"report/accountreport/BankReceiveBook"},
    // {text:"CashPaymentBook"  , navigation:"report/accountreport/CashPaymentBook"},
    // {text:"BankPaymentBook"  , navigation:"report/accountreport/BankPaymentBook"},
    // {text:"AllAccountBook"  , navigation:"report/accountreport/AllAccountBook"},
    { text: "VoucherReport", navigation: "report/accountreport/voucher-report" },
    { text: "TrailBalance", navigation: "report/accountreport/trial-balance" },
    { text: "WorkingPaper", navigation: "report/accountreport/working-paper" },
    { text: "IncomeStatementReport", navigation: "report/accountreport/income-statement-report" },
    // { text: "RAC27", navigation: "report/accountreport/rac27" },
    { text: "StatementsOfFinancialClassification",  navigation: "report/accountreport/statement-of-financial-clissification" },
    { text: "RAC30", navigation: "report/accountreport/rac30" },
    { text: "RAC25", navigation: "report/accountreport/cash-flow-report" },
    { text: "EditedDataReport", navigation: "report/accountreport/edited-data-report" },
    { text: "RAC10", navigation: "report/accountreport/history-edited-delete-data-report" },
    // { text: "RAC31", navigation: "report/accountreport/rac31" },
  ];

  public ChequeSystem: Array<{ text: string; navigation: string }> = [
    { text: "SummaryReceiveChequeReport", navigation: "report/chequereport/summary-receive-cheque-report" },
    { text: "RCQ10", navigation: "report/chequereport/summary-movement-cheque-report" },
    { text: "RCQ11", navigation: "report/chequereport/summary-payment-cheque-report" },
    //{ text: "RCQ15", navigation: "report/chequereport/report-payment-cheque-onhand" },
  ];

  public SystemAsset: Array<{ text: string; navigation: string }> = [
    { text: "AssetCodeReport", navigation: "report/assetreport/asset-code-report" },
    { text: "CalculateDepreciationReport", navigation: "report/assetreport/calculate-depreciation-report" },
    { text: "AssetSaleReport", navigation: "report/assetreport/asset-sale-report" },
    { text: "GCSIPA01", navigation: "report/assetreport/asset-group-type-report" },
  ];
  public LogTracing: Array<{ text: string; navigation: string }> = [
    { text: "CreateAndEditReport", navigation: "report/logtracingreport/create-edit-document-report" },
    { text: "ApproveDocumentReport", navigation: "report/logtracingreport/approve-document-report" },
  ];

  public CompanyInformation: Array<{ text: string; navigation: string }> = [
    { text: "MasterCompanyData", navigation: "setup/companyinformation/company" },
    { text: "CompanyConsolidate", navigation: "setup/companyinformation/company-consolidate" },
    { text: "CP04", navigation: "setup/companyinformation/division" },
    { text: "SectionProfile", navigation: "setup/companyinformation/section" },
    { text: "AccountPeriodInfo", navigation: "setup/account/account-period" },
    { text: "DocumentGroup", navigation: "setup/companyinformation/document-group" },
    // {text:"SetupDateFormatandVatRate"  , navigation:"setup/SetupDateFormatandVatRate"}
  ];

  public UserProfile: Array<{ text: string; navigation: string }> = [
    { text: "Position", navigation: "setup/userprofile/position" },
    { text: "EmpCode", navigation: "setup/userprofile/employee-master" },
    // { text: "EmpDetail", navigation: "setup/person" },
  ];

  public UserAuthorization: Array<{ text: string; navigation: string }> = [
    { text: "ChangePassword", navigation: "setup/userautho/changepassword" },
    // {text:"EditPersonalInformation"  , navigation:"employee-master"},
    //  {text:"DefaultSystemSetting"  , navigation:"DefaultSystemSetting"},
    // {text:"SetupUserAuthorization"  , navigation:"setup/setup-user"},
    { text: "AuthenSystem", navigation: "setup/userautho/authenbysystem" },
    /* {text:"StandardAuthen"  , navigation:"StandardAuthen"}, */
    { text: "AuthenEmployee", navigation: "setup/userautho/authorization-by-user" },
    { text: "InputMinMax", navigation: "setup/userautho/InputMinMax" },
  ];

  public Manual: Array<{ text: string; navigation: string }> = [
    { text: "Flow-AccCloud", navigation: "setup/Flow-AccCloud" },
    { text: "OSM", navigation: "setup/OSM" },
    { text: "TCSM", navigation: "setup/TCSM" },
    { text: "MCSM", navigation: "setup/MCSM" },
    { text: "SCSM", navigation: "setup/SCSM" },
    { text: "Manual1", navigation: "setup/Manual1" },
    { text: "Manual2", navigation: "setup/Manual2" },
    { text: "Manual3", navigation: "setup/Manual3" },
    { text: "WHMSM", navigation: "setup/WHMSM" },
    { text: "Manual4", navigation: "setup/Manual4" },
    { text: "Manual5", navigation: "setup/Manual5" },
    { text: "SetupEmail", navigation: "setup/SetupEmail" },
    { text: "SendEmailSystemManual", navigation: "setup/SendEmailSystemManual" },
    { text: "PopUp", navigation: "setup/PopUp" },
  ];

  public SetupAccount: Array<{ text: string; navigation: string }> = [
    { text: "AssignAccountCode", navigation: "setup/account/account-code" },
    { text: "DescriptionDetail", navigation: "setup/account/account-division" },
    { text: "AccountSubType", navigation: "setup/account/account-main" },
    { text: "AssignSaveAccountTemplate", navigation: "setup/account/account-template-all" },
    { text: "SM75", navigation: "setup/account/account-define" },
    { text: "SetupAdvancePayment", navigation: "setup/account/setup-advance-pm" },
    { text: "CostCenter", navigation: "setup/account/cost-center" },
    { text: "AccountAlloc", navigation: "setup/account/account-alloc" },
    { text: "CSG", navigation: "setup/account/account-setup" },
  ];

  public Product: Array<{ text: string; navigation: string }> = [
    { text: "ProductGroup", navigation: "setup/products/product-group" },
    { text: "ProductCategory", navigation: "setup/products/product-sub-group1" },
    { text: "ProductUnit", navigation: "setup/products/unit-of-measure" },
    { text: "AssignWarehouseCode", navigation: "setup/products/warehouse" },
    { text: "Location", navigation: "setup/products/location" },
    { text: "AssignProductModel", navigation: "setup/products/product-model" },
    { text: "ProductCode", navigation: "setup/products/product" },
    { text: "BillofMeterailBOM", navigation: "setup/products/product-complementary" },
    // { text: "productEcommerce", navigation: "setup/product-ecommerce" },
    { text: "standardCost", navigation: "setup/products/standard-cost" },
    { text: "PriceList", navigation: "setup/products/price-list" },
    { text: "priceAdjustment", navigation: "setup/products/price-edit-record" },
    { text: "reorderPoint", navigation: "setup/products/reorder-point" },
    { text: "PackageType", navigation: "setup/products/package-type"},
    { text: "ProductBrand", navigation: "setup/products/product-brand" },
    { text: "ProductSize", navigation: "setup/products/product-size" },
    { text: "ProductPattern", navigation: "setup/products/product-pattern" },
    { text: "ProductGrade", navigation: "setup/products/product-grade" },
    { text: "ProductOtherGroup", navigation: "setup/products/product-othergroup" },
  ];

  public Service: Array<{ text: string; navigation: string }> = [
    { text: "ServiceGroup", navigation: "setup/services/service-group" },
    { text: "ServiceType", navigation: "setup/services/service-type" },
    { text: "ServiceCode", navigation: "setup/services/service" },
    { text: "SettingServiceTeam", navigation: "setup/services/service-team" },
  ];

  public Purchase: Array<{ text: string; navigation: string }> = [
    { text: "SupplierGroup", navigation: "setup/purchase/supplier-group" },
    { text: "Supplier", navigation: "setup/purchase/supplier" },
    { text: "AccountPayableBalanceBringtForward", navigation: "setup/purchase/opening-entry-supplier" },
  ];

  public Sale: Array<{ text: string; navigation: string }> = [
    { text: "CustomerGroup", navigation: "setup/sale/customer-group" },
    { text: "CUSTCODE", navigation: "setup/sale/customer" },
    { text: "initAR", navigation: "setup/sale/opening-entry-customer" },
    { text: "Member", navigation: "setup/sale/member" },
  ];

  public Transport: Array<{ text: string; navigation: string }> = [
    { text: "TransportCode", navigation: "setup/transport/deliver" },
    { text: "TransportFromSO", navigation: "setup/transport/transport-from-so" },
    { text: "TransportFromDO", navigation: "setup/transport/transport-from-do" },
    { text: "TransportFromService", navigation: "setup/transport/transport-service" },
    { text: "TransportArea", navigation: "setup/transport/transport-area" },
    { text: "TransportObjective", navigation: "setup/transport/transport-objective" },
  ];

  public SaleArea: Array<{ text: string; navigation: string }> = [
    { text: "SaleAreaGroup", navigation: "setup/salearea/sale-area" },
    // { text: "SetupSaleAreaGroup"  , navigation:"setup/setup-sale-area-group"}
  ];

  public Bank: Array<{ text: string; navigation: string }> = [
    { text: "AssignBankCode", navigation: "setup/banks/bank" },
    { text: "AssignBankBranch", navigation: "setup/banks/bank-branch" },
    { text: "DepositBookProfile", navigation: "setup/banks/deposit-book-master" },
  ];

  public Boq: Array<{ text: string; navigation: string }> = [
    { text: "BoqGroup", navigation: "setup/boqs/boq-group" },
    { text: "BoqSubGroup", navigation: "setup/boqs/boq-sub-group" },
    { text: "BOQ", navigation: "setup/boqs/boq" },
    { text: "ProjectPlan", navigation: "setup/boqs/project-plan" },
  ];

  public Job: Array<{ text: string; navigation: string }> = [
    { text: "JobGroupCode", navigation: "setup/job/job-group" },
    { text: "JobCode", navigation: "setup/job/job-code" },
    { text: "SubJob", navigation: "productionsystem/sub-job" },
    { text: "JobExpense", navigation: "setup/job/job-expense" },
    { text: "SetReceivingType", navigation: "setup/job/setreceivingtype" },
  ];

  public JobForPerpetual: Array<{ text: string; navigation: string }> = [
    { text: "JobGroupCode", navigation: "setup/job/job-group" },
    { text: "JobCode", navigation: "setup/job/job-code" },
    { text: "SubJob", navigation: "productionsystem/sub-job" },
    { text: "JobExpense", navigation: "setup/job/job-expense" },
    { text: "ActualCosting", navigation: "setup/account/actual-costing" },
    { text: "SetReceivingType", navigation: "setup/job/setreceivingtype" },
  ];

  public Tax: Array<{ text: string; navigation: string }> = [
    { text: "AssignNameinVatReport", navigation: "setup/tax/nameinvat" },
    { text: "WithholdingTax", navigation: "setup/tax/with-holding-tax" },
  ];

  public Currencey: Array<{ text: string; navigation: string }> = [
    { text: "NationalCurrencey", navigation: "setup/currencys/currency" },
  ];

  public Import: Array<{ text: string; navigation: string }> = [
    { text: "PrepareDataExcel", navigation: "setup/preparedata/prepare-data-excel" },
  ];

  @ViewChildren(IgxExpansionPanelComponent)
  public accordion: QueryList<IgxExpansionPanelComponent>;

  public collapsed(index: number) {
    if (!this.accordion) {
      return true;
    }
    return (
      this.accordion.toArray()[index] &&
      this.accordion.toArray()[index].collapsed
    );
  }

  public onInteraction(event) {
    const expandedPanels = this.accordion.filter((panel) => !panel.collapsed);
    expandedPanels.forEach((expandedPanel) => {
      if (expandedPanel.id !== event.owner.id) {
        expandedPanel.collapse();
      }
    });
  }

  public async onClick(navigation: string) {
    while (sessionStorage.getItem("session") == null) {
      await this.delay(300);
    }
    if (navigation == "") {
      this.http
        .post<any>("/api/Login/Authentication/CheckUserLogout", {
          tblUserLoginId: this.userInSessionStorage.tblUserLoginId,
        })
        .subscribe((data) => {});
      this.http.get<any>("/api/authen/logout").subscribe((data) => {
        localStorage.clear();
        this._router.navigate(["login"]);
      });
    } else if (navigation == "warehouse-management") {
      const url = this._router.createUrlTree([navigation + "/login"]);
      window.open(url.toString(), "_blank");
    } else if (navigation == "setup/api-document") {
      const url = this._router.createUrlTree([navigation + "/"]);
      window.open(url.toString(), "_blank");  //_blank open in new tab
    } else if (navigation == "V.1") {
      window.location.href = "http://27.254.76.226/acconline/Acc_Login.aspx";
    } else if (navigation.includes("https://www.youtube.com")) {
      window.open(navigation.toString());
    } else if (navigation.includes("https://drive.google.com")) {
      window.open(navigation.toString());
    }else if (navigation.includes("https://analysis.acccloud.me")) {
      window.open(navigation.toString());
    }else if (navigation.includes("https://application.acccloud.me")) {
      window.open(navigation.toString());
    }else if (navigation.includes("https://acccloud.me/crm/login")){
      window.open(navigation.toString());
    }
    else if (navigation.includes("e-commerce")) {
      const url = this._router.createUrlTree([navigation + "/" + this.userInSessionStorage.companyCode.toLowerCase()]);
      window.open(url.toString(), "_blank");
    } else if (navigation.includes("payroll/calendar-company")) {
      const url = this._router.createUrlTree([navigation]);
      window.open(url.toString(), "_blank");
    } else if (navigation.includes("faq/faq-center")) {
      this._router.navigate([navigation]).then(() => { window.location.reload() });
    }else {
      this._router.navigate([navigation]);
    }
  }

  public delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  public toggle(x: any) {
    switch (x) {
      case "dashboard":
        this.collapseModel.dashboard = false;
        break;
      case "company":
        this.collapseModel.companyProfile = false;
        break;
      case "setup":
        this.collapseModel.setupProfile = false;
        break;
      case "report":
        this.collapseModel.reportAndAnalysis = false;
        break;
      case "analysis":
        this.collapseModel.analysis = false;
        break;
      case "extension":
        this.collapseModel.extension = false;
        break;
      case "support":
        this.collapseModel.support = false;
        break;
      default:
        break;
    }
  }
  public closeSideNav() {
    this.collapseModel.dashboard = true;
    this.collapseModel.companyProfile = true;
    this.collapseModel.setupProfile = true;
    this.collapseModel.reportAndAnalysis = true;
    this.collapseModel.support = true;
    this.collapseModel.analysis = true;
  }
}
