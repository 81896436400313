import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { Card } from './card.blueprint';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  public authen: any;

  constructor(private _location: Location) { }

  ngOnInit(): void {
  }
    public card = new Card({
        buttons: ["Back"],
        icons: ["report_problem"],
        subtitle: "This page is under construction, Try Again Later.",
        title: "404 Not Found"
    });
    backClicked() {
      this._location.back();
    }

}
