<div class="content-wrap" igxLayout>
	<main igxFlex>
		<app-sidenav></app-sidenav>
	</main>
	<main igxFlex>
        <app-header></app-header>
        <body oncontextmenu="return false;">
            <div [translate] class="header" igxLayout igxLayoutJustify="flex-start" style="margin-top: 20%;margin-left: 40%;">Youarenotanauthorizeduser</div>
        </body>
	</main>
</div>


