export const menuweb = {
  homepage: [
    {
      system: "homepagedocumentapprovalsystem",
      page: "homepage-document-approval-system",
    },
    {
      system: "homepage",
      page: "homepage-company-status",
    },
    {
      system: "homepage",
      page: "homepage-salesystem",
    },
    {
      system: "homepage",
      page: "homepage-receivesystem",
    },
    {
      system: "homepage",
      page: "homepage-purchasesystem",
    },
    {
      system: "homepage",
      page: "homepage-paymentsystem",
    },
    {
      system: "homepage",
      page: "homepage-warehousesystem",
    },
    {
      system: "homepage",
      page: "homepage-productionsystem",
    },
    {
      system: "homepage",
      page: "homepage-transportationsystem",
    },
    {
      system: "homepage",
      page: "homepage-track-employee",
    },
    {
      system: "homepage",
      page: "homepage-servicesystem",
    },
    {
      system: "homepage",
      page: "homepage-jobtracking",
    },
  ],
  productreceiveinvoice: [
    {
      system: "purchasesystem",
      page: "product-receive-invoice",
    },
    {
      system: "purchasesystem",
      page: "product-receive-invoice-noprice",
    },
  ],
  purchaseorder: [
    {
      system: "purchasesystem",
      page: "purchase-order",
    },
    {
      system: "purchasesystem",
      page: "purchase-order-noprice",
    },
  ],
  purchaserequestinvoice: [
    {
      system: "purchasesystem",
      page: "purchase-request-invoice",
    },
    {
      system: "purchasesystem",
      page: "purchase-request-invoice-noprice",
    },
  ],
  pettycash: [
    {
      system: "paymentsystem",
      page: "petty-cash",
    },
    {
      system: "paymentsystem",
      page: "expensegenaral",
    },
  ],
  otherexpense: [
    {
      system: "paymentsystem",
      page: "other-expense",
    },
    {
      system: "paymentsystem",
      page: "setupdebtsupplier",
    },
  ],
  // registercheque: [{
  //     system: 'finance',
  //     page: 'registerchequepayment'
  // }, {
  //     system: 'finance',
  //     page: 'registerchequereceive'
  // }],
  homepagedocumentapprovalsystem: [
    {
      system: "homepagedocumentapprovalsystem",
      page: "homepage-document-approval-system",
    },
  ],
  company: [
    {
      system: "companyinfo",
      page: "company",
    },
  ],
  division: [
    {
      system: "companyinfo",
      page: "division",
    },
  ],
  section: [
    {
      system: "companyinfo",
      page: "section",
    },
  ],
  accountperiod: [
    {
      system: "companyinfo",
      page: "account-period",
    },
  ],
  documentgroup: [
    {
      system: "companyinfo",
      page: "document-group",
    },
  ],
  companyconsolidate: [
    {
      system: "companyinfo",
      page: "company-consolidate",
    },
  ],
  employeegroup: [
    {
      system: "companyinfo",
      page: "employee-group",
    },
  ],
  employeemaster: [
    {
      system: "companyinfo",
      page: "employee-master",
    },
  ],
  person: [
    {
      system: "companyinfo",
      page: "person",
    },
  ],
  position: [
    {
      system: "companyinfo",
      page: "position",
    },
  ],
  changepassword: [
    {
      system: "companyinfo",
      page: "changepassword",
    },
  ],
  authenbysystem: [
    {
      system: "companyinfo",
      page: "authenbysystem",
    },
  ],
  authorizationbyuser: [
    {
      system: "companyinfo",
      page: "authorization-by-user",
    },
  ],
  InputMinMax: [
    {
      system: "companyinfo",
      page: "InputMinMax",
    },
  ],
  managementdashboard: [
    {
      system: "dashboard",
      page: "management-dashboard",
    },
  ],
  saledashboard: [
    {
      system: "dashboard",
      page: "sale-dashboard",
    },
  ],
  procurementdashboard: [
    {
      system: "dashboard",
      page: "procurement-dashboard",
    },
  ],
  financedashboard: [
    {
      system: "dashboard",
      page: "finance-dashboard",
    },
  ],
  productiondashboard: [
    {
      system: "dashboard",
      page: "production-dashboard",
    },
  ],
  homepagepurchasesystem: [
    {
      system: "homepage",
      page: "homepage-purchasesystem",
    },
    {
      system: "purchasesystem",
      page: "purchase-request-invoice",
    },
  ],
  order: [
    {
      system: "open-api",
      page: "order",
    },
    {
      system: "salesystem",
      page: "sale-order-product",
    },
  ],
  home: [
    {
      system: "open-api",
      page: "order",
    },
  ],
  shop: [
    {
      system: "open-api",
      page: "order",
    },
  ],
  menu: [
    {
      system: "open-api",
      page: "order",
    },
  ],
  updatestock: [
    {
      system: "open-api",
      page: "order",
    },
  ],
  invoiceinfo: [
    {
      system: "open-api",
      page: "order",
    },
  ],
  orderdetails: [
    {
      system: "open-api",
      page: "order",
    },
  ],
  productprice: [
    {
      system: "center",
      page: "productprice",
    },
  ],
  voucherbook: [
    {
      system: "account",
      page: "voucher-book",
    },
    {
      system: "account",
      page: "general-journal",
    },
    {
      system: "account",
      page: "jegplus",
    },
    {
      system: "account",
      page: "bank-book",
    },
    {
      system: "account",
      page: "cash-book",
    },
    {
      system: "account",
      page: "cash-payment-book",
    },
    {
      system: "account",
      page: "bank-payment-book",
    },
    {
      system: "account",
      page: "credit-purchase-book",
    },
    {
      system: "account",
      page: "credit-sale-book",
    },
    {
      system: "account",
      page: "opening-entry-book",
    },
    {
      system: "account",
      page: "return-voucher-book",
    },
  ],
};
