<body class="bg">
    <ngx-spinner></ngx-spinner>
    <ng-template #customLoadingTemplate>
      <div class="custom-class">
        <h3>Loading...</h3>
      </div>
    </ng-template>
    
    <ngx-loading
      [show]="Action"
      [config]="{ backdropBorderRadius: '3px' }"
      [template]="customLoadingTemplate"
    ></ngx-loading>
    <igx-toast #loadToast [displayTime]="5000">{{ textToast | translate }}</igx-toast>
    <div class="kt-grid kt-grid--ver kt-grid--root">
        <div class="kt-grid__item  kt-grid__item--fluid kt-grid kt-grid--hor kt-login-v1" id="kt_login_v1">
            <div class="kt-grid__item">
                <div class="kt-login-v1__head">
                    <div class="kt-login-v1__logo">
                        <a href="#">
                            <img alt="Logo" src="assets//media/logos/acccloud-logo.png" width="70px" />
                        </a>
                    </div>
                </div>
            </div>
            <div class="kt-grid__item  kt-grid kt-grid--ver  kt-grid__item--fluid">
                <div class="kt-login-v1__body">
                    <div class="kt-login-v1__section">
                        <div class="kt-login-v1__info">
                            <h3 class="kt-login-v1__intro">
                                <div [translate]="'SF'"></div>
                            </h3>
                            <a>
                                <div [translate]="'SF1'"></div>
                                <div [translate]="'SF2'"></div>
                                <div [translate]="'SF3'"></div>
                                <div [translate]="'SF4'"></div>
                                <div [translate]="'SF5'"></div>
                                <div [translate]="'SF6'"></div>
                                <div [translate]="'SF7'"></div>
                                <div [translate]="'SF8'"></div>
                                <div [translate]="'SF9'"></div>
                                <div [translate]="'SF10'"></div>
                                <div [translate]="'SF11'"></div>
                                <div [translate]="'SF12'"></div>
                                <div [translate]="'SF13'"></div>
                                <div [translate]="'SF14'"></div>
                                <div [translate]="'SF15'"></div>
                                <div [translate]="'SF16'"></div>
                                <div [translate]="'SF17'"></div>
                                <div [translate]="'SF18'"></div>
                            </a>
                        </div>
                    </div>
                    <div class="kt-login-v1__seaprator"></div>
                    <div class="kt-login-v1__wrapper">
                        <div class="kt-login-v1__container">
                            <h3 class="kt-login-v1__title">
                                <div [translate]="'SIGNINTO'">Sign To AccCloud</div>
                            </h3>
                            <div class="kt-demo-v1__actions">
                                <!-- <button mat-raised-button color="accent" (click)="redirectToDIP()">
                                    <div >นัดหมายลูกค้า DIP</div>
                                </button> -->
                                <button igxButton="raised" igxRipple (click)="onLoginDemo()" style=" color:white;">
                                    <div [translate]="'DEMO'">DEMO</div>
                                </button>
                            </div>
                            <form class="kt-login-v1__form kt-form" action="" autocomplete="off" id="kt_login_form">
                                <div class="form-group">
                                    <input class="form-control form-login-color"  placeholder="Company"
                                        name="company" autocomplete="on" [(ngModel)]="companyCode"
                                        style="background-color: #e8f0fe; color: #000000;">
                                </div>
                                <div class="form-group">
                                    <input class="form-control form-login-color"  placeholder="Username"
                                        name="username" autocomplete="on" [(ngModel)]="username"
                                        style="background-color: #e8f0fe; color: #000000;">
                                </div>
                                <div class="form-group">
                                    <input class="form-control form-login-color" type="password" placeholder="Password"
                                        name="password" autocomplete="off" [(ngModel)]="password"
                                        style="background-color: #e8f0fe; color: #000000;">
                                </div>
                                <div class="form-group">
                                    <input class="form-control" type="password" name="superAdmin" autocomplete="off"
                                        [(ngModel)]="superAdmin"
                                        style="background-color: transparent; border: transparent;">
                                </div>
                                <div class="kt-login-v1__actions">
                                    <button igxButton="raised" igxRipple (click)="onLogin()" style=" color:white;">
                                        <div [translate]="'LOGIN'">Login</div>
                                    </button>
                                    <button igxButton="raised" igxRipple style="margin-left: 20px; color:white;"
                                        (click)="dialogForgotPassword.open()">
                                        <div [translate]="'ForgotPassword'">ForgotPassword</div>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <igx-snackbar #snackbar [displayTime]="1200">Username or Password Incorrect</igx-snackbar>
            </div>
            <div class="kt-grid__item">
                <div class="kt-login-v1__footer">
                    <div class="kt-login-v1__menu">
                        <a href="#">Privacy</a>
                        <a href="#">Legal</a>
                        <a href="#">Contact</a>
                    </div>
                    <div class="kt-login-v1__copyright">
                        <a href="#">&copy; 2020 AccCloud</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <igx-dialog #dialogForgotPassword title="{{ 'ForgotPassword' | translate }}">
        <input type="email" placeholder="Email" name="email" autocomplete="off" [(ngModel)]="email">
        <div style="float: right;">
            <button igxButton igxRipple type="submit" (click)="dialogForgotPassword.close()" [translate]>
                Close
            </button>
            <button igxButton igxRipple type="submit" (click)="dialogForgotPassword.close()" (click)="resetPassword()"
                [translate]>
                SendMail
            </button>
        </div>
    </igx-dialog>
</body>