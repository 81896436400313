import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Card } from './card.blueprint';

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent implements OnInit {
  public authen: any;

  constructor( private _router: Router) { }

  ngOnInit(): void {
  }
    public card = new Card({
        buttons: ["Home"],
        content: "notauthcontent",
        icons: ["privacy_tip"],
        subtitle: "notauthsubtitle",
        title: "notauthtitle"
    });
    backClicked() {
      this._router.navigate(["homepage"]);
    }

}
