import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { sha256 } from 'js-sha256';

import { Card } from './card.blueprint';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public authen: any;
  public email;
  public sign;
  public password;
  public passwordConfirm;
  public username = ''
  public companyCode = ''
  public userInSessionStorage;


  constructor(private _router: Router, private http: HttpClient) { }

  ngOnInit(): void {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.email = urlParams.get('email')
    this.sign = urlParams.get('sign')
    var key = '1a1d5c4d22dcfc41a8f1d18bc87e32e38296538ff249678fe0ee399122d900f0'
    var sign = sha256.hmac(key, this.email)
    if (sign != this.sign) {
      this._router.navigate(["/login"]);
    } else {
      this.http.post<any>("/api/Center/TblUserLogin/getByEmail", { email: this.email }).subscribe(async (data) => {
        if (data.length != 0) {
          if (data.length == 1) {
            this.userInSessionStorage = data[0]
            this.username = data[0].username
            this.companyCode = data[0].companyCode
          }
        }
      });
    }
  }
  public card = new Card({
    buttons: ["Ok"],
    icons: ["privacy_tip"],
    title: "ChangePassword"
  });
  backClicked() {
    if (this.password != this.passwordConfirm) {
      alert('รหัสผ่านใหม่ทั้ง 2 ไม่ตรงกัน');
      return false;
    } else {
      var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
      if (!this.password.match(decimal)) {
        alert('ไม่สามารถใช้รหัสผ่านนี้ได้ เนื่องจากรหัสผ่านต้องมีความยาว 8-20 ตัวอักษร และประกอบด้วยอักษรตัวพิมพ์เล็ก อักษรตัวพิมพ์ใหญ่ ตัวเลข และอักษรพิเศษ ')
        return false;
      } else {
        var key = this.userInSessionStorage.companyCode + this.userInSessionStorage.username
        var msg = this.password
        var sign = sha256.hmac(key, msg)
        if (sign == this.userInSessionStorage.password_encryption || sign == this.userInSessionStorage.password2 || sign == this.userInSessionStorage.password3 || sign == this.userInSessionStorage.password4) {
          alert('ไม่สามารถใช้รหัสผ่านนี้ได้ เนื่องจากรหัสผ่านนี้เคยถูกใช้งานแล้ว');
        } else {
          this.userInSessionStorage.password4 = this.userInSessionStorage.password3
          this.userInSessionStorage.password3 = this.userInSessionStorage.password2
          this.userInSessionStorage.password2 = this.userInSessionStorage.password_encryption
          this.userInSessionStorage.password_encryption = sign
          this.userInSessionStorage.password = msg
          this.userInSessionStorage.passwordExpiryDate = new Date().setDate(new Date().getDate() + 90)
          this.http.post<any>("/api/Center/TblUserLogin/update", [this.userInSessionStorage]).subscribe(async (data) => {
            if (data.length != 0) {
              alert('แก้ไขรหัสผ่านเรียบร้อย');
              this._router.navigate(["/login"]);
            }
          });
        }
      }
    }
  }

  public delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
