import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environments } from 'src/app/page/shared/variable-share';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private translate: TranslateService, private http: HttpClient) {
  }
  ngOnInit(): void {
    this.getEnvironment();
    this.translate.setDefaultLang('th-TH');
  }
  public getEnvironment() {
    this.http
      .get<any>("api/statusService/env")
      .subscribe((data) => {
        environments.environments = data.env;
        switch(data.env){
          case "dev":
            environments.url_report = "https://acccloudreport.me"
            break;
          case "local":
            environments.url_report = "https://acccloudreport.me"
            break;
          case "prod":
            environments.url_report = "https://acccloudreport.me"
            break;
          case "sit":
            environments.url_report = "https://acccloudreport.me"
            break;
        }
      }, (err) => {
        console.log(err);
      });
  }

}



