import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './page/not-found/not-found.component';


const routes: Routes = [
	{ path: 'login',component: LoginComponent},
	{ path: '', redirectTo: 'page', pathMatch: 'full'},
	{
		path:'payroll',
		loadChildren:()=>import('../app/payroll/payroll.module').then(m=>m.PayrollModule)
	},
	{
		path:'pos',
		loadChildren:()=>import('../app/pos/pos.module').then(m=>m.PosModule)
	},
	{
		path:'e-commerce',
		loadChildren:()=>import('../app/e-commerce/e-commerce.module').then(m=>m.ECommerceModule)
	},
	{
		path:'warehouse-management',
		loadChildren:()=>import('../app/warehouse-management/warehouse-management.module').then(m=>m.WarehouseManagementModule)
	},
	{
		path:'crm',
		loadChildren:()=>import('../app/crm/crm.module').then(m=>m.CrmModule)
	},
	{
		path:'page',
		loadChildren:()=>import('../app/page/page.module').then(m=>m.PageModule)
	},
	{
		path:'**',component:NotFoundComponent,pathMatch:'full'
	},
];
@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
