import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  IgxAvatarModule,
  IgxBannerModule,
  IgxBottomNavModule,
  IgxButtonGroupModule,
  IgxButtonModule,
  IgxComboModule,
  IgxDividerModule,
  IgxDropDownModule,
  IgxExpansionPanelModule,
  IgxIconModule,
  IgxInputGroupModule,
  IgxLayoutModule,
  IgxNavbarModule,
  IgxNavigationDrawerModule,
  IgxRippleModule,
  IgxSimpleComboModule,
  IgxTabsModule,
  IgxToggleModule,
  IgxDialogComponent,
  IgxDialogModule
} from '@infragistics/igniteui-angular';
import { NgxSpinnerModule } from 'ngx-spinner';

import { FooterComponent } from './component/layout/footer/footer.component';
import { HeaderComponent } from './component/layout/header/header.component';
import { SidenavComponent } from './component/layout/sidenav/sidenav.component';
import { BarcodeComponent } from './component/layout/barcode/barcode.component';
import { CrmSidenavComponent } from './component/layout/crm-sidenav/crm-sidenav.component';

@NgModule({
  declarations: [FooterComponent, HeaderComponent, SidenavComponent, BarcodeComponent, CrmSidenavComponent],
  imports: [
    CommonModule,
    IgxIconModule,
    IgxNavbarModule,
    IgxButtonModule,
    IgxLayoutModule,
    IgxNavigationDrawerModule,
    IgxRippleModule,
    IgxToggleModule,
    TranslateModule,
    IgxExpansionPanelModule,
    IgxTabsModule,
    IgxBottomNavModule,
    NgxSpinnerModule,
    IgxDropDownModule,
    IgxButtonGroupModule,
    IgxInputGroupModule,
    IgxComboModule,
    IgxIconModule,
    IgxBannerModule,
    IgxAvatarModule,
    IgxDividerModule,
    IgxSimpleComboModule,
    IgxDialogComponent,
    IgxDialogModule
  ],
   exports: [
     FooterComponent,
     HeaderComponent,
     SidenavComponent,
     TranslateModule,
     IgxLayoutModule,
     BarcodeComponent,
     CrmSidenavComponent
  ],
})
export class SharedModule {

}

