<body style="background-color:rgb(255, 255, 255)">
    <div class="sample-column card-wrapper">
        <igx-card>
            <igx-card-header>
                <h3 igxCardHeaderTitle>{{ card.title | translate }}</h3>
            </igx-card-header>
            <igx-card-content>
                <p>Company : {{ companyCode }}</p>
                <p>Username : {{ username }}</p>
                <p [translate] style="color: lightgray;">PolicyPassword</p>

                <input type="password" placeholder="Password" name="password" autocomplete="off" [(ngModel)]="password">
                <input type="password" placeholder="Confirm Password" name="password" autocomplete="off"
                    [(ngModel)]="passwordConfirm">

            </igx-card-content>
            <igx-card-actions>
                <button igxButton igxRipple *ngFor="let button of card.buttons" (click)="backClicked()">{{ button |
                    translate }}</button>

                <button igxButton="icon" igxRipple [igxRippleCentered]="true" *ngFor="let icon of card.icons">
                    <igx-icon>{{icon}}</igx-icon>
                </button>
            </igx-card-actions>

        </igx-card>
    </div>
</body>