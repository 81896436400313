import { MbscModule } from '@mobiscroll/angular';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IgxButtonModule, IgxCheckboxModule, IgxDialogModule, IgxExcelExporterService, IgxSnackbarModule, IgxToastModule } from '@infragistics/igniteui-angular';
import { CookieService } from 'ngx-cookie-service';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { PageModule } from './page/page.module';
import { SharedModule } from './shared/shared.module';
import { NgxLoadingModule } from 'ngx-loading';


@NgModule({
	declarations: [AppComponent, LoginComponent ],
	imports: [  
		MbscModule ,  
		BrowserModule,
		SharedModule,
		PageModule,
		IgxDialogModule,
		IgxButtonModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		MaterialFileInputModule,
		MatFormFieldModule,
		IgxCheckboxModule,
		MatButtonModule,
		MatIconModule,
		HttpClientModule,
		NgxSpinnerModule,
		NgxLoadingModule.forRoot({}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		FormsModule,
		IgxToastModule,
		IgxSnackbarModule,
	],
	providers: [IgxExcelExporterService,CookieService],
	bootstrap: [AppComponent],
})
export class AppModule {
}
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}