import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IgxToastComponent, VerticalAlignment,  } from "@infragistics/igniteui-angular";
import { NgxSpinnerService } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';
import { Title } from "@angular/platform-browser";
import { sha256 } from 'js-sha256';
import { environments } from '../page/shared/variable-share';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  [x: string]: any;
  public authen: any;

  translate: TranslateService;
  constructor(private titleService: Title, private cookieService: CookieService, private _router: Router, translate: TranslateService, private http: HttpClient, private spinner: NgxSpinnerService) {
    this.translate = translate;
    this.titleService.setTitle("Login | โปรแกรมบัญชี AccCloud");
  }

  @ViewChild("loadToast", { read: IgxToastComponent, static: true })
  public loadToast: IgxToastComponent;
  public textToast: any;

  public companyCode: any;
  public username: '';
  public password: any;
  public superAdmin = '';
  public ipAddress = '';
  public location = '';
  public cookies = '';

  public email: any;

  public userLoginDemo = {
    "companyCode": "TEST05",
    "username": "ADMIN",
    "password": "admin",
    "superAdmin": 'ACCOUNTTEST',
  }

  public delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  ngOnInit(): void {
    this.http.get<any>("/api/authen/logout").subscribe((data) => {
      localStorage.clear();
      sessionStorage.clear();
      this.cookieService.delete('cookies');
    });
    this.loadToast.positionSettings.verticalDirection = VerticalAlignment.Bottom;
    this.getLocation()
    this.getIPAddress()
    this.cookies = Math.random().toString(36).substr(2, 9);
  }
  changeLanguage(lang: string) {
    this.translate.use(lang);
  }
  async onLogin() {
    this.Action = true;
this.spinner.show();
    var result: any
    var userLogin = {
      "username": this.username,
      "password": this.password,
      "companyCode": this.companyCode,
      "session": this.cookies,
      "superAdmin": this.superAdmin,
      "ipAddress": this.ipAddress,
      "location": this.location,
      "deviceType":navigator.userAgent,
      "logInMode": environments.environments
    }
    var info = {
      "username": this.username.toUpperCase(),
      "companyCode": this.companyCode.toUpperCase(),
      "superAdmin": this.superAdmin,
    }
    var key = userLogin.companyCode.toUpperCase() + userLogin.username.toUpperCase()
    var sign = sha256.hmac(key, userLogin.password)
    userLogin.password = sign
    this.http
      .post<any>("api/authen/login", userLogin)
      .subscribe(async (data) => {
        this.Action = false;
this.spinner.hide();
        result = data;
        if (result == "1") {
          let checkIp={
            employeeMasterId:this.username.toUpperCase(),
            fixIp: this.ipAddress,
            companyCode:this.companyCode
          };
          let checkIpUserResult = await this.http.post<any>("api/Company/checkIpUser", checkIp).toPromise();
          if(checkIpUserResult.responseData != undefined && checkIpUserResult.responseData != null || this.superAdmin == 'stradmin'){
            if(checkIpUserResult.responseData == "N"  ||this.superAdmin == 'stradmin'){
              //this.cookieService.set('cookies', this.cookies);
              localStorage.setItem('cookies', JSON.stringify(this.cookies));
              localStorage.setItem('info', JSON.stringify(info));
              userLogin.superAdmin = "RE-CHECK";
              localStorage.setItem('re-check', JSON.stringify(userLogin));
              if (this.superAdmin != undefined && this.superAdmin != '') {
                if (this.superAdmin == 'stradmin') {
                  this.textToast = 'SuperAdmin On'
                  this.loadToast.open('');
                  this._router.navigate(["homepage"]);
                } else {
                  this.textToast = 'SuperAdmin Password Incorrect'
                  this.loadToast.open('');
                }
              } else {
                this._router.navigate(["homepage"]);
              }
            }else{
              this.textToast = 'ERR:CT001 เข้าระบบได้เฉพาะ IP ที่กำหนด'
              this.loadToast.open('');
            }
            
          }else{
            this.textToast = 'ERR:S001 มีปัญหากรุณาติดต่อทาง Support'
            this.loadToast.open('');
          }
        }
      }, (err) => {
        this.textToast = 'LoginFeild'
        this.loadToast.open('');
        this.Action = false;
this.spinner.hide();
      });
  }

  onLoginDemo() {
    this.Action = true;
this.spinner.show();
    var result: any
    var key = this.userLoginDemo.companyCode.toUpperCase() + this.userLoginDemo.username.toUpperCase()
    var sign = sha256.hmac(key, this.userLoginDemo.password)
    this.userLoginDemo.password = sign
    this.http
      .post<any>("api/authen/login", this.userLoginDemo)
      .subscribe((data) => {
        this.Action = false;
this.spinner.hide();
        result = data;
        if (result == "1") {
          localStorage.setItem('info', JSON.stringify(this.userLoginDemo));
          this._router.navigate(["homepage"]);
        }
      }, (err) => {
        this.textToast = 'LoginFeild'
        this.loadToast.open('');
        this.Action = false;
this.spinner.hide();
      });
  }

  public getIPAddress() {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }
  public getLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        this.location = latitude.toString() + ',' + longitude.toString()
      });
    } else {
      console.log("No support for geolocation")
    }
  }

  public resetPassword(): void {
    var emailsArray = this.email.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    if (emailsArray != null && emailsArray.length) {
      this.http.post<any>("/api/Center/TblUserLogin/getByEmail", { email: this.email }).subscribe(async (data) => {
        if (data.length != 0) {
          if (data.length == 1) {
            var key = '1a1d5c4d22dcfc41a8f1d18bc87e32e38296538ff249678fe0ee399122d900f0'
            var sign = sha256.hmac(key, this.email)
            this.http.post<any>("/api/email/sending", { email: this.email, sign: sign }).subscribe(async (data) => {
              this.textToast = 'SeningEmailResetPassword'
              this.loadToast.open();
            });
          } else {
            this.textToast = 'FoundEmailDuplicate'
            this.loadToast.open();
          }
        } else {
          this.textToast = 'NotFoundEmail'
          this.loadToast.open();
        }
      });
    } else {
      this.textToast = 'NotEmail'
      this.loadToast.open();
    }
  }

  public redirectToDIP(){
    this._router.navigate(["calendar/create-calendar"]);
  }
}
