<router-outlet></router-outlet>
<button  *ngIf="superAdmin" class="float" (click)="onFloatClick()" (click)="dialog.open()">
    <igx-icon>note_alt</igx-icon>
</button>
<igx-dialog #dialog  class="dialog">
        <div class="modalv1" style="width: 1000px;">
            <div class="layout-box__el" style="margin-left: 5px">
                <igx-input-group>
                    <label igxLabel for="memo">{{memoLabel}}</label>
                    <textarea igxInput id="memo" [(ngModel)]="memo"></textarea>
                </igx-input-group>
            </div>
            <div igxLayout igxLayoutJustify="flex-end" style="margin: 2%">
                <button igxButton igxRipple type="button" igxLayout igxLayoutJustify="center"
                    (click)="dialog.close()" (click)="updateMemo()" [translate]>Close
                </button>
            </div>
        </div>
</igx-dialog>